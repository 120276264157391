import axios from "axios";
import { ErrorAlert, SuccessAlert } from "components/alert";
import React, {createContext, useState} from "react";
import EstateDetail from "views/screens/EstateDetail";

export const ESContext = createContext();

const path_url = process.env.REACT_APP_API_CONNECT;

export function ESProvider(props){
    const [ESDetail, setESDetail] = useState({
        titledeed_number: "",
        saleCatagory: "LAND",
        saleType: "",
        province: {
            id: "0",
            name: ""
        },
        district: {
            id: "0",
            name: ""
        },
        size:{
            rai: 0,
            ngan: 0,
            wa: 0,
        },
        bathRoom: 0,
        bedRoom: 0,
        useableArea: 0,
        totalPrice: 0,
        pricePerWa: 0,
        quick: false,
        description: "",
        title:"",
        freePay: "0",
        package: {
            type: 'STANDARD',
            price: 0
        },
        matching: false,
        images: [],
        preview_images: [],
        video: null,
        more: '',
        coAgent: false,
        titleDeedShow: true,
    });

    function inputData(val, prop){
        setESDetail((item) =>{
            const datas = {...item}
            datas[prop] = val;
            return {...datas}
        })
    }

    function inputDataNumber(val, prop){
        setESDetail((item) =>{
            const datas = {...item}
            datas[prop] = parseInt(val);
            return {...datas}
        })
    }

    function inputImage(val){
        setESDetail((item) =>{
            const datas = {...item}
            const imgs = [...datas.images]
            const prevs = [...datas.preview_images]
            imgs.push(val)
            prevs.push(URL.createObjectURL(val))
            return{...datas, images: imgs, preview_images: prevs}
        })
    }

    function deleteImage(key){
        setESDetail((item) =>{
            const datas = {...item}
            const imgs = [...datas.images]
            const prevs = [...datas.preview_images]
            const remove_img = imgs.filter((item,i) => i !== key)
            const remove_prev = prevs.filter((item,i) => i !== key)
            return{...datas, images: [...remove_img], preview_images: [...remove_prev]}
        })
    }

    function inputDataSize(val, prop, element){
        setESDetail((item) =>{
            const datas = {...item}
            datas[prop][element] = val;
            return {...datas}
        })
    }

    const uploadImageSale = (files) => new Promise(async (resolve, reject) =>{
        try{
            const form = new FormData();
            files.map(item => {
                return form.append('imgfile', item)
            })
            const result = await axios.post(path_url+'/upload/addSale', form, {
                headers:{
                    'Content-Type' : 'multipart/form-data'
                }
            })

            if(result.status === 200){
                resolve(result.data)
            }
        }catch(e){
            reject(e)
        }
    })

    async function uploadSale(userId, userName, userPhone, userLand, userHome){
        try{
            if(ESDetail.images.length !== 0){
                const imagess = await uploadImageSale(ESDetail.images)
                const res = await axios.post(path_url+'/sale/addSale',{
                    rai: ESDetail.size.rai ? ESDetail.size.rai : 0,
                    ngan: ESDetail.size.ngan ? ESDetail.size.ngan : 0,
                    wa: ESDetail.size.wa ? ESDetail.size.wa : 0,
                    user_id: userId,
                    saleCategory: ESDetail.saleCatagory,
                    name: userName,
                    phoneNumber: userPhone,
                    userLand: userLand,
                    userHome: userHome,
                    saleType: ESDetail.saleType,
                    provice: ESDetail.province.name,
                    district: ESDetail.district.name,
                    titleDeednumber: ESDetail.titledeed_number,
                    title: ESDetail.title,
                    description: ESDetail.description,
                    priceperwa: ESDetail.pricePerWa,
                    pricetotal: ESDetail.totalPrice,
                    bathRoom: ESDetail.bathRoom ? ESDetail.bathRoom : 0,
                    bedroom: ESDetail.bedRoom ? ESDetail.bedRoom : 0,
                    area: ESDetail.useableArea ? ESDetail.useableArea : 0,
                    detailUrl: ESDetail.video,
                    quick: ESDetail.quick,
                    packageType: ESDetail.package.type,
                    packagePrice: ESDetail.package.price,
                    matching: ESDetail.matching,
                    freepay: ESDetail.freePay,
                    images: imagess,
                    more: ESDetail.more,
                    coAgent: ESDetail.coAgent,
                    titleDeedShow: ESDetail.titleDeedShow
                })
    
                if(res.status === 200){
                    SuccessAlert();
                    localStorage.setItem('profile', JSON.stringify(res.data))
                    // setTimeout(() =>{
                    //     window.location.href = "/dashboard"
                    // },1500)
                }
            }else{
                const res = await axios.post(path_url+'/sale/addSale',{
                    rai: ESDetail.size.rai ? ESDetail.size.rai : 0,
                    ngan: ESDetail.size.ngan ? ESDetail.size.ngan : 0,
                    wa: ESDetail.size.wa ? ESDetail.size.wa : 0,
                    user_id: userId,
                    saleCategory: ESDetail.saleCatagory,
                    name: userName,
                    phoneNumber: userPhone,
                    userLand: userLand,
                    userHome: userHome,
                    saleType: ESDetail.saleType,
                    provice: ESDetail.province.name,
                    district: ESDetail.district.name,
                    titleDeednumber: ESDetail.titledeed_number,
                    title: ESDetail.title,
                    description: ESDetail.description,
                    priceperwa: ESDetail.pricePerWa,
                    pricetotal: ESDetail.totalPrice,
                    bathRoom: ESDetail.bathRoom ? ESDetail.bathRoom : 0,
                    bedroom: ESDetail.bedRoom ? ESDetail.bedRoom : 0,
                    area: ESDetail.useableArea ? ESDetail.useableArea : 0,
                    detailUrl: ESDetail.video,
                    quick: ESDetail.quick,
                    packageType: ESDetail.package.type,
                    packagePrice: ESDetail.package.price,
                    matching: ESDetail.matching,
                    freepay: ESDetail.freePay,
                    images: [],
                    more: ESDetail.more,
                    coAgent: ESDetail.coAgent,
                    titleDeedShow: ESDetail.titleDeedShow
                })
    
                if(res.status === 200){
                    SuccessAlert();
                    localStorage.setItem('profile', JSON.stringify(res.data))
                    // setTimeout(() =>{
                    //     window.location.href = "/dashboard"
                    // },1500)
                }
            }
            
            
        }catch(e){
            console.log(e)
            if(e.response.status === 401){
                ErrorAlert({text: 'ขออภัย! ทรัพย์แปลงนี้ลงประกาศแล้ว กรุณาติดต่อเจ้าหน้าที่'})
            }else{
                ErrorAlert({text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง!'})
            }
        }
        // const form = new FormData();
        // ESDetail.images.map(item => {
        //     return form.append('imgfile', item)
        // })
        // form.append('rai', ESDetail.size.rai ? ESDetail.size.rai : 0);
        // form.append('ngan', ESDetail.size.ngan ? ESDetail.size.ngan : 0);
        // form.append('wa', ESDetail.size.wa ? ESDetail.size.wa : 0);
        // form.append('user_id', userId);
        // form.append('saleCategory', ESDetail.saleCatagory);
        // form.append('name', userName);
        // form.append('phoneNumber', userPhone);
        // form.append('userLand', userLand);
        // form.append('userHome', userHome);
        // form.append('saleType', ESDetail.saleType);
        // form.append('provice', ESDetail.province.name);
        // form.append('district', ESDetail.district.name);
        // form.append('titleDeednumber', ESDetail.titledeed_number)
        // form.append('title', ESDetail.title);
        // form.append('description', ESDetail.description);
        // form.append('priceperwa', ESDetail.pricePerWa);
        // form.append('pricetotal', ESDetail.totalPrice);
        // form.append('bathRoom', ESDetail.bathRoom ? ESDetail.bathRoom : 0);
        // form.append('bedroom', ESDetail.bedRoom ? ESDetail.bedRoom : 0);
        // form.append('area', ESDetail.useableArea ? ESDetail.useableArea : 0);
        // form.append('detailUrl', ESDetail.video);
        // form.append('quick', ESDetail.quick);
        // form.append('packageType', ESDetail.package.type);
        // form.append('packagePrice', ESDetail.package.price);
        // form.append('matching', ESDetail.matching);
        // form.append('freepay', ESDetail.freePay);
        // axios.post(path_url+'/upload/addSale',form,{
        //     headers: {
        //         'Content-Type' : 'multipart/form-data'
        //     }
        // })
        // .then(res =>{
        //     if(res.status === 200){
        //         SuccessAlert();
        //         sessionStorage.setItem('profile', JSON.stringify(res.data))
        //         setTimeout(() =>{
        //             window.location.reload();
        //         },1500)
        //     }
        // })
        // .catch(error =>{
        //     console.log(error)
        //     ErrorAlert({text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง!'})
        // })
    }

    function onCheckData(){
        console.log(ESDetail)
    }

    const _handlerLandCal = () =>{
        const rai = parseFloat(ESDetail.size.rai);
        const ngan = parseFloat(ESDetail.size.ngan);
        const wa = parseFloat(ESDetail.size.wa);

        const cal_rai = rai * 4;
        const cal_ngan = (cal_rai + ngan) * 100;
        const cal_wa = (cal_ngan + wa)
        
        const cal_price = parseFloat(ESDetail.pricePerWa) * cal_wa

        inputData(cal_price, "totalPrice")
    }

    return(
        <ESContext.Provider
        value={{
            ESDetail, 
            inputData, 
            onCheckData, 
            inputDataSize, 
            uploadSale, 
            inputImage, 
            deleteImage, 
            _handlerLandCal,
            inputDataNumber
        }}
        >
            {props.children}
        </ESContext.Provider>
    )
}