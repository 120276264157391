import React from "react";

import { useLocation } from "react-router-dom";
import { LoginContext } from "components/loginContext/LoginContext";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faShareNodes,
    faChevronUp,
    faLink,
    faCheck
} from '@fortawesome/free-solid-svg-icons';
import { 
    Button, 
    Row,
    Col,
    Popover,
    PopoverBody,
    Tooltip,
} from "reactstrap";
import { 
    GoogleMap, 
    Polygon, 
    Marker,
    useJsApiLoader
} from '@react-google-maps/api';
import {
    faFacebookF,
    faLine,
    faTwitter
} from '@fortawesome/free-brands-svg-icons';
import {
    FacebookShareButton,
    LineShareButton,
    TwitterShareButton
} from 'react-share';
import PhotoGrid from "components/photogrid/photogrid";
import blank_profile from "assets/img/blank-profile.png";
import HouseMarker from 'assets/img/house.svg';
import LandMarker from 'assets/img/land.svg';
import CommercialMarker from 'assets/img/commercial.svg';
import HotelMarker from 'assets/img/hotel.svg';
import FactoryMarker from 'assets/img/factory.svg';
import MoreMarker from 'assets/img/more.svg';
import ReactPlayer from "react-player";
import { TikTok } from "react-tiktok";
import LoginModal from "components/loginModal";

const google_api = process.env.REACT_APP_GOOGLE_API;
const path_url = process.env.REACT_APP_API_CONNECT;
const domain = `${window.location.origin}${window.location.pathname}${window.location.search}`
const share_url = domain;

function MyDetail(){
    const { userData } = React.useContext(LoginContext);
    const [estateData, setEstateData] = React.useState(null);
    const [popoverShare, setPopoverShare] = React.useState(false);
    const [copycheck, setCopycheck] = React.useState(false);
    const [tooltip, setTooltip] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [loginOpen, setLoginOpen] = React.useState(false);
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: google_api,
        region:'th',
        language: 'th',
        version: "weekly",
    });

    const toggle = () => setTooltip(!tooltip);

    function useQuery() {
        const { search } = useLocation();
        return new URLSearchParams(search);
    }

    let query = useQuery();
    const google = window.google;

    React.useEffect(() =>{
        _onFetch()

        scrollToTop()
    },[])

    async function _onFetch(){
        const titledeed = query.get("sale");
        
        try{
            const result = await axios.post(path_url+'/landingpage/getDetailEstate',{
                titleDeed: titledeed
            })

            if(result.status === 200){
                setEstateData(result.data)
                console.log(result.data)
            }
        }catch(e){
            console.log(e)
        }
    }

    const copylink = async () =>{
        try{
            await navigator.clipboard.writeText(share_url)
            setCopycheck(true)
            setTimeout(() => {
                setCopycheck(false)
            },2000)
        }catch(e){
            console.log(e)
        }
    }

    const toNavigate = () =>{
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${estateData?.titledeed?.centerPoint?.coordinates[1]}, ${estateData?.titledeed?.centerPoint?.coordinates[0]}`)
    }

    const intl = new Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 0,
    })

    const userSaleShow = async () =>{
        if(userData){
            try{
                const res = await axios.post(path_url+'/sale/interest',{
                    saleID: estateData._id,
                    userID: userData._id,
                    name: userData.name,
                    email: userData.email,
                })
                if(res.status === 200){
                    setShow(true)
                }
            }catch(e){
                console.log(e)
            }
        }else{
            setLoginOpen(true)
        }
    }

    const freepaytype = (val) =>{
        switch(val){
            case '1' : return "ผู้ซื้อผู้ขายแบ่งจ่ายคนละครึ่ง";
            case '2' : return "ผู้ขายจ่ายทั้งหมด";
            case '3' : return "ผู้ซื้อจ่ายทั้งหมด";
            case '4' : return "ผู้ขายจ่ายภาษีเงินได้ ส่วนที่เหลือแบ่งจ่ายคนละครึ่ง";
            case '5' : return "ตามที่ตกลง";
            default : return "ตามที่ตกลง";
        }
    }

    function scrollToTop(){
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
    }

    return(
        <>
        <div className="myestate-container">
            <div className="myestate-header mb-30">
                <h1 className="tltd-header">{estateData?.title}</h1>
                <div className="container-like">
                    {/* <Favorite 
                    className="mr-10" 
                    onFavorite={() => onFavorite(estateData?._id)} 
                    favorite={userData !== null ? userData.favorites.some((items) => items === estateData?._id) : false} 
                    /> */}
                    <Button 
                    id="Popover1" 
                    className="btn-share-estate"
                    onClick={() => setPopoverShare(true)}
                    >
                        <FontAwesomeIcon icon={faShareNodes} style={{fontSize: 20}} />
                    </Button>
                    <Popover 
                    target="Popover1"
                    placement="bottom" 
                    toggle={() => setPopoverShare(!popoverShare)}
                    isOpen={popoverShare}
                    >
                        <PopoverBody>
                            <FacebookShareButton 
                            url={share_url+''} 
                            className="btn-share-facebook mr-10"
                            >
                                <FontAwesomeIcon icon={faFacebookF} style={{color:'white'}} />
                            </FacebookShareButton>
                            <LineShareButton
                            url={share_url}
                            className="btn-share-line mr-10"
                            >
                                <FontAwesomeIcon icon={faLine} style={{color:'white'}} />
                            </LineShareButton>
                            <TwitterShareButton
                            url={share_url}
                            className="btn-share-twitter mr-10"
                            >
                                <FontAwesomeIcon icon={faTwitter} style={{color:'white'}} />
                            </TwitterShareButton>
                            <Button
                            color="share-copylink"
                            onClick={() => copylink()}
                            id="tooltip1"
                            >
                                <FontAwesomeIcon icon={faLink} />
                            </Button>
                            <Tooltip
                            isOpen={tooltip}
                            target="tooltip1"
                            toggle={toggle}
                            >
                                Copy Link {copycheck ? <FontAwesomeIcon icon={faCheck} color="#41CD28" /> : null}
                            </Tooltip>
                        </PopoverBody>
                    </Popover>
                </div>
            </div>
            <div className="myestate-image mb-50">
                <PhotoGrid items={estateData? estateData?.images?.map(items => {return {src: items.path}}):[]} />
            </div>
            {estateData?.propertyDetailUrl ? 
            <div className="mb-50">
                {String(estateData?.propertyDetailUrl).indexOf("tiktok.com") > 1 ? 
                <TikTok
                url={estateData?.propertyDetailUrl}
                />
                : 
                <ReactPlayer 
                url={estateData?.propertyDetailUrl }
                controls={true}
                width={'100%'}
                height={500}
                />}
            </div> : null}
            <div className="myestate-map mb-50">
                <p className="header">แผนที่</p>
                {isLoaded && google ?
                    <GoogleMap
                    mapContainerClassName="mapWrapper"
                    center={{
                        lat: estateData?.titledeed?.centerPoint?.coordinates[1], 
                        lng: estateData?.titledeed?.centerPoint?.coordinates[0]
                    }}
                    zoom={14}
                    options={{ 
                        gestureHandling: 'greedy',
                        fullscreenControl: false,
                        mapTypeControl: true,
                        mapTypeControlOptions:{
                            position: google.maps.ControlPosition.LEFT_TOP,
                            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                            mapTypeIds: [google.maps.MapTypeId.TERRAIN, google.maps.MapTypeId.SATELLITE],
                            
                        },
                        mapTypeId: `${google.maps.MapTypeId.SATELLITE}`,
                        
                    }}
                    >
                        <button 
                        style={{
                            display:'block',
                            position:'absolute',
                            top: 15,
                            right: 15,
                            background: '#fff',
                            color: '#000',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                            border: 'none',
                            cursor: 'pointer',
                            borderRadius: 8
                        }}
                        className="shadow"
                        onClick={() => toNavigate()}
                        >
                            <p>นำทาง</p>
                        </button>
                        {estateData?.titledeed ? 
                        <>
                        <Polygon 
                        options={{
                            strokeColor: '#1789FE',
                            strokeWeight: 3,
                            fillColor: '#1789FE'
                        }}
                        path={estateData?.titledeed?.paths?.coordinates[0].map(element =>{
                            return {lat: element[1], lng: element[0]}
                        })}
                        />
                        <Marker 
                        icon={estateData?.saleCategory === "LAND" ? LandMarker : HouseMarker}
                        label={{
                            color: estateData?.saleCategory === 'LAND' 
                            ? '#EF9751' 
                            : '#1789FE',
                            text: estateData?.saleCategory === 'LAND'
                            ? `${intl.format(estateData?.pricePerWa)}` 
                            : `${intl.format(estateData?.totalPrice)}`,
                            fontFamily: "'Prompt', sans-serif"
                        }}
                        position={{
                            lat: estateData?.titledeed?.centerPoint?.coordinates[1], 
                            lng: estateData?.titledeed?.centerPoint?.coordinates[0]
                        }}
                        />
                        </>
                        : null}
                    </GoogleMap>
                : null}
            </div>
            <div className="myestate-info mb-50">
                <div className="estate-info-container-1">
                    <div className="header-container">
                        <span className="header">ข้อมูลและรายละเอียดแปลงที่ดิน</span>
                        {estateData?.quick ? 
                        <div className="saler-quick mb-20">
                            <div className="description">ขายด่วน</div> 
                        </div>
                        : null}
                    </div>
                    
                    {show ? 
                    <div className="saler-info mb-50">
                        <div className="saler-img mr-10">
                            <img src={estateData?.userDetail?.profileImg ? estateData?.userDetail?.profileImg : blank_profile} alt="" />
                        </div>
                        
                        <div className="saler-detail">
                            <div className="saler-body">
                                <p>ชื่อผู้ประกาศ : <label>{userData ? userData.role === "PREMIUM" || estateData?.userDetail?.role === "PREMIUM" || estateData?.package?.type === "PERPLOT" ? estateData?.userDetail?.name : "ตลาดที่ดิน" : "ตลาดที่ดิน"}</label></p>
                                <p>เบอร์ติดต่อ : <label>{userData ? userData.role === "PREMIUM" || estateData?.userDetail?.role === "PREMIUM" || estateData?.package?.type === "PERPLOT" ? estateData?.userDetail?.phoneNumber : "020-955-198" : "020-955-198"}</label></p>
                                <p>เบอร์ติดต่อสำรอง : <label>{userData ? userData.role === "PREMIUM" || estateData?.userDetail?.role === "PREMIUM" || estateData?.package?.type === "PERPLOT" ? estateData?.userDetail?.phoneBackup : "" : ""}</label></p>
                            </div>
                            <div className="saler-status mb-20">
                                <div className="description">{estateData?.saleType === "BROKER" ? 'นายหน้า' : 'เจ้าของที่ดิน'}</div> 
                            </div>
                            {userData ? 
                            userData.role === "PREMIUM" || estateData?.userDetail?.role === "PREMIUM" || estateData?.package?.type === "PERPLOT" ?
                            null
                            : 
                            <div className="mb-20">
                                <small className="color-red">* ข้อมูลติดต่อเจ้าของที่ดินสำหรับสมาชิก Premium เท่านั้น <a href="/packages" ><u>สนใจคลิก</u></a></small>
                            </div>
                            :
                            <div className="mb-20">
                                <small className="color-red">* ข้อมูลติดต่อเจ้าของที่ดินสำหรับสมาชิก Premium เท่านั้น <a href="/packages" ><u>สนใจคลิก</u></a></small>
                            </div>
                            }
                            <div className="hideinfo">
                                <Button color="transparent" onClick={() => setShow(!show)}>
                                    แสดงน้อยลง 
                                    <FontAwesomeIcon icon={faChevronUp} className="ml-10" />
                                </Button>
                            </div>
                        </div>
                        
                    </div>
                    :
                    <div className="showinfo mb-50">
                        <Button color="default" onClick={() => userSaleShow()}>แสดงข้อมูลผู้ขาย</Button>
                    </div>
                    }
                    {estateData?.coAgent ? 
                        <div className="co-agent mb-30">
                            {estateData?.saleType === "OWNER" ? "ยินดีรับนายหน้า" : "ยินดีรับ Co-Agent"}
                        </div>
                    : null}
                    <div className="estate-description mb-10">
                        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-2">
                            <Col>
                                <label className="title">รหัสประกาศ</label>
                                <label className="text">{estateData?.saleID}</label>
                            </Col>
                            <Col>
                                <label className="title">โฉนดเลขที่</label>
                                {userData?.role === "PREMIUM"  ? 
                                <p 
                                className="text" 
                                style={{display:'flex', wordBreak:'break-word', wordWrap:'break-word'}}>
                                    {estateData?.titleDeedShow ? estateData?.titleDeedNumber : <label>*****</label>}
                                </p>
                                : 
                                <div 
                                className="text" 
                                style={{display:'flex', wordBreak:'break-word', wordWrap:'break-word'}}
                                >
                                    <small className="color-red">* สำหรับสมาชิก Premium เท่านั้น <a href="/packages" ><u>สนใจคลิก</u></a></small>
                                </div>
                                }
                            </Col> 
                            <Col>
                                <label className="title">ประเภท</label>
                                <label className="text">
                                    {
                                        estateData?.saleCategory === "LAND" 
                                        ? 'ที่ดินเปล่า' 
                                        : estateData?.saleCategory === "HOME"
                                        ? 'บ้าน ที่พักอาศัย'
                                        : estateData?.saleCategory === "COMMERCIAL"
                                        ? 'อาคารพาณิชย์'
                                        : estateData?.saleCategory === "HOTEL"
                                        ? 'โรงแรม/รีสอร์ท'
                                        : estateData?.saleCategory === "FACTORY"
                                        ? 'โรงงาน/โกดัง'
                                        : estateData?.saleCategory === "MORE"
                                        ? 'อื่นๆ'
                                        :null
                                    }
                                    {estateData?.saleCategory === "MORE" ?
                                    <div>({estateData?.more})</div>
                                    : null}
                                </label>
                            </Col>
                            <Col>
                                <label className="title">ที่อยู่</label>
                                <label className="text">{estateData?.saleDistrict + ", " + estateData?.saleProvince}</label>
                            </Col>
                        </Row>
                        {estateData?.cityplan ? 
                        <>
                        <div className="estate-cityplan">
                            <label className="title">ผังเมือง</label>
                            <Row className="row-cols-1 row-cols-md-2 row-cols-lg-2">
                                <Col>
                                    <label>พิกัดสถานที่</label>
                                    <label className="text">{estateData?.cityplan?.locationCoordinates}</label>
                                </Col>
                                <Col>
                                    <label>ที่ดินหมายเลข</label>
                                    <label className="text">{estateData?.cityplan?.landNumber}</label>
                                </Col>
                                <Col>
                                    <label>ประเภท</label>
                                    <label className="text">{estateData?.cityplan?.type}</label>
                                </Col>
                            </Row>
                        </div>
                        </>
                        : null}
                        
                        <div className="estate-highlight">
                            <label className="title">จุดเด่นของแปลงที่ดิน</label>
                            <textarea 
                            className="text" 
                            rows={8} 
                            disabled 
                            defaultValue={estateData?.description}
                            />
                        </div>
                        
                    </div>
                </div>
                <div className="estate-info-container-2">
                    <div className="detail-wrapper mb-20">
                        <div 
                        style={{
                            paddingLeft: 20, 
                            paddingTop:10, 
                            paddingBottom:10, 
                            borderBottom:'0.5px solid gray', 
                            marginBottom: 10
                        }}>
                            <p style={{fontWeight: 400}}>รายละเอียด</p>
                        </div>
                        <div style={{padding: 10}}>
                            <p className="mb-10">
                                {'พื้นที่ขนาด '}
                                {estateData?.size?.rai ? estateData?.size?.rai+' ไร่ ': null}
                                {estateData?.size?.ngan ? estateData?.size?.ngan+' งาน ': null} 
                                {estateData?.size?.wa ? estateData?.size?.wa+' ตารางวา': null} 
                            </p>
                            {estateData?.saleCategory === "HOME" || estateData?.saleCategory === "COMMERCIAL" || estateData?.saleCategory === "MORE" ? 
                            <div className="mb-10"> 
                                <label className="mr-10">{estateData.bedRoom ? `${estateData?.bedRoom} ห้องนอน`: null} </label> 
                                <label className="mr-10">{estateData.bathRoom ? `${estateData?.bathRoom} ห้องน้ำ`: null} </label>
                                <label>{estateData.usableArea ? `พื้นที่ใช้สอย ${estateData.usableArea} ตารางเมตร`: null} </label> 
                            </div>
                            : estateData?.saleCategory === "HOTEL" || estateData?.saleCategory === "FACTORY" ?
                            <div className="mb-10"> 
                                <label>{estateData.usableArea ? `พื้นที่ใช้สอย ${estateData.usableArea} ตารางเมตร`: null} </label> 
                            </div>
                            :null}
                            {estateData?.saleCategory === 'LAND' ? 
                            <p style={{marginBottom: 10}}>
                                ราคา {intl.format(estateData?.pricePerWa)} / ตร.ว.
                            </p>:null}
                            <p style={{marginBottom: 10, fontWeight:500}} className="color-default font">
                                ราคารวม {intl.format(estateData?.totalPrice)}
                            </p>
                            {estateData?.landAppraisalPrice ? 
                            <p style={{marginBottom: 10, fontWeight:500}} className="color-black font">
                                ราคาประเมินที่ดิน (กรมธนารักษ์) : {estateData?.landAppraisalPrice}
                            </p>
                            :null}
                            
                        </div>
                        {/* <div style={{padding: 10}}>
                            <Button 
                            color="default-outline" 
                            size="sm" 
                            className="full-w" 
                            disabled={userData?.role === "PREMIUM" ? false : true}
                            onClick={() => setPricesOfferModal(true)}
                            >
                                เสนอราคา
                            </Button>
                        </div> */}
                    </div>
                    <div className="mb-30">
                        <p style={{fontWeight:500}}>ผู้ชำระค่าธรรมเนียม, ภาษี ณ วันโอนกรรมสิทธิ์</p>
                        <p className="color-gray" style={{fontSize:14}}>(ค่าธรรมเนียมการโอน, ภาษีธุรกิจเฉพาะ และภาษีเงินได้ไม่รวมค่าจดจำนอง)</p>
                        <div className="color-gray" style={{border : '0.5px solid gray', textAlign:'center', borderRadius:10, padding:10}}>
                            {estateData?.freepay ? freepaytype(estateData.freepay) : "ตามที่ตกลง"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <LoginModal isOpen={loginOpen} onClose={() => setLoginOpen(false)} className="modal-sm" />
        </>
    )
}
export default MyDetail;