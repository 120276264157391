import React from 'react';
import Navbars from 'routers/navbars';

import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Col,
    Spinner
} from 'reactstrap'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faChevronRight,
    faChevronLeft
} from "@fortawesome/free-solid-svg-icons";
import {
    faComments
} from "@fortawesome/free-regular-svg-icons";
import Footer from 'components/footer';

import Consignment_Tabs1 from './ConsignmentTabs/Tabs1';
import Consignment_Tabs2 from './ConsignmentTabs/Tabs2';
import Consignment_Tabs3 from './ConsignmentTabs/Tabs3';
import axios from 'axios';
import { ErrorAlert, SuccessAlert } from 'components/alert';
import Swal from 'sweetalert2';
import LoginModal from 'components/loginModal';
import { LoginContext } from 'components/loginContext/LoginContext';

const path_url = process.env.REACT_APP_API_CONNECT;
const line_url = process.env.REACT_APP_LINE_PATH;

function ConsignmentMn(){
    const { userData, setUserData } = React.useContext(LoginContext)
    const [tabActive, setTabActive] = React.useState(1);
    const [consignData, setConsignData] = React.useState({
        transaction_type: 'MORTGAGE',
        titledeed_Number: '',
        saleType: 'LAND',
        title: '',
        province: '',
        district: '',
        rai: '',
        ngan: '',
        wa: '',
        totalPrice: '',
        pricePerWa: '',
        year: 0,
        description: "",
        images: [],
        preview_images: [],
        video: null
    })

    const [acceptChecked, setAcceptChecked] = React.useState(false);
    const [toggleFail, setToggleFail] = React.useState(false);
    const [toggleFail2, setToggleFail2] = React.useState(false);
    // const [userData, setUserData] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [loginOpen, setLoginOpen] = React.useState(false)

    React.useEffect(() => {
        const data = localStorage.getItem("profile")
        if(!data){
            setLoginOpen(true)
        }else{
            setLoginOpen(false)
        }
    },[])

    const next = () =>{
        setTabActive((item) =>{
            return item + 1;
        })
        scrollToTop();
    }

    const prev = () =>{
        setTabActive((item) =>{
            return item - 1;
        })
        scrollToTop();
    }

    const scrollToTop = () =>{
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
    }

    const _handlerChange = (val, prop) =>{
        setConsignData((prev) =>{
            const data = {...prev}
            data[prop] = val;
            return {...data}
        })
    }

    const onChangeImage = (val) =>{
        inputImage(val[0])
    }

    function inputImage(val){
        setConsignData((item) =>{
            const datas = {...item}
            const imgs = [...datas.images]
            const prevs = [...datas.preview_images]
            imgs.push(val)
            prevs.push(URL.createObjectURL(val))
            return{...datas, images: imgs, preview_images: prevs}
        })
    }

    const deleteImg = (key) =>{
        setConsignData((item) =>{
            const datas = {...item}
            const imgs = [...datas.images]
            const prevs = [...datas.preview_images]
            const remove_img = imgs.filter((item,i) => i !== key)
            const remove_prev = prevs.filter((item,i) => i !== key)
            return{...datas, images: [...remove_img], preview_images: [...remove_prev]}
        })
    }

    function _handlerChecked(val){
        setAcceptChecked(val)
    }

    function dataCheck(){
        if(
            consignData.titledeed_Number &&
            consignData.title &&
            consignData.province &&
            (consignData.rai || consignData.ngan || consignData.wa) &&
            consignData.pricePerWa &&
            consignData.totalPrice &&
            (consignData.year > 0)
        ){
            next()
        }else{
            setToggleFail(true)
        }
    }

    function dataCheck2(){
        if(consignData.images.length !== 0){
            next()
        }else{
            setToggleFail2(true)
        }
    }

    function afterSend(){
        Swal.fire({
            icon: 'success',
            title: 'อยู่ระหว่างการพิจารณา',
            text: 'ข้อมูลของท่านได้ส่งให้ทางทีมงานแล้ว ข้อมูลจะถูกเก็บเป็นความลับ',
            confirmButtonColor: '#EF9751'
        }).then((result) =>{
            console.log(result)
            if(result.isConfirmed || result.isDismissed){
                window.location.reload()
            }
        }).catch(e =>{
            console.log(e)
        })
    }

    const uploadImageConsignment = (files) => new Promise(async (resolve, reject) =>{
        try{
            const form = new FormData();
            files.map(item => {
                return form.append('images', item)
            })
            const result = await axios.post(path_url+'/upload/uploadImageConsignment', form, {
                headers:{
                    'Content-Type' : 'multipart/form-data'
                }
            })

            if(result.status === 200){
                resolve(result.data)
            }
        }catch(e){
            reject(e)
        }
    })

    async function onSubmit(){
        setLoading(true)
        try{
            if(acceptChecked){
                const imagess = await uploadImageConsignment(consignData.images).then(item => item)
                const res = await axios.post(path_url+'/consignment/consignment_insert',{
                    ...consignData,
                    rai: consignData.rai ? parseFloat(consignData.rai) : 0,
                    ngan: consignData.ngan ? parseFloat(consignData.ngan) : 0,
                    wa: consignData.wa ? parseFloat(consignData.wa) : 0,
                    totalPrice: parseFloat(consignData.totalPrice),
                    pricePerWa: parseFloat(consignData.pricePerWa),
                    images: imagess,
                    user: userData._id
                })

                if(res.status === 200){
                    SuccessAlert()
                    setLoading(false)
                    setTimeout(() => {
                        afterSend()
                    },1500)
                }
            }else{
                setLoading(false)
                ErrorAlert({text: 'กรุณายอมรับเงื่อนไขก่อนทำรายการ'})
                return;
            }
            
        }catch(e){
            console.log(e)
            setLoading(false)
            // ErrorAlert({text: 'เกิดข้อผิดพลาด กรุณาตรวจสอบข้อมูลอีกครั้ง'})
            return;
        }
    }

    return(
        <>
        <Navbars />
        <div className='consignment-container'>
            <div className="consignment-title">
                <p className="underline">จำนอง/ขายฝาก</p>
            </div>
            <ul className="wizard">
                <li className={tabActive === 1 ? "active" : ""}>
                    <span>ข้อมูลผู้ประกาศ/ข้อมูลทรัพย์</span>
                </li>
                <li className={tabActive === 2 ? "active" : ""}>
                    <span>รูปถ่ายแปลงที่ดิน/บ้าน</span>
                </li>
                <li className={tabActive === 3 ? "active" : ""}>
                    <span>ยืนยันข้อมูลการประกาศ</span>
                </li>
            </ul>
            <div className="consignment-content">
                <div className={tabActive === 1 ? 'show' : ''}>
                    <Consignment_Tabs1 user={userData} value={consignData} onchange={(val, prop) => _handlerChange(val, prop)} />
                    <div className="btn-content">
                        <Button color="next" onClick={() => dataCheck()}>
                            ขั้นตอนถัดไป <FontAwesomeIcon icon={faChevronRight} style={{marginLeft:10}} />
                        </Button>
                    </div>
                    <div className="tab-contact">
                        <div className="contact-wrapper">
                            <i style={{fontSize:30, fontWeight: 500}}>" ไม่ต้องกังวลนะคะ "</i>
                            <p style={{fontSize:20, marginBottom: 20, fontWeight: 400}}>เรามีผู้ช่วยคอยให้บริการค่ะ</p>
                            <Button 
                            type="button" 
                            color="default" 
                            onClick={() => window.open(line_url)}
                            >
                                <FontAwesomeIcon icon={faComments} style={{marginRight:15}} />คลิก ติดต่อผู้ช่วย
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={tabActive === 2 ? 'show' : ''}>
                    <div className="btn-content">
                        <Button type="button" color="next" onClick={() => prev()}><FontAwesomeIcon icon={faChevronLeft} style={{marginRight:10}} /> ย้อนกลับ</Button>
                        <Button type="button" color="next" onClick={() => dataCheck2()}>ขั้นตอนถัดไป <FontAwesomeIcon icon={faChevronRight} style={{marginLeft:10}} /></Button>
                    </div>
                    <Consignment_Tabs2 
                    value={consignData} 
                    onchange={(val, prop) => _handlerChange(val, prop)} 
                    onDeleteImg={(val) => deleteImg(val)} 
                    onChangeImg={(val) => onChangeImage(val)}
                    />
                    <div className="btn-content">
                        <Button type="button" color="next" onClick={() => prev()}><FontAwesomeIcon icon={faChevronLeft} style={{marginRight:10}} /> ย้อนกลับ</Button>
                        <Button type="button" color="next" onClick={() => dataCheck2()}>ขั้นตอนถัดไป <FontAwesomeIcon icon={faChevronRight} style={{marginLeft:10}} /></Button>
                    </div>
                    <div className="tab-contact">
                        <div className="contact-wrapper">
                            <i style={{fontSize:30, fontWeight: 500}}>" ไม่ต้องกังวลนะคะ "</i>
                            <p style={{fontSize:20, marginBottom: 20, fontWeight: 400}}>เรามีผู้ช่วยคอยให้บริการค่ะ</p>
                            <Button 
                            type="button" 
                            color="default"
                            onClick={() => window.open(line_url)}
                            >
                                <FontAwesomeIcon icon={faComments} style={{marginRight:15}} />คลิก ติดต่อผู้ช่วย
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={tabActive === 3 ? 'show' : ''}>
                    <Consignment_Tabs3 
                    user={userData}
                    value={consignData}
                    check={acceptChecked}
                    onCheckbox={(val) => _handlerChecked(val)}
                    />
                    <div className="btn-content">
                        <Button type="button" color="next" onClick={() => prev()}><FontAwesomeIcon icon={faChevronLeft} style={{marginRight:10}} /> ย้อนกลับ</Button>
                        {loading ? 
                            <Button type="button" color="default-sm" style={{border: '2px solid #EF9751'}}>
                                Loading... <Spinner size='sm' className="ml-10" />
                            </Button>
                        :
                            <Button type="button" color="default-sm" style={{border: '2px solid #EF9751'}} onClick={() => onSubmit()}>
                                ส่งข้อมูล <FontAwesomeIcon icon={faChevronRight} style={{marginLeft:10}} />
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
        <Footer />

        <Modal isOpen={toggleFail} toggle={() => setToggleFail(false)}>
            <ModalHeader toggle={() => setToggleFail(false)}></ModalHeader>
            <ModalBody>
                <Row>
                    <Col className="col-12 col-md-12 col-lg-12">
                    <div className="text-error-box">
                        <ul className="error-list">
                            {!consignData.titledeed_Number && <li>กรุณากรอกข้อมูลเลขที่โฉนด (หากมีมากกว่า 1 โฉนด ให้ใช้เครื่องหมาย "," คั่น)</li> }
                            {!consignData.province && <li>กรุณาเลือกจังหวัด</li>}
                            {!consignData.district && <li>กรุณาเลือกอำเภอ</li>}
                            {!(consignData.rai || consignData.ngan || consignData.wa) && <li>กรุณากรอกขนาดที่ดิน เช่น 1 ไร่ 0 งาน 0 ตารางวา</li>}
                            {!consignData.pricePerWa && <li>กรุณากรอกราคาต่อตารางวา</li>}
                            {!consignData.totalPrice && <li>กรุณากรอกราคารวมที่ดิน</li>}
                            {!consignData.title && <li>กรุณากรอกหัวข้อ</li>}
                            {consignData.year === 0 && <li>กรุณาเลือกระยะเวลาที่ต้องการ จำนอง / ขายฝาก</li> }
                        </ul>
                    </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button 
                color="transparent" 
                onClick={() => setToggleFail(false)}
                >
                    ปิด
                </Button>
            </ModalFooter>
        </Modal>

        <Modal isOpen={toggleFail2} toggle={() => setToggleFail2(false)}>
            <ModalHeader toggle={() => setToggleFail2(false)}></ModalHeader>
            <ModalBody>
                <Row>
                    <Col className="col-12 col-md-12 col-lg-12">
                    <div className="text-error-box">
                        <ul className="error-list">
                            {(consignData.images.length === 0) && <li>กรุณาเพิ่มรูปภาพ อย่างน้อย 1 ภาพ แต่ไม่เกิน 8 ภาพ</li> }
                        </ul>
                    </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button 
                color="transparent" 
                onClick={() => setToggleFail2(false)}
                >
                    ปิด
                </Button>
            </ModalFooter>
        </Modal>
        <LoginModal isOpen={loginOpen} />
        </>
    )
}

export default ConsignmentMn;