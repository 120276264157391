import React from "react";
import logo from 'assets/img/taladteedin-logo.png';
// import Footer from "components/footer";
import FooterCustom from "components/footerCustom";
import { 
    Badge, 
    Button, 
    Card,
    CardBody,
    CardImg,
    CardText,
    CardTitle,
    CardFooter,
    Row,
    Col,
    FormGroup,
    Input
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faLocationDot, 
    faHouse,
    faBuilding,
    faHotel,
    faIndustry,
    faCircleInfo,
    faThumbTack,
    faBullhorn,
    faMapLocationDot,
    faCity,
    faPhone,
    faCog
} from '@fortawesome/free-solid-svg-icons';
import {
    faFacebookF,
    faLine
} from '@fortawesome/free-brands-svg-icons';
import Carousel from "react-multi-carousel";
import blank_img from 'assets/img/blank-profile.png';
import { TikTok } from "react-tiktok";

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
};

function Exemples(){
    // const [fetchData, setFetchData] = React.useState(null);
    const [color, setColor] = React.useState('#48C9B0');
    const [classes, setClasses] = React.useState("dropdown");
    const handleClick = () => {
        if (classes === "dropdown") {
        setClasses("dropdown show");
        } else {
        setClasses("dropdown");
        }
    };

    var bodyStyles = document.body;
    bodyStyles.id = "style-2"
    bodyStyles.style.setProperty('--color-custom', color);

    return(
        <>
        <div className="page-container">
            <nav className="navbar">
                <img alt="" src={logo} style={{height: 50}} />
            </nav>
            <div className="page-wrapper-body">
                <div className="page-banner">
                    <img 
                    alt="" 
                    src="https://taladteedin.sgp1.digitaloceanspaces.com/landmarket-prod/banner/anthonyb5POxb2aL9o.jpeg" 
                    />
                    <span className="text-title">
                        <p className="title"> <div>Exemple Property</div></p>
                        <p className="sub-title"><div>powered by TaladTeedin</div></p>
                    </span>
                    <div className="property-box">
                        <div className="property-wrapper">
                            <Button 
                            color="default"
                            style={{
                                backgroundColor: color,
                                marginLeft: 10,
                                marginRight: 10,
                                position:'relative'
                            }}
                            >
                                ทั้งหมด
                                <Badge 
                                pill
                                style={{
                                    border: '2px solid white',
                                    position:'absolute',
                                    top: -10,
                                    right: -15,
                                    backgroundColor: color
                                }}
                                >
                                    100
                                </Badge>
                            </Button>
                            <Button 
                            color="default"
                            style={{
                                backgroundColor: '#fff',
                                marginLeft: 10,
                                marginRight: 10,
                                position:'relative',
                                color: 'black'
                            }}
                            >
                                <FontAwesomeIcon icon={faLocationDot} className="mr-10" />
                                ที่ดิน
                                <Badge 
                                pill
                                style={{
                                    border: '2px solid white',
                                    position:'absolute',
                                    top: -10,
                                    right: -15,
                                    backgroundColor: color
                                }}
                                >
                                    20
                                </Badge>
                            </Button>
                            <Button 
                            color="default"
                            style={{
                                backgroundColor: '#fff',
                                marginLeft: 10,
                                marginRight: 10,
                                position:'relative',
                                color: 'black'
                            }}
                            >
                                <FontAwesomeIcon icon={faHouse} className="mr-10" />
                                บ้าน
                                {/* <Badge 
                                pill
                                style={{
                                    border: '2px solid white',
                                    position:'absolute',
                                    top: -10,
                                    right: -15,
                                    backgroundColor: '#EF9751'
                                }}
                                >
                                    20
                                </Badge> */}
                            </Button>
                            <Button 
                            color="default"
                            style={{
                                backgroundColor: '#fff',
                                marginLeft: 10,
                                marginRight: 10,
                                position:'relative',
                                color: 'black'
                            }}
                            >
                                <FontAwesomeIcon icon={faBuilding} className="mr-10" />
                                อาคารพาณิชย์
                                {/* <Badge 
                                pill
                                style={{
                                    border: '2px solid white',
                                    position:'absolute',
                                    top: -10,
                                    right: -15,
                                    backgroundColor: '#EF9751'
                                }}
                                >
                                    20
                                </Badge> */}
                            </Button>
                            <Button 
                            color="default"
                            style={{
                                backgroundColor: '#fff',
                                marginLeft: 10,
                                marginRight: 10,
                                position:'relative',
                                color: 'black'
                            }}
                            >
                                <FontAwesomeIcon icon={faHotel} className="mr-10" />
                                โรงแรม/รีสอร์ท
                                {/* <Badge 
                                pill
                                style={{
                                    border: '2px solid white',
                                    position:'absolute',
                                    top: -10,
                                    right: -15,
                                    backgroundColor: '#EF9751'
                                }}
                                >
                                    20
                                </Badge> */}
                            </Button>
                            <Button 
                            color="default"
                            style={{
                                backgroundColor: '#fff',
                                marginLeft: 10,
                                marginRight: 10,
                                position:'relative',
                                color: 'black'
                            }}
                            >
                                <FontAwesomeIcon icon={faIndustry} className="mr-10" />
                                โรงงาน/โกดัง
                                {/* <Badge 
                                pill
                                style={{
                                    border: '2px solid white',
                                    position:'absolute',
                                    top: -10,
                                    right: -15,
                                    backgroundColor: '#EF9751'
                                }}
                                >
                                    20
                                </Badge> */}
                            </Button>
                            <Button 
                            color="default"
                            style={{
                                backgroundColor: '#fff',
                                marginLeft: 10,
                                marginRight: 10,
                                position:'relative',
                                color: 'black'
                            }}
                            >
                                <FontAwesomeIcon icon={faCircleInfo} className="mr-10" />
                                อื่นๆ
                                {/* <Badge 
                                pill
                                style={{
                                    border: '2px solid white',
                                    position:'absolute',
                                    top: -10,
                                    right: -15,
                                    backgroundColor: '#EF9751'
                                }}
                                >
                                    20
                                </Badge> */}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="page-property">
                    <Row className="row-cols-2 row-cols-md-4">
                        <Col className="mb-10">
                            <div className="property-item" style={{backgroundColor:'#000'}}>
                                <label className="label-1" style={{color:'#fff'}}>รวมประกาศขาย</label>
                                <label className="label-2" style={{color:'#fff'}}>ทรัพย์</label>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faBullhorn} />
                                </div>
                                <p className="text-number" style={{color: color}}>60</p>
                            </div>
                        </Col>
                        <Col className="mb-10">
                            <div className="property-item" style={{backgroundColor:'#F2F3F4'}}>
                                <label className="label-1" style={{color:'#000'}}>ที่ดินทั้งหมด</label>
                                <label className="label-2" style={{color:'#000'}}>ทรัพย์</label>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faMapLocationDot} />
                                </div>
                                <p className="text-number" style={{color: color}}>20</p>
                            </div>
                        </Col>
                        <Col className="mb-10">
                            <div className="property-item" style={{backgroundColor:'#F2F3F4'}}>
                                <label className="label-1" style={{color:'#000'}}>บ้านทั้งหมด</label>
                                <label className="label-2" style={{color:'#000'}}>ทรัพย์</label>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faHouse} />
                                </div>
                                <p className="text-number" style={{color: color}}>30</p>
                            </div>
                        </Col>
                        <Col className="mb-10">
                            <div className="property-item" style={{backgroundColor:'#F2F3F4'}}>
                                <label className="label-1" style={{color:'#000'}}>อสังหาฯอื่นๆ</label>
                                <label className="label-2" style={{color:'#000'}}>ทรัพย์</label>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faCity} />
                                </div>
                                <p className="text-number" style={{color: color}}>20</p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="page-body-1">
                    <p className="title mb-10">
                        <FontAwesomeIcon icon={faThumbTack} className="mr-20 icon" />
                        ปักหมุดแนะนำ
                    </p>
                    <Carousel 
                    responsive={responsive}
                    infinite={false}
                    autoPlay={false}
                    ssr={true}
                    shouldResetAutoplay={false}
                    containerClass="carousel-container mb-30"
                    itemClass="carousel-item"
                    >
                        <Card>
                            <div className="card-tag">
                                <div className="tag">
                                    {/* {item?.saleType === "OWNER" ?
                                    <div className="tag-1 bg_default color-white mt-10">
                                        เจ้าของขายเอง
                                    </div>
                                    :
                                    null} */}
                                    {/* {item.quick ?  */}
                                    <div className="tag-2 bg_redpink color-white mt-10">
                                        ขายด่วน
                                    </div>
                                    {/* : null} */}
                                </div>
                                <CardImg 
                                alt=""
                                src="https://taladteedin-test.sgp1.digitaloceanspaces.com/files/b8951dbf-2852-4ec7-a3a0-bdcee27df964"
                                />
                            </div>
                            <CardBody>
                                <div className="body-1">
                                    <CardTitle>
                                        ขายที่ดินเชียงราย ใกล้สถานีรถไฟเชียงราย 16-1-8
                                    </CardTitle>
                                    <CardText>
                                        ที่ดินเปล่าแปลงสวย บรรยากาศดี เดินทางง่าย ใกล้แหล่งอำนวยความสะดวก สาธารณูปโภคเข้าถึง ขายไร่ละ 4 ล้าน
                                    </CardText>
                                </div>
                                <div className="body-2">
                                    <CardText>฿ 65,080,000</CardText>
                                    <CardText style={{color: color}}>฿ 10,000/ตร.ว.</CardText>
                                </div>
                                <CardText>
                                    <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                    เวียงชัย, เชียงราย
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card>
                            <div className="card-tag">
                                <div className="tag">
                                    {/* {item?.saleType === "OWNER" ?
                                    <div className="tag-1 bg_default color-white mt-10">
                                        เจ้าของขายเอง
                                    </div>
                                    :
                                    null} */}
                                    {/* {item.quick ?  */}
                                    <div className="tag-2 bg_redpink color-white mt-10">
                                        ขายด่วน
                                    </div>
                                    {/* : null} */}
                                </div>
                                <CardImg 
                                alt=""
                                src="https://taladteedin-test.sgp1.digitaloceanspaces.com/files/b8951dbf-2852-4ec7-a3a0-bdcee27df964"
                                />
                            </div>
                            <CardBody>
                                <div className="body-1">
                                    <CardTitle>
                                        ขายที่ดินเชียงราย ใกล้สถานีรถไฟเชียงราย 16-1-8
                                    </CardTitle>
                                    <CardText>
                                        ที่ดินเปล่าแปลงสวย บรรยากาศดี เดินทางง่าย ใกล้แหล่งอำนวยความสะดวก สาธารณูปโภคเข้าถึง ขายไร่ละ 4 ล้าน
                                    </CardText>
                                </div>
                                <div className="body-2">
                                    <CardText>฿ 65,080,000</CardText>
                                    <CardText style={{color: color}}>฿ 10,000/ตร.ว.</CardText>
                                </div>
                                <CardText>
                                    <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                    เวียงชัย, เชียงราย
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card>
                            <div className="card-tag">
                                <div className="tag">
                                    {/* {item?.saleType === "OWNER" ?
                                    <div className="tag-1 bg_default color-white mt-10">
                                        เจ้าของขายเอง
                                    </div>
                                    :
                                    null} */}
                                    {/* {item.quick ?  */}
                                    <div className="tag-2 bg_redpink color-white mt-10">
                                        ขายด่วน
                                    </div>
                                    {/* : null} */}
                                </div>
                                <CardImg 
                                alt=""
                                src="https://taladteedin-test.sgp1.digitaloceanspaces.com/files/b8951dbf-2852-4ec7-a3a0-bdcee27df964"
                                />
                            </div>
                            <CardBody>
                                <div className="body-1">
                                    <CardTitle>
                                        ขายที่ดินเชียงราย ใกล้สถานีรถไฟเชียงราย 16-1-8
                                    </CardTitle>
                                    <CardText>
                                        ที่ดินเปล่าแปลงสวย บรรยากาศดี เดินทางง่าย ใกล้แหล่งอำนวยความสะดวก สาธารณูปโภคเข้าถึง ขายไร่ละ 4 ล้าน
                                    </CardText>
                                </div>
                                <div className="body-2">
                                    <CardText>฿ 65,080,000</CardText>
                                    <CardText style={{color: color}}>฿ 10,000/ตร.ว.</CardText>
                                </div>
                                <CardText>
                                    <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                    เวียงชัย, เชียงราย
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card>
                            <div className="card-tag">
                                <div className="tag">
                                    {/* {item?.saleType === "OWNER" ?
                                    <div className="tag-1 bg_default color-white mt-10">
                                        เจ้าของขายเอง
                                    </div>
                                    :
                                    null} */}
                                    {/* {item.quick ?  */}
                                    <div className="tag-2 bg_redpink color-white mt-10">
                                        ขายด่วน
                                    </div>
                                    {/* : null} */}
                                </div>
                                <CardImg 
                                alt=""
                                src="https://taladteedin-test.sgp1.digitaloceanspaces.com/files/b8951dbf-2852-4ec7-a3a0-bdcee27df964"
                                />
                            </div>
                            <CardBody>
                                <div className="body-1">
                                    <CardTitle>
                                        ขายที่ดินเชียงราย ใกล้สถานีรถไฟเชียงราย 16-1-8
                                    </CardTitle>
                                    <CardText>
                                        ที่ดินเปล่าแปลงสวย บรรยากาศดี เดินทางง่าย ใกล้แหล่งอำนวยความสะดวก สาธารณูปโภคเข้าถึง ขายไร่ละ 4 ล้าน
                                    </CardText>
                                </div>
                                <div className="body-2">
                                    <CardText>฿ 65,080,000</CardText>
                                    <CardText style={{color: color}}>฿ 10,000/ตร.ว.</CardText>
                                </div>
                                <CardText>
                                    <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                    เวียงชัย, เชียงราย
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card>
                            <div className="card-tag">
                                <div className="tag">
                                    {/* {item?.saleType === "OWNER" ?
                                    <div className="tag-1 bg_default color-white mt-10">
                                        เจ้าของขายเอง
                                    </div>
                                    :
                                    null} */}
                                    {/* {item.quick ?  */}
                                    <div className="tag-2 bg_redpink color-white mt-10">
                                        ขายด่วน
                                    </div>
                                    {/* : null} */}
                                </div>
                                <CardImg 
                                alt=""
                                src="https://taladteedin-test.sgp1.digitaloceanspaces.com/files/b8951dbf-2852-4ec7-a3a0-bdcee27df964"
                                />
                            </div>
                            <CardBody>
                                <div className="body-1">
                                    <CardTitle>
                                        ขายที่ดินเชียงราย ใกล้สถานีรถไฟเชียงราย 16-1-8
                                    </CardTitle>
                                    <CardText>
                                        ที่ดินเปล่าแปลงสวย บรรยากาศดี เดินทางง่าย ใกล้แหล่งอำนวยความสะดวก สาธารณูปโภคเข้าถึง ขายไร่ละ 4 ล้าน
                                    </CardText>
                                </div>
                                <div className="body-2">
                                    <CardText>฿ 65,080,000</CardText>
                                    <CardText style={{color: color}}>฿ 10,000/ตร.ว.</CardText>
                                </div>
                                <CardText>
                                    <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                    เวียงชัย, เชียงราย
                                </CardText>
                            </CardBody>
                        </Card>
                    </Carousel>
                </div>
                <div className="page-body-2">
                    <p className="title mb-10">
                        รายการประกาศทั้งหมด
                    </p>
                    <Carousel 
                    responsive={responsive}
                    infinite={false}
                    autoPlay={false}
                    ssr={true}
                    shouldResetAutoplay={false}
                    containerClass="carousel-container mb-30"
                    itemClass="carousel-item"
                    >
                        <Card>
                            <div className="card-tag">
                                <div className="tag">
                                    {/* {item?.saleType === "OWNER" ?
                                    <div className="tag-1 bg_default color-white mt-10">
                                        เจ้าของขายเอง
                                    </div>
                                    :
                                    null} */}
                                    {/* {item.quick ?  */}
                                    <div className="tag-2 bg_redpink color-white mt-10">
                                        ขายด่วน
                                    </div>
                                    {/* : null} */}
                                </div>
                                <CardImg 
                                alt=""
                                src="https://taladteedin-test.sgp1.digitaloceanspaces.com/files/b8951dbf-2852-4ec7-a3a0-bdcee27df964"
                                />
                            </div>
                            <CardBody>
                                <div className="body-1">
                                    <CardTitle>
                                        ขายที่ดินเชียงราย ใกล้สถานีรถไฟเชียงราย 16-1-8
                                    </CardTitle>
                                    <CardText>
                                        ที่ดินเปล่าแปลงสวย บรรยากาศดี เดินทางง่าย ใกล้แหล่งอำนวยความสะดวก สาธารณูปโภคเข้าถึง ขายไร่ละ 4 ล้าน
                                    </CardText>
                                </div>
                                <div className="body-2">
                                    <CardText>฿ 65,080,000</CardText>
                                    <CardText style={{color: color}}>฿ 10,000/ตร.ว.</CardText>
                                </div>
                                <CardText>
                                    <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                    เวียงชัย, เชียงราย
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card>
                            <div className="card-tag">
                                <div className="tag">
                                    {/* {item?.saleType === "OWNER" ?
                                    <div className="tag-1 bg_default color-white mt-10">
                                        เจ้าของขายเอง
                                    </div>
                                    :
                                    null} */}
                                    {/* {item.quick ?  */}
                                    <div className="tag-2 bg_redpink color-white mt-10">
                                        ขายด่วน
                                    </div>
                                    {/* : null} */}
                                </div>
                                <CardImg 
                                alt=""
                                src="https://taladteedin-test.sgp1.digitaloceanspaces.com/files/b8951dbf-2852-4ec7-a3a0-bdcee27df964"
                                />
                            </div>
                            <CardBody>
                                <div className="body-1">
                                    <CardTitle>
                                        ขายที่ดินเชียงราย ใกล้สถานีรถไฟเชียงราย 16-1-8
                                    </CardTitle>
                                    <CardText>
                                        ที่ดินเปล่าแปลงสวย บรรยากาศดี เดินทางง่าย ใกล้แหล่งอำนวยความสะดวก สาธารณูปโภคเข้าถึง ขายไร่ละ 4 ล้าน
                                    </CardText>
                                </div>
                                <div className="body-2">
                                    <CardText>฿ 65,080,000</CardText>
                                    <CardText style={{color: color}}>฿ 10,000/ตร.ว.</CardText>
                                </div>
                                <CardText>
                                    <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                    เวียงชัย, เชียงราย
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card>
                            <div className="card-tag">
                                <div className="tag">
                                    {/* {item?.saleType === "OWNER" ?
                                    <div className="tag-1 bg_default color-white mt-10">
                                        เจ้าของขายเอง
                                    </div>
                                    :
                                    null} */}
                                    {/* {item.quick ?  */}
                                    <div className="tag-2 bg_redpink color-white mt-10">
                                        ขายด่วน
                                    </div>
                                    {/* : null} */}
                                </div>
                                <CardImg 
                                alt=""
                                src="https://taladteedin-test.sgp1.digitaloceanspaces.com/files/b8951dbf-2852-4ec7-a3a0-bdcee27df964"
                                />
                            </div>
                            <CardBody>
                                <div className="body-1">
                                    <CardTitle>
                                        ขายที่ดินเชียงราย ใกล้สถานีรถไฟเชียงราย 16-1-8
                                    </CardTitle>
                                    <CardText>
                                        ที่ดินเปล่าแปลงสวย บรรยากาศดี เดินทางง่าย ใกล้แหล่งอำนวยความสะดวก สาธารณูปโภคเข้าถึง ขายไร่ละ 4 ล้าน
                                    </CardText>
                                </div>
                                <div className="body-2">
                                    <CardText>฿ 65,080,000</CardText>
                                    <CardText style={{color: color}}>฿ 10,000/ตร.ว.</CardText>
                                </div>
                                <CardText>
                                    <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                    เวียงชัย, เชียงราย
                                </CardText>
                            </CardBody>
                        </Card>
                        <Card>
                            <div className="card-tag">
                                <div className="tag">
                                    {/* {item?.saleType === "OWNER" ?
                                    <div className="tag-1 bg_default color-white mt-10">
                                        เจ้าของขายเอง
                                    </div>
                                    :
                                    null} */}
                                    {/* {item.quick ?  */}
                                    <div className="tag-2 bg_redpink color-white mt-10">
                                        ขายด่วน
                                    </div>
                                    {/* : null} */}
                                </div>
                                <CardImg 
                                alt=""
                                src="https://taladteedin-test.sgp1.digitaloceanspaces.com/files/b8951dbf-2852-4ec7-a3a0-bdcee27df964"
                                />
                            </div>
                            <CardBody>
                                <div className="body-1">
                                    <CardTitle>
                                        ขายที่ดินเชียงราย ใกล้สถานีรถไฟเชียงราย 16-1-8
                                    </CardTitle>
                                    <CardText>
                                        ที่ดินเปล่าแปลงสวย บรรยากาศดี เดินทางง่าย ใกล้แหล่งอำนวยความสะดวก สาธารณูปโภคเข้าถึง ขายไร่ละ 4 ล้าน
                                    </CardText>
                                </div>
                                <div className="body-2">
                                    <CardText>฿ 65,080,000</CardText>
                                    <CardText style={{color: color}}>฿ 10,000/ตร.ว.</CardText>
                                </div>
                                <CardText>
                                    <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                    เวียงชัย, เชียงราย
                                </CardText>
                            </CardBody>
                        </Card>
                    </Carousel>
                </div>
                <div className="page-body-3">
                    <p className="title mb-10">
                        ประกาศรับซื้อ
                    </p>
                    <Row className="row-cols-1 row-cols-md-4 row-cols-lg-4 mb-30">
                        <Col className="mb-10">
                            <Card >
                                <CardBody>
                                    <div className="header mb-10">
                                        <img alt="userImg" src={blank_img} className="user-img" />
                                        <p className="name">ทดสอบ ทดสอบ</p>
                                        <p className="oversize" style={{color: color}}>ต้องการ</p>
                                        <div className="text-wrapper" style={{backgroundColor: color}}>
                                            <FontAwesomeIcon icon={faLocationDot} className="mr-10 icon" />
                                            <div className="label">
                                                <p className="label-1">
                                                    จ.เชียงใหม่
                                                </p>
                                                <p className="label-2">
                                                    อ.เมืองเชียงใหม่
                                                </p>
                                            </div>
                                        </div>
                                        <small className="date">
                                            10 ม.ค 67
                                        </small>
                                    </div>
                                    
                                    <div className="text-description">
                                        <div className="font-weight-500 color-default mb-10" style={{color: color}}>
                                            วัตถุประสงค์: 
                                            <p className="font-weight-300 ml-10 color-black" >สร้างบ้าน</p>
                                        </div>
                                        <div className="flex font-weight-500 color-default mb-10" style={{color: color}}>
                                            ขนาดเนื้อที่: <div className="font-weight-300 ml-10 color-black">200 ตร.ว. - 400 ตร.ว.</div>
                                        </div>
                                        <div className="flex font-weight-500 color-default " style={{color: color}}>
                                            ราคารวม: 
                                        </div>
                                        <div className="color-black mb-10">
                                            ฿0 - ฿2,000,000
                                        </div>
                                        <div className="flex font-weight-500 color-default" style={{color: color}}>
                                            รายละเอียด: 
                                        </div>
                                        <div className="text-detail color-black">
                                            หรือหากมีใกล้เคียงและติดถนนสามารถเสนอได้
                                        </div>
                                    </div>
                                </CardBody>
                                <CardFooter className="flex-center">
                                    <Button 
                                    color="blue"
                                    style={{
                                        borderColor: color,
                                        color: color
                                    }}
                                    >
                                        เสนอขาย
                                    </Button>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col className="mb-10">
                            <Card >
                                <CardBody>
                                    <div className="header mb-10">
                                        <img alt="userImg" src={blank_img} className="user-img" />
                                        <p className="name">ทดสอบ ทดสอบ</p>
                                        <p className="oversize" style={{color: color}}>ต้องการ</p>
                                        <div className="text-wrapper" style={{backgroundColor: color}}>
                                            <FontAwesomeIcon icon={faLocationDot} className="mr-10 icon" />
                                            <div className="label">
                                                <p className="label-1">
                                                    จ.เชียงใหม่
                                                </p>
                                                <p className="label-2">
                                                    อ.เมืองเชียงใหม่
                                                </p>
                                            </div>
                                        </div>
                                        <small className="date">
                                            10 ม.ค 67
                                        </small>
                                    </div>
                                    
                                    <div className="text-description">
                                        <div className="font-weight-500 color-default mb-10" style={{color: color}}>
                                            วัตถุประสงค์: 
                                            <p className="font-weight-300 ml-10 color-black" >สร้างบ้าน</p>
                                        </div>
                                        <div className="flex font-weight-500 color-default mb-10" style={{color: color}}>
                                            ขนาดเนื้อที่: <div className="font-weight-300 ml-10 color-black">200 ตร.ว. - 400 ตร.ว.</div>
                                        </div>
                                        <div className="flex font-weight-500 color-default " style={{color: color}}>
                                            ราคารวม: 
                                        </div>
                                        <div className="color-black mb-10">
                                            ฿0 - ฿2,000,000
                                        </div>
                                        <div className="flex font-weight-500 color-default" style={{color: color}}>
                                            รายละเอียด: 
                                        </div>
                                        <div className="text-detail color-black">
                                            หรือหากมีใกล้เคียงและติดถนนสามารถเสนอได้
                                        </div>
                                    </div>
                                </CardBody>
                                <CardFooter className="flex-center">
                                    <Button 
                                    color="blue"
                                    style={{
                                        borderColor: color,
                                        color: color
                                    }}
                                    >
                                        เสนอขาย
                                    </Button>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col className="mb-10">
                            <Card >
                                <CardBody>
                                    <div className="header mb-10">
                                        <img alt="userImg" src={blank_img} className="user-img" />
                                        <p className="name">ทดสอบ ทดสอบ</p>
                                        <p className="oversize" style={{color: color}}>ต้องการ</p>
                                        <div className="text-wrapper" style={{backgroundColor: color}}>
                                            <FontAwesomeIcon icon={faLocationDot} className="mr-10 icon" />
                                            <div className="label">
                                                <p className="label-1">
                                                    จ.เชียงใหม่
                                                </p>
                                                <p className="label-2">
                                                    อ.เมืองเชียงใหม่
                                                </p>
                                            </div>
                                        </div>
                                        <small className="date">
                                            10 ม.ค 67
                                        </small>
                                    </div>
                                    
                                    <div className="text-description">
                                        <div className="font-weight-500 color-default mb-10" style={{color: color}}>
                                            วัตถุประสงค์: 
                                            <p className="font-weight-300 ml-10 color-black" >สร้างบ้าน</p>
                                        </div>
                                        <div className="flex font-weight-500 color-default mb-10" style={{color: color}}>
                                            ขนาดเนื้อที่: <div className="font-weight-300 ml-10 color-black">200 ตร.ว. - 400 ตร.ว.</div>
                                        </div>
                                        <div className="flex font-weight-500 color-default " style={{color: color}}>
                                            ราคารวม: 
                                        </div>
                                        <div className="color-black mb-10">
                                            ฿0 - ฿2,000,000
                                        </div>
                                        <div className="flex font-weight-500 color-default" style={{color: color}}>
                                            รายละเอียด: 
                                        </div>
                                        <div className="text-detail color-black">
                                            หรือหากมีใกล้เคียงและติดถนนสามารถเสนอได้
                                        </div>
                                    </div>
                                </CardBody>
                                <CardFooter className="flex-center">
                                    <Button 
                                    color="blue"
                                    style={{
                                        borderColor: color,
                                        color: color
                                    }}
                                    >
                                        เสนอขาย
                                    </Button>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col className="mb-10">
                            <Card >
                                <CardBody>
                                    <div className="header mb-10">
                                        <img alt="userImg" src={blank_img} className="user-img" />
                                        <p className="name">ทดสอบ ทดสอบ</p>
                                        <p className="oversize" style={{color: color}}>ต้องการ</p>
                                        <div className="text-wrapper" style={{backgroundColor: color}}>
                                            <FontAwesomeIcon icon={faLocationDot} className="mr-10 icon" />
                                            <div className="label">
                                                <p className="label-1">
                                                    จ.เชียงใหม่
                                                </p>
                                                <p className="label-2">
                                                    อ.เมืองเชียงใหม่
                                                </p>
                                            </div>
                                        </div>
                                        <small className="date">
                                            10 ม.ค 67
                                        </small>
                                    </div>
                                    
                                    <div className="text-description">
                                        <div className="font-weight-500 color-default mb-10" style={{color: color}}>
                                            วัตถุประสงค์: 
                                            <p className="font-weight-300 ml-10 color-black" >สร้างบ้าน</p>
                                        </div>
                                        <div className="flex font-weight-500 color-default mb-10" style={{color: color}}>
                                            ขนาดเนื้อที่: <div className="font-weight-300 ml-10 color-black">200 ตร.ว. - 400 ตร.ว.</div>
                                        </div>
                                        <div className="flex font-weight-500 color-default " style={{color: color}}>
                                            ราคารวม: 
                                        </div>
                                        <div className="color-black mb-10">
                                            ฿0 - ฿2,000,000
                                        </div>
                                        <div className="flex font-weight-500 color-default" style={{color: color}}>
                                            รายละเอียด: 
                                        </div>
                                        <div className="text-detail color-black">
                                            หรือหากมีใกล้เคียงและติดถนนสามารถเสนอได้
                                        </div>
                                    </div>
                                </CardBody>
                                <CardFooter className="flex-center">
                                    <Button 
                                    color="blue"
                                    style={{
                                        borderColor: color,
                                        color: color
                                    }}
                                    >
                                        เสนอขาย
                                    </Button>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <Button
                        color="default"
                        style={{
                            backgroundColor: color,
                            color: 'white'
                        }}
                        >
                            ดูกระกาศทั้งหมด
                        </Button>
                    </div>
                </div>
                <div className="page-body-4">
                    <div className="box-1">
                        <div className="subBox">
                            <div className="decorate-1"></div>
                            <div className="decorate-2"></div>
                            <img alt="" src={blank_img} className="profile-img" />
                        </div>
                    </div>
                    <div className="box-2">
                        <div className="subBox">
                            <p style={{fontSize: '3em', fontWeight: 500}}>ทดสอบ ทดสอบ</p>
                            <h5 style={{marginBottom: 30}}>
                                นายหน้าอสังหาริมทรัพย์
                            </h5>
                            <p>
                                นายหน้าอสังหาริมทรัพย์ มีทรัพย์สินหลายแปลงและพร้อมตามหาที่ดินตามที่คุณต้องการ
                            </p>
                        </div>
                        <div className="subBox-2">
                            <a 
                            href="tel:020955198"
                            className="btn btn-default mr-10" 
                            style={{
                                backgroundColor: color
                            }}
                            >
                                <FontAwesomeIcon icon={faPhone} className="mr-10" />
                                020-955-198
                            </a>
                            <a 
                            href="tel:020955198"
                            className="btn btn-default mr-10" 
                            style={{
                                backgroundColor: color
                            }}
                            >
                                <FontAwesomeIcon icon={faFacebookF} />
                            </a>
                            <a 
                            href="tel:020955198"
                            className="btn btn-default mr-10" 
                            style={{
                                backgroundColor: color
                            }}
                            >
                                <FontAwesomeIcon icon={faLine} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="page-body-5">
                    <p className="title mb-10">
                        TikTok Content
                    </p>
                    <div className="tiktok-container">
                        <div className={`tiktok_item tiktok_item-1`}>
                            <TikTok 
                            url="https://www.tiktok.com/@taladteedin/video/7218177878406597914"
                            />
                        </div>
                        <div className={`tiktok_item tiktok_item-2`}>
                            <TikTok 
                            url="https://www.tiktok.com/@taladteedin/video/7299402111689739525"
                            />
                        </div>
                        <div className={`tiktok_item tiktok_item-3`}>
                            <TikTok 
                            url="https://www.tiktok.com/@taladteedin/video/7304586274608778503"
                            />
                        </div>
                    </div>
                </div>
                <div className="page-body-6">
                    <p style={{color: color}} className="title mb-10">
                        บทความน่าสนใจ
                        <p className="color-black">
                            อัพเดทข่าวสาร บทความที่ดิน วงการอสังหาริมทรัพย์
                        </p>
                    </p>
                    <div className="image-container">
                        <div className={`gallery__item gallery_item-1`}>
                            <img src="https://taladteedin-test.sgp1.digitaloceanspaces.com/contents/0717bd96-7df7-484f-bd33-89504e8f4dd0" className="gallery__img" alt="Image 1" />
                            <div className="backdrop">
                                <p className="text">
                                    โฉนดครุฑแดง  คืออะไร ต่างจากเอกสารสิทธิ์อื่นยังไง
                                </p>
                            </div>
                        </div>
                        <div className={`gallery__item gallery_item-2`}>
                            <img src="https://taladteedin-test.sgp1.digitaloceanspaces.com/contents/597424fc-9d3c-446f-8af6-55d95f042724" className="gallery__img" alt="Image 1" />
                            <div className="backdrop">
                                <p className="text">
                                ระวัง !! ถ้าไม่อยากปวดหัว  อย่าก่อสร้างโดยไม่มี BOQ
                                </p>
                            </div>
                        </div>
                        <div className={`gallery__item gallery_item-3`}>
                            <img src="https://taladteedin-test.sgp1.digitaloceanspaces.com/contents/038d5aeb-a8f8-4c53-9eac-4585a6621b14" className="gallery__img" alt="Image 1" />
                            <div className="backdrop">
                                <p className="text">
                                    ต้องรู้ก่อนทำ  “ขายฝากที่ดิน”  จะได้ไม่เสียรู้และเสียเปรียบ
                                </p>
                            </div>
                        </div>
                        <div className={`gallery__item gallery_item-4`}>
                            <img src="https://taladteedin-test.sgp1.digitaloceanspaces.com/contents/21027119-b172-4e9a-8cc5-0b23b38a8559" className="gallery__img" alt="Image 1" />
                            <div className="backdrop">
                                <p className="text">
                                    สัญญาจะซื้อจะขายที่ดิน ไม่เหมือนกับ สัญญาซื้อขายที่ดิน
                                </p>
                            </div>
                        </div>
                        <div className={`gallery__item gallery_item-5`}>
                            <img src="https://taladteedin-test.sgp1.digitaloceanspaces.com/contents/4b4995b8-0e64-4492-9e81-4e9711f93ad3" className="gallery__img" alt="Image 1" />
                            <div className="backdrop">
                                <p className="text">
                                    ต้องรู้  วิธีเขียนหนังสือมอบอำนาจที่ดิน ทด.21  ให้ผ่านฉลุย
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <Button
                        color="default"
                        style={{
                            backgroundColor: color,
                            color: 'white'
                        }}
                        >
                            ดูบทความทั้งหมด
                        </Button>
                    </div>
                </div>
            </div>
            <div className="fixed-plugin">
                <div className={classes}>
                    <div onClick={handleClick}>
                        <FontAwesomeIcon icon={faCog} className="fa-cog" />
                    </div>
                    <ul className="dropdown-menu show">
                        <li className="header-title">COLOR SELECTED</li>
                        <li className="adjustments-line">
                            <FormGroup className="form-group">
                                <Input 
                                type="color" 
                                style={{
                                    border:'1px solid #000'
                                }}
                                value={color} onChange={(val) => setColor(val.target.value)} 
                                />
                            </FormGroup>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <FooterCustom />
        </>
    )
}
export default Exemples;