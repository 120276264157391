import React from "react";

import Navbars from "routers/navbars";
import Footer from "components/footer";
import { Button, Col, Row } from "reactstrap";
import { faAddressCard, faClock } from "@fortawesome/free-regular-svg-icons";
import { 
    faStore, 
    faCartShopping, 
    faWallet, 
    faCircleXmark, 
    faBullhorn, 
    faMagnifyingGlassLocation,
    faBuilding,
    faPhone,
    faGlobe
} from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare, faYoutube, faTiktok, faLine } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import img_1_1 from 'assets/img/Help Center/1/Group 193.png';
import img_1_2 from 'assets/img/Help Center/1/Image 28.png';
import img_1_3 from 'assets/img/Help Center/1/Group 506.png';

import img_2_1 from 'assets/img/Help Center/2/Image 13.png';
import img_2_2 from 'assets/img/Help Center/2/Image 14.png';
import img_2_3 from 'assets/img/Help Center/2/Image 15.png';
import img_2_4 from 'assets/img/Help Center/2/Image 16.png';
import img_2_5 from 'assets/img/Help Center/2/Image 44.png';
import img_2_6 from 'assets/img/Help Center/2/Image 22.png';
import img_2_7 from 'assets/img/Help Center/2/Image 43.png';
import img_2_8 from 'assets/img/Help Center/2/Image 25.png';

import img_3_1 from 'assets/img/Help Center/5/Image 19.png';
import img_3_2 from 'assets/img/Help Center/5/Image 42.png';
import img_3_3 from 'assets/img/Help Center/5/Image 40.png';

import img_4_1 from 'assets/img/Help Center/6/Image 19.png';
import img_4_2 from 'assets/img/Help Center/6/Image 41.png';
import img_4_3 from 'assets/img/Help Center/6/Image 24.png';
import img_4_4 from 'assets/img/Help Center/6/Image 26.png';

const line_path = process.env.REACT_APP_LINE_PATH;

function HelpCenter(){
    const [active, setActive] = React.useState(null);
    const [active2, setActive2] = React.useState(null);

    return(
        <>
        <Navbars />
        <div className="help-contailner">
            <div className="help-text">
                <p className="title">Help Center</p>
                <div>
                    <Button 
                    color="default-sm" 
                    className="font-size-xl"
                    style={{border: '2px solid white'}}
                    >
                        ติดต่อผู้ช่วย
                    </Button>
                </div>
            </div>
        </div>
        <div className="help-detail-container">
            <p className="title">แนะนำวิธีการสำหรับผู้ประกาศขาย</p>
            <Row className="row-cols-2 row-cols-md-4">
                <Col className={active === 1 ? "tab active":"tab"} onClick={() => active === 1 ? setActive(null) : setActive(1)}>
                    <div className="tabs border">
                        <FontAwesomeIcon icon={faAddressCard} size={'4x'} style={{marginBottom:10}} />
                        <p>วิธีการลงทะเบียน</p>
                    </div>
                    
                </Col>
                <Col className={active === 2 ? "tab active":"tab"} onClick={() => active === 2 ? setActive(null) : setActive(2)}>
                    <div className="tabs border">
                        <FontAwesomeIcon icon={faStore} size={'4x'} style={{marginBottom:10}} />
                        <p>วิธีการลงประกาศขาย</p>
                    </div>
                </Col>
                <Col className="tab" onClick={() => window.open('/package')}>
                    <div className="tabs border">
                        <FontAwesomeIcon icon={faCartShopping} size={'4x'} style={{marginBottom:10}} />
                        <p>บริการเสริม</p>
                    </div>
                </Col>
                <Col className={active === 4 ? "tab active":"tab"} onClick={() => active === 4 ? setActive(null) : setActive(4)}>
                    <div className="tabs border">
                        <FontAwesomeIcon icon={faWallet} size={'4x'} style={{marginBottom:10}} />
                        <p>วิธีการชำระเงิน</p>
                    </div>
                </Col>
            </Row>
            {active ? 
            <div className="help-tab-wrapper">
                <div className="tab-close">
                    <FontAwesomeIcon icon={faCircleXmark} className="icon" onClick={() => setActive(null)} />
                </div>
                {active === 1 ?
                    <div className="tab-1">
                        <Row className="row-cols-1 row-cols-md-3 row-cols-lg-3">
                            <Col className="mb-20 ">
                                <img alt="img-1" src={img_1_1} className="img_detail" />
                                <label className="color-default">
                                    1.สามารถเลือกวิธีการเข้าสู่ระบบได้ทั้งล็อกอินผ่าน Facebook และ Line
                                </label>
                            </Col>
                            <Col className="mb-20">
                                <img alt="img-2" src={img_1_2} className="img_detail" />
                                <label className="color-default">
                                    2.หรือเลือกสมัครสมาชิก กรอกข้อมูลให้ครบ ยืนยันการสมัคร เป็นอันเรียบร้อย
                                </label>
                            </Col>
                            <Col className="mb-20">
                                <img alt="img-3" src={img_1_3} className="img_detail" />
                                <label className="color-default">
                                    3.เมื่อล็อกอินผ่านระบบไลน์หรือเฟสบุ๊ค ระบบจะทำการเข้าสู่ระบบให้โดยอัตโนมัติ
                                </label>
                            </Col>
                        </Row>
                    </div>
                : active === 2 ?
                    <div className="tab-2">
                        <Row className="row-cols-1 row-cols-md-4 row-cols-lg-4">
                            <Col className="mb-20">
                                <img alt="img-1" src={img_2_1} className="img_detail" />
                                <label className="color-default">
                                    1.หลังจากที่ทำการล็อกอินผ่าน Facebook หรือ Line เข้าสู่ระบบเรียบร้อยแล้ว ให้เลือกกดประกาศขายที่ดิน
                                </label>
                            </Col>
                            <Col className="mb-20">
                                <img alt="img-2" src={img_2_2} className="img_detail" />
                                <label className="color-default">
                                    2.พอเข้าหน้าประกาศขายแล้ว ให้ลูกค้าเลือกว่าเป็น "เจ้าของที่ดิน" หรือ "นายหน้า"และตรวจสอบชื่อผู้ลงขาย / เบอร์ติดต่อ / ID Line ว่าถูกต้องหรือไม่
                                </label>
                            </Col>
                            <Col className="mb-20">
                                <img alt="img-3" src={img_2_3} className="img_detail" />
                                <label className="color-default">
                                    3.เมื่อเสร็จขั้นตอนแรกแล้ว ให้ลูกค้าเลือก " ประเภททรัพย์ที่ต้องการขาย " และกรอกข้อมูลให้ครบถ้วน
                                </label>
                            </Col>
                            <Col className="mb-20">
                                <img alt="img-3" src={img_2_4} className="img_detail" />
                                <label className="color-default">
                                    4.เลือกเงื่อนไขราคา ณ กรมที่ดิน หากไม่เข้าใจหรือต้องการผู้ช่วยทางเว็บไซต์ของเรามีบริการผู้ช่วยให้กับลูกค้า
                                </label>
                            </Col>
                            <Col className="mb-20">
                                <img alt="img-3" src={img_2_5} className="img_detail" />
                                <label className="color-default">
                                    5.เลือกบริการเสริมเพื่อให้การขายทรัพย์ของคุณได้เปรียบกว่าแปลงอื่นๆ เมื่อเลือกบริการเสริมเสร็จแล้วให้กดขั้นตอนถัดไป เพื่อลงข้อมูลรูปภาพ
                                </label>
                            </Col>
                            <Col className="mb-20">
                                <img alt="img-3" src={img_2_6} className="img_detail" />
                                <label className="color-default">
                                    6.หลังจากกดปุ่มขั้นตอนถัดไปแล้ว ให้ลูกค้าใส่รูปที่ต้องการประกาศขายโดยรูปที่นำมาลงประกาศนั้นขนาดต้องไม่เกิน 1920x1080 px
                                </label>
                            </Col>
                            <Col className="mb-20">
                                <img alt="img-3" src={img_2_7} className="img_detail" />
                                <label className="color-default">
                                    7.ลงข้อมูลทุกอย่างครบถ้วนดีแล้ว ทางระบบจะให้ลูกค้าตรวจความถูกต้องถ้าหากข้อมูลถูกต้องแล้ว ระบบจะพาเข้าสู่หน้าชำระเงิน (เฉพาะลูกค้าที่เลือกซื้อบริการเสริม) เป็นอันประกาศขายเป็นที่เรียบร้อย
                                </label>
                            </Col>
                            <Col className="mb-20">
                                <img alt="img-3" src={img_2_8} className="img_detail" />
                                <label className="color-default">
                                    8.แล้วลูกค้าสามารถเข้ามาดูการประกาศรับซื้อทั้งหมดได้ เพื่อหาการประกาศรับซื้อที่ต้องตามที่ดินลักษณะใกล้เคียงกับทรัพย์ที่ท่านมีอยู่
                                </label>
                            </Col>
                        </Row>
                    </div>
                : active === 4 ?
                    <div className="tab-4">
                            
                    </div>
                :null}
            </div> 
            : null}
        </div>

        <div className="help-detail-container-2">
            <p className="title">แนะนำวิธีการสำหรับผู้ประกาศรับซื้อ</p>
            <Row className="row-cols-2 row-cols-md-4" style={{justifyContent:'center'}}>
                <Col className={active2 === 1 ? "tab active":"tab"} onClick={() => active2 === 1 ? setActive2(null) : setActive2(1)}>
                    <div className="tabs border">
                        <FontAwesomeIcon icon={faMagnifyingGlassLocation} size={'4x'} style={{marginBottom:10}} />
                        <p>วิธีการค้นหาทรัพย์</p>
                    </div>
                    
                </Col>
                <Col className={active2 === 2 ? "tab active":"tab"} onClick={() => active2 === 2 ? setActive2(null) : setActive2(2)}>
                    <div className="tabs border">
                        <FontAwesomeIcon icon={faBullhorn} size={'4x'} style={{marginBottom:10}} />
                        <p>วิธีการลงประกาศรับซื้อ</p>
                    </div>
                </Col>
            </Row>
            {active2 ? 
            <div className="help-tab-wrapper">
                <div className="tab-close">
                    <FontAwesomeIcon icon={faCircleXmark} className="icon" onClick={() => setActive2(null)} />
                </div>
                {active2 === 1 ?
                    <div className="tab-1">
                        <Row className="row-cols-1 row-cols-md-3 row-cols-lg-3">
                            <Col className="mb-20">
                                <img alt="img-1" src={img_3_1} className="img_detail" />
                                <label className="color-default">
                                    1.หลังจาก เข้าสู่ระบบเรียบร้อยแล้ว ให้เลือกกดปุ่มหาซื้อที่ดิน หรือ ค้นหาจากช่อง search bar 
                                </label>
                            </Col>
                            <Col className="mb-20">
                                <img alt="img-2" src={img_3_2} className="img_detail" />
                                <label className="color-default">
                                    2.หรือค้นหาที่ดินจากบนแผนที่ ในหน้า Full Map ซึ่งจะมีที่ดินมากมายโดยระบบจะอ้างอิงจากโลเคชั่นที่ท่านอยู่ ณ ขนาดนั้น
                                </label>
                            </Col>
                            <Col className="mb-20">
                                <img alt="img-3" src={img_3_3} className="img_detail" />
                                <label className="color-default">
                                    3.และเมื่อเข้ามาในหน้า Full map แล้วลูกค้าสามารถเลือกที่ดินบริเวณที่ต้องการได้เลยและทางแผนที่จะมีหมุดแสดงราคาที่ดินให้เปรียบเทียบกันได้ชัดเจน แต่ถ้าหากยังไม่เจอที่ดินที่ต้องการ ลูกค้าสามารถกดปุ่ม " คลิกเพื่อประกาศรับซื้อ " เพื่อลงประกาศรับซื้อที่ดินตามวัตถุประสงค์ที่ต้องการได้ 
                                </label>
                            </Col>
                        </Row>
                    </div>
                : active2 === 2 ?
                    <div className="tab-2">
                        <Row className="row-cols-1 row-cols-md-3 row-cols-lg-3">
                            <Col className="mb-20">
                                <img alt="img-1" src={img_4_1} className="img_detail" />
                                <label className="color-default">
                                    1.หลังจาก เข้าสู่ระบบเรียบร้อยแล้ว ให้เลือกกดปุ่มหาซื้อที่ดิน หรือ ค้นหาจากช่อง search bar
                                </label>
                            </Col>
                            <Col className="mb-20">
                                <img alt="img-2" src={img_4_2} className="img_detail" />
                                <label className="color-default">
                                    2.สามารถเข้าดูการประกาศที่ดินเบื้องต้นได้จากหน้า Homepage แต่ถ้าหากยังหาที่ดินไม่ถูกใจ สามารถเลือกกด "ดูการประกาศรับซื้อทั้งหมด"
                                </label>
                            </Col>
                            <Col className="mb-20">
                                <img alt="img-3" src={img_4_3} className="img_detail" />
                                <label className="color-default">
                                    3.แต่หากลูกค้าเลือกที่ "ประกาศรับซื้อที่ดิน/บ้าน" ทางระบบจะส่งเข้ามาในหน้าที่ประกาศรับซื้อที่ดินโดยอัตโนมัติลูกค้าสามารถกรอกข้อมูลของที่ดินที่ต้องการได้เลย
                                </label>
                            </Col>
                            <Col className="mb-20">
                                <img alt="img-3" src={img_4_4} className="img_detail" />
                                <label className="color-default">
                                    4.เมื่อลูกค้ากรอกข้อมูลในการค้นหาเสร็จแล้วเรียบร้อย ระบบจะให้ลูกค้าทวนข้อมูลในการประกาศอีกครั้ง หากมีทรัพย์ที่ตรงกับที่ลูกค้าได้ประกาศรับซื้อ ทางทีมงานจะติดต่อหาทันที
                                </label>
                            </Col>
                        </Row>
                    </div>
                :null}
            </div> 
            : null}
        </div>

        <div className="question-hit">
            <p className="title">คำถามยอดนิยม</p>
            <Row className="row-cols-1 row-cols-md-2 row-cols-lg-2">
                <Col className="vr mb-20">
                    <h5 style={{marginBottom: 20}}>ในส่วนของผู้ประกาศขาย</h5>
                    <div>
                        <p>Q: ลงไม่เป็นต้องทำยังไง ?</p>
                        <p>A: </p>
                        <p>Q: ไม่มีเลขโฉนด สามารถลงได้หรือไม่ ?</p>
                        <p>A: </p>
                        <p>Q: หาพิกัดที่ดินไม่ได้ ?</p>
                        <p>A: </p>
                        <p>Q: ลงประกาศขายแล้ว รออนุมัตินานไหม ประมาณกี่วัน ?</p>
                        <p>A: </p>
                    </div>
                </Col>
                <Col className="mb-20">
                    <h5 style={{marginBottom: 20}}>ในส่วนของผู้ประกาศรับซื้อ</h5>
                    <div>
                        <p>Q: หาที่ดินที่ต้องการไม่เจอ ต้องทำยังไง ?</p>
                        <p>A: </p>
                        <p>Q: ขั้นตอนการลงประกาศรับซื้อ ต้องทำยังไง ?</p>
                        <p>A: </p>
                        <p>Q: ทำไมดูข้อมูลผู้ประกาศไม่ได้ ?</p>
                        <p>A: </p>
                        <p>Q: ลงประกาศรับซื้อที่ดิน มีค่าใช้จ่ายหรือไม่ ?</p>
                        <p>A: </p>
                    </div>
                </Col>
            </Row>
        </div>
        <div className="company-info">
            <Row>
                <Col className="col-12 col-md-8 col-lg-8 mb-20">
                <iframe 
                title="TaladTeedin Map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d428.7550233031974!2d99.85895475608861!3d19.911591380827954!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30d7058ca84f73fd%3A0x6e523269e205b362!2z4LiV4Lil4Liy4LiU4LiX4Li14LmI4LiU4Li04LiZ!5e0!3m2!1sth!2sth!4v1659461761750!5m2!1sth!2sth" 
                className="map-info" 
                style={{border:0}} 
                allowFullScreen={true}
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"/>
                </Col>
                <Col className="col-12 col-md-4 col-lg-4 mb-20">
                    <div className="flex align-center mb-30 color-black">
                        <FontAwesomeIcon icon={faBuilding} size='2x'  />
                        <div className="ml-10">
                            <h5>บริษัท ตลาดที่ดินดอทคอม จำกัด</h5>
                            <p>35/91 หมู่ 22 อำเภอเมืองเชียงราย จังหวัดเชียงราย 57000</p>
                        </div>
                    </div>
                    <div className="flex align-center mb-30 color-black">
                        <FontAwesomeIcon icon={faPhone} size='2x'  />
                        <div className="ml-10">
                            <h5>ติดต่อ</h5>
                            <p>020-955-198</p>
                        </div>
                    </div>
                    <div className="flex align-center mb-30 color-black">
                        <FontAwesomeIcon icon={faClock} size='2x'  />
                        <div className="ml-10">
                            <h5>จันทร์ - เสาร์</h5>
                            <p>08 : 30 น. - 17 : 00 น.</p>
                        </div>
                    </div>
                    <div className="flex align-center mb-30 color-black">
                        <FontAwesomeIcon icon={faGlobe} size='2x'  />
                        <div className="ml-10">
                            <h5>ช่องทางติดตาม</h5>
                            <div className="flex">
                                <FontAwesomeIcon 
                                icon={faFacebookSquare} 
                                className="btn ml-10 mr-10 color-facebook" size="2x" 
                                onClick={() => window.open('https://www.facebook.com/profile.php?id=100063635147660')}
                                />
                                <FontAwesomeIcon 
                                icon={faTiktok} 
                                className="btn ml-10 mr-10 color-tiktok" 
                                size="2x" 
                                onClick={() => window.open('https://www.tiktok.com/@taladteedin')}
                                />
                                <FontAwesomeIcon 
                                icon={faYoutube} 
                                className="btn ml-10 mr-10 color-youtube" 
                                size="2x" 
                                onClick={() => window.open('https://www.youtube.com/channel/UCDDllnPTvKVQS44xb0rlLBw')}
                                />
                                <FontAwesomeIcon 
                                icon={faLine} 
                                className="btn ml-10 mr-10 color-line" 
                                size="2x" 
                                onClick={() => window.open(line_path)}
                                />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
        <Footer />
        </>
    )
}
export default HelpCenter