import { ErrorAlert, SuccessAlert } from "components/alert";
import React from "react";
import axios from "axios";
import { 
    Modal, 
    ModalBody, 
    ModalHeader, 
    ModalFooter, 
    Form,
    Label,
    Input,
    FormGroup,
    Button,
    Row,
    Col,
    Spinner
} from 'reactstrap';

const path_url = process.env.REACT_APP_API_CONNECT;

function ReportModal(props){
    const { 
        isOpen, 
        toggle, 
        province,
        titledeed,
        district
    } = props;

    const [reportData, setReportData] = React.useState({
        email: "",
        title: "",
        description: ""
    })
    const [loading, setLoading] = React.useState(false)

    const onChangeText = (val, prop) =>{
        setReportData((item) =>{
            const data = {...item}
            data[prop] = val;
            return {...data}
        })
    }

    const onSubmit = async (e) =>{
        e.preventDefault();
        setLoading(true)
        try{
            const res = await axios.post(path_url+'/report/addReport',{
                email: reportData.email,
                titleDeedNumber: titledeed,
                province: province,
                district: district,
                title: reportData.title,
                description: reportData.description,
            })

            if(res.status === 200){
                SuccessAlert();
                setLoading(false);
                setTimeout(() => {
                    window.location.reload()
                }, 1500);
            }
        }catch(e){
            setLoading(false);
            ErrorAlert({text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'})
        }
    }

    return(
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>รายงานข้อมูลไม่ถูกต้อง</ModalHeader>
            <Form onSubmit={onSubmit}>
                <ModalBody>
                    <Row>
                        <Col className="col-12 col-md-12 col-lg-12">
                            <FormGroup className="form-group">
                                <Label>Email</Label>
                                <Input 
                                placeholder="Email"
                                className="border"
                                value={reportData.email}
                                onChange={(val) => onChangeText(val.target.value, "email")}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12 col-md-12 col-lg-12">
                            <FormGroup className="form-group">
                                <Label>โฉนดที่ดิน</Label>
                                <Input 
                                placeholder="โฉนดที่ดิน"
                                className="border"
                                value={titledeed}
                                disabled
                                />
                            </FormGroup>
                        </Col>
                        <Col className="col-12 col-md-12 col-lg-12">
                            <FormGroup className="form-group">
                                <Label>อำเภอ</Label>
                                <Input 
                                placeholder="อำเภอ"
                                className="border"
                                value={district}
                                disabled
                                />
                            </FormGroup>
                        </Col>
                        <Col className="col-12 col-md-12 col-lg-12">
                            <FormGroup className="form-group">
                                <Label>จังหวัด</Label>
                                <Input 
                                placeholder="จังหวัด"
                                className="border"
                                value={province}
                                disabled
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-10">
                        <Col>
                            <div>ระบุข้อมูลที่ไม่ถูกต้อง</div>
                        </Col>
                    </Row>
                    <Row className="row-cols-1 row-cols-md-2 row-cols-lg-2">
                        <Col className="mb-10">
                            <div 
                            className={reportData.title === "แปลงนี้ขายแล้ว" ? "btn btn-report small active" : "btn btn-report small"}
                            onClick={() => onChangeText('แปลงนี้ขายแล้ว', 'title')}
                            >
                                แปลงนี้ขายแล้ว
                            </div>
                        </Col>
                        <Col className="mb-10">
                            <div 
                            className={reportData.title === "ไม่ใช่เจ้าของขายเอง" ? "btn btn-report small active" : "btn btn-report small"}
                            onClick={() => onChangeText('ไม่ใช่เจ้าของขายเอง', 'title')}
                            >
                                ไม่ใช่เจ้าของขายเอง
                            </div>
                        </Col>
                        <Col className="mb-10">
                            <div 
                            className={reportData.title === "ราคาของที่ดินคลาดเคลื่อน" ? "btn btn-report small active" : "btn btn-report small"}
                            onClick={() => onChangeText('ราคาของที่ดินคลาดเคลื่อน', 'title')}
                            >
                                ราคาของที่ดินคลาดเคลื่อน
                            </div>
                        </Col>
                        <Col className="mb-10">
                            <div 
                            className={reportData.title === "หมุดแผนที่ดินไม่ถูกต้อง" ? "btn btn-report small active" : "btn btn-report small"}
                            onClick={() => onChangeText('หมุดแผนที่ดินไม่ถูกต้อง', 'title')}
                            >
                                หมุดแผนที่ดินไม่ถูกต้อง
                            </div>
                        </Col>
                        <Col className="mb-10">
                            <div 
                            className={reportData.title === "ขนาดของที่ดินไม่ตรงกับโฉนด" ? "btn btn-report small active" : "btn btn-report small"}
                            onClick={() => onChangeText('ขนาดของที่ดินไม่ตรงกับโฉนด', 'title')}
                            >
                                ขนาดของที่ดินไม่ตรงกับโฉนด
                            </div>
                        </Col>
                        <Col className="mb-10">
                            <div 
                            className={reportData.title === "ไม่ได้รับอนุญาตจากเจ้าของที่ดิน" ? "btn btn-report small active" : "btn btn-report small"}
                            onClick={() => onChangeText('ไม่ได้รับอนุญาตจากเจ้าของที่ดิน', 'title')}
                            >
                                ไม่ได้รับอนุญาตจากเจ้าของที่ดิน
                            </div>
                        </Col>
                        <Col className="mb-10">
                            <div 
                            className={reportData.title === "อื่นๆ" ? "btn btn-report small active" : "btn btn-report small"}
                            onClick={() => onChangeText('อื่นๆ', 'title')}
                            >
                                อื่นๆ
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className="form-group">
                                <Input 
                                type="textarea" 
                                className="form-textarea" 
                                placeholder="คำอธิบายเพิ่มเติม..."
                                value={reportData.description}
                                onChange={(val) => onChangeText(val.target.value, "description")}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {loading ?
                    <div type="submit" className="btn-default-sm">
                        <Spinner size='sm' className="mr-10" /> Loading...
                    </div>
                    :
                    <Button type="submit" color="default-sm">ส่งรายงาน</Button>
                    }
                    
                </ModalFooter>
            </Form>
        </Modal>
    )
}
export default ReportModal;