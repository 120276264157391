import React from "react";
import {
    Button
} from 'reactstrap';
import img1 from 'assets/img/good.png';

import {
    faCheck,
    faXmark
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from 'react-router-dom';

const line_path = process.env.REACT_APP_LINE_PATH;

function PackageComponents(){
    const history = useHistory();
    return(
        <>
        <div style={{overflowX: 'auto', height:'100%'}}>
            <table className="table-promotion" style={{marginLeft: 'auto', marginRight: 'auto'}}>
                <thead>
                    <tr>
                        <th scope="row" style={{minWidth: '20%'}}></th>
                        <th scope="row" style={{minWidth: '20%'}}>
                            <div className="font-weight-500 header-free color-black" style={{fontSize: 30}}>
                                FREE
                            </div> 
                        </th>
                        <th scope="row" style={{minWidth: '30%'}}>
                            <div className="font-weight-500 header-plot color-black" style={{fontSize: 30}}>
                                รายแปลง
                            </div>
                        </th>
                        <th scope="row" style={{minWidth: '30%', position:'relative'}}>
                            <div className="font-weight-500 header-premium color-black" style={{fontSize: 30}}>
                                PREMIUM
                            </div>
                            <img alt="" src={img1} style={{position:'absolute', top:12, left:15, width: 55}} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td scope="row" className="border-none"></td>
                        <td className="border-none text-center">
                            <p><label style={{fontWeight:400}}>เหมาะสำหรับ</label> เจ้าของที่ดินที่มีที่ดิน 1-3 แปลง</p>
                        </td>
                        <td className="border-none text-center">
                        <p>ปักป้ายขายที่ดินออนไลน์กับเราประหยัดกว่าปักป้ายหน้าที่ดิน ข้อมูลจะอยู่จนกว่าจะขายได้ <label style={{fontWeight:400}}>เหมาะสำหรับ</label> เจ้าของที่ดินที่ต้องขายเอง <label style={{fontWeight:400}}>ไม่ผ่านนายหน้า</label></p>
                            
                        </td>
                        <td className="border-none text-center" style={{borderTopRightRadius: 10}}>
                        <p><label style={{fontWeight:400}}>เหมาะสำหรับ</label> ผู้ที่ซื้อขายที่ดินเป็นประจำ เช่น นายหน้ามืออาชีพ, นักธุรกิจอสังหาริมทรัพย์ ที่ต้องการซื้อขายที่ดินที่ครบถ้วน ในที่เดียว</p>
                        </td>
                    </tr>
                    <tr>
                        <td scope="row" className="bg-default-orange border-none color-black" style={{borderTopLeftRadius: 10}}>
                            ค่าบริการ
                        </td>
                        <td className="text-center">ฟรี</td>
                        <td className="text-center">500.- / แปลง</td>
                        <td className="text-center">450.-/เดือน หรือ <br/> 4,200.-/ปี (เดือนละ 350.-/เดือน)</td>
                    </tr>
                    <tr>
                        <td scope="row" className="bg-default-dark border-none" ></td>
                        <td className="bg-default-dark color-black text-center" style={{ fontSize: 20 }} colSpan={3}>กรณีลงประกาศขาย</td>
                        {/* <td className="bg_lightgray"></td>
                        <td className="bg_lightgray"></td> */}
                    </tr>
                    <tr>
                        <td scope="row" className="bg-default-orange border-none color-black">
                            ลงประกาศขายได้ ไม่จำกัดจำนวนแปลง
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faCheck} className="color-default" style={{fontSize: 25}} />
                        </td>
                        <td className="text-center color-gray">
                            <FontAwesomeIcon icon={faXmark} className="color-gray" style={{fontSize: 25}} />
                            <p><small className="color-gray">1 แปลง</small></p>
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faCheck} className="color-default" style={{fontSize: 25}} />
                        </td>
                    </tr>
                    <tr>
                        <td scope="row" className="bg-default-orange border-none color-black">
                            ข้อมูลเบอร์ติดต่อ
                        </td>
                        <td className="text-center color-gray">
                            <FontAwesomeIcon icon={faXmark} className="color-gray" style={{fontSize: 25}} />
                            <p><small className="color-gray">(ใช้ข้อมูลติดต่อของตลาดที่ดิน)</small></p> 
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faCheck} className="color-default" style={{fontSize: 25}} />
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faCheck} className="color-default" style={{fontSize: 25}} />
                        </td>
                    </tr>
                    <tr>
                        <td scope="row" className="bg-default-orange border-none color-black">
                            แจ้งเตือนเมื่อมีผู้สนใจที่ดินของท่าน
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faXmark} className="color-gray" style={{fontSize: 25}} />
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faCheck} className="color-default" style={{fontSize: 25}} />
                            <p><small className="color-gray">(ในบางกรณี จะได้รับเบอร์ติดต่อของผู้สนใจด้วย)</small></p>
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faCheck} className="color-default" style={{fontSize: 25}} />
                            <p><small className="color-gray">(ในบางกรณี จะได้รับเบอร์ติดต่อของผู้สนใจด้วย)</small></p>
                        </td>
                    </tr>
                    <tr>
                        <td scope="row" className="bg-default-orange border-none color-black">
                            เสนอขายที่ดิน ตาม "ประกาศรับซื้อ" โดยตรง ไปยังผู้ประกาศ
                        </td>
                        <td className="text-center color-gray">
                            <FontAwesomeIcon icon={faXmark} className="color-gray" style={{fontSize: 25}} />
                            <p><small className="color-gray">(ผ่านตลาดที่ดินเท่านั้น)</small></p>
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faCheck} className="color-default" style={{fontSize: 25}} />
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faCheck} className="color-default" style={{fontSize: 25}} />
                        </td>
                    </tr>
                    <tr>
                        <td scope="row" className="bg-default-orange border-none color-black">
                            รับข้อมูลจากผู้ที่ต้องการเสนอราคาซื้อต่ำกว่าราคาที่ตั้งขาย
                        </td>
                        <td className="text-center color-gray">
                            <FontAwesomeIcon icon={faXmark} className="color-gray" style={{fontSize: 25}} />
                            <p><small className="color-gray">(ผ่านตลาดที่ดินเท่านั้น)</small></p>
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faCheck} className="color-default" style={{fontSize: 25}} />
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faCheck} className="color-default" style={{fontSize: 25}} />
                        </td>
                    </tr>
                    <tr>
                        <td scope="row" className="bg-default-orange border-none color-black">
                            รับข้อมูลผู้ประกาศหาซื้อ จากตลาดที่ดิน ในทุกพื้นที่พร้อม Co-Agent กับ ตลาดที่ดิน
                        </td>
                        <td className="text-center color-gray">
                            <FontAwesomeIcon icon={faXmark} className="color-gray" style={{fontSize: 25}} />
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faXmark} className="color-gray" style={{fontSize: 25}} />
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faCheck} className="color-default" style={{fontSize: 25}} />
                        </td>
                    </tr>
                    <tr>
                        <td scope="row" className="bg-default-dark border-none" ></td>
                        <td className="bg-default-dark color-black text-center" style={{ fontSize: 20 }} colSpan={3}>
                            กรณีหาซื้อที่ดิน
                        </td>
                        {/* <td className="bg_lightgray"></td>
                        <td className="bg_lightgray"></td> */}
                    </tr>
                    <tr>
                        <td scope="row" className="bg-default-orange border-none color-black">
                            เลขที่โฉนด
                        </td>
                        <td className="text-center color-gray">
                            <FontAwesomeIcon icon={faXmark} className="color-gray" style={{fontSize: 25}} />
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faXmark} className="color-gray" style={{fontSize: 25}} />
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faCheck} className="color-default" style={{fontSize: 25}} />
                        </td>
                    </tr>
                    <tr>
                        <td scope="row" className="bg-default-orange border-none color-black">
                            เลือกทำเลโปรด 10 ทำเล พร้อมรับการแจ้งเตือนทันทีเมื่อมีที่ดินแปลงใหม่ลงขายในทำเลโปรดของคุณ
                        </td>
                        <td className="text-center color-gray">
                            <FontAwesomeIcon icon={faXmark} className="color-gray" style={{fontSize: 25}} />
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faXmark} className="color-gray" style={{fontSize: 25}} />
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faCheck} className="color-default" style={{fontSize: 25}} />
                        </td>
                    </tr>
                    <tr>
                        <td scope="row" className="bg-default-orange border-none color-black">
                            ติดต่อกับผู้ขายโดยตรง
                        </td>
                        <td className="text-center color-gray">
                            <FontAwesomeIcon icon={faXmark} className="color-gray" style={{fontSize: 25}} />
                            <p><small className="color-gray">(ผ่านตลาดที่ดินเท่านั้น)</small></p>
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faXmark} className="color-gray" style={{fontSize: 25}} />
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faCheck} className="color-default" style={{fontSize: 25}} />
                        </td>
                    </tr>
                    <tr>
                        <td scope="row" className="bg-default-orange border-none color-black">
                            เสนอราคาซื้อกับทางผู้ขายโดยตรง หรือ ให้ตลาดที่ดินช่วยเหลือ
                        </td>
                        <td className="text-center color-gray">
                            <FontAwesomeIcon icon={faXmark} className="color-gray" style={{fontSize: 25}} />
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faXmark} className="color-gray" style={{fontSize: 25}} />
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faCheck} className="color-default" style={{fontSize: 25}} />
                        </td>
                    </tr>
                    <tr>
                        <td scope="row" className="bg-default-orange border-none color-black" style={{borderBottomLeftRadius: 10}}>
                            ขอทราบข้อมูลแปลงที่ขายแล้วจากทางแอดมิน
                        </td>
                        <td className="text-center color-gray">
                            <FontAwesomeIcon icon={faXmark} className="color-gray" style={{fontSize: 25}} />
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faXmark} className="color-gray" style={{fontSize: 25}} />
                        </td>
                        <td className="text-center">
                            <FontAwesomeIcon icon={faCheck} className="color-default" style={{fontSize: 25}} />
                        </td>
                    </tr>
                    <tr>
                        <td className="bg_white border-none"></td>
                        <td className="bg_white border-none">
                            {/* <Button disabled color="gray-outline" className="full-w">ค่าเริ่มต้น</Button> */}
                        </td>
                        <td className="bg_white border-none text-center">
                        <i>
                                <small>
                                    * ชำระได้ที่หน้าลงประกาศขาย
                                </small>
                            </i>
                        </td>
                        <td className="bg_white border-none" style={{display:'flex', justifyContent:'center'}}>
                            <Button color="default" className="w-40 mr-10" onClick={() => history.push("/payments/package/premium")}>
                                เลือก
                            </Button>
                            <Button color="default-outline" className="w-40" onClick={() => window.open(line_path)}>
                                สนใจ
                            </Button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </>
    )
}
export default PackageComponents;