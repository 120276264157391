import React  from "react";

import ReactImageGallery from "react-image-gallery";
import { 
    Col, 
    Row, 
    FormGroup,
    Input
} from "reactstrap";

import profileImg from 'assets/img/blank-profile.png';

function Consignment_Tabs3(props){

    const { 
        user,
        value, 
        check,
        onCheckbox
    } = props;

    const intl = new Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 0,
    })

    return(
        <>
        <div className="consignment-tab-wrapper">
            <div className="consignment-tab3-section-1 mb-30">
                <div className="image-container">
                    {value.preview_images ? 
                    <ReactImageGallery 
                    showPlayButton={false}
                    // showThumbnails={false}
                    thumbnailPosition='bottom'
                    showFullscreenButton={false}
                    items={
                        value.preview_images.map(items => {
                            return{
                                original: items,
                                thumbnail: items,
                                originalClass: "image-preview",
                                thumbnailWidth:120,
                                thumbnailHeight: 80,
                            }
                        })
                    }
                    />
                    :null}
                </div>
            </div>
            <div className="consignment-tab3-section-2">
                
                <div className="column-1">
                    <div className="header mb-10">
                        ข้อมูลทรัพย์ที่จะลงประกาศขายจริง
                    </div>
                    <hr />
                    <Row>
                        <Col>
                            <div className="detail-wrapper">
                                <img src={profileImg} alt="profile-img" className="profile-img" />
                                <div style={{display:'flex', flexDirection:'column'}}>
                                    <label style={{fontWeight: '500', marginBottom:10}}>ชื่อผู้ประกาศ : <label>{user && user.name}</label></label>
                                    <label style={{fontWeight: '500', marginBottom:10}}>เบอร์ติดต่อ : <label>{user && user.phoneNumber}</label></label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <hr className="mb-30" />
                    <Row className="mb-20">
                        <Col>
                            <p className="header mb-10">ประเภทธุรกรรม</p>
                            <div style={{display:'flex'}}>
                                <div className="d-type ml-10 mr-10">{value.transaction_type === 'MORTGAGE' ? 'จำนอง' : value.transaction_type === 'CONSIGNMENT' ? 'ขายฝาก' : null}</div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 mb-20">
                        <Col style={{marginBottom: 15}}>
                            <p style={{fontWeight: 500, paddingBottom: 10}}>โฉนดที่ดิน</p>
                            <p style={{paddingBottom: 2, borderBottom:'0.5px solid gray'}}>{value?.titledeed_Number}</p>
                        </Col>
                        <Col style={{marginBottom: 15}}>
                            <p style={{fontWeight: 500, paddingBottom: 10}}>ประเภท</p>
                            <p style={{paddingBottom: 2, borderBottom:'0.5px solid gray'}}>{value?.saleType === 'LAND' ? 'ที่ดินเปล่า' : 'พร้อมสิ่งปลูกสร้าง'}</p>
                        </Col>
                        <Col style={{marginBottom: 15}}>
                            <p style={{fontWeight: 500, paddingBottom: 10}}>ที่อยู่</p>
                            <p style={{paddingBottom: 2, borderBottom:'0.5px solid gray'}}>
                                {value?.district + ", " + value?.province}
                            </p>
                        </Col>
                    </Row>
                    <Row className="mb-20">
                        <Col>
                            <p className="header mb-10">รายละเอียดทรัพย์ที่จะ จำนอง / ขายฝาก</p>
                            <FormGroup className="form-group">
                                <Input 
                                type="textarea" 
                                className="form-textarea"
                                cols={5}
                                placeholder="จุดเด่นและรายละเอียดเพิ่มเติมของแปลงนี้"
                                disabled
                                value={value.description}
                                />
                            </FormGroup> 
                        </Col>
                    </Row>
                </div>
                <div className="column-2">
                    <div className="detail-wrapper mb-20">
                        <div 
                        style={{
                            paddingLeft: 20, 
                            paddingTop:10, 
                            paddingBottom:10, 
                            borderBottom:'0.5px solid gray', 
                            marginBottom: 10
                        }}>
                            <p style={{fontWeight: 500}}>รายละเอียด</p>
                        </div>
                        <div style={{padding: 10}}>
                            <p style={{marginBottom: 10}}>
                                {`${value.rai ? value.rai : 0} ไร่ ${value.ngan ? value.ngan : 0} งาน ${value.wa ? value.wa : 0} ตารางวา `}
                            </p>
                            <p style={{marginBottom: 10}}>
                                ราคา {intl.format(parseFloat(value.pricePerWa))} / ตร.ว.
                            </p>
                            <p style={{marginBottom: 10}} className="color-default">ราคารวม {intl.format(parseFloat(value.totalPrice))}</p>
                        </div>
                    </div>
                    <Row className="mb-20">
                        <Col>
                            <p className="header mb-10">ระยะเวลาที่ต้องการ จำนอง / ขายฝาก</p>
                            <div style={{display:'flex'}}>
                                <div className="d-type ml-10 mr-10">
                                    ระยะเวลา {value?.year} ปี
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div 
                    className="check-box-container mb-20"
                    onClick={() => onCheckbox(!check)}
                    >
                        {/* <FontAwesomeIcon className="color-gray" icon={faSquare} style={{marginRight: 10}} /> */}
                        <FormGroup check>
                            <Input 
                            type='checkbox' 
                            checked={check} 
                            onChange={(val) => onCheckbox(val.target.checked)} 
                            />
                            <p style={{fontSize: 12}}>
                                ข้าพเจ้าขอรับรอง และขอรับผิดชอบต่อข้อมูลที่นำมาประกาศขายนี้ ว่าเป็นข้อมูลจริงและได้อนุญาตจากเจ้าของที่ดินเรียบร้อยแล้ว
                            </p>
                        </FormGroup>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Consignment_Tabs3