import React from "react";
import { 
    Button, 
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Row,
    Col
} from "reactstrap";
import Navbars from "routers/navbars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faChevronRight,
    faChevronLeft
} from "@fortawesome/free-solid-svg-icons";
import {
    faComments
} from "@fortawesome/free-regular-svg-icons";
import Footer from "components/footer";
import { ESContext } from "./forsaleTab/context/ESContext";
import LoginModal from "components/loginModal";
import Tab1 from "./forsaleTab/tab-1";
import Tab2 from "./forsaleTab/tab-2";
import Tab3 from "./forsaleTab/tab-3";

const line_url = process.env.REACT_APP_LINE_PATH;

function ForSale(){
    const { ESDetail } = React.useContext(ESContext)
    const [tabActive, setTabActive] = React.useState(1);
    const [loginOpen, setLoginOpen] = React.useState(false);
    const [toggleFail, setToggleFail] = React.useState(false);
    
    
    React.useEffect(() => {
        const user = localStorage.getItem('profile')
        if(!user){
            setLoginOpen(true)
        }
    },[])

    const dataCheck = () => {
        if(ESDetail.saleCatagory === 'LAND'){
            if(
                ESDetail.saleType &&
                ESDetail.titledeed_number &&
                ESDetail.province.name &&
                ESDetail.district.name &&
                ESDetail.pricePerWa &&
                ESDetail.totalPrice &&
                ESDetail.title && 
                ESDetail.freePay !== "0"
            ){
                return next();
            }else{
                return setToggleFail(true);
            }
        }else if(ESDetail.saleCatagory === 'HOME' || ESDetail.saleCatagory === "COMMERCIAL"){
            if(
                ESDetail.saleType &&
                ESDetail.titledeed_number &&
                ESDetail.province.name &&
                ESDetail.district.name &&
                ESDetail.totalPrice &&
                ESDetail.title && 
                ESDetail.bathRoom &&
                ESDetail.bedRoom && 
                ESDetail.useableArea &&
                ESDetail.freePay !== "0"
            ){
                return next();
            }else{
                return setToggleFail(true);
            }
        }else{
            if(
                ESDetail.saleType &&
                ESDetail.titledeed_number &&
                ESDetail.province.name &&
                ESDetail.district.name &&
                ESDetail.totalPrice &&
                ESDetail.title && 
                ESDetail.freePay !== "0"
            ){
                return next();
            }else{
                return setToggleFail(true);
            }
        }
    }

    function next(){
        setTabActive((item) =>{
            return item + 1;
        })
        scrollToTop();
    }

    function prev(){
        setTabActive((item) =>{
            return item - 1;
        })
        scrollToTop();
    }

    function scrollToTop(){
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
    }

    return(
        <>
        <Navbars />
        <div className="forsale-contianer">
            <div className="forsale-title">
                ประกาศ<p className="underline">ขายที่ดิน</p>
            </div>
            <ul className="wizard">
                <li className={tabActive === 1 ? "active" : ""}>
                    <span>ข้อมูลผู้ประกาศ/ข้อมูลทรัพย์</span>
                </li>
                {/* <li className={tabActive === 2 ? "active" : ""}>
                    <span>รูปถ่ายแปลงที่ดิน/บ้าน</span>
                </li> */}
                <li className={tabActive === 2 ? "active" : ""}>
                    <span>ยืนยันข้อมูลการประกาศ</span>
                </li>
            </ul>
            <div className="tab-content">
                <div className={tabActive === 1 ? 'show' : ''}>
                    <Tab1  />
                    <div className="btn-content">
                        <Button color="next" onClick={() => dataCheck()}>
                            ขั้นตอนถัดไป <FontAwesomeIcon icon={faChevronRight} style={{marginLeft:10}} />
                        </Button>
                    </div>
                    
                </div>
                {/* <div className={tabActive === 2 ? 'show' : ''}>
                    <div className="btn-content">
                        <Button type="button" color="next" onClick={() => prev()}><FontAwesomeIcon icon={faChevronLeft} style={{marginRight:10}} /> ย้อนกลับ</Button>
                        <Button type="button" color="next" onClick={() => next()}>ขั้นตอนถัดไป <FontAwesomeIcon icon={faChevronRight} style={{marginLeft:10}} /></Button>
                    </div>
                    <Tab2 />
                    <div className="btn-content">
                        <Button type="button" color="next" onClick={() => prev()}><FontAwesomeIcon icon={faChevronLeft} style={{marginRight:10}} /> ย้อนกลับ</Button>
                        <Button type="button" color="next" onClick={() => next()}>ขั้นตอนถัดไป <FontAwesomeIcon icon={faChevronRight} style={{marginLeft:10}} /></Button>
                    </div>
                </div> */}
                <div className={tabActive === 2 ? 'show' : ''}>
                    <div className="btn-content">
                        <Button type="button" color="next" onClick={() => prev()}><FontAwesomeIcon icon={faChevronLeft} style={{marginRight:10}} /> ย้อนกลับ</Button>
                    </div>
                    <Tab3 />
                    <div className="btn-content">
                        <Button type="button" color="next" onClick={() => prev()}><FontAwesomeIcon icon={faChevronLeft} style={{marginRight:10}} /> ย้อนกลับ</Button>
                    </div>
                </div>
            </div>
            <div className="tab-contact">
                <div className="contact-wrapper">
                    <i style={{fontSize:30, fontWeight: 500}}>" ไม่ต้องกังวลนะคะ "</i>
                    <p style={{fontSize:20, marginBottom: 20, fontWeight: 400}}>เรามีผู้ช่วยคอยให้บริการค่ะ</p>
                    <Button 
                    type="button" 
                    color="default"
                    onClick={() => window.open(line_url)}
                    >
                        <FontAwesomeIcon icon={faComments} style={{marginRight:15}} />คลิก ติดต่อผู้ช่วย
                    </Button>
                </div>
            </div>
        </div>
        <Footer />
        <LoginModal isOpen={loginOpen} onClose={() => setLoginOpen(false)} />
        <Modal isOpen={toggleFail} toggle={() => setToggleFail(false)}>
            <ModalHeader toggle={() => setToggleFail(false)}></ModalHeader>
            <ModalBody>
                <Row>
                    <Col className="col-12 col-md-12 col-lg-12">
                    <div className="text-error-box">
                        {ESDetail.saleCatagory === 'LAND' ? 
                            <ul className="error-list">
                                {!ESDetail.saleType && <li>กรุณาเลือกผู้ลงประกาศ</li>}
                                {!ESDetail.titledeed_number && <li>กรุณากรอกข้อมูลเลขที่โฉนด (หากมีมากกว่า 1 โฉนด ให้ใช้เครื่องหมาย "," คั่น)</li> }
                                {!ESDetail.province.name && <li>กรุณาเลือกจังหวัด</li>}
                                {!ESDetail.district.name && <li>กรุณาเลือกอำเภอ</li>}
                                {!ESDetail.pricePerWa && <li>กรุณากรอกราคาต่อตารางวา</li>}
                                {!ESDetail.totalPrice && <li>กรุณากรอกราคารวมที่ดิน</li>}
                                {!ESDetail.title && <li>กรุณากรอกหัวข้อ</li>}
                                {ESDetail.freePay === "0" && <li>กรุณาเลือกชำระวิธีชำระค่าธรรมเนียม</li> }
                            </ul>
                            : ESDetail.saleCatagory === 'HOME' || ESDetail.saleCatagory === "COMMERCIAL" ?
                            <ul className="error-list">
                                {!ESDetail.saleType && <li>กรุณาเลือกผู้ลงประกาศ</li>}
                                {!ESDetail.titledeed_number && <li>กรุณากรอกข้อมูลเลขที่โฉนด (หากมีมากกว่า 1 โฉนด ให้ใช้เครื่องหมาย "," คั่น)</li>}
                                {!ESDetail.province.name && <li>กรุณาเลือกจังหวัด</li>}
                                {!ESDetail.district.name && <li>กรุณาเลือกอำเภอ</li>}
                                {!ESDetail.totalPrice && <li>กรุณากรอกราคารวมที่ดิน</li>}
                                {!ESDetail.title && <li>กรุณากรอกหัวข้อ</li>}
                                {!ESDetail.bathRoom && <li>กรุณากรอกจำนวนห้องน้ำ</li>}
                                {!ESDetail.bedRoom && <li>กรุณากรอกจำนวนห้องนอน</li>}
                                {!ESDetail.useableArea && <li>กรุณากรอกพื้นที่ใช้สอย</li>}
                                {ESDetail.freePay === "0" && <li>กรุณาเลือกชำระวิธีชำระค่าธรรมเนียม</li>}
                            </ul>
                            :
                            <ul className="error-list">
                                {!ESDetail.saleType && <li>กรุณาเลือกผู้ลงประกาศ</li>}
                                {!ESDetail.titledeed_number && <li>กรุณากรอกข้อมูลเลขที่โฉนด (หากมีมากกว่า 1 โฉนด ให้ใช้เครื่องหมาย "," คั่น)</li>}
                                {!ESDetail.province.name && <li>กรุณาเลือกจังหวัด</li>}
                                {!ESDetail.district.name && <li>กรุณาเลือกอำเภอ</li>}
                                {!ESDetail.totalPrice && <li>กรุณากรอกราคารวมที่ดิน</li>}
                                {!ESDetail.title && <li>กรุณากรอกหัวข้อ</li>}
                                {ESDetail.freePay === "0" && <li>กรุณาเลือกชำระวิธีชำระค่าธรรมเนียม</li>}
                            </ul>
                        }
                    </div>
                    
                    </Col>
                </Row>
                
            </ModalBody>
            <ModalFooter>
                <Button 
                color="transparent" 
                onClick={() => setToggleFail(false)}
                >
                    ปิด
                </Button>
            </ModalFooter>
        </Modal>
        </>
    )
}

export default ForSale;