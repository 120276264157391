import React from "react";

import {
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Input,
    Label,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormText,
    Badge,
    Card,
    CardBody,
    CardImg,
    CardText,
    CardTitle,
    CardFooter,
    Alert,
    InputGroup
} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faUpload, 
    faLocationDot, 
    faHouse,
    faBuilding,
    faHotel,
    faThumbTack,
    faBullhorn,
    faMapLocationDot,
    faCity,
    faPhone,
    faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import {
    faFacebookF,
    faLine
} from '@fortawesome/free-brands-svg-icons';
// import Navbars from "routers/navbars";
import blank_profile from 'assets/img/blank-profile.png';
import axios from "axios";
import { ErrorAlert, SuccessAlert } from "components/alert";
import Swal from "sweetalert2";
import { LoginContext } from "components/loginContext/LoginContext";
import { PROVINCE_LIST } from "views/components/province";
import visa from 'assets/img/Payment/visa.svg';
import master from 'assets/img/Payment/mastercard.svg';
import promptpay from 'assets/img/Payment/prompt-pay.png';
import { useHistory } from 'react-router-dom';
import blank_img from 'assets/img/blank-profile.png';
import Pagination from "../pagination/Pagination";

const path_url = process.env.REACT_APP_API_CONNECT;

function Profiles(){
    const inputFile = React.useRef(null);
    // const [editFocus, setEditFocus] = React.useState(true);
    const {userData, setUserData} = React.useContext(LoginContext);
    const [previewProfile, setPreviewProfile] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [loading2, setLoading2] = React.useState(false);
    const [extendModal, setExtendModal] = React.useState(false);
    const [extendType, setExtendType] = React.useState("monthly");
    const [extendCreditInfo, setExtendCreditInfo] = React.useState({
        number: "",
        name: "",
        exp: {
            month: "",
            year: ""
        },
        cvv: "",
        city: "",
        postalCode: ""
    })
    const history = useHistory();
    const [promptpayModal, setPromptpayModal] = React.useState(false);
    const [promptpayResult, setPromptpayResult] = React.useState(null);
    const [active, setActive] = React.useState(1);

    const onButtonClick = () => {
        inputFile.current.click();
    };

    const onChangeFile = async (file) =>{
        // const preview = URL.createObjectURL(file[0]);
        // setPreviewProfile(preview);
        // setImageProfile(file[0]);
        try{
            // console.log(file[0])
            const form = new FormData();
            form.append('image1', file[0])
            const resultImg = await axios.post(path_url+'/upload/uploadImageProfile', form,{
                headers:{
                    'Content-Type' : 'multipart/form-data'
                }
            })
            if(resultImg.status === 200){
                await axios.post(path_url+'/user/updateUserImage',{
                    _id: userData._id,
                    profileImg: resultImg.data.path
                })
                setUserData((item) =>{
                    const data = {...item}
                    data['profileImg'] = resultImg.data.path;
                    return {...data}
                })
            }
        }catch(e){
            console.log(e)
        }
    }

    const onChange = (val, child) =>{
        setUserData((item) =>{
            const data = {...item}
            data[child] = val
            return {...data}
        })
    }

    const onSubmit = async (e) =>{
        e.preventDefault();
        setLoading(true);
        axios.post(path_url+'/user/update_profile', {
            userId: userData?._id,
            ...userData
        })
        .then(res =>{
            if(res.status === 200){
                SuccessAlert();
                setPreviewProfile(null);
                setLoading(false);
                setUserData((item) =>{
                    const data = {...item}
                    return {...data}
                })
            }
        })
        .catch(err =>{
            setLoading(false);
            ErrorAlert({text : 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'})
        })
    }

    // change_password
    const [passwordToggle, setPasswordToggle] = React.useState(false);
    const [newPassword, setNewPassworld] = React.useState('');
    const [reNewPassword, setReNewPassworld] = React.useState('');
    const [loading1, setLoading1] = React.useState(false);

    const pass_toggle_Close = () =>{
        setNewPassworld('');
        setReNewPassworld('');
        setPasswordToggle(false)
    }

    const onChangeSubmit = async () =>{
        setLoading1(true)

        try{
            if(newPassword === reNewPassword){
                const res = await axios.post(path_url+'/user/reNewPassword',{
                    _id: userData._id,
                    password: newPassword
                })

                if(res.status === 200){
                    SuccessAlert()
                    setLoading1(false)

                    setTimeout(() => {
                        window.location.reload()
                    }, 1500);
                }
            }else{
                Swal.fire({
                    icon:'error',
                    text:'ขออภัย! รหัสผ่านไม่ตรงกัน',
                    confirmButtonText:'ปิด',
                    confirmButtonColor:'#F32013'
                })

                setLoading1(false)
            }
        }catch(e){
            setLoading1(false)
            ErrorAlert({text: e})
        }
    }

    function expDate(exp){
        return new Date(exp).toLocaleString('th', {day:'2-digit', month: 'short', year:'numeric', hour:'2-digit', minute:'2-digit'})
    }

    function cc_format(value) {
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        var matches = v.match(/\d{4,16}/g);
        var match = matches && matches[0] || ''
        var parts = [];
        var len = match.length;
        for (var i=0; i<len; i+=4) {
          parts.push(match.substring(i, i+4))
        }
        if (parts.length) {
          return parts.join(' ')
        } else {
          return value
        }
    }

    function onSelectProvince(val){
        const province = PROVINCE_LIST.find(element =>{
            return element.province_id === val
        });
        setExtendCreditInfo({...extendCreditInfo, city: province.province_name_eng})
    }

    async function _onSubmit(){
        setLoading2(true)
        try{
            if(active === 1){
                const date = new Date(userData.expiredAt)
                const tryd = extendType === "monthly" ? 31 : 366;
                date.setDate(date.getDate() + tryd)
                const result = await axios.post(path_url+'/payment/payment_checkout',{
                    email: userData.email, 
                    name: extendCreditInfo.name, 
                    amount: extendType === "monthly" ? 450 : 4200, 
                    exp : {
                        month: extendCreditInfo.exp.month,
                        year: extendCreditInfo.exp.year
                    }, 
                    cardNumber: (extendCreditInfo.number).replaceAll(" ", ""), 
                    cvv: extendCreditInfo.cvv, 
                    userID: userData._id, 
                    expired: date,
                    city: extendCreditInfo.city,
                    postalCode: extendCreditInfo.postalCode
                })
                if(result){
                    SuccessFull()
                    setLoading2(false)
                }
            }else{
                const result = await axios.post(path_url+'/payment/createPromptpay',{
                    amount: extendType === "monthly" ? 450 : 4200,
                    user: {
                        _id: userData._id,
                        name: userData.name,
                        email: userData.email
                    }
                })
                if(result.status === 200){
                    setPromptpayResult(result.data)
                    setPromptpayModal(true)
                    setLoading2(false)
                }
            }
        }catch(e){
            console.log(e)
            setLoading2(false)
        }
    }

    function _onSuccess(){
        setPromptpayResult(null)
        setPromptpayModal(false)
        setTimeout(() => {
            window.location.reload()
        }, 500);
    }

    function SuccessFull(){
        Swal.fire({
            icon: 'success',
            title: "สำเร็จ!",
            text: "การชำระเงิน สำเร็จแล้ว!",
            showConfirmButton: true,
            confirmButtonColor: '#EF9751',
            confirmButtonText: 'เยี่ยมเลย!'
        })
        .then((result) =>{
            if(result.isConfirmed){
                window.location.reload()
            }
        })
    }

    const [pageEditToggle, setPageEditToggle] = React.useState(false);
    const [pageEditData, setPageEditData] = React.useState(null);
    const [pageLogo, setPageLogo] = React.useState({
        display: null,
        value: null
    })
    const [pageBanner, setPageBanner] = React.useState({
        display: null,
        value: null
    })

    const logoRef = React.useRef(null);
    const bannerRef = React.useRef(null);

    React.useState(() => {
        fetchPageData();
    },[])

    const pageToggleOpen = () =>{
        setPageEditToggle(true)
    }

    const pageToggleClose = () =>{
        setPageLogo({
            display: null,
            value: null
        })
        setPageBanner({
            display: null,
            value: null
        })
        setPageEditToggle(false)
    }

    function onChangeLogo(){
        logoRef.current.click()
    }

    function onChangeBanner(){
        bannerRef.current.click()
    }

    function onSelectLogo(val){
        setPageLogo((item) =>{
            const datas = {...item}
            datas.value = val[0]
            datas.display = URL.createObjectURL(val[0])
            return{...datas}
        })
    }

    function onSelectBanner(val){
        setPageBanner((item) =>{
            const datas = {...item}
            datas.value = val[0]
            datas.display = URL.createObjectURL(val[0])
            return{...datas}
        })
    }

    async function fetchPageData(){
        try{
            const data = await axios.post(path_url+'/landingpage/userCheckLandingPage',{
                uid: userData?._id
            })

            if(data.status === 200){
                setPageEditData(data.data)
                // console.log(data.data)
            }
        }catch(e){
            console.log(e)
        }
    }

    function onChangePageData(val, child){
        const data = {...pageEditData}
        data[child] = val
        setPageEditData({...data})
    }

    function onChangeSocialData(val, child){
        const data = {...pageEditData}
        const data1 = {...data.social}
        data1[child] = val
        setPageEditData({...data, social: {...data1}})
    }

    function addTiktokContent(){
        const data = {...pageEditData};
        const data1 = [...data.tiktokContent];
        data1.push({url: ""})
        setPageEditData({...data, tiktokContent: [...data1]})
    }

    function onChangeTiktokContent(val, child){
        const data = {...pageEditData}
        const data1 = [...data.tiktokContent]
        data1[child].url = val
        setPageEditData({...data, tiktokContent: [...data1]})
    }

    function removeTiktokContent(id){
        const data = {...pageEditData}
        const data1 = [...data.tiktokContent]
        const dataRm = data1.filter((item, i) => i !== id)
        setPageEditData({...data, tiktokContent: [...dataRm]})
    }

    async function uploadLogo(file, pageID){
        try{
            const form = new FormData()
            form.append('logoImg', file)
            form.append('pageID', pageID)
            const result = await axios.post(path_url+'/upload/uploadLogo',form,{
                headers: {
                    'Content-Type' : 'multipart/form-data'
                }
            })

            if(result.status === 200){
                return result.data
            }
        }catch(e){
            return e
        }
    }

    async function uploadBanner(file, pageID){
        try{
            const form = new FormData()
            form.append('banner', file)
            form.append('pageID', pageID)
            const result = await axios.post(path_url+'/upload/uploadBanner',form,{
                headers: {
                    'Content-Type' : 'multipart/form-data'
                }
            })

            if(result.status === 200){
                return result.data
            }
        }catch(e){
            return e
        }
    }

    async function onSubmitEditPage(){
        try{
            // console.log(pageEditData)
            let logoNew = null;
            if(pageLogo.value){
                logoNew = await uploadLogo(pageLogo.value, pageEditData._id)
            }
            let bannerNew = null;
            if(pageBanner.value){
                bannerNew = await uploadBanner(pageBanner.value, pageEditData._id)
            }

            const body = {
                titleName: pageEditData.titleName,
                detailProperty: pageEditData.detailProperty,
                tiktokContent: pageEditData.tiktokContent,
                color: pageEditData.color,
                social: pageEditData.social,
                bannerImg: bannerNew ? bannerNew.path : pageEditData.bannerImg,
                logo: logoNew ? logoNew.path : pageEditData.logo,
                pageID: pageEditData._id,
                pin: pageEditData.pin,
                address: pageEditData.address
            }

            const result = await axios.post(path_url+'/landingpage/updatePageSetting',{...body})
            if(result.status === 200){
                Swal.fire({
                    title: 'บันทึกเรียบร้อย',
                    icon:'success',
                    timer: 1500,
                    position: 'top-right'
                })
                setTimeout(() => {
                    setPageEditData({...pageEditData, ...body})
                    pageToggleClose()
                }, 1500);
            }
        }catch(e){
            console.log(e)
        }
    }

    const [saleData, setSaleData] = React.useState([]);
    const [salePageCurrent, setSalePageCurrent] = React.useState(1);
    const [saleCount, setSaleCount] = React.useState(0);
    const [saleToggle, setSaleToggle] = React.useState(false);
    const [salePageSize] = React.useState(9);
    const [saleAlert, setSaleAlert] = React.useState(false)

    function saleToggleOpen(){
        setSaleToggle(true);
        saleFetchPin()
    }

    function saleToggleClose(){
        setSaleToggle(false);
        setSaleData([])
    }

    async function saleFetchPin(page=1){
        const skip = (page - 1) * salePageSize;
        try{
            const result = await axios.post(path_url+'/landingpage/getSaleForPin',{
                skip: skip,
                uid: userData?._id
            })

            if(result.status === 200){
                setSaleData(result.data.data)
                setSaleCount(result.data.count)
            }
        }catch(e){
            console.log(e)
        }
    }

    const intl = new Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 0,
    })

    function setCurrentSalePage(page){
        setSalePageCurrent(page)
        saleFetchPin(page)
    }

    function onAddSalePin(saleID){
        if(pageEditData?.pin?.length < 10){
            const data = {...pageEditData}
            const result = [...data.pin, {saleID: saleID}]
            // console.log({...data, pin: [...result]})
            setPageEditData({...data, pin: [...result]})
        }else{
            setSaleAlert(true)
            setTimeout(() => {
                setSaleAlert(false)
            }, 2000);
        }
    }

    function onDeleteSalePin(saleID){
        const data = {...pageEditData};
        const pin = [...data.pin];
        const dataFilter = pin.filter((item) => item.saleID !== saleID)
        // console.log({...data, pin: [...dataFilter]})
        setPageEditData({...data, pin: [...dataFilter]})
    }

    return(
        <>
        {/* <Navbars /> */}
        <div className="dashboard-container">
            <div className="header mb-30 color-darkblue">ข้อมูลส่วนตัว</div>
            <div className="dashboard-profile shadow mb-30">
                <div className="dashboard-profile-wrapper">
                    <div className="profile-img">
                        {previewProfile ? 
                        <img alt="profile" src={previewProfile} className="image mb-20" />
                        :
                        userData && 
                        <img alt="profile" src={userData.profileImg ? userData.profileImg : blank_profile} className="image mb-20" />
                        }
                        
                        <input type={'file'} id='file' ref={inputFile} onChange={(val)=> onChangeFile(val.target.files)} hidden />
                        <Button 
                        color="transparent" 
                        style={{fontSize:14}} 
                        onClick={onButtonClick}
                        >
                            เลือกรูป
                        </Button>
                    </div>
                    <div className="profile-detail">
                        <Form>
                            <Row>
                                <Col className="col-12">
                                    <Label>Email</Label>
                                    <FormGroup className="form-group">
                                        <Input 
                                        className="border" 
                                        // readOnly={true}
                                        value={userData?.email}
                                        onChange={(val) => onChange(val.target.value, 'email')}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col className="col-12">
                                    <FormGroup className="form-group">
                                        <Label>Password</Label>
                                        <Button 
                                        color="skypastal" 
                                        className="btn-sm"
                                        disabled={userData?.provider === "REGISTER" ? false : true}
                                        onClick={() => setPasswordToggle(true)}
                                        >
                                            เปลี่ยน Password
                                        </Button>
                                    </FormGroup>
                                </Col>
                                <Col className="col-12">
                                    <FormGroup className="form-group">
                                        <Label>ชื่อ</Label>
                                        <Input 
                                        className="border" 
                                        value={userData?.name}
                                        onChange={(val) => onChange(val.target.value, 'name')}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col className="col-12">
                                    <FormGroup className="form-group">
                                        <Label>เบอร์ติดต่อ</Label>
                                        <Input 
                                        className="border" 
                                        value={userData?.phoneNumber}
                                        onChange={(val) => onChange(val.target.value, 'phoneNumber')}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col className="col-12">
                                    <FormGroup className="form-group">
                                        <Label>เบอร์ติดต่อสำรอง</Label>
                                        <Input 
                                        className="border" 
                                        value={userData?.phoneBackup}
                                        onChange={(val) => onChange(val.target.value, 'phoneBackup')}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="flex align-center justify-center">
                                    {loading ? 
                                        <Button color="default-outline">
                                            <Spinner size={'sm'} className="mr-10" style={{color:'orange'}} />
                                            Loading...
                                        </Button>
                                    :
                                        <Button 
                                        type="button"
                                        color="default" 
                                        size="sm"
                                        onClick={onSubmit}
                                        >
                                            <FontAwesomeIcon icon={faUpload} className="mr-10" />
                                            อัพเดตข้อมูลส่วนตัว
                                        </Button>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </div>
            <div className="dashboard-status mb-30">
                <h5 className="mb-10">สถานะ</h5>
                {userData?.role === "USER" ? 
                <div className="dashboard-status-wrapper">
                    <h6>ผู้ใช้งานทั่วไป</h6>
                    <Button color="transparent" onClick={() =>  window.open('/packages')}>
                        ดูแพคเก็จ
                    </Button>
                </div>
                :
                <div className="dashboard-status-wrapper">
                    <h6>ผู้ใช้งานพรีเมี่ยม <br/> <label>สิ้นสุดวันที่ {new Date(userData?.expiredAt).toLocaleDateString('th', {day:'2-digit', month:'short', year:'numeric'})}</label></h6>
                    <Button 
                    type="button" 
                    color="default" 
                    size="sm" 
                    onClick={() => setExtendModal(true)}>
                        <u>ต่ออายุ</u>
                    </Button>
                </div>
                }
            </div>
            {pageEditData ? 
            <div className="dashboard-landingpage mb-30">
                <h5 className="mb-10">ตั้งค่า Pro Web</h5>
                <div className="dashboard-landingpage-wrapper">
                    <label>สิ้นสุดวันที่ {new Date(pageEditData?.expiredAt).toLocaleDateString('th', {day:'2-digit', month:'short', year:'numeric'})}</label>
                    <div>
                        <Button 
                        type="button" 
                        color="sky" 
                        size="sm" 
                        className="mr-10"
                        onClick={() => pageToggleOpen()}
                        >
                            <u>แก้ไข</u>
                        </Button>
                        <Button 
                        type="button" 
                        color="danger" 
                        className="mr-10"
                        size="sm" 
                        // onClick={() => setExtendModal(true)}
                        >
                            <u>ต่ออายุ</u>
                        </Button>
                        <Button 
                        type="button" 
                        color="default" 
                        size="sm" 
                        onClick={() => window.open(window.location.origin+'/page/'+ String(pageEditData.titleName).replace(' ','.'))}
                        >
                            <u>View</u>
                        </Button>
                    </div>
                    
                    {/* <label>สิ้นสุดวันที่ {new Date(userData?.expiredAt).toLocaleDateString('th', {day:'2-digit', month:'short', year:'numeric'})}</label> */}
                </div>
            </div>
            : null}
        </div>

        <Modal isOpen={passwordToggle} toggle={pass_toggle_Close}>
            <ModalHeader toggle={pass_toggle_Close}></ModalHeader>
            <ModalBody>
                <Form>
                    <Row className="mb-10">
                        <Col>
                            <FormGroup className="form-group">
                                <Label>เปลี่ยนรหัสใหม่</Label>
                                <Input 
                                type="password"
                                className="border"
                                placeholder="New Password"
                                value={newPassword}
                                onChange={(val) => setNewPassworld(val.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className="form-group">
                                <Label>ยืนยันรหัสอีกครั้ง</Label>
                                <Input 
                                type="password"
                                className="border"
                                placeholder="Re-New Password"
                                value={reNewPassword}
                                onChange={(val) => setReNewPassworld(val.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                {loading1 ? 
                    <Button 
                    color="default-sm"
                    className="mr-30"
                    >
                        <Spinner size={'sm'} className="mr-10" style={{color:'white'}} />
                        Loading...
                    </Button>
                :
                    <Button 
                    type="submit"
                    color="default-sm" 
                    className="mr-30"
                    onClick={onChangeSubmit}
                    >
                        บันทึก
                    </Button>
                }
                <Button color="transparent-sm" onClick={pass_toggle_Close}>ยกเลิก</Button>
            </ModalFooter>
        </Modal>
        <Modal isOpen={extendModal} toggle={() => setExtendModal(false)}>
            <ModalHeader toggle={() => setExtendModal(false)}>
                วิธีการชำระ
            </ModalHeader>
            <ModalBody>
                <div className="mb-20">
                    <Row className="mb-10">
                        <Col className="col-6" style={{display:'flex', justifyContent:'center'}}>
                            <FormGroup check onClick={() => setExtendType('monthly')} >
                                <Input type="radio" name="radio1" checked={extendType === "monthly"} />
                                <Label check>รายเดือน 450.-/เดือน</Label>
                            </FormGroup>
                        </Col>
                        <Col className="col-6" style={{display:'flex', justifyContent:'center'}}>
                            <FormGroup check onClick={() => setExtendType('yearly')}>
                                <Input type="radio" name="radio1" checked={extendType === "yearly"} />
                                <Label check>รายปี 4,200.-/ปี </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <p className="color-gray mb-20">
                        Package : {extendType === "monthly" ? <label className="color-black">Premium (450 บาท / เดือน)</label> : <label className="color-black">Premium (4,200 บาท / ปี) <p>(ตกเดือนละ 350 บาท <u style={{fontWeight: 500}} className="color-default">ถูกกว่า</u>)</p></label>}
                    </p>
                </div>
                <hr className="mb-20" />
                <h4>เลือกวิธีการชำระ</h4>
                <Row className="mb-20">
                    <Col className="col-12 col-md-6 col-lg-6">
                        <button
                        className={`btn-payment mb-10 ${active === 1 ? "choose" : ""}`}
                        onClick={() => setActive(1)}
                        >
                            <div>
                                <img alt="visa" src={visa} style={{width: 35, marginRight: 5}} />
                                <img alt="mastercard" src={master} style={{width: 35}} />
                            </div>
                            บัตรเครดิต/เดบิต
                        </button>
                    </Col>
                    <Col className="col-12 col-md-6 col-lg-6">
                        <button
                        className={`btn-payment mb-10 ${active === 2 ? "choose" : ""}`}
                        onClick={() => setActive(2)}
                        >
                            <div>
                                <img alt="visa" src={promptpay} style={{width: 70, marginRight: 5}} />
                            </div>
                            พร้อมเพย์
                        </button>
                    </Col>
                </Row>
                {active === 1 ?
                <Row className="mb-20 payment-info">
                    <Col className="col-12 col-md-12 col-lg-12">
                        <FormGroup className="form-group">
                            <Label>หมายเลขบัตร</Label>
                            <input 
                            className="form-control-2"
                            placeholder="0000 0000 0000 0000"
                            value={cc_format(extendCreditInfo.number)}
                            onChange={(val) => setExtendCreditInfo({...extendCreditInfo, number: val.target.value})}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-12 col-md-12 col-lg-12">
                        <FormGroup className="form-group">
                            <Label>ชื่อบนบัตร</Label>
                            <input 
                            className="form-control-2"
                            placeholder="NAME ON CARD"
                            value={extendCreditInfo.name}
                            onChange={(val) => setExtendCreditInfo({...extendCreditInfo, name: val.target.value})}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-12 col-md-6 col-lg-6">
                        <FormGroup className="form-group">
                            <Label>จังหวัด</Label>
                            <Input 
                            type="select" 
                            className="form-control-2"
                            onChange={(val) => onSelectProvince(val.target.value)}
                            >
                                <option value="">เลือก</option>
                                {PROVINCE_LIST.map((item, i) =>{
                                    return(
                                        <option key={i} value={item.province_id}>
                                            {item.province_name}
                                        </option>
                                    )
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col className="col-12 col-md-6 col-lg-6">
                        <FormGroup className="form-group">
                            <Label>รหัสไปรษณีย์</Label>
                            <input
                            className="form-control-2"
                            value={extendCreditInfo.postalCode}
                            onChange={(val) => setExtendCreditInfo({...extendCreditInfo, postalCode: val.target.value})}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-12 col-md-6 col-lg-6">
                        <FormGroup className="form-group">
                            <Label>รหัส CVV</Label>
                            <input 
                            className="form-control-2"
                            placeholder="รหัส 3 ตัวหลังบัตร"
                            maxLength={3}
                            value={extendCreditInfo.cvv}
                            onChange={(val) => setExtendCreditInfo({...extendCreditInfo, cvv: val.target.value})}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="col-12 col-md-6 col-lg-6">
                        <FormGroup className="form-group">
                            <Label>วันหมดอายุบัตร</Label>
                            <Row>
                                <Col className="col-5">
                                <input 
                                className="form-control-2"
                                placeholder="MM"
                                maxLength={2}
                                pattern="[0-9]*"
                                value={extendCreditInfo.exp.month}
                                onChange={(val) => setExtendCreditInfo({...extendCreditInfo, exp:{...extendCreditInfo.exp, month: val.target.value}})}
                                />
                                </Col>
                                /
                                <Col className="col-6">
                                <input 
                                className="form-control-2"
                                placeholder="YYYY"
                                maxLength={4}
                                pattern="[0-9]*"
                                value={extendCreditInfo.exp.year}
                                onChange={(val) => setExtendCreditInfo({...extendCreditInfo, exp:{...extendCreditInfo.exp, year: val.target.value}})}
                                />
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>
                : 
                <Row>
                    <Col className="col-12">
                        <h5>วิธีการชำระ พร้อมเพย์</h5>
                        <p>{"-> เลือกการชำระแบบ 'พร้อมเพย์'"}</p>
                        <p>{"-> คลิกปุ่ม 'ชำระเงิน'"}</p>
                        <p>{"-> แสกน QR Code"}</p>
                        <p>{"-> คลิกปุ่ม 'สำเร็จ'"}</p>
                        <p className="mb-10">{"-> รอการตรวจสอบ 5-10 นาที"}</p>
                        <p>หรือ ดาวน์โหลด QR Code</p>
                        <p>{"-> เลือกการชำระแบบ 'พร้อมเพย์'"}</p>
                        <p>{"-> คลิกปุ่ม 'ชำระเงิน'"}</p>
                        <p>{"-> คลิกปุ่ม 'ดาวน์โหลด QR Code'"}</p>
                        <p>{"-> คลิกปุ่ม 'สำเร็จ'"}</p>
                        <p>{"-> เปิดแอพฯ (Mobile Banking)"}</p>
                        <p>{"-> แสกน QR Code และ เลือกจากรูปภาพ"}</p>
                        <p className="mb-10">{"-> รอการตรวจสอบ 5-10 นาที"}</p>
                    </Col>
                </Row>
                }
            </ModalBody>
            <ModalFooter>
                {loading2 ? 
                <Button color="sky" className="full-w">
                    <Spinner size='sm' className="mr-10" /> Loading...
                </Button>
                : 
                <Button color="sky" className="full-w" onClick={_onSubmit}>
                    ชำระเงิน
                </Button>
                }
            </ModalFooter>
        </Modal>
        <Modal isOpen={promptpayModal} >
            <ModalBody>
                {promptpayResult ? 
                <>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <img 
                    alt={promptpayResult.source.scannable_code.image.filename} 
                    src={promptpayResult.source.scannable_code.image.download_uri} 
                    style={{width: '80%'}} 
                    />
                </div>
                <div className="mb-20">
                    QR Code จะหมดอายุใน : {expDate(promptpayResult.expires_at)}
                </div>
                <div>
                    <a href={promptpayResult.source.scannable_code.image.download_uri} className="btn btn-blue" download="TLTD-Promptpay">
                        ดาวน์โหลด QR Code
                    </a>
                </div>
                </>
                : null}
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={_onSuccess}>
                    สำเร็จ
                </Button>
            </ModalFooter>
        </Modal>

        <Modal isOpen={pageEditToggle} toggle={() => pageToggleClose()} className="modal-fullscreen">
            <ModalHeader toggle={() => pageToggleClose()}>Setting Page</ModalHeader>
            <ModalBody>
                {pageEditData ? 
                <div className="setting-page">
                    <div className="setting-flex-1">
                        <h3>ตั้งค่า</h3>
                        <ul className="setting-box mb-30" >
                            <li>
                                <FormGroup className="form-group">
                                    <Label className="font-weight-400">Logo</Label>
                                    <input ref={logoRef}  type='file' id='file' onChange={(val) => onSelectLogo(val.target.files)} style={{display: 'none'}} />
                                    <div style={{display:'flex', justifyContent:'center', marginTop:20, marginBottom:20}}>
                                        {pageLogo?.display ?
                                        <img 
                                        alt="logo" 
                                        src={pageLogo?.display} 
                                        style={{height: 32}} 
                                        />
                                        :
                                        <img 
                                        alt="logo" 
                                        src={pageEditData?.logo} 
                                        style={{height: 32}} 
                                        />
                                        }
                                    </div>
                                    <Button 
                                    color="default" 
                                    size="sm"
                                    onClick={() => onChangeLogo()}
                                    >
                                        เลือก
                                    </Button>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup className="form-group">
                                    <Label className="font-weight-400">Banner</Label>
                                    <FormText className="color-red">* ขนาดที่แนะนำ 1280 x 850</FormText>
                                    <input ref={bannerRef} type="file" onChange={(val) => onSelectBanner(val.target.files)} hidden />
                                    <div style={{display:'flex', justifyContent:'center', marginTop:20, marginBottom:20}}>
                                        {pageBanner?.display ? 
                                        <img alt="logo" src={pageBanner?.display} style={{height: 200}} />
                                        :
                                        <img alt="logo" src={pageEditData?.bannerImg} style={{height: 200}} />
                                        }
                                        
                                    </div>
                                    <Button 
                                    color="default" 
                                    size="sm"
                                    onClick={() => onChangeBanner()}
                                    >
                                        เลือก
                                    </Button>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup className="form-group">
                                    <Label className="font-weight-400">ชื่อเว็บไซต์</Label>
                                    <Input 
                                    type="text" 
                                    value={pageEditData?.titleName} 
                                    onChange={(val) => onChangePageData(val.target.value, 'titleName')}
                                    />
                                    <FormText className="color-red">
                                        * ห้ามใช้อักษรพิเศษเช่น + - * / $ = , " " () ฯลฯ แต่สามารถใช้การเว้นวรรคได้เช่น TaladTeedin Property
                                    </FormText>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup className="form-group">
                                    <Label className="font-weight-400">ที่อยู่</Label>
                                    <textarea 
                                    role="3" 
                                    className="form-textarea" 
                                    value={pageEditData?.address}
                                    onChange={(val) => onChangePageData(val.target.value, 'address')}
                                    />
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup className="form-group">
                                    <Label className="font-weight-400">ข้อมูลแนะนำตัวเจ้าของ</Label>
                                    <textarea 
                                    role="3" 
                                    className="form-textarea" 
                                    value={pageEditData?.detailProperty}
                                    onChange={(val) => onChangePageData(val.target.value, 'detailProperty')}
                                    />
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup className="form-group">
                                    <Label className="font-weight-400">สีบนเว็บ</Label>
                                    <Input 
                                    type='color' 
                                    value={pageEditData?.color} 
                                    onChange={(val) => onChangePageData(val.target.value, 'color')}
                                    />
                                </FormGroup>
                            </li>
                            <li>
                                <Label className="font-weight-400">โซเชียลมีเดียต่างๆ</Label>
                                <ul className="ml-10">
                                    <li>
                                        <FormGroup className="form-group">
                                            <Label>Facebook URL</Label>
                                            <Input 
                                            type='text' 
                                            placeholder="https://" 
                                            value={pageEditData?.social?.facebookUrl}
                                            onChange={(val) => onChangeSocialData(val.target.value, 'facebookUrl')}
                                            />
                                        </FormGroup>
                                    </li>
                                    <li>
                                        <FormGroup className="form-group">
                                            <Label>Youtube Channel</Label>
                                            <Input 
                                            type='text' 
                                            placeholder="https://" 
                                            value={pageEditData?.social?.youtubeUrl}
                                            onChange={(val) => onChangeSocialData(val.target.value, 'youtubeUrl')}
                                            />
                                        </FormGroup>
                                    </li>
                                    <li>
                                        <FormGroup className="form-group">
                                            <Label>Line Contact</Label>
                                            <Input 
                                            type='text' 
                                            placeholder="https://" 
                                            value={pageEditData?.social?.lineUrl}
                                            onChange={(val) => onChangeSocialData(val.target.value, 'lineUrl')}
                                            />
                                        </FormGroup>
                                    </li>
                                    <li>
                                        <FormGroup className="form-group">
                                            <Label>Tiktok Channel</Label>
                                            <Input 
                                            type='text' 
                                            placeholder="https://" 
                                            value={pageEditData?.social?.tiktokUrl}
                                            onChange={(val) => onChangeSocialData(val.target.value, 'tiktokUrl')}
                                            />
                                        </FormGroup>
                                    </li>
                                </ul>
                            </li>
                            <li style={{border:'0.5px solid gray', borderRadius: 10, padding: 5, marginBottom: 10}}>
                                <Label className="font-weight-400">Tiktok Content</Label>
                                {Array.from(pageEditData?.tiktokContent).map((item, i) =>{
                                    return(
                                        <FormGroup className="form-group" key={i}>
                                            <InputGroup>
                                                <Input 
                                                type='text' 
                                                placeholder="https://" 
                                                value={item.url}
                                                onChange={(val) => onChangeTiktokContent(val.target.value, i)}
                                                />
                                                <Button 
                                                color="danger"
                                                size="sm"
                                                onClick={() => removeTiktokContent(i)}
                                                >
                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                </Button>
                                            </InputGroup>
                                            
                                        </FormGroup>
                                    )
                                })}
                                <div style={{display:'flex', justifyContent:'center'}}>
                                    <Button 
                                    color="transparent" 
                                    size="sm"
                                    onClick={() => addTiktokContent()}
                                    >
                                        เพิ่ม
                                    </Button>
                                </div>
                            </li>
                            <li>
                                <p className="font-weight-400">อสังหาฯ ที่อยากแนะนำ</p>
                                <Button 
                                color="sky" 
                                size="sm" 
                                className="full-w"
                                onClick={() => saleToggleOpen()}
                                >
                                    เลือก
                                </Button>
                            </li>
                        </ul>
                        <Button 
                        color="default" 
                        className="full-w"
                        onClick={() => onSubmitEditPage()}
                        >
                            บันทึก
                        </Button>
                    </div>
                    <div className="setting-flex-2">
                        <h3>Display</h3>
                        <label className="color-red mb-10">* ข้อมูลบางส่วนเป็นเพียงแค่ตัวอย่างเท่านั้น เพื่อให้มองเห็นการเปลี่ยนแปลง</label>
                        <div className="setting-dispaly">
                            <nav className="nav">
                                {pageLogo?.display ? 
                                <img 
                                alt="" 
                                src={pageLogo?.display} 
                                style={{height: 50}}
                                />
                                : 
                                <img 
                                alt="" 
                                src={pageEditData?.logo} 
                                style={{height: 50}}
                                />
                                }
                                
                            </nav>
                            <div className="banner">
                                {pageBanner?.display ? 
                                <img 
                                alt="" 
                                className="banner-img"
                                src={pageBanner?.display} 
                                />
                                :
                                <img 
                                alt="" 
                                className="banner-img"
                                src={pageEditData?.bannerImg} 
                                />
                                }
                                
                                {/* <span className="text-title">
                                    <p className="title">{pageEditData?.titleName}</p>
                                    <p className="sub-title">powered by TaladTeedin</p>
                                </span> */}
                                <div className="property-box">
                                    <div className="property-wrapper">
                                        <Button 
                                        color="default"
                                        style={{
                                            backgroundColor: pageEditData?.color,
                                            marginLeft: 10,
                                            marginRight: 10,
                                            position:'relative',
                                            fontSize: '1vw',
                                            display:'flex'
                                        }}
                                        >
                                            ทั้งหมด
                                            <Badge 
                                            pill
                                            style={{
                                                border: '2px solid white',
                                                position:'absolute',
                                                top: -10,
                                                right: -15,
                                                backgroundColor: pageEditData?.color
                                            }}
                                            >
                                                100
                                            </Badge>
                                        </Button>
                                        <Button 
                                        color="default"
                                        style={{
                                            backgroundColor: '#fff',
                                            marginLeft: 10,
                                            marginRight: 10,
                                            position:'relative',
                                            color: 'black',
                                            fontSize: '1vw',
                                            display:'flex'
                                        }}
                                        >
                                            <FontAwesomeIcon icon={faLocationDot} className="mr-10" />
                                            ที่ดิน
                                            <Badge 
                                            pill
                                            style={{
                                                border: '2px solid white',
                                                position:'absolute',
                                                top: -10,
                                                right: -15,
                                                backgroundColor: pageEditData?.color
                                            }}
                                            >
                                                20
                                            </Badge>
                                        </Button>
                                        <Button 
                                        color="default"
                                        style={{
                                            backgroundColor: '#fff',
                                            marginLeft: 10,
                                            marginRight: 10,
                                            position:'relative',
                                            color: 'black',
                                            fontSize: '1vw',
                                            display:'flex'
                                        }}
                                        >
                                            <FontAwesomeIcon icon={faHouse} className="mr-10" />
                                            บ้าน
                                            {/* <Badge 
                                            pill
                                            style={{
                                                border: '2px solid white',
                                                position:'absolute',
                                                top: -10,
                                                right: -15,
                                                backgroundColor: '#EF9751'
                                            }}
                                            >
                                                20
                                            </Badge> */}
                                        </Button>
                                        <Button 
                                        color="default"
                                        style={{
                                            backgroundColor: '#fff',
                                            marginLeft: 10,
                                            marginRight: 10,
                                            position:'relative',
                                            color: 'black',
                                            fontSize: '1vw',
                                            display:'flex'
                                        }}
                                        >
                                            <FontAwesomeIcon icon={faBuilding} className="mr-10" />
                                            อาคารพาณิชย์
                                            {/* <Badge 
                                            pill
                                            style={{
                                                border: '2px solid white',
                                                position:'absolute',
                                                top: -10,
                                                right: -15,
                                                backgroundColor: '#EF9751'
                                            }}
                                            >
                                                20
                                            </Badge> */}
                                        </Button>
                                        <Button 
                                        color="default"
                                        style={{
                                            backgroundColor: '#fff',
                                            marginLeft: 10,
                                            marginRight: 10,
                                            position:'relative',
                                            color: 'black',
                                            fontSize: '1vw',
                                            display:'flex',
                                        }}
                                        >
                                            <FontAwesomeIcon icon={faHotel} className="mr-10" />
                                            โรงแรม/รีสอร์ท
                                            {/* <Badge 
                                            pill
                                            style={{
                                                border: '2px solid white',
                                                position:'absolute',
                                                top: -10,
                                                right: -15,
                                                backgroundColor: '#EF9751'
                                            }}
                                            >
                                                20
                                            </Badge> */}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="page-body-1 mb-20">
                                <p className="title mb-10">
                                    รายการประกาศทั้งหมด (ตัวอย่าง)
                                </p>
                                <Row className="row-cols-1 row-cols-md-3">
                                    <Col>
                                        <Card>
                                            <div className="card-tag">
                                                <div className="tag">
                                                    {/* {item?.saleType === "OWNER" ?
                                                    <div className="tag-1 bg_default color-white mt-10">
                                                        เจ้าของขายเอง
                                                    </div>
                                                    :
                                                    null} */}
                                                    {/* {item.quick ?  */}
                                                    <div className="tag-2 bg_redpink color-white mt-10">
                                                        ขายด่วน
                                                    </div>
                                                    {/* : null} */}
                                                </div>
                                                <CardImg 
                                                alt=""
                                                src="https://taladteedin-test.sgp1.digitaloceanspaces.com/files/b8951dbf-2852-4ec7-a3a0-bdcee27df964"
                                                />
                                            </div>
                                            <CardBody>
                                                <div className="body-1">
                                                    <CardTitle>
                                                        ขายที่ดินเชียงราย ใกล้สถานีรถไฟเชียงราย 16-1-8
                                                    </CardTitle>
                                                    <CardText>
                                                        ที่ดินเปล่าแปลงสวย บรรยากาศดี เดินทางง่าย ใกล้แหล่งอำนวยความสะดวก สาธารณูปโภคเข้าถึง ขายไร่ละ 4 ล้าน
                                                    </CardText>
                                                </div>
                                                <div className="body-2">
                                                    <CardText>฿ 65,080,000</CardText>
                                                    <CardText style={{color: pageEditData.color}}>฿ 10,000/ตร.ว.</CardText>
                                                </div>
                                                <CardText>
                                                    <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                                    เวียงชัย, เชียงราย
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card>
                                            <div className="card-tag">
                                                <div className="tag">
                                                    {/* {item?.saleType === "OWNER" ?
                                                    <div className="tag-1 bg_default color-white mt-10">
                                                        เจ้าของขายเอง
                                                    </div>
                                                    :
                                                    null} */}
                                                    {/* {item.quick ?  */}
                                                    <div className="tag-2 bg_redpink color-white mt-10">
                                                        ขายด่วน
                                                    </div>
                                                    {/* : null} */}
                                                </div>
                                                <CardImg 
                                                alt=""
                                                src="https://taladteedin-test.sgp1.digitaloceanspaces.com/files/b8951dbf-2852-4ec7-a3a0-bdcee27df964"
                                                />
                                            </div>
                                            <CardBody>
                                                <div className="body-1">
                                                    <CardTitle>
                                                        ขายที่ดินเชียงราย ใกล้สถานีรถไฟเชียงราย 16-1-8
                                                    </CardTitle>
                                                    <CardText>
                                                        ที่ดินเปล่าแปลงสวย บรรยากาศดี เดินทางง่าย ใกล้แหล่งอำนวยความสะดวก สาธารณูปโภคเข้าถึง ขายไร่ละ 4 ล้าน
                                                    </CardText>
                                                </div>
                                                <div className="body-2">
                                                    <CardText>฿ 65,080,000</CardText>
                                                    <CardText style={{color: pageEditData.color}}>฿ 10,000/ตร.ว.</CardText>
                                                </div>
                                                <CardText>
                                                    <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                                    เวียงชัย, เชียงราย
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card>
                                            <div className="card-tag">
                                                {/* <div className="tag">
                                                    <div className="tag-2 bg_redpink color-white mt-10">
                                                        ขายด่วน
                                                    </div>
                                                </div> */}
                                                <CardImg 
                                                alt=""
                                                src="https://taladteedin-test.sgp1.digitaloceanspaces.com/files/b8951dbf-2852-4ec7-a3a0-bdcee27df964"
                                                />
                                            </div>
                                            <CardBody>
                                                <div className="body-1">
                                                    <CardTitle>
                                                        ขายที่ดินเชียงราย ใกล้สถานีรถไฟเชียงราย 16-1-8
                                                    </CardTitle>
                                                    <CardText>
                                                        ที่ดินเปล่าแปลงสวย บรรยากาศดี เดินทางง่าย ใกล้แหล่งอำนวยความสะดวก สาธารณูปโภคเข้าถึง ขายไร่ละ 4 ล้าน
                                                    </CardText>
                                                </div>
                                                <div className="body-2">
                                                    <CardText>฿ 65,080,000</CardText>
                                                    <CardText style={{color: pageEditData.color}}>฿ 10,000/ตร.ว.</CardText>
                                                </div>
                                                <CardText>
                                                    <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                                    เวียงชัย, เชียงราย
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                            <div className="page-body-3 mb-20">
                                <p className="title mb-10">
                                    ประกาศรับซื้อ (ตัวอย่าง)
                                </p>
                                <Row className="row-cols-1 row-cols-md-3 row-cols-lg-3">
                                    <Col className="mb-10">
                                        <Card >
                                            <CardBody>
                                                <div className="header mb-10">
                                                    <img alt="userImg" src={userData?.profileImg ? userData?.profileImg : blank_img} className="user-img" />
                                                    <p className="name">{userData.name}</p>
                                                    <p className="oversize" style={{color: pageEditData.color}}>ต้องการ</p>
                                                    <div className="text-wrapper" style={{backgroundColor: pageEditData.color}}>
                                                        <FontAwesomeIcon icon={faLocationDot} className="mr-10 icon" />
                                                        <div className="label">
                                                            <p className="label-1">
                                                                จ.เชียงใหม่
                                                            </p>
                                                            <p className="label-2">
                                                                อ.เมืองเชียงใหม่
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <small className="date">
                                                        10 ม.ค 67
                                                    </small>
                                                </div>
                                                
                                                <div className="text-description">
                                                    <div className="font-weight-500 color-default mb-10" style={{color: pageEditData.color}}>
                                                        วัตถุประสงค์: 
                                                        <p className="font-weight-300 ml-10 color-black" >สร้างบ้าน</p>
                                                    </div>
                                                    <div className="flex font-weight-500 color-default mb-10" style={{color: pageEditData.color}}>
                                                        ขนาดเนื้อที่: <div className="font-weight-300 ml-10 color-black">200 ตร.ว. - 400 ตร.ว.</div>
                                                    </div>
                                                    <div className="flex font-weight-500 color-default " style={{color: pageEditData.color}}>
                                                        ราคารวม: 
                                                    </div>
                                                    <div className="color-black mb-10">
                                                        ฿0 - ฿2,000,000
                                                    </div>
                                                    <div className="flex font-weight-500 color-default" style={{color: pageEditData.color}}>
                                                        รายละเอียด: 
                                                    </div>
                                                    <div className="text-detail color-black">
                                                        หรือหากมีใกล้เคียงและติดถนนสามารถเสนอได้
                                                    </div>
                                                </div>
                                            </CardBody>
                                            <CardFooter className="flex-center">
                                                <Button 
                                                color="blue"
                                                style={{
                                                    borderColor: pageEditData.color,
                                                    color: pageEditData.color
                                                }}
                                                >
                                                    เสนอขาย
                                                </Button>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                    <Col className="mb-10">
                                        <Card >
                                            <CardBody>
                                                <div className="header mb-10">
                                                    <img alt="userImg" src={userData?.profileImg ? userData?.profileImg : blank_img} className="user-img" />
                                                    <p className="name">{userData.name}</p>
                                                    <p className="oversize" style={{color: pageEditData.color}}>ต้องการ</p>
                                                    <div className="text-wrapper" style={{backgroundColor: pageEditData.color}}>
                                                        <FontAwesomeIcon icon={faLocationDot} className="mr-10 icon" />
                                                        <div className="label">
                                                            <p className="label-1">
                                                                จ.เชียงใหม่
                                                            </p>
                                                            <p className="label-2">
                                                                อ.เมืองเชียงใหม่
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <small className="date">
                                                        10 ม.ค 67
                                                    </small>
                                                </div>
                                                
                                                <div className="text-description">
                                                    <div className="font-weight-500 color-default mb-10" style={{color: pageEditData.color}}>
                                                        วัตถุประสงค์: 
                                                        <p className="font-weight-300 ml-10 color-black" >สร้างบ้าน</p>
                                                    </div>
                                                    <div className="flex font-weight-500 color-default mb-10" style={{color: pageEditData.color}}>
                                                        ขนาดเนื้อที่: <div className="font-weight-300 ml-10 color-black">200 ตร.ว. - 400 ตร.ว.</div>
                                                    </div>
                                                    <div className="flex font-weight-500 color-default " style={{color: pageEditData.color}}>
                                                        ราคารวม: 
                                                    </div>
                                                    <div className="color-black mb-10">
                                                        ฿0 - ฿2,000,000
                                                    </div>
                                                    <div className="flex font-weight-500 color-default" style={{color: pageEditData.color}}>
                                                        รายละเอียด: 
                                                    </div>
                                                    <div className="text-detail color-black">
                                                        หรือหากมีใกล้เคียงและติดถนนสามารถเสนอได้
                                                    </div>
                                                </div>
                                            </CardBody>
                                            <CardFooter className="flex-center">
                                                <Button 
                                                color="blue"
                                                style={{
                                                    borderColor: pageEditData.color,
                                                    color: pageEditData.color
                                                }}
                                                >
                                                    เสนอขาย
                                                </Button>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                    <Col className="mb-10">
                                        <Card >
                                            <CardBody>
                                                <div className="header mb-10">
                                                    <img alt="userImg" src={userData?.profileImg ? userData?.profileImg : blank_img} className="user-img" />
                                                    <p className="name">{userData.name}</p>
                                                    <p className="oversize" style={{color: pageEditData.color}}>ต้องการ</p>
                                                    <div className="text-wrapper" style={{backgroundColor: pageEditData.color}}>
                                                        <FontAwesomeIcon icon={faLocationDot} className="mr-10 icon" />
                                                        <div className="label">
                                                            <p className="label-1">
                                                                จ.เชียงใหม่
                                                            </p>
                                                            <p className="label-2">
                                                                อ.เมืองเชียงใหม่
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <small className="date">
                                                        10 ม.ค 67
                                                    </small>
                                                </div>
                                                
                                                <div className="text-description">
                                                    <div className="font-weight-500 color-default mb-10" style={{color: pageEditData.color}}>
                                                        วัตถุประสงค์: 
                                                        <p className="font-weight-300 ml-10 color-black" >สร้างบ้าน</p>
                                                    </div>
                                                    <div className="flex font-weight-500 color-default mb-10" style={{color: pageEditData.color}}>
                                                        ขนาดเนื้อที่: <div className="font-weight-300 ml-10 color-black">200 ตร.ว. - 400 ตร.ว.</div>
                                                    </div>
                                                    <div className="flex font-weight-500 color-default " style={{color: pageEditData.color}}>
                                                        ราคารวม: 
                                                    </div>
                                                    <div className="color-black mb-10">
                                                        ฿0 - ฿2,000,000
                                                    </div>
                                                    <div className="flex font-weight-500 color-default" style={{color: pageEditData.color}}>
                                                        รายละเอียด: 
                                                    </div>
                                                    <div className="text-detail color-black">
                                                        หรือหากมีใกล้เคียงและติดถนนสามารถเสนอได้
                                                    </div>
                                                </div>
                                            </CardBody>
                                            <CardFooter className="flex-center">
                                                <Button 
                                                color="blue"
                                                style={{
                                                    borderColor: pageEditData.color,
                                                    color: pageEditData.color
                                                }}
                                                >
                                                    เสนอขาย
                                                </Button>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>
                                <div style={{display:'flex', justifyContent:'center'}}>
                                    <Button
                                    color="default"
                                    style={{
                                        backgroundColor: pageEditData.color,
                                        color: 'white'
                                    }}
                                    >
                                        ดูกระกาศทั้งหมด
                                    </Button>
                                </div>
                            </div>
                            <div className="page-property">
                                <Row className="row-cols-2 row-cols-md-4">
                                    <Col className="mb-10">
                                        <div className="property-item" style={{backgroundColor:'#F2F3F4'}}>
                                            <label className="label-1" style={{color:'#000'}}>รวมประกาศขาย</label>
                                            <label className="label-2" style={{color:'#000'}}>ทรัพย์</label>
                                            <div className="icon">
                                                <FontAwesomeIcon icon={faBullhorn} />
                                            </div>
                                            <p className="text-number" style={{color: pageEditData.color}}>60</p>
                                        </div>
                                    </Col>
                                    <Col className="mb-10">
                                        <div className="property-item" style={{backgroundColor:'#F2F3F4'}}>
                                            <label className="label-1" style={{color:'#000'}}>ที่ดินทั้งหมด</label>
                                            <label className="label-2" style={{color:'#000'}}>ทรัพย์</label>
                                            <div className="icon">
                                                <FontAwesomeIcon icon={faMapLocationDot} />
                                            </div>
                                            <p className="text-number" style={{color: pageEditData.color}}>20</p>
                                        </div>
                                    </Col>
                                    <Col className="mb-10">
                                        <div className="property-item" style={{backgroundColor:'#F2F3F4'}}>
                                            <label className="label-1" style={{color:'#000'}}>บ้านทั้งหมด</label>
                                            <label className="label-2" style={{color:'#000'}}>ทรัพย์</label>
                                            <div className="icon">
                                                <FontAwesomeIcon icon={faHouse} />
                                            </div>
                                            <p className="text-number" style={{color: pageEditData.color}}>30</p>
                                        </div>
                                    </Col>
                                    <Col className="mb-10">
                                        <div className="property-item" style={{backgroundColor:'#F2F3F4'}}>
                                            <label className="label-1" style={{color:'#000'}}>อสังหาฯอื่นๆ</label>
                                            <label className="label-2" style={{color:'#000'}}>ทรัพย์</label>
                                            <div className="icon">
                                                <FontAwesomeIcon icon={faCity} />
                                            </div>
                                            <p className="text-number" style={{color: pageEditData.color}}>20</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="page-body-4 mb-20">
                                <div className="box-1">
                                    <div className="subBox">
                                        <div className="decorate-1"></div>
                                        <div className="decorate-2"></div>
                                        <img alt="" src={userData?.profileImg ? userData?.profileImg : blank_img} className="profile-img" />
                                    </div>
                                </div>
                                <div className="box-2">
                                    <div className="subBox">
                                        <p style={{fontSize: '3em', fontWeight: 500}}>{userData.name}</p>
                                        <h5 style={{marginBottom: 30}}>
                                            นายหน้าอสังหาริมทรัพย์
                                        </h5>
                                        <p>
                                            {pageEditData.detailProperty}
                                        </p>
                                    </div>
                                    <div className="subBox-2">
                                        <a 
                                        href="tel:020955198"
                                        className="btn btn-default mr-10" 
                                        style={{
                                            backgroundColor: pageEditData.color
                                        }}
                                        >
                                            <FontAwesomeIcon icon={faPhone} className="mr-10" />
                                            020-955-198
                                        </a>
                                        <a 
                                        href="tel:020955198"
                                        className="btn btn-default mr-10" 
                                        style={{
                                            backgroundColor: pageEditData.color
                                        }}
                                        >
                                            <FontAwesomeIcon icon={faFacebookF} />
                                        </a>
                                        <a 
                                        href="tel:020955198"
                                        className="btn btn-default mr-10" 
                                        style={{
                                            backgroundColor: pageEditData.color
                                        }}
                                        >
                                            <FontAwesomeIcon icon={faLine} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
            </ModalBody>
        </Modal>

        <Modal isOpen={saleToggle} toggle={saleToggleClose} className="modal-xl modal-salePin">
            <ModalHeader toggle={saleToggleClose}>
                เลือกอสังหาฯ ที่อยากแนะนำบนหน้าเว็บ
            </ModalHeader>
            <ModalBody >
                <>
                <small className="color-red">* เลือกได้สูงสุดไม่เกิน 10 แปลง</small>
                <Row className="row-cols-2 row-cols-md-3 mb-20">
                    {saleData.map((item, i) =>{
                        return(
                        <Col className="mb-10">
                            <Card key={i}>
                                <div className="card-tag">
                                    <div className="tag">
                                        {item?.saleType === "OWNER" ?
                                        <div className="tag-1 bg_default color-white mt-10">
                                            เจ้าของขายเอง
                                        </div>
                                        :
                                        null}
                                        {item.quick ? 
                                        <div className="tag-2 bg_redpink color-white mt-10">
                                            ขายด่วน
                                        </div>
                                        : null}
                                    </div>
                                    {pageEditData?.pin?.some((items) => items.saleID === item._id) === true ?
                                    <div 
                                    className="tag-pin"
                                    onClick={() => onDeleteSalePin(item._id)}
                                    >
                                        
                                        <FontAwesomeIcon icon={faThumbTack} className="icon pin" />
                                    </div>
                                    : 
                                    <div 
                                    className="tag-pin"
                                    onClick={() => onAddSalePin(item._id)}
                                    >
                                        
                                        <FontAwesomeIcon icon={faThumbTack} className="icon" />
                                        {/* <FontAwesomeIcon icon={faThumbTack} className="icon" /> */}
                                    </div>
                                    }
                                    <CardImg 
                                    alt={item?.titleDeedNumber}
                                    src={item?.images[0]?.path} 
                                    />
                                </div>
                                <CardBody>
                                    <div className="body-1">
                                        <CardTitle>
                                            {item?.title}
                                        </CardTitle>
                                        <CardText>{item?.description}</CardText>
                                    </div>
                                    <div className="body-2">
                                        <CardText>{intl.format(item?.totalPrice)}</CardText>
                                        {item?.pricePerWa ?
                                            <CardText className="color-default">{intl.format(item?.pricePerWa)}/ตร.ว.</CardText>
                                        :null}
                                    </div>
                                    <CardText>
                                        <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                        {item?.saleDistrict + ", " + item?.saleProvince}
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        )
                    })}
                </Row>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <Pagination
                        className="pagination-bar"
                        currentPage={salePageCurrent}
                        totalCount={saleCount}
                        pageSize={salePageSize}
                        onPageChange={page => setCurrentSalePage(page)}
                    />
                </div>
                </>
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={saleToggleClose}>
                    ตกลง
                </Button>
            </ModalFooter>
        </Modal>
        <Alert color="danger" isOpen={saleAlert}>
            เลือกครบ 10 แปลงแล้ว
        </Alert>
        </>
    )

}
export default Profiles;