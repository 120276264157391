import React from "react";
import Navbars from "routers/navbars";

import { 
    Button,
    Table, 
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Row,
    Col,
    Label,
    UncontrolledTooltip
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faCirclePlus, faArrowRight, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { PROVINCE_LIST } from "views/components/province";
import { DISTRICT_LIST } from "views/components/districts";
import axios from "axios";
import { LoginContext } from "components/loginContext/LoginContext";
import { useHistory } from 'react-router-dom';

const path_url = process.env.REACT_APP_API_CONNECT;
function FavoriteArea(){
    const [toggle, setToggle] = React.useState(false);
    const [DistrictList, setDistrictList] = React.useState([]);
    const [favo_Area, setFavo_Area] = React.useState({
        province: "",
        district: ""
    })
    const { userData } = React.useContext(LoginContext);
    const [dataList, setDataList] = React.useState([]);
    const history = useHistory();

    React.useEffect(() =>{
        if(userData && userData.role === "PREMIUM"){
            onFetch()
        }
    },[userData])

    async function onFetch(){
        try{
            const res = await axios.post(path_url+'/favoriteArea/getFavoriteArea',{
                userID: userData._id,
            })

            if(res.status === 200){
                setDataList(res.data)
            }
        }catch(e){
            console.log(e)
        }
    }

    const _onSelectProvince = (val) =>{
        const province = PROVINCE_LIST.find(element =>{
            return element.province_name === val
        });

        const district = DISTRICT_LIST.filter((item) =>{
            return item.province_id === province?.province_id
        }).map((element) =>{
            return element
        });
        setDistrictList(district);
        inputData(province.province_name, 'province')
        inputData("", "district")
    }

    const _onChangDistrict = (val) =>{
        const district = DISTRICT_LIST.find(element =>{
            return element.amphur_name === val
        })
        inputData(district.amphur_name, 'district')
    }

    function inputData(val, child){
        setFavo_Area((item) =>{
            const data = {...item}
            data[child] = val
            return data
        })
    }

    // async function onSubmit(){
    //     try{
    //         const date = new Date();
    //         date.setDate(date.getDate() + 30);

    //         const res = await axios.post(path_url+'/favoriteArea/addFavoriteArea',{
    //             userID: userData._id,
    //             expired: date
    //         })

    //         if(res.status === 200){
    //             alert('success')
    //         }
    //     }catch(e){
    //         console.log(e)
    //     }
    // }

    async function onSetRow(){
        try{
            const favoAddList = [...dataList]
            favoAddList.push({
                district: favo_Area.district, 
                province: favo_Area.province,
                createAt: new Date()
            })
            // console.log(favoAddList)
            const res = await axios.post(path_url+'/favoriteArea/updateFavoriteArea',{
                userID: userData._id,
                areas: favoAddList
            })

            if(res.status === 200){
                setFavo_Area({
                    province: "",
                    district: ""
                })
                setToggle(false)
                setTimeout(() => {
                    onFetch()
                },500)
            }
        }catch(e){
            console.log(e)
        }
    }

    return(
        <>
        {/* <Navbars /> */}
        <div className="favorite-area">
            <div className="container">
                <h3>ทำเลโปรดของท่าน</h3>
            </div>
            <div className="error-toast mb-20">
                <label>
                    <FontAwesomeIcon icon={faCircleInfo} /> สำหรับสมาชิกที่ชำระค่าบริการเท่านั้น หากท่านสนใจสามารถ <a href="/package">ดูเพิ่มเติม</a> ในหน้าบริการเสริม
                </label>
            </div>
            {/* <div>
                <Button onClick={() => onSubmit()}>add Favo</Button>
            </div> */}
            <div className="description mb-30">
                <label>การันตี ที่ดินลงใหม่ในทำเลโปรดของท่านภายในไม่เกิน 3 เดือน </label>
                <hr className="mt-10 mb-10 color-gray" />
                <label>ทำเลโปรดจะแจ้งเตือนทุกครั้งที่มีแปลงที่ดินใหม่ในพื้นที่ของท่าน</label>
                <hr className="mt-10 mb-10 color-gray" />
                <label>สามารถปักหมุดพื้นที่โปรดของท่านไม่เกิน 10 พื้นที่</label>
            </div>
            <div className="container-btn mb-10">
                <Button 
                color="sky" 
                size="sm" 
                onClick={() => setToggle(true)} 
                disabled={dataList.length >= 10 ? true : false || userData?.role === "PREMIUM" ? false : true}
                >
                    <FontAwesomeIcon icon={faCirclePlus} /> เพิ่ม
                </Button>
            </div>
            <div style={{overflowX: 'auto'}} className="mb-30">
                <Table>
                    <thead>
                        <tr>
                            <th className="w-10 text-center">ลำดับ</th>
                            <th className="w-20">อำเภอ/เขต</th>
                            <th className="w-20">จังหวัด</th>
                            <th className="text-center">เพิ่มเมื่อ</th>
                            <th className="text-center">จำนวนที่ดินในพื้นที่</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataList.map((item,i) =>{
                            const date = new Date(item.createAt).toLocaleString('th',{day: '2-digit', month:'short', year:'2-digit', hour:'2-digit' ,minute:'2-digit'})
                            return(
                                <tr key={i}>
                                    <td className="w-10 text-center">{i+1}</td>
                                    <td className="w-20">{item.district}</td>
                                    <td className="w-20">{item.province}</td>
                                    <td className="text-center">{date}</td>
                                    <td className="text-center">{item.count}</td>
                                    <td>
                                        <div style={{display: 'flex'}}>
                                            <Button 
                                            size="sm" 
                                            color="round-default-sm" 
                                            id={"tooltip1"+i} 
                                            onClick={() => history.push(`/favoritearealist?district=${item.district}&province=${item.province}`)}
                                            disabled={userData?.role === "PREMIUM" ? false : true}
                                            >
                                                <FontAwesomeIcon icon={faArrowRight} />
                                            </Button>
                                            <UncontrolledTooltip target={"tooltip1"+i} placement="right">ทั้งหมด</UncontrolledTooltip>
                                            <Button 
                                            size="sm" 
                                            color="round-danger-sm" 
                                            id={"tooltip2"+i}
                                            disabled={userData?.role === "PREMIUM" ? false : true}
                                            >
                                                <FontAwesomeIcon icon={faTrashCan} />
                                            </Button>
                                            <UncontrolledTooltip target={"tooltip2"+i} placement="right">ลบ</UncontrolledTooltip>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        
                    </tbody>

                </Table>
                {dataList.length === 0 ? 
                <div style={{
                    display: 'flex', 
                    justifyContent:'center', 
                    padding: 10, 
                    backgroundColor:'lightgrey',
                    width: '100%'
                }}
                >
                    ไม่พบรายการ
                </div>
                : null}
            </div>
        </div>
        <Modal isOpen={toggle} toggle={() => setToggle(false)}>
            <ModalHeader toggle={() => setToggle(false)}>เพิ่มทำเลโปรด</ModalHeader>
            <ModalBody>
                <Row>
                    <Col className="col-12">
                        <FormGroup className="form-group">
                            <Label>จังหวัด</Label>
                            <Input 
                            type="select"
                            className="form-control color-darkblue border"
                            placeholder="จังหวัด"
                            value={favo_Area.province}
                            onChange={(val) => _onSelectProvince(val.target.value)}
                            >
                                <option value={""} disabled>เลือก</option>
                                {PROVINCE_LIST.map((item, i) =>{
                                    return(
                                        <option key={i} value={item.province_name}>
                                            {item.province_name}
                                        </option>
                                    )
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col className="col-12">
                        <FormGroup className="form-group">
                            <Label>อำเภอ</Label>
                            <Input 
                            type="select"
                            className="form-control color-darkblue border"
                            placeholder="อำเภอ"
                            readOnly={DistrictList.length === 0 ? true : false}
                            value={favo_Area.district}
                            onChange={(val) => _onChangDistrict(val.target.value)}
                            >
                                <option value={""} disabled>เลือก</option>
                                {DistrictList.map((item, i) =>{
                                    return(
                                        <option key={i} value={item.amphur_name}>
                                            {item.amphur_name}
                                        </option>
                                    )
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="default" size="sm" onClick={() => onSetRow()}>
                    บันทึก
                </Button>
            </ModalFooter>
        </Modal>
        </>
    )
}
export default FavoriteArea;