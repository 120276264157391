import React from "react";
import Navbars from "routers/navbars";
import { useLocation } from "react-router-dom";
import banner from 'assets/img/Mask Group 78.png';
import banner1 from 'assets/img/Package/Group900.jpg';
import banner2 from 'assets/img/Package/Group1.jpg';

// import AdditionalService from "./packageTab/AdditionalService";
import MorePackage from "./packageTab/MoreService";
import ConsignmentService from "./packageTab/ConsignmentService";
import Provideland from "./packageTab/Provideland";

import Footer from "components/footer";

function ServicePage(){
    const [navActive, setNavActive] = React.useState('1');

    function useQuery() {
        const { search } = useLocation();
        return new URLSearchParams(search);
    }

    const query = useQuery();

    React.useEffect(() =>{
        const service = query.get("service")
        setNavActive(service ? service : '0')
    },[])

    return(
        <>
            <Navbars />
            <div className="service-container">
                {navActive === '0' ?
                <img alt="img-banner" src={banner1} className="banner-package" />
                : navActive === '1' ? 
                <img alt="img-banner" src={banner2} className="banner-package" />
                :
                <img alt="img-banner" src={banner} className="banner-package" />
                }
                
                <div className="package-nav mb-30">
                    {/* <div className={navActive === '0' ? "nav-item active" : "nav-item"} onClick={() => setNavActive('0')}>
                        บริการเสริม
                    </div> */}
                    <div className={navActive === '0' ? "nav-item active" : "nav-item"} onClick={() => setNavActive('0')}>
                        บริการขายฝาก/จำนอง
                    </div>
                    <div className={navActive === '1' ? "nav-item active" : "nav-item"} onClick={() => setNavActive('1')}>
                        บริการจัดหาที่ดิน
                    </div>
                    <div className={navActive === '2' ? "nav-item active" : "nav-item"} onClick={() => setNavActive('2')}>
                        บริการอื่นๆ
                    </div>
                </div>
                <div className="show-package">
                {navActive === '0' ? 
                    <>
                        <ConsignmentService />
                    </>
                : navActive === '1' ? 
                    <>
                        <Provideland />
                    </>
                : navActive === '2' ? 
                    <>
                        <MorePackage />
                    </>
                : null}
                </div>
            </div>
            <Footer />
        </>
    )
}
export default ServicePage;