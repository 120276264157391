import React from "react";

import { Helmet, HelmetData } from 'react-helmet-async';

const helmetData = new HelmetData({});

export const SEO = ({ title, description, type, image, url }) => {
    return (
      <Helmet helmetData={helmetData}>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={image} />
        <meta property="og:description" content={description} />
        <meta property="fb:app_id" content="1202029513835683" />
        <meta name="twitter:card" content={type} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:site" content={url} />
      </Helmet>
    );
  };