import React from "react";
import { 
    Button, 
    Row, 
    Col,
    Card,
    CardBody,
    CardImg
} from "reactstrap";

import exp_1 from 'assets/img/Package/provide/Asset 3.png';
import exp_2 from 'assets/img/Package/provide/Asset 2.png';
import exp_3 from 'assets/img/Package/provide/Asset 1.png';

const line_url = process.env.REACT_APP_LINE_PATH;

function Provideland(){
    return(
        <>
        <div className="provide-container">
            <h3 className="color-default text-center mb-50 font-weight-500">
                บริการรับจัดหาที่ดิน
            </h3>
            <div className="text text-center mb-50">
                <p className="text color-default">
                บริการรับจัดหาที่ดินอีกหนึ่งบริการโดยตลาดที่ดิน การันตีด้วยประสบการณ์ในธุรกิจอสังหาริมทรัพย์ 
                โดยเฉพาะด้านการจัดหาและรวบรวมแปลงที่ดินให้กับบริษัทฯ กลุ่มทุน ที่มีความต้องการซื้อที่ดิน ไม่ว่าจะเป็น CPN, ศุภาลัย ฯลฯ
                </p>
                ประกอบกับ เครือข่ายและข้อมูลที่ดิน จาก เจ้าของที่ดิน และ agent ทั่วประเทศ  
                และเรามีที่ดินอีกมากมายที่เจ้าของ ไม่ประสงค์ลงประกาศขายผ่านสื่ออินเตอร์เน็ต และไม่ต้องการปักป้ายขายที่ดิน  
                หากท่านมีความต้องการซื้อที่ดิน เพียงแจ้งความต้องการ, ทำเล, เนื้อที่, วัตถุประสงค์, งบประมาณ กับเรา  
                เราสามารถจัดหาและรวบรวมให้ท่านได้ตามโจทย์ที่ได้รับ
            </div>
            <div className="bg-img" >
                <div className="contianer-ads">
                    <hr style={{width:'10%', borderColor:'gray', borderWidth:1.2}}/>
                    <div className="text-ads">
                        ตัวอย่างงานของเรา
                    </div>
                    <hr style={{width:'10%', borderColor:'gray', borderWidth:1.2}} />
                </div>
            </div>
            <div className="example-list mb-50">
                <Row className="row-cols-1 row-cols-md-3 row-cols-lg-3 row-cols-xl-3">
                    <Col className="mb-10">
                        <Card>
                            <CardImg alt="exp-1" src={exp_1} />
                            <CardBody>
                                <p style={{fontSize:20, color:'#BDC3C7'}} className="mb-20">01</p>
                                <p className="color-default" style={{fontSize:25}}>จัดหาที่ให้</p>
                                <b className="color-default" style={{fontSize:25}}>โครงการ ศุภาลัย</b>
                                <p className="color-default mb-10" style={{fontSize:20}}>อ.เมืองเชียงราย จ.เชียงราย</p>
                                <p className="color-gray">เนื้อที่ 199-0-85.40 ไร่</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="mb-10">
                        <Card>
                            <CardImg alt="exp-2" src={exp_2} />
                            <CardBody>
                                <p style={{fontSize:20, color:'#BDC3C7'}} className="mb-20">02</p>
                                <p className="color-default" style={{fontSize:25}}>จัดหาที่ให้</p>
                                <b className="color-default" style={{fontSize:25}}>โครงการ นิรติ เชียงราย</b>
                                <p className="color-default mb-10" style={{fontSize:20}}>อ.เวียงชัย จ.เชียงราย </p>
                                <p className="color-gray">เนื้อที่ 76-1-2.6 ไร่</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="mb-10">
                        <Card>
                            <CardImg alt="exp-3" src={exp_3} />
                            <CardBody>
                                <p style={{fontSize:20, color:'#BDC3C7'}} className="mb-20">03</p>
                                <p className="color-default" style={{fontSize:25}}>จัดหาที่ให้</p>
                                <b className="color-default" style={{fontSize:25}}>ศุภาลัย</b>
                                <p className="color-default mb-10" style={{fontSize:20}}>อ.เมืองเชียงราย จ.เชียงราย</p>
                                <p className="color-gray">เนื้อที่ 74-3-41.7 ไร่</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            <div className="provide-footer">
                <Button 
                color="default"
                onClick={() => window.open(line_url)}
                >
                    ขอข้อมูลเพิ่มเติม
                </Button>
            </div>
        </div>
        </>
    )
}
export default Provideland