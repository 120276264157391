import React from "react";
import { 
    Switch, 
    Route, 
    Redirect, 
    useParams, 
    useHistory,
    Link
} from "react-router-dom";
import logo from 'assets/img/taladteedin-logo.png';
import FooterCustom from "components/footerCustom";
import { PageContext } from "./PageContext";
import LoadingIndicretor2 from "views/components/loading2";
import { Helmet } from "react-helmet-async";

import MyPage from "./MyPage";
import MyMaps from "./MyMaps";
import MyDetail from "./MyDetail";

const RouterDash = [
    {
        path: "/home",
        component: MyPage,
        layout: "/page"
    },
    {
        path: "/maps",
        component: MyMaps,
        layout: "/page"
    },
    {
        path: "/detail",
        component: MyDetail,
        layout: "/page"
    }
]

function PageRouter(){
    const { pageData, _onFetch, color } = React.useContext(PageContext);
    // const [visible] = React.useState(true)
    let { name } = useParams();
    const history = useHistory();

    React.useEffect(() =>{
        _onFetch(name)
    },[])

    var bodyStyles = document.body;
    bodyStyles.id = "style-2"
    bodyStyles.style.setProperty('--color-custom', color);

    // console.log(pageData)

    return(
        pageData ? 
            <>
            <Helmet>
                <title>{pageData?.page?.titleName}</title>
                <link rel="icon" href={pageData?.page?.logo} />
                <meta name="title" content={pageData?.page?.titleName} />
                <meta name="description" content={pageData?.page?.detailProperty}/>
                <meta property="og:title" content={pageData?.page?.titleName} />
                <meta property="og:description" content={pageData?.page?.detailProperty} />
                <meta property="og:site_name" content={pageData?.page?.titleName} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={pageData?.page?.bannerImg} />
                {/* <meta property="og:image:type" content="image/jpg" /> */}
                <meta property="fb:app_id" content="1202029513835683" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={pageData?.page?.titleName} />
                <meta name="twitter:description" content={pageData?.page?.detailProperty} />
                <meta name="twitter:image" content={pageData?.page?.bannerImg} />
            </Helmet>
            <div className="page-container">
                <nav className="navbar">
                    <div className="container">
                        <img alt="" 
                        src={pageData?.page?.logo ? pageData?.page?.logo : logo} 
                        style={{height: 50, cursor:'pointer'}} 
                        onClick={() => history.push(`/page/${name}/home`)}
                        />
                        <div className="nav-menu">
                            <Link 
                            to={`/page/${name}/home`} 
                            className="nav-item mr-20"
                            style={{color: color}}
                            >
                                หน้าแรก
                            </Link>
                            <Link 
                            to={`/page/${name}/maps`} 
                            className="nav-item mr-20"
                            style={{color: color}}
                            >
                                ค้นหาแผนที่
                            </Link>
                            <a 
                            href={pageData?.page?.social?.lineUrl} 
                            target="_blank"
                            className="nav-item mr-20"
                            style={{color: color}}
                            >
                                ติดต่อเรา
                            </a>
                        </div>
                    </div>
                    
                </nav>
                <main className="page-wrapper-body">
                    <Switch>
                        {RouterDash.map((prop, key) =>{
                            return (
                                <Route  
                                path={prop.layout + `/${name}` + prop.path}
                                component={prop.component}
                                key={key}
                                />
                            )
                        })}
                        <Redirect from={`/page/${name}`} to={`/page/${name}/home`} />  
                    </Switch>
                </main>
            </div>
            <FooterCustom />
            </>
        :
        <div style={{
            width:'100%', 
            height:'100vh', 
            display:'flex', 
            alignItems:'center',
            justifyContent:'center',
            flexDirection:'column',
            fontSize: 25
        }}
        >
            <LoadingIndicretor2
            visible={true}
            className=""
            alt=""
            />
            <label>รอสักครู่.........</label>
        </div>
    )
}
export default PageRouter;