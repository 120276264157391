import React from "react";

import {
    Row,
    Col,
    Label,
    Card,
    CardBody,
} from 'reactstrap';
import Navbars from "routers/navbars";
import Footer from "components/footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faBuilding,
    faPhone,
    faGlobe
} from "@fortawesome/free-solid-svg-icons";
import {
    faFacebookSquare,
    faYoutube,
    faTiktok
} from '@fortawesome/free-brands-svg-icons';
import { faClock } from "@fortawesome/free-regular-svg-icons";

import service1 from 'assets/img/Home/ขายได้.jpg';
import service2 from 'assets/img/Home/ซื้อง่าย2.jpg';
import service3 from 'assets/img/Home/partnership.jpg';

const line_url = process.env.REACT_APP_LINE_PATH;

function About(){

    return(
        <>
        <Navbars />
        <div className="about-containter">
            <div className="about-banner mb-50">
                <div className="text-header">เกี่ยวกับเรา</div>
            </div>
        </div>
        <div className="about-content-1 mb-50">
            <p className="color-default mb-20" style={{fontSize:'2em'}}>ความเป็นมาของ “ตลาดที่ดิน”</p>
            <p className="mb-30">  จากประสบการณ์ในธุรกิจการพัฒนาอสังหาริมทรัพย์ของทีมผู้ก่อตั้ง  ได้พบปัญหารวมถึงความไม่สะดวกสบายในกระบวนการซื้อขายที่ดินอยู่หลายอย่าง ยกตัวอย่างเช่น</p>
            <ul className="ul mb-30">
                <li className="mb-10">
                    การค้นหาที่ดิน  (โดยเฉพาะบนสื่อออนไลน์ต่าง ๆ) ตามโจทย์ความต้องการนั้น  ทำได้ยากมาก และใช้เวลานานมาก ในการค้นหาและรวบรวมข้อมูลของที่ดินแปลงต่าง ๆ
                </li>
                <li className="mb-10">
                    ปัญหาความไม่ถูกต้อง, คลาดเคลื่อน, ไม่อัพเดท หรือไม่ครบถ้วนของข้อมูลต่าง ๆ ไม่ว่าจะเป็นด้านราคา,  สถานะการซื้อขาย, เอกสารไม่ครบถ้วน, ข้อมูลไม่ตรงกัน เป็นต้น
                </li>
                <li className="mb-10">
                    มีความไม่โปร่งใสในการซื้อขาย มีการปกปิดข้อมูล
                </li>
                <li className="mb-10">
                    ในหลาย ๆ ครั้ง ไม่สามารถเข้าถึงหรือติดต่อเจ้าของที่ดินได้
                </li>
                <li className="mb-10">
                    ไม่มีแพลตฟอร์ม หรือเว็บไซต์ใดเลย ที่ทำหน้าที่สื่อกลางในการนำเสนอข้อมูลที่ดินที่มีการซื้อขายในตลาดได้ดีเพียงพอ
                </li>
            </ul>
            <p className="mb-30">ทีมผู้ก่อตั้ง "ตลาดที่ดิน" มีความตั้งใจที่จะแก้ไขปัญหาเหล่านี้ และพัฒนากระบวนการซื้อขายที่ดิน ของประเทศไทย ให้สามารถทำได้ง่ายสะดวก รวดเร็ว โปร่งใส จึงได้พัฒนาระบบการซื้อขายบน platform ของตลาดที่ดิน</p>
        </div>
        <div className="about-content-2 mb-50">
            <p className="color-default mb-20" style={{fontSize:'2em'}}>ทำไมต้องเลือกใช้บริการเว็บของเรา</p>
            <p className="mb-30">ณ ปัจจุบัน เว็บไซต์ตลาดที่ดิน เป็นเว็บไซต์เดียวที่มุ่งเน้นไปที่การให้บริการสื่อกลางการซื้อขายที่ดินโดยเฉพาะ</p>
            <ul className="ul mb-30">
                <li className="mb-10">
                    การออกแบบเว็บไซต์ของตลาดที่ดิน รวมถึงรูปแบบการให้บริการ จึงได้รับการออกแบบสำหรับรองรับการค้นหาและนำเสนอข้อมูลที่สำคัญของ
                    ที่ดินแต่ละแปลงออกมาอย่างชัดเจน  เรียบง่ายที่สุด  บนระบบแผนที่และรูปแปลง  ซึ่งสามารถสื่อถึงทำเล  ตำแหน่ง  ขอบเขต  ขอบที่ดิน  
                    อันเป็นคุณสมบัติสำคัญของแปลงที่ดินออกมาได้ดีที่สุด
                </li>
                <li className="mb-10">
                    บนเว็บไซต์ของตลาดที่ดิน  ผู้ค้นหา  สามารถเปรียบเทียบข้อมูลสำคัญ ๆ ต่าง ๆ ของแปลงที่ดินกับแปลงอื่น ๆ ที่อยู่ใกล้เคียงกันหรือในทำเล
                    เดียวกันได้ในหน้าจอเดียวกัน
                </li>
            </ul>
        </div>
        <div className="about-content-3 mb-50">
            <p className="color-default mb-20" style={{fontSize:'2em'}}>ทำไมต้องเป็นเรา</p>
            <p className="mb-30">นอกเหนือจากการออกแบบเว็บไซต์ และการออกแบบบริการที่มุ่งเน้นไปที่การซื้อขายที่ดินแล้ว วัตถุประสงค์ที่สำคัญที่สุดของ "ตลาดที่ดิน" คือ เกิดการซื้อขายขึ้นจริง</p>
            <ul className="ul mb-30">
                <li className="mb-10">
                    ผู้ขาย สามารถขายที่ดินได้จริง
                </li>
                <li className="mb-10">
                    ผู้ซื้อ ได้ที่ดินตามโจทย์ความต้องการ
                </li>
                <li className="mb-10">
                    ตลาดที่ดิน มีที่ดินมากที่สุด ทุกทำเล และมีระบบตรวจสอบ รายงานข้อมูล ของแปลงที่ดิน
                </li>
            </ul>
        </div>
        <div className="about-content-4 mb-50">
            <Label className="title color-darkblue">
                ทำไมต้องเลือกบริการจากเรา?
            </Label>
            <Row className="row-cols-1 row-cols-md-3 row-cols-sm-1">
                <Col style={{marginBottom:10}}>
                    <Card className="btn" onClick={() => window.location.href = "/forsale"}>
                        <CardBody>
                            <div className="header">
                                <img alt="" src={service1} className="icon" />
                                <div style={{fontSize:'2em', fontWeight:400}} className="color-default">ขายได้</div>
                            </div>
                            <ul style={{marginLeft:10, marginBottom:10}} className="color-darkblue">
                                <li>
                                    เพิ่มโอกาสขาย เพิ่มการมองเห็นบนโลกออนไลน์
                                </li>
                                <li>
                                    ช่วยจับคู่ที่ดิน ให้ Matching กับผู้ซื้อ
                                </li>
                                <li>
                                    มีที่ปรึกษา มีผู้ช่วย ทุกขั้นตอน
                                </li>
                            </ul>
                        </CardBody>
                    </Card>
                </Col>
                <Col style={{marginBottom:10}}>
                    <Card className="btn" onClick={() => window.location.href = "/map"}>
                        <CardBody>
                            <div className="header">
                                <img alt="" src={service2} className="icon" />
                                <div style={{fontSize:'2em', fontWeight:400}} className="color-default">ซื้อง่าย</div>
                            </div>
                            <ul style={{marginLeft:10, marginBottom:10}} className="color-darkblue">
                                <li>
                                    หาที่ดินง่าย เพียงเลื่อนแผนที่ ได้ข้อมูลครบ
                                </li>
                                <li>
                                    ที่ดินไม่ถูกใจ ระบุโจทย์ไว้ ให้เราช่วยหา
                                </li>
                                <li>
                                    มีที่ปรึกษาที่ไว้ใจได้ ให้คำปรึกษาก่อนตัดสินใจ
                                </li>
                            </ul>
                        </CardBody>
                    </Card>
                </Col>
                <Col style={{marginBottom:10}}>
                    <Card className="btn" onClick={() => window.open(line_url)}>
                        <CardBody>
                            <div className="header">
                                <img alt="" src={service3} className="icon" />
                                <div style={{fontSize:'2em', fontWeight:400}} className="color-default">มีผู้ช่วย</div>
                            </div>
                            <ul style={{marginLeft:10, marginBottom:10}} className="color-darkblue ul-color-default">
                                <li>
                                    ช่วยลงประกาศที่ดินให้คุณ
                                </li>
                                <li>
                                    ช่วยให้คำปรึกษาให้กับคุณ
                                </li>
                                <li>
                                    ช่วยตอบคำถาม คลายข้อสงสัยให้กบคุณ
                                </li>
                            </ul>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
        <div className="company-info">
            <Row>
                <Col className="col-12 col-md-8 col-lg-8 mb-20">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d428.7550233031974!2d99.85895475608861!3d19.911591380827954!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30d7058ca84f73fd%3A0x6e523269e205b362!2z4LiV4Lil4Liy4LiU4LiX4Li14LmI4LiU4Li04LiZ!5e0!3m2!1sth!2sth!4v1659461761750!5m2!1sth!2sth" 
                title="dsf"
                className="map-info" 
                style={{border:0}} 
                allowFullScreen="true" 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
                />
                </Col>
                <Col className="col-12 col-md-4 col-lg-4 mb-20">
                    <div className="flex align-center mb-30 color-black">
                        <FontAwesomeIcon icon={faBuilding} size='2x'  />
                        <div className="ml-10">
                            <h5>บริษัท ตลาดที่ดินดอทคอม จำกัด</h5>
                            <p>35/91 หมู่ 22 อำเภอเมืองเชียงราย จังหวัดเชียงราย 57000</p>
                        </div>
                    </div>
                    <div className="flex align-center mb-30 color-black">
                        <FontAwesomeIcon icon={faPhone} size='2x'  />
                        <div className="ml-10">
                            <h5>ติดต่อ</h5>
                            <p>096 414 6351</p>
                        </div>
                    </div>
                    <div className="flex align-center mb-30 color-black">
                        <FontAwesomeIcon icon={faClock} size='2x'  />
                        <div className="ml-10">
                            <h5>จันทร์ - เสาร์</h5>
                            <p>08 : 30 น. - 17 : 00 น.</p>
                        </div>
                    </div>
                    <div className="flex align-center mb-30 color-black">
                        <FontAwesomeIcon icon={faGlobe} size='2x'  />
                        <div className="ml-10">
                            <h5>ช่องทางติดตาม</h5>
                            <div className="flex">
                                <FontAwesomeIcon icon={faFacebookSquare} className="btn ml-10 mr-10 color-facebook" size="2x" />
                                <FontAwesomeIcon icon={faTiktok} className="btn ml-10 mr-10 color-tiktok" size="2x" />
                                <FontAwesomeIcon icon={faYoutube} className="btn ml-10 mr-10 color-youtube" size="2x" />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
        <Footer />
        </>
    )
}
export default About;