import axios from "axios";
import { ErrorAlert } from "components/alert";
import React from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardImg,
    CardText,
    CardTitle,
} from 'reactstrap';
import Navbars from "routers/navbars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faLocationDot,
    faXmark
} from '@fortawesome/free-solid-svg-icons';
import Pagination from "views/pagination/Pagination";
import Favorite from "components/favorites";
import { LoginContext } from "components/loginContext/LoginContext";

const path_url = process.env.REACT_APP_API_CONNECT;

function FavoriteMN(){
    const [fetch, setFetch] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize] = React.useState(9);
    const [count, setCount] = React.useState(0);
    const { userData, setUserData } = React.useContext(LoginContext);

    React.useEffect(() =>{
        if(userData !== null){
            onFetch(userData._id)
        }
    },[userData])

    const onFetch = async (id) =>{
        try{
            const res = await axios.post(path_url+'/user/getUserFavorite',{
                _id: id,
            })

            if(res.status === 200){
                setFetch(res.data.data.favorites)
                setCount(res.data.count)
            }
        }catch(e){
            console.log(e)
            // ErrorAlert({text: 'ไม่พบข้อมูล กรุณาลองใหม่อีกครั้ง'})
        }
    }

    const onChangePage = (page) =>{
        // console.log(page)
        setCurrentPage(page);
    }

    const intl = new Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 0,
    })

    const checkFavorite = async (id) =>{
        if(userData !== null){
            return userData.favorites.some((item) => item === id)
        }else{
            return null
        }
    }

    async function onFavorite(id){
        const fa = await checkFavorite(id)
        if(fa === false){
            try{
                const data = {...userData}
                const favo = [...data.favorites, id]
                const res = await axios.post(path_url+'/user/updateFavorites',{
                    _id: userData._id,
                    favorites: favo
                })

                if(res.status === 200){
                    data['favorites'] = favo
                    // localStorage.setItem('profile', JSON.stringify({...data}));
                    setUserData({...data})
                }
            }catch(e){
                console.log(e)
            }
        }else if(fa === true){
            try{
                const data = {...userData};
                const favo = [...data.favorites];
                const dataFilter = favo.filter((item) => item !== id)
                const res = await axios.post(path_url+'/user/updateFavorites',{
                    _id: userData._id,
                    favorites: dataFilter
                })

                if(res.status === 200){
                    data['favorites'] = dataFilter
                    // localStorage.setItem('profile', JSON.stringify({...data}));
                    setUserData({...data})
                }
            }catch(e){
                console.log(e)
            }
        }else{
            return;
        }
    }

    const toEstateDetail = (val) =>{
        window.open(`/estate_detail/?sale=${val}`)
        // router.push(`/EstateDetail/?sale=${val}`)
    }

    return(
        <>
        <div className="favorite-container">
            <div className="header mb-30 color-darkblue">รายการโปรด</div>
            {fetch.length !== 0 ?
            <>
            <Row className="row-cols-1 row-cols-md-3 row-cols-lg-3">
                {Array.from(fetch).map((item, key) => {
                    return(
                        <Col className="mb-20" key={key}>
                            <Card
                            style={{
                                cursor:'pointer'
                            }}
                            onClick={() => item?.status === 'SOLD' ? null : toEstateDetail(item?._id)}
                            >
                                <div className="card-tag">
                                    {/* {item?.saleType === "OWNER" ? */}
                                    <div className="tag bg_default color-white">
                                        เจ้าของขายเอง
                                    </div>
                                    {/* :
                                    null} */}
                                    {/* <div className="tag-2 bg_redpink color-white">
                                        ขายด่วน
                                    </div> */}
                                    <Favorite onFavorite={() => onFavorite(item?._id)} favorite={userData !== null ? userData.favorites.some((items) => items === item?._id) : false} />
                                    <CardImg 
                                    alt={item?.titleDeedNumber}
                                    src={item?.images[0]?.path} 
                                    />
                                </div>
                                <CardBody>
                                    <div className="body-1">
                                        <CardTitle>
                                            {item?.title}
                                        </CardTitle>
                                        <CardText>{item?.description}</CardText>
                                    </div>
                                    <div className="body-2">
                                        <CardText>{intl.format(item?.totalPrice)}</CardText>
                                        {item?.pricePerWa ?
                                            <CardText className="color-default">{intl.format(item?.pricePerWa)}/ตร.ว.</CardText>
                                        :null}
                                    </div>
                                    <CardText>
                                        <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                        {item?.saleDistrict + ", " + item?.saleProvince}
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    )
                })}
            </Row> 
            <div className="favorite-footer">
                <Pagination 
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={count}
                pageSize={pageSize}
                onPageChange={page => onChangePage(page)}
                />
            </div>
            </>
            : 
            <>
            <div
            style={{
                display:'flex',
                flexDirection:'row',
                justifyContent:'center'
            }}
            >
                <FontAwesomeIcon icon={faXmark} className="mr-10" /> ไม่พบรายการโปรด
            </div>
            </>
            }
        </div>
        </>
    )
}

export default FavoriteMN