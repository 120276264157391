import React from "react";
import { 
    Row,
    Col,
    Card,
    CardBody,
    CardImg,
    CardFooter,
    CardTitle,
    CardText,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
    InputGroup,
    Spinner,
    FormText
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLocationDot,
    faTrashCan
} from '@fortawesome/free-solid-svg-icons';
import {
    faPenToSquare,
    faImage
} from '@fortawesome/free-regular-svg-icons';

import Pagination from "views/pagination/Pagination";
import { PROVINCE_LIST } from "views/components/province";
import { DISTRICT_LIST } from "views/components/districts";
import axios from "axios";
import { ErrorAlert, SuccessAlert } from "components/alert";
// import Navbars from "routers/navbars";
import Swal from "sweetalert2";
import { LoginContext } from "components/loginContext/LoginContext";

const path_url = process.env.REACT_APP_API_CONNECT;
const OmiseCard = window.OmiseCard;

const domain = `${window.location.origin}${window.location.pathname}`
const share_url = domain;

function Sale(){
    const inputFile = React.useRef(null);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize] = React.useState(9);
    const [fetch, setFetch] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const { userData } = React.useContext(LoginContext);
    const [editToggle, setEditToggle] = React.useState(false);
    const [editData, setEditData] = React.useState(null);
    const [districtList, setDistrictList] = React.useState([]);
    const [packageChange, setPackageChange] = React.useState(null);
    const [newImage, setNewImage] = React.useState({
        preview: [], 
        value: []
    })
    const [loading, setLoading] = React.useState(false);

    const onButtonClick = () => {
        const imageCount = Array.from(editData.images).length;
        const previewCount = Array.from(newImage.value).length;
        const sumImage = imageCount + previewCount
        if(sumImage < 8){
            inputFile.current.click();
        }else{
            ErrorAlert({text: 'รูปภาพเกิน 8 รูปแล้ว'})
        }
    };

    React.useEffect(() => {
        // const user = localStorage.getItem("profile")
        // const parse = JSON.parse(user)
        if(userData !== null){
            onFetch({},userData._id)
        }
        
    },[userData])

    const EditToggle = (val) =>{
        setEditData(val)
        setEditToggle(true)
        onStartProvince(val.saleProvince)
    }

    const EditToggleClose = () =>{
        setEditData(null)
        setEditToggle(false)
        setPackageChange(null)
        setNewImage({preview:[], value:[]})
    }

    const onEdit = (val, prop) =>{
        setEditData((item) =>{
            const data = {...item}
            data[prop] = val
            return {...data}
        })
    }

    const onEditSize = (val, prop) =>{
        setEditData((items) =>{
            const data = {...items}
            data['size'][prop] = parseFloat(val)
            return {...data}
        })
    }

    const onFetch = async (page=1, userid) =>{
        const skip = (page - 1) * pageSize;
        try{
            const datafetch = await axios.post(path_url+'/sale/findSalesByUser',{
                userid: userid,
                skip: skip
            })

            if(datafetch.status === 200){
                setFetch(datafetch.data.fetch);
                setCount(datafetch.data.count);
            }
        }catch(e){
            if(e.response.status === 404){
                ErrorAlert({text: 'ไม่พบข้อมูลของท่าน กรุณาลองใหม่อีกครั้ง'})
            }else{
                console.log(e)
                // ErrorAlert({text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'})
            }
        }
    }

    const onStartProvince = (val) =>{
        const province = PROVINCE_LIST.find(element =>{
            return element.province_name === val
        });

        const district = DISTRICT_LIST.filter((item) =>{
            return item.province_id === province?.province_id
        }).map((element) =>{
            return element
        });
        setDistrictList(district);
    }

    const _onSelectProvince = (val) =>{
        const province = PROVINCE_LIST.find(element =>{
            return element.province_name === val
        });

        const district = DISTRICT_LIST.filter((item) =>{
            return item.province_id === province?.province_id
        }).map((element) =>{
            return element
        });
        setDistrictList(district);
        onEdit(val, 'saleProvince');
    }

    const _onSelectDistrict = (val) =>{
        const district = DISTRICT_LIST.find(element =>{
            return element.amphur_name === val
        })
        onEdit(district.amphur_name, 'saleDistrict');
    }

    const onChangePage = (page) =>{
        // console.log(page)
        setCurrentPage(page);
        onFetch(page, userData._id)
    }

    const intl = new Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 0,
    })

    const onPackageChange = (val) =>{
        switch(val){
            case "STANDARD" : setPackageChange({type: "STANDARD", price: 0});
            break;
            case "PERPLOT" : setPackageChange({type: "PERPLOT", price: 500});
            break;
            default : setPackageChange({type: "STANDARD", price: 0});
            break;
        }
    }

    const onEditSubmit = (e) =>{
        e.preventDefault();
        setLoading(true);
        updateSaleData1()
    }

    const uploadImage = (files) => new Promise(async (resolve, reject) =>{
        try{
            const form = new FormData();
            files.map(item =>{
                return form.append('fileImage', item)
            })
            const result = await axios.post(path_url+'/upload/updateImageSale', form, {
                headers:{
                    'Content-Type' : 'multipart/form-data'
                }
            })
            if(result.status === 200)
            resolve(result.data)
        }catch(e){
            reject(e)
        }
    })

    const updateSaleData1 = async () =>{
        // console.log(newImage)
        try{
            if(newImage.value.length !== 0){
                const result = await uploadImage(newImage.value);

                if(result){
                    // console.log(result)
                    const imagePush = [...editData.images];
                    result.map(item => {
                        return imagePush.push({path: item.path})
                    })
                    // console.log(imagePush)
                    
                    const res = await axios.post(path_url+'/sale/updateSaleByUser',{
                        ...editData,
                        images: imagePush,
                    })

                    if(res.status === 200){
                        setFetch((prevItems) =>{
                            return prevItems.map(item =>{
                                if(item._id === editData._id){
                                    item = {...editData, images: imagePush}
                                }
                                return item;
                            })
                        },EditToggleClose())
                        SuccessAlert()
                        setLoading(false)
                    }
                }
            }else{
                const res = await axios.post(path_url+'/sale/updateSaleByUser',{
                    ...editData,
                })

                if(res.status === 200){
                    setFetch((prevItems) =>{
                        return prevItems.map(item =>{
                            if(item._id === editData._id){
                                item = {...editData}
                            }
                            return item;
                        })
                    },EditToggleClose())
                    setLoading(false);
                    SuccessAlert()
                }
            }
        }catch(e){
            ErrorAlert({text: 'Server Error'})
            setLoading(false)
        }
    }

    const onImagePush = (val) =>{
        setNewImage(item =>{
            const preview = [...item.preview];
            preview.push(URL.createObjectURL(val[0]))
            const image = [...item.value];
            image.push(val[0])
            return{...item, preview: preview, value: image}
        })
    }

    const deleteImage = (key) => {
        setEditData(item =>{
            const data = {...item};
            const image = [...data.images]
            const remove = image.filter((item,i) => i !== key)
            return {...data, images: [...remove]}
        })
    }

    const removePreview = (id) =>{
        setNewImage(item =>{
            const preview = [...item.preview];
            const image = [...item.value];
            const removePreview = preview.filter((item,i) => i !== id)
            const removeImage = image.filter((item,i) => i !== id)
            return{...item, preview: [...removePreview], value: [...removeImage]}
        })
    }

    function HandleRemove(item){
        Swal.fire({
            icon: 'question',
            text: `ต้องการลบ โฉนด ${item.titleDeedNumber} อ.${item.saleDistrict} จ.${item.saleProvince} ?`,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'ลบเลย!',
            cancelButtonText: 'ยกเลิก',
            confirmButtonColor: '#EF9751',
            cancelButtonColor : '#8c8c8c',
            reverseButtons: true
        }).then((result) =>{
            if(result.isConfirmed){
                removeSale(item)
            }
        }).catch(e =>{
            console.log(e)
        })
    }

    async function removeSale(item){
        try{
            const res = await axios.post(path_url+'/sale/remove_sale',{
                _id: item._id
            })

            if(res.status === 200){
                SuccessAlert()

                setTimeout(() => {
                    window.location.reload()
                }, 1500);
            }
        }catch(e){
            console.log(e)
            ErrorAlert({text: 'ไม่สามารถบันทึกการเปลี่ยนแปลงข้อมูลได้ กรุณาลองใหม่อีกครั้ง!'})
        }
    }

    return(
        <>
        <div className="sale-container">
            <div className="header mb-30 color-darkblue">บ้านและที่ดิน ของท่าน</div>
            {/* <div className="sub-header mb-20">
                <div className="flex mr-10" style={{fontWeight:500, fontSize:20}}>
                    ทั้งหมด : <label className="ml-10" style={{fontWeight:500, fontSize:20}}>{userData ? userData.land + userData.house : 0}</label> ,
                </div>
                <div className="flex mr-10" style={{fontWeight:500, fontSize:20}}>
                    ที่ดิน : <label className="ml-10" style={{fontWeight:500, fontSize:20}}>{userData ? userData.land : 0}</label>,
                </div> 
                <div className="flex mr-10" style={{fontWeight:500, fontSize:20}}>
                    บ้าน : <label className="ml-10" style={{fontWeight:500, fontSize:20}}>{userData ? userData.house : 0}</label>
                </div>
            </div> */}
            <div className="sale-wrapper">
                
                {/* <Row className="row-cols-1 row-cols-md-3 row-cols-lg-3">
                    <Col className="mb-20">
                        <div className="sale-box shadow">
                            <p className="head color-green">บ้าน/ที่ดิน รวม</p>
                            <label className="label color-green">{userData ? userData.land + userData.house : 0}</label>
                        </div>
                    </Col>
                    <Col className="mb-20">
                        <div className="sale-box shadow">
                            <p className="head color-default">ที่ดิน</p>
                            <label className="label color-default">{userData ? userData.land : 0}</label>
                        </div>
                    </Col>
                    <Col className="mb-20">
                        <div className="sale-box shadow">
                            <p className="head color-sky">บ้าน</p>
                            <label className="label color-sky">{userData ? userData.house : 0}</label>
                        </div>
                    </Col>
                </Row> */}
                <Row className="row-cols-1 row-cols-md-3 row-cols-lg-3">
                    {fetch?.map((item, index) => {
                        return(
                            <Col className="mb-20" key={index}>
                                <Card>
                                    {item.status === "PENDING" 
                                    ? <div 
                                    className="bg_warning color-white"
                                    style={{
                                        position:'absolute',
                                        top: 20,
                                        left: 0,
                                        paddingLeft:20,
                                        paddingRight:20
                                    }}
                                    >
                                        PENDING
                                    </div> 
                                    : item.status === "ACTIVE" 
                                    ? <div 
                                    className="bg_success color-white"
                                    style={{
                                        position:'absolute',
                                        top: 20,
                                        left: 0,
                                        paddingLeft:20,
                                        paddingRight:20
                                    }}
                                    >
                                        ONLINE
                                    </div> 
                                    : item.status === "INACTIVE" ?
                                    <div 
                                    className="bg_gray color-white"
                                    style={{
                                        position:'absolute',
                                        top: 20,
                                        left: 0,
                                        paddingLeft:20,
                                        paddingRight:20
                                    }}
                                    >
                                        DELETE
                                    </div> 
                                    : item.status === "SOLD" &&
                                    <div 
                                    className="bg_default color-white"
                                    style={{
                                        position:'absolute',
                                        top: 20,
                                        left: 0,
                                        paddingLeft:20,
                                        paddingRight:20
                                    }}
                                    >
                                        SOLD OUT
                                    </div> 
                                    }
                                    <CardImg 
                                    alt="" 
                                    src={item?.images[0]?.path}
                                    onClick={() => item.status === "ACTIVE" ? window.open(`https://www.taladteedin.com/estate_detail/?sale=${item._id}`) : null}
                                    className="btn"
                                    />
                                    <CardBody
                                    onClick={() => item.status === "ACTIVE" ? window.open(`https://www.taladteedin.com/estate_detail/?sale=${item._id}`) : null}
                                    className="btn"
                                    >
                                        <div className="body-1">
                                            <CardTitle className="color-darkblue">{item?.title}</CardTitle>
                                            <CardText className="color-darkblue">{`${item?.size?.rai} ไร่ ${item?.size?.ngan} งาน ${item?.size?.wa} ตร.ว.`}</CardText>
                                        </div>
                                        <div className="body-2">
                                            <CardText className="color-darkblue">{intl.format(item?.totalPrice)}</CardText>
                                            {item.saleCategory === 'LAND' ? <CardText className="color-default">{`${intl.format(item?.pricePerWa)} /ตร.ว`}</CardText> : null}
                                        </div>
                                        <CardText className="color-darkblue">
                                            <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                            {`อ.${item?.saleDistrict} จ.${item?.saleProvince}`}
                                        </CardText>
                                    </CardBody>
                                    <CardFooter 
                                    style={{
                                        display:'flex', 
                                        justifyContent:'flex-end',
                                        padding:10
                                    }}
                                    >
                                        <Button color="round-sky-sm" onClick={() => EditToggle(item)}>
                                            <FontAwesomeIcon icon={faPenToSquare} />
                                        </Button>
                                        <Button color="round-danger-sm" onClick={() => HandleRemove(item)}>
                                            <FontAwesomeIcon icon={faTrashCan} />
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </div>
            <div className="sale-footer">
                <Pagination 
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={count}
                pageSize={pageSize}
                onPageChange={page => onChangePage(page)}
                />
            </div>
        </div>

        <Modal isOpen={editToggle} toggle={EditToggleClose} className="modal-lg">
            <ModalHeader toggle={EditToggleClose}>แก้ไข {editData ? editData.title : null}</ModalHeader>
            {editData 
            ? <Form onSubmit={onEditSubmit}>
                <ModalBody>
                    <Row className="row-cols-3 row-cols-md-5 rowcols-lg-5 mb-20">
                        {editData.images.map((item,i) =>(
                            <Col key={i} style={{position:'relative', display:'flex', alignItems:'center'}}>
                                <img alt={item._id ? item._id : ""} src={item.path} style={{width:'100%', maxHeight: 100, objectFit:'cover'}} />
                                <div 
                                onClick={() => deleteImage(i)}
                                style={{
                                    display:'flex',
                                    justifyContent:'center',
                                    alignItems:'center',
                                    position: 'absolute',
                                    top: -5,
                                    right: 5,
                                    width: 22,
                                    height:22,
                                    borderRadius: '50%',
                                    background:'red',
                                    border:'none',
                                    color: 'white',
                                    cursor:'pointer'
                                }}
                                >x</div>
                            </Col>
                        ))}
                        {newImage.preview.map((item,i) =>(
                            <Col key={i} style={{position:'relative', display:'flex', alignItems:'center'}}>
                                <img alt="" src={item} style={{width:'100%', maxHeight: 100, objectFit:'cover'}} />
                                <div 
                                onClick={() => removePreview(i)}
                                style={{
                                    display:'flex',
                                    justifyContent:'center',
                                    alignItems:'center',
                                    position: 'absolute',
                                    top: -5,
                                    right: 5,
                                    width: 22,
                                    height:22,
                                    borderRadius: '50%',
                                    background:'red',
                                    border:'none',
                                    color: 'white',
                                    cursor:'pointer'
                                }}
                                >x</div>
                            </Col>
                        ))}
                        <Col style={{padding:10}}>
                            <input 
                            ref={inputFile} 
                            type={'file'} 
                            hidden 
                            accept="image/jpg, image/jpeg, image/png" 
                            onChange={(val) => onImagePush(val.target.files)}
                            />
                            <div 
                            style={{
                                height: 100,
                                border:'2px dashed gray',
                                borderRadius: 5,
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                                cursor:'pointer',
                            }}
                            onClick={onButtonClick}
                            >
                                <FontAwesomeIcon icon={faImage} className="color-gray" style={{fontSize: 30}} />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-20">
                        <Col>
                            <div 
                            style={{fontWeight:400}} 
                            className="color-darkblue"
                            >
                                ข้อมูลผู้ลงประกาศ
                            </div>
                            <div className="selected">
                                <Button 
                                color={editData.saleType === "OWNER" ? "selected" : "transparent" }
                                size="sm" 
                                style={{margin:10}}
                                onClick={() => onEdit("OWNER", "saleType")}
                                >
                                    เจ้าของที่ดิน
                                </Button>
                                <Button 
                                color={editData.saleType === "BROKER" ? "selected" : "transparent" }
                                size="sm" 
                                style={{margin:10}}
                                onClick={() => onEdit("BROKER", "saleType")}
                                >
                                    นายหน้า
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-20">
                        <Col 
                        className="col-12 col-md-4 col-lg-4"
                        onClick={() => onEdit(editData.coAgent ? false : true, "coAgent")}
                        style={{cursor:'pointer'}}
                        >
                            <FormGroup 
                            check
                            className="check-box-container"
                            >
                                <Input 
                                type="checkbox" 
                                checked={editData.coAgent ? editData.coAgent : false} 
                                onChange={(val) => onEdit(val.target.checked, "coAgent")} 
                                />
                                {editData.saleType === "OWNER" ? <p>ยินดีรับนายหน้า</p> : <p>ยินดีรับ Co-Agent</p>}
                            </FormGroup>
                            <FormText className="color-red">* ไม่จำเป็น</FormText>
                        </Col>
                    </Row>
                    <div 
                    style={{fontWeight:400}} 
                    className="color-darkblue mb-10"
                    >
                        ข้อมูลของที่ดิน / บ้าน
                    </div>
                    <div className="selected">
                        <Button 
                        color={editData.saleCategory === "LAND" ? "selected" : "transparent" }
                        size="sm" 
                        style={{margin:10}}
                        disabled
                        >
                            ที่ดิน
                        </Button>
                        <Button 
                        color={editData.saleCategory === "HOME" ? "selected" : "transparent" }
                        size="sm" 
                        style={{margin:10}}
                        disabled
                        >
                            บ้าน
                        </Button>
                    </div>
                    <Row className="row-cols-1 row-cols-md-1 row-cols-lg-1">
                        <Col className="mb-10">
                            <FormGroup className="form-group">
                                <Label className="color-darkblue">เลขที่โฉนด</Label>
                                <Input 
                                value={editData.titleDeedNumber} 
                                className="border color-darkblue" 
                                onChange={(val) => onEdit(val.target.value, "titleDeedNumber")}
                                />
                            </FormGroup>
                            <FormGroup switch>
                                <Input 
                                type="switch" 
                                role="switch" 
                                disabled={userData?.role === "PREMIUM" ? false : true}
                                checked={editData.titleDeedShow} 
                                onChange={(val) => onEdit(val.target.checked, "titleDeedShow")} 
                                />
                                <Label>{editData.titleDeedShow ? "แสดงเลขที่โฉนด" : "ไม่แสดงเลขที่โฉนด"}</Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="form-group">
                                <Label className="color-darkblue">หัวข้อ</Label>
                                <Input 
                                value={editData.title} 
                                className="border color-darkblue" 
                                onChange={(val) => onEdit(val.target.value, "title")}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div 
                    style={{fontWeight:400}} 
                    className="color-darkblue mb-10"
                    >
                        ที่อยู่ของแปลงที่ดิน
                    </div>
                    <Row className="row-cols-1 row-cols-md-2 row-cols-lg-2 mb-20">
                        <Col>
                            <FormGroup className="form-group">
                                <Label className="color-darkblue">จังหวัด</Label>
                                <Input 
                                className="color-darkblue form-control border" 
                                type="select" 
                                value={editData.saleProvince}
                                onChange={(val) =>{ 
                                    _onSelectProvince(val.target.value);
                                }}
                                >
                                    <option key={0} value={""}>จังหวัด</option>
                                    {PROVINCE_LIST.map((item, i) =>{
                                        return(
                                            <option key={i} value={item.province_name}>
                                                {item.province_name}
                                            </option>
                                        )
                                    })}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="form-group">
                                <Label className="color-darkblue">อำเภอ</Label>
                                <Input 
                                className="color-darkblue form-control border" 
                                type="select" 
                                value={editData.saleDistrict}
                                onChange={(val) =>{ 
                                    _onSelectDistrict(val.target.value);
                                }}
                                >
                                    <option key={0} value={""}>อำเภอ</option>
                                    {districtList.map((item, i) =>{
                                        return(
                                            <option key={i} value={item.amphur_name}>
                                                {item.amphur_name}
                                            </option>
                                        )
                                    })}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div 
                    style={{fontWeight:400}} 
                    className="color-darkblue mb-10"
                    >
                        เนื้อที่รวมของทรัพย์
                    </div>
                    <Row className="row-cols-1 row-cols-md-3 row-cols-lg-3">
                        <Col>
                            <FormGroup className="form-group">
                                <Label className="color-darkblue">ไร่</Label>
                                <Input 
                                type="number"
                                value={editData.size.rai} 
                                className="border color-darkblue" 
                                onChange={(val) => onEditSize(val.target.value, "rai")}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="form-group">
                                <Label className="color-darkblue">งาน</Label>
                                <Input 
                                type="number"
                                value={editData.size.ngan} 
                                className="border color-darkblue" 
                                onChange={(val) => onEditSize(val.target.value, "ngan")}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="form-group">
                                <Label className="color-darkblue">ตารางวา</Label>
                                <Input 
                                type="number"
                                value={editData.size.wa} 
                                className="border color-darkblue" 
                                onChange={(val) => onEditSize(val.target.value, "wa")}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {editData.saleCategory === "HOME" ?
                    <Row className="row-cols-1 row-cols-md-3 row-cols-lg-3">
                        <Col>
                            <FormGroup className="form-group">
                                <Label className="color-darkblue">ห้องน้ำ</Label>
                                <Input 
                                type="number"
                                value={editData.bathRoom} 
                                className="border color-darkblue" 
                                onChange={(val) => onEdit(val.target.value, "bathRoom")}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="form-group">
                                <Label className="color-darkblue">ห้องนอน</Label>
                                <Input 
                                type="number"
                                value={editData.bedRoom} 
                                className="border color-darkblue" 
                                onChange={(val) => onEdit(val.target.value, "bedRoom")}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="form-group">
                                <Label className="color-darkblue">พื้นที่ใช้สอย</Label>
                                <Input 
                                type="number"
                                value={editData.usableArea} 
                                className="border color-darkblue" 
                                onChange={(val) => onEdit(val.target.value, "usableArea")}
                                />
                            </FormGroup>
                        </Col>
                    </Row>: null}
                    <Row className="row-cols-1 row-cols-md-2 row-cols-lg-2 mb-20">
                        {editData.saleCategory === "LAND" ? 
                        <Col>
                            <FormGroup className="form-group">
                                <Label className="color-darkblue">ราคาต่อตารางวา (บาท)</Label>
                                <Input 
                                type="number"
                                value={editData.pricePerWa} 
                                className="border color-darkblue" 
                                onChange={(val) => onEdit(val.target.value, "pricePerWa")}
                                />
                            </FormGroup>
                        </Col> 
                        : null}
                        <Col>
                            <FormGroup className="form-group">
                                <Label className="color-darkblue">ราคารวมทั้งหมด (บาท)</Label>
                                <Input 
                                type="number"
                                value={editData.totalPrice} 
                                className="border color-darkblue" 
                                onChange={(val) => onEdit(val.target.value, "totalPrice")}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-20">
                        <Col>
                            <FormGroup check>
                                <Input 
                                type="checkbox" 
                                checked={editData.quick ? editData.quick : false} 
                                onChange={(val) => onEdit(val.target.checked, "quick")} 
                                />
                                <Label check>ต้องการขายด่วน</Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-20">
                        <Col>
                            <FormGroup check>
                                <Input 
                                type="checkbox" 
                                checked={editData.matching ? editData.matching : false} 
                                onChange={(val) => onEdit(val.target.checked, "matching")} 
                                />
                                <Label check>บริการ Matching</Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-20">
                        <Col>
                            <FormGroup className="form-group">
                                <Label className="color-darkblue">จุดเด่นแปลงที่ดินของคุณ</Label>
                                <Input 
                                type="textarea"
                                value={editData.description} 
                                className="form-textarea"  
                                onChange={(val) => onEdit(val.target.value, "description")}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className="form-group">
                                <Label className="title">
                                    ผู้ชำระค่าธรรมเนียม, ภาษี ณ วันโอนกรรมสิทธิ์
                                </Label>
                                <Label style={{color:'gray'}}>
                                    (ค่าธรรมเนียมการโอน, ภาษีธุรกิจเฉพาะ และภาษีเงินได้ ไม่รวมค่าจดจำนอง)
                                </Label>
                                <InputGroup>
                                    <Input 
                                    className="color-darkblue form-control" 
                                    type="select"
                                    value={editData.freepay ? editData.freepay : '0'}
                                    onChange={(val) => onEdit(val.target.value, "freepay")}
                                    >
                                        <option value={'0'} disabled>เลือก</option>
                                        <option value={'1'}>ผู้ซื้อผู้ขายแบ่งจ่ายคนละครึ่ง</option>
                                        <option value={'2'}>ผู้ขายจ่ายทั้งหมด</option>
                                        <option value={'3'}>ผู้ซื้อจ่ายทั้งหมด</option>
                                        <option value={'4'}>ผู้ขายจ่ายภาษีเงินได้ ส่วนที่เหลือแบ่งจ่ายคนละครึ่ง</option>
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className="form-group">
                                <Label>Package ของท่าน</Label>
                                <Input
                                className="border color-darkblue form-control" 
                                type="select"
                                value={packageChange ? packageChange.type : editData.package ? editData.package.type : ""}
                                onChange={(val) => onPackageChange(val.target.value)}
                                >
                                    <option value={""} disabled>เลือก</option>
                                    <option value={"STANDARD"} disabled>มาตราฐาน (ฟรี)</option>
                                    <option value={"PERPLOT"} disabled>รายแปลง 500.-</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className="form-group">
                                <Label>แจ้งสถานะปัจจุบัน</Label>
                                <Input
                                className="border color-darkblue form-control" 
                                type="select"
                                value={editData.status}
                                onChange={(val) => onEdit(val.target.value, 'status')}
                                >
                                    <option value={"PENDING"} disabled>รอดำเนินการ</option>
                                    <option value={"ACTIVE"} disabled>รอขาย</option>
                                    <option value={"INACTIVE"}>ยกเลิกการขาย</option>
                                    <option value={"SOLD"}>ปิดการขาย</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {loading ?
                    <Button 
                    type="submit"
                    color="default-sm"
                    >
                        <Spinner size='sm' className="mr-10" /> Loading...
                    </Button>
                    :
                    <Button 
                    type="submit"
                    color="default-sm"
                    id="credit-card"
                    >
                        update
                    </Button>
                    }
                    
                </ModalFooter>
            </Form>
            : null}
        </Modal>
        </>
    )

}
export default Sale;