import React from "react";
import {
    Button,

} from 'reactstrap'

function ErrorPage(){
    return(
        <>
            <div className="errorpage">
                <div className="btn-container">
                    <a href="/" className="btn-default" style={{marginRight:10}}>กลับหน้าหลัก</a>
                    <Button color="default">แจ้งปัญหาการใช้งาน</Button>
                </div>
            </div>
        </>
    )
}
export default ErrorPage