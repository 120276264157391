import React from "react";
import { 
    Button, 
    Form, 
    FormGroup, 
    Input, 
    Row,
    FormText
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faUpload,
    faCheck,
    faCircleCheck
} from "@fortawesome/free-solid-svg-icons";
import {
    faImages,
}from '@fortawesome/free-regular-svg-icons';
import {
    faFacebookSquare,
    faYoutube
} from '@fortawesome/free-brands-svg-icons';
// import axios from "axios";
import ads1 from "assets/img/Package/standard/New package-05.jpg";
import ads2 from "assets/img/Package/boost/New package-06.jpg";
import ads3 from "assets/img/Package/special/New package-08.jpg";
import ReactImageGallery from "react-image-gallery";
import { ESContext } from "./context/ESContext";
import { ErrorAlert } from "components/alert";

// const path_url = process.env.REACT_APP_API_CONNECT;

function Tab2(){
    const inputFile = React.useRef(null);
    const {ESDetail, inputData, inputImage, deleteImage } = React.useContext(ESContext);
    // const [Preview, setPreview] = React.useState([]);

    const onButtonClick = () => {
        if(ESDetail.preview_images.length < 8){
            inputFile.current.click();
        }else{
            ErrorAlert({text: 'ขออภัย! รูปภาพครบ 8 รูปแล้ว'})
        }
       
    };

    const onChangeImage = (val) =>{
        inputImage(val[0])
    }

    const deleteImg = (key) => deleteImage(key)

    return(
        <>
            <div className="tab-wrapper">
                <div className="tab2-section-1">
                    <div className="header-tab">
                        <div className="title">รูปถ่าย</div>
                        <label className="subtitle">(ขนาดที่รูปภาพไม่เกิน 1920x1080 px จำนวน 8 รูปเท่านั้น)
                            <label className="color-red font-weight-500" style={{fontSize:20}}>*</label>
                        </label>
                    </div>
                    <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={(val) => onChangeImage(val.target.files)} accept="image/jpg, image/jpeg, image/png" />
                    <Button color="default-sm" onClick={onButtonClick}>
                        <FontAwesomeIcon icon={faUpload} style={{marginRight:10}} /> อัพโหลดรูปภาพ
                    </Button>
                </div>
                <div className="tab2-section-2">
                    {ESDetail.preview_images.length !== 0 ? 
                    <ReactImageGallery 
                    showPlayButton={false}
                    // showThumbnails={false}
                    thumbnailPosition='bottom'
                    showFullscreenButton={false}
                    items={
                        ESDetail.preview_images.map((items,i) => {
                            return{
                                original: items,
                                thumbnail: items,
                                originalClass:"item-img",
                                renderThumbInner:()=>{
                                    return(
                                        <div className="thumnail-container">
                                            <img alt="" src={items} style={{width:'100%', height:'100%'}} />
                                            <button onClick={() => deleteImg(i)} className="delete">x</button>
                                        </div>
                                    )
                                }
                            }
                        })
                    }
                    />
                    :
                    <>
                    <div className="ground-image">
                        <FontAwesomeIcon icon={faImages} style={{fontSize:100, color:'lightgray'}} />
                    </div>
                    <div className="ground-images">
                        <div className="sub-ground" >
                            <FontAwesomeIcon icon={faImages} style={{fontSize:50, color:"lightgray"}} />
                        </div>
                        <div className="sub-ground" >
                            <FontAwesomeIcon icon={faImages} style={{fontSize:50, color:"lightgray"}} />
                        </div>
                        <div className="sub-ground">
                            <FontAwesomeIcon icon={faImages} style={{fontSize:50, color:"lightgray"}} />
                        </div>
                    </div>
                    </>
                    }
                </div>
                <div className="tab2-section-3">
                    <Form>
                        <Row>
                            <div className="col-12 col-md-1">
                                <div className="title">วิดีโอ</div>
                            </div>
                            <div className="col-12 col-md-10">
                                <FormGroup className="form-group">
                                    <Input 
                                    className="color-darkblue border" 
                                    type="text" 
                                    placeholder="example https://www.youtube.com" 
                                    onChange={(val) => inputData(val.target.value, 'video')}
                                    />
                                    <FormText className="color-red">
                                        * วาง URL ของวิดีโอ
                                    </FormText>
                                </FormGroup>
                            </div>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )

}
export default Tab2