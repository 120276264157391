import React from "react";
import {
    Row,
    Form,
    FormGroup,
    Input,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Spinner,
    ModalFooter
} from 'reactstrap';
import profileImg from 'assets/img/blank-profile.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faCheckSquare, 
    faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { faSquare, faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { ESContext } from "./context/ESContext";
// import visa_card from "assets/img/Payment/visa-10.svg";
// import master_card from "assets/img/Payment/mastercard_new.svg";
import promptpay from "assets/img/Payment/prompt-pay.png";
import { ErrorAlert } from "components/alert";
import ReactImageGallery from "react-image-gallery";
import { LoginContext } from "components/loginContext/LoginContext";
// import { PROVINCE_LIST } from "views/components/province";
import axios from "axios";
import lineOAQR from 'assets/img/LineOA QR.png';

const path_url = process.env.REACT_APP_API_CONNECT;
const line_path = process.env.REACT_APP_LINE_PATH;

function Tab3(){
    const [ModalToggle, setModalToggle] = React.useState(false);
    const { ESDetail, inputData, uploadSale } = React.useContext(ESContext);
    // const [Selected, setSelected] = React.useState(null);
    const [AcceptChecked, setAcceptChecked] = React.useState(false);
    const [ConditionChecked, setConditionChecked] = React.useState(false);
    const { userData } = React.useContext(LoginContext);
    const [loading, setLoading] = React.useState(false);
    const [paymentType, setPaymentType] = React.useState(2);
    const [cardInfo, setCardInfo] = React.useState({
        number: "",
        name: "",
        exp: {
            month: "",
            year: ""
        },
        cvv: "",
        city: "",
        postalCode: ""
    });
    const [promptpayModal, setPromptpayModal] = React.useState(false);
    const [promptpayResult, setPromptpayResult] = React.useState(null);
    const [promptpayLoading, setPromptpayLoading] = React.useState(false);
    const [lineOAModal, setLineOAModal] = React.useState(false);

    const intl = new Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 0,
    })

    const onSubmit = async (e) =>{
        e.preventDefault();
        setLoading(true)
        try{
            if(userData !== null){
                if(ConditionChecked){
                    if(ESDetail.package.type === "STANDARD"){
                        await uploadSale(userData._id, userData.name, userData.phoneNumber, userData.land, userData.house)
                        setLoading(false);
                        setLineOAModal(true);
                    }else{
                        if(paymentType === 1){
                            const result = await axios.post(path_url+'/payment/payment_perplot',{
                                email: userData.email, 
                                name: cardInfo.name, 
                                amount: 500, 
                                exp : {
                                    month: cardInfo.exp.month,
                                    year: cardInfo.exp.year
                                }, 
                                cardNumber: (cardInfo.number).replaceAll(" ", ""), 
                                cvv: cardInfo.cvv, 
                                userID: userData._id, 
                                city: cardInfo.city,
                                postalCode: cardInfo.postalCode
                            })
                            if(result.status === 200){
                                await uploadSale(userData._id, userData.name, userData.phoneNumber, userData.land, userData.house)
                                setLoading(false)
                                setLineOAModal(true);
                            }
                        }else{
                            const result = await axios.post(path_url+'/payment/createPromptpay',{
                                amount: 500,
                                user: {
                                    _id: userData._id,
                                    name: userData.name,
                                    email: userData.email
                                }
                            })
                            if(result.status === 200){
                                setPromptpayResult(result.data)
                                setPromptpayModal(true)
                                setLoading(false)
                            }
                        }
                    }
                }else{
                    setLoading(false)
                    ErrorAlert({text: 'กรุณายอมรับเงื่อนไขก่อนทำรายการ'})
                }
            }else{
                setLoading(false)
                ErrorAlert({text:'กรุณาเข้าสู่ระบบก่อนทำรายการ'})  
            }
        }catch(err){
            setLoading(false)
            console.log(err)
        }
    }

    const onCheckOut = () =>{
        if(!ESDetail.titledeed_number || !ESDetail.province.name || !ESDetail.district.name || !ESDetail.totalPrice || !ESDetail.title ) 
        return ErrorAlert({text: 'กรุณากรอกข้อมูลการประกาศขายของท่านให้ครบถ้วน'});
        else return setModalToggle(true)
    }

    // function cc_format(value) {
    //     var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    //     var matches = v.match(/\d{4,16}/g);
    //     var match = matches && matches[0] || ''
    //     var parts = [];
    //     var len = match.length;
    //     for (var i=0; i<len; i+=4) {
    //       parts.push(match.substring(i, i+4))
    //     }
    //     if (parts.length) {
    //       return parts.join(' ')
    //     } else {
    //       return value
    //     }
    // }

    // function _onSelectProvince(val){
    //     const province = PROVINCE_LIST.find(element =>{
    //         return element.province_id === val
    //     });
    //     setCardInfo({...cardInfo, city: province.province_name_eng})
    // }

    function expDate(exp){
        return new Date(exp).toLocaleString('th', {day:'2-digit', month: 'short', year:'numeric', hour:'2-digit', minute:'2-digit'})
    }

    async function _onSuccess(){
        setPromptpayLoading(true)
        try{
            await uploadSale(userData._id, userData.name, userData.phoneNumber, userData.land, userData.house)
            setPromptpayLoading(false)
            setLineOAModal(true);
        }catch(e){
            console.log(e)
            setPromptpayLoading(false)
        }
    }

    function LineOAModalClose(){
        setTimeout(() =>{
            window.location.href = "/dashboard"
        },1500)
    }

    return(
        <>
            <div className="tab-wrapper">
                <div className="tab3-container">
                    <div className="tab3-column1">
                        <div className="header">
                            ข้อมูลทรัพย์ที่จะลงประกาศขายจริง
                        </div>
                        <div className="title">
                            หัวข้อ : {ESDetail.title}
                        </div>
                        <div className="image-container">
                            {ESDetail.preview_images ? 
                            <ReactImageGallery 
                            showPlayButton={false}
                            // showThumbnails={false}
                            thumbnailPosition='bottom'
                            showFullscreenButton={false}
                            items={
                                ESDetail.preview_images.map(items => {
                                    return{
                                        original: items,
                                        thumbnail: items,
                                        originalClass: "image-preview",
                                        thumbnailWidth:120,
                                        thumbnailHeight: 80,
                                    }
                                })
                            }
                            />
                            :null}
                        </div>
                        <div className="detail-containner">
                            <Row>
                                <div className="col-12">
                                    <div className="title">
                                        ข้อมูลและรายละเอียดแปลงที่ดิน
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12 col-md-7">
                                    <div className="detail-wrapper">
                                        <img src={userData?.profileImg ? userData.profileImg : profileImg} alt="profile-img" className="profile-img" />
                                        <div style={{display:'flex', flexDirection:'column'}}>
                                            <label style={{fontWeight: '500', marginBottom:10}}>ชื่อผู้ประกาศ : <label>{userData ? userData.name : ""}</label></label>
                                            <label style={{fontWeight: '500', marginBottom:10}}>เบอร์ติดต่อ : <label>{userData ? userData.phoneNumber : ""}</label></label>
                                            <div style={{display:'flex'}}>
                                                <div className="d-type ml-10 mr-10">{ESDetail.saleType === 'BROKER' ? 'นายหน้า' : 'เจ้าของที่ดิน'}</div>
                                                {ESDetail.quick ? <div className="quick ml-10 mr-10"> ขายด่วน </div> : null}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <Row className="row-cols-1 row-cols-md-2" style={{marginBottom:10}}>
                                        <Col style={{marginBottom: 15}}>
                                            <p style={{fontWeight: 500, paddingBottom: 10}}>โฉนดที่ดิน</p>
                                            <p style={{paddingBottom: 2, borderBottom:'0.5px solid gray'}}>{ESDetail.titledeed_number}</p>
                                        </Col>
                                        <Col style={{marginBottom: 15}}>
                                            <p style={{fontWeight: 500, paddingBottom: 10}}>ประเภท</p>
                                            <p style={{paddingBottom: 2, borderBottom:'0.5px solid gray'}}>
                                                {ESDetail.saleCatagory === 'LAND' ? 'ที่ดิน' 
                                                :ESDetail.saleCatagory === 'HOME' ? 'บ้าน'
                                                :ESDetail.saleCatagory === 'COMMERCIAL' ? 'อาคารพาณิชย์'
                                                :ESDetail.saleCatagory === 'HOTEL' ? 'โรงแรม/รีสอร์ท'
                                                :ESDetail.saleCatagory === 'FACTORY' ? 'โรงงาน/โกดัง' 
                                                :ESDetail.saleCatagory === 'MORE' ? 'อื่นๆ' : null }
                                            </p>
                                        </Col>
                                        <Col style={{marginBottom: 15}}>
                                            <p style={{fontWeight: 500, paddingBottom: 10}}>ที่อยู่</p>
                                            <p style={{paddingBottom: 2, borderBottom:'0.5px solid gray'}}>
                                                {ESDetail.district.name + ", " + ESDetail.province.name}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Form>
                                        <Row>
                                            <Col>
                                                <FormGroup className="form-group">
                                                    <p style={{fontWeight: 500, paddingBottom: 10}}>จุดเด่นแปลงที่ดิน</p>
                                                    <Input 
                                                    type="textarea" 
                                                    className="form-textarea"
                                                    cols={5}
                                                    placeholder="จุดเด่นและรายละเอียดเพิ่มเติมของแปลงนี้"
                                                    disabled
                                                    value={ESDetail.description}
                                                    />
                                                </FormGroup> 
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                                <div className="col-12 col-md-5">
                                    <div className="detail-wrapper2">
                                        <div 
                                        style={{
                                            paddingLeft: 20, 
                                            paddingTop:10, 
                                            paddingBottom:10, 
                                            borderBottom:'0.5px solid gray', 
                                            marginBottom: 10
                                        }}>
                                            <p style={{fontWeight: 400}}>รายละเอียด</p>
                                        </div>
                                        <div style={{padding: 10}}>
                                            <p style={{marginBottom: 10}}>
                                                {`${ESDetail.size.rai ? ESDetail.size.rai : 0} ไร่ ${ESDetail.size.ngan ? ESDetail.size.ngan : 0} งาน ${ESDetail.size.wa ? ESDetail.size.wa : 0} ตารางวา `}
                                            </p>
                                            {ESDetail.saleCatagory === "HOME" || ESDetail.saleCatagory === "COMMERCIAL" || ESDetail.saleCatagory === "MORE" ?
                                            <div className="mb-10"> 
                                                <label className="mr-10">{ESDetail.bedRoom ? `${ESDetail?.bedRoom} ห้องนอน`: null} </label> 
                                                <label className="mr-10">{ESDetail.bathRoom ? `${ESDetail?.bathRoom} ห้องน้ำ`: null} </label>
                                                <label>{ESDetail.useableArea ? `พื้นที่ใช้สอย ${ESDetail.useableArea} ตารางเมตร`: null} </label> 
                                            </div>
                                            : ESDetail.saleCatagory === "HOTEL" || ESDetail.saleCatagory === "FACTORY" ? 
                                            <div className="mb-10"> 
                                                <label>{ESDetail.useableArea ? `พื้นที่ใช้สอย ${ESDetail.useableArea} ตารางเมตร`: null} </label> 
                                            </div>
                                            :null}
                                            {ESDetail.saleCatagory === "LAND" ?
                                                <p style={{marginBottom: 10}}>
                                                    ราคา {intl.format(parseFloat(ESDetail.pricePerWa))} / ตร.ว.
                                                </p>
                                            : null}
                                            <p style={{marginBottom: 10}} className="color-default">ราคารวม {intl.format(parseFloat(ESDetail.totalPrice))}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p style={{fontWeight:500}}>ผู้ชำระค่าธรรมเนียมภาษี ณ วันโอนกรรมสิทธิ์</p>
                                        <p className="color-gray" style={{fontSize:14}}>(ค่าธรรมเนียมการโอน, ภาษีธุรกิจเฉพาะ และภาษีเงินได้ไม่รวมค่าจดจำนอง)</p>
                                        <div className="color-gray" style={{border : '0.5px solid gray', textAlign:'center', borderRadius:10, padding:10}}>
                                            {ESDetail.freePay === "0" ?
                                            "ไม่มี"
                                            : ESDetail.freePay === "1" ?
                                            "ผู้ซื้อผู้ขายแบ่งจ่ายคนละครึ่ง"
                                            : ESDetail.freePay === "2" ?
                                            "ผู้ขายจ่ายทั้งหมด"
                                            : ESDetail.freePay === "3" ?
                                            "ผู้ซื้อจ่ายทั้งหมด"
                                            : ESDetail.freePay === "4" ?
                                            "ผู้ขายจ่ายภาษีเงินได้ ส่วนที่เหลือแบ่งจ่ายคนละครึ่ง"
                                            : ESDetail.freePay === "5" ? 
                                            "ตามที่ตกลง"
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div> 
                    <div className="tab3-column2">
                        <div className="column-wrpper">
                            <div 
                            className={ESDetail.matching ? "btn btn-package active" : "btn btn-package"} 
                            onClick={() => inputData(!ESDetail.matching, 'matching')}
                            >
                                <div
                                style={{
                                    display:'flex', 
                                    flexDirection:'row', 
                                    justifyContent:'space-between', 
                                    borderBottom:'0.5px solid gray',
                                    paddingBottom: 10,
                                    marginBottom: 10
                                }}
                                >
                                    <p className="color-black" style={{fontWeight: 500, fontSize: 15}}>บริการ Matching</p>
                                    <FontAwesomeIcon icon={ESDetail.matching ? faCheckSquare : faSquare} className="icon-check" />
                                </div>
                                <div
                                style={{
                                    display:'flex', 
                                    flexDirection:'row', 
                                    justifyContent:'space-between',
                                    alignItems:'flex-end',
                                    paddingTop: 10
                                }}
                                >
                                    <div>
                                        <p style={{fontSize: 14}} className="color-gray">บริการช่วยหาผู้ซื้อที่ดินให้กับท่าน</p>
                                    </div>
                                    <div>
                                        <Button color="gray" style={{fontSize:12}}>
                                            ติดต่อผู้ดูแล
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <hr className="mb-20"/>
                            <div
                            className={ESDetail.package.type === "STANDARD" ? "btn btn-package active" : "btn btn-package"} 
                            onClick={() => inputData({type: 'STANDARD', price: 0}, "package")}
                            >
                                <div
                                style={{
                                    display:'flex', 
                                    flexDirection:'row', 
                                    justifyContent:'space-between', 
                                    borderBottom:'0.5px solid gray',
                                    paddingBottom: 10,
                                    marginBottom: 10
                                }}
                                >
                                    <p className="color-black" style={{fontWeight: 500, fontSize: 15}}>มาตราฐาน (ฟรี)</p>
                                    <FontAwesomeIcon icon={ESDetail.package.type === "STANDARD" ? faCheckSquare : faSquare} className="icon-check" />
                                </div>
                            </div>
                            <div
                            className={ESDetail.package.type === "PERPLOT" ? "btn btn-package active" : "btn btn-package"}
                            onClick={() => inputData({type: 'PERPLOT', price: 500}, "package")}
                            >
                                <div
                                style={{
                                    display:'flex', 
                                    flexDirection:'row', 
                                    justifyContent:'space-between', 
                                    borderBottom:'0.5px solid gray',
                                    paddingBottom: 10,
                                    marginBottom: 10
                                }}
                                >
                                    <p className="color-black" style={{fontWeight: 500, fontSize: 15}}>รายแปลง (500.- / แปลง)</p>
                                    <FontAwesomeIcon icon={ESDetail.package.type === "PERPLOT" ? faCheckSquare : faSquare} className="icon-check" />
                                </div>
                                {ESDetail.package.type === "PERPLOT" ? 
                                <div style={{ paddingTop: 10 }} >
                                    <Row className="mb-20">
                                        {/* <Col className="col-6">
                                            <FormGroup check onClick={() => setPaymentType(1)}>
                                                <Input
                                                name="radio1"
                                                type="radio"
                                                checked={paymentType === 1}
                                                // onChange={() => setPaymentType(1)}
                                                />
                                                {' '}
                                                <Label check>
                                                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                                    <img alt="visa" src={visa_card} style={{width: 35, marginRight: 5}} />
                                                    <img alt="mastercard" src={master_card} style={{width: 35}} />
                                                </div>
                                                </Label>
                                            </FormGroup>
                                        </Col> */}
                                        <Col className="col-6">
                                            <FormGroup check onClick={() => setPaymentType(2)}>
                                                <Input
                                                name="radio1"
                                                type="radio"
                                                checked={paymentType === 2}
                                                // onChange={() => setPaymentType(2)}
                                                />
                                                {' '}
                                                <Label check>
                                                <div>
                                                    <img alt="promptpayimg" src={promptpay} style={{width: 70, marginRight: 5}} />
                                                </div>
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/* {paymentType === 1 ? 
                                    <Row className="mb-20 payment-info">
                                        <Col className="col-12 col-md-12 col-lg-12">
                                            <FormGroup className="form-group">
                                                <Label>หมายเลขบัตร</Label>
                                                <input 
                                                className="form-control border"
                                                placeholder="0000 0000 0000 0000"
                                                value={cc_format(cardInfo.number)}
                                                onChange={(val) => setCardInfo({...cardInfo, number: val.target.value})}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-12 col-md-12 col-lg-12">
                                            <FormGroup className="form-group">
                                                <Label>ชื่อบนบัตร</Label>
                                                <input 
                                                className="form-control border"
                                                placeholder="NAME ON CARD"
                                                value={cardInfo.name}
                                                onChange={(val) => setCardInfo({...cardInfo, name: val.target.value})}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-12 col-md-6 col-lg-6">
                                            <FormGroup className="form-group">
                                                <Label>จังหวัด</Label>
                                                <Input 
                                                type="select" 
                                                className="form-control border"
                                                onChange={(val) => _onSelectProvince(val.target.value)}
                                                >
                                                    <option value="">เลือก</option>
                                                    {PROVINCE_LIST.map((item, i) =>{
                                                        return(
                                                            <option key={i} value={item.province_id}>
                                                                {item.province_name}
                                                            </option>
                                                        )
                                                    })}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-12 col-md-6 col-lg-6">
                                            <FormGroup className="form-group">
                                                <Label>รหัสไปรษณีย์</Label>
                                                <input
                                                className="form-control border"
                                                value={cardInfo.postalCode}
                                                onChange={(val) => setCardInfo({...cardInfo, postalCode: val.target.value})}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-12 col-md-6 col-lg-6">
                                            <FormGroup className="form-group">
                                                <Label>รหัส CVV</Label>
                                                <input 
                                                className="form-control border"
                                                placeholder="CVV CODE"
                                                maxLength={3}
                                                value={cardInfo.cvv}
                                                onChange={(val) => setCardInfo({...cardInfo, cvv: val.target.value})}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-12 col-md-6 col-lg-6">
                                            <FormGroup className="form-group">
                                                <Label>วันหมดอายุบัตร</Label>
                                                <Row>
                                                    <Col className="col-5">
                                                    <input 
                                                    className="form-control border"
                                                    placeholder="MM"
                                                    maxLength={2}
                                                    pattern="[0-9]*"
                                                    value={cardInfo.exp.month}
                                                    onChange={(val) => setCardInfo({...cardInfo, exp:{...cardInfo.exp, month: val.target.value}})}
                                                    />
                                                    </Col>
                                                    /
                                                    <Col className="col-6">
                                                    <input 
                                                    className="form-control border"
                                                    placeholder="YYYY"
                                                    maxLength={4}
                                                    pattern="[0-9]*"
                                                    value={cardInfo.exp.year}
                                                    onChange={(val) => setCardInfo({...cardInfo, exp:{...cardInfo.exp, year: val.target.value}})}
                                                    />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    :null} */}
                                </div>
                                : null}
                                
                            </div>
                            <div className="noti-box-container mb-20">
                                <ul>
                                    <li className="mb-10">กรณีลงขายแบบ " ฟรี " ระบบจะทำการแสดงข้อมูลู้ติดต่อเป็นของทาง www.taladteedin.com</li>
                                    <li className="mb-10">หากต้องการแสดงข้อมูลผู้ติดต่อของคุณ จะเป็นส่วนของ <strong>บริการสมาชิกพรีเมี่ยมแบบรายแปลง, รายเดือน และ แบบรายปี</strong></li>
                                    <li>เว้นแต่ท่านเป็นสมาชิกพรีเมี่ยม ระบบจะทำการแสดงข้อมูลติดต่อเป็นของท่าน</li>
                                </ul>
                            </div>
                            <div 
                            className="check-box-container mb-20"
                            onClick={() => setAcceptChecked(!AcceptChecked)}
                            >
                                {/* <FontAwesomeIcon className="color-gray" icon={faSquare} style={{marginRight: 10}} /> */}
                                <FormGroup check>
                                    <Input 
                                    type='checkbox' 
                                    checked={AcceptChecked} onChange={(val) => setAcceptChecked(val.target.checked)} />
                                    <p style={{fontSize: 12}}>
                                        ข้าพเจ้าขอรับรอง และขอรับผิดชอบต่อข้อมูลที่นำมาประกาศขายนี้ ว่าเป็นข้อมูลจริงและได้อนุญาตจากเจ้าของที่ดินเรียบร้อยแล้ว
                                    </p>
                                </FormGroup>
                            </div>
                            <div 
                            className="mb-20"
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent: 'space-between',
                                alignItems:'flex-end',
                                border: '2px solid #EF9751',
                                borderRadius: 10,
                                padding: 10
                            }}>
                                <div>
                                    <p className="color-black" style={{fontSize: 14}}>ค่าบริการรวมทั้งหมด</p>
                                    <p className="color-default" style={{fontSize: 30, fontWeight: 500}}>฿ {ESDetail.package.price}</p>
                                </div>
                                <Button 
                                color="default-sm" 
                                onClick={() => onCheckOut()}
                                disabled={AcceptChecked ? false : true}
                                >
                                    ยืนยันข้อมูล <FontAwesomeIcon icon={faChevronRight} />
                                </Button>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>

            <Modal isOpen={ModalToggle} toggle={() => setModalToggle(!ModalToggle)} className="modal-lg">
                <ModalHeader toggle={() => setModalToggle(!ModalToggle)}>
                    ช่องทางการชำระเงิน
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col className="col-12 col-md-6 col-sm-6 col-lg-6 mb-10">
                                <div style={{textAlign: 'center'}} className="mb-20">
                                    <FontAwesomeIcon icon={faCircleCheck} style={{fontSize: 50}} className="color-green" />
                                </div>
                                <p className="mb-20">
                                    ตลาดที่ดิน จะทำการตรวจสอบและลงประกาศขาย ท่านจะได้รับลิงก์ และแจ้งเตือนผ่านอีเมล เมื่อทำการตรวจสอบสำเร็จแล้ว
                                </p>
                                {/* <div>
                                    เพิ่มเพื่อนกับเรา เพื่อรับข่าสารหรือแจ้งปัญหาการใช้งานได้ที่ Line Official Taladteedin
                                </div>
                                <img alt="lineOA" src={lineOAQR} width={200} /> */}
                            </Col>
                            <Col className="col-12 col-md-6 col-sm-6 col-lg-6 mb-10">
                                <div 
                                style={{
                                    padding:5, 
                                    backgroundColor:'#F5F5F5', 
                                    borderRadius: 5
                                }}
                                className="mb-10"
                                >
                                    <p style={{fontWeight:400}} className="mb-10">
                                        รายละเอียดค่าบริการ
                                    </p>
                                    <div style={{borderBottom:'1px solid gray', paddingBottom:10}}>
                                        <Row>
                                            <Col className="col-8 col-md-8 col-lg-8">
                                                <p style={{fontSize:14}}>
                                                    {ESDetail.package.type === "STANDARD" ?
                                                    "มาตราฐาน (ฟรี)"
                                                    : ESDetail.package.type === "PERPLOT" ?
                                                    "รายแปลง"
                                                    : null
                                                    }
                                                </p>
                                            </Col>
                                            <Col 
                                            style={{
                                                display:'flex', 
                                                justifyContent:'flex-end'
                                            }} 
                                            className="col-4 col-md-4 col-lg-4"
                                            >
                                                <p style={{fontSize:14}}>
                                                    {ESDetail.package.price} บาท
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-8 col-md-8 col-lg-8">
                                                <p style={{fontSize:14}}>
                                                    Free Post
                                                </p>
                                            </Col>
                                            <Col 
                                            style={{
                                                display:'flex', 
                                                justifyContent:'flex-end'
                                            }} 
                                            className="col-4 col-md-4 col-lg-4"
                                            >
                                                <p style={{fontSize:14}}>
                                                        ฟรี
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div style={{padding: '5px 0px', display:'flex', justifyContent:'space-between'}}>
                                        <p style={{fontSize:16}}>ราคารวม</p>
                                        <p style={{fontSize:16}} className="color-default">
                                            {ESDetail.package.price} บาท
                                        </p>
                                    </div>
                                    <Row>
                                        <Col>
                                            <small className="color-red">* รวมภาษีมูลค่าเพิ่มแล้ว</small>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mb-10">
                                    <FormGroup check>
                                        <Input type='checkbox' checked={ConditionChecked} onChange={(val) => setConditionChecked(val.target.checked)} />
                                        <Label check style={{fontSize:12}}>
                                            ฉันได้อ่านและยอมรับ
                                            <Label className="btn color-default" style={{fontSize:12, marginLeft:5}}>
                                                ข้อตกลงและเงื่อนไขการชำระเงิน
                                            </Label>
                                        </Label>
                                    </FormGroup>
                                </div>
                                <div style={{display:'flex', justifyContent:'center'}}>
                                    {loading ? 
                                    <Button color="default-sm" disabled>
                                        <Spinner size='sm' className="mr-10" /> Loading...
                                    </Button>
                                    : 
                                    <Button 
                                    color="default-sm" 
                                    onClick={onSubmit}
                                    id="credit-card"
                                    >
                                        ลงประกาศ
                                    </Button>
                                    }
                                    
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>

            <Modal isOpen={promptpayModal} >
                <ModalBody>
                        {promptpayResult ? 
                        <>
                        <div style={{display:'flex', justifyContent:'center'}}>
                            <img 
                            alt={promptpayResult.source.scannable_code.image.filename} 
                            src={promptpayResult.source.scannable_code.image.download_uri} 
                            style={{width: '80%'}} 
                            />
                        </div>
                        <div className="mb-20">
                            QR Code จะหมดอายุใน : {expDate(promptpayResult.expires_at)}
                        </div>
                        <div>
                            <a href={promptpayResult.source.scannable_code.image.download_uri} className="btn btn-blue" download="TLTD-Promptpay">
                                ดาวน์โหลด QR Code
                            </a>
                        </div>
                        </>
                        : null}
                </ModalBody>
                <ModalFooter>
                    <Button color="default" onClick={_onSuccess} disabled={promptpayLoading}>
                        {promptpayLoading ? 
                        <>
                            <Spinner size={'sm'} className="mr-10" style={{color:'white'}} />
                            Loading...
                        </>
                        : 
                        "สำเร็จ"
                        }
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={lineOAModal} toggle={() => LineOAModalClose()} className="modal-xs">
                <ModalHeader toggle={() => LineOAModalClose()}>
                    Line Official TaladTeedin
                </ModalHeader>
                <ModalBody>
                    <p>
                        เพื่อการติดต่อที่รวดเร็วและระบบจะส่งลิงค์ประกาศขายให้ท่าน ผ่าน Line กรุณาเพิ่มเพื่อน @taladteedin หรือ สแกน QR Code นี้และพิมพ์เลขที่โฉนด
                    </p>
                    <a href={line_path}>
                        <img alt="" src={lineOAQR} width={'100%'} />
                    </a>
                </ModalBody>
                <ModalFooter>
                    <Button 
                    color="default"
                    onClick={() => LineOAModalClose()}
                    >
                        ปิด
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )

}
export default Tab3