import React from "react";
import Navbars from "routers/navbars";
import Footer from "components/footer";

function PrivacyPolicy(){
    return(
        <>
        <Navbars />
        <div className="privacy-container mb-50">
            <h1 className="tltd-header font-weight-500 mb-20">นโยบายความเป็นส่วนตัว</h1>
            <div className="privacy-wrapper mb-50">
                <p>แก้ไขล่าสุด : 25 พ.ย 2565</p>
                <br/>
                <p>
                บริษัท ธนกร ตลาดที่ดิน จำกัด 
                เป็นผู้ให้บริการเว็บไซต์ www.taladteedin.com  
                (ในนโยบายนี้รวมกันเรียกว่า <b>"เว็บไซต์"</b>) 
                ผ่านแพลตฟอร์มเดสก์ท็อป โทรศัพท์มือถือ แท็บเล็ต และแอปพลิเคชัน 
                ในการนี้ ในนโยบายความเป็นส่วนตัวนี้ ให้เรียกบริษัท ธนกร ตลาดที่ดิน จำกัด 
                ว่า <b>"เรา"</b> <b>"ของเรา"</b> และ <b>"บริษัท"</b> (เว้นแต่บริบทจะกำหนดให้เป็นประการอื่น)
                </p>
                <br />
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                นโยบายความเป็นส่วนตัวนี้ (รวมทั้งข้อกำหนดการให้บริการที่ www.taladteedin.com รวมถึงเอกสารใด ๆ ที่อ้างถึงในเอกสารดังกล่าว) 
                มีผลครอบคลุมการใช้งานเว็บไซต์ของท่าน รวมถึงแอปพลิเคชัน 
                และวิธีการอื่นใดที่ท่านสามารถใช้เชื่อมต่อหรือสื่อสารกับบริษัทผ่านการใช้ผลิตภัณฑ์และ
                บริการต่าง ๆ ของบริษัท (รวมเรียกว่า “บริการ”)  นอกจากนี้ นโยบายความเป็น
                ส่วนตัวนี้ระบุถึง (ก) วิธีการที่บริษัทใช้ ประมวลผลหรือเปิดเผยข้อมูลส่วนบุคคลที่บริษัท
                ได้เก็บรวบรวมจากท่านหรือที่ท่านได้มอบให้บริษัท ซึ่งรวมถึงวิธีการใช้ข้อมูลบางประการ
                ในการเลือกแสดงข้อมูลอสังหาริมทรัพย์ให้เหมาะสมกับท่าน (ข) ประเภทข้อมูลส่วนบุคคล
                ที่บริษัทเก็บรวบรวม (ค) วิธีการที่บริษัทใช้ในการรักษาความปลอดภัยของข้อมูล และ (ง) 
                วิธีการที่ท่านสามารถเข้าถึงหรือเปลี่ยนแปลงข้อมูลส่วนบุคคลที่บริษัทเก็บไว้ ทั้งนี้ 
                'ข้อมูลส่วนบุคคล' หมายถึง ข้อมูลใด ๆ เกี่ยวกับท่านซึ่งสามารถใช้ระบุตัวตนของท่าน
                ได้ไม่ว่าโดยตรงหรือโดยอ้อม ไม่ว่าข้อมูลดังกล่าวจะเป็นจริงหรือไม่ หรือจากข้อมูลดัง
                กล่าวหรือข้อมูลอื่นซึ่งบริษัทมีหรืออาจมี ตามคำจำกัดความใด ๆ ภายใต้กฎหมายที่ใช้บังคับ
                </p>
                <br/>
                <br/>
                <b>การให้ความยินยอม  เก็บ  รวบรวม  ใช้  และ/หรือ เปิดเผยข้อมูลส่วนบุคคล</b>
                <br/>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                วัตถุประสงค์ของการเก็บ รวบรวม ใช้ และ/หรือ เปิดเผยข้อมูลส่วนบุคคล 
                บริษัท ธนกร ตลาดที่ดิน จำกัด เป็นผู้ให้บริการเว็บไซต์ TaladTeedin.co.th และ 
                TaladTeedin.com จะเก็บ รวบรวม ใช้ และ/หรือ เปิดเผยข้อมูลส่วนบุคคล 
                เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562  และเพื่อประโยชน์ของ 
                บริษัท ธนกร ตลาดที่ดิน จำกัด ในการเก็บ รวบรวม ใช้ และ/หรือ เปิดเผยข้อมูลส่วนบุคคล  
                ข้าพเจ้า (เจ้าของข้อมูล) ยินยอมให้ บริษัท ธนกร ตลาดที่ดิน จำกัด เก็บ รวบรวม ใช้  
                และ/หรือ เปิดเผยข้อมูลส่วนบุคคลคน  ดังต่อไปนี้
                <ol style={{paddingLeft:50}}>
                    <li>รายละเอียดเกี่ยวกับตัวผู้ให้ข้อมูล เช่น ชื่อ นามสกุล วัน เดือน ปีเกิด สถานภาพ เป็นต้น</li>
                    <li>รายละเอียดเกี่ยวกับการระบุและยืนยันตัวตน เช่น  หมายเลขประจำตัวประชาชน หมายเลขหนังสือเดินทาง หมายเลขโทรศัพท์ ภาพถ่าย เป็นต้น</li>
                    <li>รายละเอียดสำหรับการติดต่อ เช่น ที่อยู่ตามทะเบียนบ้าน  และ/หรือ  ที่อยู่ปัจจุบันที่สามารถติดต่อได้ หมายเลขโทรศัพท์ ที่อยู่ตามจดหมายอิเล็กทรอนิกส์ (E-Mail) เป็นต้น</li>
                    <li>รายละเอียดในเอกสารต่างๆ 
                        ที่ผู้ให้ข้อมูลยินยอมมอบให้แก่บริษัท ธนกร ตลาดที่ดิน จำกัด 
                        เช่น รายละเอียดข้อมูลในอสังหาริมทรัพย์ไม่ว่าผู้ให้ข้อมูลจะเป็นเจ้าของกรรมสิทธิ์หรือไม่ 
                        (กรณีผู้ให้ข้อมูลมิใช่เจ้าของกรรมสิทธิ์ในอสังหาริมทรัพย์นั้น 
                        บริษัทถือว่าผู้ให้ข้อมูลได้รับความยินยอมจากเจ้าของกรรมสิทธิ์ในอสังหาริมทรัพย์นั้นแล้ว 
                        โดยหากเกิดความเสียหายประการใดๆ ขึ้นเกี่ยวกับการที่ผู้ให้ข้อมูล 
                        ให้ใช้หรือนำเสนอข้อมูลดังกล่าวแก่บริษัท  
                        บริษัทไม่จำต้องรับผิดในความเสียหายประการใดๆ ที่เกิดแต่ข้อมูลนั้น) เป็นต้น
                    </li>
                    <li>ข้อมูลอื่นๆ ที่ผู้ให้ข้อมูลได้ให้ไว้กับบริษัท ธนกร ตลาดที่ดิน จำกัด</li>
                </ol>
                </p>
                <br/>
                <br/>
                <ol style={{paddingLeft:25}}>
                    <li style={{fontWeight:'bold'}}>
                        <b>การให้ความสำคัญกับความเป็นส่วนตัวของข้อมูล</b>
                        <p style={{paddingLeft: 20}}>
                        1.1 โปรดศึกษานโยบายความเป็นส่วนตัวนี้อย่างละเอียดเพื่อทำความเข้าใจเกี่ยวกับนโยบายและวิธีปฏิบัติของบริษัท 
                        เกี่ยวกับข้อมูลส่วนบุคคลของท่านและวิธีการที่บริษัทดำเนินการเกี่ยวกับข้อมูลส่วนบุคคลของท่าน
                        <br/>
                        <br/>
                        1.2 การที่ท่านเข้าเยี่ยมชมหรือใช้งานเว็บไซต์หรือแอปพลิเคชัน หรือการสมัครเข้าใช้บริการของบริษัท 
                        หรือเข้าทำสัญญากับบริษัทเกี่ยวกับเว็บไซต์หรือแอปพลิเคชัน 
                        (ก) ถือว่าท่านได้อ่านและตกลงเกี่ยวกับนโยบายความเป็นส่วนตัวนี้แล้ว และ 
                        (ข) ท่านตกลงว่าข้อมูลส่วนบุคคลใด ๆ และทั้งหมดที่เกี่ยวกับท่าน ที่บริษัทได้เก็บรวบรวม 
                        หรือที่ท่านได้ให้ไว้แก่บริษัทเป็นครั้งคราว อาจมีการใช้ ประมวลผล และเปิดเผยเพื่อวัตถุประสงค์และ
                        ให้แก่บุคคลตามที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้
                        <br/><br/>
                        1.3 บริการมิได้มีวัตถุประสงค์เพื่อการใช้งานของบุคคลใด ๆ 
                        ที่ยังไม่บรรลุนิติภาวะหรือมีความสามารถทางกฎหมายไม่สมบูรณ์ เช่น ผู้เยาว์ 
                        หรือบุคคลที่ถือว่าเป็นบุคคลไร้ความสามารถหรือเสมือนไร้ความสามารถ การที่ท่านใช้บริการ 
                        เว็บไซต์ และ/หรือแอปพลิเคชัน อย่างต่อเนื่อง ท่านรับรองว่าท่านเป็นผู้บรรลุนิติภาวะแล้วและมีสติ
                        สัมปชัญญะครบถ้วนในการติดต่อกับผู้ใช้รายอื่นและเข้าถึงบริการ/ผลิตภัณฑ์ต่าง ๆ บนเว็บไซต์ 
                        ในกรณีที่ท่านยังไม่บรรลุนิติภาวะหรือมีความสามารถทางกฎหมายไม่สมบูรณ์ โปรดยุติการใช้บริการ
                        และออกจากเว็บไซต์และ/หรือแอปพลิเคชัน โดยทันที
                        <br/><br/>
                        1.4 โดยความมุ่งหมายของบริษัทในการที่จะจัดการ คุ้มครอง 
                        และประมวลผลข้อมูลส่วนบุคคลของท่านได้อย่างเหมาะสม บริษัทจะทบทวนนโยบาย 
                        กระบวนการ และขั้นตอนต่าง ๆ ของบริษัทเป็นครั้งคราว โดยบริษัทอาจแก้ไขเพิ่ม
                        เติมนโยบายความเป็นส่วนตัวนี้ได้ไม่ว่าในเวลาใดโดยนำนโยบายความเป็นส่วนตัว
                        ฉบับแก้ไขขึ้นแสดงบนเว็บไซต์ ในการนี้ แม้บริษัทจะใช้ความพยายามในการส่งคำ
                        บอกกล่าวทุกครั้งที่มีการแก้ไขเพิ่มเติมนโยบายก็ตาม หากท่านยังคงใช้งานเว็บไซต์ 
                        แอปพลิเคชัน หรือบริการต่อไปหลังจากที่การเปลี่ยนแปลงมีผลใช้บังคับแล้ว 
                        บริษัทจะถือว่าท่านได้รับทราบและตกลงเกี่ยวกับนโยบายความเป็นส่วนตัวที่ได้แก้ไข
                        เปลี่ยนแปลงนั้น บริษัทขอให้ท่านโปรดตรวจสอบเว็บไซต์นี้เป็นครั้งคราวสำหรับการ
                        ปรับปรุงแก้ไขนโยบายความเป็นส่วนตัวนี้
                        </p>
                    </li>
                    <br/>
                    <li style={{fontWeight:'bold'}}>
                        <b>การเก็บรวบรวมข้อมูลส่วนบุคคล</b>
                        <div style={{paddingLeft: 20}}>
                            <p>
                                2.1 เมื่อท่านใช้บริการของบริษัท บริษัทจะเก็บรวบรวมข้อมูลส่วนบุคคล
                                ต่าง ๆ หรือข้อมูลที่ไม่สามารถระบุตัวตนได้จากหรือที่เกี่ยวกับท่าน 
                                อุปกรณ์ของท่าน และการใช้บริการของท่าน โดยท่านรับทราบว่าข้อมูล
                                ส่วนบุคคลที่ท่านมอบให้แก่บริษัทและที่บริษัทได้เก็บรวบรวมจากท่าน 
                                เป็นข้อมูลของท่านหรือเป็นข้อมูลที่ท่านมีอำนาจในการมอบให้แก่บริษัท
                            </p>
                            <br/>
                            <p>
                                2.2 ข้อมูลที่ท่านมอบให้โดยสมัครใจ เมื่อใช้บริการของบริษัท 
                                บริษัทอาจขอให้ท่านให้ข้อมูลส่วนบุคคลเกี่ยวกับตัวท่าน ซึ่งรวมถึงแต่
                                ไม่จำกัดเฉพาะ ชื่อ ที่อยู่ หมายเลขโทรศัพท์ อีเมล เพศ อาชีพ 
                                ความสนใจส่วนบุคคล ข้อมูลการชำระเงิน รายละเอียดเกี่ยวกับที่อยู่
                                อาศัยของท่านหรือทรัพย์ที่ท่านให้ความสนใจ หรือข้อมูลทางการเงิน 
                                นอกจากนี้ สำหรับบริการหรือผลิตภัณฑ์บางรายการ บริษัทอาจเก็บรวบ
                                รวมข้อมูลส่วนบุคคลของท่านเพื่อให้สามารถตรวจสอบตัวตนของท่านได้ 
                                ซึ่งรวมถึงข้อมูลบัตรประจำตัวประชาชน ใบอนุญาตขับขี่ หนังสือเดินทาง
                                ของท่าน เป็นต้น ซึ่งอาจเกิดขึ้นได้ในกรณีต่าง ๆ เช่นกรณีดังต่อไปนี้:
                            </p>
                            <div style={{paddingLeft: 20}}>
                                <p>
                                    2.2.1 เมื่อท่านดำเนินธุรกรรมกับบริษัทบนหรือเกี่ยวกับเว็บไซต์หรือแอปพลิเคชัน
                                </p>
                                <p>
                                    2.2.2 เมื่อท่านลงทะเบียนบัญชีผู้ใช้งานกับบริษัทบนเว็บไซต์หรือแอปพลิเคชัน
                                </p>
                                <p>
                                    2.2.3 เมื่อท่านติดต่อฝ่ายบริการลูกค้าหรือฝ่ายขายของบริษัททาง
                                    อีเมล โทรศัพท์ หรือเข้าติดต่อด้วยตนเอง เพื่อแก้ไขปัญหาใด ๆ 
                                    ที่ท่านอาจประสบ หรือในกรณีที่ท่านมีข้อสงสัยหรือคำขอใด ๆ
                                </p>
                                <p>
                                    2.2.4 เมื่อท่านสมัครเข้าใช้บริการต่าง ๆ ที่บริษัทเสนอให้บนเว็บไซต์
                                    หรือแอปพลิเคชัน หรือเพื่อการแจ้งเตือนทางอีเมลและ/หรือจดหมายข่าว
                                    ของบริษัท
                                </p>
                                <p>
                                    2.2.5 เมื่อท่านเข้าถึงบริการใด ๆ ของบริษัทผ่านทางไซต์โซเชียลเน็ตเวิร์ค 
                                    (“เอสเอ็นเอส”) เช่น เฟสบุ๊ค
                                </p>
                                <p>
                                    2.2.6 เมื่อท่านติดต่อกับผู้เชี่ยวชาญด้านอสังหาริมทรัพย์ ผ่านบริการของบริษัท
                                </p>
                                <p>
                                    2.2.7 เมื่อท่านกรอกข้อมูลลงในแบบฟอร์มหรือธุรกรรมอื่น ๆ
                                </p>
                                <p>
                                    2.2.8 เมื่อท่านโพสต์หรือให้ข้อมูลบนเว็บไซต์ของบริษัท
                                </p>
                            </div>
                            <br/>
                            <p>
                                2.3 ท่านอาจให้ข้อมูลเกี่ยวกับบุคคลภายนอก หมายรวมถึงข้อมูลอสังหา
                                ริมทรัพย์อันมีบุคคลภายนอกเป็นผู้ถือกรรมสิทธิ์แก่บริษัทผ่านบริการ เช่น 
                                ในกรณีที่ท่านแบ่งปันข้อมูลรายการอสังหาริมทรัพย์กับผู้รับข้อมูลผ่านทางอี
                                เมลหรือข้อความสั้น (SMS) ทั้งนี้ การที่ท่านให้ข้อมูลดังกล่าวแก่บริษัท 
                                ท่านให้การรับรองต่อบริษัทว่าท่านได้รับความยินยอมจากบุคคลภายนอก
                                แล้วสำหรับการที่ท่านให้ข้อมูลส่วนบุคคลของบุคคลดังกล่าวแก่บริษัทเพื่อ
                                วัตถุประสงค์ตามที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้
                            </p>
                            <br/>
                            <p>
                                2.4 ในบางโอกาส บริษัทจะกำหนดให้ท่านต้องระบุชื่อ ข้อมูลการติดต่อ 
                                และรายละเอียดอื่น ๆ ที่ระบุว่าเป็นข้อมูลบังคับตามที่บริษัทร้องขอ 
                                ในกรณีที่ท่านปฏิเสธไม่ให้ข้อมูลส่วนบุคคลที่เป็นข้อมูลบังคับใด ๆ 
                                ตามที่บริษัทร้องขอ หรือมีความประสงค์จะเพิกถอนความยินยอม 
                                หรือจำกัดการประมวลผลข้อมูลส่วนบุคคลของท่านอย่างมีนัยสำคัญ 
                                ท่านตกลงว่า (โดยไม่คำนึงถึงข้อตกลงใด ๆ ระหว่างท่านและบริษัท) 
                                บริษัทอาจไม่สามารถให้บริการและผลิตภัณฑ์แก่ท่านได้
                            </p>
                            <br/>
                            <p>
                                2.5 บริษัทยังอาจเก็บรวบรวมข้อมูลส่วนบุคคลของท่านผ่านนิติบุคคลที่
                                เกี่ยวข้องของบริษัท ผู้ให้บริการของบริษัท บุคคลภายนอก 
                                และแหล่งข้อมูลที่มีอยู่อันเป็นสาธารณะอื่นใด เพื่อให้บริษัทสามารถให้
                                บริการที่ดีหรือที่เกี่ยวข้องกับท่านยิ่งขึ้นได้
                            </p>
                            <br/>
                            <p>
                                2.6 ข้อมูลอุปกรณ์เคลื่อนที่และเบราว์เซอร์บนอุปกรณ์เคลื่อนที่ ท่าน
                                สามารถปรับการตั้งค่าเกี่ยวกับการแบ่งปันข้อมูลบางประการ เช่น 
                                รุ่นอุปกรณ์เคลื่อนที่ หรือภาษาที่ใช้ในอุปกรณ์เคลื่อนที่ของท่าน 
                                บนอุปกรณ์เคลื่อนที่และเบราว์เซอร์บนอุปกรณ์เคลื่อนที่ได้โดยปรับการ
                                ตั้งค่าความเป็นส่วนตัวและการรักษาความปลอดภัยบนอุปกรณ์เคลื่อนที่
                                ของท่าน โปรดดูคำแนะนำจากผู้ให้บริการอุปกรณ์เคลื่อนที่หรือผู้ผลิต
                                อุปกรณ์เคลื่อนที่ของท่าน
                            </p>
                            <br/>
                            <p>
                                2.7 ข้อมูลตำแหน่ง ในกรณีที่ท่านเปิดระบบระบุตำแหน่งบนอุปกรณ์เคลื่อน
                                ที่ของท่าน เมื่อได้รับอนุญาตจากท่านแล้ว บริษัทอาจเก็บรวบรวมข้อมูล
                                ตำแหน่งของอุปกรณ์ของท่านซึ่งบริษัทใช้ในการให้ข้อมูลและเสนอข้อมูล
                                อสังหาริมทรัพย์ตามตำแหน่งสถานที่ให้แก่ท่าน หากท่านต้องการปิดการ
                                ใช้งานฟีเจอร์นี้ ท่านสามารถปิดระบบแจ้งตำแหน่งบนอุปกรณ์เคลื่อนที่ของ
                                ท่านได้
                            </p>
                            <br/>
                            <p>
                                2.8 การจัดการอีเมล ท่านอาจได้รับอีเมลจากบริษัทเนื่องจากเหตุต่าง ๆ 
                                เช่น ในกรณีที่ท่านดำเนินการใด ๆ ผ่านทางบริการ ลงทะเบียนรับการราย
                                งานประจำ หรือประกาศขายหรือให้เช่าที่พักอาศัยและมีผู้ซื้อส่งข้อความถึงท่าน 
                                ในกรณีที่ท่านมีบัญชีผู้ใช้ที่เปิดใช้งานกับบริษัท ท่านสามารถแก้ไขการตั้งค่าผ่าน
                                การตั้งค่าบัญชี นอกจากนี้ ท่านยังสามารถจัดการการรับการสื่อสารบางประเภท
                                ได้โดยปฏิบัติตามวิธีการที่ปรากฏในอีเมลที่บริษัทส่งให้แก่ท่าน โปรดทราบว่าแม้
                                ท่านจะยกเลิกการรับอีเมลบางรายการจากบริษัท บริษัทยังอาจมีความจำเป็น
                                ต้องส่งอีเมลเกี่ยวกับข้อมูลธุรกรรมหรือการจัดการที่สำคัญให้แก่ท่าน
                            </p>
                            <br/>
                            <p>
                                2.9 ข้อมูลที่มีความอ่อนไหว โดยทั่วไปแล้ว บริษัทไม่เก็บรวบรวมข้อมูล
                                ส่วนบุคคล ที่มีความอ่อนไหวซึ่งได้จำกัดความไว้ตามกฎหมายว่าด้วยความ
                                เป็นส่วนตัวใดๆ ในการให้บริการ ทั้งนี้ เว้นแต่บริษัทจะได้ร้องขอเป็น
                                การเฉพาะ โปรดงดเว้นจากการส่งหรือมอบข้อมูลส่วนบุคคลที่มีความ
                                อ่อนไหวใด ๆ ให้แก่บริษัทในขณะที่ใช้บริการ การที่ท่านเปิดเผยข้อมูล
                                ส่วนบุคคลที่มีความอ่อนไหวใด ๆ จะถือว่าท่านได้ให้ความยินยอมล่วงหน้า
                                สำหรับให้บริษัทประมวลผลข้อมูลส่วนบุคคลนั้น ไม่ว่าท่านได้ให้ข้อมูลนั้นด้วย
                                วัตถุประสงค์ใด
                            </p>
                        </div>
                    </li>
                    <br/>
                    <li style={{fontWeight:'bold'}}>
                        <b>วัตถุประสงค์ของการใช้</b>
                        <div style={{paddingLeft: 20}}>
                            <p>
                                3.1 ข้อมูลส่วนบุคคลที่ท่านได้ให้ไว้อาจมีการใช้หรือประมวลผลโดยบริษัท 
                                ทั้งรวมกับข้อมูลของบุคคลอื่นหรือแยกออกแต่ละราย และข้อมูลดังกล่าวอาจ
                                มีการรวมเข้ากับข้อมูลอื่น เพื่อวัตถุประสงค์ในการจัดการและดำเนินการ
                                เว็บไซต์และแอปพลิเคชัน โดยขอบเขตดังกล่าวอาจรวมถึงไม่จำกัดเฉพาะ
                                วัตถุประสงค์ต่าง ๆ ดังต่อไปนี้:
                            </p>
                            <div style={{paddingLeft: 20}}>
                                <ul className="ul">
                                    <li>จัดการเว็บไซต์และแอปพลิเคชัน</li>
                                    <li>พัฒนาการรับชมของท่านโดยปรับค่าเว็บไซต์และแอปพลิเคชันให้เหมาะกับท่าน</li>
                                    <li>ให้บริการตามที่ท่านร้องขอเป็นการเฉพาะ</li>
                                    <li>ตอบข้อสงสัยหรือคำร้องขอของท่านจากอีเมลและ/หรือการส่งแบบฟอร์มบนเว็บไซต์หรือแอปพลิเคชัน</li>
                                    <li>ตอบสนองและติดตามข้อร้องเรียนเกี่ยวกับการใช้งานเว็บไซต์ แอปพลิเคชัน หรือบริการใด ๆ ของบริษัท</li>
                                    <li>ส่งการสื่อสารเชิงพาณิชย์โดยทั่วไป รวมทั้งการแจ้งเตือนเกี่ยวกับการเปลี่ยนแปลงบริการของบริษัทผ่านช่องทางการสื่อสารใด ๆ ซึ่งรวมถึงแต่ไม่จำกัดเฉพาะอีเมล โทรศัพท์ ข้อความสั้น (SMS) และการแจ้งเตือนผ่านแอปพลิเคชัน</li>
                                    <li>ดำเนินการเกี่ยวกับการบริหารจัดการสำนักงานและการปฏิบัติการ</li>
                                    <li>ข้อมูลดังกล่าวอาจใช้เพื่อการเสนอข้อมูลทางการตลาดแบบเฉพาะตัวบุคคล บนเว็บไซต์หรือแอปพลิเคชัน</li>
                                    <li>ติดต่อท่านเพื่อทำการสำรวจ วิจัย และสอบถามความคิดเห็นเกี่ยวกับผลิตภัณฑ์ บริการ หรือเว็บไซต์</li>
                                    <li>รวมข้อมูลส่วนบุคคลของท่านเข้ากับข้อมูลต่าง ๆ เพื่อให้ท่านได้สัมผัสกับการใช้งานที่ดีและได้รับข้อมูลที่เกี่ยวข้องกับความต้องการของท่านยิ่งขึ้น และเพื่อปรับปรุงคุณภาพผลิตภัณฑ์และบริการ รวมถึงการใช้ข้อมูลเพื่อวิเคราะห์หาสาเหตุของปัญหาต่าง ๆ</li>
                                    <li>ตรวจสอบตัวตนของท่านเมื่อท่านลงทะเบียนหรือเข้าสู่ระบบบนเว็บไซต์ของบริษัทผ่านบัญชีของท่าน และแจ้งเตือนให้ท่านทราบถึงรหัสผ่านและชื่อผู้ใช้ของท่าน</li>
                                    <li>ช่วยปฏิบัติหน้าที่ของบริษัทที่เกิดจากสัญญาใด ๆ ระหว่างท่านและบริษัท</li>
                                    <li>เพื่อวัตถุประสงค์อื่นใดที่บริษัทจะแจ้งให้ท่านทราบเมื่อมีการขอความยินยอมจากท่าน</li>
                                </ul>
                            </div>
                            <br/>
                            <p>
                                3.2 เนื่องจากวัตถุประสงค์ซึ่งบริษัทอาจ/จะเก็บรวบรวม ใช้ เปิดเผย 
                                หรือประมวลผลข้อมูลส่วนบุคคลของท่านขึ้นอยู่กับพฤติการณ์ที่เกิดขึ้นในขณะนั้น 
                                วัตถุประสงค์ดังกล่าวจึงอาจไม่ได้ระบุไว้ข้างต้น อย่างไรก็ตาม บริษัทจะแจ้งให้
                                ท่านทราบเกี่ยวกับวัตถุประสงค์อื่นดังกล่าวเมื่อบริษัทขอความยินยอมจากท่าน 
                                เว้นแต่กรณีที่บริษัทสามารถประมวลผลข้อมูลส่วนบุคคลของท่านได้โดยไม่จำเป็น
                                ต้องขอความยินยอมจากท่าน ตามกฎหมายว่าด้วยความเป็นส่วนตัวหรือกฎหมายที่
                                ใช้บังคับอื่นใด
                            </p>
                            <br/>
                        </div>
                    </li>
                    <br/>
                    <li style={{fontWeight:'bold'}}>
                        <b>Cookie  คืออะไร</b>
                        <p>
                            &nbsp;&nbsp;&nbsp;"คุกกี้"  เป็นไฟล์ข้อมูลขนาดเล็กที่แสดงประวัติการเข้าชมเว็บไซต์และการ
                            ดาวน์โหลด  ซึ่งประโยชน์และความจำเป็นของคุกกี้ก็คือ  เมื่อท่านเคยใช้
                            งานเว็บไซต์หนึ่งแล้ว  คุกกี้จะจดจำข้อมูลหลายรูปแบบ  เช่น  สถานการณ์
                            เข้าใช้งานในปัจจุบันของท่าน  ข้อมูลการตั้งค่าภาษาที่ท่านชื่นชอบ  
                            เพื่อให้ท่านสามารถใช้บริการที่มีการใช้คุกกี้หรือเทคโนโลยีอื่นใดที่มีลักษณะ
                            ใกล้เคียงกันได้อย่างต่อเนื่อง  ซึ่งทำให้คอมพิวเตอร์หรืออุปกรณ์ของท่าน
                            จดจำเว็บไซต์นั้นได้เมื่อกลับเข้าไปใช้งานเว็บไซต์อีกครั้ง
                        </p>
                    </li>
                    <br/>
                    <li style={{fontWeight:'bold'}}>
                        <b>Cookie คืออะไร</b>
                        <div style={{paddingLeft: 20}}>
                            <p>
                                5.1 ท่านควรดำเนินการให้แน่ใจว่าข้อมูลส่วนบุคคลทั้งหมดที่ท่านได้ส่ง
                                ให้แก่บริษัทนั้นครบถ้วน เที่ยงตรง เป็นจริง และถูกต้อง หากท่านไม่
                                ดำเนินการดังกล่าวอาจเป็นผลให้บริษัทไม่สามารถให้บริการตามที่ท่าน
                                ร้องขอได้
                            </p>
                            <br/>
                            <p>
                                5.2 บริษัทจะใช้ความพยายามตามสมควรในการดำเนินการให้ข้อมูลส่วน
                                บุคคลของท่านนั้นมีความถูกต้องและเป็นปัจจุบัน ในกรณีที่บริษัทอาจมีการ
                                ใช้ข้อมูลส่วนบุคคลของท่านเพื่อการตัดสินใจใด ๆ ที่มีผลต่อท่าน อย่าง
                                ไรก็ตาม ข้อความในส่วนนี้หมายความว่าท่านจะต้องให้ข้อมูลที่ปรับปรุง
                                แล้วแก่บริษัทในกรณีที่มีการเปลี่ยนแปลงใด ๆ เกี่ยวกับข้อมูลส่วนบุคคล
                                ซึ่งท่านได้ให้ไว้แก่บริษัท บริษัทจะไม่รับผิดชอบต่อการใช้ข้อมูลส่วนบุคคลซึ่งท่านได้ให้ไว้แก่บริษัทที่ไม่ถูกต้องหรือไม่ครบถ้วนอันเป็นผลจากการที่ท่านมิได้ให้ข้อมูลส่วนบุคคลที่ปรับปรุงแล้วแก่บริษัทในกรณีที่มีการเปลี่ยนแปลงใด ๆ เกี่ยวกับข้อมูลดังกล่าว
                            </p>
                            <br/>
                            <p>
                                5.3 บริษัทมีความมุ่งมั่นที่จะดำเนินการให้ข้อมูลส่วนบุคคลที่บริษัทเก็บรวบ
                                รวมมีความปลอดภัย สมบูรณ์ และเป็นความลับ  บริษัทได้กำหนดวิธีการ
                                ในการป้องกันและใช้มาตรการการรักษาความปลอดภัยตามสมควรเพื่อคุ้ม
                                ครองข้อมูลส่วนบุคคลของท่านจากการเข้าถึง การแก้ไข และการเปิด
                                เผยโดยไม่ได้รับอนุญาต ทั้งนี้ บริษัทได้ทบทวนและปรับปรุงมาตรการใน
                                การรักษาความปลอดภัยของบริษัทให้เป็นไปตามเทคโนโลยีในปัจจุบัน
                            </p>
                            <br/>
                            <p>
                                5.4 อย่างไรก็ตาม ท่านเข้าใจว่าการส่งข้อมูลผ่านอินเทอร์เน็ตเป็นวิธี
                                การที่ไม่สามารถรักษาความปลอดภัยได้โดยสมบูรณ์ แม้ว่าบริษัทจะใช้
                                ความพยายามอย่างดีที่สุดในการคุ้มครองข้อมูลส่วนบุคคลของท่านแล้ว 
                                แต่บริษัทไม่อาจรับประกันได้ถึงความปลอดภัยของข้อมูลส่วนบุคคลของ
                                ท่านที่ส่งไปยังเว็บไซต์หรือแอปพลิเคชัน กล่าวคือ การส่งข้อมูลใด ๆ 
                                จะต้องกระทำโดยเป็นความเสี่ยงของท่านเอง นอกจากนี้ บริษัทไม่อาจ
                                รับผิดชอบสำหรับการที่บุคคลภายนอกใช้ข้อมูลส่วนบุคคลของท่านโดยมิได้
                                รับอนุญาตที่เกิดจากปัจจัยที่อยู่นอกเหนือการควบคุมของบริษัทโดยสิ้นเชิง
                            </p>
                            <br/>
                            <p>
                                5.5 เมื่อบริษัทมอบ (หรือเมื่อท่านกำหนด) รหัสผ่านซึ่งจะทำให้ท่าน
                                สามารถเข้าถึงเว็บไซต์หรือแอปพลิเคชันบางส่วนได้ ท่านจะต้องรับผิด
                                ชอบในการเก็บรหัสผ่านนี้ไว้เป็นความลับ บริษัทขอให้ท่านไม่เปิดเผย
                                รหัสผ่านนี้ให้แก่ผู้อื่น ทั้งนี้ บริษัทจะไม่ถามรหัสผ่านจากท่านโดยเด็ดขาด 
                                ยกเว้นในขณะที่ท่านจะเข้าสู่ระบบของเว็บไซต์หรือแอปพลิเคชัน 
                                แล้วแต่กรณี
                            </p>
                            <br/>
                            <p>
                                5.6 บริษัทขอแนะนำให้ท่านดำเนินการต่าง ๆ เพื่อคุ้มครองข้อมูลส่วน
                                บุคคลของท่านให้ปลอดภัย เช่น กำหนดรหัสผ่านที่สามารถคาดเดาได้
                                ยากและเก็บรหัสผ่านไว้ให้เป็นความลับ รวมทั้งออกจากระบบบัญชีผู้ใช้
                                ของท่านและปิดเว็บเบราว์เซอร์ทุกครั้งเมื่อท่านเสร็จสิ้นจากการใช้บริ
                                การของบริษัทบนอุปกรณ์สาธารณะหรืออุปกรณ์ที่ไม่ปลอดภัย
                            </p>
                        </div>
                    </li>
                    <br/>
                    <li style={{fontWeight:'bold'}}>
                        <b>การใช้และเปิดเผยข้อมูลส่วนบุคคล</b>
                        <div style={{paddingLeft: 20}}>
                            <p>
                                6.1 วัตถุประสงค์เพื่อการตลาดแบบตรง บริษัทจะไม่ใช้ข้อมูลส่วนบุคคล
                                ของท่านสำหรับการตลาดแบบตรง หรือจะไม่เปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่บุคคลภายนอกเพื่อวัตถุประสงค์สำหรับการตลาดแบบตรงเว้นแต่บริษัทได้รับความยินยอมจากท่านโดยชัดแจ้ง  เมื่อได้รับความยินยอมจากท่าน บริษัทอาจใช้และแบ่งปันข้อมูลส่วนบุคคลของท่าน (กล่าวคือ ชื่อ ที่อยู่อาศัย หมายเลขโทรศัพท์มือถือ หมายเลขโทรศัพท์บ้าน และอีเมล)
                            </p>
                            <div style={{paddingLeft: 20}}>
                                <p>
                                    (ก) เพื่อให้ข้อมูลแก่ท่านเกี่ยวกับการเสนอข้อมูลอสังหาริมทรัพย์ 
                                    การส่งเสริมการขาย สินค้าหรือบริการ และทำการตลาดแบบตรง
                                    เกี่ยวกับอสังหาริมทรัพย์ การลงทุน และ/หรือผลิตภัณฑ์/บริการทาง
                                    การเงิน ซึ่งบริษัทเชื่อว่าท่านอาจมีความสนใจในข้อมูลดังกล่าว 
                                    และ
                                </p>
                                <p>
                                    (ข) ให้แก่ (1) ตัวแทนซื้อขายอสังหาริมทรัพย์ ผู้พัฒนาอสังหาริม
                                    ทรัพย์ ผู้รับเหมาก่อสร้าง เจ้าของอสังหาริมทรัพย์ ผู้ให้บริการทาง
                                    การเงินและการลงทุน ซึ่งมีรายการหรือการโฆษณาบนเว็บไซต์ของ
                                    บริษัทตามที่ท่านได้ส่งคำขอ และ (2) ผู้ให้บริการของบริษัท 
                                    เพื่อให้บุคคลดังกล่าวสามารถให้ผลิตภัณฑ์หรือบริการในนามของ
                                    บริษัท หรือผลิตภัณฑ์หรือบริการที่ท่านได้ร้องขอจากบุคคลดังกล่าว
                                    โดยตรง หรือให้ความช่วยเหลือบริษัทในการมอบผลิตภัณฑ์หรือบริการ
                                    ให้แก่ท่าน และ/หรือเพื่อการใช้งานของบุคคลดังกล่าวในการตลาด
                                    แบบตรงและ
                                </p>
                            </div>
                            <br/>
                            <p>
                                6.2 การจัดการและดำเนินการเว็บไซต์และแอปพลิเคชัน บริษัทอาจเปิด
                                เผยข้อมูลส่วนบุคคลของท่านในกรณีดังต่อไปนี้:
                                <div style={{paddingLeft: 10}}>
                                    <ul className="ul">
                                        <li>เปิดเผยให้แก่บุคคลภายนอก ในกรณีที่ท่านได้ขอข้อมูล บริการ หรือผลิตภัณฑ์จากบุคคลดังกล่าว</li>
                                        <li>เปิดเผยให้แก่ผู้ให้บริการของบริษัท ที่ปรึกษาด้านวิชาชีพ และบุคคลภายนอกอื่น ๆ เพื่อวัตถุประสงค์ต่อไปนี้:</li>
                                        <div style={{paddingLeft: 10}}>
                                            <p>(1) วัตถุประสงค์ใด ๆ ที่กฎหมายและกฎระเบียบที่ใช้บังคับอนุญาตให้กระทำได้</p>
                                            <p>(2) เพื่อปฏิบัติตามข้อกำหนดทางกฎหมายใด ๆ</p>
                                            <p>(3) เพื่อบังคับหรือใช้ข้อกำหนดการให้บริการของบริษัท</p>
                                            <p>(4) เพื่อปกป้องสิทธิ ทรัพย์สิน และความปลอดภัยของบริษัท และสิทธิ ทรัพย์สิน และความปลอดภัยของบริษัทในเครือและบริษัทย่อยของบริษัท ผู้ใช้งานเว็บไซต์และแอปพลิเคชันคนอื่น และบุคคลอื่น</p>
                                            <p>(5) ดำเนินการเกี่ยวกับกระบวนการทางกฎหมายใด ๆ ที่มีขึ้นหรืออาจมีขึ้น และ</p>
                                            <p>(6) ดำเนินการเกี่ยวกับธุรกรรมสินทรัพย์ทางธุรกิจ ที่บริษัทเป็นหรืออาจเป็นคู่สัญญา</p>
                                        </div>
                                    </ul>
                                </div>
                            </p>
                            <br/>
                            <p>
                                6.3 ความยินยอม บริษัทจะไม่ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่บุคคลภาย
                                นอกโดยมิได้รับความยินยอมจากท่านสำหรับการเปิดเผยข้อมูลดังกล่าวเสียก่อน 
                                อย่างไรก็ตาม โปรดทราบว่าในบางกรณี บริษัทอาจใช้หรือเปิดเผยข้อมูลส่วนบุคคล
                                ของท่านให้แก่บุคคลภายนอกโดยมิได้รับความยินยอมจากท่านในบางกรณี
                            </p>
                            <br/>
                            <p>
                                6.4 ในกรณีที่บริษัทเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่บุคคลภายนอก
                                โดยปราศจากความยินยอมของท่าน บริษัทจะใช้ความพยายามอย่างเต็ม
                                ที่ในการกำหนดให้บุคคลภายนอกดังกล่าวคุ้มครองข้อมูลส่วนบุคคลของท่าน
                            </p>
                        </div>
                    </li>
                    <br/>
                    <li style={{fontWeight:'bold'}}>
                        <b>การเพิกถอนความยินยอม</b>
                        <div style={{paddingLeft: 20}}>
                            <p>
                                7.1 ท่านสามารถเพิกถอนความยินยอมได้โดยติดต่อฝ่ายบริการลูกค้าที่: 
                                line และ Facebook  ( ตลาดที่ดิน taladteedin.com )
                            </p>
                            <br/>
                            <p>
                                7.2 โปรดทราบว่าในกรณีที่ท่านเพิกถอนความยินยอมในการใช้ข้อมูล
                                ส่วนบุคคลของท่านไม่ว่าทั้งหมดหรือบางส่วน บริษัทอาจไม่อยู่ในฐานะ
                                ที่จะให้บริการแก่ท่านได้อีกต่อไป ทั้งนี้โดยขึ้นอยู่กับลักษณะคำขอของท่าน 
                                โดยเมื่อบริษัทได้รับแจ้งการเพิกถอนจากท่าน บริษัทจะแจ้งให้ท่านทราบ
                                ถึงผลที่จะเกิดขึ้นจากการเพิกถอนความยินยอม ซึ่งรวมถึงแต่ไม่จำกัด
                                เฉพาะการสิ้นสุดลงของสัญญาใด ๆ ที่ท่านอาจมีอยู่กับบริษัท และการปฏิบัติ
                                ผิดหน้าที่หรือข้อผูกพันตามสัญญาของท่าน ในการนี้ บริษัทของสงวนซึ่งสิทธิ
                                และการเยียวยาตามกฎหมายสำหรับกรณีดังกล่าวไว้โดยชัดแจ้ง
                            </p>
                        </div>
                    </li>
                    <br/>
                    <li style={{fontWeight:'bold'}}>
                        <b>การเข้าถึง การลบ และการแก้ไข</b>
                        <div style={{paddingLeft: 20}}>
                            <p>
                                8.1 ท่านสามารถเข้าถึงข้อมูลส่วนบุคคลเกี่ยวกับท่านที่บริษัทมี ท่านสามารถร้องขอให้บริษัทแก้ไข ลบ
                            </p>
                            <br/>
                            <p>
                                8.2 บริษัทอาจปฏิเสธที่จะเปิดเผย ลบ หรือแก้ไขเพิ่มเติมข้อมูลส่วนบุคคลใด ๆ ด้วยเหตุผลที่ระบุไว้ในกฎหมายว่าด้วยความเป็นส่วนตัว
                            </p>
                            <br/>
                            <p>
                                8.3 บริษัทจะตอบคำร้องขอของท่านภายใน 30 วันปฏิทินนับแต่วันที่ได้รับ
                                คำร้องขอนั้น ทั้งนี้ เท่าที่จะสามารถกระทำได้อย่างสมเหตุผล 
                                โดยในกรณีที่ไม่อาจกระทำได้ภายในเวลาดังกล่าว บริษัทจะแจ้งให้ท่าน
                                ทราบโดยเร็วที่สุดเกี่ยวกับกำหนดเวลาที่บริษัทจะตอบคำร้องขอของท่าน
                            </p>
                        </div>
                    </li>
                    <br/>
                    <li style={{fontWeight:'bold'}}>
                        <b>การเก็บรักษาข้อมูลส่วนบุคคล</b>
                        <p>
                            บริษัทจะเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้ในระยะเวลาเท่าที่จำเป็นตาม
                            สมควรเพื่อปฏิบัติหน้าที่ของบริษัทให้ลุล่วงเพื่อวัตถุประสงค์ตามที่ระบุไว้ในนโยบาย
                            ความเป็นส่วนตัวนี้เท่านั้น ในกรณีที่เกิดกระบวนการทางกฎหมายหรือทางวินัยขึ้น 
                            บริษัทอาจเก็บข้อมูลส่วนบุคคลของท่านไว้จนกว่ากระบวนการดังกล่าวจะสิ้นสุดลง 
                            ซึ่งรวมถึงระหว่างระยะเวลาอุทธรณ์ใด ๆ และหลังจากนั้นบริษัทจึงจะลบหรือเก็บ
                            ข้อมูลเข้าในคลังข้อมูลเท่าที่กฎหมายว่าด้วยความเป็นส่วนตัวอนุญาตให้กระทำได้ 
                            ในการนี้ ตลอดระยะเวลาการเก็บรักษาข้อมูล บริษัทจะใช้มาตรการการรักษาความ
                            ปลอดภัยตามสมควรเพื่อป้องกันมิให้ข้อมูลของท่านสูญหายด้วยเหตุที่ไม่คาดคิด 
                            หรือจากการใช้ เข้าถึง เปลี่ยนแปลง หรือเปิดเผยโดยมิได้รับอนุญาต หรือจาก
                            ความเสียหายหรือถูกทำลาย โดยมาตรการการรักษาความปลอดภัยของข้อมูลทาง
                            กายภาพรวมถึงการควบคุมการเข้าพื้นที่ และมาตรการการรักษาความปลอดภัยของ
                            ข้อมูลเชิงอิเล็กทรอนิกส์รวมถึงการควบคุมการเข้าถึงและรหัสผ่าน
                        </p>
                    </li>
                    <br/>
                    <li style={{fontWeight:'bold'}}>
                        <b>ข้อจำกัดความรับผิด</b>
                        <div style={{paddingLeft: 20}}>
                            <p>
                                10.1  บริษัทจะไม่รับผิดต่อการที่ท่านเปิดเผยข้อมูลส่วนบุคคลใด ๆ 
                                โดยสมัครใจให้แก่ผู้ใช้รายอื่นทราบ ซึ่งเกี่ยวกับการใช้งานเว็บไซต์หรือ
                                แอปพลิเคชัน แล้วแต่กรณี
                            </p>
                            <br/>
                            <p>
                                10.2 บริษัทไม่จำต้องรับผิดในที่ท่านให้ข้อมูลอสังหาริมทรัพย์ กล่าวคือ 
                                บริษัทไม่สามารถยืนยันความถูกต้องหรือความเป็นจริง รวมถึงการให้
                                ความยินยอมของเจ้าของกรรมสิทธิ์  และไม่อาจรับผิดชอบต่อความเสีย
                                หายที่เกิดขึ้น ไม่ว่าในกรณีใด ๆ จากการใช้ข้อมูล ท่านหรือผู้นำข้อมูลไป
                                ใช้พึงใช้วิจารณญาณ และตรวจสอบตามความเหมาะสม
                            </p>
                        </div>
                    </li>
                    <br/>
                    <li style={{fontWeight:'bold'}}>
                        <b>การแก้ไขเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</b>
                        <p>
                            บริษัทอาจแก้ไขเปลี่ยนแปลงนโยบายนี้เป็นครั้งคราว  โดยท่านสามารถทราบ
                            ข้อกำหนดและเงื่อนไข  นโยบายที่มีการเปลี่ยนแปลงนี้ได้ผ่านทางเว็บไซต์
                            ของบริษัท (www.TaladTeedin.com)
                        </p>
                    </li>
                    <br/>
                    <li style={{fontWeight:'bold'}}>
                        <b>นโยบายความเป็นส่วนตัวของเว็บไซต์อื่น</b>
                        <p>
                            นโยบายความเป็นส่วนตัวฉบับนี้ใช้สำหรับการใช้บริการ และการใช้งานบนเว็บ
                            ไซต์สำหรับผู้ใช้ของ บริษัท ธนกร ตลาดที่ดิน จำกัด  เป็นผู้ให้บริการเว็บไซต์ 
                            www.TaladTeedin.com  เท่านั้น  หากท่านเข้า
                            ชมเว็บไซต์อื่นแม้จะผ่านช่องทางเว็บไซต์ของบริษัท  การคุ้มครองข้อมูลส่วนบุค
                            คลต่างๆ จะเป็นไปตามนโยบายความเป็นส่วนตัวของเว็บไซต์นั้น ซึ่งบริษัทไม่มี
                            ส่วนเกี่ยวข้องด้วย
                        </p>
                    </li>
                    <br/>
                    <li style={{fontWeight:'bold'}}>
                        <b>กฎหมายที่ใช้บังคับ</b>
                        <p>
                            นโยบายความเป็นส่วนตัวนี้อยู่ภายใต้บังคับกฎหมายของประเทศไทย
                        </p>
                    </li>
                    <br/>
                    <li style={{fontWeight:'bold'}}>
                        <b>รายละเอียดติดต่อเรา</b>
                        <p>
                            ภายใต้นโยบายความส่วนตัวฉบับนี้ โปรดติดต่อหรือเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของเราที่
                        </p>
                        <div style={{paddingLeft: 10}}>
                            <ul className="ul">
                                <li>ที่อยู่ : 219/1 หมู่ 16 ตำบลรอบเวียง อำเภอเมืองเชียงราย จังหวัดเชียงราย 57000</li>
                                <li>อีเมล : taladteedininfo@gmail.com</li>
                                <li>เบอร์โทร : 096-414-6351</li>
                            </ul>
                        </div>
                    </li>
                </ol>
            </div>
            <div className="h3 font-weight-500">นโยบายการคืนเงิน</div>
            <div className="privacy-wrapper mb-20">
                <p>บริษัท ขอสงวนสิทธิ์ในการคืนเงินให้แก่ลูกค้าในทุกกรณี</p>
            </div>
            <div className="h3 font-weight-500">นโยบายการคืนเงิน</div>
            <div className="privacy-wrapper mb-20">
                <p>
                    ท่านสามารถยกเลิกการใช้บริการที่ท่านได้สมัครไว้กับบริษัทได้
                    โดยสามารถแจ้งยกเลิกได้ผ่าน Line official ของบริษัท
                    ได้ตลอดเวลา โดยไม่มีเงื่อนไขโดยบริษัท 
                    จะใช้เวลาดำเนินการยกเลิก ภายใน 7 วันทำการ 
                    หลังจากได้รับการแจ้งยกเลิกจากท่าน
                </p>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default PrivacyPolicy;