import axios from "axios";
import React from "react";
import { LoginContext } from "components/loginContext/LoginContext";
import { 
    Card,
    CardBody,
    CardFooter,
    CardImg,
    CardTitle,
    CardText,
    Col, 
    Row, 
    Button,
} from "reactstrap";
import Pagination from "views/pagination/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from '@fortawesome/free-solid-svg-icons';

const path_url = process.env.REACT_APP_API_CONNECT;

function Providinglands(){
    const { userData } = React.useContext(LoginContext);
    const [fetchData, setFetchData] = React.useState([])
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize] = React.useState(9);
    const [count, setCount] = React.useState(0);

    React.useEffect(() =>{
        if(userData){
            if(userData.role === "PREMIUM"){
                getFetchData(1)
            }
        }
    },[userData])

    async function getFetchData(page){
        const skip = (page - 1) * pageSize;
        try{
            const res = await axios.post(path_url+'/consignment/getProvidingLandByUser',{
                skip: skip
            })
            if(res.status === 200){
                setFetchData(res.data.data)
                setCount(res.data.count)
            }
        }catch(e){
            console.log(e)
        }
    }

    const intl = new Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 0,
    })

    const onChangePage = (page) =>{
        setCurrentPage(page);
        getFetchData(page)
    }

    function gotoView(id){
        window.open('http://localhost:3000/providinglandview/?sale='+id)
    }

    return( 
        <>
        <div className="providing-container">
            <h3>จัดหาที่ดินร่วมกับตลาดที่ดิน</h3>
            <Row className="pt-20 row-cols-2 row-cols-md-3">
                {userData?.role === "PREMIUM" ?
                fetchData.map((item,i) =>{
                    return(
                        <Col style={{marginBottom: 10}} key={i}>
                            <Card>
                                <CardImg alt={item?.title} src={item?.images[0]?.path} />
                                <CardBody>
                                    <div className="body-1">
                                        <CardTitle>
                                            {item?.title}
                                        </CardTitle>
                                        <CardText>{item?.description}</CardText>
                                    </div>
                                    <div className="body-2">
                                        <CardText>{intl.format(item?.totalPrice)}</CardText>
                                        {item?.pricePerWa ?
                                            <CardText className="color-default">{intl.format(item?.pricePerWa)}/ตร.ว.</CardText>
                                        :null}
                                    </div>
                                </CardBody>
                                <CardFooter className="flex-center">
                                    <Button color="transparent" size="sm" onClick={() => gotoView(item._id)}>
                                        View
                                    </Button>
                                </CardFooter>
                            </Card>
                        </Col>
                    )
                })
                :
                <div style={{
                    display:'flex', 
                    justifyContent:'center', 
                    flexDirection:'column', 
                    width: '100%', 
                    textAlign:'center'
                }} 
                className="color-gray"
                >
                    <FontAwesomeIcon icon={faCrown} size="4x" className="mb-20" />
                    สำหรับสมาชิกพรีเมี่ยมเท่านั้น
                </div>
                }
            </Row>
            <div 
            className="mb-20 mr-20"
            style={{
                width:'100%',
                display:'flex',
                justifyContent:'center',
            }}>
                <Pagination 
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={count}
                pageSize={pageSize}
                onPageChange={page => onChangePage(page)}
                />
            </div>
        </div>
        </>
    )
}
export default Providinglands;