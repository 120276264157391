import React from "react";

import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardImg,
    CardFooter,
    CardText,
    CardTitle
} from 'reactstrap';
import Navbars from "routers/navbars";
import Footer from "components/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';
import ReactPlayer from "react-player";
import banner from 'assets/img/Mask Group 76.png';
import axios from "axios";
import Pagination from "./pagination/Pagination";
import { TikTok } from "react-tiktok";

const path_url = process.env.REACT_APP_API_CONNECT;

function Content(){
    const [fetchImage, setFetchImage] = React.useState([]);
    const [fetchVideo, setFetchVideo] = React.useState([]);
    const [fetchTiktok, setFetchTiktok] = React.useState([]);
    const [imgCurent, setImgCurent] = React.useState(1);
    const [videoCurent, setVideoCurent] = React.useState(1);
    const [tiktokCurent, setTiktokCurent] = React.useState(1);
    const [f_ImageCount, setF_ImageCount] = React.useState(0);
    const [f_VideoCount, setF_VideoCount] = React.useState(0);
    const [f_TiktokCount, setF_TiktokCount] = React.useState(0);
    const [pageSize] = React.useState(12);

    React.useEffect(() => {
        fetchDataImage(1);
        fetchDataVideo(1);
        fetchDataTiktok(1);
    },[])

    const fetchDataImage = async (page) =>{
        const skip = (page - 1) * pageSize;
        try{
            const res = await axios.post(path_url+'/content/getImageContent', {
                skip: skip
            })

            if(res.status === 200){
                setFetchImage(res.data.data);
                setF_ImageCount(res.data.count);
            }
        }catch(e){
            console.log(e)
        }
    }

    const fetchDataVideo = async (page = 1) =>{
        const skip = (page - 1) * pageSize;
        try{
            const res = await axios.post(path_url+'/content/getVideoContent',{
                skip: skip
            })

            if(res.status === 200){
                setFetchVideo(res.data.data)
                setF_VideoCount(res.data.count)
            }
        }catch(e){
            console.log(e)
        }
    }

    const fetchDataTiktok = async (page = 1) =>{
        const skip = (page - 1) * pageSize;
        try{
            const res = await axios.post(path_url+'/content/getTiktokContent',{
                skip: skip
            })

            if(res.status === 200){
                setFetchTiktok(res.data.data)
                setF_TiktokCount(res.data.count)
            }
        }catch(e){
            console.log(e)
        }
    }

    function MoreImageContent(val){
        setImgCurent(val);
        fetchDataImage(val);
    }

    function MoreVideoContent(val){
        setVideoCurent(val);
        fetchDataVideo(val);
    }
    function MoreTiktokContent(val){
        setTiktokCurent(val);
        fetchDataTiktok(val);
    }
    

    return(
        <>
        <Navbars />
        <div className="content-container">
            <img alt="banner" src={banner} className="content-img mb-50" />
            <div className="content-highlight mb-50">
                <div className="content-header mb-30">
                    <div className="flex align-center mb-20">
                        <p className="title mr-30 color-darkblue">ความรู้เกี่ยวกับเรื่องที่ดิน</p>
                        <FontAwesomeIcon 
                        icon={faFacebookSquare} 
                        size="2x" 
                        className="btn color-facebook" 
                        onClick={() => window.open('https://www.facebook.com/profile.php?id=100063635147660')}
                        />
                    </div>
                    <div className="content-header-right ">
                        <Button color="transparent">
                            <FontAwesomeIcon icon={faHashtag} className="mr-10" />
                            ทั้งหมด
                            <FontAwesomeIcon icon={faCaretDown} style={{marginLeft:5}} />
                        </Button> 
                    </div>
                </div>
                <Row className={`row-cols-1 row-cols-md-3`}>
                    {fetchImage.map((item, i) =>{
                    // if(i <= 0){
                        return(
                            <Col key={i} className="content-estete-highlight mb-40">
                                <Card 
                                onClick={() => window.location.href = `/contentview/${item._id}`}
                                >
                                    <CardImg 
                                    alt={item._id}
                                    src={item.coverPath}
                                    />
                                    <CardBody>
                                        <CardTitle className="color-default mb-10">{item.title}</CardTitle>
                                        <CardText className="color-darkblue mb-10">{`ผู้เขียน: ${item.name}`}</CardText>
                                    </CardBody>
                                    <CardFooter>
                                        <div style={{display: 'flex', flexWrap:'wrap'}} className="mb-10">
                                            {Array.from(item.tags).map((result, i) =>{
                                                return(
                                                    <div key={i} className="tags">
                                                        <p style={{fontSize: 10, color:'white'}}>{result}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <Button 
                                        color="transparent" 
                                        onClick={() => window.location.href = `/contentview?id=${item._id}`}
                                        >
                                            อ่านเพิ่มเติม
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Col>
                        )
                    // }
                })}
                </Row>
                 <div className="content-highlight-footer">
                    <Pagination 
                    className="pagination-bar"
                    currentPage={imgCurent}
                    totalCount={f_ImageCount}
                    pageSize={pageSize}
                    onPageChange={page => MoreImageContent(page)}
                    />
                </div> 
            </div>
            <div className="content-video mb-50">
                <div className="content-header mb-30">
                    <div className="flex align-center mb-20">
                        <p className="title mr-30 color-darkblue">VDO CONENT</p>
                        <FontAwesomeIcon 
                        icon={faYoutube} 
                        size="2x" 
                        className="btn color-youtube" 
                        onClick={() => window.open('https://www.youtube.com/@taladteedinchannel')}
                        />
                    </div>
                </div>
                <Row className={`row-cols-1 row-cols-md-3`}>
                    {fetchVideo.map((item, i) =>{
                    // if(i <= 0){
                        return(
                            <Col className="mb-40" key={i}>
                                <Card>
                                    <div className="card-video">
                                        <ReactPlayer 
                                        url={item.coverPath}
                                        controls={true}
                                        width={'100%'}
                                        height={'100%'}
                                        />
                                    </div>
                                    
                                    <CardBody>
                                        <CardTitle className="color-default mb-10">{item.title}</CardTitle>
                                        <CardText className="color-darkblue mb-10">{`ผู้เขียน: ${item.name}`}</CardText>
                                    </CardBody>
                                    <CardFooter>
                                        <div style={{display: 'flex', flexWrap:'wrap'}} className="mb-10">
                                            {Array.from(item.tags).map((result, i) =>{
                                                return(
                                                    <div key={i} className="tags">
                                                        <p style={{fontSize: 10, color:'white'}}>{result}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <Button color="transparent" onClick={() => window.location.href = `/contentview/${item._id}`}>
                                            อ่านเพิ่มเติม
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Col>
                        )
                    // }
                })}
                </Row>
                <div className="content-video-footer">
                    <Pagination 
                    className="pagination-bar"
                    currentPage={videoCurent}
                    totalCount={f_VideoCount}
                    pageSize={pageSize}
                    onPageChange={page => MoreVideoContent(page)}
                    />
                </div>
            </div>
            <div className="content-tiktok mb-50">
                <div className="content-header mb-30">
                    <div className="flex align-center mb-20">
                        <p className="title mr-30 color-darkblue">TIKTOK CONENT</p>
                        <FontAwesomeIcon 
                        icon={faTiktok} 
                        size="2x" 
                        className="btn color-tiktok" 
                        onClick={() => window.open('https://www.tiktok.com/@taladteedin')}
                        />
                    </div>
                </div>
                <Row className={`row-cols-1 row-cols-md-3`}>
                    {fetchTiktok.map((item, i) =>{
                        return(
                            <Col className="mb-20" key={i}>
                                <TikTok 
                                url={item.coverPath}
                                />
                            </Col>
                        )
                    })}
                </Row>
                <div className="content-tiktok-footer">
                    <Pagination 
                    className="pagination-bar"
                    currentPage={tiktokCurent}
                    totalCount={f_TiktokCount}
                    pageSize={pageSize}
                    onPageChange={page => MoreTiktokContent(page)}
                    />
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}
export default Content;