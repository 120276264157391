import React from 'react';
import Navbars from "routers/navbars";
import blank_img from 'assets/img/blank-profile.png';

import {
    Row,
    Col,
    Button,
    Popover,
    PopoverBody,
    UncontrolledPopover,
    Spinner,
    Alert
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faLocationDot,
    faPaperPlane,
    faEllipsis,
    faCircleCheck,
    faTrash
} from '@fortawesome/free-solid-svg-icons';
import {
    faImages
} from '@fortawesome/free-regular-svg-icons';
import { useHistory, useParams } from 'react-router-dom';
import Footer from 'components/footer';
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';
import "moment/locale/th";
import { LoginContext } from "components/loginContext/LoginContext";
import LoginModal from "components/loginModal";
import { PhotoSwipe } from "react-photoswipe";

const path_url = process.env.REACT_APP_API_CONNECT;

function AnnounceDetail(){
    let history = useHistory();
    var { id } = useParams();
    const { userData } = React.useContext(LoginContext);
    const [dataFetch, setDataFetch] = React.useState(null);
    const [newImage, setNewImage] = React.useState({
        preview: [], 
        value: []
    })
    const inputFile = React.useRef(null);
    const [loginOpen, setLoginOpen] = React.useState(false);
    const [text, setText] = React.useState("");
    const [loadingComment, setLoadingComment] = React.useState(false);
    const [successAlert, setSuccessAlert] = React.useState(false);
    const [deleteAlert, setDeleteAlert] = React.useState(false);

    React.useEffect(() =>{
        onFetch()
    },[])

    async function onFetch(){
        try{
            const result = await axios.post(path_url+'/announce/getAnnounceByID',{
                id: id
            })

            if(result.status === 200){
                setDataFetch(result.data)
                console.log(result.data)
            }

        }catch(e){
            console.log(e)
        }
    }

    function choose(val){
        switch(val){
            case 'build' : return 'สร้างบ้าน';
            case 'SmallHouse' : return 'พัฒนาโครงการที่อยู่อาศัยขนาดเล็ก';
            case 'LargeResidential' : return 'พัฒนาโครงการที่อยู่อาศัยขนาดใหญ่';
            case 'BigBusiness' : return 'ธุรกิจขนาดใหญ่';
            case 'SME' : return 'ธุรกิจขนาดเล็ก SME';
            case 'Speculate' : return 'เก็งกำไร';
            case 'Farming' : return 'การเกษตร';
            default : return 'อื่นๆ';
        }
    }

    function unitSize(val){
        var SizeUnit;
        switch(val){
            case 'squarewa' : SizeUnit = 'ตร.ว.'; 
            break;
            case 'ngan' : SizeUnit = 'งาน';
            break;
            case 'rai' : SizeUnit = 'ไร่';
            break;
            default : SizeUnit = '';
        }
        return SizeUnit
    }

    const intl = new Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 0,
    })

    const onImagePush = (val) =>{
        setNewImage(item =>{
            const preview = [...item.preview];
            preview.push(URL.createObjectURL(val[0]))
            const image = [...item.value];
            image.push(val[0])
            return{...item, preview: preview, value: image}
        })
    }

    const removePreview = (id) =>{
        setNewImage(item =>{
            const preview = [...item.preview];
            const image = [...item.value];
            const removePreview = preview.filter((item,i) => i !== id)
            const removeImage = image.filter((item,i) => i !== id)
            return{...item, preview: [...removePreview], value: [...removeImage]}
        })
    }

    const onButtonClick = () => {
        const imageCount = Array.from(newImage.preview).length;
        if(imageCount < 3){
            inputFile.current.click();
        }else{
            Swal.fire({
                icon:'warning',
                text: 'รูปภาพไม่เกิน 3 รูป',
                confirmButtonColor: '#DE1414'
            })
        }
    }

    const uploadImageComment = (files) => new Promise(async (resolve, reject) =>{
        try{
            const form = new FormData();
            files.map(item => {
                return form.append('images', item)
            })
            const result = await axios.post(path_url+'/upload/uploadImageComment', form, {
                headers:{
                    'Content-Type' : 'multipart/form-data'
                }
            })

            if(result.status === 200){
                resolve(result.data)
            }
        }catch(e){
            reject(e)
        }
    })

    async function submitComment(){
        setLoadingComment(true)
        try{
            const images = [];
            // const body = null;

            if(newImage.value.length !== 0){
                const resultImg = await uploadImageComment(newImage.value);
                resultImg.map((item) => images.push({path: item.path}))
            }

            const data = {...dataFetch}
            const dataComment = [...data.comments]
            dataComment.push({
                images: images,
                user: userData?._id,
                name: userData?.name,
                text: text,
                create_At: new Date()
            })

            const responsive = await axios.post(path_url+'/announce/addComment', {
                _id: dataFetch?._id,
                emailSender: dataFetch?.user?.email,
                nameSender: dataFetch?.user?.name,
                comments: dataComment
            })

            if(responsive.status === 200){
                window.location.reload()
            }

        }catch(e){
            console.log(e)
        }
    }

    const [commentEdit, setCommentEdit] = React.useState(null)
    const [editImg, setEditImg] = React.useState({
        preview: [], 
        value: []
    })
    const inputFile1 = React.useRef(null);
    const [photoFull, setPhotoFull] = React.useState({
        isOpen: false,
        path: ""
    })

    const onEditImagePush = (val) =>{
        setEditImg(item =>{
            const preview = [...item.preview];
            preview.push(URL.createObjectURL(val[0]))
            const image = [...item.value];
            image.push(val[0])
            return{...item, preview: preview, value: image}
        })
    }

    const onChangeImg = () => {
        const imageCount = Array.from(commentEdit.images).length;
        const imageCount2 = editImg.value.length
        if((imageCount + imageCount2) < 3){
            inputFile1.current.click();
        }else{
            Swal.fire({
                icon:'warning',
                text: 'รูปภาพไม่เกิน 3 รูป เท่านั้น!',
                confirmButtonColor: '#DE1414'
            })
        }
    }

    function editImgRemove(item){
        setCommentEdit((prev) =>{
            const data = {...prev}
            const imgArr = [...data.images]
            const remove = imgArr.filter(({},i) => i !== item)
            return {...data, images: [...remove]}
        })
    }

    function editCommentClose(){
        setCommentEdit(null)
        setEditImg({
            preview: [], 
            value: []
        })
    }

    function editNewImgRemove(id){
        setEditImg((prev) =>{
            const preview = [...prev.preview];
            const image = [...prev.value];
            const removePreview = preview.filter((item,i) => i !== id)
            const removeImage = image.filter((item,i) => i !== id)
            return{...prev, preview: [...removePreview], value: [...removeImage]}
        })
    }

    async function onEditSubmit(){
        try{
            const images = [...commentEdit.images];

            if(editImg.value.length !== 0){
                const resultImg = await uploadImageComment(editImg.value);
                resultImg.map((item) => images.push({path: item.path}))
            }

            const data = {...dataFetch}
            const dataComment = [...data.comments]
            for(var i=0; dataComment.length > i; i++){
                if(dataComment[i]._id === commentEdit._id){
                    dataComment[i].text = commentEdit.text
                    dataComment[i].images = images
                }
            }

            const responsive = await axios.post(path_url+'/announce/updateComment', {
                _id: dataFetch?._id,
                comments: dataComment
            })

            if(responsive.status === 200){
                setDataFetch({...data, comments: [...dataComment]})
                editCommentClose()
                setSuccessAlert(true)
                setTimeout(() => {
                    setSuccessAlert(false)
                },1500)
            }

        }catch(e){
            console.log(e)
        }
    }

    const handleClose = () => {
        setPhotoFull({
            isOpen: false,
            path: ""
        })
    };

    function confirmDeleteComment(id){
        Swal.fire({
            icon:'warning',
            text: 'แน่ใจหรือไม่ที่ต้องการลบคอมเมนต์นี้!',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: '#1a75ff',
            confirmButtonText: 'ตกลง',
            cancelButtonColor: '#DE1414',
            cancelButtonText: 'ยกเลิก'
        }).then((result) =>{
            if(result.isConfirmed){
                deleteComment(id)
            }
        })
    }

    async function deleteComment(id){
        try{
            const data = {...dataFetch};
            const dataComment = [...data.comments];
            const remove = dataComment.filter(({},i) => i !== id);

            const responsive = await axios.post(path_url+'/announce/updateComment', {
                _id: dataFetch?._id,
                comments: remove
            })

            if(responsive.status === 200){
                setDataFetch({...data, comments: [...remove]})
                setDeleteAlert(true)
                setTimeout(() => {
                    setDeleteAlert(false)
                },1500)
            }

        }catch(e){
            console.log(e)
        }
    }

    return(dataFetch ? (
        <>
        <Navbars />
        <div className='announce_view'>
            <div className='container'>
                <div className='container-1'>
                    <div className='gotoBack' onClick={() => history.goBack()}>
                        <FontAwesomeIcon icon={faChevronLeft} style={{fontSize: 25}} />
                    </div>
                    <div className='date'>
                        {new Date(dataFetch.create_At).toLocaleDateString('th',{day:'2-digit', month:'short', year:'2-digit'})}
                    </div>
                    <div className='flex-1 mb-30'>
                        <img alt="profile-img" src={dataFetch?.user?.profileImg ? dataFetch?.user?.profileImg : blank_img} className='img-profile' />
                        <p className='name'>{dataFetch?.user?.name ? dataFetch?.user?.name : "ไม่พบรายชื่อ"}</p>
                        <p className='oversize'>ต้องการ</p>
                        <div className="text-wrapper">
                            <FontAwesomeIcon icon={faLocationDot} className="mr-10 icon" />
                            <div className="label">
                                <p className="label-1">
                                    จ.{dataFetch?.province}
                                </p>
                                <p className="label-2">
                                    อ.{dataFetch?.district}
                                </p>
                            </div>
                        </div>
                        
                    </div>
                    <div className="flex-2">
                        <Row className='mb-10'>
                            <Col className="col-3">
                                <label className='label'>วัตถุประสงค์ : </label>
                            </Col>
                            <Col>
                                {choose(dataFetch?.choice)}
                            </Col>
                        </Row>
                        <Row className='mb-10'>
                            <Col className="col-3">
                                <label className='label'>ขนาดเนื้อที่ : </label>
                            </Col>
                            <Col>
                                {`${dataFetch?.size?.lowest?.count} ${unitSize(dataFetch?.size?.lowest?.square)} - ${dataFetch?.size?.maximum?.count} ${unitSize(dataFetch?.size?.maximum?.square)}`}
                            </Col>
                        </Row>
                        <Row className='mb-10'>
                            <Col className="col-3">
                                <label className='label'>ราคารวม : </label>
                            </Col>
                            <Col>
                                {`${intl.format(dataFetch?.price?.lowest)} - ${intl.format(dataFetch?.price?.maximum)}`}
                            </Col>
                        </Row>
                        <Row className='mb-10'>
                            <Col className="col-3">
                                <label className='label'>รายละเอียด : </label>
                            </Col>
                            <Col>
                                {dataFetch?.description}
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='container-2'>
                    {userData ? 
                    <>
                        {userData.role !== "PREMIUM" ? 
                        <div className='backdrop-ovelay'>
                            <div className='container'>
                                <p style={{color: 'white'}} className='mb-20'>
                                    ขออภัย สงวนสิทธิ์สำหรับสมาชิกพรีเมี่ยมเท่านั้น
                                </p>
                                <Button color='default' size='sm'>
                                    สมัคร คลิก
                                </Button>
                            </div>
                        </div>
                        : null}
                        <div className='container-flex mb-20'>
                            <img alt='' src={userData?.profileImg ? userData?.profileImg : blank_img} className='img-profile' />
                            <div style={{width: '100%'}}>
                                <p className='ml-10 mb-10'>{userData?.name}</p>
                                <textarea 
                                rows={3} 
                                className='form-textarea-1' 
                                placeholder='เขียนข้อความ........' 
                                value={text}
                                onChange={(val) => setText(val.target.value)}
                                />
                                <Button 
                                color='transparent' 
                                size='sm'
                                onClick={() => onButtonClick()}
                                >
                                    <FontAwesomeIcon icon={faImages} /> <small className='color-red'>(* ไม่เกิน 3 รูป)</small>
                                </Button>
                            </div>
                        </div>
                        <div className='mb-20'>
                            <input 
                            ref={inputFile} 
                            type={'file'} 
                            hidden 
                            accept="image/jpg, image/jpeg, image/png" 
                            onChange={(val) => onImagePush(val.target.files)}
                            />
                            <div style={{position:'relative', display:'flex', alignItems:'center'}}>
                            {newImage.preview.map((val, i) =>{
                                return(
                                    <div key={i} style={{position:'relative', marginLeft: 10}}>
                                        <img 
                                        alt='' 
                                        src={val} 
                                        style={{
                                            width: 150, 
                                            height: 150, 
                                            objectFit: 'contain',
                                            border: '1px solid #EEEEEE'
                                        }} 
                                        />
                                        <div 
                                        onClick={() => removePreview(i)}
                                        style={{
                                            display:'flex',
                                            justifyContent:'center',
                                            alignItems:'center',
                                            position: 'absolute',
                                            top: -5,
                                            right: -5,
                                            width: 22,
                                            height:22,
                                            borderRadius: '50%',
                                            background:'red',
                                            border:'none',
                                            color: 'white',
                                            cursor:'pointer'
                                        }}
                                        >x</div>
                                    </div>
                                )
                            })}
                            </div>
                        </div>
                    </>
                    : 
                    <>
                        <div className='backdrop-ovelay'>
                            <div className='container'>
                                <p style={{color: 'white'}} className='mb-20'>
                                    กรุณาเข้าสู่ระบบก่อนการดำเนินการใดๆ
                                </p>
                                <Button color='default' size='sm' onClick={() => setLoginOpen(true)}>
                                    เข้าสู่ระบบ
                                </Button>
                            </div>
                        </div>
                        <div className='container-flex  mb-20'>
                            <img alt='' src={blank_img} className='img-profile' />
                            <div style={{width: '100%'}}>
                                <p className='ml-10 mb-10'>{userData?.name}</p>
                                <textarea 
                                rows={3} 
                                className='form-textarea-1' 
                                placeholder='เขียนข้อความ........' 
                                disabled
                                />
                                <Button 
                                color='transparent' 
                                size='sm'
                                onClick={() => onButtonClick()}
                                >
                                    <FontAwesomeIcon icon={faImages} /> <small className='color-red'>(* ไม่เกิน 3 รูป)</small>
                                </Button>
                            </div>
                        </div>
                        <div className='mb-20'>
                            <input 
                            ref={inputFile} 
                            type={'file'} 
                            hidden 
                            accept="image/jpg, image/jpeg, image/png" 
                            onChange={(val) => onImagePush(val.target.files)}
                            />
                            <div style={{position:'relative', display:'flex', alignItems:'center'}}>
                            {newImage.preview.map((val, i) =>{
                                return(
                                    <div key={i} style={{position:'relative', marginLeft: 10}}>
                                        <img 
                                        alt='' 
                                        src={val} 
                                        style={{
                                            width: 150, 
                                            height: 150, 
                                            objectFit: 'contain',
                                            border: '1px solid #EEEEEE'
                                        }} 
                                        />
                                        <div 
                                        onClick={() => removePreview(i)}
                                        style={{
                                            display:'flex',
                                            justifyContent:'center',
                                            alignItems:'center',
                                            position: 'absolute',
                                            top: -5,
                                            right: -5,
                                            width: 22,
                                            height:22,
                                            borderRadius: '50%',
                                            background:'red',
                                            border:'none',
                                            color: 'white',
                                            cursor:'pointer'
                                        }}
                                        >x</div>
                                    </div>
                                )
                            })}
                            </div>
                        </div>
                        
                        </>
                    }
                    
                    <Button 
                    color='circle-md' 
                    size='sm'
                    onClick={submitComment}
                    disabled={loadingComment}
                    >
                        <FontAwesomeIcon icon={faPaperPlane} className='mr-10' /> ส่ง 
                        {loadingComment ? 
                        <Spinner size='sm' className="ml-10" />
                        : null}
                    </Button>
                </div>

                {dataFetch?.comments?.sort((a, b) => new Date(a.create_At).getTime() < new Date(b.create_At).getTime() ? 1 : -1).map((items, i) =>{
                    return(
                        <div className='container-post mb-30' key={i}>
                            {userData?._id === items?.user?._id ? 
                            <>
                                <Button 
                                id={`Popover${i}`}
                                color='transparent' 
                                style={{
                                    position: 'absolute', 
                                    top: 10, 
                                    right: 15,
                                    color: 'gray'
                                }}
                                >
                                    <FontAwesomeIcon icon={faEllipsis} />
                                </Button>
                                <UncontrolledPopover 
                                target={`Popover${i}`}
                                placement='bottom'
                                >
                                    <PopoverBody>
                                        <ul>
                                            <li className='li' onClick={() => setCommentEdit(items)}>
                                                แก้ไข
                                            </li>
                                            <li className='li' onClick={() => confirmDeleteComment(i)}>
                                                ลบความคิดเห็นนี้
                                            </li>
                                        </ul>
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </>
                            : null}
                            {commentEdit?._id === items._id ? 
                            <div className='container-flex mb-20'>
                                <img alt='' src={commentEdit?.user?.profileImg ? commentEdit?.user?.profileImg : blank_img} className='img-profile' />
                                <div style={{width: '100%'}}>
                                    <p className='mb-10'>{commentEdit?.user?.name} <label>({moment(commentEdit?.create_At).startOf("minute").fromNow()})</label></p>
                                    <textarea 
                                    rows={3} 
                                    className='form-textarea-1' 
                                    placeholder='เขียนข้อความ........' 
                                    value={commentEdit?.text}
                                    onChange={(val) => setCommentEdit({...commentEdit, text: val.target.value})}
                                    />
                                    <Button 
                                    className='mb-20'
                                    color='transparent' 
                                    size='sm'
                                    onClick={() => onChangeImg()}
                                    >
                                        <FontAwesomeIcon icon={faImages} /> <small className='color-red'>(* ไม่เกิน 3 รูป)</small>
                                    </Button>
                                    <input 
                                    ref={inputFile1} 
                                    type={'file'} 
                                    hidden 
                                    accept="image/jpg, image/jpeg, image/png" 
                                    onChange={(val) => onEditImagePush(val.target.files)}
                                    />
                                    <div style={{display:'flex'}} className='mb-50'>
                                        {commentEdit?.images?.map((IMGitem, i) =>{
                                            return(
                                                <div key={i} style={{position:'relative', marginLeft: 10}}>
                                                    <img 
                                                    alt='' 
                                                    src={IMGitem?.path} 
                                                    style={{width: 150, marginRight: 10}}
                                                    />
                                                    <div 
                                                    onClick={() => editImgRemove(i)}
                                                    style={{
                                                        display:'flex',
                                                        justifyContent:'center',
                                                        alignItems:'center',
                                                        position: 'absolute',
                                                        top: -5,
                                                        right: 0,
                                                        width: 22,
                                                        height:22,
                                                        borderRadius: '50%',
                                                        background:'red',
                                                        border:'none',
                                                        color: 'white',
                                                        cursor:'pointer'
                                                    }}
                                                    >x</div>
                                                </div>
                                            )
                                        })}
                                        {editImg.preview.map((IMGitem, i) =>{
                                            return(
                                                <div key={i} style={{position:'relative', marginLeft: 10}}>
                                                    <img 
                                                    alt='' 
                                                    src={IMGitem} 
                                                    style={{width: 150, marginRight: 10}}
                                                    />
                                                    <div 
                                                    onClick={() => editNewImgRemove(i)}
                                                    style={{
                                                        display:'flex',
                                                        justifyContent:'center',
                                                        alignItems:'center',
                                                        position: 'absolute',
                                                        top: -5,
                                                        right: 0,
                                                        width: 22,
                                                        height:22,
                                                        borderRadius: '50%',
                                                        background:'red',
                                                        border:'none',
                                                        color: 'white',
                                                        cursor:'pointer'
                                                    }}
                                                    >x</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div style={{display:'flex'}}>
                                        <Button
                                        color='default'
                                        size='sm'
                                        className='mr-20 full-w'
                                        onClick={() => onEditSubmit()}
                                        >
                                            บันทึกการแก้ไข
                                        </Button>
                                        <Button 
                                        color='secondary'
                                        size='sm'
                                        className='full-w'
                                        onClick={() => editCommentClose()}
                                        >
                                            ยกเลิก
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            : 
                            <div className='container-flex mb-20'>
                                <img alt='' src={items?.user?.profileImg ? items?.user?.profileImg : blank_img} className='img-profile' />
                                <div style={{width: '100%'}}>
                                    <p className='mb-10'>{items?.user?.name} <label>({moment(items?.create_At).startOf("minute").fromNow()})</label></p>
                                    <p className='text mb-20'>{items?.text}</p>
                                    <div style={{display:'flex'}}>
                                        {items?.images?.map((IMGitem, i) =>{
                                            return(
                                                <img 
                                                alt='' 
                                                src={IMGitem?.path} 
                                                key={i} 
                                                style={{width: 150, marginRight: 10}} 
                                                onClick={() => setPhotoFull({isOpen: true, path: IMGitem?.path}) }
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            }
                            
                        </div>
                    )
                })}
                
            </div>
        </div>
        <Footer/>
        <PhotoSwipe
        isOpen={photoFull.isOpen} 
        items={[{
            src: photoFull.path,
            w: 1400,
            h: window.innerHeight,
        }]} 
        shareLinkClick={false}
        onClose={() => handleClose()}
        options={{bgOpacity: 0.5}}
        />
        <LoginModal isOpen={loginOpen} onClose={() => setLoginOpen(false)} />
        <Alert isOpen={successAlert}>
            <FontAwesomeIcon icon={faCircleCheck} className='mr-10' />
            แก้ไขเรียบร้อยแล้ว
        </Alert>
        <Alert color='danger' isOpen={deleteAlert}>
            <FontAwesomeIcon icon={faTrash} className='mr-10' />
            ลบเรียบร้อยแล้ว
        </Alert>
        </>
    ): null)
}
export default AnnounceDetail;