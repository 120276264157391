import React from "react";
import { 
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Input,
    Label,
    Button
}from 'reactstrap';
import Navbars from "routers/navbars";
import Footer from "components/footer";
import PhotoGrid from "components/photogrid/photogrid";
import axios from "axios";
import { ErrorAlert } from "components/alert";
import { useLocation } from "react-router-dom";
import { 
    GoogleMap, 
    useJsApiLoader, 
    Polygon,
    Marker
} from '@react-google-maps/api';
import circle_point from 'assets/img/Geo-1.svg';

const path_url = process.env.REACT_APP_API_CONNECT;
const google_api = process.env.REACT_APP_GOOGLE_API;

function SecretProject(){
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: google_api,
        region:'th',
        language: 'th',
        version: "weekly",
    });
    const [Estate, setEstate] = React.useState(null);
    const [tokenToggle, setTokenToggle] = React.useState(true);
    const [tokenVal, setTokenVal] = React.useState('');
    const [coordinateUser, setCoordinateUser] = React.useState({
        lat: 18.737666197841595,
        lng: 98.98034537929969,
    })

    React.useEffect(() =>{
        navigator.geolocation.getCurrentPosition(function(position){
            const {latitude, longitude} = position.coords;
            setCoordinateUser({lat:latitude, lng:longitude})
        },(err)=>{
            if(err){
                ErrorAlert({text:'กรุณาตรวจสอบคำขออนุญาตการเปิดใช้งานที่ตั้งของท่าน'})
            }
        })
    },[])
    
    function useQuery() {
        const { search } = useLocation();
        return new URLSearchParams(search);
    }

    let query = useQuery();
    const google = window.google;

    const onSubmit = async (e) =>{
        e.preventDefault();
        try{
            const res = await axios.post(path_url+'/consignment/checkToken', {
                token: tokenVal
            })

            if(res.status === 200){
                onFetch()
                setTokenToggle(false)
            }
        }catch(e){
            console.log(e)
            if(e.response.status === 401){
                ErrorAlert({text: 'รหัสหมดอายุแล้ว'})
            }else if(e.response.status === 402){
                ErrorAlert({text: 'ไม่พบรหัสนี้'})
            }else{
                ErrorAlert({text: e})
            }
        }
    }

    const onFetch = () =>{
        const titledeed = query.get("sale");
        axios.post(path_url+'/consignment/getConsignById',{
            titleDeed: titledeed
        }).then(result =>{
            if(result.status === 200){
                if(result.data) setEstate(result.data);
                else ErrorAlert({text:'ไม่พบข้อมูล! กรุณาลองใหม่อีกครั้ง'});
            }
        }).catch((e) =>{
            ErrorAlert({text:'ไม่พบข้อมูล! กรุณาลองใหม่อีกครั้ง'})
        })
    }

    const intl = new Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 0,
    })

    return(
        <>{Estate ? 
        <>
        <Navbars />
        <div className="consign-view-container">
            <div className="consign-header mb-30">
                <h5>{Estate.title}</h5>
            </div>
            <div className="consign-picture mb-50">
                <PhotoGrid items={Estate.images ? Estate?.images?.map(items => {return {src: items.path}}) : []} />
            </div>
            <div className="consign-info mb-50">
                <div className="consign-info-container-1">
                    <span className="header mb-20">ข้อมูลและรายละเอียดแปลงที่ดิน</span>
                    <div className="consign-description mb-10">
                        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-2">
                            <Col>
                                <label className="title">รหัสประกาศ</label>
                                <label className="text">{Estate?.saleID}</label>
                            </Col>
                            <Col>
                                <label className="title">โฉนดเลขที่</label>
                                <label className="text">{Estate?.titleDeedNumber}</label>
                            </Col>
                            <Col>
                                <label className="title">ประเภท</label>
                                <label className="text">{Estate?.saleType === 'LAND' ? 'ที่ดินเปล่า' : 'พร้อมสิ่งปลูกสร้าง'}</label>
                            </Col>
                            <Col>
                                <label className="title">ที่อยู่</label>
                                <label className="text">{Estate?.district + ", " + Estate?.province}</label>
                            </Col>
                        </Row>
                        <div className="consign-highlight">
                            <label className="title">จุดเด่นของแปลงที่ดิน</label>
                            <textarea 
                            className="text" 
                            rows={8} 
                            disabled 
                            defaultValue={Estate?.description}
                            />
                        </div>
                    </div>
                </div>
                <div className="consign-info-container-2">
                    <div className="detail-wrapper mb-20">
                        <div 
                        style={{
                            paddingLeft: 20, 
                            paddingTop:10, 
                            paddingBottom:10, 
                            borderBottom:'0.5px solid gray', 
                            marginBottom: 10
                        }}>
                            <p style={{fontWeight: 400}}>รายละเอียด</p>
                        </div>
                        <div style={{padding: 10}}>
                            <p style={{marginBottom: 10}}>
                                {'พื้นที่ขนาด '}
                                {Estate?.size?.rai ? Estate?.size?.rai+' ไร่ ': null}
                                {Estate?.size?.ngan ? Estate?.size?.ngan+' งาน ': null} 
                                {Estate?.size?.wa ? Estate?.size?.wa+' ตารางวา': null} 
                            </p>
                            <p style={{marginBottom: 10}}>
                                ราคา {intl.format(Estate?.pricePerWa)} / ตร.ว.
                            </p>
                            <p style={{marginBottom: 10, fontWeight:500}} className="color-default font">
                                ราคารวม {intl.format(Estate?.totalPrice)}
                            </p>
                        </div>
                    </div>
                    <div className="mb-30">
                        <div className="color-gray" style={{border : '0.5px solid gray', textAlign:'center', borderRadius:10, padding:10}}>
                            โปรเจกต์ลับ
                        </div>
                    </div>
                </div>
            </div>
            <div className="consign-map mb-50">
                <p className="header">แผนที่</p>
                {google && isLoaded ?
                    <GoogleMap
                    mapContainerClassName="mapWrapper"
                    center={{
                        lat: Estate?.consigndeed?.centerPoint?.coordinates[1], 
                        lng: Estate?.consigndeed?.centerPoint?.coordinates[0]
                    }}
                    zoom={18}
                    options={{ 
                        gestureHandling: 'greedy',
                        fullscreenControl: false,
                        mapTypeControl: true,
                        mapTypeControlOptions:{
                            position: google && google.maps.ControlPosition.LEFT_TOP,
                            style: google && google.maps.MapTypeControlStyle.DROPDOWN_MENU
                        }
                    }}
                    >
                        <Marker 
                        position={coordinateUser}
                        icon={circle_point}
                        />
                        {Estate?.consigndeed ? 
                        <>
                        <Polygon 
                        options={{
                            strokeColor: '#EF9751',
                            strokeWeight: 3,
                            fillColor: '#EF9751'
                        }}
                        path={Estate?.consigndeed?.paths?.coordinates.map(element =>{
                            return {lat: element[1], lng: element[0]}
                        })}
                        />
                        </>
                        : null}
                    </GoogleMap>
                : null}
            </div>
        </div>
        <Footer />
        
        </> : null}
        <Modal isOpen={tokenToggle} >
            <ModalHeader>รหัสลับ</ModalHeader>
            <Form onSubmit={onSubmit}>
                <ModalBody>
                    <FormGroup className="form-group">
                        <Label>key</Label>
                        <Input 
                        type="password"
                        className="color-darkblue border form-control"
                        placeholder="รหัส 4 ตัวอักษร"
                        onChange={(val) => setTokenVal(val.target.value)}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button 
                    type="submit"
                    color="default" 
                    className="btn-sm"
                    // onClick={() => onSubmit()}
                    >
                        ตรวจสอบ
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
        </>
    )
}

export default SecretProject