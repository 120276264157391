import React  from "react";

import {
    Form,
    Input,
    Button,
    FormGroup,
    Label,
    Row,
    Col,
    InputGroup
} from 'reactstrap';

import { PROVINCE_LIST } from "views/components/province";
import { DISTRICT_LIST } from "views/components/districts";

function Consignment_Tabs1(props){
    const [DistrictList, setDistrictList] = React.useState([]);

    const { 
        user, 
        value, 
        onchange 
    } = props;

    const _onSelectProvince = (val) =>{
        const province = PROVINCE_LIST.find(element =>{
            return element.province_name === val
        });

        const district = DISTRICT_LIST.filter((item) =>{
            return item.province_id === province?.province_id
        }).map((element) =>{
            return element
        });
        setDistrictList(district);
        onchange(province.province_name, "province")
        onchange('', "district")
    }

    const _onChangDistrict = (val) =>{
        const district = DISTRICT_LIST.find(element =>{
            return element.amphur_name === val
        })
        // setEnterData(item => {
        //     return{...item, district: val}
        // })
        onchange(district.amphur_name, "district")
    }

    const _handlerLandCal = () =>{
        const rai = value?.rai ? parseFloat(value.rai) : 0;
        const ngan = value?.ngan ? parseFloat(value.ngan) : 0;
        const wa = value.wa ? parseFloat(value.wa) : 0;

        const cal_rai = rai * 4;
        const cal_ngan = (cal_rai + ngan) * 100;
        const cal_wa = (cal_ngan + wa)
        
        const cal_price = (parseFloat(value.pricePerWa) * cal_wa).toString()

        onchange(cal_price, "totalPrice")
    }

    return(
        <>
        <div className="consignment-tab-wrapper">
            <div className="consignment-tab-section-1">
                <Form className="mb-30">
                    <div className="title">ข้อมูลเจ้าของทรัพย์</div>
                    <Row style={{alignItems:'flex-end'}} className="mb-20">
                        <Col className="col-md-2 col-sm-12">
                            <FormGroup className="form-group">
                                <div>ผู้ลงขาย <Label className="color-red font-weight-500" style={{fontSize:20}}>*</Label></div>
                                <Input 
                                placeholder="Name" 
                                className="color-darkblue border" 
                                type="text" 
                                disabled
                                defaultValue={user ? user.name : ""}
                                // onChange={val => inputData(val.target.value, "name")}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="col-md-2 col-sm-12">
                            <FormGroup className="form-group">
                                <div>เบอร์ติดต่อ <Label className="color-red font-weight-500" style={{fontSize:20}}>*</Label></div>
                                <Input 
                                placeholder="Phone Number" 
                                className="color-darkblue border" 
                                type="tel" 
                                disabled
                                defaultValue={user ? user.phoneNumber : ""}
                                // onChange={(val) => inputData(val.target.value, "tel")}
                                />
                                {/* {userData 
                                ? !userData.phoneNumber 
                                ? <FormText className="color-red">* กรุณาแก้ไขข้อมูลส่วนตัว</FormText>
                                : null
                                : null} */}
                            </FormGroup>
                        </Col>
                        <Col className="col-md-2 col-sm-12">
                            <FormGroup className="form-group">
                                <div>เบอร์ติดต่อสำรอง</div>
                                <Input 
                                placeholder="Reserve Phone Number" 
                                className="color-darkblue border" 
                                type="tel" 
                                disabled
                                defaultValue={user ? user.phoneBackup : ""}
                                // onChange={(val) => inputData(val.target.value, "tel_backup")}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="title">ประเภทธุรกรรม</div>
                    <div className="selected">
                        <Button 
                        color={value.transaction_type === "MORTGAGE" ? "selected" : "default-outline" }
                        size="sm" 
                        style={{margin:10}}
                        onClick={() => onchange("MORTGAGE", "transaction_type")}
                        >
                            จำนอง
                        </Button>
                        <Button 
                        color={value.transaction_type === "CONSIGNMENT" ? "selected" : "default-outline" }
                        size="sm" 
                        style={{margin:10}}
                        onClick={() => onchange("CONSIGNMENT", "transaction_type")}
                        >
                            ขายฝาก
                        </Button>
                    </div>
                    <Row className="mb-20">
                        <div className="col-md-6 col-sm-12">
                            <FormGroup className="form-group">
                                <Label className="font-weight-500">
                                    เลขที่โฉนด<Label className="color-red font-weight-500" style={{fontSize:20}}>* </Label> 
                                </Label>
                                <span style={{fontSize:12}}>(กรณีแปลงนี้มีมากกว่า 1 โฉนดให้ใช้เครื่องหมาย "," คั่นระหว่างเลขโฉนด *432136,123456,35795*)</span>
                                <span style={{fontSize:12, marginBottom: 10}}>(ท่านสามารถแอดไลน์ผู้ช่วย  เพื่อให้ช่วยลงประกาศ เช่น ในกรณีที่เป็นแปลงใหญ่ มีหลายโฉนด เป็นต้น)</span>
                                <Input 
                                placeholder="1234, 45677" 
                                className="color-darkblue border" 
                                type="text" 
                                value={value.titledeed_Number}
                                onChange={(val) => onchange(val.target.value, "titledeed_Number")}
                                />
                            </FormGroup>
                        </div>
                    </Row>
                    <div className="title">ประเภททรัพย์</div>
                    <div className="selected">
                        <Button 
                        color={value.saleType === "LAND" ? "selected" : "default-outline" }
                        size="sm" 
                        style={{margin:10}}
                        onClick={() => onchange("LAND", "saleType")}
                        >
                            ที่ดินเปล่า
                        </Button>
                        <Button 
                        color={value.saleType === "WITH_BUILDING" ? "selected" : "default-outline" }
                        size="sm" 
                        style={{margin:10}}
                        onClick={() => onchange("WITH_BUILDING", "saleType")}
                        >
                            พร้อมสิ่งปลูกสร้าง
                        </Button>
                    </div>
                    <Row className="mb-20">
                        <div className="col-md-6 col-sm-12">
                            <FormGroup className="form-group">
                                <Label className="font-weight-500">
                                    หัวข้อ <Label className="color-red font-weight-500" style={{fontSize:20}}>* </Label> 
                                </Label>
                                <Input 
                                placeholder="Title" 
                                className="color-darkblue border" 
                                type="text" 
                                value={value.title}
                                onChange={(val) => onchange(val.target.value, "title")}
                                />
                            </FormGroup>
                        </div>
                    </Row>
                    <Row className="mb-20">
                        <div className="col-md-5 col-sm-12">
                            <Row style={{display:'flex', alignItems:'flex-end'}}>
                                <div className="col-md-6 col-sm-12">
                                    <FormGroup className="form-group">
                                        <Label className="font-weight-500">
                                            ที่อยู่ของแปลงที่ดิน <Label className="color-red font-weight-500" style={{fontSize:20}}>*</Label> 
                                        </Label>
                                        <InputGroup>
                                            <Input 
                                            className="color-darkblue form-control" 
                                            type="select" 
                                            value={value.province}
                                            onChange={(val) =>{ 
                                                _onSelectProvince(val.target.value);
                                            }}
                                            >
                                                <option value={''} disabled>จังหวัด</option>
                                                {PROVINCE_LIST.map((item, i) =>{
                                                    return(
                                                        <option key={i} value={item.province_name}>
                                                            {item.province_name}
                                                        </option>
                                                    )
                                                })}
                                            </Input>
                                        </InputGroup>
                                    </FormGroup>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <FormGroup className="form-group">
                                        <InputGroup>
                                            <Input 
                                            className="color-darkblue form-control" 
                                            type="select" 
                                            disabled={DistrictList.length !== 0 ? false : true}
                                            value={value.district}
                                            onChange={val => _onChangDistrict(val.target.value)}
                                            >
                                                <option value={0} disabled>อำเภอ</option>
                                                {DistrictList.map((item, i) =>{
                                                    return(
                                                        <option key={i} value={item.amphur_name}>
                                                            {item.amphur_name}
                                                        </option>
                                                    )
                                                })}
                                            </Input>
                                        </InputGroup>
                                    </FormGroup>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row className="mb-20">
                        <div className="col-md-6 col-sm-12">
                            <Row style={{display:'flex', alignItems:'flex-end'}}>
                                <div className="col-md-4 col-sm-12">
                                    <FormGroup className="form-group">
                                        <Label className="font-weight-500">
                                            เนื้อที่รวมของทรัพย์ <Label className="color-red font-weight-500" style={{fontSize:20}}>*</Label> 
                                        </Label>
                                        <Input 
                                        placeholder="ไร่" 
                                        className="color-darkblue border" 
                                        type="text" 
                                        value={value.rai}
                                        onChange={(val) => onchange(val.target.value, "rai")}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <FormGroup className="form-group">
                                        <Input 
                                        placeholder="งาน" 
                                        className="color-darkblue border" 
                                        type="text" 
                                        value={value.ngan}
                                        onChange={(val) => onchange(val.target.value, "ngan")}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <FormGroup className="form-group">
                                        <Input 
                                        placeholder="ตารางวา" 
                                        className="color-darkblue border" 
                                        type="text" 
                                        value={value.wa}
                                        onChange={(val) => onchange(val.target.value, "wa")}
                                        />
                                    </FormGroup>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row className="mb-20">
                        <div className="col-md-6 col-sm-12">
                            <Row>
                                <div className="col-md-6 col-sm-12">
                                    <FormGroup className="form-group">
                                        <Label className="font-weight-500">
                                            ราคาต่อตารางวา (บาท) <Label className="color-red font-weight-500" style={{fontSize:20}}>*</Label> 
                                        </Label>
                                        <Input 
                                        placeholder="0" 
                                        className="color-darkblue border" 
                                        type="text" 
                                        value={value.pricePerWa}
                                        onChange={(val) => onchange(val.target.value, "pricePerWa")}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <FormGroup className="form-group">
                                        <Label className="font-weight-500">
                                            ราคารวมทั้งหมด (บาท) <Label className="color-red font-weight-500" style={{fontSize:20}}>*</Label> 
                                        </Label>
                                        <Input 
                                        placeholder="0" 
                                        className="color-darkblue border" 
                                        type="text" 
                                        value={value.totalPrice}
                                        onChange={(val) => onchange(val.target.value, "totalPrice")}
                                        onFocus={() => _handlerLandCal()}
                                        />
                                    </FormGroup>
                                </div>
                                
                            </Row>
                        </div>
                    </Row>
                    <div className="title">ระยะเวลาที่ต้องการ จำนอง / ขายฝาก <Label className="color-red font-weight-500" style={{fontSize:20}}>* </Label> </div>
                    <Row className="mb-20">
                        <Col className="col-12 col-md-6 col-lg-6 col-xl-6">
                            <FormGroup className="form-group">
                                <InputGroup>
                                    <Input 
                                    className="color-darkblue form-control" 
                                    type="select" 
                                    value={value.year}
                                    onChange={val => onchange(val.target.value, 'year')}
                                    >
                                        <option value={0} disabled>เลือกปี</option>
                                        <option value={1}>1 ปี</option>
                                        <option value={2}>2 ปี</option>
                                        <option value={3}>3 ปี</option>
                                        <option value={4}>4 ปี</option>
                                        <option value={5}>5 ปี</option>
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-md-6 col-sm-12">
                            <FormGroup className="form-group">
                                <Label className="title">
                                    รายละเอียดของทรัพย์สินที่จะ จำนอง / ขายฝาก 
                                </Label>
                                <Input
                                type="textarea" 
                                className="form-textarea" 
                                placeholder="ที่ดินติดแม่น้ำ / ติดถนน / อยู่ในเขตชุมชน / ทำเลดี / อยู่ใกล้สถานที่ต่างๆ เช่น ศูนย์ราชการหน้าโรงเรียน หน้าสถานที่ท่องเที่ยว"
                                rows={7} 
                                value={value.description}
                                onChange={(val) => onchange(val.target.value, "description")}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
        </>
    )
}
export default Consignment_Tabs1