import React from "react";
import { 
    Button, 
    Row,
    Col
} from "reactstrap";

import img1 from 'assets/img/Package/consignment/Group 1133.png';
import img2 from 'assets/img/Package/consignment/Group 1134.png';
import img3 from 'assets/img/Package/consignment/Group 1135.png';
import img4 from 'assets/img/Package/consignment/Group 1136.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronRight,
    faChevronDown
} from '@fortawesome/free-solid-svg-icons';

function ConsignmentService(){
    const [slide, setSlide] = React.useState(null)
    return(
        <>
        <div className="consignment-container">
            <h3 className="color-default text-center mb-50 font-weight-500">
                ปรึกษาจำนอง/ขายฝาก
            </h3>
            <div className="text text-center mb-20">
                <p className="text color-default">
                การจำนองและการขายฝาก เป็นอีกทางเลือกหนึ่งสำหรับท่านที่ต้องการเงินทุนหมุนเวียน และมีที่ดิน หรือ อสังหาริมทรัพย์อื่น ๆ
                </p>
                แต่การจำนอง  และขายฝาก มีรายละเอียดที่ต้องศึกษา และทำความเข้าใจก่อนที่จะตัดสินใจดำเนินการ 
                เพื่อไม่ให้ฝ่ายใดฝ่ายหนึ่งต้องตกเป็นฝ่ายเสียเปรียบ หรือทำไปโดยรู้เท่าไม่ถึงการณ์
                ตลาดที่ดิน  มีความตั้งใจที่จะทำให้การซื้อ/ขายที่ดิน  รวมถึงการจำนองและขายฝาก เป็นไปด้วยความโปร่งใส  
                ทั้งผู้จำนอง และผู้รับจำนอง (ผู้ขายฝาก และผู้รับขายฝาก) ต้องมีความรู้ ความเข้าใจในเงื่อนไขทางกฎหมาย 
                และยอมรับเงื่อนไข ก่อนที่จะดำเนินการ เราจึงมีบริการให้คำปรึกษาและจัดหาแหล่งทุนในการจำนองและขายฝาก
                
            </div>
            <Row className="row-cols-2 row-cols-md-4 row-cols-lg-4 consignment-img-wrapper mb-20">
                <Col className="mb-10">
                    <div className="consignment-img">
                        <img alt="i1" src={img1} className="img" />
                        <div 
                        className={`btn consignment-detail ${slide === 1 ? 'show' : ''}`} 
                        onClick={() => setSlide(item => !item || item !== 1 ? 1 : null)}
                        >
                            <div className="header">
                                <div className="wrapper-1">
                                    <h6>ให้ความรู้เกี่ยวกับ</h6>
                                    <small className="b">การจำนอง และขายฝาก</small>
                                </div>
                                <FontAwesomeIcon icon={slide === 1 ? faChevronDown : faChevronRight} />
                            </div>
                            <div className={`discription ${slide === 1 ? 'show' : ''}`}>
                                <ul>
                                    <li>
                                        ให้ความรู้เบื้องต้น ระหว่างการจำนองและการขายฝาก
                                    </li>
                                    <li>
                                        ให้ความรู้รายละเอียด ของการจำนองและการขายฝาก
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="mb-10">
                    <div className="consignment-img">
                        <img alt="i2" src={img2} className="img" />
                        <div 
                        className={`btn consignment-detail ${slide === 2 ? 'show' : ''}`} 
                        onClick={() => setSlide(item => !item || item !== 2 ? 2 : null)}
                        >
                            <div className="header">
                                <div className="wrapper-1">
                                    <h6>พร้อมให้คำปรึกษา</h6>
                                    <small className="b">การจำนอง และขายฝาก</small>
                                </div>
                                <FontAwesomeIcon icon={slide === 2 ? faChevronDown : faChevronRight} />
                            </div>
                            <div className={`discription ${slide === 2 ? 'show' : ''}`}>
                                <ul>
                                    <li>
                                        ให้คำปรึกษากับลูกค้า เรื่องของราคาระยะเวลา ที่เหมาะสม
                                    </li>
                                    <li>
                                        ให้คำแนะนำวิธีการ จำนอง ขายฝาก
                                    </li>
                                    <li>
                                        แนะนำข้อดี ข้อเสีย ให้กับลูกค้า
                                    </li>
                                    <li>
                                        แนะนำข้อควรระวัง
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="mb-10">
                    <div className="consignment-img">
                        <img alt="i3" src={img3} className="img" />
                        <div 
                        className={`btn consignment-detail ${slide === 3 ? 'show' : ''}`} 
                        onClick={() => setSlide(item => !item || item !== 3 ? 3 : null)}
                        >
                            <div className="header">
                                <div className="wrapper-1">
                                    <h6>ยินดีช่วยแนะนำ</h6>
                                    <small className="b">กลุ่มของนายทุน</small>
                                </div>
                                <FontAwesomeIcon icon={slide === 3 ? faChevronDown : faChevronRight} />
                            </div>
                            <div className={`discription ${slide === 3 ? 'show' : ''}`}>
                                <ul>
                                    <li>
                                        ที่รักษาจรรยาบรรณ ไม่เอาเปรียบ ให้แก่ท่านที่สนใจ
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="mb-10">
                    <div className="consignment-img">
                        <img alt="i4" src={img4} className="img" />
                        <div 
                        className={`btn consignment-detail ${slide === 4 ? 'show' : ''}`} 
                        onClick={() => setSlide(item => !item || item !== 4 ? 4 : null)}
                        >
                            <div className="header">
                                <div className="wrapper-1">
                                    <h6>เก็บข้อมูลเป็นความลับ</h6>
                                </div>
                                <FontAwesomeIcon icon={slide === 4 ? faChevronDown : faChevronRight} />
                            </div>
                            <div className={`discription ${slide === 4 ? 'show' : ''}`}>
                                <ul>
                                    <li>
                                        ทางเราจะเก็บรักษาข้อมูลของท่าน
                                        ให้เป็นความลับ จะไม่มีการเผยแพร่
                                        ข้อมูลให้บุคคลอื่น ถ้าหากยังไม่ได้
                                        รับอนุญาตจากลูกค้าเอง 
                                        เพื่อปกป้องผลประโยชน์และความเป็น
                                        ส่วนตัวของลูกค้า
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className="consignment-footer">
                <Button 
                color="default"
                onClick={() => window.location.href = '/consignment'}
                >
                    ส่งข้อมูลจำนอง / ขายฝาก
                </Button>
            </div>
        </div>
        </>
    )
}
export default ConsignmentService