import React from "react";
import {
    Row,
    Col,
    Label
} from "reactstrap"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebookF, 
    faLine, 
    faYoutube,
    faTiktok
} from "@fortawesome/free-brands-svg-icons";

const line_path = process.env.REACT_APP_LINE_PATH;

function Footer(){
    const [active, setActive] = React.useState(null);
    return(
        <>
        <div className="footer">
            <Row className="footer-section-1">
                <Col className="col-12 col-md-3 col-sm-12 dropdown" onClick={() => setActive(active === '1' ? null : '1')}>
                    <Label className="title">เกี่ยวกับเรา</Label>
                    <ul className={active === '1' ? "active" : ""}>
                        <li>
                            <a href="/About">ความเป็นมาของตลาดที่ดิน</a>
                        </li>
                    </ul>
                </Col>
                <Col className="col-12 col-md-3 col-sm-12 dropdown dropdown" onClick={() => setActive(active === '2' ? null : '2')}>
                    <Label className="title">บทความน่าสนใจ</Label>
                    <ul className={active === '2' ? "active" : ""}>
                        <li>
                            <a href="/content">สาระน่ารู้จาก ตลาดที่ดิน</a>
                        </li>
                        <li>
                            <a href="/content">Vdo Content</a>
                        </li>
                    </ul>
                </Col>
                <Col className="col-12 col-md-3 col-sm-12 dropdown" onClick={() => setActive(active === '3' ? null : '3')}>
                    <Label className="title">เกี่ยวกับที่ดิน</Label>
                    <ul className={active === '3' ? "active" : ""}>
                        <li>
                            <a href="/">ลงประกาศขายที่ดิน</a>
                        </li>
                        <li>
                            <a href="/">ลงประกาศหาที่ดิน</a>
                        </li>
                        <li>
                            <a href="/">ดูที่ดินที่น่าสนใจ</a>
                        </li>
                        <li>
                            <a href="/">ค้นหาโดยใช้แผนที่</a>
                        </li>
                    </ul>
                </Col>
                <Col className="col-12 col-md-3 col-sm-12 dropdown" onClick={() => setActive(active === '4' ? null : '4')}>
                    <Label className="title">ศูนย์ช่วยเหลือ</Label>
                    <ul className={active === '4' ? "active" : ""}>
                        <li>
                            <a href="/helpCenter">วิธีการลงทะเบียน</a>
                        </li>
                        <li>
                            <a href="/helpCenter">วิธีการลงประกาศขาย</a>
                        </li>
                        <li>
                            <a href="/helpCenter">วิธีการชำระเงิน</a>
                        </li>
                        <li>
                            <a href="/helpCenter">วิธีการค้นหาทรัพย์</a>
                        </li>
                        <li>
                            <a href="/helpCenter">วิธีการลงประกาศหา</a>
                        </li>
                        <li>
                            <a href="/helpCenter">คำถามยอดนิยม</a>
                        </li>
                        <li>
                            <a href="/helpCenter">ร้องเรียน/แจ้งข้อมูลไม่ถูกต้อง/แจ้งอัพเดทข้อมูล</a>
                        </li>
                    </ul>
                </Col>
            </Row>
            <div className="footer-section-2">
                <div className="footer-section-2-social">
                    <button className="btn-social mr-10" onClick={() => window.open('https://www.facebook.com/profile.php?id=100063635147660')}>
                        <FontAwesomeIcon icon={faFacebookF} className="icon" />
                    </button>
                    <button className="btn-social mr-10" onClick={() => window.open(line_path)}>
                        <FontAwesomeIcon icon={faLine} className="icon" />
                    </button>
                    <button className="btn-social mr-10" onClick={() => window.open('https://www.youtube.com/channel/UCDDllnPTvKVQS44xb0rlLBw')}>
                        <FontAwesomeIcon icon={faYoutube} className="icon" />
                    </button>
                    <button className="btn-social mr-10" onClick={() => window.open('https://www.tiktok.com/@taladteedin')}>
                        <FontAwesomeIcon icon={faTiktok} className="icon" />
                    </button>
                </div>
                <div>
                    <Link to="/Privacy" target="_blank" className="foot-sec-text">นโยบาย</Link>
                    © {new Date().getFullYear()}, TaladTeedin.com Co., Ltd.
                </div>
            </div>
        </div>
        </>
    )
}
export default Footer