import React from "react";
import Navbars from "routers/navbars";
import Footer from "components/footer";

import {
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Button
} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faPlus,
    faCalculator
} from "@fortawesome/free-solid-svg-icons";
import {
    faAndroid,
    faApple
} from "@fortawesome/free-brands-svg-icons";

function Calculator(){

    const [formular, setFormular] = React.useState([
        {
            rai: "",
            ngan: "",
            wa: "",
        },
        {
            rai: "",
            ngan: "",
            wa: "",
        },
    ])

    const [sumFormular, setSumFormular] = React.useState({
        rai: "",
        ngan: "",
        wa: ""
    })

    const addRows = () =>{
        setFormular(items =>{
            const item = [...items];
            item.push({
                rai: "",
                ngan: "",
                wa: "",
            })
            return item
        })
    }

    function onChangeFormular(val, props, key){
        setFormular((items) =>{
            const data = [...items];
            data[key][props] = val;
            return data
        })
    }

    const mod = (dividend, divisor) => ((dividend % divisor) + divisor) % divisor;

    function calculator(){
        const rai = formular.reduce((sum, obj) =>{
            return sum += parseInt(obj.rai ? obj.rai : 0)
        },0)

        const ngan = formular.reduce((sum, obj) =>{
            return sum += parseInt(obj.ngan ? obj.ngan : 0)
        },0)

        const wa = formular.reduce((sum, obj) =>{
            return sum += parseFloat(obj.wa ? obj.wa : 0)
        },0)

        const cal = ((rai*400)+(ngan*100)+ wa)

        const cal_rai = parseInt(cal/400)
        const cal_ngan = parseInt(mod(cal,400)/100)
        const cal_wa = parseFloat(mod(cal,100)).toFixed(2)

        setSumFormular({
            rai: cal_rai,
            ngan: cal_ngan,
            wa: cal_wa
        })
    }
    return(
        <>
        <Navbars />
        <div className="service-container">
            <div className="calculator-container mb-50">
                <h3>โปรแกรมรวมเนื้อที่</h3>
                <div className="container mb-50">
                    {formular.map((item,i) =>{
                    return(
                    <Row className="row-cols-3" key={i}>
                        <Col className="mb-10">
                            <FormGroup className="form-group">
                                <Label>ไร่</Label>
                                <Input 
                                className="bg_white"  
                                placeholder="0"
                                value={item.rai}
                                onChange={(val) => onChangeFormular(val.target.value, 'rai', i)}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="mb-10">
                            <FormGroup className="form-group">
                                <Label>งาน</Label>
                                <Input 
                                className="bg_white" 
                                placeholder="0"
                                value={item.ngan}
                                onChange={(val) => onChangeFormular(val.target.value, 'ngan', i)}
                                />
                            </FormGroup>        
                        </Col>
                        <Col className="mb-10">
                            <FormGroup className="form-group">
                                <Label>ตารางวา</Label>
                                <Input 
                                className="bg_white" 
                                placeholder="0"
                                value={item.wa}
                                onChange={(val) => onChangeFormular(val.target.value, 'wa', i)}
                                />
                            </FormGroup>        
                        </Col>
                    </Row>
                    )
                    })}
                    <div className="addrows mb-10">
                        <Button 
                        color="default" 
                        className="btn-sm mr-10"
                        onClick={calculator}
                        >
                            <FontAwesomeIcon icon={faCalculator} className="mr-10" />
                            คำนวณ
                        </Button>
                        <Button 
                        color="sky" 
                        className="btn-sm"
                        onClick={addRows}
                        >
                            <FontAwesomeIcon icon={faPlus} className="mr-10" />
                            เพิ่มแถว
                        </Button>
                    </div>
                    <p>ผลลัพธ์</p>
                    <Row className="row-cols-3">
                        <Col className="mb-10">
                            <FormGroup className="form-group">
                                <Label>ไร่</Label>
                                <Input 
                                className="bg_white" 
                                readOnly 
                                value={sumFormular.rai}
                                placeholder="0"
                                />
                            </FormGroup>
                        </Col>
                        <Col className="mb-10">
                            <FormGroup className="form-group">
                                <Label>งาน</Label>
                                <Input 
                                className="bg_white" 
                                readOnly
                                value={sumFormular.ngan}
                                placeholder="0"
                                />
                            </FormGroup>        
                        </Col>
                        <Col className="mb-10">
                            <FormGroup className="form-group">
                                <Label>ตารางวา</Label>
                                <Input 
                                className="bg_white" 
                                readOnly
                                value={sumFormular.wa}
                                placeholder="0"
                                />
                            </FormGroup>        
                        </Col>
                    </Row>
                </div>
                <h3>เว็บไซต์และระบบที่เป็นประโยชน์</h3>
                <div className="mb-50" style={{padding:5}}>
                    <div 
                    style={{
                        padding: 30,
                        borderRadius: 10,
                        textAlign:'center',
                        backgroundColor: '#001730',
                        color:'white',
                        cursor:'pointer',
                        fontSize: '2rem'
                    }}
                    className="mb-20"
                    onClick={() => window.open('https://landsmaps.dol.go.th/')}
                    >
                        ระบบค้นหารูปแปลงที่ดิน
                    </div>
                    <div 
                    style={{
                        padding: 30,
                        borderRadius: 10,
                        textAlign:'center',
                        backgroundColor: '#294B9D',
                        color:'white',
                        cursor:'pointer',
                        fontSize: '2rem'
                    }}
                    className="mb-20"
                    onClick={() => window.open('http://assessprice.treasury.go.th/')}
                    >
                        ตรวจสอบราคาประเมิน กรมธนารักษ์
                    </div>
                    <div 
                    style={{
                        padding: 30,
                        borderRadius: 10,
                        textAlign:'center',
                        backgroundColor: '#28a745',
                        color:'white',
                        cursor:'pointer',
                        fontSize: '2rem'
                    }}
                    className="mb-20"
                    onClick={() => window.open('https://www.dol.go.th/Pages/ตัวอย่างหนังสือมอบอำนาจ.aspx')}
                    >
                        แบบฟอร์ม และตัวอย่าง หนังสือมอบอำนาจ กรมที่ดิน
                    </div>
                    <div 
                    style={{
                        padding: 30,
                        borderRadius: 10,
                        textAlign:'center',
                        backgroundColor: 'rgb(0,158,227)',
                        color:'white',
                        cursor:'pointer',
                        fontSize: '2rem'
                    }}
                    className="mb-20"
                    onClick={() => window.open('http://www.landusephuket.com/')}
                    >
                        ตรวจสอบการใช้ประโยชน์ที่ดิน จังหวัดภูเก็ต
                    </div>
                    <div 
                    style={{
                        padding: 30,
                        borderRadius: 10,
                        textAlign:'center',
                        backgroundColor: '#90d441',
                        color:'white',
                        cursor:'pointer',
                        fontSize: '2rem'
                    }}
                    className="mb-20"
                    onClick={() => window.open('https://lecs.dol.go.th/rcal/#/')}
                    >
                        คำนวณค่าธรรมเนียมโอนที่ดิน และภาษี เบื้องต้น
                    </div>
                    <div 
                    style={{
                        padding: 30,
                        borderRadius: 10,
                        textAlign:'center',
                        backgroundColor: '#076946',
                        color:'white',
                        cursor:'pointer',
                        fontSize: '2rem'
                    }}
                    className="mb-20"
                    onClick={() => window.open('https://regsurvey.dol.go.th/surveydata/index.php')}
                    >
                        สืบค้น สำนักงาน/ช่าง รังวัดเอกชน
                    </div>
                    <div 
                    style={{
                        padding: 30,
                        borderRadius: 10,
                        textAlign:'center',
                        backgroundColor: '#F39C12',
                        color:'white',
                        cursor:'pointer',
                        fontSize: '2rem'
                    }}
                    className="mb-20"
                    onClick={() => window.open('https://www.dol.go.th/lo/Documents/คู่มือบริการประชาชน/o.pdf')}
                    >
                        คู่มือการใช้บริการช่างรังวัดเอกชน
                    </div>
                    <div 
                    style={{
                        padding: 30,
                        borderRadius: 10,
                        textAlign:'center',
                        backgroundColor: '#886ab5',
                        color:'white',
                        cursor:'pointer',
                        fontSize: '2rem'
                    }}
                    className="mb-20"
                    onClick={() => window.open('https://plludds.dpt.go.th')}
                    >
                        ระบบตรวจสอบการใช้ประโยชน์ที่ดินตามผังเมือง
                    </div>
                    <div 
                    style={{
                        padding: 30,
                        borderRadius: 10,
                        textAlign:'center',
                        backgroundColor: '#07DCD8',
                        color:'white',
                        cursor:'pointer',
                        fontSize: '2rem'
                    }}
                    className="mb-20"
                    onClick={() => window.open('https://www.dol.go.th/samuthsakorn/DocLib6/สรุปย่อกฎหมายจัดสรร.pdf')}
                    >
                        สรุปย่อกฎหมายจัดสรร
                    </div>
                    <div 
                    style={{
                        padding: 30,
                        borderRadius: 10,
                        textAlign:'center',
                        backgroundColor: '#0EA20C',
                        color:'white',
                        cursor:'pointer',
                        fontSize: '2rem'
                    }}
                    className="mb-20"
                    onClick={() => window.open('https://spapp.dol.go.th/ebook_training/ebook_print/knowing_teedin65/mobile/index.html')}
                    >
                        รอบรู้เรื่องที่ดิน
                    </div>
                    <div 
                    style={{
                        padding: 30,
                        borderRadius: 10,
                        textAlign:'center',
                        backgroundColor: '#AF7AC5',
                        color:'white',
                        cursor:'pointer',
                        fontSize: '2rem'
                    }}
                    className="mb-20"
                    onClick={() => window.open('https://thaivaluer.com/')}
                    >
                        ตรวจสอบแนวเวนคืนที่ดิน
                    </div>
                </div>
                <h3>Application ที่เป็นประโยชน์</h3>
                <div className="platform-container mb-50">
                    <div className="platform-wrapper mb-10">
                        <div className="title">
                            <p style={{
                                padding: 10, 
                                backgroundColor: 'white',
                                borderRadius: 10,
                                fontWeight: 500,
                                fontSize: 20
                            }}
                            >
                                SmartLand
                            </p>
                        </div>
                        <div className="text-wrapper" style={{backgroundColor:'#27AE60'}}>
                            <div 
                            className="ios"
                            onClick={() => window.open('https://apps.apple.com/th/app/smartlands/id1528456941?l=th')}
                            >
                                <FontAwesomeIcon icon={faApple} size="2x" className="mr-20" />
                                IOS
                            </div>
                            <div 
                            className="android"
                            onClick={() => window.open('https://play.google.com/store/apps/details?id=th.go.dol.smartlands&hl=th&gl=US&pli=1')}
                            >
                                <FontAwesomeIcon icon={faAndroid} size="2x" className="mr-20" />
                                Android
                            </div>
                        </div>
                    </div>
                    <div className="platform-wrapper mb-10">
                        <div className="title">
                            <p style={{
                                padding: 10, 
                                backgroundColor: 'white',
                                borderRadius: 10,
                                fontWeight: 500,
                                fontSize: 20
                            }}
                            >
                                e-Qland
                            </p>
                        </div>
                        <div className="text-wrapper" style={{backgroundColor:'#F39C12'}}>
                            <div 
                            className="ios"
                            onClick={() => window.open('https://apps.apple.com/th/app/e-qlands/id1534272999?l=th')}
                            >
                                <FontAwesomeIcon icon={faApple} size="2x" className="mr-20" />
                                IOS
                            </div>
                            <div 
                            className="android"
                            onClick={() => window.open('https://play.google.com/store/apps/details?id=com.dol.eqland&hl=th&gl=US')}
                            >
                                <FontAwesomeIcon icon={faAndroid} size="2x" className="mr-20" />
                                Android
                            </div>
                        </div>
                    </div>
                    <div className="platform-wrapper mb-10">
                        <div className="title">
                            <p style={{
                                padding: 10, 
                                backgroundColor: 'white',
                                borderRadius: 10,
                                fontWeight: 500,
                                fontSize: 20
                            }}
                            >
                                Forest4Thai
                            </p>
                        </div>
                        <div className="text-wrapper" style={{backgroundColor:'#0B5345'}}>
                            <div 
                            className="ios"
                            onClick={() => window.open('https://apps.apple.com/th/app/forest4thai/id1070121529')}
                            >
                                <FontAwesomeIcon icon={faApple} size="2x" className="mr-20" />
                                IOS
                            </div>
                            <div 
                            className="android"
                            onClick={() => window.open('https://play.google.com/store/apps/details?id=th.go.Forest4Thai&hl=th&gl=US')}
                            >
                                <FontAwesomeIcon icon={faAndroid} size="2x" className="mr-20" />
                                Android
                            </div>
                        </div>
                    </div>
                    <div className="platform-wrapper mb-10">
                        <div className="title">
                            <p style={{
                                padding: 10, 
                                backgroundColor: 'white',
                                borderRadius: 10,
                                fontWeight: 500,
                                fontSize: 20
                            }}
                            >
                                ยอดฮวงจุ้ย
                            </p>
                        </div>
                        <div className="text-wrapper" style={{backgroundColor:'#CB4335'}}>
                            <div 
                            className="ios"
                            onClick={() => window.open('https://apps.apple.com/th/app/%E0%B8%A2%E0%B8%AD%E0%B8%94%E0%B8%AE%E0%B8%A7%E0%B8%87%E0%B8%88-%E0%B8%A2/id1229770579')}
                            >
                                <FontAwesomeIcon icon={faApple} size="2x" className="mr-20" />
                                IOS
                            </div>
                            <div 
                            className="android"
                            onClick={() => window.open('https://play.google.com/store/apps/details?id=com.homeheng.topfengshui&pcampaignid=web_share')}
                            >
                                <FontAwesomeIcon icon={faAndroid} size="2x" className="mr-20" />
                                Android
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}
export default Calculator