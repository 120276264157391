export const DISTRICT_LIST = [
  {
      "amphur_id": "1",
      "amphur_code": "1001",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e1e\u0e23\u0e30\u0e19\u0e04\u0e23",
      "amphur_name_eng": "Khet Phra Nakhon",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "2",
      "amphur_code": "1002",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e14\u0e38\u0e2a\u0e34\u0e15",
      "amphur_name_eng": "Khet Dusit",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "3",
      "amphur_code": "1003",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e2b\u0e19\u0e2d\u0e07\u0e08\u0e2d\u0e01",
      "amphur_name_eng": "Khet  Nong Chok",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "4",
      "amphur_code": "1004",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e1a\u0e32\u0e07\u0e23\u0e31\u0e01",
      "amphur_name_eng": "Khet Bang Rak",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "5",
      "amphur_code": "1005",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e1a\u0e32\u0e07\u0e40\u0e02\u0e19",
      "amphur_name_eng": "Khet Bang Khen",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "6",
      "amphur_code": "1006",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e1a\u0e32\u0e07\u0e01\u0e30\u0e1b\u0e34",
      "amphur_name_eng": "Khet Bang Kapi",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "7",
      "amphur_code": "1007",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e1b\u0e17\u0e38\u0e21\u0e27\u0e31\u0e19",
      "amphur_name_eng": "Khet Pathum Wan",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "8",
      "amphur_code": "1008",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e1b\u0e49\u0e2d\u0e21\u0e1b\u0e23\u0e32\u0e1a\u0e28\u0e31\u0e15\u0e23\u0e39\u0e1e\u0e48\u0e32\u0e22",
      "amphur_name_eng": "Khet Pom Prap Sattru Phai",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "9",
      "amphur_code": "1009",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e1e\u0e23\u0e30\u0e42\u0e02\u0e19\u0e07",
      "amphur_name_eng": "Khet Phra Khanong",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "10",
      "amphur_code": "1010",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e21\u0e35\u0e19\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Khet Min Buri",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "11",
      "amphur_code": "1011",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e25\u0e32\u0e14\u0e01\u0e23\u0e30\u0e1a\u0e31\u0e07",
      "amphur_name_eng": "Khet Lat Krabang",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "12",
      "amphur_code": "1012",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e22\u0e32\u0e19\u0e19\u0e32\u0e27\u0e32",
      "amphur_name_eng": "Khet Yan Nawa",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "13",
      "amphur_code": "1013",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e2a\u0e31\u0e21\u0e1e\u0e31\u0e19\u0e18\u0e27\u0e07\u0e28\u0e4c",
      "amphur_name_eng": "Khet Samphanthawong",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "14",
      "amphur_code": "1014",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e1e\u0e0d\u0e32\u0e44\u0e17",
      "amphur_name_eng": "Khet Phaya Thai",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "15",
      "amphur_code": "1015",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e18\u0e19\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Khet Thon Buri",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "16",
      "amphur_code": "1016",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e1a\u0e32\u0e07\u0e01\u0e2d\u0e01\u0e43\u0e2b\u0e0d\u0e48",
      "amphur_name_eng": "Khet Bangkok Yai",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "17",
      "amphur_code": "1017",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e2b\u0e49\u0e27\u0e22\u0e02\u0e27\u0e32\u0e07",
      "amphur_name_eng": "Khet Huai Khwang",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "18",
      "amphur_code": "1018",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e04\u0e25\u0e2d\u0e07\u0e2a\u0e32\u0e19",
      "amphur_name_eng": "Khet Khlong San",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "19",
      "amphur_code": "1019",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e15\u0e25\u0e34\u0e48\u0e07\u0e0a\u0e31\u0e19",
      "amphur_name_eng": "Khet Taling Chan",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "20",
      "amphur_code": "1020",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e1a\u0e32\u0e07\u0e01\u0e2d\u0e01\u0e19\u0e49\u0e2d\u0e22",
      "amphur_name_eng": "Khet Bangkok Noi",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "21",
      "amphur_code": "1021",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e1a\u0e32\u0e07\u0e02\u0e38\u0e19\u0e40\u0e17\u0e35\u0e22\u0e19",
      "amphur_name_eng": "Khet Bang Khun Thian",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "22",
      "amphur_code": "1022",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e20\u0e32\u0e29\u0e35\u0e40\u0e08\u0e23\u0e34\u0e0d",
      "amphur_name_eng": "Khet Phasi Charoen",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "23",
      "amphur_code": "1023",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e2b\u0e19\u0e2d\u0e07\u0e41\u0e02\u0e21",
      "amphur_name_eng": "Khet Nong Khaem",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "24",
      "amphur_code": "1024",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e23\u0e32\u0e29\u0e0e\u0e23\u0e4c\u0e1a\u0e39\u0e23\u0e13\u0e30",
      "amphur_name_eng": "Khet Rat Burana",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "25",
      "amphur_code": "1025",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e1a\u0e32\u0e07\u0e1e\u0e25\u0e31\u0e14",
      "amphur_name_eng": "Khet Bang Phlat",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "26",
      "amphur_code": "1026",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e14\u0e34\u0e19\u0e41\u0e14\u0e07",
      "amphur_name_eng": "Khet Din Daeng",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "27",
      "amphur_code": "1027",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e1a\u0e36\u0e07\u0e01\u0e38\u0e48\u0e21",
      "amphur_name_eng": "Khet Bueng Kum",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "28",
      "amphur_code": "1028",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e2a\u0e32\u0e17\u0e23",
      "amphur_name_eng": "Khet Sathon",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "29",
      "amphur_code": "1029",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e1a\u0e32\u0e07\u0e0b\u0e37\u0e48\u0e2d",
      "amphur_name_eng": "Khet Bang Sue",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "30",
      "amphur_code": "1030",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e08\u0e15\u0e38\u0e08\u0e31\u0e01\u0e23",
      "amphur_name_eng": "Khet Chatuchak",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "31",
      "amphur_code": "1031",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e1a\u0e32\u0e07\u0e04\u0e2d\u0e41\u0e2b\u0e25\u0e21",
      "amphur_name_eng": "Khet Bang Kho Laem",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "32",
      "amphur_code": "1032",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e1b\u0e23\u0e30\u0e40\u0e27\u0e28",
      "amphur_name_eng": "Khet Prawet",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "33",
      "amphur_code": "1033",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e04\u0e25\u0e2d\u0e07\u0e40\u0e15\u0e22",
      "amphur_name_eng": "Khet Khlong Toei",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "34",
      "amphur_code": "1034",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e2a\u0e27\u0e19\u0e2b\u0e25\u0e27\u0e07",
      "amphur_name_eng": "Khet Suan Luang",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "35",
      "amphur_code": "1035",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e08\u0e2d\u0e21\u0e17\u0e2d\u0e07",
      "amphur_name_eng": "Khet Chom Thong",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "36",
      "amphur_code": "1036",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e14\u0e2d\u0e19\u0e40\u0e21\u0e37\u0e2d\u0e07",
      "amphur_name_eng": "Khet Don Mueang",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "37",
      "amphur_code": "1037",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e23\u0e32\u0e0a\u0e40\u0e17\u0e27\u0e35",
      "amphur_name_eng": "Khet Ratchathewi",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "38",
      "amphur_code": "1038",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e25\u0e32\u0e14\u0e1e\u0e23\u0e49\u0e32\u0e27",
      "amphur_name_eng": "Khet Lat Phrao",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "39",
      "amphur_code": "1039",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e27\u0e31\u0e12\u0e19\u0e32",
      "amphur_name_eng": "Khet Watthana",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "40",
      "amphur_code": "1040",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e1a\u0e32\u0e07\u0e41\u0e04",
      "amphur_name_eng": "Khet Bang Khae",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "41",
      "amphur_code": "1041",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e2b\u0e25\u0e31\u0e01\u0e2a\u0e35\u0e48",
      "amphur_name_eng": "Khet Lak Si",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "42",
      "amphur_code": "1042",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e2a\u0e32\u0e22\u0e44\u0e2b\u0e21",
      "amphur_name_eng": "Khet Sai Mai",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "43",
      "amphur_code": "1043",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e04\u0e31\u0e19\u0e19\u0e32\u0e22\u0e32\u0e27",
      "amphur_name_eng": "Khet Khan Na Yao",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "44",
      "amphur_code": "1044",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e2a\u0e30\u0e1e\u0e32\u0e19\u0e2a\u0e39\u0e07",
      "amphur_name_eng": "Khet Saphan Sung",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "45",
      "amphur_code": "1045",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e27\u0e31\u0e07\u0e17\u0e2d\u0e07\u0e2b\u0e25\u0e32\u0e07",
      "amphur_name_eng": "Khet Wang Thonglang",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "46",
      "amphur_code": "1046",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e04\u0e25\u0e2d\u0e07\u0e2a\u0e32\u0e21\u0e27\u0e32",
      "amphur_name_eng": "Khet Khlong Sam Wa",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "47",
      "amphur_code": "1047",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e1a\u0e32\u0e07\u0e19\u0e32",
      "amphur_name_eng": "Khet Bang Na",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "48",
      "amphur_code": "1048",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e17\u0e27\u0e35\u0e27\u0e31\u0e12\u0e19\u0e32",
      "amphur_name_eng": "Khet Thawi Watthana",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "49",
      "amphur_code": "1049",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e17\u0e38\u0e48\u0e07\u0e04\u0e23\u0e38",
      "amphur_name_eng": "Khet Thung Khru",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "50",
      "amphur_code": "1050",
      "amphur_name": "\u0e40\u0e02\u0e15\u0e1a\u0e32\u0e07\u0e1a\u0e2d\u0e19",
      "amphur_name_eng": "Khet Bang Bon",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "51",
      "amphur_code": "1081",
      "amphur_name": "*\u0e1a\u0e49\u0e32\u0e19\u0e17\u0e30\u0e27\u0e32\u0e22",
      "amphur_name_eng": "*Bantawai",
      "geo_id": "2",
      "province_id": "1"
  },
  {
      "amphur_id": "52",
      "amphur_code": "1101",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2a\u0e21\u0e38\u0e17\u0e23\u0e1b\u0e23\u0e32\u0e01\u0e32\u0e23",
      "amphur_name_eng": "Mueang Samut Prakan",
      "geo_id": "2",
      "province_id": "2"
  },
  {
      "amphur_id": "53",
      "amphur_code": "1102",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e1a\u0e48\u0e2d",
      "amphur_name_eng": "Bang Bo",
      "geo_id": "2",
      "province_id": "2"
  },
  {
      "amphur_id": "54",
      "amphur_code": "1103",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e1e\u0e25\u0e35",
      "amphur_name_eng": "Bang Phli",
      "geo_id": "2",
      "province_id": "2"
  },
  {
      "amphur_id": "55",
      "amphur_code": "1104",
      "amphur_name": "\u0e1e\u0e23\u0e30\u0e1b\u0e23\u0e30\u0e41\u0e14\u0e07",
      "amphur_name_eng": "Phra Pradaeng",
      "geo_id": "2",
      "province_id": "2"
  },
  {
      "amphur_id": "56",
      "amphur_code": "1105",
      "amphur_name": "\u0e1e\u0e23\u0e30\u0e2a\u0e21\u0e38\u0e17\u0e23\u0e40\u0e08\u0e14\u0e35\u0e22\u0e4c",
      "amphur_name_eng": "Phra Samut Chedi",
      "geo_id": "2",
      "province_id": "2"
  },
  {
      "amphur_id": "57",
      "amphur_code": "1106",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e40\u0e2a\u0e32\u0e18\u0e07",
      "amphur_name_eng": "Bang Sao Thong",
      "geo_id": "2",
      "province_id": "2"
  },
  {
      "amphur_id": "58",
      "amphur_code": "1201",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e19\u0e19\u0e17\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Mueang Nonthaburi",
      "geo_id": "2",
      "province_id": "3"
  },
  {
      "amphur_id": "59",
      "amphur_code": "1202",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e01\u0e23\u0e27\u0e22",
      "amphur_name_eng": "Bang Kruai",
      "geo_id": "2",
      "province_id": "3"
  },
  {
      "amphur_id": "60",
      "amphur_code": "1203",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e43\u0e2b\u0e0d\u0e48",
      "amphur_name_eng": "Bang Yai",
      "geo_id": "2",
      "province_id": "3"
  },
  {
      "amphur_id": "61",
      "amphur_code": "1204",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e1a\u0e31\u0e27\u0e17\u0e2d\u0e07",
      "amphur_name_eng": "Bang Bua Thong",
      "geo_id": "2",
      "province_id": "3"
  },
  {
      "amphur_id": "62",
      "amphur_code": "1205",
      "amphur_name": "\u0e44\u0e17\u0e23\u0e19\u0e49\u0e2d\u0e22",
      "amphur_name_eng": "Sai Noi",
      "geo_id": "2",
      "province_id": "3"
  },
  {
      "amphur_id": "63",
      "amphur_code": "1206",
      "amphur_name": "\u0e1b\u0e32\u0e01\u0e40\u0e01\u0e23\u0e47\u0e14",
      "amphur_name_eng": "Pak Kret",
      "geo_id": "2",
      "province_id": "3"
  },
  {
      "amphur_id": "64",
      "amphur_code": "1251",
      "amphur_name": "\u0e40\u0e17\u0e28\u0e1a\u0e32\u0e25\u0e19\u0e04\u0e23\u0e19\u0e19\u0e17\u0e1a\u0e38\u0e23\u0e35 (\u0e2a\u0e32\u0e02\u0e32\u0e41\u0e02\u0e27\u0e07\u0e17\u0e48\u0e32\u0e17\u0e23\u0e32\u0e22)*",
      "amphur_name_eng": "Tetsaban Nonthaburi",
      "geo_id": "2",
      "province_id": "3"
  },
  {
      "amphur_id": "65",
      "amphur_code": "1297",
      "amphur_name": "\u0e40\u0e17\u0e28\u0e1a\u0e32\u0e25\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e1b\u0e32\u0e01\u0e40\u0e01\u0e23\u0e47\u0e14*",
      "amphur_name_eng": "Tetsaban Pak Kret",
      "geo_id": "2",
      "province_id": "3"
  },
  {
      "amphur_id": "66",
      "amphur_code": "1301",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e1b\u0e17\u0e38\u0e21\u0e18\u0e32\u0e19\u0e35",
      "amphur_name_eng": "Mueang Pathum Thani",
      "geo_id": "2",
      "province_id": "4"
  },
  {
      "amphur_id": "67",
      "amphur_code": "1302",
      "amphur_name": "\u0e04\u0e25\u0e2d\u0e07\u0e2b\u0e25\u0e27\u0e07",
      "amphur_name_eng": "Khlong Luang",
      "geo_id": "2",
      "province_id": "4"
  },
  {
      "amphur_id": "68",
      "amphur_code": "1303",
      "amphur_name": "\u0e18\u0e31\u0e0d\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Thanyaburi",
      "geo_id": "2",
      "province_id": "4"
  },
  {
      "amphur_id": "69",
      "amphur_code": "1304",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e40\u0e2a\u0e37\u0e2d",
      "amphur_name_eng": "Nong Suea",
      "geo_id": "2",
      "province_id": "4"
  },
  {
      "amphur_id": "70",
      "amphur_code": "1305",
      "amphur_name": "\u0e25\u0e32\u0e14\u0e2b\u0e25\u0e38\u0e21\u0e41\u0e01\u0e49\u0e27",
      "amphur_name_eng": "Lat Lum Kaeo",
      "geo_id": "2",
      "province_id": "4"
  },
  {
      "amphur_id": "71",
      "amphur_code": "1306",
      "amphur_name": "\u0e25\u0e33\u0e25\u0e39\u0e01\u0e01\u0e32",
      "amphur_name_eng": "Lam Luk Ka",
      "geo_id": "2",
      "province_id": "4"
  },
  {
      "amphur_id": "72",
      "amphur_code": "1307",
      "amphur_name": "\u0e2a\u0e32\u0e21\u0e42\u0e04\u0e01",
      "amphur_name_eng": "Sam Khok",
      "geo_id": "2",
      "province_id": "4"
  },
  {
      "amphur_id": "74",
      "amphur_code": "1401",
      "amphur_name": "\u0e1e\u0e23\u0e30\u0e19\u0e04\u0e23\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32",
      "amphur_name_eng": "Phra Nakhon Si Ayutthaya",
      "geo_id": "2",
      "province_id": "5"
  },
  {
      "amphur_id": "75",
      "amphur_code": "1402",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e40\u0e23\u0e37\u0e2d",
      "amphur_name_eng": "Tha Ruea",
      "geo_id": "2",
      "province_id": "5"
  },
  {
      "amphur_id": "76",
      "amphur_code": "1403",
      "amphur_name": "\u0e19\u0e04\u0e23\u0e2b\u0e25\u0e27\u0e07",
      "amphur_name_eng": "Nakhon Luang",
      "geo_id": "2",
      "province_id": "5"
  },
  {
      "amphur_id": "77",
      "amphur_code": "1404",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e44\u0e17\u0e23",
      "amphur_name_eng": "Bang Sai",
      "geo_id": "2",
      "province_id": "5"
  },
  {
      "amphur_id": "78",
      "amphur_code": "1405",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e1a\u0e32\u0e25",
      "amphur_name_eng": "Bang Ban",
      "geo_id": "2",
      "province_id": "5"
  },
  {
      "amphur_id": "79",
      "amphur_code": "1406",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e1b\u0e30\u0e2d\u0e34\u0e19",
      "amphur_name_eng": "Bang Pa-in",
      "geo_id": "2",
      "province_id": "5"
  },
  {
      "amphur_id": "80",
      "amphur_code": "1407",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e1b\u0e30\u0e2b\u0e31\u0e19",
      "amphur_name_eng": "Bang Pahan",
      "geo_id": "2",
      "province_id": "5"
  },
  {
      "amphur_id": "81",
      "amphur_code": "1408",
      "amphur_name": "\u0e1c\u0e31\u0e01\u0e44\u0e2b\u0e48",
      "amphur_name_eng": "Phak Hai",
      "geo_id": "2",
      "province_id": "5"
  },
  {
      "amphur_id": "82",
      "amphur_code": "1409",
      "amphur_name": "\u0e20\u0e32\u0e0a\u0e35",
      "amphur_name_eng": "Phachi",
      "geo_id": "2",
      "province_id": "5"
  },
  {
      "amphur_id": "83",
      "amphur_code": "1410",
      "amphur_name": "\u0e25\u0e32\u0e14\u0e1a\u0e31\u0e27\u0e2b\u0e25\u0e27\u0e07",
      "amphur_name_eng": "Lat Bua Luang",
      "geo_id": "2",
      "province_id": "5"
  },
  {
      "amphur_id": "84",
      "amphur_code": "1411",
      "amphur_name": "\u0e27\u0e31\u0e07\u0e19\u0e49\u0e2d\u0e22",
      "amphur_name_eng": "Wang Noi",
      "geo_id": "2",
      "province_id": "5"
  },
  {
      "amphur_id": "85",
      "amphur_code": "1412",
      "amphur_name": "\u0e40\u0e2a\u0e19\u0e32",
      "amphur_name_eng": "Sena",
      "geo_id": "2",
      "province_id": "5"
  },
  {
      "amphur_id": "86",
      "amphur_code": "1413",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e0b\u0e49\u0e32\u0e22",
      "amphur_name_eng": "Bang Sai",
      "geo_id": "2",
      "province_id": "5"
  },
  {
      "amphur_id": "87",
      "amphur_code": "1414",
      "amphur_name": "\u0e2d\u0e38\u0e17\u0e31\u0e22",
      "amphur_name_eng": "Uthai",
      "geo_id": "2",
      "province_id": "5"
  },
  {
      "amphur_id": "88",
      "amphur_code": "1415",
      "amphur_name": "\u0e21\u0e2b\u0e32\u0e23\u0e32\u0e0a",
      "amphur_name_eng": "Maha Rat",
      "geo_id": "2",
      "province_id": "5"
  },
  {
      "amphur_id": "89",
      "amphur_code": "1416",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e41\u0e1e\u0e23\u0e01",
      "amphur_name_eng": "Ban Phraek",
      "geo_id": "2",
      "province_id": "5"
  },
  {
      "amphur_id": "90",
      "amphur_code": "1501",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2d\u0e48\u0e32\u0e07\u0e17\u0e2d\u0e07",
      "amphur_name_eng": "Mueang Ang Thong",
      "geo_id": "2",
      "province_id": "6"
  },
  {
      "amphur_id": "91",
      "amphur_code": "1502",
      "amphur_name": "\u0e44\u0e0a\u0e42\u0e22",
      "amphur_name_eng": "Chaiyo",
      "geo_id": "2",
      "province_id": "6"
  },
  {
      "amphur_id": "92",
      "amphur_code": "1503",
      "amphur_name": "\u0e1b\u0e48\u0e32\u0e42\u0e21\u0e01",
      "amphur_name_eng": "Pa Mok",
      "geo_id": "2",
      "province_id": "6"
  },
  {
      "amphur_id": "93",
      "amphur_code": "1504",
      "amphur_name": "\u0e42\u0e1e\u0e18\u0e34\u0e4c\u0e17\u0e2d\u0e07",
      "amphur_name_eng": "Pho Thong",
      "geo_id": "2",
      "province_id": "6"
  },
  {
      "amphur_id": "94",
      "amphur_code": "1505",
      "amphur_name": "\u0e41\u0e2a\u0e27\u0e07\u0e2b\u0e32",
      "amphur_name_eng": "Sawaeng Ha",
      "geo_id": "2",
      "province_id": "6"
  },
  {
      "amphur_id": "95",
      "amphur_code": "1506",
      "amphur_name": "\u0e27\u0e34\u0e40\u0e28\u0e29\u0e0a\u0e31\u0e22\u0e0a\u0e32\u0e0d",
      "amphur_name_eng": "Wiset Chai Chan",
      "geo_id": "2",
      "province_id": "6"
  },
  {
      "amphur_id": "96",
      "amphur_code": "1507",
      "amphur_name": "\u0e2a\u0e32\u0e21\u0e42\u0e01\u0e49",
      "amphur_name_eng": "Samko",
      "geo_id": "2",
      "province_id": "6"
  },
  {
      "amphur_id": "97",
      "amphur_code": "1601",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e25\u0e1e\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Mueang Lop Buri",
      "geo_id": "2",
      "province_id": "7"
  },
  {
      "amphur_id": "98",
      "amphur_code": "1602",
      "amphur_name": "\u0e1e\u0e31\u0e12\u0e19\u0e32\u0e19\u0e34\u0e04\u0e21",
      "amphur_name_eng": "Phatthana Nikhom",
      "geo_id": "2",
      "province_id": "7"
  },
  {
      "amphur_id": "99",
      "amphur_code": "1603",
      "amphur_name": "\u0e42\u0e04\u0e01\u0e2a\u0e33\u0e42\u0e23\u0e07",
      "amphur_name_eng": "Khok Samrong",
      "geo_id": "2",
      "province_id": "7"
  },
  {
      "amphur_id": "100",
      "amphur_code": "1604",
      "amphur_name": "\u0e0a\u0e31\u0e22\u0e1a\u0e32\u0e14\u0e32\u0e25",
      "amphur_name_eng": "Chai Badan",
      "geo_id": "2",
      "province_id": "7"
  },
  {
      "amphur_id": "101",
      "amphur_code": "1605",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e27\u0e38\u0e49\u0e07",
      "amphur_name_eng": "Tha Wung",
      "geo_id": "2",
      "province_id": "7"
  },
  {
      "amphur_id": "102",
      "amphur_code": "1606",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e2b\u0e21\u0e35\u0e48",
      "amphur_name_eng": "Ban Mi",
      "geo_id": "2",
      "province_id": "7"
  },
  {
      "amphur_id": "103",
      "amphur_code": "1607",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e2b\u0e25\u0e27\u0e07",
      "amphur_name_eng": "Tha Luang",
      "geo_id": "2",
      "province_id": "7"
  },
  {
      "amphur_id": "104",
      "amphur_code": "1608",
      "amphur_name": "\u0e2a\u0e23\u0e30\u0e42\u0e1a\u0e2a\u0e16\u0e4c",
      "amphur_name_eng": "Sa Bot",
      "geo_id": "2",
      "province_id": "7"
  },
  {
      "amphur_id": "105",
      "amphur_code": "1609",
      "amphur_name": "\u0e42\u0e04\u0e01\u0e40\u0e08\u0e23\u0e34\u0e0d",
      "amphur_name_eng": "Khok Charoen",
      "geo_id": "2",
      "province_id": "7"
  },
  {
      "amphur_id": "106",
      "amphur_code": "1610",
      "amphur_name": "\u0e25\u0e33\u0e2a\u0e19\u0e18\u0e34",
      "amphur_name_eng": "Lam Sonthi",
      "geo_id": "2",
      "province_id": "7"
  },
  {
      "amphur_id": "107",
      "amphur_code": "1611",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e21\u0e48\u0e27\u0e07",
      "amphur_name_eng": "Nong Muang",
      "geo_id": "2",
      "province_id": "7"
  },
  {
      "amphur_id": "108",
      "amphur_code": "1681",
      "amphur_name": "*\u0e2d.\u0e1a\u0e49\u0e32\u0e19\u0e40\u0e0a\u0e48\u0e32  \u0e08.\u0e25\u0e1e\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "*Amphoe Ban Chao",
      "geo_id": "2",
      "province_id": "7"
  },
  {
      "amphur_id": "109",
      "amphur_code": "1701",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2a\u0e34\u0e07\u0e2b\u0e4c\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Mueang Sing Buri",
      "geo_id": "2",
      "province_id": "8"
  },
  {
      "amphur_id": "110",
      "amphur_code": "1702",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e23\u0e30\u0e08\u0e31\u0e19",
      "amphur_name_eng": "Bang Rachan",
      "geo_id": "2",
      "province_id": "8"
  },
  {
      "amphur_id": "111",
      "amphur_code": "1703",
      "amphur_name": "\u0e04\u0e48\u0e32\u0e22\u0e1a\u0e32\u0e07\u0e23\u0e30\u0e08\u0e31\u0e19",
      "amphur_name_eng": "Khai Bang Rachan",
      "geo_id": "2",
      "province_id": "8"
  },
  {
      "amphur_id": "112",
      "amphur_code": "1704",
      "amphur_name": "\u0e1e\u0e23\u0e2b\u0e21\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Phrom Buri",
      "geo_id": "2",
      "province_id": "8"
  },
  {
      "amphur_id": "113",
      "amphur_code": "1705",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e0a\u0e49\u0e32\u0e07",
      "amphur_name_eng": "Tha Chang",
      "geo_id": "2",
      "province_id": "8"
  },
  {
      "amphur_id": "114",
      "amphur_code": "1706",
      "amphur_name": "\u0e2d\u0e34\u0e19\u0e17\u0e23\u0e4c\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "In Buri",
      "geo_id": "2",
      "province_id": "8"
  },
  {
      "amphur_id": "115",
      "amphur_code": "1801",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e0a\u0e31\u0e22\u0e19\u0e32\u0e17",
      "amphur_name_eng": "Mueang Chai Nat",
      "geo_id": "2",
      "province_id": "9"
  },
  {
      "amphur_id": "116",
      "amphur_code": "1802",
      "amphur_name": "\u0e21\u0e42\u0e19\u0e23\u0e21\u0e22\u0e4c",
      "amphur_name_eng": "Manorom",
      "geo_id": "2",
      "province_id": "9"
  },
  {
      "amphur_id": "117",
      "amphur_code": "1803",
      "amphur_name": "\u0e27\u0e31\u0e14\u0e2a\u0e34\u0e07\u0e2b\u0e4c",
      "amphur_name_eng": "Wat Sing",
      "geo_id": "2",
      "province_id": "9"
  },
  {
      "amphur_id": "118",
      "amphur_code": "1804",
      "amphur_name": "\u0e2a\u0e23\u0e23\u0e1e\u0e22\u0e32",
      "amphur_name_eng": "Sapphaya",
      "geo_id": "2",
      "province_id": "9"
  },
  {
      "amphur_id": "119",
      "amphur_code": "1805",
      "amphur_name": "\u0e2a\u0e23\u0e23\u0e04\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Sankhaburi",
      "geo_id": "2",
      "province_id": "9"
  },
  {
      "amphur_id": "120",
      "amphur_code": "1806",
      "amphur_name": "\u0e2b\u0e31\u0e19\u0e04\u0e32",
      "amphur_name_eng": "Hankha",
      "geo_id": "2",
      "province_id": "9"
  },
  {
      "amphur_id": "121",
      "amphur_code": "1807",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e21\u0e30\u0e42\u0e21\u0e07",
      "amphur_name_eng": "Nong Mamong",
      "geo_id": "2",
      "province_id": "9"
  },
  {
      "amphur_id": "122",
      "amphur_code": "1808",
      "amphur_name": "\u0e40\u0e19\u0e34\u0e19\u0e02\u0e32\u0e21",
      "amphur_name_eng": "Noen Kham",
      "geo_id": "2",
      "province_id": "9"
  },
  {
      "amphur_id": "123",
      "amphur_code": "1901",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2a\u0e23\u0e30\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Mueang Saraburi",
      "geo_id": "2",
      "province_id": "10"
  },
  {
      "amphur_id": "124",
      "amphur_code": "1902",
      "amphur_name": "\u0e41\u0e01\u0e48\u0e07\u0e04\u0e2d\u0e22",
      "amphur_name_eng": "Kaeng Khoi",
      "geo_id": "2",
      "province_id": "10"
  },
  {
      "amphur_id": "125",
      "amphur_code": "1903",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e41\u0e04",
      "amphur_name_eng": "Nong Khae",
      "geo_id": "2",
      "province_id": "10"
  },
  {
      "amphur_id": "126",
      "amphur_code": "1904",
      "amphur_name": "\u0e27\u0e34\u0e2b\u0e32\u0e23\u0e41\u0e14\u0e07",
      "amphur_name_eng": "Wihan Daeng",
      "geo_id": "2",
      "province_id": "10"
  },
  {
      "amphur_id": "127",
      "amphur_code": "1905",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e41\u0e0b\u0e07",
      "amphur_name_eng": "Nong Saeng",
      "geo_id": "2",
      "province_id": "10"
  },
  {
      "amphur_id": "128",
      "amphur_code": "1906",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e2b\u0e21\u0e2d",
      "amphur_name_eng": "Ban Mo",
      "geo_id": "2",
      "province_id": "10"
  },
  {
      "amphur_id": "129",
      "amphur_code": "1907",
      "amphur_name": "\u0e14\u0e2d\u0e19\u0e1e\u0e38\u0e14",
      "amphur_name_eng": "Don Phut",
      "geo_id": "2",
      "province_id": "10"
  },
  {
      "amphur_id": "130",
      "amphur_code": "1908",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e42\u0e14\u0e19",
      "amphur_name_eng": "Nong Don",
      "geo_id": "2",
      "province_id": "10"
  },
  {
      "amphur_id": "131",
      "amphur_code": "1909",
      "amphur_name": "\u0e1e\u0e23\u0e30\u0e1e\u0e38\u0e17\u0e18\u0e1a\u0e32\u0e17",
      "amphur_name_eng": "Phra Phutthabat",
      "geo_id": "2",
      "province_id": "10"
  },
  {
      "amphur_id": "132",
      "amphur_code": "1910",
      "amphur_name": "\u0e40\u0e2a\u0e32\u0e44\u0e2b\u0e49",
      "amphur_name_eng": "Sao Hai",
      "geo_id": "2",
      "province_id": "10"
  },
  {
      "amphur_id": "133",
      "amphur_code": "1911",
      "amphur_name": "\u0e21\u0e27\u0e01\u0e40\u0e2b\u0e25\u0e47\u0e01",
      "amphur_name_eng": "Muak Lek",
      "geo_id": "2",
      "province_id": "10"
  },
  {
      "amphur_id": "134",
      "amphur_code": "1912",
      "amphur_name": "\u0e27\u0e31\u0e07\u0e21\u0e48\u0e27\u0e07",
      "amphur_name_eng": "Wang Muang",
      "geo_id": "2",
      "province_id": "10"
  },
  {
      "amphur_id": "135",
      "amphur_code": "1913",
      "amphur_name": "\u0e40\u0e09\u0e25\u0e34\u0e21\u0e1e\u0e23\u0e30\u0e40\u0e01\u0e35\u0e22\u0e23\u0e15\u0e34",
      "amphur_name_eng": "Chaloem Phra Kiat",
      "geo_id": "2",
      "province_id": "10"
  },
  {
      "amphur_id": "136",
      "amphur_code": "2001",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e0a\u0e25\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Mueang Chon Buri",
      "geo_id": "5",
      "province_id": "11"
  },
  {
      "amphur_id": "137",
      "amphur_code": "2002",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e1a\u0e36\u0e07",
      "amphur_name_eng": "Ban Bueng",
      "geo_id": "5",
      "province_id": "11"
  },
  {
      "amphur_id": "138",
      "amphur_code": "2003",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e43\u0e2b\u0e0d\u0e48",
      "amphur_name_eng": "Nong Yai",
      "geo_id": "5",
      "province_id": "11"
  },
  {
      "amphur_id": "139",
      "amphur_code": "2004",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e25\u0e30\u0e21\u0e38\u0e07",
      "amphur_name_eng": "Bang Lamung",
      "geo_id": "5",
      "province_id": "11"
  },
  {
      "amphur_id": "140",
      "amphur_code": "2005",
      "amphur_name": "\u0e1e\u0e32\u0e19\u0e17\u0e2d\u0e07",
      "amphur_name_eng": "Phan Thong",
      "geo_id": "5",
      "province_id": "11"
  },
  {
      "amphur_id": "141",
      "amphur_code": "2006",
      "amphur_name": "\u0e1e\u0e19\u0e31\u0e2a\u0e19\u0e34\u0e04\u0e21",
      "amphur_name_eng": "Phanat Nikhom",
      "geo_id": "5",
      "province_id": "11"
  },
  {
      "amphur_id": "142",
      "amphur_code": "2007",
      "amphur_name": "\u0e28\u0e23\u0e35\u0e23\u0e32\u0e0a\u0e32",
      "amphur_name_eng": "Si Racha",
      "geo_id": "5",
      "province_id": "11"
  },
  {
      "amphur_id": "143",
      "amphur_code": "2008",
      "amphur_name": "\u0e40\u0e01\u0e32\u0e30\u0e2a\u0e35\u0e0a\u0e31\u0e07",
      "amphur_name_eng": "Ko Sichang",
      "geo_id": "5",
      "province_id": "11"
  },
  {
      "amphur_id": "144",
      "amphur_code": "2009",
      "amphur_name": "\u0e2a\u0e31\u0e15\u0e2b\u0e35\u0e1a",
      "amphur_name_eng": "Sattahip",
      "geo_id": "5",
      "province_id": "11"
  },
  {
      "amphur_id": "145",
      "amphur_code": "2010",
      "amphur_name": "\u0e1a\u0e48\u0e2d\u0e17\u0e2d\u0e07",
      "amphur_name_eng": "Bo Thong",
      "geo_id": "5",
      "province_id": "11"
  },
  {
      "amphur_id": "146",
      "amphur_code": "2011",
      "amphur_name": "\u0e40\u0e01\u0e32\u0e30\u0e08\u0e31\u0e19\u0e17\u0e23\u0e4c",
      "amphur_name_eng": "Ko Chan",
      "geo_id": "5",
      "province_id": "11"
  },
  {
      "amphur_id": "147",
      "amphur_code": "2051",
      "amphur_name": "\u0e2a\u0e31\u0e15\u0e2b\u0e35\u0e1a (\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e1a\u0e32\u0e07\u0e40\u0e2a\u0e23\u0e48)*",
      "amphur_name_eng": "Sattahip(Bang Sre)*",
      "geo_id": "5",
      "province_id": "11"
  },
  {
      "amphur_id": "148",
      "amphur_code": "2072",
      "amphur_name": "\u0e17\u0e49\u0e2d\u0e07\u0e16\u0e34\u0e48\u0e19\u0e40\u0e17\u0e28\u0e1a\u0e32\u0e25\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2b\u0e19\u0e2d\u0e07\u0e1b\u0e23\u0e37\u0e2d*",
      "amphur_name_eng": "Tong Tin Tetsaban Mueang Nong Prue*",
      "geo_id": "5",
      "province_id": "11"
  },
  {
      "amphur_id": "149",
      "amphur_code": "2093",
      "amphur_name": "\u0e40\u0e17\u0e28\u0e1a\u0e32\u0e25\u0e15\u0e33\u0e1a\u0e25\u0e41\u0e2b\u0e25\u0e21\u0e09\u0e1a\u0e31\u0e07*",
      "amphur_name_eng": "Tetsaban Tambon Lamsabang*",
      "geo_id": "5",
      "province_id": "11"
  },
  {
      "amphur_id": "150",
      "amphur_code": "2099",
      "amphur_name": "\u0e40\u0e17\u0e28\u0e1a\u0e32\u0e25\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e0a\u0e25\u0e1a\u0e38\u0e23\u0e35*",
      "amphur_name_eng": "Mueang Chon Buri",
      "geo_id": "5",
      "province_id": "11"
  },
  {
      "amphur_id": "151",
      "amphur_code": "2101",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e23\u0e30\u0e22\u0e2d\u0e07",
      "amphur_name_eng": "Mueang Rayong",
      "geo_id": "5",
      "province_id": "12"
  },
  {
      "amphur_id": "152",
      "amphur_code": "2102",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e09\u0e32\u0e07",
      "amphur_name_eng": "Ban Chang",
      "geo_id": "5",
      "province_id": "12"
  },
  {
      "amphur_id": "153",
      "amphur_code": "2103",
      "amphur_name": "\u0e41\u0e01\u0e25\u0e07",
      "amphur_name_eng": "Klaeng",
      "geo_id": "5",
      "province_id": "12"
  },
  {
      "amphur_id": "154",
      "amphur_code": "2104",
      "amphur_name": "\u0e27\u0e31\u0e07\u0e08\u0e31\u0e19\u0e17\u0e23\u0e4c",
      "amphur_name_eng": "Wang Chan",
      "geo_id": "5",
      "province_id": "12"
  },
  {
      "amphur_id": "155",
      "amphur_code": "2105",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e04\u0e48\u0e32\u0e22",
      "amphur_name_eng": "Ban Khai",
      "geo_id": "5",
      "province_id": "12"
  },
  {
      "amphur_id": "156",
      "amphur_code": "2106",
      "amphur_name": "\u0e1b\u0e25\u0e27\u0e01\u0e41\u0e14\u0e07",
      "amphur_name_eng": "Pluak Daeng",
      "geo_id": "5",
      "province_id": "12"
  },
  {
      "amphur_id": "157",
      "amphur_code": "2107",
      "amphur_name": "\u0e40\u0e02\u0e32\u0e0a\u0e30\u0e40\u0e21\u0e32",
      "amphur_name_eng": "Khao Chamao",
      "geo_id": "5",
      "province_id": "12"
  },
  {
      "amphur_id": "158",
      "amphur_code": "2108",
      "amphur_name": "\u0e19\u0e34\u0e04\u0e21\u0e1e\u0e31\u0e12\u0e19\u0e32",
      "amphur_name_eng": "Nikhom Phatthana",
      "geo_id": "5",
      "province_id": "12"
  },
  {
      "amphur_id": "159",
      "amphur_code": "2151",
      "amphur_name": "\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e21\u0e32\u0e1a\u0e02\u0e48\u0e32*",
      "amphur_name_eng": "Saka Tambon Mabka",
      "geo_id": "5",
      "province_id": "12"
  },
  {
      "amphur_id": "160",
      "amphur_code": "2201",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e08\u0e31\u0e19\u0e17\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Mueang Chanthaburi",
      "geo_id": "5",
      "province_id": "13"
  },
  {
      "amphur_id": "161",
      "amphur_code": "2202",
      "amphur_name": "\u0e02\u0e25\u0e38\u0e07",
      "amphur_name_eng": "Khlung",
      "geo_id": "5",
      "province_id": "13"
  },
  {
      "amphur_id": "162",
      "amphur_code": "2203",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e43\u0e2b\u0e21\u0e48",
      "amphur_name_eng": "Tha Mai",
      "geo_id": "5",
      "province_id": "13"
  },
  {
      "amphur_id": "163",
      "amphur_code": "2204",
      "amphur_name": "\u0e42\u0e1b\u0e48\u0e07\u0e19\u0e49\u0e33\u0e23\u0e49\u0e2d\u0e19",
      "amphur_name_eng": "Pong Nam Ron",
      "geo_id": "5",
      "province_id": "13"
  },
  {
      "amphur_id": "164",
      "amphur_code": "2205",
      "amphur_name": "\u0e21\u0e30\u0e02\u0e32\u0e21",
      "amphur_name_eng": "Makham",
      "geo_id": "5",
      "province_id": "13"
  },
  {
      "amphur_id": "165",
      "amphur_code": "2206",
      "amphur_name": "\u0e41\u0e2b\u0e25\u0e21\u0e2a\u0e34\u0e07\u0e2b\u0e4c",
      "amphur_name_eng": "Laem Sing",
      "geo_id": "5",
      "province_id": "13"
  },
  {
      "amphur_id": "166",
      "amphur_code": "2207",
      "amphur_name": "\u0e2a\u0e2d\u0e22\u0e14\u0e32\u0e27",
      "amphur_name_eng": "Soi Dao",
      "geo_id": "5",
      "province_id": "13"
  },
  {
      "amphur_id": "167",
      "amphur_code": "2208",
      "amphur_name": "\u0e41\u0e01\u0e48\u0e07\u0e2b\u0e32\u0e07\u0e41\u0e21\u0e27",
      "amphur_name_eng": "Kaeng Hang Maeo",
      "geo_id": "5",
      "province_id": "13"
  },
  {
      "amphur_id": "168",
      "amphur_code": "2209",
      "amphur_name": "\u0e19\u0e32\u0e22\u0e32\u0e22\u0e2d\u0e32\u0e21",
      "amphur_name_eng": "Na Yai Am",
      "geo_id": "5",
      "province_id": "13"
  },
  {
      "amphur_id": "169",
      "amphur_code": "2210",
      "amphur_name": "\u0e40\u0e02\u0e32\u0e04\u0e34\u0e0a\u0e0c\u0e01\u0e39\u0e0f",
      "amphur_name_eng": "Khoa Khitchakut",
      "geo_id": "5",
      "province_id": "13"
  },
  {
      "amphur_id": "170",
      "amphur_code": "2281",
      "amphur_name": "*\u0e01\u0e34\u0e48\u0e07 \u0e2d.\u0e01\u0e33\u0e1e\u0e38\u0e18  \u0e08.\u0e08\u0e31\u0e19\u0e17\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "*King Amphoe Kampud",
      "geo_id": "5",
      "province_id": "13"
  },
  {
      "amphur_id": "171",
      "amphur_code": "2301",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e15\u0e23\u0e32\u0e14",
      "amphur_name_eng": "Mueang Trat",
      "geo_id": "5",
      "province_id": "14"
  },
  {
      "amphur_id": "172",
      "amphur_code": "2302",
      "amphur_name": "\u0e04\u0e25\u0e2d\u0e07\u0e43\u0e2b\u0e0d\u0e48",
      "amphur_name_eng": "Khlong Yai",
      "geo_id": "5",
      "province_id": "14"
  },
  {
      "amphur_id": "173",
      "amphur_code": "2303",
      "amphur_name": "\u0e40\u0e02\u0e32\u0e2a\u0e21\u0e34\u0e07",
      "amphur_name_eng": "Khao Saming",
      "geo_id": "5",
      "province_id": "14"
  },
  {
      "amphur_id": "174",
      "amphur_code": "2304",
      "amphur_name": "\u0e1a\u0e48\u0e2d\u0e44\u0e23\u0e48",
      "amphur_name_eng": "Bo Rai",
      "geo_id": "5",
      "province_id": "14"
  },
  {
      "amphur_id": "175",
      "amphur_code": "2305",
      "amphur_name": "\u0e41\u0e2b\u0e25\u0e21\u0e07\u0e2d\u0e1a",
      "amphur_name_eng": "Laem Ngop",
      "geo_id": "5",
      "province_id": "14"
  },
  {
      "amphur_id": "176",
      "amphur_code": "2306",
      "amphur_name": "\u0e40\u0e01\u0e32\u0e30\u0e01\u0e39\u0e14",
      "amphur_name_eng": "Ko Kut",
      "geo_id": "5",
      "province_id": "14"
  },
  {
      "amphur_id": "177",
      "amphur_code": "2307",
      "amphur_name": "\u0e40\u0e01\u0e32\u0e30\u0e0a\u0e49\u0e32\u0e07",
      "amphur_name_eng": "Ko Chang",
      "geo_id": "5",
      "province_id": "14"
  },
  {
      "amphur_id": "178",
      "amphur_code": "2401",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e09\u0e30\u0e40\u0e0a\u0e34\u0e07\u0e40\u0e17\u0e23\u0e32",
      "amphur_name_eng": "Mueang Chachoengsao",
      "geo_id": "5",
      "province_id": "15"
  },
  {
      "amphur_id": "179",
      "amphur_code": "2402",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e04\u0e25\u0e49\u0e32",
      "amphur_name_eng": "Bang Khla",
      "geo_id": "5",
      "province_id": "15"
  },
  {
      "amphur_id": "180",
      "amphur_code": "2403",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e19\u0e49\u0e33\u0e40\u0e1b\u0e23\u0e35\u0e49\u0e22\u0e27",
      "amphur_name_eng": "Bang Nam Priao",
      "geo_id": "5",
      "province_id": "15"
  },
  {
      "amphur_id": "181",
      "amphur_code": "2404",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e1b\u0e30\u0e01\u0e07",
      "amphur_name_eng": "Bang Pakong",
      "geo_id": "5",
      "province_id": "15"
  },
  {
      "amphur_id": "182",
      "amphur_code": "2405",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e42\u0e1e\u0e18\u0e34\u0e4c",
      "amphur_name_eng": "Ban Pho",
      "geo_id": "5",
      "province_id": "15"
  },
  {
      "amphur_id": "183",
      "amphur_code": "2406",
      "amphur_name": "\u0e1e\u0e19\u0e21\u0e2a\u0e32\u0e23\u0e04\u0e32\u0e21",
      "amphur_name_eng": "Phanom Sarakham",
      "geo_id": "5",
      "province_id": "15"
  },
  {
      "amphur_id": "184",
      "amphur_code": "2407",
      "amphur_name": "\u0e23\u0e32\u0e0a\u0e2a\u0e32\u0e2a\u0e4c\u0e19",
      "amphur_name_eng": "Ratchasan",
      "geo_id": "5",
      "province_id": "15"
  },
  {
      "amphur_id": "185",
      "amphur_code": "2408",
      "amphur_name": "\u0e2a\u0e19\u0e32\u0e21\u0e0a\u0e31\u0e22\u0e40\u0e02\u0e15",
      "amphur_name_eng": "Sanam Chai Khet",
      "geo_id": "5",
      "province_id": "15"
  },
  {
      "amphur_id": "186",
      "amphur_code": "2409",
      "amphur_name": "\u0e41\u0e1b\u0e25\u0e07\u0e22\u0e32\u0e27",
      "amphur_name_eng": "Plaeng Yao",
      "geo_id": "5",
      "province_id": "15"
  },
  {
      "amphur_id": "187",
      "amphur_code": "2410",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e15\u0e30\u0e40\u0e01\u0e35\u0e22\u0e1a",
      "amphur_name_eng": "Tha Takiap",
      "geo_id": "5",
      "province_id": "15"
  },
  {
      "amphur_id": "188",
      "amphur_code": "2411",
      "amphur_name": "\u0e04\u0e25\u0e2d\u0e07\u0e40\u0e02\u0e37\u0e48\u0e2d\u0e19",
      "amphur_name_eng": "Khlong Khuean",
      "geo_id": "5",
      "province_id": "15"
  },
  {
      "amphur_id": "189",
      "amphur_code": "2501",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e1b\u0e23\u0e32\u0e08\u0e35\u0e19\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Mueang Prachin Buri",
      "geo_id": "5",
      "province_id": "16"
  },
  {
      "amphur_id": "190",
      "amphur_code": "2502",
      "amphur_name": "\u0e01\u0e1a\u0e34\u0e19\u0e17\u0e23\u0e4c\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Kabin Buri",
      "geo_id": "5",
      "province_id": "16"
  },
  {
      "amphur_id": "191",
      "amphur_code": "2503",
      "amphur_name": "\u0e19\u0e32\u0e14\u0e35",
      "amphur_name_eng": "Na Di",
      "geo_id": "5",
      "province_id": "16"
  },
  {
      "amphur_id": "192",
      "amphur_code": "2504",
      "amphur_name": "*\u0e2a\u0e23\u0e30\u0e41\u0e01\u0e49\u0e27",
      "amphur_name_eng": "Sa Kaeo",
      "geo_id": "5",
      "province_id": "16"
  },
  {
      "amphur_id": "193",
      "amphur_code": "2505",
      "amphur_name": "*\u0e27\u0e31\u0e07\u0e19\u0e49\u0e33\u0e40\u0e22\u0e47\u0e19",
      "amphur_name_eng": "Wang Nam Yen",
      "geo_id": "5",
      "province_id": "16"
  },
  {
      "amphur_id": "194",
      "amphur_code": "2506",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e2a\u0e23\u0e49\u0e32\u0e07",
      "amphur_name_eng": "Ban Sang",
      "geo_id": "5",
      "province_id": "16"
  },
  {
      "amphur_id": "195",
      "amphur_code": "2507",
      "amphur_name": "\u0e1b\u0e23\u0e30\u0e08\u0e31\u0e19\u0e15\u0e04\u0e32\u0e21",
      "amphur_name_eng": "Prachantakham",
      "geo_id": "5",
      "province_id": "16"
  },
  {
      "amphur_id": "196",
      "amphur_code": "2508",
      "amphur_name": "\u0e28\u0e23\u0e35\u0e21\u0e2b\u0e32\u0e42\u0e1e\u0e18\u0e34",
      "amphur_name_eng": "Si Maha Phot",
      "geo_id": "5",
      "province_id": "16"
  },
  {
      "amphur_id": "197",
      "amphur_code": "2509",
      "amphur_name": "\u0e28\u0e23\u0e35\u0e21\u0e42\u0e2b\u0e2a\u0e16",
      "amphur_name_eng": "Si Mahosot",
      "geo_id": "5",
      "province_id": "16"
  },
  {
      "amphur_id": "198",
      "amphur_code": "2510",
      "amphur_name": "*\u0e2d\u0e23\u0e31\u0e0d\u0e1b\u0e23\u0e30\u0e40\u0e17\u0e28",
      "amphur_name_eng": "Aranyaprathet",
      "geo_id": "5",
      "province_id": "16"
  },
  {
      "amphur_id": "199",
      "amphur_code": "2511",
      "amphur_name": "*\u0e15\u0e32\u0e1e\u0e23\u0e30\u0e22\u0e32",
      "amphur_name_eng": "Ta Phraya",
      "geo_id": "5",
      "province_id": "16"
  },
  {
      "amphur_id": "200",
      "amphur_code": "2512",
      "amphur_name": "*\u0e27\u0e31\u0e12\u0e19\u0e32\u0e19\u0e04\u0e23",
      "amphur_name_eng": "Watthana Nakhon",
      "geo_id": "5",
      "province_id": "16"
  },
  {
      "amphur_id": "201",
      "amphur_code": "2513",
      "amphur_name": "*\u0e04\u0e25\u0e2d\u0e07\u0e2b\u0e32\u0e14",
      "amphur_name_eng": "Khlong Hat",
      "geo_id": "5",
      "province_id": "16"
  },
  {
      "amphur_id": "202",
      "amphur_code": "2601",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e19\u0e04\u0e23\u0e19\u0e32\u0e22\u0e01",
      "amphur_name_eng": "Mueang Nakhon Nayok",
      "geo_id": "2",
      "province_id": "17"
  },
  {
      "amphur_id": "203",
      "amphur_code": "2602",
      "amphur_name": "\u0e1b\u0e32\u0e01\u0e1e\u0e25\u0e35",
      "amphur_name_eng": "Pak Phli",
      "geo_id": "2",
      "province_id": "17"
  },
  {
      "amphur_id": "204",
      "amphur_code": "2603",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e19\u0e32",
      "amphur_name_eng": "Ban Na",
      "geo_id": "2",
      "province_id": "17"
  },
  {
      "amphur_id": "205",
      "amphur_code": "2604",
      "amphur_name": "\u0e2d\u0e07\u0e04\u0e23\u0e31\u0e01\u0e29\u0e4c",
      "amphur_name_eng": "Ongkharak",
      "geo_id": "2",
      "province_id": "17"
  },
  {
      "amphur_id": "206",
      "amphur_code": "2701",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2a\u0e23\u0e30\u0e41\u0e01\u0e49\u0e27",
      "amphur_name_eng": "Mueang Sa Kaeo",
      "geo_id": "5",
      "province_id": "18"
  },
  {
      "amphur_id": "207",
      "amphur_code": "2702",
      "amphur_name": "\u0e04\u0e25\u0e2d\u0e07\u0e2b\u0e32\u0e14",
      "amphur_name_eng": "Khlong Hat",
      "geo_id": "5",
      "province_id": "18"
  },
  {
      "amphur_id": "208",
      "amphur_code": "2703",
      "amphur_name": "\u0e15\u0e32\u0e1e\u0e23\u0e30\u0e22\u0e32",
      "amphur_name_eng": "Ta Phraya",
      "geo_id": "5",
      "province_id": "18"
  },
  {
      "amphur_id": "209",
      "amphur_code": "2704",
      "amphur_name": "\u0e27\u0e31\u0e07\u0e19\u0e49\u0e33\u0e40\u0e22\u0e47\u0e19",
      "amphur_name_eng": "Wang Nam Yen",
      "geo_id": "5",
      "province_id": "18"
  },
  {
      "amphur_id": "210",
      "amphur_code": "2705",
      "amphur_name": "\u0e27\u0e31\u0e12\u0e19\u0e32\u0e19\u0e04\u0e23",
      "amphur_name_eng": "Watthana Nakhon",
      "geo_id": "5",
      "province_id": "18"
  },
  {
      "amphur_id": "211",
      "amphur_code": "2706",
      "amphur_name": "\u0e2d\u0e23\u0e31\u0e0d\u0e1b\u0e23\u0e30\u0e40\u0e17\u0e28",
      "amphur_name_eng": "Aranyaprathet",
      "geo_id": "5",
      "province_id": "18"
  },
  {
      "amphur_id": "212",
      "amphur_code": "2707",
      "amphur_name": "\u0e40\u0e02\u0e32\u0e09\u0e01\u0e23\u0e23\u0e08\u0e4c",
      "amphur_name_eng": "Khao Chakan",
      "geo_id": "5",
      "province_id": "18"
  },
  {
      "amphur_id": "213",
      "amphur_code": "2708",
      "amphur_name": "\u0e42\u0e04\u0e01\u0e2a\u0e39\u0e07",
      "amphur_name_eng": "Khok Sung",
      "geo_id": "5",
      "province_id": "18"
  },
  {
      "amphur_id": "214",
      "amphur_code": "2709",
      "amphur_name": "\u0e27\u0e31\u0e07\u0e2a\u0e21\u0e1a\u0e39\u0e23\u0e13\u0e4c",
      "amphur_name_eng": "Wang Sombun",
      "geo_id": "5",
      "province_id": "18"
  },
  {
      "amphur_id": "215",
      "amphur_code": "3001",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e19\u0e04\u0e23\u0e23\u0e32\u0e0a\u0e2a\u0e35\u0e21\u0e32",
      "amphur_name_eng": "Mueang Nakhon Ratchasima",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "216",
      "amphur_code": "3002",
      "amphur_name": "\u0e04\u0e23\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Khon Buri",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "217",
      "amphur_code": "3003",
      "amphur_name": "\u0e40\u0e2a\u0e34\u0e07\u0e2a\u0e32\u0e07",
      "amphur_name_eng": "Soeng Sang",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "218",
      "amphur_code": "3004",
      "amphur_name": "\u0e04\u0e07",
      "amphur_name_eng": "Khong",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "219",
      "amphur_code": "3005",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e40\u0e2b\u0e25\u0e37\u0e48\u0e2d\u0e21",
      "amphur_name_eng": "Ban Lueam",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "220",
      "amphur_code": "3006",
      "amphur_name": "\u0e08\u0e31\u0e01\u0e23\u0e32\u0e0a",
      "amphur_name_eng": "Chakkarat",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "221",
      "amphur_code": "3007",
      "amphur_name": "\u0e42\u0e0a\u0e04\u0e0a\u0e31\u0e22",
      "amphur_name_eng": "Chok Chai",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "222",
      "amphur_code": "3008",
      "amphur_name": "\u0e14\u0e48\u0e32\u0e19\u0e02\u0e38\u0e19\u0e17\u0e14",
      "amphur_name_eng": "Dan Khun Thot",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "223",
      "amphur_code": "3009",
      "amphur_name": "\u0e42\u0e19\u0e19\u0e44\u0e17\u0e22",
      "amphur_name_eng": "Non Thai",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "224",
      "amphur_code": "3010",
      "amphur_name": "\u0e42\u0e19\u0e19\u0e2a\u0e39\u0e07",
      "amphur_name_eng": "Non Sung",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "225",
      "amphur_code": "3011",
      "amphur_name": "\u0e02\u0e32\u0e21\u0e2a\u0e30\u0e41\u0e01\u0e41\u0e2a\u0e07",
      "amphur_name_eng": "Kham Sakaesaeng",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "226",
      "amphur_code": "3012",
      "amphur_name": "\u0e1a\u0e31\u0e27\u0e43\u0e2b\u0e0d\u0e48",
      "amphur_name_eng": "Bua Yai",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "227",
      "amphur_code": "3013",
      "amphur_name": "\u0e1b\u0e23\u0e30\u0e17\u0e32\u0e22",
      "amphur_name_eng": "Prathai",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "228",
      "amphur_code": "3014",
      "amphur_name": "\u0e1b\u0e31\u0e01\u0e18\u0e07\u0e0a\u0e31\u0e22",
      "amphur_name_eng": "Pak Thong Chai",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "229",
      "amphur_code": "3015",
      "amphur_name": "\u0e1e\u0e34\u0e21\u0e32\u0e22",
      "amphur_name_eng": "Phimai",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "230",
      "amphur_code": "3016",
      "amphur_name": "\u0e2b\u0e49\u0e27\u0e22\u0e41\u0e16\u0e25\u0e07",
      "amphur_name_eng": "Huai Thalaeng",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "231",
      "amphur_code": "3017",
      "amphur_name": "\u0e0a\u0e38\u0e21\u0e1e\u0e27\u0e07",
      "amphur_name_eng": "Chum Phuang",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "232",
      "amphur_code": "3018",
      "amphur_name": "\u0e2a\u0e39\u0e07\u0e40\u0e19\u0e34\u0e19",
      "amphur_name_eng": "Sung Noen",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "233",
      "amphur_code": "3019",
      "amphur_name": "\u0e02\u0e32\u0e21\u0e17\u0e30\u0e40\u0e25\u0e2a\u0e2d",
      "amphur_name_eng": "Kham Thale So",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "234",
      "amphur_code": "3020",
      "amphur_name": "\u0e2a\u0e35\u0e04\u0e34\u0e49\u0e27",
      "amphur_name_eng": "Sikhio",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "235",
      "amphur_code": "3021",
      "amphur_name": "\u0e1b\u0e32\u0e01\u0e0a\u0e48\u0e2d\u0e07",
      "amphur_name_eng": "Pak Chong",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "236",
      "amphur_code": "3022",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e1a\u0e38\u0e0d\u0e21\u0e32\u0e01",
      "amphur_name_eng": "Nong Bunnak",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "237",
      "amphur_code": "3023",
      "amphur_name": "\u0e41\u0e01\u0e49\u0e07\u0e2a\u0e19\u0e32\u0e21\u0e19\u0e32\u0e07",
      "amphur_name_eng": "Kaeng Sanam Nang",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "238",
      "amphur_code": "3024",
      "amphur_name": "\u0e42\u0e19\u0e19\u0e41\u0e14\u0e07",
      "amphur_name_eng": "Non Daeng",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "239",
      "amphur_code": "3025",
      "amphur_name": "\u0e27\u0e31\u0e07\u0e19\u0e49\u0e33\u0e40\u0e02\u0e35\u0e22\u0e27",
      "amphur_name_eng": "Wang Nam Khiao",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "240",
      "amphur_code": "3026",
      "amphur_name": "\u0e40\u0e17\u0e1e\u0e32\u0e23\u0e31\u0e01\u0e29\u0e4c",
      "amphur_name_eng": "Thepharak",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "241",
      "amphur_code": "3027",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e22\u0e32\u0e07",
      "amphur_name_eng": "Mueang Yang",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "242",
      "amphur_code": "3028",
      "amphur_name": "\u0e1e\u0e23\u0e30\u0e17\u0e2d\u0e07\u0e04\u0e33",
      "amphur_name_eng": "Phra Thong Kham",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "243",
      "amphur_code": "3029",
      "amphur_name": "\u0e25\u0e33\u0e17\u0e30\u0e40\u0e21\u0e19\u0e0a\u0e31\u0e22",
      "amphur_name_eng": "Lam Thamenchai",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "244",
      "amphur_code": "3030",
      "amphur_name": "\u0e1a\u0e31\u0e27\u0e25\u0e32\u0e22",
      "amphur_name_eng": "Bua Lai",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "245",
      "amphur_code": "3031",
      "amphur_name": "\u0e2a\u0e35\u0e14\u0e32",
      "amphur_name_eng": "Sida",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "246",
      "amphur_code": "3032",
      "amphur_name": "\u0e40\u0e09\u0e25\u0e34\u0e21\u0e1e\u0e23\u0e30\u0e40\u0e01\u0e35\u0e22\u0e23\u0e15\u0e34",
      "amphur_name_eng": "Chaloem Phra Kiat",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "247",
      "amphur_code": "3049",
      "amphur_name": "\u0e17\u0e49\u0e2d\u0e07\u0e16\u0e34\u0e48\u0e19\u0e40\u0e17\u0e28\u0e1a\u0e32\u0e25\u0e15\u0e33\u0e1a\u0e25\u0e42\u0e1e\u0e18\u0e34\u0e4c\u0e01\u0e25\u0e32\u0e07*",
      "amphur_name_eng": "Pho Krang",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "248",
      "amphur_code": "3051",
      "amphur_name": "\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e21\u0e30\u0e04\u0e48\u0e32-\u0e1e\u0e25\u0e2a\u0e07\u0e04\u0e23\u0e32\u0e21*",
      "amphur_name_eng": "Ma Ka-Pon Songkram*",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "249",
      "amphur_code": "3081",
      "amphur_name": "*\u0e42\u0e19\u0e19\u0e25\u0e32\u0e27",
      "amphur_name_eng": "*Non Lao",
      "geo_id": "3",
      "province_id": "19"
  },
  {
      "amphur_id": "250",
      "amphur_code": "3101",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e1a\u0e38\u0e23\u0e35\u0e23\u0e31\u0e21\u0e22\u0e4c",
      "amphur_name_eng": "Mueang Buri Ram",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "251",
      "amphur_code": "3102",
      "amphur_name": "\u0e04\u0e39\u0e40\u0e21\u0e37\u0e2d\u0e07",
      "amphur_name_eng": "Khu Mueang",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "252",
      "amphur_code": "3103",
      "amphur_name": "\u0e01\u0e23\u0e30\u0e2a\u0e31\u0e07",
      "amphur_name_eng": "Krasang",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "253",
      "amphur_code": "3104",
      "amphur_name": "\u0e19\u0e32\u0e07\u0e23\u0e2d\u0e07",
      "amphur_name_eng": "Nang Rong",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "254",
      "amphur_code": "3105",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e01\u0e35\u0e48",
      "amphur_name_eng": "Nong Ki",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "255",
      "amphur_code": "3106",
      "amphur_name": "\u0e25\u0e30\u0e2b\u0e32\u0e19\u0e17\u0e23\u0e32\u0e22",
      "amphur_name_eng": "Lahan Sai",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "256",
      "amphur_code": "3107",
      "amphur_name": "\u0e1b\u0e23\u0e30\u0e42\u0e04\u0e19\u0e0a\u0e31\u0e22",
      "amphur_name_eng": "Prakhon Chai",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "257",
      "amphur_code": "3108",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e01\u0e23\u0e27\u0e14",
      "amphur_name_eng": "Ban Kruat",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "258",
      "amphur_code": "3109",
      "amphur_name": "\u0e1e\u0e38\u0e17\u0e44\u0e18\u0e2a\u0e07",
      "amphur_name_eng": "Phutthaisong",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "259",
      "amphur_code": "3110",
      "amphur_name": "\u0e25\u0e33\u0e1b\u0e25\u0e32\u0e22\u0e21\u0e32\u0e28",
      "amphur_name_eng": "Lam Plai Mat",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "260",
      "amphur_code": "3111",
      "amphur_name": "\u0e2a\u0e15\u0e36\u0e01",
      "amphur_name_eng": "Satuek",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "261",
      "amphur_code": "3112",
      "amphur_name": "\u0e1b\u0e30\u0e04\u0e33",
      "amphur_name_eng": "Pakham",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "262",
      "amphur_code": "3113",
      "amphur_name": "\u0e19\u0e32\u0e42\u0e1e\u0e18\u0e34\u0e4c",
      "amphur_name_eng": "Na Pho",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "263",
      "amphur_code": "3114",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e2b\u0e07\u0e2a\u0e4c",
      "amphur_name_eng": "Nong Hong",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "264",
      "amphur_code": "3115",
      "amphur_name": "\u0e1e\u0e25\u0e31\u0e1a\u0e1e\u0e25\u0e32\u0e0a\u0e31\u0e22",
      "amphur_name_eng": "Phlapphla Chai",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "265",
      "amphur_code": "3116",
      "amphur_name": "\u0e2b\u0e49\u0e27\u0e22\u0e23\u0e32\u0e0a",
      "amphur_name_eng": "Huai Rat",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "266",
      "amphur_code": "3117",
      "amphur_name": "\u0e42\u0e19\u0e19\u0e2a\u0e38\u0e27\u0e23\u0e23\u0e13",
      "amphur_name_eng": "Non Suwan",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "267",
      "amphur_code": "3118",
      "amphur_name": "\u0e0a\u0e33\u0e19\u0e34",
      "amphur_name_eng": "Chamni",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "268",
      "amphur_code": "3119",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e43\u0e2b\u0e21\u0e48\u0e44\u0e0a\u0e22\u0e1e\u0e08\u0e19\u0e4c",
      "amphur_name_eng": "Ban Mai Chaiyaphot",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "269",
      "amphur_code": "3120",
      "amphur_name": "\u0e42\u0e19\u0e19\u0e14\u0e34\u0e19\u0e41\u0e14\u0e07",
      "amphur_name_eng": "Din Daeng",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "270",
      "amphur_code": "3121",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e14\u0e48\u0e32\u0e19",
      "amphur_name_eng": "Ban Dan",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "271",
      "amphur_code": "3122",
      "amphur_name": "\u0e41\u0e04\u0e19\u0e14\u0e07",
      "amphur_name_eng": "Khaen Dong",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "272",
      "amphur_code": "3123",
      "amphur_name": "\u0e40\u0e09\u0e25\u0e34\u0e21\u0e1e\u0e23\u0e30\u0e40\u0e01\u0e35\u0e22\u0e23\u0e15\u0e34",
      "amphur_name_eng": "Chaloem Phra Kiat",
      "geo_id": "3",
      "province_id": "20"
  },
  {
      "amphur_id": "273",
      "amphur_code": "3201",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2a\u0e38\u0e23\u0e34\u0e19\u0e17\u0e23\u0e4c",
      "amphur_name_eng": "Mueang Surin",
      "geo_id": "3",
      "province_id": "21"
  },
  {
      "amphur_id": "274",
      "amphur_code": "3202",
      "amphur_name": "\u0e0a\u0e38\u0e21\u0e1e\u0e25\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Chumphon Buri",
      "geo_id": "3",
      "province_id": "21"
  },
  {
      "amphur_id": "275",
      "amphur_code": "3203",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e15\u0e39\u0e21",
      "amphur_name_eng": "Tha Tum",
      "geo_id": "3",
      "province_id": "21"
  },
  {
      "amphur_id": "276",
      "amphur_code": "3204",
      "amphur_name": "\u0e08\u0e2d\u0e21\u0e1e\u0e23\u0e30",
      "amphur_name_eng": "Chom Phra",
      "geo_id": "3",
      "province_id": "21"
  },
  {
      "amphur_id": "277",
      "amphur_code": "3205",
      "amphur_name": "\u0e1b\u0e23\u0e32\u0e2a\u0e32\u0e17",
      "amphur_name_eng": "Prasat",
      "geo_id": "3",
      "province_id": "21"
  },
  {
      "amphur_id": "278",
      "amphur_code": "3206",
      "amphur_name": "\u0e01\u0e32\u0e1a\u0e40\u0e0a\u0e34\u0e07",
      "amphur_name_eng": "Kap Choeng",
      "geo_id": "3",
      "province_id": "21"
  },
  {
      "amphur_id": "279",
      "amphur_code": "3207",
      "amphur_name": "\u0e23\u0e31\u0e15\u0e19\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Rattanaburi",
      "geo_id": "3",
      "province_id": "21"
  },
  {
      "amphur_id": "280",
      "amphur_code": "3208",
      "amphur_name": "\u0e2a\u0e19\u0e21",
      "amphur_name_eng": "Sanom",
      "geo_id": "3",
      "province_id": "21"
  },
  {
      "amphur_id": "281",
      "amphur_code": "3209",
      "amphur_name": "\u0e28\u0e35\u0e02\u0e23\u0e20\u0e39\u0e21\u0e34",
      "amphur_name_eng": "Sikhoraphum",
      "geo_id": "3",
      "province_id": "21"
  },
  {
      "amphur_id": "282",
      "amphur_code": "3210",
      "amphur_name": "\u0e2a\u0e31\u0e07\u0e02\u0e30",
      "amphur_name_eng": "Sangkha",
      "geo_id": "3",
      "province_id": "21"
  },
  {
      "amphur_id": "283",
      "amphur_code": "3211",
      "amphur_name": "\u0e25\u0e33\u0e14\u0e27\u0e19",
      "amphur_name_eng": "Lamduan",
      "geo_id": "3",
      "province_id": "21"
  },
  {
      "amphur_id": "284",
      "amphur_code": "3212",
      "amphur_name": "\u0e2a\u0e33\u0e42\u0e23\u0e07\u0e17\u0e32\u0e1a",
      "amphur_name_eng": "Samrong Thap",
      "geo_id": "3",
      "province_id": "21"
  },
  {
      "amphur_id": "285",
      "amphur_code": "3213",
      "amphur_name": "\u0e1a\u0e31\u0e27\u0e40\u0e0a\u0e14",
      "amphur_name_eng": "Buachet",
      "geo_id": "3",
      "province_id": "21"
  },
  {
      "amphur_id": "286",
      "amphur_code": "3214",
      "amphur_name": "\u0e1e\u0e19\u0e21\u0e14\u0e07\u0e23\u0e31\u0e01",
      "amphur_name_eng": "Phanom Dong Rak",
      "geo_id": "3",
      "province_id": "21"
  },
  {
      "amphur_id": "287",
      "amphur_code": "3215",
      "amphur_name": "\u0e28\u0e23\u0e35\u0e13\u0e23\u0e07\u0e04\u0e4c",
      "amphur_name_eng": "Si Narong",
      "geo_id": "3",
      "province_id": "21"
  },
  {
      "amphur_id": "288",
      "amphur_code": "3216",
      "amphur_name": "\u0e40\u0e02\u0e27\u0e32\u0e2a\u0e34\u0e19\u0e23\u0e34\u0e19\u0e17\u0e23\u0e4c",
      "amphur_name_eng": "Khwao Sinarin",
      "geo_id": "3",
      "province_id": "21"
  },
  {
      "amphur_id": "289",
      "amphur_code": "3217",
      "amphur_name": "\u0e42\u0e19\u0e19\u0e19\u0e32\u0e23\u0e32\u0e22\u0e13\u0e4c",
      "amphur_name_eng": "Non Narai",
      "geo_id": "3",
      "province_id": "21"
  },
  {
      "amphur_id": "290",
      "amphur_code": "3301",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e28\u0e23\u0e35\u0e2a\u0e30\u0e40\u0e01\u0e29",
      "amphur_name_eng": "Mueang Si Sa Ket",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "291",
      "amphur_code": "3302",
      "amphur_name": "\u0e22\u0e32\u0e07\u0e0a\u0e38\u0e21\u0e19\u0e49\u0e2d\u0e22",
      "amphur_name_eng": "Yang Chum Noi",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "292",
      "amphur_code": "3303",
      "amphur_name": "\u0e01\u0e31\u0e19\u0e17\u0e23\u0e32\u0e23\u0e21\u0e22\u0e4c",
      "amphur_name_eng": "Kanthararom",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "293",
      "amphur_code": "3304",
      "amphur_name": "\u0e01\u0e31\u0e19\u0e17\u0e23\u0e25\u0e31\u0e01\u0e29\u0e4c",
      "amphur_name_eng": "Kantharalak",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "294",
      "amphur_code": "3305",
      "amphur_name": "\u0e02\u0e38\u0e02\u0e31\u0e19\u0e18\u0e4c",
      "amphur_name_eng": "Khukhan",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "295",
      "amphur_code": "3306",
      "amphur_name": "\u0e44\u0e1e\u0e23\u0e1a\u0e36\u0e07",
      "amphur_name_eng": "Phrai Bueng",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "296",
      "amphur_code": "3307",
      "amphur_name": "\u0e1b\u0e23\u0e32\u0e07\u0e04\u0e4c\u0e01\u0e39\u0e48",
      "amphur_name_eng": "Prang Ku",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "297",
      "amphur_code": "3308",
      "amphur_name": "\u0e02\u0e38\u0e19\u0e2b\u0e32\u0e0d",
      "amphur_name_eng": "Khun Han",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "298",
      "amphur_code": "3309",
      "amphur_name": "\u0e23\u0e32\u0e29\u0e35\u0e44\u0e28\u0e25",
      "amphur_name_eng": "Rasi Salai",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "299",
      "amphur_code": "3310",
      "amphur_name": "\u0e2d\u0e38\u0e17\u0e38\u0e21\u0e1e\u0e23\u0e1e\u0e34\u0e2a\u0e31\u0e22",
      "amphur_name_eng": "Uthumphon Phisai",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "300",
      "amphur_code": "3311",
      "amphur_name": "\u0e1a\u0e36\u0e07\u0e1a\u0e39\u0e23\u0e1e\u0e4c",
      "amphur_name_eng": "Bueng Bun",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "301",
      "amphur_code": "3312",
      "amphur_name": "\u0e2b\u0e49\u0e27\u0e22\u0e17\u0e31\u0e1a\u0e17\u0e31\u0e19",
      "amphur_name_eng": "Huai Thap Than",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "302",
      "amphur_code": "3313",
      "amphur_name": "\u0e42\u0e19\u0e19\u0e04\u0e39\u0e13",
      "amphur_name_eng": "Non Khun",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "303",
      "amphur_code": "3314",
      "amphur_name": "\u0e28\u0e23\u0e35\u0e23\u0e31\u0e15\u0e19\u0e30",
      "amphur_name_eng": "Si Rattana",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "304",
      "amphur_code": "3315",
      "amphur_name": "\u0e19\u0e49\u0e33\u0e40\u0e01\u0e25\u0e35\u0e49\u0e22\u0e07",
      "amphur_name_eng": "Si Rattana",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "305",
      "amphur_code": "3316",
      "amphur_name": "\u0e27\u0e31\u0e07\u0e2b\u0e34\u0e19",
      "amphur_name_eng": "Wang Hin",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "306",
      "amphur_code": "3317",
      "amphur_name": "\u0e20\u0e39\u0e2a\u0e34\u0e07\u0e2b\u0e4c",
      "amphur_name_eng": "Phu Sing",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "307",
      "amphur_code": "3318",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e08\u0e31\u0e19\u0e17\u0e23\u0e4c",
      "amphur_name_eng": "Mueang Chan",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "308",
      "amphur_code": "3319",
      "amphur_name": "\u0e40\u0e1a\u0e0d\u0e08\u0e25\u0e31\u0e01\u0e29\u0e4c",
      "amphur_name_eng": "Benchalak",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "309",
      "amphur_code": "3320",
      "amphur_name": "\u0e1e\u0e22\u0e38\u0e2b\u0e4c",
      "amphur_name_eng": "Phayu",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "310",
      "amphur_code": "3321",
      "amphur_name": "\u0e42\u0e1e\u0e18\u0e34\u0e4c\u0e28\u0e23\u0e35\u0e2a\u0e38\u0e27\u0e23\u0e23\u0e13",
      "amphur_name_eng": "Pho Si Suwan",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "311",
      "amphur_code": "3322",
      "amphur_name": "\u0e28\u0e34\u0e25\u0e32\u0e25\u0e32\u0e14",
      "amphur_name_eng": "Sila Lat",
      "geo_id": "3",
      "province_id": "22"
  },
  {
      "amphur_id": "312",
      "amphur_code": "3401",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2d\u0e38\u0e1a\u0e25\u0e23\u0e32\u0e0a\u0e18\u0e32\u0e19\u0e35",
      "amphur_name_eng": "Mueang Ubon Ratchathani",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "313",
      "amphur_code": "3402",
      "amphur_name": "\u0e28\u0e23\u0e35\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e43\u0e2b\u0e21\u0e48",
      "amphur_name_eng": "Si Mueang Mai",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "314",
      "amphur_code": "3403",
      "amphur_name": "\u0e42\u0e02\u0e07\u0e40\u0e08\u0e35\u0e22\u0e21",
      "amphur_name_eng": "Khong Chiam",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "315",
      "amphur_code": "3404",
      "amphur_name": "\u0e40\u0e02\u0e37\u0e48\u0e2d\u0e07\u0e43\u0e19",
      "amphur_name_eng": "Khueang Nai",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "316",
      "amphur_code": "3405",
      "amphur_name": "\u0e40\u0e02\u0e21\u0e23\u0e32\u0e10",
      "amphur_name_eng": "Khemarat",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "317",
      "amphur_code": "3406",
      "amphur_name": "*\u0e0a\u0e32\u0e19\u0e38\u0e21\u0e32\u0e19",
      "amphur_name_eng": "*Shanuman",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "318",
      "amphur_code": "3407",
      "amphur_name": "\u0e40\u0e14\u0e0a\u0e2d\u0e38\u0e14\u0e21",
      "amphur_name_eng": "Det Udom",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "319",
      "amphur_code": "3408",
      "amphur_name": "\u0e19\u0e32\u0e08\u0e30\u0e2b\u0e25\u0e27\u0e22",
      "amphur_name_eng": "Na Chaluai",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "320",
      "amphur_code": "3409",
      "amphur_name": "\u0e19\u0e49\u0e33\u0e22\u0e37\u0e19",
      "amphur_name_eng": "Nam Yuen",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "321",
      "amphur_code": "3410",
      "amphur_name": "\u0e1a\u0e38\u0e13\u0e11\u0e23\u0e34\u0e01",
      "amphur_name_eng": "Buntharik",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "322",
      "amphur_code": "3411",
      "amphur_name": "\u0e15\u0e23\u0e30\u0e01\u0e32\u0e23\u0e1e\u0e37\u0e0a\u0e1c\u0e25",
      "amphur_name_eng": "Trakan Phuet Phon",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "323",
      "amphur_code": "3412",
      "amphur_name": "\u0e01\u0e38\u0e14\u0e02\u0e49\u0e32\u0e27\u0e1b\u0e38\u0e49\u0e19",
      "amphur_name_eng": "Kut Khaopun",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "324",
      "amphur_code": "3413",
      "amphur_name": "*\u0e1e\u0e19\u0e32",
      "amphur_name_eng": "*Phana",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "325",
      "amphur_code": "3414",
      "amphur_name": "\u0e21\u0e48\u0e27\u0e07\u0e2a\u0e32\u0e21\u0e2a\u0e34\u0e1a",
      "amphur_name_eng": "Muang Sam Sip",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "326",
      "amphur_code": "3415",
      "amphur_name": "\u0e27\u0e32\u0e23\u0e34\u0e19\u0e0a\u0e33\u0e23\u0e32\u0e1a",
      "amphur_name_eng": "Warin Chamrap",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "327",
      "amphur_code": "3416",
      "amphur_name": "*\u0e2d\u0e33\u0e19\u0e32\u0e08\u0e40\u0e08\u0e23\u0e34\u0e0d",
      "amphur_name_eng": "*Amnat Charoen",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "328",
      "amphur_code": "3417",
      "amphur_name": "*\u0e40\u0e2a\u0e19\u0e32\u0e07\u0e04\u0e19\u0e34\u0e04\u0e21",
      "amphur_name_eng": "*Senangkhanikhom",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "329",
      "amphur_code": "3418",
      "amphur_name": "*\u0e2b\u0e31\u0e27\u0e15\u0e30\u0e1e\u0e32\u0e19",
      "amphur_name_eng": "*Hua Taphan",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "330",
      "amphur_code": "3419",
      "amphur_name": "\u0e1e\u0e34\u0e1a\u0e39\u0e25\u0e21\u0e31\u0e07\u0e2a\u0e32\u0e2b\u0e32\u0e23",
      "amphur_name_eng": "Phibun Mangsahan",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "331",
      "amphur_code": "3420",
      "amphur_name": "\u0e15\u0e32\u0e25\u0e2a\u0e38\u0e21",
      "amphur_name_eng": "Tan Sum",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "332",
      "amphur_code": "3421",
      "amphur_name": "\u0e42\u0e1e\u0e18\u0e34\u0e4c\u0e44\u0e17\u0e23",
      "amphur_name_eng": "Pho Sai",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "333",
      "amphur_code": "3422",
      "amphur_name": "\u0e2a\u0e33\u0e42\u0e23\u0e07",
      "amphur_name_eng": "Samrong",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "334",
      "amphur_code": "3423",
      "amphur_name": "*\u0e01\u0e34\u0e48\u0e07\u0e2d\u0e33\u0e40\u0e20\u0e2d\u0e25\u0e37\u0e2d\u0e2d\u0e33\u0e19\u0e32\u0e08",
      "amphur_name_eng": "*King Amphoe Lue Amnat",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "335",
      "amphur_code": "3424",
      "amphur_name": "\u0e14\u0e2d\u0e19\u0e21\u0e14\u0e41\u0e14\u0e07",
      "amphur_name_eng": "Don Mot Daeng",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "336",
      "amphur_code": "3425",
      "amphur_name": "\u0e2a\u0e34\u0e23\u0e34\u0e19\u0e18\u0e23",
      "amphur_name_eng": "Sirindhorn",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "337",
      "amphur_code": "3426",
      "amphur_name": "\u0e17\u0e38\u0e48\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e38\u0e14\u0e21",
      "amphur_name_eng": "Thung Si Udom",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "338",
      "amphur_code": "3427",
      "amphur_name": "*\u0e1b\u0e17\u0e38\u0e21\u0e23\u0e32\u0e0a\u0e27\u0e07\u0e28\u0e32",
      "amphur_name_eng": "*Pathum Ratchawongsa",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "339",
      "amphur_code": "3428",
      "amphur_name": "*\u0e01\u0e34\u0e48\u0e07\u0e2d\u0e33\u0e40\u0e20\u0e2d\u0e28\u0e23\u0e35\u0e2b\u0e25\u0e31\u0e01\u0e0a\u0e31\u0e22",
      "amphur_name_eng": "*King Amphoe Sri Lunk Chai",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "340",
      "amphur_code": "3429",
      "amphur_name": "\u0e19\u0e32\u0e40\u0e22\u0e35\u0e22",
      "amphur_name_eng": "Na Yia",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "341",
      "amphur_code": "3430",
      "amphur_name": "\u0e19\u0e32\u0e15\u0e32\u0e25",
      "amphur_name_eng": "Na Tan",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "342",
      "amphur_code": "3431",
      "amphur_name": "\u0e40\u0e2b\u0e25\u0e48\u0e32\u0e40\u0e2a\u0e37\u0e2d\u0e42\u0e01\u0e49\u0e01",
      "amphur_name_eng": "Lao Suea Kok",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "343",
      "amphur_code": "3432",
      "amphur_name": "\u0e2a\u0e27\u0e48\u0e32\u0e07\u0e27\u0e35\u0e23\u0e30\u0e27\u0e07\u0e28\u0e4c",
      "amphur_name_eng": "Sawang Wirawong",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "344",
      "amphur_code": "3433",
      "amphur_name": "\u0e19\u0e49\u0e33\u0e02\u0e38\u0e48\u0e19",
      "amphur_name_eng": "Nam Khun",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "345",
      "amphur_code": "3481",
      "amphur_name": "*\u0e2d.\u0e2a\u0e38\u0e27\u0e23\u0e23\u0e13\u0e27\u0e32\u0e23\u0e35  \u0e08.\u0e2d\u0e38\u0e1a\u0e25\u0e23\u0e32\u0e0a\u0e18\u0e32\u0e19\u0e35",
      "amphur_name_eng": "*Suwan Wari",
      "geo_id": "3",
      "province_id": "23"
  },
  {
      "amphur_id": "346",
      "amphur_code": "3501",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e22\u0e42\u0e2a\u0e18\u0e23",
      "amphur_name_eng": "Mueang Yasothon",
      "geo_id": "3",
      "province_id": "24"
  },
  {
      "amphur_id": "347",
      "amphur_code": "3502",
      "amphur_name": "\u0e17\u0e23\u0e32\u0e22\u0e21\u0e39\u0e25",
      "amphur_name_eng": "Sai Mun",
      "geo_id": "3",
      "province_id": "24"
  },
  {
      "amphur_id": "348",
      "amphur_code": "3503",
      "amphur_name": "\u0e01\u0e38\u0e14\u0e0a\u0e38\u0e21",
      "amphur_name_eng": "Kut Chum",
      "geo_id": "3",
      "province_id": "24"
  },
  {
      "amphur_id": "349",
      "amphur_code": "3504",
      "amphur_name": "\u0e04\u0e33\u0e40\u0e02\u0e37\u0e48\u0e2d\u0e19\u0e41\u0e01\u0e49\u0e27",
      "amphur_name_eng": "Kham Khuean Kaeo",
      "geo_id": "3",
      "province_id": "24"
  },
  {
      "amphur_id": "350",
      "amphur_code": "3505",
      "amphur_name": "\u0e1b\u0e48\u0e32\u0e15\u0e34\u0e49\u0e27",
      "amphur_name_eng": "Pa Tio",
      "geo_id": "3",
      "province_id": "24"
  },
  {
      "amphur_id": "351",
      "amphur_code": "3506",
      "amphur_name": "\u0e21\u0e2b\u0e32\u0e0a\u0e19\u0e30\u0e0a\u0e31\u0e22",
      "amphur_name_eng": "Maha Chana Chai",
      "geo_id": "3",
      "province_id": "24"
  },
  {
      "amphur_id": "352",
      "amphur_code": "3507",
      "amphur_name": "\u0e04\u0e49\u0e2d\u0e27\u0e31\u0e07",
      "amphur_name_eng": "Kho Wang",
      "geo_id": "3",
      "province_id": "24"
  },
  {
      "amphur_id": "353",
      "amphur_code": "3508",
      "amphur_name": "\u0e40\u0e25\u0e34\u0e07\u0e19\u0e01\u0e17\u0e32",
      "amphur_name_eng": "Loeng Nok Tha",
      "geo_id": "3",
      "province_id": "24"
  },
  {
      "amphur_id": "354",
      "amphur_code": "3509",
      "amphur_name": "\u0e44\u0e17\u0e22\u0e40\u0e08\u0e23\u0e34\u0e0d",
      "amphur_name_eng": "Thai Charoen",
      "geo_id": "3",
      "province_id": "24"
  },
  {
      "amphur_id": "355",
      "amphur_code": "3601",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e0a\u0e31\u0e22\u0e20\u0e39\u0e21\u0e34",
      "amphur_name_eng": "Mueang Chaiyaphum",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "356",
      "amphur_code": "3602",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e40\u0e02\u0e27\u0e49\u0e32",
      "amphur_name_eng": "Ban Khwao",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "357",
      "amphur_code": "3603",
      "amphur_name": "\u0e04\u0e2d\u0e19\u0e2a\u0e27\u0e23\u0e23\u0e04\u0e4c",
      "amphur_name_eng": "Khon Sawan",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "358",
      "amphur_code": "3604",
      "amphur_name": "\u0e40\u0e01\u0e29\u0e15\u0e23\u0e2a\u0e21\u0e1a\u0e39\u0e23\u0e13\u0e4c",
      "amphur_name_eng": "Kaset Sombun",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "359",
      "amphur_code": "3605",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e1a\u0e31\u0e27\u0e41\u0e14\u0e07",
      "amphur_name_eng": "Nong Bua Daeng",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "360",
      "amphur_code": "3606",
      "amphur_name": "\u0e08\u0e31\u0e15\u0e38\u0e23\u0e31\u0e2a",
      "amphur_name_eng": "Chatturat",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "361",
      "amphur_code": "3607",
      "amphur_name": "\u0e1a\u0e33\u0e40\u0e2b\u0e19\u0e47\u0e08\u0e13\u0e23\u0e07\u0e04\u0e4c",
      "amphur_name_eng": "Bamnet Narong",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "362",
      "amphur_code": "3608",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e1a\u0e31\u0e27\u0e23\u0e30\u0e40\u0e2b\u0e27",
      "amphur_name_eng": "Nong Bua Rawe",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "363",
      "amphur_code": "3609",
      "amphur_name": "\u0e40\u0e17\u0e1e\u0e2a\u0e16\u0e34\u0e15",
      "amphur_name_eng": "Thep Sathit",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "364",
      "amphur_code": "3610",
      "amphur_name": "\u0e20\u0e39\u0e40\u0e02\u0e35\u0e22\u0e27",
      "amphur_name_eng": "Phu Khiao",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "365",
      "amphur_code": "3611",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e41\u0e17\u0e48\u0e19",
      "amphur_name_eng": "Ban Thaen",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "366",
      "amphur_code": "3612",
      "amphur_name": "\u0e41\u0e01\u0e49\u0e07\u0e04\u0e23\u0e49\u0e2d",
      "amphur_name_eng": "Kaeng Khro",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "367",
      "amphur_code": "3613",
      "amphur_name": "\u0e04\u0e2d\u0e19\u0e2a\u0e32\u0e23",
      "amphur_name_eng": "Khon San",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "368",
      "amphur_code": "3614",
      "amphur_name": "\u0e20\u0e31\u0e01\u0e14\u0e35\u0e0a\u0e38\u0e21\u0e1e\u0e25",
      "amphur_name_eng": "Phakdi Chumphon",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "369",
      "amphur_code": "3615",
      "amphur_name": "\u0e40\u0e19\u0e34\u0e19\u0e2a\u0e07\u0e48\u0e32",
      "amphur_name_eng": "Noen Sa-nga",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "370",
      "amphur_code": "3616",
      "amphur_name": "\u0e0b\u0e31\u0e1a\u0e43\u0e2b\u0e0d\u0e48",
      "amphur_name_eng": "Sap Yai",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "371",
      "amphur_code": "3651",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e0a\u0e31\u0e22\u0e20\u0e39\u0e21\u0e34 (\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e42\u0e19\u0e19\u0e2a\u0e33\u0e23\u0e32\u0e0d)*",
      "amphur_name_eng": "Mueang Chaiyaphum(Non Sumran)*",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "372",
      "amphur_code": "3652",
      "amphur_name": "\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e1a\u0e49\u0e32\u0e19\u0e2b\u0e27\u0e48\u0e32\u0e40\u0e12\u0e48\u0e32*",
      "amphur_name_eng": "Ban Wha Tao*",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "373",
      "amphur_code": "3653",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e1a\u0e31\u0e27\u0e41\u0e14\u0e07 (\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e27\u0e31\u0e07\u0e0a\u0e21\u0e20\u0e39)*",
      "amphur_name_eng": "Nong Bua Daeng",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "374",
      "amphur_code": "3654",
      "amphur_name": "\u0e01\u0e34\u0e48\u0e07\u0e2d\u0e33\u0e40\u0e20\u0e2d\u0e0b\u0e31\u0e1a\u0e43\u0e2b\u0e0d\u0e48 (\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e0b\u0e31\u0e1a\u0e43\u0e2b\u0e0d\u0e48)*",
      "amphur_name_eng": "King Amphoe Sap Yai*",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "375",
      "amphur_code": "3655",
      "amphur_name": "\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e42\u0e04\u0e01\u0e40\u0e1e\u0e0a\u0e23*",
      "amphur_name_eng": "Coke Phet*",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "376",
      "amphur_code": "3656",
      "amphur_name": "\u0e40\u0e17\u0e1e\u0e2a\u0e16\u0e34\u0e15 (\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e19\u0e32\u0e22\u0e32\u0e07\u0e01\u0e25\u0e31\u0e01)*",
      "amphur_name_eng": "Thep Sathit*",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "377",
      "amphur_code": "3657",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e41\u0e17\u0e48\u0e19 (\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e1a\u0e49\u0e32\u0e19\u0e40\u0e15\u0e48\u0e32)*",
      "amphur_name_eng": "Ban Thaen",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "378",
      "amphur_code": "3658",
      "amphur_name": "\u0e41\u0e01\u0e49\u0e07\u0e04\u0e23\u0e49\u0e2d (\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e17\u0e48\u0e32\u0e21\u0e30\u0e44\u0e1f\u0e2b\u0e27\u0e32\u0e19)*",
      "amphur_name_eng": "Kaeng Khro*",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "379",
      "amphur_code": "3659",
      "amphur_name": "\u0e04\u0e2d\u0e19\u0e2a\u0e32\u0e23 (\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e42\u0e19\u0e19\u0e04\u0e39\u0e13)*",
      "amphur_name_eng": "Khon San*",
      "geo_id": "3",
      "province_id": "25"
  },
  {
      "amphur_id": "380",
      "amphur_code": "3701",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2d\u0e33\u0e19\u0e32\u0e08\u0e40\u0e08\u0e23\u0e34\u0e0d",
      "amphur_name_eng": "Mueang Amnat Charoen",
      "geo_id": "3",
      "province_id": "26"
  },
  {
      "amphur_id": "381",
      "amphur_code": "3702",
      "amphur_name": "\u0e0a\u0e32\u0e19\u0e38\u0e21\u0e32\u0e19",
      "amphur_name_eng": "Chanuman",
      "geo_id": "3",
      "province_id": "26"
  },
  {
      "amphur_id": "382",
      "amphur_code": "3703",
      "amphur_name": "\u0e1b\u0e17\u0e38\u0e21\u0e23\u0e32\u0e0a\u0e27\u0e07\u0e28\u0e32",
      "amphur_name_eng": "Pathum Ratchawongsa",
      "geo_id": "3",
      "province_id": "26"
  },
  {
      "amphur_id": "383",
      "amphur_code": "3704",
      "amphur_name": "\u0e1e\u0e19\u0e32",
      "amphur_name_eng": "Phana",
      "geo_id": "3",
      "province_id": "26"
  },
  {
      "amphur_id": "384",
      "amphur_code": "3705",
      "amphur_name": "\u0e40\u0e2a\u0e19\u0e32\u0e07\u0e04\u0e19\u0e34\u0e04\u0e21",
      "amphur_name_eng": "Senangkhanikhom",
      "geo_id": "3",
      "province_id": "26"
  },
  {
      "amphur_id": "385",
      "amphur_code": "3706",
      "amphur_name": "\u0e2b\u0e31\u0e27\u0e15\u0e30\u0e1e\u0e32\u0e19",
      "amphur_name_eng": "Hua Taphan",
      "geo_id": "3",
      "province_id": "26"
  },
  {
      "amphur_id": "386",
      "amphur_code": "3707",
      "amphur_name": "\u0e25\u0e37\u0e2d\u0e2d\u0e33\u0e19\u0e32\u0e08",
      "amphur_name_eng": "Lue Amnat",
      "geo_id": "3",
      "province_id": "26"
  },
  {
      "amphur_id": "387",
      "amphur_code": "3901",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2b\u0e19\u0e2d\u0e07\u0e1a\u0e31\u0e27\u0e25\u0e33\u0e20\u0e39",
      "amphur_name_eng": "Mueang Nong Bua Lam Phu",
      "geo_id": "3",
      "province_id": "27"
  },
  {
      "amphur_id": "388",
      "amphur_code": "3902",
      "amphur_name": "\u0e19\u0e32\u0e01\u0e25\u0e32\u0e07",
      "amphur_name_eng": "Na Klang",
      "geo_id": "3",
      "province_id": "27"
  },
  {
      "amphur_id": "389",
      "amphur_code": "3903",
      "amphur_name": "\u0e42\u0e19\u0e19\u0e2a\u0e31\u0e07",
      "amphur_name_eng": "Non Sang",
      "geo_id": "3",
      "province_id": "27"
  },
  {
      "amphur_id": "390",
      "amphur_code": "3904",
      "amphur_name": "\u0e28\u0e23\u0e35\u0e1a\u0e38\u0e0d\u0e40\u0e23\u0e37\u0e2d\u0e07",
      "amphur_name_eng": "Si Bun Rueang",
      "geo_id": "3",
      "province_id": "27"
  },
  {
      "amphur_id": "391",
      "amphur_code": "3905",
      "amphur_name": "\u0e2a\u0e38\u0e27\u0e23\u0e23\u0e13\u0e04\u0e39\u0e2b\u0e32",
      "amphur_name_eng": "Suwannakhuha",
      "geo_id": "3",
      "province_id": "27"
  },
  {
      "amphur_id": "392",
      "amphur_code": "3906",
      "amphur_name": "\u0e19\u0e32\u0e27\u0e31\u0e07",
      "amphur_name_eng": "Na Wang",
      "geo_id": "3",
      "province_id": "27"
  },
  {
      "amphur_id": "393",
      "amphur_code": "4001",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e02\u0e2d\u0e19\u0e41\u0e01\u0e48\u0e19",
      "amphur_name_eng": "Mueang Khon Kaen",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "394",
      "amphur_code": "4002",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e1d\u0e32\u0e07",
      "amphur_name_eng": "Ban Fang",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "395",
      "amphur_code": "4003",
      "amphur_name": "\u0e1e\u0e23\u0e30\u0e22\u0e37\u0e19",
      "amphur_name_eng": "Phra Yuen",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "396",
      "amphur_code": "4004",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e40\u0e23\u0e37\u0e2d",
      "amphur_name_eng": "Nong Ruea",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "397",
      "amphur_code": "4005",
      "amphur_name": "\u0e0a\u0e38\u0e21\u0e41\u0e1e",
      "amphur_name_eng": "Chum Phae",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "398",
      "amphur_code": "4006",
      "amphur_name": "\u0e2a\u0e35\u0e0a\u0e21\u0e1e\u0e39",
      "amphur_name_eng": "Si Chomphu",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "399",
      "amphur_code": "4007",
      "amphur_name": "\u0e19\u0e49\u0e33\u0e1e\u0e2d\u0e07",
      "amphur_name_eng": "Nam Phong",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "400",
      "amphur_code": "4008",
      "amphur_name": "\u0e2d\u0e38\u0e1a\u0e25\u0e23\u0e31\u0e15\u0e19\u0e4c",
      "amphur_name_eng": "Ubolratana",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "401",
      "amphur_code": "4009",
      "amphur_name": "\u0e01\u0e23\u0e30\u0e19\u0e27\u0e19",
      "amphur_name_eng": "Kranuan",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "402",
      "amphur_code": "4010",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e44\u0e1c\u0e48",
      "amphur_name_eng": "Ban Phai",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "403",
      "amphur_code": "4011",
      "amphur_name": "\u0e40\u0e1b\u0e37\u0e2d\u0e22\u0e19\u0e49\u0e2d\u0e22",
      "amphur_name_eng": "Pueai Noi",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "404",
      "amphur_code": "4012",
      "amphur_name": "\u0e1e\u0e25",
      "amphur_name_eng": "Phon",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "405",
      "amphur_code": "4013",
      "amphur_name": "\u0e41\u0e27\u0e07\u0e43\u0e2b\u0e0d\u0e48",
      "amphur_name_eng": "Waeng Yai",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "406",
      "amphur_code": "4014",
      "amphur_name": "\u0e41\u0e27\u0e07\u0e19\u0e49\u0e2d\u0e22",
      "amphur_name_eng": "Waeng Noi",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "407",
      "amphur_code": "4015",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e2a\u0e2d\u0e07\u0e2b\u0e49\u0e2d\u0e07",
      "amphur_name_eng": "Nong Song Hong",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "408",
      "amphur_code": "4016",
      "amphur_name": "\u0e20\u0e39\u0e40\u0e27\u0e35\u0e22\u0e07",
      "amphur_name_eng": "Phu Wiang",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "409",
      "amphur_code": "4017",
      "amphur_name": "\u0e21\u0e31\u0e0d\u0e08\u0e32\u0e04\u0e35\u0e23\u0e35",
      "amphur_name_eng": "Mancha Khiri",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "410",
      "amphur_code": "4018",
      "amphur_name": "\u0e0a\u0e19\u0e1a\u0e17",
      "amphur_name_eng": "Chonnabot",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "411",
      "amphur_code": "4019",
      "amphur_name": "\u0e40\u0e02\u0e32\u0e2a\u0e27\u0e19\u0e01\u0e27\u0e32\u0e07",
      "amphur_name_eng": "Khao Suan Kwang",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "412",
      "amphur_code": "4020",
      "amphur_name": "\u0e20\u0e39\u0e1c\u0e32\u0e21\u0e48\u0e32\u0e19",
      "amphur_name_eng": "Phu Pha Man",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "413",
      "amphur_code": "4021",
      "amphur_name": "\u0e0b\u0e33\u0e2a\u0e39\u0e07",
      "amphur_name_eng": "Sam Sung",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "414",
      "amphur_code": "4022",
      "amphur_name": "\u0e42\u0e04\u0e01\u0e42\u0e1e\u0e18\u0e34\u0e4c\u0e44\u0e0a\u0e22",
      "amphur_name_eng": "Khok Pho Chai",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "415",
      "amphur_code": "4023",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e19\u0e32\u0e04\u0e33",
      "amphur_name_eng": "Nong Na Kham",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "416",
      "amphur_code": "4024",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e41\u0e2e\u0e14",
      "amphur_name_eng": "Ban Haet",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "417",
      "amphur_code": "4025",
      "amphur_name": "\u0e42\u0e19\u0e19\u0e28\u0e34\u0e25\u0e32",
      "amphur_name_eng": "Non Sila",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "418",
      "amphur_code": "4029",
      "amphur_name": "\u0e40\u0e27\u0e35\u0e22\u0e07\u0e40\u0e01\u0e48\u0e32",
      "amphur_name_eng": "Wiang Kao",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "419",
      "amphur_code": "4068",
      "amphur_name": "\u0e17\u0e49\u0e2d\u0e07\u0e16\u0e34\u0e48\u0e19\u0e40\u0e17\u0e28\u0e1a\u0e32\u0e25\u0e15\u0e33\u0e1a\u0e25\u0e1a\u0e49\u0e32\u0e19\u0e40\u0e1b\u0e47\u0e14*",
      "amphur_name_eng": "Ban Pet*",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "420",
      "amphur_code": "4098",
      "amphur_name": "\u0e40\u0e17\u0e28\u0e1a\u0e32\u0e25\u0e15\u0e33\u0e1a\u0e25\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e1e\u0e25*",
      "amphur_name_eng": "Tet Saban Tambon Muang Phon*",
      "geo_id": "3",
      "province_id": "28"
  },
  {
      "amphur_id": "421",
      "amphur_code": "4101",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2d\u0e38\u0e14\u0e23\u0e18\u0e32\u0e19\u0e35",
      "amphur_name_eng": "Mueang Udon Thani",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "422",
      "amphur_code": "4102",
      "amphur_name": "\u0e01\u0e38\u0e14\u0e08\u0e31\u0e1a",
      "amphur_name_eng": "Kut Chap",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "423",
      "amphur_code": "4103",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e27\u0e31\u0e27\u0e0b\u0e2d",
      "amphur_name_eng": "Nong Wua So",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "424",
      "amphur_code": "4104",
      "amphur_name": "\u0e01\u0e38\u0e21\u0e20\u0e27\u0e32\u0e1b\u0e35",
      "amphur_name_eng": "Kumphawapi",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "425",
      "amphur_code": "4105",
      "amphur_name": "\u0e42\u0e19\u0e19\u0e2a\u0e30\u0e2d\u0e32\u0e14",
      "amphur_name_eng": "Non Sa-at",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "426",
      "amphur_code": "4106",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e2b\u0e32\u0e19",
      "amphur_name_eng": "Nong Han",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "427",
      "amphur_code": "4107",
      "amphur_name": "\u0e17\u0e38\u0e48\u0e07\u0e1d\u0e19",
      "amphur_name_eng": "Thung Fon",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "428",
      "amphur_code": "4108",
      "amphur_name": "\u0e44\u0e0a\u0e22\u0e27\u0e32\u0e19",
      "amphur_name_eng": "Chai Wan",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "429",
      "amphur_code": "4109",
      "amphur_name": "\u0e28\u0e23\u0e35\u0e18\u0e32\u0e15\u0e38",
      "amphur_name_eng": "Si That",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "430",
      "amphur_code": "4110",
      "amphur_name": "\u0e27\u0e31\u0e07\u0e2a\u0e32\u0e21\u0e2b\u0e21\u0e2d",
      "amphur_name_eng": "Wang Sam Mo",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "431",
      "amphur_code": "4111",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e14\u0e38\u0e07",
      "amphur_name_eng": "Ban Dung",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "432",
      "amphur_code": "4112",
      "amphur_name": "*\u0e2b\u0e19\u0e2d\u0e07\u0e1a\u0e31\u0e27\u0e25\u0e33\u0e20\u0e39",
      "amphur_name_eng": "*Nong Bua Lam Phu",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "433",
      "amphur_code": "4113",
      "amphur_name": "*\u0e28\u0e23\u0e35\u0e1a\u0e38\u0e0d\u0e40\u0e23\u0e37\u0e2d\u0e07",
      "amphur_name_eng": "*Si Bun Rueang",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "434",
      "amphur_code": "4114",
      "amphur_name": "*\u0e19\u0e32\u0e01\u0e25\u0e32\u0e07",
      "amphur_name_eng": "*Na Klang",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "435",
      "amphur_code": "4115",
      "amphur_name": "*\u0e2a\u0e38\u0e27\u0e23\u0e23\u0e13\u0e04\u0e39\u0e2b\u0e32",
      "amphur_name_eng": "*Suwannakhuha",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "436",
      "amphur_code": "4116",
      "amphur_name": "*\u0e42\u0e19\u0e19\u0e2a\u0e31\u0e07",
      "amphur_name_eng": "*Non Sang",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "437",
      "amphur_code": "4117",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e1c\u0e37\u0e2d",
      "amphur_name_eng": "Ban Phue",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "438",
      "amphur_code": "4118",
      "amphur_name": "\u0e19\u0e49\u0e33\u0e42\u0e2a\u0e21",
      "amphur_name_eng": "Nam Som",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "439",
      "amphur_code": "4119",
      "amphur_name": "\u0e40\u0e1e\u0e47\u0e0d",
      "amphur_name_eng": "Phen",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "440",
      "amphur_code": "4120",
      "amphur_name": "\u0e2a\u0e23\u0e49\u0e32\u0e07\u0e04\u0e2d\u0e21",
      "amphur_name_eng": "Sang Khom",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "441",
      "amphur_code": "4121",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e41\u0e2a\u0e07",
      "amphur_name_eng": "Nong Saeng",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "442",
      "amphur_code": "4122",
      "amphur_name": "\u0e19\u0e32\u0e22\u0e39\u0e07",
      "amphur_name_eng": "Na Yung",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "443",
      "amphur_code": "4123",
      "amphur_name": "\u0e1e\u0e34\u0e1a\u0e39\u0e25\u0e22\u0e4c\u0e23\u0e31\u0e01\u0e29\u0e4c",
      "amphur_name_eng": "Phibun Rak",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "444",
      "amphur_code": "4124",
      "amphur_name": "\u0e01\u0e39\u0e48\u0e41\u0e01\u0e49\u0e27",
      "amphur_name_eng": "Ku Kaeo",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "445",
      "amphur_code": "4125",
      "amphur_name": "\u0e1b\u0e23\u0e30\u0e08\u0e31\u0e01\u0e29\u0e4c\u0e28\u0e34\u0e25\u0e1b\u0e32\u0e04\u0e21",
      "amphur_name_eng": "rachak-sinlapakhom",
      "geo_id": "3",
      "province_id": "29"
  },
  {
      "amphur_id": "446",
      "amphur_code": "4201",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e40\u0e25\u0e22",
      "amphur_name_eng": "Mueang Loei",
      "geo_id": "3",
      "province_id": "30"
  },
  {
      "amphur_id": "447",
      "amphur_code": "4202",
      "amphur_name": "\u0e19\u0e32\u0e14\u0e49\u0e27\u0e07",
      "amphur_name_eng": "Na Duang",
      "geo_id": "3",
      "province_id": "30"
  },
  {
      "amphur_id": "448",
      "amphur_code": "4203",
      "amphur_name": "\u0e40\u0e0a\u0e35\u0e22\u0e07\u0e04\u0e32\u0e19",
      "amphur_name_eng": "Chiang Khan",
      "geo_id": "3",
      "province_id": "30"
  },
  {
      "amphur_id": "449",
      "amphur_code": "4204",
      "amphur_name": "\u0e1b\u0e32\u0e01\u0e0a\u0e21",
      "amphur_name_eng": "Pak Chom",
      "geo_id": "3",
      "province_id": "30"
  },
  {
      "amphur_id": "450",
      "amphur_code": "4205",
      "amphur_name": "\u0e14\u0e48\u0e32\u0e19\u0e0b\u0e49\u0e32\u0e22",
      "amphur_name_eng": "Dan Sai",
      "geo_id": "3",
      "province_id": "30"
  },
  {
      "amphur_id": "451",
      "amphur_code": "4206",
      "amphur_name": "\u0e19\u0e32\u0e41\u0e2b\u0e49\u0e27",
      "amphur_name_eng": "Na Haeo",
      "geo_id": "3",
      "province_id": "30"
  },
  {
      "amphur_id": "452",
      "amphur_code": "4207",
      "amphur_name": "\u0e20\u0e39\u0e40\u0e23\u0e37\u0e2d",
      "amphur_name_eng": "Phu Ruea",
      "geo_id": "3",
      "province_id": "30"
  },
  {
      "amphur_id": "453",
      "amphur_code": "4208",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e25\u0e35\u0e48",
      "amphur_name_eng": "Tha Li",
      "geo_id": "3",
      "province_id": "30"
  },
  {
      "amphur_id": "454",
      "amphur_code": "4209",
      "amphur_name": "\u0e27\u0e31\u0e07\u0e2a\u0e30\u0e1e\u0e38\u0e07",
      "amphur_name_eng": "Wang Saphung",
      "geo_id": "3",
      "province_id": "30"
  },
  {
      "amphur_id": "455",
      "amphur_code": "4210",
      "amphur_name": "\u0e20\u0e39\u0e01\u0e23\u0e30\u0e14\u0e36\u0e07",
      "amphur_name_eng": "Phu Kradueng",
      "geo_id": "3",
      "province_id": "30"
  },
  {
      "amphur_id": "456",
      "amphur_code": "4211",
      "amphur_name": "\u0e20\u0e39\u0e2b\u0e25\u0e27\u0e07",
      "amphur_name_eng": "Phu Luang",
      "geo_id": "3",
      "province_id": "30"
  },
  {
      "amphur_id": "457",
      "amphur_code": "4212",
      "amphur_name": "\u0e1c\u0e32\u0e02\u0e32\u0e27",
      "amphur_name_eng": "Pha Khao",
      "geo_id": "3",
      "province_id": "30"
  },
  {
      "amphur_id": "458",
      "amphur_code": "4213",
      "amphur_name": "\u0e40\u0e2d\u0e23\u0e32\u0e27\u0e31\u0e13",
      "amphur_name_eng": "Erawan",
      "geo_id": "3",
      "province_id": "30"
  },
  {
      "amphur_id": "459",
      "amphur_code": "4214",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e2b\u0e34\u0e19",
      "amphur_name_eng": "Nong Hin",
      "geo_id": "3",
      "province_id": "30"
  },
  {
      "amphur_id": "460",
      "amphur_code": "4301",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2b\u0e19\u0e2d\u0e07\u0e04\u0e32\u0e22",
      "amphur_name_eng": "Mueang Nong Khai",
      "geo_id": "3",
      "province_id": "31"
  },
  {
      "amphur_id": "461",
      "amphur_code": "4302",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e1a\u0e48\u0e2d",
      "amphur_name_eng": "Tha Bo",
      "geo_id": "3",
      "province_id": "31"
  },
  {
      "amphur_id": "464",
      "amphur_code": "4305",
      "amphur_name": "\u0e42\u0e1e\u0e19\u0e1e\u0e34\u0e2a\u0e31\u0e22",
      "amphur_name_eng": "Phon Phisai",
      "geo_id": "3",
      "province_id": "31"
  },
  {
      "amphur_id": "466",
      "amphur_code": "4307",
      "amphur_name": "\u0e28\u0e23\u0e35\u0e40\u0e0a\u0e35\u0e22\u0e07\u0e43\u0e2b\u0e21\u0e48",
      "amphur_name_eng": "Si Chiang Mai",
      "geo_id": "3",
      "province_id": "31"
  },
  {
      "amphur_id": "467",
      "amphur_code": "4308",
      "amphur_name": "\u0e2a\u0e31\u0e07\u0e04\u0e21",
      "amphur_name_eng": "Sangkhom",
      "geo_id": "3",
      "province_id": "31"
  },
  {
      "amphur_id": "473",
      "amphur_code": "4314",
      "amphur_name": "\u0e2a\u0e23\u0e30\u0e43\u0e04\u0e23",
      "amphur_name_eng": "Sakhrai",
      "geo_id": "3",
      "province_id": "31"
  },
  {
      "amphur_id": "474",
      "amphur_code": "4315",
      "amphur_name": "\u0e40\u0e1d\u0e49\u0e32\u0e44\u0e23\u0e48",
      "amphur_name_eng": "Fao Rai",
      "geo_id": "3",
      "province_id": "31"
  },
  {
      "amphur_id": "475",
      "amphur_code": "4316",
      "amphur_name": "\u0e23\u0e31\u0e15\u0e19\u0e27\u0e32\u0e1b\u0e35",
      "amphur_name_eng": "Rattanawapi",
      "geo_id": "3",
      "province_id": "31"
  },
  {
      "amphur_id": "476",
      "amphur_code": "4317",
      "amphur_name": "\u0e42\u0e1e\u0e18\u0e34\u0e4c\u0e15\u0e32\u0e01",
      "amphur_name_eng": "Pho Tak",
      "geo_id": "3",
      "province_id": "31"
  },
  {
      "amphur_id": "477",
      "amphur_code": "4401",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e21\u0e2b\u0e32\u0e2a\u0e32\u0e23\u0e04\u0e32\u0e21",
      "amphur_name_eng": "Mueang Maha Sarakham",
      "geo_id": "3",
      "province_id": "32"
  },
  {
      "amphur_id": "478",
      "amphur_code": "4402",
      "amphur_name": "\u0e41\u0e01\u0e14\u0e33",
      "amphur_name_eng": "Kae Dam",
      "geo_id": "3",
      "province_id": "32"
  },
  {
      "amphur_id": "479",
      "amphur_code": "4403",
      "amphur_name": "\u0e42\u0e01\u0e2a\u0e38\u0e21\u0e1e\u0e34\u0e2a\u0e31\u0e22",
      "amphur_name_eng": "Kosum Phisai",
      "geo_id": "3",
      "province_id": "32"
  },
  {
      "amphur_id": "480",
      "amphur_code": "4404",
      "amphur_name": "\u0e01\u0e31\u0e19\u0e17\u0e23\u0e27\u0e34\u0e0a\u0e31\u0e22",
      "amphur_name_eng": "Kantharawichai",
      "geo_id": "3",
      "province_id": "32"
  },
  {
      "amphur_id": "481",
      "amphur_code": "4405",
      "amphur_name": "\u0e40\u0e0a\u0e35\u0e22\u0e07\u0e22\u0e37\u0e19",
      "amphur_name_eng": "Kantharawichai",
      "geo_id": "3",
      "province_id": "32"
  },
  {
      "amphur_id": "482",
      "amphur_code": "4406",
      "amphur_name": "\u0e1a\u0e23\u0e1a\u0e37\u0e2d",
      "amphur_name_eng": "Borabue",
      "geo_id": "3",
      "province_id": "32"
  },
  {
      "amphur_id": "483",
      "amphur_code": "4407",
      "amphur_name": "\u0e19\u0e32\u0e40\u0e0a\u0e37\u0e2d\u0e01",
      "amphur_name_eng": "Na Chueak",
      "geo_id": "3",
      "province_id": "32"
  },
  {
      "amphur_id": "484",
      "amphur_code": "4408",
      "amphur_name": "\u0e1e\u0e22\u0e31\u0e04\u0e06\u0e20\u0e39\u0e21\u0e34\u0e1e\u0e34\u0e2a\u0e31\u0e22",
      "amphur_name_eng": "Phayakkhaphum Phisai",
      "geo_id": "3",
      "province_id": "32"
  },
  {
      "amphur_id": "485",
      "amphur_code": "4409",
      "amphur_name": "\u0e27\u0e32\u0e1b\u0e35\u0e1b\u0e17\u0e38\u0e21",
      "amphur_name_eng": "Wapi Pathum",
      "geo_id": "3",
      "province_id": "32"
  },
  {
      "amphur_id": "486",
      "amphur_code": "4410",
      "amphur_name": "\u0e19\u0e32\u0e14\u0e39\u0e19",
      "amphur_name_eng": "Na Dun",
      "geo_id": "3",
      "province_id": "32"
  },
  {
      "amphur_id": "487",
      "amphur_code": "4411",
      "amphur_name": "\u0e22\u0e32\u0e07\u0e2a\u0e35\u0e2a\u0e38\u0e23\u0e32\u0e0a",
      "amphur_name_eng": "Yang Sisurat",
      "geo_id": "3",
      "province_id": "32"
  },
  {
      "amphur_id": "488",
      "amphur_code": "4412",
      "amphur_name": "\u0e01\u0e38\u0e14\u0e23\u0e31\u0e07",
      "amphur_name_eng": "Kut Rang",
      "geo_id": "3",
      "province_id": "32"
  },
  {
      "amphur_id": "489",
      "amphur_code": "4413",
      "amphur_name": "\u0e0a\u0e37\u0e48\u0e19\u0e0a\u0e21",
      "amphur_name_eng": "Chuen Chom",
      "geo_id": "3",
      "province_id": "32"
  },
  {
      "amphur_id": "490",
      "amphur_code": "4481",
      "amphur_name": "*\u0e2b\u0e25\u0e38\u0e1a",
      "amphur_name_eng": "*Lub",
      "geo_id": "3",
      "province_id": "32"
  },
  {
      "amphur_id": "491",
      "amphur_code": "4501",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e23\u0e49\u0e2d\u0e22\u0e40\u0e2d\u0e47\u0e14",
      "amphur_name_eng": "Mueang Roi Et",
      "geo_id": "3",
      "province_id": "33"
  },
  {
      "amphur_id": "492",
      "amphur_code": "4502",
      "amphur_name": "\u0e40\u0e01\u0e29\u0e15\u0e23\u0e27\u0e34\u0e2a\u0e31\u0e22",
      "amphur_name_eng": "Kaset Wisai",
      "geo_id": "3",
      "province_id": "33"
  },
  {
      "amphur_id": "493",
      "amphur_code": "4503",
      "amphur_name": "\u0e1b\u0e17\u0e38\u0e21\u0e23\u0e31\u0e15\u0e15\u0e4c",
      "amphur_name_eng": "Pathum Rat",
      "geo_id": "3",
      "province_id": "33"
  },
  {
      "amphur_id": "494",
      "amphur_code": "4504",
      "amphur_name": "\u0e08\u0e15\u0e38\u0e23\u0e1e\u0e31\u0e01\u0e15\u0e23\u0e1e\u0e34\u0e21\u0e32\u0e19",
      "amphur_name_eng": "Chaturaphak Phiman",
      "geo_id": "3",
      "province_id": "33"
  },
  {
      "amphur_id": "495",
      "amphur_code": "4505",
      "amphur_name": "\u0e18\u0e27\u0e31\u0e0a\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Thawat Buri",
      "geo_id": "3",
      "province_id": "33"
  },
  {
      "amphur_id": "496",
      "amphur_code": "4506",
      "amphur_name": "\u0e1e\u0e19\u0e21\u0e44\u0e1e\u0e23",
      "amphur_name_eng": "Phanom Phrai",
      "geo_id": "3",
      "province_id": "33"
  },
  {
      "amphur_id": "497",
      "amphur_code": "4507",
      "amphur_name": "\u0e42\u0e1e\u0e19\u0e17\u0e2d\u0e07",
      "amphur_name_eng": "Phon Thong",
      "geo_id": "3",
      "province_id": "33"
  },
  {
      "amphur_id": "498",
      "amphur_code": "4508",
      "amphur_name": "\u0e42\u0e1e\u0e18\u0e34\u0e4c\u0e0a\u0e31\u0e22",
      "amphur_name_eng": "Pho Chai",
      "geo_id": "3",
      "province_id": "33"
  },
  {
      "amphur_id": "499",
      "amphur_code": "4509",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e1e\u0e2d\u0e01",
      "amphur_name_eng": "Nong Phok",
      "geo_id": "3",
      "province_id": "33"
  },
  {
      "amphur_id": "500",
      "amphur_code": "4510",
      "amphur_name": "\u0e40\u0e2a\u0e25\u0e20\u0e39\u0e21\u0e34",
      "amphur_name_eng": "Selaphum",
      "geo_id": "3",
      "province_id": "33"
  },
  {
      "amphur_id": "501",
      "amphur_code": "4511",
      "amphur_name": "\u0e2a\u0e38\u0e27\u0e23\u0e23\u0e13\u0e20\u0e39\u0e21\u0e34",
      "amphur_name_eng": "Suwannaphum",
      "geo_id": "3",
      "province_id": "33"
  },
  {
      "amphur_id": "502",
      "amphur_code": "4512",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2a\u0e23\u0e27\u0e07",
      "amphur_name_eng": "Mueang Suang",
      "geo_id": "3",
      "province_id": "33"
  },
  {
      "amphur_id": "503",
      "amphur_code": "4513",
      "amphur_name": "\u0e42\u0e1e\u0e19\u0e17\u0e23\u0e32\u0e22",
      "amphur_name_eng": "Phon Sai",
      "geo_id": "3",
      "province_id": "33"
  },
  {
      "amphur_id": "504",
      "amphur_code": "4514",
      "amphur_name": "\u0e2d\u0e32\u0e08\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16",
      "amphur_name_eng": "At Samat",
      "geo_id": "3",
      "province_id": "33"
  },
  {
      "amphur_id": "505",
      "amphur_code": "4515",
      "amphur_name": "\u0e40\u0e21\u0e22\u0e27\u0e14\u0e35",
      "amphur_name_eng": "Moei Wadi",
      "geo_id": "3",
      "province_id": "33"
  },
  {
      "amphur_id": "506",
      "amphur_code": "4516",
      "amphur_name": "\u0e28\u0e23\u0e35\u0e2a\u0e21\u0e40\u0e14\u0e47\u0e08",
      "amphur_name_eng": "Si Somdet",
      "geo_id": "3",
      "province_id": "33"
  },
  {
      "amphur_id": "507",
      "amphur_code": "4517",
      "amphur_name": "\u0e08\u0e31\u0e07\u0e2b\u0e32\u0e23",
      "amphur_name_eng": "Changhan",
      "geo_id": "3",
      "province_id": "33"
  },
  {
      "amphur_id": "508",
      "amphur_code": "4518",
      "amphur_name": "\u0e40\u0e0a\u0e35\u0e22\u0e07\u0e02\u0e27\u0e31\u0e0d",
      "amphur_name_eng": "Chiang Khwan",
      "geo_id": "3",
      "province_id": "33"
  },
  {
      "amphur_id": "509",
      "amphur_code": "4519",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e2e\u0e35",
      "amphur_name_eng": "Nong Hi",
      "geo_id": "3",
      "province_id": "33"
  },
  {
      "amphur_id": "510",
      "amphur_code": "4520",
      "amphur_name": "\u0e17\u0e38\u0e48\u0e07\u0e40\u0e02\u0e32\u0e2b\u0e25\u0e27\u0e07",
      "amphur_name_eng": "Thung Khao Luang\u0e01\u0e34\u0e48",
      "geo_id": "3",
      "province_id": "33"
  },
  {
      "amphur_id": "511",
      "amphur_code": "4601",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e01\u0e32\u0e2c\u0e2a\u0e34\u0e19\u0e18\u0e38\u0e4c",
      "amphur_name_eng": "Mueang Kalasin",
      "geo_id": "3",
      "province_id": "34"
  },
  {
      "amphur_id": "512",
      "amphur_code": "4602",
      "amphur_name": "\u0e19\u0e32\u0e21\u0e19",
      "amphur_name_eng": "Na Mon",
      "geo_id": "3",
      "province_id": "34"
  },
  {
      "amphur_id": "513",
      "amphur_code": "4603",
      "amphur_name": "\u0e01\u0e21\u0e25\u0e32\u0e44\u0e2a\u0e22",
      "amphur_name_eng": "Kamalasai",
      "geo_id": "3",
      "province_id": "34"
  },
  {
      "amphur_id": "514",
      "amphur_code": "4604",
      "amphur_name": "\u0e23\u0e48\u0e2d\u0e07\u0e04\u0e33",
      "amphur_name_eng": "Rong Kham",
      "geo_id": "3",
      "province_id": "34"
  },
  {
      "amphur_id": "515",
      "amphur_code": "4605",
      "amphur_name": "\u0e01\u0e38\u0e09\u0e34\u0e19\u0e32\u0e23\u0e32\u0e22\u0e13\u0e4c",
      "amphur_name_eng": "Kuchinarai",
      "geo_id": "3",
      "province_id": "34"
  },
  {
      "amphur_id": "516",
      "amphur_code": "4606",
      "amphur_name": "\u0e40\u0e02\u0e32\u0e27\u0e07",
      "amphur_name_eng": "Khao Wong",
      "geo_id": "3",
      "province_id": "34"
  },
  {
      "amphur_id": "517",
      "amphur_code": "4607",
      "amphur_name": "\u0e22\u0e32\u0e07\u0e15\u0e25\u0e32\u0e14",
      "amphur_name_eng": "Yang Talat",
      "geo_id": "3",
      "province_id": "34"
  },
  {
      "amphur_id": "518",
      "amphur_code": "4608",
      "amphur_name": "\u0e2b\u0e49\u0e27\u0e22\u0e40\u0e21\u0e47\u0e01",
      "amphur_name_eng": "Huai Mek",
      "geo_id": "3",
      "province_id": "34"
  },
  {
      "amphur_id": "519",
      "amphur_code": "4609",
      "amphur_name": "\u0e2a\u0e2b\u0e31\u0e2a\u0e02\u0e31\u0e19\u0e18\u0e4c",
      "amphur_name_eng": "Sahatsakhan",
      "geo_id": "3",
      "province_id": "34"
  },
  {
      "amphur_id": "520",
      "amphur_code": "4610",
      "amphur_name": "\u0e04\u0e33\u0e21\u0e48\u0e27\u0e07",
      "amphur_name_eng": "Kham Muang",
      "geo_id": "3",
      "province_id": "34"
  },
  {
      "amphur_id": "521",
      "amphur_code": "4611",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e04\u0e31\u0e19\u0e42\u0e17",
      "amphur_name_eng": "Tha Khantho",
      "geo_id": "3",
      "province_id": "34"
  },
  {
      "amphur_id": "522",
      "amphur_code": "4612",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e01\u0e38\u0e07\u0e28\u0e23\u0e35",
      "amphur_name_eng": "Nong Kung Si",
      "geo_id": "3",
      "province_id": "34"
  },
  {
      "amphur_id": "523",
      "amphur_code": "4613",
      "amphur_name": "\u0e2a\u0e21\u0e40\u0e14\u0e47\u0e08",
      "amphur_name_eng": "Somdet",
      "geo_id": "3",
      "province_id": "34"
  },
  {
      "amphur_id": "524",
      "amphur_code": "4614",
      "amphur_name": "\u0e2b\u0e49\u0e27\u0e22\u0e1c\u0e36\u0e49\u0e07",
      "amphur_name_eng": "Huai Phueng",
      "geo_id": "3",
      "province_id": "34"
  },
  {
      "amphur_id": "525",
      "amphur_code": "4615",
      "amphur_name": "\u0e2a\u0e32\u0e21\u0e0a\u0e31\u0e22",
      "amphur_name_eng": "Sam Chai",
      "geo_id": "3",
      "province_id": "34"
  },
  {
      "amphur_id": "526",
      "amphur_code": "4616",
      "amphur_name": "\u0e19\u0e32\u0e04\u0e39",
      "amphur_name_eng": "Na Khu",
      "geo_id": "3",
      "province_id": "34"
  },
  {
      "amphur_id": "527",
      "amphur_code": "4617",
      "amphur_name": "\u0e14\u0e2d\u0e19\u0e08\u0e32\u0e19",
      "amphur_name_eng": "Don Chan",
      "geo_id": "3",
      "province_id": "34"
  },
  {
      "amphur_id": "528",
      "amphur_code": "4618",
      "amphur_name": "\u0e06\u0e49\u0e2d\u0e07\u0e0a\u0e31\u0e22",
      "amphur_name_eng": "Khong Chai",
      "geo_id": "3",
      "province_id": "34"
  },
  {
      "amphur_id": "529",
      "amphur_code": "4701",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2a\u0e01\u0e25\u0e19\u0e04\u0e23",
      "amphur_name_eng": "Mueang Sakon Nakhon",
      "geo_id": "3",
      "province_id": "35"
  },
  {
      "amphur_id": "530",
      "amphur_code": "4702",
      "amphur_name": "\u0e01\u0e38\u0e2a\u0e38\u0e21\u0e32\u0e25\u0e22\u0e4c",
      "amphur_name_eng": "Kusuman",
      "geo_id": "3",
      "province_id": "35"
  },
  {
      "amphur_id": "531",
      "amphur_code": "4703",
      "amphur_name": "\u0e01\u0e38\u0e14\u0e1a\u0e32\u0e01",
      "amphur_name_eng": "Kut Bak",
      "geo_id": "3",
      "province_id": "35"
  },
  {
      "amphur_id": "532",
      "amphur_code": "4704",
      "amphur_name": "\u0e1e\u0e23\u0e23\u0e13\u0e32\u0e19\u0e34\u0e04\u0e21",
      "amphur_name_eng": "Phanna Nikhom",
      "geo_id": "3",
      "province_id": "35"
  },
  {
      "amphur_id": "533",
      "amphur_code": "4705",
      "amphur_name": "\u0e1e\u0e31\u0e07\u0e42\u0e04\u0e19",
      "amphur_name_eng": "Phang Khon",
      "geo_id": "3",
      "province_id": "35"
  },
  {
      "amphur_id": "534",
      "amphur_code": "4706",
      "amphur_name": "\u0e27\u0e32\u0e23\u0e34\u0e0a\u0e20\u0e39\u0e21\u0e34",
      "amphur_name_eng": "Waritchaphum",
      "geo_id": "3",
      "province_id": "35"
  },
  {
      "amphur_id": "535",
      "amphur_code": "4707",
      "amphur_name": "\u0e19\u0e34\u0e04\u0e21\u0e19\u0e49\u0e33\u0e2d\u0e39\u0e19",
      "amphur_name_eng": "Nikhom Nam Un",
      "geo_id": "3",
      "province_id": "35"
  },
  {
      "amphur_id": "536",
      "amphur_code": "4708",
      "amphur_name": "\u0e27\u0e32\u0e19\u0e23\u0e19\u0e34\u0e27\u0e32\u0e2a",
      "amphur_name_eng": "Wanon Niwat",
      "geo_id": "3",
      "province_id": "35"
  },
  {
      "amphur_id": "537",
      "amphur_code": "4709",
      "amphur_name": "\u0e04\u0e33\u0e15\u0e32\u0e01\u0e25\u0e49\u0e32",
      "amphur_name_eng": "Kham Ta Kla",
      "geo_id": "3",
      "province_id": "35"
  },
  {
      "amphur_id": "538",
      "amphur_code": "4710",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e21\u0e48\u0e27\u0e07",
      "amphur_name_eng": "Ban Muang",
      "geo_id": "3",
      "province_id": "35"
  },
  {
      "amphur_id": "539",
      "amphur_code": "4711",
      "amphur_name": "\u0e2d\u0e32\u0e01\u0e32\u0e28\u0e2d\u0e33\u0e19\u0e27\u0e22",
      "amphur_name_eng": "Akat Amnuai",
      "geo_id": "3",
      "province_id": "35"
  },
  {
      "amphur_id": "540",
      "amphur_code": "4712",
      "amphur_name": "\u0e2a\u0e27\u0e48\u0e32\u0e07\u0e41\u0e14\u0e19\u0e14\u0e34\u0e19",
      "amphur_name_eng": "Sawang Daen Din",
      "geo_id": "3",
      "province_id": "35"
  },
  {
      "amphur_id": "541",
      "amphur_code": "4713",
      "amphur_name": "\u0e2a\u0e48\u0e2d\u0e07\u0e14\u0e32\u0e27",
      "amphur_name_eng": "Song Dao",
      "geo_id": "3",
      "province_id": "35"
  },
  {
      "amphur_id": "542",
      "amphur_code": "4714",
      "amphur_name": "\u0e40\u0e15\u0e48\u0e32\u0e07\u0e2d\u0e22",
      "amphur_name_eng": "Tao Ngoi",
      "geo_id": "3",
      "province_id": "35"
  },
  {
      "amphur_id": "543",
      "amphur_code": "4715",
      "amphur_name": "\u0e42\u0e04\u0e01\u0e28\u0e23\u0e35\u0e2a\u0e38\u0e1e\u0e23\u0e23\u0e13",
      "amphur_name_eng": "Khok Si Suphan",
      "geo_id": "3",
      "province_id": "35"
  },
  {
      "amphur_id": "544",
      "amphur_code": "4716",
      "amphur_name": "\u0e40\u0e08\u0e23\u0e34\u0e0d\u0e28\u0e34\u0e25\u0e1b\u0e4c",
      "amphur_name_eng": "Charoen Sin",
      "geo_id": "3",
      "province_id": "35"
  },
  {
      "amphur_id": "545",
      "amphur_code": "4717",
      "amphur_name": "\u0e42\u0e1e\u0e19\u0e19\u0e32\u0e41\u0e01\u0e49\u0e27",
      "amphur_name_eng": "Phon Na Kaeo",
      "geo_id": "3",
      "province_id": "35"
  },
  {
      "amphur_id": "546",
      "amphur_code": "4718",
      "amphur_name": "\u0e20\u0e39\u0e1e\u0e32\u0e19",
      "amphur_name_eng": "Phu Phan",
      "geo_id": "3",
      "province_id": "35"
  },
  {
      "amphur_id": "547",
      "amphur_code": "4751",
      "amphur_name": "\u0e27\u0e32\u0e19\u0e23\u0e19\u0e34\u0e27\u0e32\u0e2a (\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e01\u0e38\u0e14\u0e40\u0e23\u0e37\u0e2d\u0e04\u0e33)*",
      "amphur_name_eng": "Wanon Niwat",
      "geo_id": "3",
      "province_id": "35"
  },
  {
      "amphur_id": "548",
      "amphur_code": "4781",
      "amphur_name": "*\u0e2d.\u0e1a\u0e49\u0e32\u0e19\u0e2b\u0e31\u0e19  \u0e08.\u0e2a\u0e01\u0e25\u0e19\u0e04\u0e23",
      "amphur_name_eng": "*Banhan",
      "geo_id": "3",
      "province_id": "35"
  },
  {
      "amphur_id": "549",
      "amphur_code": "4801",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e19\u0e04\u0e23\u0e1e\u0e19\u0e21",
      "amphur_name_eng": "Mueang Nakhon Phanom",
      "geo_id": "3",
      "province_id": "36"
  },
  {
      "amphur_id": "550",
      "amphur_code": "4802",
      "amphur_name": "\u0e1b\u0e25\u0e32\u0e1b\u0e32\u0e01",
      "amphur_name_eng": "Pla Pak",
      "geo_id": "3",
      "province_id": "36"
  },
  {
      "amphur_id": "551",
      "amphur_code": "4803",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e2d\u0e38\u0e40\u0e17\u0e19",
      "amphur_name_eng": "Tha Uthen",
      "geo_id": "3",
      "province_id": "36"
  },
  {
      "amphur_id": "552",
      "amphur_code": "4804",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e41\u0e1e\u0e07",
      "amphur_name_eng": "Ban Phaeng",
      "geo_id": "3",
      "province_id": "36"
  },
  {
      "amphur_id": "553",
      "amphur_code": "4805",
      "amphur_name": "\u0e18\u0e32\u0e15\u0e38\u0e1e\u0e19\u0e21",
      "amphur_name_eng": "That Phanom",
      "geo_id": "3",
      "province_id": "36"
  },
  {
      "amphur_id": "554",
      "amphur_code": "4806",
      "amphur_name": "\u0e40\u0e23\u0e13\u0e39\u0e19\u0e04\u0e23",
      "amphur_name_eng": "Renu Nakhon",
      "geo_id": "3",
      "province_id": "36"
  },
  {
      "amphur_id": "555",
      "amphur_code": "4807",
      "amphur_name": "\u0e19\u0e32\u0e41\u0e01",
      "amphur_name_eng": "Na Kae",
      "geo_id": "3",
      "province_id": "36"
  },
  {
      "amphur_id": "556",
      "amphur_code": "4808",
      "amphur_name": "\u0e28\u0e23\u0e35\u0e2a\u0e07\u0e04\u0e23\u0e32\u0e21",
      "amphur_name_eng": "Si Songkhram",
      "geo_id": "3",
      "province_id": "36"
  },
  {
      "amphur_id": "557",
      "amphur_code": "4809",
      "amphur_name": "\u0e19\u0e32\u0e2b\u0e27\u0e49\u0e32",
      "amphur_name_eng": "Na Wa",
      "geo_id": "3",
      "province_id": "36"
  },
  {
      "amphur_id": "558",
      "amphur_code": "4810",
      "amphur_name": "\u0e42\u0e1e\u0e19\u0e2a\u0e27\u0e23\u0e23\u0e04\u0e4c",
      "amphur_name_eng": "Phon Sawan",
      "geo_id": "3",
      "province_id": "36"
  },
  {
      "amphur_id": "559",
      "amphur_code": "4811",
      "amphur_name": "\u0e19\u0e32\u0e17\u0e21",
      "amphur_name_eng": "Na Thom",
      "geo_id": "3",
      "province_id": "36"
  },
  {
      "amphur_id": "560",
      "amphur_code": "4812",
      "amphur_name": "\u0e27\u0e31\u0e07\u0e22\u0e32\u0e07",
      "amphur_name_eng": "Wang Yang",
      "geo_id": "3",
      "province_id": "36"
  },
  {
      "amphur_id": "561",
      "amphur_code": "4901",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e21\u0e38\u0e01\u0e14\u0e32\u0e2b\u0e32\u0e23",
      "amphur_name_eng": "Mueang Mukdahan",
      "geo_id": "3",
      "province_id": "37"
  },
  {
      "amphur_id": "562",
      "amphur_code": "4902",
      "amphur_name": "\u0e19\u0e34\u0e04\u0e21\u0e04\u0e33\u0e2a\u0e23\u0e49\u0e2d\u0e22",
      "amphur_name_eng": "Nikhom Kham Soi",
      "geo_id": "3",
      "province_id": "37"
  },
  {
      "amphur_id": "563",
      "amphur_code": "4903",
      "amphur_name": "\u0e14\u0e2d\u0e19\u0e15\u0e32\u0e25",
      "amphur_name_eng": "Don Tan",
      "geo_id": "3",
      "province_id": "37"
  },
  {
      "amphur_id": "564",
      "amphur_code": "4904",
      "amphur_name": "\u0e14\u0e07\u0e2b\u0e25\u0e27\u0e07",
      "amphur_name_eng": "Dong Luang",
      "geo_id": "3",
      "province_id": "37"
  },
  {
      "amphur_id": "565",
      "amphur_code": "4905",
      "amphur_name": "\u0e04\u0e33\u0e0a\u0e30\u0e2d\u0e35",
      "amphur_name_eng": "Khamcha-i",
      "geo_id": "3",
      "province_id": "37"
  },
  {
      "amphur_id": "566",
      "amphur_code": "4906",
      "amphur_name": "\u0e2b\u0e27\u0e49\u0e32\u0e19\u0e43\u0e2b\u0e0d\u0e48",
      "amphur_name_eng": "Wan Yai",
      "geo_id": "3",
      "province_id": "37"
  },
  {
      "amphur_id": "567",
      "amphur_code": "4907",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e2a\u0e39\u0e07",
      "amphur_name_eng": "Nong Sung",
      "geo_id": "3",
      "province_id": "37"
  },
  {
      "amphur_id": "568",
      "amphur_code": "5001",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e40\u0e0a\u0e35\u0e22\u0e07\u0e43\u0e2b\u0e21\u0e48",
      "amphur_name_eng": "Mueang Chiang Mai",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "569",
      "amphur_code": "5002",
      "amphur_name": "\u0e08\u0e2d\u0e21\u0e17\u0e2d\u0e07",
      "amphur_name_eng": "Chom Thong",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "570",
      "amphur_code": "5003",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e41\u0e08\u0e48\u0e21",
      "amphur_name_eng": "Mae Chaem",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "571",
      "amphur_code": "5004",
      "amphur_name": "\u0e40\u0e0a\u0e35\u0e22\u0e07\u0e14\u0e32\u0e27",
      "amphur_name_eng": "Chiang Dao",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "572",
      "amphur_code": "5005",
      "amphur_name": "\u0e14\u0e2d\u0e22\u0e2a\u0e30\u0e40\u0e01\u0e47\u0e14",
      "amphur_name_eng": "Doi Saket",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "573",
      "amphur_code": "5006",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e41\u0e15\u0e07",
      "amphur_name_eng": "Mae Taeng",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "574",
      "amphur_code": "5007",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e23\u0e34\u0e21",
      "amphur_name_eng": "Mae Rim",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "575",
      "amphur_code": "5008",
      "amphur_name": "\u0e2a\u0e30\u0e40\u0e21\u0e34\u0e07",
      "amphur_name_eng": "Samoeng",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "576",
      "amphur_code": "5009",
      "amphur_name": "\u0e1d\u0e32\u0e07",
      "amphur_name_eng": "Fang",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "577",
      "amphur_code": "5010",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e2d\u0e32\u0e22",
      "amphur_name_eng": "Mae Ai",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "578",
      "amphur_code": "5011",
      "amphur_name": "\u0e1e\u0e23\u0e49\u0e32\u0e27",
      "amphur_name_eng": "Phrao",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "579",
      "amphur_code": "5012",
      "amphur_name": "\u0e2a\u0e31\u0e19\u0e1b\u0e48\u0e32\u0e15\u0e2d\u0e07",
      "amphur_name_eng": "San Pa Tong",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "580",
      "amphur_code": "5013",
      "amphur_name": "\u0e2a\u0e31\u0e19\u0e01\u0e33\u0e41\u0e1e\u0e07",
      "amphur_name_eng": "San Kamphaeng",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "581",
      "amphur_code": "5014",
      "amphur_name": "\u0e2a\u0e31\u0e19\u0e17\u0e23\u0e32\u0e22",
      "amphur_name_eng": "San Sai",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "582",
      "amphur_code": "5015",
      "amphur_name": "\u0e2b\u0e32\u0e07\u0e14\u0e07",
      "amphur_name_eng": "Hang Dong",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "583",
      "amphur_code": "5016",
      "amphur_name": "\u0e2e\u0e2d\u0e14",
      "amphur_name_eng": "Hot",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "584",
      "amphur_code": "5017",
      "amphur_name": "\u0e14\u0e2d\u0e22\u0e40\u0e15\u0e48\u0e32",
      "amphur_name_eng": "Doi Tao",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "585",
      "amphur_code": "5018",
      "amphur_name": "\u0e2d\u0e21\u0e01\u0e4b\u0e2d\u0e22",
      "amphur_name_eng": "Omkoi",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "586",
      "amphur_code": "5019",
      "amphur_name": "\u0e2a\u0e32\u0e23\u0e20\u0e35",
      "amphur_name_eng": "Saraphi",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "587",
      "amphur_code": "5020",
      "amphur_name": "\u0e40\u0e27\u0e35\u0e22\u0e07\u0e41\u0e2b\u0e07",
      "amphur_name_eng": "Wiang Haeng",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "588",
      "amphur_code": "5021",
      "amphur_name": "\u0e44\u0e0a\u0e22\u0e1b\u0e23\u0e32\u0e01\u0e32\u0e23",
      "amphur_name_eng": "Chai Prakan",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "589",
      "amphur_code": "5022",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e27\u0e32\u0e07",
      "amphur_name_eng": "Mae Wang",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "590",
      "amphur_code": "5023",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e2d\u0e2d\u0e19",
      "amphur_name_eng": "Mae On",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "591",
      "amphur_code": "5024",
      "amphur_name": "\u0e14\u0e2d\u0e22\u0e2b\u0e25\u0e48\u0e2d",
      "amphur_name_eng": "Doi Lo",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "592",
      "amphur_code": "5051",
      "amphur_name": "\u0e40\u0e17\u0e28\u0e1a\u0e32\u0e25\u0e19\u0e04\u0e23\u0e40\u0e0a\u0e35\u0e22\u0e07\u0e43\u0e2b\u0e21\u0e48 (\u0e2a\u0e32\u0e02\u0e32\u0e41\u0e02\u0e27\u0e07\u0e01\u0e32\u0e25\u0e27\u0e34\u0e25\u0e30)*",
      "amphur_name_eng": "Tet Saban Nakorn Chiangmai(Kan lawi la)*",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "593",
      "amphur_code": "5052",
      "amphur_name": "\u0e40\u0e17\u0e28\u0e1a\u0e32\u0e25\u0e19\u0e04\u0e23\u0e40\u0e0a\u0e35\u0e22\u0e07\u0e43\u0e2b\u0e21\u0e48 (\u0e2a\u0e32\u0e02\u0e32\u0e41\u0e02\u0e27\u0e07\u0e28\u0e23\u0e35\u0e27\u0e34\u0e0a\u0e31)*",
      "amphur_name_eng": "Tet Saban Nakorn Chiangmai(Sri Wi)*",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "594",
      "amphur_code": "5053",
      "amphur_name": "\u0e40\u0e17\u0e28\u0e1a\u0e32\u0e25\u0e19\u0e04\u0e23\u0e40\u0e0a\u0e35\u0e22\u0e07\u0e43\u0e2b\u0e21\u0e48 (\u0e2a\u0e32\u0e02\u0e32\u0e40\u0e21\u0e47\u0e07\u0e23\u0e32\u0e22)*",
      "amphur_name_eng": "Tet Saban Nakorn Chiangmai(Meng Rai)*",
      "geo_id": "1",
      "province_id": "38"
  },
  {
      "amphur_id": "595",
      "amphur_code": "5101",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e25\u0e33\u0e1e\u0e39\u0e19",
      "amphur_name_eng": "Mueang Lamphun",
      "geo_id": "1",
      "province_id": "39"
  },
  {
      "amphur_id": "596",
      "amphur_code": "5102",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e17\u0e32",
      "amphur_name_eng": "Mae Tha",
      "geo_id": "1",
      "province_id": "39"
  },
  {
      "amphur_id": "597",
      "amphur_code": "5103",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e42\u0e2e\u0e48\u0e07",
      "amphur_name_eng": "Ban Hong",
      "geo_id": "1",
      "province_id": "39"
  },
  {
      "amphur_id": "598",
      "amphur_code": "5104",
      "amphur_name": "\u0e25\u0e35\u0e49",
      "amphur_name_eng": "Li",
      "geo_id": "1",
      "province_id": "39"
  },
  {
      "amphur_id": "599",
      "amphur_code": "5105",
      "amphur_name": "\u0e17\u0e38\u0e48\u0e07\u0e2b\u0e31\u0e27\u0e0a\u0e49\u0e32\u0e07",
      "amphur_name_eng": "Thung Hua Chang",
      "geo_id": "1",
      "province_id": "39"
  },
  {
      "amphur_id": "600",
      "amphur_code": "5106",
      "amphur_name": "\u0e1b\u0e48\u0e32\u0e0b\u0e32\u0e07",
      "amphur_name_eng": "Pa Sang",
      "geo_id": "1",
      "province_id": "39"
  },
  {
      "amphur_id": "601",
      "amphur_code": "5107",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e18\u0e34",
      "amphur_name_eng": "Ban Thi",
      "geo_id": "1",
      "province_id": "39"
  },
  {
      "amphur_id": "602",
      "amphur_code": "5108",
      "amphur_name": "\u0e40\u0e27\u0e35\u0e22\u0e07\u0e2b\u0e19\u0e2d\u0e07\u0e25\u0e48\u0e2d\u0e07",
      "amphur_name_eng": "Wiang Nong Long",
      "geo_id": "1",
      "province_id": "39"
  },
  {
      "amphur_id": "603",
      "amphur_code": "5201",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e25\u0e33\u0e1b\u0e32\u0e07",
      "amphur_name_eng": "Mueang Lampang",
      "geo_id": "1",
      "province_id": "40"
  },
  {
      "amphur_id": "604",
      "amphur_code": "5202",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e40\u0e21\u0e32\u0e30",
      "amphur_name_eng": "Mae Mo",
      "geo_id": "1",
      "province_id": "40"
  },
  {
      "amphur_id": "605",
      "amphur_code": "5203",
      "amphur_name": "\u0e40\u0e01\u0e32\u0e30\u0e04\u0e32",
      "amphur_name_eng": "Ko Kha",
      "geo_id": "1",
      "province_id": "40"
  },
  {
      "amphur_id": "606",
      "amphur_code": "5204",
      "amphur_name": "\u0e40\u0e2a\u0e23\u0e34\u0e21\u0e07\u0e32\u0e21",
      "amphur_name_eng": "Soem Ngam",
      "geo_id": "1",
      "province_id": "40"
  },
  {
      "amphur_id": "607",
      "amphur_code": "5205",
      "amphur_name": "\u0e07\u0e32\u0e27",
      "amphur_name_eng": "Ngao",
      "geo_id": "1",
      "province_id": "40"
  },
  {
      "amphur_id": "608",
      "amphur_code": "5206",
      "amphur_name": "\u0e41\u0e08\u0e49\u0e2b\u0e48\u0e21",
      "amphur_name_eng": "Chae Hom",
      "geo_id": "1",
      "province_id": "40"
  },
  {
      "amphur_id": "609",
      "amphur_code": "5207",
      "amphur_name": "\u0e27\u0e31\u0e07\u0e40\u0e2b\u0e19\u0e37\u0e2d",
      "amphur_name_eng": "Wang Nuea",
      "geo_id": "1",
      "province_id": "40"
  },
  {
      "amphur_id": "610",
      "amphur_code": "5208",
      "amphur_name": "\u0e40\u0e16\u0e34\u0e19",
      "amphur_name_eng": "Thoen",
      "geo_id": "1",
      "province_id": "40"
  },
  {
      "amphur_id": "611",
      "amphur_code": "5209",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e1e\u0e23\u0e34\u0e01",
      "amphur_name_eng": "Mae Phrik",
      "geo_id": "1",
      "province_id": "40"
  },
  {
      "amphur_id": "612",
      "amphur_code": "5210",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e17\u0e30",
      "amphur_name_eng": "Mae Tha",
      "geo_id": "1",
      "province_id": "40"
  },
  {
      "amphur_id": "613",
      "amphur_code": "5211",
      "amphur_name": "\u0e2a\u0e1a\u0e1b\u0e23\u0e32\u0e1a",
      "amphur_name_eng": "Sop Prap",
      "geo_id": "1",
      "province_id": "40"
  },
  {
      "amphur_id": "614",
      "amphur_code": "5212",
      "amphur_name": "\u0e2b\u0e49\u0e32\u0e07\u0e09\u0e31\u0e15\u0e23",
      "amphur_name_eng": "Hang Chat",
      "geo_id": "1",
      "province_id": "40"
  },
  {
      "amphur_id": "615",
      "amphur_code": "5213",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e1b\u0e32\u0e19",
      "amphur_name_eng": "Mueang Pan",
      "geo_id": "1",
      "province_id": "40"
  },
  {
      "amphur_id": "616",
      "amphur_code": "5301",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2d\u0e38\u0e15\u0e23\u0e14\u0e34\u0e15\u0e16\u0e4c",
      "amphur_name_eng": "Mueang Uttaradit",
      "geo_id": "1",
      "province_id": "41"
  },
  {
      "amphur_id": "617",
      "amphur_code": "5302",
      "amphur_name": "\u0e15\u0e23\u0e2d\u0e19",
      "amphur_name_eng": "Tron",
      "geo_id": "1",
      "province_id": "41"
  },
  {
      "amphur_id": "618",
      "amphur_code": "5303",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e1b\u0e25\u0e32",
      "amphur_name_eng": "Tha Pla",
      "geo_id": "1",
      "province_id": "41"
  },
  {
      "amphur_id": "619",
      "amphur_code": "5304",
      "amphur_name": "\u0e19\u0e49\u0e33\u0e1b\u0e32\u0e14",
      "amphur_name_eng": "Nam Pat",
      "geo_id": "1",
      "province_id": "41"
  },
  {
      "amphur_id": "620",
      "amphur_code": "5305",
      "amphur_name": "\u0e1f\u0e32\u0e01\u0e17\u0e48\u0e32",
      "amphur_name_eng": "Fak Tha",
      "geo_id": "1",
      "province_id": "41"
  },
  {
      "amphur_id": "621",
      "amphur_code": "5306",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e42\u0e04\u0e01",
      "amphur_name_eng": "Ban Khok",
      "geo_id": "1",
      "province_id": "41"
  },
  {
      "amphur_id": "622",
      "amphur_code": "5307",
      "amphur_name": "\u0e1e\u0e34\u0e0a\u0e31\u0e22",
      "amphur_name_eng": "Phichai",
      "geo_id": "1",
      "province_id": "41"
  },
  {
      "amphur_id": "623",
      "amphur_code": "5308",
      "amphur_name": "\u0e25\u0e31\u0e1a\u0e41\u0e25",
      "amphur_name_eng": "Laplae",
      "geo_id": "1",
      "province_id": "41"
  },
  {
      "amphur_id": "624",
      "amphur_code": "5309",
      "amphur_name": "\u0e17\u0e2d\u0e07\u0e41\u0e2a\u0e19\u0e02\u0e31\u0e19",
      "amphur_name_eng": "Thong Saen Khan",
      "geo_id": "1",
      "province_id": "41"
  },
  {
      "amphur_id": "625",
      "amphur_code": "5401",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e41\u0e1e\u0e23\u0e48",
      "amphur_name_eng": "Mueang Phrae",
      "geo_id": "1",
      "province_id": "42"
  },
  {
      "amphur_id": "626",
      "amphur_code": "5402",
      "amphur_name": "\u0e23\u0e49\u0e2d\u0e07\u0e01\u0e27\u0e32\u0e07",
      "amphur_name_eng": "Rong Kwang",
      "geo_id": "1",
      "province_id": "42"
  },
  {
      "amphur_id": "627",
      "amphur_code": "5403",
      "amphur_name": "\u0e25\u0e2d\u0e07",
      "amphur_name_eng": "Long",
      "geo_id": "1",
      "province_id": "42"
  },
  {
      "amphur_id": "628",
      "amphur_code": "5404",
      "amphur_name": "\u0e2a\u0e39\u0e07\u0e40\u0e21\u0e48\u0e19",
      "amphur_name_eng": "Sung Men",
      "geo_id": "1",
      "province_id": "42"
  },
  {
      "amphur_id": "629",
      "amphur_code": "5405",
      "amphur_name": "\u0e40\u0e14\u0e48\u0e19\u0e0a\u0e31\u0e22",
      "amphur_name_eng": "Den Chai",
      "geo_id": "1",
      "province_id": "42"
  },
  {
      "amphur_id": "630",
      "amphur_code": "5406",
      "amphur_name": "\u0e2a\u0e2d\u0e07",
      "amphur_name_eng": "Song",
      "geo_id": "1",
      "province_id": "42"
  },
  {
      "amphur_id": "631",
      "amphur_code": "5407",
      "amphur_name": "\u0e27\u0e31\u0e07\u0e0a\u0e34\u0e49\u0e19",
      "amphur_name_eng": "Wang Chin",
      "geo_id": "1",
      "province_id": "42"
  },
  {
      "amphur_id": "632",
      "amphur_code": "5408",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e21\u0e48\u0e27\u0e07\u0e44\u0e02\u0e48",
      "amphur_name_eng": "Nong Muang Khai",
      "geo_id": "1",
      "province_id": "42"
  },
  {
      "amphur_id": "633",
      "amphur_code": "5501",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e19\u0e48\u0e32\u0e19",
      "amphur_name_eng": "Mueang Nan",
      "geo_id": "1",
      "province_id": "43"
  },
  {
      "amphur_id": "634",
      "amphur_code": "5502",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e08\u0e23\u0e34\u0e21",
      "amphur_name_eng": "Mae Charim",
      "geo_id": "1",
      "province_id": "43"
  },
  {
      "amphur_id": "635",
      "amphur_code": "5503",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e2b\u0e25\u0e27\u0e07",
      "amphur_name_eng": "Ban Luang",
      "geo_id": "1",
      "province_id": "43"
  },
  {
      "amphur_id": "636",
      "amphur_code": "5504",
      "amphur_name": "\u0e19\u0e32\u0e19\u0e49\u0e2d\u0e22",
      "amphur_name_eng": "Na Noi",
      "geo_id": "1",
      "province_id": "43"
  },
  {
      "amphur_id": "637",
      "amphur_code": "5505",
      "amphur_name": "\u0e1b\u0e31\u0e27",
      "amphur_name_eng": "Pua",
      "geo_id": "1",
      "province_id": "43"
  },
  {
      "amphur_id": "638",
      "amphur_code": "5506",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e27\u0e31\u0e07\u0e1c\u0e32",
      "amphur_name_eng": "Tha Wang Pha",
      "geo_id": "1",
      "province_id": "43"
  },
  {
      "amphur_id": "639",
      "amphur_code": "5507",
      "amphur_name": "\u0e40\u0e27\u0e35\u0e22\u0e07\u0e2a\u0e32",
      "amphur_name_eng": "Wiang Sa",
      "geo_id": "1",
      "province_id": "43"
  },
  {
      "amphur_id": "640",
      "amphur_code": "5508",
      "amphur_name": "\u0e17\u0e38\u0e48\u0e07\u0e0a\u0e49\u0e32\u0e07",
      "amphur_name_eng": "Thung Chang",
      "geo_id": "1",
      "province_id": "43"
  },
  {
      "amphur_id": "641",
      "amphur_code": "5509",
      "amphur_name": "\u0e40\u0e0a\u0e35\u0e22\u0e07\u0e01\u0e25\u0e32\u0e07",
      "amphur_name_eng": "Chiang Klang",
      "geo_id": "1",
      "province_id": "43"
  },
  {
      "amphur_id": "642",
      "amphur_code": "5510",
      "amphur_name": "\u0e19\u0e32\u0e2b\u0e21\u0e37\u0e48\u0e19",
      "amphur_name_eng": "Na Muen",
      "geo_id": "1",
      "province_id": "43"
  },
  {
      "amphur_id": "643",
      "amphur_code": "5511",
      "amphur_name": "\u0e2a\u0e31\u0e19\u0e15\u0e34\u0e2a\u0e38\u0e02",
      "amphur_name_eng": "Santi Suk",
      "geo_id": "1",
      "province_id": "43"
  },
  {
      "amphur_id": "644",
      "amphur_code": "5512",
      "amphur_name": "\u0e1a\u0e48\u0e2d\u0e40\u0e01\u0e25\u0e37\u0e2d",
      "amphur_name_eng": "Bo Kluea",
      "geo_id": "1",
      "province_id": "43"
  },
  {
      "amphur_id": "645",
      "amphur_code": "5513",
      "amphur_name": "\u0e2a\u0e2d\u0e07\u0e41\u0e04\u0e27",
      "amphur_name_eng": "Song Khwae",
      "geo_id": "1",
      "province_id": "43"
  },
  {
      "amphur_id": "646",
      "amphur_code": "5514",
      "amphur_name": "\u0e20\u0e39\u0e40\u0e1e\u0e35\u0e22\u0e07",
      "amphur_name_eng": "Phu Phiang",
      "geo_id": "1",
      "province_id": "43"
  },
  {
      "amphur_id": "647",
      "amphur_code": "5515",
      "amphur_name": "\u0e40\u0e09\u0e25\u0e34\u0e21\u0e1e\u0e23\u0e30\u0e40\u0e01\u0e35\u0e22\u0e23\u0e15\u0e34",
      "amphur_name_eng": "Chaloem Phra Kiat",
      "geo_id": "1",
      "province_id": "43"
  },
  {
      "amphur_id": "648",
      "amphur_code": "5601",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e1e\u0e30\u0e40\u0e22\u0e32",
      "amphur_name_eng": "Mueang Phayao",
      "geo_id": "1",
      "province_id": "44"
  },
  {
      "amphur_id": "649",
      "amphur_code": "5602",
      "amphur_name": "\u0e08\u0e38\u0e19",
      "amphur_name_eng": "Chun",
      "geo_id": "1",
      "province_id": "44"
  },
  {
      "amphur_id": "650",
      "amphur_code": "5603",
      "amphur_name": "\u0e40\u0e0a\u0e35\u0e22\u0e07\u0e04\u0e33",
      "amphur_name_eng": "Chiang Kham",
      "geo_id": "1",
      "province_id": "44"
  },
  {
      "amphur_id": "651",
      "amphur_code": "5604",
      "amphur_name": "\u0e40\u0e0a\u0e35\u0e22\u0e07\u0e21\u0e48\u0e27\u0e19",
      "amphur_name_eng": "Chiang Muan",
      "geo_id": "1",
      "province_id": "44"
  },
  {
      "amphur_id": "652",
      "amphur_code": "5605",
      "amphur_name": "\u0e14\u0e2d\u0e01\u0e04\u0e33\u0e43\u0e15\u0e49",
      "amphur_name_eng": "Dok Khamtai",
      "geo_id": "1",
      "province_id": "44"
  },
  {
      "amphur_id": "653",
      "amphur_code": "5606",
      "amphur_name": "\u0e1b\u0e07",
      "amphur_name_eng": "Pong",
      "geo_id": "1",
      "province_id": "44"
  },
  {
      "amphur_id": "654",
      "amphur_code": "5607",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e43\u0e08",
      "amphur_name_eng": "Mae Chai",
      "geo_id": "1",
      "province_id": "44"
  },
  {
      "amphur_id": "655",
      "amphur_code": "5608",
      "amphur_name": "\u0e20\u0e39\u0e0b\u0e32\u0e07",
      "amphur_name_eng": "Phu Sang",
      "geo_id": "1",
      "province_id": "44"
  },
  {
      "amphur_id": "656",
      "amphur_code": "5609",
      "amphur_name": "\u0e20\u0e39\u0e01\u0e32\u0e21\u0e22\u0e32\u0e27",
      "amphur_name_eng": "Phu Kamyao",
      "geo_id": "1",
      "province_id": "44"
  },
  {
      "amphur_id": "657",
      "amphur_code": "5701",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e40\u0e0a\u0e35\u0e22\u0e07\u0e23\u0e32\u0e22",
      "amphur_name_eng": "Mueang Chiang Rai",
      "geo_id": "1",
      "province_id": "45"
  },
  {
      "amphur_id": "658",
      "amphur_code": "5702",
      "amphur_name": "\u0e40\u0e27\u0e35\u0e22\u0e07\u0e0a\u0e31\u0e22",
      "amphur_name_eng": "Wiang Chai",
      "geo_id": "1",
      "province_id": "45"
  },
  {
      "amphur_id": "659",
      "amphur_code": "5703",
      "amphur_name": "\u0e40\u0e0a\u0e35\u0e22\u0e07\u0e02\u0e2d\u0e07",
      "amphur_name_eng": "Chiang Khong",
      "geo_id": "1",
      "province_id": "45"
  },
  {
      "amphur_id": "660",
      "amphur_code": "5704",
      "amphur_name": "\u0e40\u0e17\u0e34\u0e07",
      "amphur_name_eng": "Thoeng",
      "geo_id": "1",
      "province_id": "45"
  },
  {
      "amphur_id": "661",
      "amphur_code": "5705",
      "amphur_name": "\u0e1e\u0e32\u0e19",
      "amphur_name_eng": "Phan",
      "geo_id": "1",
      "province_id": "45"
  },
  {
      "amphur_id": "662",
      "amphur_code": "5706",
      "amphur_name": "\u0e1b\u0e48\u0e32\u0e41\u0e14\u0e14",
      "amphur_name_eng": "Pa Daet",
      "geo_id": "1",
      "province_id": "45"
  },
  {
      "amphur_id": "663",
      "amphur_code": "5707",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e08\u0e31\u0e19",
      "amphur_name_eng": "Mae Chan",
      "geo_id": "1",
      "province_id": "45"
  },
  {
      "amphur_id": "664",
      "amphur_code": "5708",
      "amphur_name": "\u0e40\u0e0a\u0e35\u0e22\u0e07\u0e41\u0e2a\u0e19",
      "amphur_name_eng": "Chiang Saen",
      "geo_id": "1",
      "province_id": "45"
  },
  {
      "amphur_id": "665",
      "amphur_code": "5709",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e2a\u0e32\u0e22",
      "amphur_name_eng": "Mae Sai",
      "geo_id": "1",
      "province_id": "45"
  },
  {
      "amphur_id": "666",
      "amphur_code": "5710",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e2a\u0e23\u0e27\u0e22",
      "amphur_name_eng": "Mae Suai",
      "geo_id": "1",
      "province_id": "45"
  },
  {
      "amphur_id": "667",
      "amphur_code": "5711",
      "amphur_name": "\u0e40\u0e27\u0e35\u0e22\u0e07\u0e1b\u0e48\u0e32\u0e40\u0e1b\u0e49\u0e32",
      "amphur_name_eng": "Wiang Pa Pao",
      "geo_id": "1",
      "province_id": "45"
  },
  {
      "amphur_id": "668",
      "amphur_code": "5712",
      "amphur_name": "\u0e1e\u0e0d\u0e32\u0e40\u0e21\u0e47\u0e07\u0e23\u0e32\u0e22",
      "amphur_name_eng": "Phaya Mengrai",
      "geo_id": "1",
      "province_id": "45"
  },
  {
      "amphur_id": "669",
      "amphur_code": "5713",
      "amphur_name": "\u0e40\u0e27\u0e35\u0e22\u0e07\u0e41\u0e01\u0e48\u0e19",
      "amphur_name_eng": "Wiang Kaen",
      "geo_id": "1",
      "province_id": "45"
  },
  {
      "amphur_id": "670",
      "amphur_code": "5714",
      "amphur_name": "\u0e02\u0e38\u0e19\u0e15\u0e32\u0e25",
      "amphur_name_eng": "Khun Tan",
      "geo_id": "1",
      "province_id": "45"
  },
  {
      "amphur_id": "671",
      "amphur_code": "5715",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e1f\u0e49\u0e32\u0e2b\u0e25\u0e27\u0e07",
      "amphur_name_eng": "Mae Fa Luang",
      "geo_id": "1",
      "province_id": "45"
  },
  {
      "amphur_id": "672",
      "amphur_code": "5716",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e25\u0e32\u0e27",
      "amphur_name_eng": "Mae Lao",
      "geo_id": "1",
      "province_id": "45"
  },
  {
      "amphur_id": "673",
      "amphur_code": "5717",
      "amphur_name": "\u0e40\u0e27\u0e35\u0e22\u0e07\u0e40\u0e0a\u0e35\u0e22\u0e07\u0e23\u0e38\u0e49\u0e07",
      "amphur_name_eng": "Wiang Chiang Rung",
      "geo_id": "1",
      "province_id": "45"
  },
  {
      "amphur_id": "674",
      "amphur_code": "5718",
      "amphur_name": "\u0e14\u0e2d\u0e22\u0e2b\u0e25\u0e27\u0e07",
      "amphur_name_eng": "Doi Luang",
      "geo_id": "1",
      "province_id": "45"
  },
  {
      "amphur_id": "675",
      "amphur_code": "5801",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e41\u0e21\u0e48\u0e2e\u0e48\u0e2d\u0e07\u0e2a\u0e2d\u0e19",
      "amphur_name_eng": "Mueang Mae Hong Son",
      "geo_id": "1",
      "province_id": "46"
  },
  {
      "amphur_id": "676",
      "amphur_code": "5802",
      "amphur_name": "\u0e02\u0e38\u0e19\u0e22\u0e27\u0e21",
      "amphur_name_eng": "Khun Yuam",
      "geo_id": "1",
      "province_id": "46"
  },
  {
      "amphur_id": "677",
      "amphur_code": "5803",
      "amphur_name": "\u0e1b\u0e32\u0e22",
      "amphur_name_eng": "Pai",
      "geo_id": "1",
      "province_id": "46"
  },
  {
      "amphur_id": "678",
      "amphur_code": "5804",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e2a\u0e30\u0e40\u0e23\u0e35\u0e22\u0e07",
      "amphur_name_eng": "Mae Sariang",
      "geo_id": "1",
      "province_id": "46"
  },
  {
      "amphur_id": "679",
      "amphur_code": "5805",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e25\u0e32\u0e19\u0e49\u0e2d\u0e22",
      "amphur_name_eng": "Mae La Noi",
      "geo_id": "1",
      "province_id": "46"
  },
  {
      "amphur_id": "680",
      "amphur_code": "5806",
      "amphur_name": "\u0e2a\u0e1a\u0e40\u0e21\u0e22",
      "amphur_name_eng": "Sop Moei",
      "geo_id": "1",
      "province_id": "46"
  },
  {
      "amphur_id": "681",
      "amphur_code": "5807",
      "amphur_name": "\u0e1b\u0e32\u0e07\u0e21\u0e30\u0e1c\u0e49\u0e32",
      "amphur_name_eng": "Pang Mapha",
      "geo_id": "1",
      "province_id": "46"
  },
  {
      "amphur_id": "682",
      "amphur_code": "5881",
      "amphur_name": "*\u0e2d.\u0e21\u0e48\u0e27\u0e22\u0e15\u0e48\u0e2d  \u0e08.\u0e41\u0e21\u0e48\u0e2e\u0e48\u0e2d\u0e07\u0e2a\u0e2d\u0e19",
      "amphur_name_eng": "Muen Tor",
      "geo_id": "1",
      "province_id": "46"
  },
  {
      "amphur_id": "683",
      "amphur_code": "6001",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e19\u0e04\u0e23\u0e2a\u0e27\u0e23\u0e23\u0e04\u0e4c",
      "amphur_name_eng": "Mueang Nakhon Sawan",
      "geo_id": "2",
      "province_id": "47"
  },
  {
      "amphur_id": "684",
      "amphur_code": "6002",
      "amphur_name": "\u0e42\u0e01\u0e23\u0e01\u0e1e\u0e23\u0e30",
      "amphur_name_eng": "Krok Phra",
      "geo_id": "2",
      "province_id": "47"
  },
  {
      "amphur_id": "685",
      "amphur_code": "6003",
      "amphur_name": "\u0e0a\u0e38\u0e21\u0e41\u0e2a\u0e07",
      "amphur_name_eng": "Chum Saeng",
      "geo_id": "2",
      "province_id": "47"
  },
  {
      "amphur_id": "686",
      "amphur_code": "6004",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e1a\u0e31\u0e27",
      "amphur_name_eng": "Nong Bua",
      "geo_id": "2",
      "province_id": "47"
  },
  {
      "amphur_id": "687",
      "amphur_code": "6005",
      "amphur_name": "\u0e1a\u0e23\u0e23\u0e1e\u0e15\u0e1e\u0e34\u0e2a\u0e31\u0e22",
      "amphur_name_eng": "Banphot Phisai",
      "geo_id": "2",
      "province_id": "47"
  },
  {
      "amphur_id": "688",
      "amphur_code": "6006",
      "amphur_name": "\u0e40\u0e01\u0e49\u0e32\u0e40\u0e25\u0e35\u0e49\u0e22\u0e27",
      "amphur_name_eng": "Kao Liao",
      "geo_id": "2",
      "province_id": "47"
  },
  {
      "amphur_id": "689",
      "amphur_code": "6007",
      "amphur_name": "\u0e15\u0e32\u0e04\u0e25\u0e35",
      "amphur_name_eng": "Takhli",
      "geo_id": "2",
      "province_id": "47"
  },
  {
      "amphur_id": "690",
      "amphur_code": "6008",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e15\u0e30\u0e42\u0e01",
      "amphur_name_eng": "Takhli",
      "geo_id": "2",
      "province_id": "47"
  },
  {
      "amphur_id": "691",
      "amphur_code": "6009",
      "amphur_name": "\u0e44\u0e1e\u0e28\u0e32\u0e25\u0e35",
      "amphur_name_eng": "Phaisali",
      "geo_id": "2",
      "province_id": "47"
  },
  {
      "amphur_id": "692",
      "amphur_code": "6010",
      "amphur_name": "\u0e1e\u0e22\u0e38\u0e2b\u0e30\u0e04\u0e35\u0e23\u0e35",
      "amphur_name_eng": "Phayuha Khiri",
      "geo_id": "2",
      "province_id": "47"
  },
  {
      "amphur_id": "693",
      "amphur_code": "6011",
      "amphur_name": "\u0e25\u0e32\u0e14\u0e22\u0e32\u0e27",
      "amphur_name_eng": "Phayuha Khiri",
      "geo_id": "2",
      "province_id": "47"
  },
  {
      "amphur_id": "694",
      "amphur_code": "6012",
      "amphur_name": "\u0e15\u0e32\u0e01\u0e1f\u0e49\u0e32",
      "amphur_name_eng": "Tak Fa",
      "geo_id": "2",
      "province_id": "47"
  },
  {
      "amphur_id": "695",
      "amphur_code": "6013",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e27\u0e07\u0e01\u0e4c",
      "amphur_name_eng": "Mae Wong",
      "geo_id": "2",
      "province_id": "47"
  },
  {
      "amphur_id": "696",
      "amphur_code": "6014",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e40\u0e1b\u0e34\u0e19",
      "amphur_name_eng": "Mae Poen",
      "geo_id": "2",
      "province_id": "47"
  },
  {
      "amphur_id": "697",
      "amphur_code": "6015",
      "amphur_name": "\u0e0a\u0e38\u0e21\u0e15\u0e32\u0e1a\u0e07",
      "amphur_name_eng": "Chum Ta Bong",
      "geo_id": "2",
      "province_id": "47"
  },
  {
      "amphur_id": "698",
      "amphur_code": "6051",
      "amphur_name": "\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e2b\u0e49\u0e27\u0e22\u0e19\u0e49\u0e33\u0e2b\u0e2d\u0e21*",
      "amphur_name_eng": "Huen Nam Hom",
      "geo_id": "2",
      "province_id": "47"
  },
  {
      "amphur_id": "699",
      "amphur_code": "6052",
      "amphur_name": "\u0e01\u0e34\u0e48\u0e07\u0e2d\u0e33\u0e40\u0e20\u0e2d\u0e0a\u0e38\u0e21\u0e15\u0e32\u0e1a\u0e07 (\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e0a\u0e38\u0e21\u0e15\u0e32\u0e1a\u0e07)*",
      "amphur_name_eng": "Chum Ta Bong",
      "geo_id": "2",
      "province_id": "47"
  },
  {
      "amphur_id": "700",
      "amphur_code": "6053",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e27\u0e07\u0e01\u0e4c (\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e41\u0e21\u0e48\u0e40\u0e25\u0e48\u0e22\u0e4c)*",
      "amphur_name_eng": "Mea Ley",
      "geo_id": "2",
      "province_id": "47"
  },
  {
      "amphur_id": "701",
      "amphur_code": "6101",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2d\u0e38\u0e17\u0e31\u0e22\u0e18\u0e32\u0e19\u0e35",
      "amphur_name_eng": "Mueang Uthai Thani",
      "geo_id": "2",
      "province_id": "48"
  },
  {
      "amphur_id": "702",
      "amphur_code": "6102",
      "amphur_name": "\u0e17\u0e31\u0e1e\u0e17\u0e31\u0e19",
      "amphur_name_eng": "Thap Than",
      "geo_id": "2",
      "province_id": "48"
  },
  {
      "amphur_id": "703",
      "amphur_code": "6103",
      "amphur_name": "\u0e2a\u0e27\u0e48\u0e32\u0e07\u0e2d\u0e32\u0e23\u0e21\u0e13\u0e4c",
      "amphur_name_eng": "Sawang Arom",
      "geo_id": "2",
      "province_id": "48"
  },
  {
      "amphur_id": "704",
      "amphur_code": "6104",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e09\u0e32\u0e07",
      "amphur_name_eng": "Nong Chang",
      "geo_id": "2",
      "province_id": "48"
  },
  {
      "amphur_id": "705",
      "amphur_code": "6105",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e02\u0e32\u0e2b\u0e22\u0e48\u0e32\u0e07",
      "amphur_name_eng": "Nong Khayang",
      "geo_id": "2",
      "province_id": "48"
  },
  {
      "amphur_id": "706",
      "amphur_code": "6106",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e44\u0e23\u0e48",
      "amphur_name_eng": "Ban Rai",
      "geo_id": "2",
      "province_id": "48"
  },
  {
      "amphur_id": "707",
      "amphur_code": "6107",
      "amphur_name": "\u0e25\u0e32\u0e19\u0e2a\u0e31\u0e01",
      "amphur_name_eng": "Lan Sak",
      "geo_id": "2",
      "province_id": "48"
  },
  {
      "amphur_id": "708",
      "amphur_code": "6108",
      "amphur_name": "\u0e2b\u0e49\u0e27\u0e22\u0e04\u0e15",
      "amphur_name_eng": "Huai Khot",
      "geo_id": "2",
      "province_id": "48"
  },
  {
      "amphur_id": "709",
      "amphur_code": "6201",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e01\u0e33\u0e41\u0e1e\u0e07\u0e40\u0e1e\u0e0a\u0e23",
      "amphur_name_eng": "Mueang Kamphaeng Phet",
      "geo_id": "2",
      "province_id": "49"
  },
  {
      "amphur_id": "710",
      "amphur_code": "6202",
      "amphur_name": "\u0e44\u0e17\u0e23\u0e07\u0e32\u0e21",
      "amphur_name_eng": "Sai Ngam",
      "geo_id": "2",
      "province_id": "49"
  },
  {
      "amphur_id": "711",
      "amphur_code": "6203",
      "amphur_name": "\u0e04\u0e25\u0e2d\u0e07\u0e25\u0e32\u0e19",
      "amphur_name_eng": "Khlong Lan",
      "geo_id": "2",
      "province_id": "49"
  },
  {
      "amphur_id": "712",
      "amphur_code": "6204",
      "amphur_name": "\u0e02\u0e32\u0e13\u0e38\u0e27\u0e23\u0e25\u0e31\u0e01\u0e29\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Khanu Woralaksaburi",
      "geo_id": "2",
      "province_id": "49"
  },
  {
      "amphur_id": "713",
      "amphur_code": "6205",
      "amphur_name": "\u0e04\u0e25\u0e2d\u0e07\u0e02\u0e25\u0e38\u0e07",
      "amphur_name_eng": "Khlong Khlung",
      "geo_id": "2",
      "province_id": "49"
  },
  {
      "amphur_id": "714",
      "amphur_code": "6206",
      "amphur_name": "\u0e1e\u0e23\u0e32\u0e19\u0e01\u0e23\u0e30\u0e15\u0e48\u0e32\u0e22",
      "amphur_name_eng": "Phran Kratai",
      "geo_id": "2",
      "province_id": "49"
  },
  {
      "amphur_id": "715",
      "amphur_code": "6207",
      "amphur_name": "\u0e25\u0e32\u0e19\u0e01\u0e23\u0e30\u0e1a\u0e37\u0e2d",
      "amphur_name_eng": "Lan Krabue",
      "geo_id": "2",
      "province_id": "49"
  },
  {
      "amphur_id": "716",
      "amphur_code": "6208",
      "amphur_name": "\u0e17\u0e23\u0e32\u0e22\u0e17\u0e2d\u0e07\u0e27\u0e31\u0e12\u0e19\u0e32",
      "amphur_name_eng": "Sai Thong Watthana",
      "geo_id": "2",
      "province_id": "49"
  },
  {
      "amphur_id": "717",
      "amphur_code": "6209",
      "amphur_name": "\u0e1b\u0e32\u0e07\u0e28\u0e34\u0e25\u0e32\u0e17\u0e2d\u0e07",
      "amphur_name_eng": "Pang Sila Thong",
      "geo_id": "2",
      "province_id": "49"
  },
  {
      "amphur_id": "718",
      "amphur_code": "6210",
      "amphur_name": "\u0e1a\u0e36\u0e07\u0e2a\u0e32\u0e21\u0e31\u0e04\u0e04\u0e35",
      "amphur_name_eng": "Bueng Samakkhi",
      "geo_id": "2",
      "province_id": "49"
  },
  {
      "amphur_id": "719",
      "amphur_code": "6211",
      "amphur_name": "\u0e42\u0e01\u0e2a\u0e31\u0e21\u0e1e\u0e35\u0e19\u0e04\u0e23",
      "amphur_name_eng": "Kosamphi Nakhon",
      "geo_id": "2",
      "province_id": "49"
  },
  {
      "amphur_id": "720",
      "amphur_code": "6301",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e15\u0e32\u0e01",
      "amphur_name_eng": "Mueang Tak",
      "geo_id": "4",
      "province_id": "50"
  },
  {
      "amphur_id": "721",
      "amphur_code": "6302",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e15\u0e32\u0e01",
      "amphur_name_eng": "Ban Tak",
      "geo_id": "4",
      "province_id": "50"
  },
  {
      "amphur_id": "722",
      "amphur_code": "6303",
      "amphur_name": "\u0e2a\u0e32\u0e21\u0e40\u0e07\u0e32",
      "amphur_name_eng": "Sam Ngao",
      "geo_id": "4",
      "province_id": "50"
  },
  {
      "amphur_id": "723",
      "amphur_code": "6304",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e23\u0e30\u0e21\u0e32\u0e14",
      "amphur_name_eng": "Mae Ramat",
      "geo_id": "4",
      "province_id": "50"
  },
  {
      "amphur_id": "724",
      "amphur_code": "6305",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e2a\u0e2d\u0e07\u0e22\u0e32\u0e07",
      "amphur_name_eng": "Tha Song Yang",
      "geo_id": "4",
      "province_id": "50"
  },
  {
      "amphur_id": "725",
      "amphur_code": "6306",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e2a\u0e2d\u0e14",
      "amphur_name_eng": "Mae Sot",
      "geo_id": "4",
      "province_id": "50"
  },
  {
      "amphur_id": "726",
      "amphur_code": "6307",
      "amphur_name": "\u0e1e\u0e1a\u0e1e\u0e23\u0e30",
      "amphur_name_eng": "Phop Phra",
      "geo_id": "4",
      "province_id": "50"
  },
  {
      "amphur_id": "727",
      "amphur_code": "6308",
      "amphur_name": "\u0e2d\u0e38\u0e49\u0e21\u0e1c\u0e32\u0e07",
      "amphur_name_eng": "Umphang",
      "geo_id": "4",
      "province_id": "50"
  },
  {
      "amphur_id": "728",
      "amphur_code": "6309",
      "amphur_name": "\u0e27\u0e31\u0e07\u0e40\u0e08\u0e49\u0e32",
      "amphur_name_eng": "Wang Chao",
      "geo_id": "4",
      "province_id": "50"
  },
  {
      "amphur_id": "729",
      "amphur_code": "6381",
      "amphur_name": "*\u0e01\u0e34\u0e48\u0e07 \u0e2d.\u0e17\u0e48\u0e32\u0e1b\u0e38\u0e22  \u0e08.\u0e15\u0e32\u0e01",
      "amphur_name_eng": "*King Ta Pui",
      "geo_id": "4",
      "province_id": "50"
  },
  {
      "amphur_id": "730",
      "amphur_code": "6401",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2a\u0e38\u0e42\u0e02\u0e17\u0e31\u0e22",
      "amphur_name_eng": "Mueang Sukhothai",
      "geo_id": "2",
      "province_id": "51"
  },
  {
      "amphur_id": "731",
      "amphur_code": "6402",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e14\u0e48\u0e32\u0e19\u0e25\u0e32\u0e19\u0e2b\u0e2d\u0e22",
      "amphur_name_eng": "Ban Dan Lan Hoi",
      "geo_id": "2",
      "province_id": "51"
  },
  {
      "amphur_id": "732",
      "amphur_code": "6403",
      "amphur_name": "\u0e04\u0e35\u0e23\u0e35\u0e21\u0e32\u0e28",
      "amphur_name_eng": "Khiri Mat",
      "geo_id": "2",
      "province_id": "51"
  },
  {
      "amphur_id": "733",
      "amphur_code": "6404",
      "amphur_name": "\u0e01\u0e07\u0e44\u0e01\u0e23\u0e25\u0e32\u0e28",
      "amphur_name_eng": "Kong Krailat",
      "geo_id": "2",
      "province_id": "51"
  },
  {
      "amphur_id": "734",
      "amphur_code": "6405",
      "amphur_name": "\u0e28\u0e23\u0e35\u0e2a\u0e31\u0e0a\u0e19\u0e32\u0e25\u0e31\u0e22",
      "amphur_name_eng": "Si Satchanalai",
      "geo_id": "2",
      "province_id": "51"
  },
  {
      "amphur_id": "735",
      "amphur_code": "6406",
      "amphur_name": "\u0e28\u0e23\u0e35\u0e2a\u0e33\u0e42\u0e23\u0e07",
      "amphur_name_eng": "Si Samrong",
      "geo_id": "2",
      "province_id": "51"
  },
  {
      "amphur_id": "736",
      "amphur_code": "6407",
      "amphur_name": "\u0e2a\u0e27\u0e23\u0e23\u0e04\u0e42\u0e25\u0e01",
      "amphur_name_eng": "Sawankhalok",
      "geo_id": "2",
      "province_id": "51"
  },
  {
      "amphur_id": "737",
      "amphur_code": "6408",
      "amphur_name": "\u0e28\u0e23\u0e35\u0e19\u0e04\u0e23",
      "amphur_name_eng": "Si Nakhon",
      "geo_id": "2",
      "province_id": "51"
  },
  {
      "amphur_id": "738",
      "amphur_code": "6409",
      "amphur_name": "\u0e17\u0e38\u0e48\u0e07\u0e40\u0e2a\u0e25\u0e35\u0e48\u0e22\u0e21",
      "amphur_name_eng": "Thung Saliam",
      "geo_id": "2",
      "province_id": "51"
  },
  {
      "amphur_id": "739",
      "amphur_code": "6501",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e1e\u0e34\u0e29\u0e13\u0e38\u0e42\u0e25\u0e01",
      "amphur_name_eng": "Mueang Phitsanulok",
      "geo_id": "2",
      "province_id": "52"
  },
  {
      "amphur_id": "740",
      "amphur_code": "6502",
      "amphur_name": "\u0e19\u0e04\u0e23\u0e44\u0e17\u0e22",
      "amphur_name_eng": "Nakhon Thai",
      "geo_id": "2",
      "province_id": "52"
  },
  {
      "amphur_id": "741",
      "amphur_code": "6503",
      "amphur_name": "\u0e0a\u0e32\u0e15\u0e34\u0e15\u0e23\u0e30\u0e01\u0e32\u0e23",
      "amphur_name_eng": "Chat Trakan",
      "geo_id": "2",
      "province_id": "52"
  },
  {
      "amphur_id": "742",
      "amphur_code": "6504",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e23\u0e30\u0e01\u0e33",
      "amphur_name_eng": "Bang Rakam",
      "geo_id": "2",
      "province_id": "52"
  },
  {
      "amphur_id": "743",
      "amphur_code": "6505",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e01\u0e23\u0e30\u0e17\u0e38\u0e48\u0e21",
      "amphur_name_eng": "Bang Krathum",
      "geo_id": "2",
      "province_id": "52"
  },
  {
      "amphur_id": "744",
      "amphur_code": "6506",
      "amphur_name": "\u0e1e\u0e23\u0e2b\u0e21\u0e1e\u0e34\u0e23\u0e32\u0e21",
      "amphur_name_eng": "Phrom Phiram",
      "geo_id": "2",
      "province_id": "52"
  },
  {
      "amphur_id": "745",
      "amphur_code": "6507",
      "amphur_name": "\u0e27\u0e31\u0e14\u0e42\u0e1a\u0e2a\u0e16\u0e4c",
      "amphur_name_eng": "Wat Bot",
      "geo_id": "2",
      "province_id": "52"
  },
  {
      "amphur_id": "746",
      "amphur_code": "6508",
      "amphur_name": "\u0e27\u0e31\u0e07\u0e17\u0e2d\u0e07",
      "amphur_name_eng": "Wang Thong",
      "geo_id": "2",
      "province_id": "52"
  },
  {
      "amphur_id": "747",
      "amphur_code": "6509",
      "amphur_name": "\u0e40\u0e19\u0e34\u0e19\u0e21\u0e30\u0e1b\u0e23\u0e32\u0e07",
      "amphur_name_eng": "Noen Maprang",
      "geo_id": "2",
      "province_id": "52"
  },
  {
      "amphur_id": "748",
      "amphur_code": "6601",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e1e\u0e34\u0e08\u0e34\u0e15\u0e23",
      "amphur_name_eng": "Mueang Phichit",
      "geo_id": "2",
      "province_id": "53"
  },
  {
      "amphur_id": "749",
      "amphur_code": "6602",
      "amphur_name": "\u0e27\u0e31\u0e07\u0e17\u0e23\u0e32\u0e22\u0e1e\u0e39\u0e19",
      "amphur_name_eng": "Wang Sai Phun",
      "geo_id": "2",
      "province_id": "53"
  },
  {
      "amphur_id": "750",
      "amphur_code": "6603",
      "amphur_name": "\u0e42\u0e1e\u0e18\u0e34\u0e4c\u0e1b\u0e23\u0e30\u0e17\u0e31\u0e1a\u0e0a\u0e49\u0e32\u0e07",
      "amphur_name_eng": "Pho Prathap Chang",
      "geo_id": "2",
      "province_id": "53"
  },
  {
      "amphur_id": "751",
      "amphur_code": "6604",
      "amphur_name": "\u0e15\u0e30\u0e1e\u0e32\u0e19\u0e2b\u0e34\u0e19",
      "amphur_name_eng": "Taphan Hin",
      "geo_id": "2",
      "province_id": "53"
  },
  {
      "amphur_id": "752",
      "amphur_code": "6605",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e21\u0e39\u0e25\u0e19\u0e32\u0e01",
      "amphur_name_eng": "Bang Mun Nak",
      "geo_id": "2",
      "province_id": "53"
  },
  {
      "amphur_id": "753",
      "amphur_code": "6606",
      "amphur_name": "\u0e42\u0e1e\u0e17\u0e30\u0e40\u0e25",
      "amphur_name_eng": "Pho Thale",
      "geo_id": "2",
      "province_id": "53"
  },
  {
      "amphur_id": "754",
      "amphur_code": "6607",
      "amphur_name": "\u0e2a\u0e32\u0e21\u0e07\u0e48\u0e32\u0e21",
      "amphur_name_eng": "Sam Ngam",
      "geo_id": "2",
      "province_id": "53"
  },
  {
      "amphur_id": "755",
      "amphur_code": "6608",
      "amphur_name": "\u0e17\u0e31\u0e1a\u0e04\u0e25\u0e49\u0e2d",
      "amphur_name_eng": "Tap Khlo",
      "geo_id": "2",
      "province_id": "53"
  },
  {
      "amphur_id": "756",
      "amphur_code": "6609",
      "amphur_name": "\u0e2a\u0e32\u0e01\u0e40\u0e2b\u0e25\u0e47\u0e01",
      "amphur_name_eng": "Sak Lek",
      "geo_id": "2",
      "province_id": "53"
  },
  {
      "amphur_id": "757",
      "amphur_code": "6610",
      "amphur_name": "\u0e1a\u0e36\u0e07\u0e19\u0e32\u0e23\u0e32\u0e07",
      "amphur_name_eng": "Bueng Na Rang",
      "geo_id": "2",
      "province_id": "53"
  },
  {
      "amphur_id": "758",
      "amphur_code": "6611",
      "amphur_name": "\u0e14\u0e07\u0e40\u0e08\u0e23\u0e34\u0e0d",
      "amphur_name_eng": "Dong Charoen",
      "geo_id": "2",
      "province_id": "53"
  },
  {
      "amphur_id": "759",
      "amphur_code": "6612",
      "amphur_name": "\u0e27\u0e0a\u0e34\u0e23\u0e1a\u0e32\u0e23\u0e21\u0e35",
      "amphur_name_eng": "Wachirabarami",
      "geo_id": "2",
      "province_id": "53"
  },
  {
      "amphur_id": "760",
      "amphur_code": "6701",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e40\u0e1e\u0e0a\u0e23\u0e1a\u0e39\u0e23\u0e13\u0e4c",
      "amphur_name_eng": "Mueang Phetchabun",
      "geo_id": "2",
      "province_id": "54"
  },
  {
      "amphur_id": "761",
      "amphur_code": "6702",
      "amphur_name": "\u0e0a\u0e19\u0e41\u0e14\u0e19",
      "amphur_name_eng": "Chon Daen",
      "geo_id": "2",
      "province_id": "54"
  },
  {
      "amphur_id": "762",
      "amphur_code": "6703",
      "amphur_name": "\u0e2b\u0e25\u0e48\u0e21\u0e2a\u0e31\u0e01",
      "amphur_name_eng": "Lom Sak",
      "geo_id": "2",
      "province_id": "54"
  },
  {
      "amphur_id": "763",
      "amphur_code": "6704",
      "amphur_name": "\u0e2b\u0e25\u0e48\u0e21\u0e40\u0e01\u0e48\u0e32",
      "amphur_name_eng": "Lom Kao",
      "geo_id": "2",
      "province_id": "54"
  },
  {
      "amphur_id": "764",
      "amphur_code": "6705",
      "amphur_name": "\u0e27\u0e34\u0e40\u0e0a\u0e35\u0e22\u0e23\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Wichian Buri",
      "geo_id": "2",
      "province_id": "54"
  },
  {
      "amphur_id": "765",
      "amphur_code": "6706",
      "amphur_name": "\u0e28\u0e23\u0e35\u0e40\u0e17\u0e1e",
      "amphur_name_eng": "Si Thep",
      "geo_id": "2",
      "province_id": "54"
  },
  {
      "amphur_id": "766",
      "amphur_code": "6707",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e44\u0e1c\u0e48",
      "amphur_name_eng": "Nong Phai",
      "geo_id": "2",
      "province_id": "54"
  },
  {
      "amphur_id": "767",
      "amphur_code": "6708",
      "amphur_name": "\u0e1a\u0e36\u0e07\u0e2a\u0e32\u0e21\u0e1e\u0e31\u0e19",
      "amphur_name_eng": "Bueng Sam Phan",
      "geo_id": "2",
      "province_id": "54"
  },
  {
      "amphur_id": "768",
      "amphur_code": "6709",
      "amphur_name": "\u0e19\u0e49\u0e33\u0e2b\u0e19\u0e32\u0e27",
      "amphur_name_eng": "Nam Nao",
      "geo_id": "2",
      "province_id": "54"
  },
  {
      "amphur_id": "769",
      "amphur_code": "6710",
      "amphur_name": "\u0e27\u0e31\u0e07\u0e42\u0e1b\u0e48\u0e07",
      "amphur_name_eng": "Wang Pong",
      "geo_id": "2",
      "province_id": "54"
  },
  {
      "amphur_id": "770",
      "amphur_code": "6711",
      "amphur_name": "\u0e40\u0e02\u0e32\u0e04\u0e49\u0e2d",
      "amphur_name_eng": "Khao Kho",
      "geo_id": "2",
      "province_id": "54"
  },
  {
      "amphur_id": "771",
      "amphur_code": "7001",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e23\u0e32\u0e0a\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Mueang Ratchaburi",
      "geo_id": "4",
      "province_id": "55"
  },
  {
      "amphur_id": "772",
      "amphur_code": "7002",
      "amphur_name": "\u0e08\u0e2d\u0e21\u0e1a\u0e36\u0e07",
      "amphur_name_eng": "Chom Bueng",
      "geo_id": "4",
      "province_id": "55"
  },
  {
      "amphur_id": "773",
      "amphur_code": "7003",
      "amphur_name": "\u0e2a\u0e27\u0e19\u0e1c\u0e36\u0e49\u0e07",
      "amphur_name_eng": "Suan Phueng",
      "geo_id": "4",
      "province_id": "55"
  },
  {
      "amphur_id": "774",
      "amphur_code": "7004",
      "amphur_name": "\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e2a\u0e30\u0e14\u0e27\u0e01",
      "amphur_name_eng": "Damnoen Saduak",
      "geo_id": "4",
      "province_id": "55"
  },
  {
      "amphur_id": "775",
      "amphur_code": "7005",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e42\u0e1b\u0e48\u0e07",
      "amphur_name_eng": "Ban Pong",
      "geo_id": "4",
      "province_id": "55"
  },
  {
      "amphur_id": "776",
      "amphur_code": "7006",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e41\u0e1e",
      "amphur_name_eng": "Bang Phae",
      "geo_id": "4",
      "province_id": "55"
  },
  {
      "amphur_id": "777",
      "amphur_code": "7007",
      "amphur_name": "\u0e42\u0e1e\u0e18\u0e32\u0e23\u0e32\u0e21",
      "amphur_name_eng": "Photharam",
      "geo_id": "4",
      "province_id": "55"
  },
  {
      "amphur_id": "778",
      "amphur_code": "7008",
      "amphur_name": "\u0e1b\u0e32\u0e01\u0e17\u0e48\u0e2d",
      "amphur_name_eng": "Pak Tho",
      "geo_id": "4",
      "province_id": "55"
  },
  {
      "amphur_id": "779",
      "amphur_code": "7009",
      "amphur_name": "\u0e27\u0e31\u0e14\u0e40\u0e1e\u0e25\u0e07",
      "amphur_name_eng": "Wat Phleng",
      "geo_id": "4",
      "province_id": "55"
  },
  {
      "amphur_id": "780",
      "amphur_code": "7010",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e04\u0e32",
      "amphur_name_eng": "Ban Kha",
      "geo_id": "4",
      "province_id": "55"
  },
  {
      "amphur_id": "781",
      "amphur_code": "7074",
      "amphur_name": "\u0e17\u0e49\u0e2d\u0e07\u0e16\u0e34\u0e48\u0e19\u0e40\u0e17\u0e28\u0e1a\u0e32\u0e25\u0e15\u0e33\u0e1a\u0e25\u0e1a\u0e49\u0e32\u0e19\u0e06\u0e49\u0e2d\u0e07",
      "amphur_name_eng": "Tet Saban Ban Kong",
      "geo_id": "4",
      "province_id": "55"
  },
  {
      "amphur_id": "782",
      "amphur_code": "7101",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e01\u0e32\u0e0d\u0e08\u0e19\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Mueang Kanchanaburi",
      "geo_id": "4",
      "province_id": "56"
  },
  {
      "amphur_id": "783",
      "amphur_code": "7102",
      "amphur_name": "\u0e44\u0e17\u0e23\u0e42\u0e22\u0e04",
      "amphur_name_eng": "Sai Yok",
      "geo_id": "4",
      "province_id": "56"
  },
  {
      "amphur_id": "784",
      "amphur_code": "7103",
      "amphur_name": "\u0e1a\u0e48\u0e2d\u0e1e\u0e25\u0e2d\u0e22",
      "amphur_name_eng": "Bo Phloi",
      "geo_id": "4",
      "province_id": "56"
  },
  {
      "amphur_id": "785",
      "amphur_code": "7104",
      "amphur_name": "\u0e28\u0e23\u0e35\u0e2a\u0e27\u0e31\u0e2a\u0e14\u0e34\u0e4c",
      "amphur_name_eng": "Si Sawat",
      "geo_id": "4",
      "province_id": "56"
  },
  {
      "amphur_id": "786",
      "amphur_code": "7105",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e21\u0e30\u0e01\u0e32",
      "amphur_name_eng": "Tha Maka",
      "geo_id": "4",
      "province_id": "56"
  },
  {
      "amphur_id": "787",
      "amphur_code": "7106",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e21\u0e48\u0e27\u0e07",
      "amphur_name_eng": "Tha Muang",
      "geo_id": "4",
      "province_id": "56"
  },
  {
      "amphur_id": "788",
      "amphur_code": "7107",
      "amphur_name": "\u0e17\u0e2d\u0e07\u0e1c\u0e32\u0e20\u0e39\u0e21\u0e34",
      "amphur_name_eng": "Pha Phum",
      "geo_id": "4",
      "province_id": "56"
  },
  {
      "amphur_id": "789",
      "amphur_code": "7108",
      "amphur_name": "\u0e2a\u0e31\u0e07\u0e02\u0e25\u0e30\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Sangkhla Buri",
      "geo_id": "4",
      "province_id": "56"
  },
  {
      "amphur_id": "790",
      "amphur_code": "7109",
      "amphur_name": "\u0e1e\u0e19\u0e21\u0e17\u0e27\u0e19",
      "amphur_name_eng": "Phanom Thuan",
      "geo_id": "4",
      "province_id": "56"
  },
  {
      "amphur_id": "791",
      "amphur_code": "7110",
      "amphur_name": "\u0e40\u0e25\u0e32\u0e02\u0e27\u0e31\u0e0d",
      "amphur_name_eng": "Lao Khwan",
      "geo_id": "4",
      "province_id": "56"
  },
  {
      "amphur_id": "792",
      "amphur_code": "7111",
      "amphur_name": "\u0e14\u0e48\u0e32\u0e19\u0e21\u0e30\u0e02\u0e32\u0e21\u0e40\u0e15\u0e35\u0e49\u0e22",
      "amphur_name_eng": "Dan Makham Tia",
      "geo_id": "4",
      "province_id": "56"
  },
  {
      "amphur_id": "793",
      "amphur_code": "7112",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e1b\u0e23\u0e37\u0e2d",
      "amphur_name_eng": "Nong Prue",
      "geo_id": "4",
      "province_id": "56"
  },
  {
      "amphur_id": "794",
      "amphur_code": "7113",
      "amphur_name": "\u0e2b\u0e49\u0e27\u0e22\u0e01\u0e23\u0e30\u0e40\u0e08\u0e32",
      "amphur_name_eng": "Huai Krachao",
      "geo_id": "4",
      "province_id": "56"
  },
  {
      "amphur_id": "795",
      "amphur_code": "7151",
      "amphur_name": "\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e17\u0e48\u0e32\u0e01\u0e23\u0e30\u0e14\u0e32\u0e19*",
      "amphur_name_eng": "Tha Kra Dan",
      "geo_id": "4",
      "province_id": "56"
  },
  {
      "amphur_id": "796",
      "amphur_code": "7181",
      "amphur_name": "*\u0e1a\u0e49\u0e32\u0e19\u0e17\u0e27\u0e19  \u0e08.\u0e01\u0e32\u0e0d\u0e08\u0e19\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "*Ban Tuan",
      "geo_id": "4",
      "province_id": "56"
  },
  {
      "amphur_id": "797",
      "amphur_code": "7201",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2a\u0e38\u0e1e\u0e23\u0e23\u0e13\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Mueang Suphan Buri",
      "geo_id": "2",
      "province_id": "57"
  },
  {
      "amphur_id": "798",
      "amphur_code": "7202",
      "amphur_name": "\u0e40\u0e14\u0e34\u0e21\u0e1a\u0e32\u0e07\u0e19\u0e32\u0e07\u0e1a\u0e27\u0e0a",
      "amphur_name_eng": "Doem Bang Nang Buat",
      "geo_id": "2",
      "province_id": "57"
  },
  {
      "amphur_id": "799",
      "amphur_code": "7203",
      "amphur_name": "\u0e14\u0e48\u0e32\u0e19\u0e0a\u0e49\u0e32\u0e07",
      "amphur_name_eng": "Dan Chang",
      "geo_id": "2",
      "province_id": "57"
  },
  {
      "amphur_id": "800",
      "amphur_code": "7204",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e1b\u0e25\u0e32\u0e21\u0e49\u0e32",
      "amphur_name_eng": "Bang Pla Ma",
      "geo_id": "2",
      "province_id": "57"
  },
  {
      "amphur_id": "801",
      "amphur_code": "7205",
      "amphur_name": "\u0e28\u0e23\u0e35\u0e1b\u0e23\u0e30\u0e08\u0e31\u0e19\u0e15\u0e4c",
      "amphur_name_eng": "Si Prachan",
      "geo_id": "2",
      "province_id": "57"
  },
  {
      "amphur_id": "802",
      "amphur_code": "7206",
      "amphur_name": "\u0e14\u0e2d\u0e19\u0e40\u0e08\u0e14\u0e35\u0e22\u0e4c",
      "amphur_name_eng": "Don Chedi",
      "geo_id": "2",
      "province_id": "57"
  },
  {
      "amphur_id": "803",
      "amphur_code": "7207",
      "amphur_name": "\u0e2a\u0e2d\u0e07\u0e1e\u0e35\u0e48\u0e19\u0e49\u0e2d\u0e07",
      "amphur_name_eng": "Song Phi Nong",
      "geo_id": "2",
      "province_id": "57"
  },
  {
      "amphur_id": "804",
      "amphur_code": "7208",
      "amphur_name": "\u0e2a\u0e32\u0e21\u0e0a\u0e38\u0e01",
      "amphur_name_eng": "Sam Chuk",
      "geo_id": "2",
      "province_id": "57"
  },
  {
      "amphur_id": "805",
      "amphur_code": "7209",
      "amphur_name": "\u0e2d\u0e39\u0e48\u0e17\u0e2d\u0e07",
      "amphur_name_eng": "U Thong",
      "geo_id": "2",
      "province_id": "57"
  },
  {
      "amphur_id": "806",
      "amphur_code": "7210",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e2b\u0e0d\u0e49\u0e32\u0e44\u0e0b",
      "amphur_name_eng": "Nong Ya Sai",
      "geo_id": "2",
      "province_id": "57"
  },
  {
      "amphur_id": "807",
      "amphur_code": "7301",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e19\u0e04\u0e23\u0e1b\u0e10\u0e21",
      "amphur_name_eng": "Mueang Nakhon Pathom",
      "geo_id": "2",
      "province_id": "58"
  },
  {
      "amphur_id": "808",
      "amphur_code": "7302",
      "amphur_name": "\u0e01\u0e33\u0e41\u0e1e\u0e07\u0e41\u0e2a\u0e19",
      "amphur_name_eng": "Kamphaeng Saen",
      "geo_id": "2",
      "province_id": "58"
  },
  {
      "amphur_id": "809",
      "amphur_code": "7303",
      "amphur_name": "\u0e19\u0e04\u0e23\u0e0a\u0e31\u0e22\u0e28\u0e23\u0e35",
      "amphur_name_eng": "Nakhon Chai Si",
      "geo_id": "2",
      "province_id": "58"
  },
  {
      "amphur_id": "810",
      "amphur_code": "7304",
      "amphur_name": "\u0e14\u0e2d\u0e19\u0e15\u0e39\u0e21",
      "amphur_name_eng": "Don Tum",
      "geo_id": "2",
      "province_id": "58"
  },
  {
      "amphur_id": "811",
      "amphur_code": "7305",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e40\u0e25\u0e19",
      "amphur_name_eng": "Bang Len",
      "geo_id": "2",
      "province_id": "58"
  },
  {
      "amphur_id": "812",
      "amphur_code": "7306",
      "amphur_name": "\u0e2a\u0e32\u0e21\u0e1e\u0e23\u0e32\u0e19",
      "amphur_name_eng": "Sam Phran",
      "geo_id": "2",
      "province_id": "58"
  },
  {
      "amphur_id": "813",
      "amphur_code": "7307",
      "amphur_name": "\u0e1e\u0e38\u0e17\u0e18\u0e21\u0e13\u0e11\u0e25",
      "amphur_name_eng": "Phutthamonthon",
      "geo_id": "2",
      "province_id": "58"
  },
  {
      "amphur_id": "814",
      "amphur_code": "7401",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2a\u0e21\u0e38\u0e17\u0e23\u0e2a\u0e32\u0e04\u0e23",
      "amphur_name_eng": "Mueang Samut Sakhon",
      "geo_id": "2",
      "province_id": "59"
  },
  {
      "amphur_id": "815",
      "amphur_code": "7402",
      "amphur_name": "\u0e01\u0e23\u0e30\u0e17\u0e38\u0e48\u0e21\u0e41\u0e1a\u0e19",
      "amphur_name_eng": "Krathum Baen",
      "geo_id": "2",
      "province_id": "59"
  },
  {
      "amphur_id": "816",
      "amphur_code": "7403",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e41\u0e1e\u0e49\u0e27",
      "amphur_name_eng": "Ban Phaeo",
      "geo_id": "2",
      "province_id": "59"
  },
  {
      "amphur_id": "817",
      "amphur_code": "7501",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2a\u0e21\u0e38\u0e17\u0e23\u0e2a\u0e07\u0e04\u0e23\u0e32\u0e21",
      "amphur_name_eng": "Mueang Samut Songkhram",
      "geo_id": "2",
      "province_id": "60"
  },
  {
      "amphur_id": "818",
      "amphur_code": "7502",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e04\u0e19\u0e17\u0e35",
      "amphur_name_eng": "Bang Khonthi",
      "geo_id": "2",
      "province_id": "60"
  },
  {
      "amphur_id": "819",
      "amphur_code": "7503",
      "amphur_name": "\u0e2d\u0e31\u0e21\u0e1e\u0e27\u0e32",
      "amphur_name_eng": "Amphawa",
      "geo_id": "2",
      "province_id": "60"
  },
  {
      "amphur_id": "820",
      "amphur_code": "7601",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e40\u0e1e\u0e0a\u0e23\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Mueang Phetchaburi",
      "geo_id": "4",
      "province_id": "61"
  },
  {
      "amphur_id": "821",
      "amphur_code": "7602",
      "amphur_name": "\u0e40\u0e02\u0e32\u0e22\u0e49\u0e2d\u0e22",
      "amphur_name_eng": "Khao Yoi",
      "geo_id": "4",
      "province_id": "61"
  },
  {
      "amphur_id": "822",
      "amphur_code": "7603",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e2b\u0e0d\u0e49\u0e32\u0e1b\u0e25\u0e49\u0e2d\u0e07",
      "amphur_name_eng": "Nong Ya Plong",
      "geo_id": "4",
      "province_id": "61"
  },
  {
      "amphur_id": "823",
      "amphur_code": "7604",
      "amphur_name": "\u0e0a\u0e30\u0e2d\u0e33",
      "amphur_name_eng": "Cha-am",
      "geo_id": "4",
      "province_id": "61"
  },
  {
      "amphur_id": "824",
      "amphur_code": "7605",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e22\u0e32\u0e07",
      "amphur_name_eng": "Tha Yang",
      "geo_id": "4",
      "province_id": "61"
  },
  {
      "amphur_id": "825",
      "amphur_code": "7606",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e25\u0e32\u0e14",
      "amphur_name_eng": "Ban Lat",
      "geo_id": "4",
      "province_id": "61"
  },
  {
      "amphur_id": "826",
      "amphur_code": "7607",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e41\u0e2b\u0e25\u0e21",
      "amphur_name_eng": "Ban Laem",
      "geo_id": "4",
      "province_id": "61"
  },
  {
      "amphur_id": "827",
      "amphur_code": "7608",
      "amphur_name": "\u0e41\u0e01\u0e48\u0e07\u0e01\u0e23\u0e30\u0e08\u0e32\u0e19",
      "amphur_name_eng": "Kaeng Krachan",
      "geo_id": "4",
      "province_id": "61"
  },
  {
      "amphur_id": "828",
      "amphur_code": "7701",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e1b\u0e23\u0e30\u0e08\u0e27\u0e1a\u0e04\u0e35\u0e23\u0e35\u0e02\u0e31\u0e19\u0e18\u0e4c",
      "amphur_name_eng": "Mueang Prachuap Khiri Khan",
      "geo_id": "4",
      "province_id": "62"
  },
  {
      "amphur_id": "829",
      "amphur_code": "7702",
      "amphur_name": "\u0e01\u0e38\u0e22\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Kui Buri",
      "geo_id": "4",
      "province_id": "62"
  },
  {
      "amphur_id": "830",
      "amphur_code": "7703",
      "amphur_name": "\u0e17\u0e31\u0e1a\u0e2a\u0e30\u0e41\u0e01",
      "amphur_name_eng": "Thap Sakae",
      "geo_id": "4",
      "province_id": "62"
  },
  {
      "amphur_id": "831",
      "amphur_code": "7704",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e2a\u0e30\u0e1e\u0e32\u0e19",
      "amphur_name_eng": "Bang Saphan",
      "geo_id": "4",
      "province_id": "62"
  },
  {
      "amphur_id": "832",
      "amphur_code": "7705",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e2a\u0e30\u0e1e\u0e32\u0e19\u0e19\u0e49\u0e2d\u0e22",
      "amphur_name_eng": "Bang Saphan Noi",
      "geo_id": "4",
      "province_id": "62"
  },
  {
      "amphur_id": "833",
      "amphur_code": "7706",
      "amphur_name": "\u0e1b\u0e23\u0e32\u0e13\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Pran Buri",
      "geo_id": "4",
      "province_id": "62"
  },
  {
      "amphur_id": "834",
      "amphur_code": "7707",
      "amphur_name": "\u0e2b\u0e31\u0e27\u0e2b\u0e34\u0e19",
      "amphur_name_eng": "Hua Hin",
      "geo_id": "4",
      "province_id": "62"
  },
  {
      "amphur_id": "835",
      "amphur_code": "7708",
      "amphur_name": "\u0e2a\u0e32\u0e21\u0e23\u0e49\u0e2d\u0e22\u0e22\u0e2d\u0e14",
      "amphur_name_eng": "Sam Roi Yot",
      "geo_id": "4",
      "province_id": "62"
  },
  {
      "amphur_id": "836",
      "amphur_code": "8001",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e19\u0e04\u0e23\u0e28\u0e23\u0e35\u0e18\u0e23\u0e23\u0e21\u0e23\u0e32\u0e0a",
      "amphur_name_eng": "Mueang Nakhon Si Thammarat",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "837",
      "amphur_code": "8002",
      "amphur_name": "\u0e1e\u0e23\u0e2b\u0e21\u0e04\u0e35\u0e23\u0e35",
      "amphur_name_eng": "Phrom Khiri",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "838",
      "amphur_code": "8003",
      "amphur_name": "\u0e25\u0e32\u0e19\u0e2a\u0e01\u0e32",
      "amphur_name_eng": "Lan Saka",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "839",
      "amphur_code": "8004",
      "amphur_name": "\u0e09\u0e27\u0e32\u0e07",
      "amphur_name_eng": "Chawang",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "840",
      "amphur_code": "8005",
      "amphur_name": "\u0e1e\u0e34\u0e1b\u0e39\u0e19",
      "amphur_name_eng": "Phipun",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "841",
      "amphur_code": "8006",
      "amphur_name": "\u0e40\u0e0a\u0e35\u0e22\u0e23\u0e43\u0e2b\u0e0d\u0e48",
      "amphur_name_eng": "Chian Yai",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "842",
      "amphur_code": "8007",
      "amphur_name": "\u0e0a\u0e30\u0e2d\u0e27\u0e14",
      "amphur_name_eng": "Cha-uat",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "843",
      "amphur_code": "8008",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e28\u0e32\u0e25\u0e32",
      "amphur_name_eng": "Tha Sala",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "844",
      "amphur_code": "8009",
      "amphur_name": "\u0e17\u0e38\u0e48\u0e07\u0e2a\u0e07",
      "amphur_name_eng": "Thung Song",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "845",
      "amphur_code": "8010",
      "amphur_name": "\u0e19\u0e32\u0e1a\u0e2d\u0e19",
      "amphur_name_eng": "Na Bon",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "846",
      "amphur_code": "8011",
      "amphur_name": "\u0e17\u0e38\u0e48\u0e07\u0e43\u0e2b\u0e0d\u0e48",
      "amphur_name_eng": "Thung Yai",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "847",
      "amphur_code": "8012",
      "amphur_name": "\u0e1b\u0e32\u0e01\u0e1e\u0e19\u0e31\u0e07",
      "amphur_name_eng": "Pak Phanang",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "848",
      "amphur_code": "8013",
      "amphur_name": "\u0e23\u0e48\u0e2d\u0e19\u0e1e\u0e34\u0e1a\u0e39\u0e25\u0e22\u0e4c",
      "amphur_name_eng": "Ron Phibun",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "849",
      "amphur_code": "8014",
      "amphur_name": "\u0e2a\u0e34\u0e0a\u0e25",
      "amphur_name_eng": "Sichon",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "850",
      "amphur_code": "8015",
      "amphur_name": "\u0e02\u0e19\u0e2d\u0e21",
      "amphur_name_eng": "Khanom",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "851",
      "amphur_code": "8016",
      "amphur_name": "\u0e2b\u0e31\u0e27\u0e44\u0e17\u0e23",
      "amphur_name_eng": "Hua Sai",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "852",
      "amphur_code": "8017",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e02\u0e31\u0e19",
      "amphur_name_eng": "Bang Khan",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "853",
      "amphur_code": "8018",
      "amphur_name": "\u0e16\u0e49\u0e33\u0e1e\u0e23\u0e23\u0e13\u0e23\u0e32",
      "amphur_name_eng": "Tham Phannara",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "854",
      "amphur_code": "8019",
      "amphur_name": "\u0e08\u0e38\u0e2c\u0e32\u0e20\u0e23\u0e13\u0e4c",
      "amphur_name_eng": "Chulabhorn",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "855",
      "amphur_code": "8020",
      "amphur_name": "\u0e1e\u0e23\u0e30\u0e1e\u0e23\u0e2b\u0e21",
      "amphur_name_eng": "Phra Phrom",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "856",
      "amphur_code": "8021",
      "amphur_name": "\u0e19\u0e1a\u0e1e\u0e34\u0e15\u0e33",
      "amphur_name_eng": "Nopphitam",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "857",
      "amphur_code": "8022",
      "amphur_name": "\u0e0a\u0e49\u0e32\u0e07\u0e01\u0e25\u0e32\u0e07",
      "amphur_name_eng": "Chang Klang",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "858",
      "amphur_code": "8023",
      "amphur_name": "\u0e40\u0e09\u0e25\u0e34\u0e21\u0e1e\u0e23\u0e30\u0e40\u0e01\u0e35\u0e22\u0e23\u0e15\u0e34",
      "amphur_name_eng": "Chaloem Phra Kiat",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "859",
      "amphur_code": "8051",
      "amphur_name": "\u0e40\u0e0a\u0e35\u0e22\u0e23\u0e43\u0e2b\u0e0d\u0e48 (\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e40\u0e2a\u0e37\u0e2d\u0e2b\u0e36\u0e07)*",
      "amphur_name_eng": "Chian Yai*",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "860",
      "amphur_code": "8052",
      "amphur_name": "\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e2a\u0e27\u0e19\u0e2b\u0e25\u0e27\u0e07**",
      "amphur_name_eng": "Suan Luang",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "861",
      "amphur_code": "8053",
      "amphur_name": "\u0e23\u0e48\u0e2d\u0e19\u0e1e\u0e34\u0e1a\u0e39\u0e25\u0e22\u0e4c (\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e2b\u0e34\u0e19\u0e15\u0e01)*",
      "amphur_name_eng": "Ron Phibun",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "862",
      "amphur_code": "8054",
      "amphur_name": "\u0e2b\u0e31\u0e27\u0e44\u0e17\u0e23 (\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e04\u0e27\u0e19\u0e0a\u0e30\u0e25\u0e34\u0e01)*",
      "amphur_name_eng": "Hua Sai",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "863",
      "amphur_code": "8055",
      "amphur_name": "\u0e17\u0e38\u0e48\u0e07\u0e2a\u0e07 (\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e01\u0e30\u0e1b\u0e32\u0e07)*",
      "amphur_name_eng": "Thung Song",
      "geo_id": "6",
      "province_id": "63"
  },
  {
      "amphur_id": "864",
      "amphur_code": "8101",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e01\u0e23\u0e30\u0e1a\u0e35\u0e48",
      "amphur_name_eng": "Mueang Krabi",
      "geo_id": "6",
      "province_id": "64"
  },
  {
      "amphur_id": "865",
      "amphur_code": "8102",
      "amphur_name": "\u0e40\u0e02\u0e32\u0e1e\u0e19\u0e21",
      "amphur_name_eng": "Khao Phanom",
      "geo_id": "6",
      "province_id": "64"
  },
  {
      "amphur_id": "866",
      "amphur_code": "8103",
      "amphur_name": "\u0e40\u0e01\u0e32\u0e30\u0e25\u0e31\u0e19\u0e15\u0e32",
      "amphur_name_eng": "Ko Lanta",
      "geo_id": "6",
      "province_id": "64"
  },
  {
      "amphur_id": "867",
      "amphur_code": "8104",
      "amphur_name": "\u0e04\u0e25\u0e2d\u0e07\u0e17\u0e48\u0e2d\u0e21",
      "amphur_name_eng": "Khlong Thom",
      "geo_id": "6",
      "province_id": "64"
  },
  {
      "amphur_id": "868",
      "amphur_code": "8105",
      "amphur_name": "\u0e2d\u0e48\u0e32\u0e27\u0e25\u0e36\u0e01",
      "amphur_name_eng": "Ao Luek",
      "geo_id": "6",
      "province_id": "64"
  },
  {
      "amphur_id": "869",
      "amphur_code": "8106",
      "amphur_name": "\u0e1b\u0e25\u0e32\u0e22\u0e1e\u0e23\u0e30\u0e22\u0e32",
      "amphur_name_eng": "Plai Phraya",
      "geo_id": "6",
      "province_id": "64"
  },
  {
      "amphur_id": "870",
      "amphur_code": "8107",
      "amphur_name": "\u0e25\u0e33\u0e17\u0e31\u0e1a",
      "amphur_name_eng": "Lam Thap",
      "geo_id": "6",
      "province_id": "64"
  },
  {
      "amphur_id": "871",
      "amphur_code": "8108",
      "amphur_name": "\u0e40\u0e2b\u0e19\u0e37\u0e2d\u0e04\u0e25\u0e2d\u0e07",
      "amphur_name_eng": "Nuea Khlong",
      "geo_id": "6",
      "province_id": "64"
  },
  {
      "amphur_id": "872",
      "amphur_code": "8201",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e1e\u0e31\u0e07\u0e07\u0e32",
      "amphur_name_eng": "Mueang Phang-nga",
      "geo_id": "6",
      "province_id": "65"
  },
  {
      "amphur_id": "873",
      "amphur_code": "8202",
      "amphur_name": "\u0e40\u0e01\u0e32\u0e30\u0e22\u0e32\u0e27",
      "amphur_name_eng": "Ko Yao",
      "geo_id": "6",
      "province_id": "65"
  },
  {
      "amphur_id": "874",
      "amphur_code": "8203",
      "amphur_name": "\u0e01\u0e30\u0e1b\u0e07",
      "amphur_name_eng": "Kapong",
      "geo_id": "6",
      "province_id": "65"
  },
  {
      "amphur_id": "875",
      "amphur_code": "8204",
      "amphur_name": "\u0e15\u0e30\u0e01\u0e31\u0e48\u0e27\u0e17\u0e38\u0e48\u0e07",
      "amphur_name_eng": "Takua Thung",
      "geo_id": "6",
      "province_id": "65"
  },
  {
      "amphur_id": "876",
      "amphur_code": "8205",
      "amphur_name": "\u0e15\u0e30\u0e01\u0e31\u0e48\u0e27\u0e1b\u0e48\u0e32",
      "amphur_name_eng": "Takua Pa",
      "geo_id": "6",
      "province_id": "65"
  },
  {
      "amphur_id": "877",
      "amphur_code": "8206",
      "amphur_name": "\u0e04\u0e38\u0e23\u0e30\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Khura Buri",
      "geo_id": "6",
      "province_id": "65"
  },
  {
      "amphur_id": "878",
      "amphur_code": "8207",
      "amphur_name": "\u0e17\u0e31\u0e1a\u0e1b\u0e38\u0e14",
      "amphur_name_eng": "Thap Put",
      "geo_id": "6",
      "province_id": "65"
  },
  {
      "amphur_id": "879",
      "amphur_code": "8208",
      "amphur_name": "\u0e17\u0e49\u0e32\u0e22\u0e40\u0e2b\u0e21\u0e37\u0e2d\u0e07",
      "amphur_name_eng": "Thai Mueang",
      "geo_id": "6",
      "province_id": "65"
  },
  {
      "amphur_id": "880",
      "amphur_code": "8301",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e20\u0e39\u0e40\u0e01\u0e47\u0e15",
      "amphur_name_eng": "Mueang Phuket",
      "geo_id": "6",
      "province_id": "66"
  },
  {
      "amphur_id": "881",
      "amphur_code": "8302",
      "amphur_name": "\u0e01\u0e30\u0e17\u0e39\u0e49",
      "amphur_name_eng": "Kathu",
      "geo_id": "6",
      "province_id": "66"
  },
  {
      "amphur_id": "882",
      "amphur_code": "8303",
      "amphur_name": "\u0e16\u0e25\u0e32\u0e07",
      "amphur_name_eng": "Thalang",
      "geo_id": "6",
      "province_id": "66"
  },
  {
      "amphur_id": "883",
      "amphur_code": "8381",
      "amphur_name": "*\u0e17\u0e38\u0e48\u0e07\u0e04\u0e32",
      "amphur_name_eng": "*Tung Ka",
      "geo_id": "6",
      "province_id": "66"
  },
  {
      "amphur_id": "884",
      "amphur_code": "8401",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2a\u0e38\u0e23\u0e32\u0e29\u0e0e\u0e23\u0e4c\u0e18\u0e32\u0e19\u0e35",
      "amphur_name_eng": "Mueang Surat Thani",
      "geo_id": "6",
      "province_id": "67"
  },
  {
      "amphur_id": "885",
      "amphur_code": "8402",
      "amphur_name": "\u0e01\u0e32\u0e0d\u0e08\u0e19\u0e14\u0e34\u0e29\u0e10\u0e4c",
      "amphur_name_eng": "Kanchanadit",
      "geo_id": "6",
      "province_id": "67"
  },
  {
      "amphur_id": "886",
      "amphur_code": "8403",
      "amphur_name": "\u0e14\u0e2d\u0e19\u0e2a\u0e31\u0e01",
      "amphur_name_eng": "Don Sak",
      "geo_id": "6",
      "province_id": "67"
  },
  {
      "amphur_id": "887",
      "amphur_code": "8404",
      "amphur_name": "\u0e40\u0e01\u0e32\u0e30\u0e2a\u0e21\u0e38\u0e22",
      "amphur_name_eng": "Ko Samui",
      "geo_id": "6",
      "province_id": "67"
  },
  {
      "amphur_id": "888",
      "amphur_code": "8405",
      "amphur_name": "\u0e40\u0e01\u0e32\u0e30\u0e1e\u0e30\u0e07\u0e31\u0e19",
      "amphur_name_eng": "Ko Pha-ngan",
      "geo_id": "6",
      "province_id": "67"
  },
  {
      "amphur_id": "889",
      "amphur_code": "8406",
      "amphur_name": "\u0e44\u0e0a\u0e22\u0e32",
      "amphur_name_eng": "Chaiya",
      "geo_id": "6",
      "province_id": "67"
  },
  {
      "amphur_id": "890",
      "amphur_code": "8407",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e0a\u0e19\u0e30",
      "amphur_name_eng": "Tha Chana",
      "geo_id": "6",
      "province_id": "67"
  },
  {
      "amphur_id": "891",
      "amphur_code": "8408",
      "amphur_name": "\u0e04\u0e35\u0e23\u0e35\u0e23\u0e31\u0e10\u0e19\u0e34\u0e04\u0e21",
      "amphur_name_eng": "Khiri Rat Nikhom",
      "geo_id": "6",
      "province_id": "67"
  },
  {
      "amphur_id": "892",
      "amphur_code": "8409",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e15\u0e32\u0e02\u0e38\u0e19",
      "amphur_name_eng": "Ban Ta Khun",
      "geo_id": "6",
      "province_id": "67"
  },
  {
      "amphur_id": "893",
      "amphur_code": "8410",
      "amphur_name": "\u0e1e\u0e19\u0e21",
      "amphur_name_eng": "Phanom",
      "geo_id": "6",
      "province_id": "67"
  },
  {
      "amphur_id": "894",
      "amphur_code": "8411",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e09\u0e32\u0e07",
      "amphur_name_eng": "Tha Chang",
      "geo_id": "6",
      "province_id": "67"
  },
  {
      "amphur_id": "895",
      "amphur_code": "8412",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e19\u0e32\u0e2a\u0e32\u0e23",
      "amphur_name_eng": "Ban Na San",
      "geo_id": "6",
      "province_id": "67"
  },
  {
      "amphur_id": "896",
      "amphur_code": "8413",
      "amphur_name": "\u0e1a\u0e49\u0e32\u0e19\u0e19\u0e32\u0e40\u0e14\u0e34\u0e21",
      "amphur_name_eng": "Ban Na Doem",
      "geo_id": "6",
      "province_id": "67"
  },
  {
      "amphur_id": "897",
      "amphur_code": "8414",
      "amphur_name": "\u0e40\u0e04\u0e35\u0e22\u0e19\u0e0b\u0e32",
      "amphur_name_eng": "Khian Sa",
      "geo_id": "6",
      "province_id": "67"
  },
  {
      "amphur_id": "898",
      "amphur_code": "8415",
      "amphur_name": "\u0e40\u0e27\u0e35\u0e22\u0e07\u0e2a\u0e23\u0e30",
      "amphur_name_eng": "Wiang Sa",
      "geo_id": "6",
      "province_id": "67"
  },
  {
      "amphur_id": "899",
      "amphur_code": "8416",
      "amphur_name": "\u0e1e\u0e23\u0e30\u0e41\u0e2a\u0e07",
      "amphur_name_eng": "Phrasaeng",
      "geo_id": "6",
      "province_id": "67"
  },
  {
      "amphur_id": "900",
      "amphur_code": "8417",
      "amphur_name": "\u0e1e\u0e38\u0e19\u0e1e\u0e34\u0e19",
      "amphur_name_eng": "Phunphin",
      "geo_id": "6",
      "province_id": "67"
  },
  {
      "amphur_id": "901",
      "amphur_code": "8418",
      "amphur_name": "\u0e0a\u0e31\u0e22\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Chai Buri",
      "geo_id": "6",
      "province_id": "67"
  },
  {
      "amphur_id": "902",
      "amphur_code": "8419",
      "amphur_name": "\u0e27\u0e34\u0e20\u0e32\u0e27\u0e14\u0e35",
      "amphur_name_eng": "Vibhavadi",
      "geo_id": "6",
      "province_id": "67"
  },
  {
      "amphur_id": "903",
      "amphur_code": "8451",
      "amphur_name": "\u0e40\u0e01\u0e32\u0e30\u0e1e\u0e07\u0e31\u0e19 (\u0e2a\u0e32\u0e02\u0e32\u0e15\u0e33\u0e1a\u0e25\u0e40\u0e01\u0e32\u0e30\u0e40\u0e15\u0e48\u0e32)*",
      "amphur_name_eng": "Ko Pha-ngan",
      "geo_id": "6",
      "province_id": "67"
  },
  {
      "amphur_id": "904",
      "amphur_code": "8481",
      "amphur_name": "*\u0e2d.\u0e1a\u0e49\u0e32\u0e19\u0e14\u0e2d\u0e19  \u0e08.\u0e2a\u0e38\u0e23\u0e32\u0e29\u0e0e\u0e23\u0e4c\u0e18\u0e32\u0e19\u0e35",
      "amphur_name_eng": "*Ban Don",
      "geo_id": "6",
      "province_id": "67"
  },
  {
      "amphur_id": "905",
      "amphur_code": "8501",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e23\u0e30\u0e19\u0e2d\u0e07",
      "amphur_name_eng": "Mueang Ranong",
      "geo_id": "6",
      "province_id": "68"
  },
  {
      "amphur_id": "906",
      "amphur_code": "8502",
      "amphur_name": "\u0e25\u0e30\u0e2d\u0e38\u0e48\u0e19",
      "amphur_name_eng": "La-un",
      "geo_id": "6",
      "province_id": "68"
  },
  {
      "amphur_id": "907",
      "amphur_code": "8503",
      "amphur_name": "\u0e01\u0e30\u0e40\u0e1b\u0e2d\u0e23\u0e4c",
      "amphur_name_eng": "Kapoe",
      "geo_id": "6",
      "province_id": "68"
  },
  {
      "amphur_id": "908",
      "amphur_code": "8504",
      "amphur_name": "\u0e01\u0e23\u0e30\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Kra Buri",
      "geo_id": "6",
      "province_id": "68"
  },
  {
      "amphur_id": "909",
      "amphur_code": "8505",
      "amphur_name": "\u0e2a\u0e38\u0e02\u0e2a\u0e33\u0e23\u0e32\u0e0d",
      "amphur_name_eng": "Suk Samran",
      "geo_id": "6",
      "province_id": "68"
  },
  {
      "amphur_id": "910",
      "amphur_code": "8601",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e0a\u0e38\u0e21\u0e1e\u0e23",
      "amphur_name_eng": "Mueang Chumphon",
      "geo_id": "6",
      "province_id": "69"
  },
  {
      "amphur_id": "911",
      "amphur_code": "8602",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e41\u0e0b\u0e30",
      "amphur_name_eng": "Tha Sae",
      "geo_id": "6",
      "province_id": "69"
  },
  {
      "amphur_id": "912",
      "amphur_code": "8603",
      "amphur_name": "\u0e1b\u0e30\u0e17\u0e34\u0e27",
      "amphur_name_eng": "Pathio",
      "geo_id": "6",
      "province_id": "69"
  },
  {
      "amphur_id": "913",
      "amphur_code": "8604",
      "amphur_name": "\u0e2b\u0e25\u0e31\u0e07\u0e2a\u0e27\u0e19",
      "amphur_name_eng": "Lang Suan",
      "geo_id": "6",
      "province_id": "69"
  },
  {
      "amphur_id": "914",
      "amphur_code": "8605",
      "amphur_name": "\u0e25\u0e30\u0e41\u0e21",
      "amphur_name_eng": "Lamae",
      "geo_id": "6",
      "province_id": "69"
  },
  {
      "amphur_id": "915",
      "amphur_code": "8606",
      "amphur_name": "\u0e1e\u0e30\u0e42\u0e15\u0e4a\u0e30",
      "amphur_name_eng": "Phato",
      "geo_id": "6",
      "province_id": "69"
  },
  {
      "amphur_id": "916",
      "amphur_code": "8607",
      "amphur_name": "\u0e2a\u0e27\u0e35",
      "amphur_name_eng": "Sawi",
      "geo_id": "6",
      "province_id": "69"
  },
  {
      "amphur_id": "917",
      "amphur_code": "8608",
      "amphur_name": "\u0e17\u0e38\u0e48\u0e07\u0e15\u0e30\u0e42\u0e01",
      "amphur_name_eng": "Thung Tako",
      "geo_id": "6",
      "province_id": "69"
  },
  {
      "amphur_id": "918",
      "amphur_code": "9001",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2a\u0e07\u0e02\u0e25\u0e32",
      "amphur_name_eng": "Mueang Songkhla",
      "geo_id": "6",
      "province_id": "70"
  },
  {
      "amphur_id": "919",
      "amphur_code": "9002",
      "amphur_name": "\u0e2a\u0e17\u0e34\u0e07\u0e1e\u0e23\u0e30",
      "amphur_name_eng": "Sathing Phra",
      "geo_id": "6",
      "province_id": "70"
  },
  {
      "amphur_id": "920",
      "amphur_code": "9003",
      "amphur_name": "\u0e08\u0e30\u0e19\u0e30",
      "amphur_name_eng": "Chana",
      "geo_id": "6",
      "province_id": "70"
  },
  {
      "amphur_id": "921",
      "amphur_code": "9004",
      "amphur_name": "\u0e19\u0e32\u0e17\u0e27\u0e35",
      "amphur_name_eng": "Na Thawi",
      "geo_id": "6",
      "province_id": "70"
  },
  {
      "amphur_id": "922",
      "amphur_code": "9005",
      "amphur_name": "\u0e40\u0e17\u0e1e\u0e32",
      "amphur_name_eng": "Thepha",
      "geo_id": "6",
      "province_id": "70"
  },
  {
      "amphur_id": "923",
      "amphur_code": "9006",
      "amphur_name": "\u0e2a\u0e30\u0e1a\u0e49\u0e32\u0e22\u0e49\u0e2d\u0e22",
      "amphur_name_eng": "Saba Yoi",
      "geo_id": "6",
      "province_id": "70"
  },
  {
      "amphur_id": "924",
      "amphur_code": "9007",
      "amphur_name": "\u0e23\u0e30\u0e42\u0e19\u0e14",
      "amphur_name_eng": "Ranot",
      "geo_id": "6",
      "province_id": "70"
  },
  {
      "amphur_id": "925",
      "amphur_code": "9008",
      "amphur_name": "\u0e01\u0e23\u0e30\u0e41\u0e2a\u0e2a\u0e34\u0e19\u0e18\u0e38\u0e4c",
      "amphur_name_eng": "Krasae Sin",
      "geo_id": "6",
      "province_id": "70"
  },
  {
      "amphur_id": "926",
      "amphur_code": "9009",
      "amphur_name": "\u0e23\u0e31\u0e15\u0e20\u0e39\u0e21\u0e34",
      "amphur_name_eng": "Rattaphum",
      "geo_id": "6",
      "province_id": "70"
  },
  {
      "amphur_id": "927",
      "amphur_code": "9010",
      "amphur_name": "\u0e2a\u0e30\u0e40\u0e14\u0e32",
      "amphur_name_eng": "Sadao",
      "geo_id": "6",
      "province_id": "70"
  },
  {
      "amphur_id": "928",
      "amphur_code": "9011",
      "amphur_name": "\u0e2b\u0e32\u0e14\u0e43\u0e2b\u0e0d\u0e48",
      "amphur_name_eng": "Hat Yai",
      "geo_id": "6",
      "province_id": "70"
  },
  {
      "amphur_id": "929",
      "amphur_code": "9012",
      "amphur_name": "\u0e19\u0e32\u0e2b\u0e21\u0e48\u0e2d\u0e21",
      "amphur_name_eng": "Na Mom",
      "geo_id": "6",
      "province_id": "70"
  },
  {
      "amphur_id": "930",
      "amphur_code": "9013",
      "amphur_name": "\u0e04\u0e27\u0e19\u0e40\u0e19\u0e35\u0e22\u0e07",
      "amphur_name_eng": "Khuan Niang",
      "geo_id": "6",
      "province_id": "70"
  },
  {
      "amphur_id": "931",
      "amphur_code": "9014",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e01\u0e25\u0e48\u0e33",
      "amphur_name_eng": "Bang Klam",
      "geo_id": "6",
      "province_id": "70"
  },
  {
      "amphur_id": "932",
      "amphur_code": "9015",
      "amphur_name": "\u0e2a\u0e34\u0e07\u0e2b\u0e19\u0e04\u0e23",
      "amphur_name_eng": "Singhanakhon",
      "geo_id": "6",
      "province_id": "70"
  },
  {
      "amphur_id": "933",
      "amphur_code": "9016",
      "amphur_name": "\u0e04\u0e25\u0e2d\u0e07\u0e2b\u0e2d\u0e22\u0e42\u0e02\u0e48\u0e07",
      "amphur_name_eng": "Khlong Hoi Khong",
      "geo_id": "6",
      "province_id": "70"
  },
  {
      "amphur_id": "934",
      "amphur_code": "9077",
      "amphur_name": "\u0e17\u0e49\u0e2d\u0e07\u0e16\u0e34\u0e48\u0e19\u0e40\u0e17\u0e28\u0e1a\u0e32\u0e25\u0e15\u0e33\u0e1a\u0e25\u0e2a\u0e33\u0e19\u0e31\u0e01\u0e02\u0e32\u0e21",
      "amphur_name_eng": "Sum Nung Kam",
      "geo_id": "6",
      "province_id": "70"
  },
  {
      "amphur_id": "935",
      "amphur_code": "9096",
      "amphur_name": "\u0e40\u0e17\u0e28\u0e1a\u0e32\u0e25\u0e15\u0e33\u0e1a\u0e25\u0e1a\u0e49\u0e32\u0e19\u0e1e\u0e23\u0e38*",
      "amphur_name_eng": "Ban Pru*",
      "geo_id": "6",
      "province_id": "70"
  },
  {
      "amphur_id": "936",
      "amphur_code": "9101",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e2a\u0e15\u0e39\u0e25",
      "amphur_name_eng": "Mueang Satun",
      "geo_id": "6",
      "province_id": "71"
  },
  {
      "amphur_id": "937",
      "amphur_code": "9102",
      "amphur_name": "\u0e04\u0e27\u0e19\u0e42\u0e14\u0e19",
      "amphur_name_eng": "Khuan Don",
      "geo_id": "6",
      "province_id": "71"
  },
  {
      "amphur_id": "938",
      "amphur_code": "9103",
      "amphur_name": "\u0e04\u0e27\u0e19\u0e01\u0e32\u0e2b\u0e25\u0e07",
      "amphur_name_eng": "Khuan Kalong",
      "geo_id": "6",
      "province_id": "71"
  },
  {
      "amphur_id": "939",
      "amphur_code": "9104",
      "amphur_name": "\u0e17\u0e48\u0e32\u0e41\u0e1e",
      "amphur_name_eng": "Tha Phae",
      "geo_id": "6",
      "province_id": "71"
  },
  {
      "amphur_id": "940",
      "amphur_code": "9105",
      "amphur_name": "\u0e25\u0e30\u0e07\u0e39",
      "amphur_name_eng": "La-ngu",
      "geo_id": "6",
      "province_id": "71"
  },
  {
      "amphur_id": "941",
      "amphur_code": "9106",
      "amphur_name": "\u0e17\u0e38\u0e48\u0e07\u0e2b\u0e27\u0e49\u0e32",
      "amphur_name_eng": "Thung Wa",
      "geo_id": "6",
      "province_id": "71"
  },
  {
      "amphur_id": "942",
      "amphur_code": "9107",
      "amphur_name": "\u0e21\u0e30\u0e19\u0e31\u0e07",
      "amphur_name_eng": "Manang",
      "geo_id": "6",
      "province_id": "71"
  },
  {
      "amphur_id": "943",
      "amphur_code": "9201",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e15\u0e23\u0e31\u0e07",
      "amphur_name_eng": "Mueang Trang",
      "geo_id": "6",
      "province_id": "72"
  },
  {
      "amphur_id": "944",
      "amphur_code": "9202",
      "amphur_name": "\u0e01\u0e31\u0e19\u0e15\u0e31\u0e07",
      "amphur_name_eng": "Kantang",
      "geo_id": "6",
      "province_id": "72"
  },
  {
      "amphur_id": "945",
      "amphur_code": "9203",
      "amphur_name": "\u0e22\u0e48\u0e32\u0e19\u0e15\u0e32\u0e02\u0e32\u0e27",
      "amphur_name_eng": "Yan Ta Khao",
      "geo_id": "6",
      "province_id": "72"
  },
  {
      "amphur_id": "946",
      "amphur_code": "9204",
      "amphur_name": "\u0e1b\u0e30\u0e40\u0e2b\u0e25\u0e35\u0e22\u0e19",
      "amphur_name_eng": "Palian",
      "geo_id": "6",
      "province_id": "72"
  },
  {
      "amphur_id": "947",
      "amphur_code": "9205",
      "amphur_name": "\u0e2a\u0e34\u0e40\u0e01\u0e32",
      "amphur_name_eng": "Sikao",
      "geo_id": "6",
      "province_id": "72"
  },
  {
      "amphur_id": "948",
      "amphur_code": "9206",
      "amphur_name": "\u0e2b\u0e49\u0e27\u0e22\u0e22\u0e2d\u0e14",
      "amphur_name_eng": "Huai Yot",
      "geo_id": "6",
      "province_id": "72"
  },
  {
      "amphur_id": "949",
      "amphur_code": "9207",
      "amphur_name": "\u0e27\u0e31\u0e07\u0e27\u0e34\u0e40\u0e28\u0e29",
      "amphur_name_eng": "Wang Wiset",
      "geo_id": "6",
      "province_id": "72"
  },
  {
      "amphur_id": "950",
      "amphur_code": "9208",
      "amphur_name": "\u0e19\u0e32\u0e42\u0e22\u0e07",
      "amphur_name_eng": "Na Yong",
      "geo_id": "6",
      "province_id": "72"
  },
  {
      "amphur_id": "951",
      "amphur_code": "9209",
      "amphur_name": "\u0e23\u0e31\u0e29\u0e0e\u0e32",
      "amphur_name_eng": "Ratsada",
      "geo_id": "6",
      "province_id": "72"
  },
  {
      "amphur_id": "952",
      "amphur_code": "9210",
      "amphur_name": "\u0e2b\u0e32\u0e14\u0e2a\u0e33\u0e23\u0e32\u0e0d",
      "amphur_name_eng": "Hat Samran",
      "geo_id": "6",
      "province_id": "72"
  },
  {
      "amphur_id": "953",
      "amphur_code": "9251",
      "amphur_name": "\u0e2d\u0e33\u0e40\u0e20\u0e2d\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e15\u0e23\u0e31\u0e07(\u0e2a\u0e32\u0e02\u0e32\u0e04\u0e25\u0e2d\u0e07\u0e40\u0e15\u0e47\u0e07)**",
      "amphur_name_eng": "Mueang Trang(Krong Teng)**",
      "geo_id": "6",
      "province_id": "72"
  },
  {
      "amphur_id": "954",
      "amphur_code": "9301",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e1e\u0e31\u0e17\u0e25\u0e38\u0e07",
      "amphur_name_eng": "Mueang Phatthalung",
      "geo_id": "6",
      "province_id": "73"
  },
  {
      "amphur_id": "955",
      "amphur_code": "9302",
      "amphur_name": "\u0e01\u0e07\u0e2b\u0e23\u0e32",
      "amphur_name_eng": "Kong Ra",
      "geo_id": "6",
      "province_id": "73"
  },
  {
      "amphur_id": "956",
      "amphur_code": "9303",
      "amphur_name": "\u0e40\u0e02\u0e32\u0e0a\u0e31\u0e22\u0e2a\u0e19",
      "amphur_name_eng": "Khao Chaison",
      "geo_id": "6",
      "province_id": "73"
  },
  {
      "amphur_id": "957",
      "amphur_code": "9304",
      "amphur_name": "\u0e15\u0e30\u0e42\u0e2b\u0e21\u0e14",
      "amphur_name_eng": "Tamot",
      "geo_id": "6",
      "province_id": "73"
  },
  {
      "amphur_id": "958",
      "amphur_code": "9305",
      "amphur_name": "\u0e04\u0e27\u0e19\u0e02\u0e19\u0e38\u0e19",
      "amphur_name_eng": "Khuan Khanun",
      "geo_id": "6",
      "province_id": "73"
  },
  {
      "amphur_id": "959",
      "amphur_code": "9306",
      "amphur_name": "\u0e1b\u0e32\u0e01\u0e1e\u0e30\u0e22\u0e39\u0e19",
      "amphur_name_eng": "Pak Phayun",
      "geo_id": "6",
      "province_id": "73"
  },
  {
      "amphur_id": "960",
      "amphur_code": "9307",
      "amphur_name": "\u0e28\u0e23\u0e35\u0e1a\u0e23\u0e23\u0e1e\u0e15",
      "amphur_name_eng": "Si Banphot",
      "geo_id": "6",
      "province_id": "73"
  },
  {
      "amphur_id": "961",
      "amphur_code": "9308",
      "amphur_name": "\u0e1b\u0e48\u0e32\u0e1a\u0e2d\u0e19",
      "amphur_name_eng": "Pa Bon",
      "geo_id": "6",
      "province_id": "73"
  },
  {
      "amphur_id": "962",
      "amphur_code": "9309",
      "amphur_name": "\u0e1a\u0e32\u0e07\u0e41\u0e01\u0e49\u0e27",
      "amphur_name_eng": "Bang Kaeo",
      "geo_id": "6",
      "province_id": "73"
  },
  {
      "amphur_id": "963",
      "amphur_code": "9310",
      "amphur_name": "\u0e1b\u0e48\u0e32\u0e1e\u0e30\u0e22\u0e2d\u0e21",
      "amphur_name_eng": "Pa Phayom",
      "geo_id": "6",
      "province_id": "73"
  },
  {
      "amphur_id": "964",
      "amphur_code": "9311",
      "amphur_name": "\u0e28\u0e23\u0e35\u0e19\u0e04\u0e23\u0e34\u0e19\u0e17\u0e23\u0e4c",
      "amphur_name_eng": "Srinagarindra",
      "geo_id": "6",
      "province_id": "73"
  },
  {
      "amphur_id": "965",
      "amphur_code": "9401",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e1b\u0e31\u0e15\u0e15\u0e32\u0e19\u0e35",
      "amphur_name_eng": "Mueang Pattani",
      "geo_id": "6",
      "province_id": "74"
  },
  {
      "amphur_id": "966",
      "amphur_code": "9402",
      "amphur_name": "\u0e42\u0e04\u0e01\u0e42\u0e1e\u0e18\u0e34\u0e4c",
      "amphur_name_eng": "Khok Pho",
      "geo_id": "6",
      "province_id": "74"
  },
  {
      "amphur_id": "967",
      "amphur_code": "9403",
      "amphur_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e08\u0e34\u0e01",
      "amphur_name_eng": "Nong Chik",
      "geo_id": "6",
      "province_id": "74"
  },
  {
      "amphur_id": "968",
      "amphur_code": "9404",
      "amphur_name": "\u0e1b\u0e30\u0e19\u0e32\u0e40\u0e23\u0e30",
      "amphur_name_eng": "Panare",
      "geo_id": "6",
      "province_id": "74"
  },
  {
      "amphur_id": "969",
      "amphur_code": "9405",
      "amphur_name": "\u0e21\u0e32\u0e22\u0e2d",
      "amphur_name_eng": "Mayo",
      "geo_id": "6",
      "province_id": "74"
  },
  {
      "amphur_id": "970",
      "amphur_code": "9406",
      "amphur_name": "\u0e17\u0e38\u0e48\u0e07\u0e22\u0e32\u0e07\u0e41\u0e14\u0e07",
      "amphur_name_eng": "Thung Yang Daeng",
      "geo_id": "6",
      "province_id": "74"
  },
  {
      "amphur_id": "971",
      "amphur_code": "9407",
      "amphur_name": "\u0e2a\u0e32\u0e22\u0e1a\u0e38\u0e23\u0e35",
      "amphur_name_eng": "Sai Buri",
      "geo_id": "6",
      "province_id": "74"
  },
  {
      "amphur_id": "972",
      "amphur_code": "9408",
      "amphur_name": "\u0e44\u0e21\u0e49\u0e41\u0e01\u0e48\u0e19",
      "amphur_name_eng": "Mai Kaen",
      "geo_id": "6",
      "province_id": "74"
  },
  {
      "amphur_id": "973",
      "amphur_code": "9409",
      "amphur_name": "\u0e22\u0e30\u0e2b\u0e23\u0e34\u0e48\u0e07",
      "amphur_name_eng": "Yaring",
      "geo_id": "6",
      "province_id": "74"
  },
  {
      "amphur_id": "974",
      "amphur_code": "9410",
      "amphur_name": "\u0e22\u0e30\u0e23\u0e31\u0e07",
      "amphur_name_eng": "Yarang",
      "geo_id": "6",
      "province_id": "74"
  },
  {
      "amphur_id": "975",
      "amphur_code": "9411",
      "amphur_name": "\u0e01\u0e30\u0e1e\u0e49\u0e2d",
      "amphur_name_eng": "Kapho",
      "geo_id": "6",
      "province_id": "74"
  },
  {
      "amphur_id": "976",
      "amphur_code": "9412",
      "amphur_name": "\u0e41\u0e21\u0e48\u0e25\u0e32\u0e19",
      "amphur_name_eng": "Mae Lan",
      "geo_id": "6",
      "province_id": "74"
  },
  {
      "amphur_id": "977",
      "amphur_code": "9501",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e22\u0e30\u0e25\u0e32",
      "amphur_name_eng": "Mueang Yala",
      "geo_id": "6",
      "province_id": "75"
  },
  {
      "amphur_id": "978",
      "amphur_code": "9502",
      "amphur_name": "\u0e40\u0e1a\u0e15\u0e07",
      "amphur_name_eng": "Betong",
      "geo_id": "6",
      "province_id": "75"
  },
  {
      "amphur_id": "979",
      "amphur_code": "9503",
      "amphur_name": "\u0e1a\u0e31\u0e19\u0e19\u0e31\u0e07\u0e2a\u0e15\u0e32",
      "amphur_name_eng": "Bannang Sata",
      "geo_id": "6",
      "province_id": "75"
  },
  {
      "amphur_id": "980",
      "amphur_code": "9504",
      "amphur_name": "\u0e18\u0e32\u0e23\u0e42\u0e15",
      "amphur_name_eng": "Than To",
      "geo_id": "6",
      "province_id": "75"
  },
  {
      "amphur_id": "981",
      "amphur_code": "9505",
      "amphur_name": "\u0e22\u0e30\u0e2b\u0e32",
      "amphur_name_eng": "Yaha",
      "geo_id": "6",
      "province_id": "75"
  },
  {
      "amphur_id": "982",
      "amphur_code": "9506",
      "amphur_name": "\u0e23\u0e32\u0e21\u0e31\u0e19",
      "amphur_name_eng": "Raman",
      "geo_id": "6",
      "province_id": "75"
  },
  {
      "amphur_id": "983",
      "amphur_code": "9507",
      "amphur_name": "\u0e01\u0e32\u0e1a\u0e31\u0e07",
      "amphur_name_eng": "Kabang",
      "geo_id": "6",
      "province_id": "75"
  },
  {
      "amphur_id": "984",
      "amphur_code": "9508",
      "amphur_name": "\u0e01\u0e23\u0e07\u0e1b\u0e34\u0e19\u0e31\u0e07",
      "amphur_name_eng": "Krong Pinang",
      "geo_id": "6",
      "province_id": "75"
  },
  {
      "amphur_id": "985",
      "amphur_code": "9601",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e19\u0e23\u0e32\u0e18\u0e34\u0e27\u0e32\u0e2a",
      "amphur_name_eng": "Mueang Narathiwat",
      "geo_id": "6",
      "province_id": "76"
  },
  {
      "amphur_id": "986",
      "amphur_code": "9602",
      "amphur_name": "\u0e15\u0e32\u0e01\u0e43\u0e1a",
      "amphur_name_eng": "Tak Bai",
      "geo_id": "6",
      "province_id": "76"
  },
  {
      "amphur_id": "987",
      "amphur_code": "9603",
      "amphur_name": "\u0e1a\u0e32\u0e40\u0e08\u0e32\u0e30",
      "amphur_name_eng": "Bacho",
      "geo_id": "6",
      "province_id": "76"
  },
  {
      "amphur_id": "988",
      "amphur_code": "9604",
      "amphur_name": "\u0e22\u0e35\u0e48\u0e07\u0e2d",
      "amphur_name_eng": "Yi-ngo",
      "geo_id": "6",
      "province_id": "76"
  },
  {
      "amphur_id": "989",
      "amphur_code": "9605",
      "amphur_name": "\u0e23\u0e30\u0e41\u0e07\u0e30",
      "amphur_name_eng": "Ra-ngae",
      "geo_id": "6",
      "province_id": "76"
  },
  {
      "amphur_id": "990",
      "amphur_code": "9606",
      "amphur_name": "\u0e23\u0e37\u0e2d\u0e40\u0e2a\u0e32\u0e30",
      "amphur_name_eng": "Rueso",
      "geo_id": "6",
      "province_id": "76"
  },
  {
      "amphur_id": "991",
      "amphur_code": "9607",
      "amphur_name": "\u0e28\u0e23\u0e35\u0e2a\u0e32\u0e04\u0e23",
      "amphur_name_eng": "Si Sakhon",
      "geo_id": "6",
      "province_id": "76"
  },
  {
      "amphur_id": "992",
      "amphur_code": "9608",
      "amphur_name": "\u0e41\u0e27\u0e49\u0e07",
      "amphur_name_eng": "Waeng",
      "geo_id": "6",
      "province_id": "76"
  },
  {
      "amphur_id": "993",
      "amphur_code": "9609",
      "amphur_name": "\u0e2a\u0e38\u0e04\u0e34\u0e23\u0e34\u0e19",
      "amphur_name_eng": "Sukhirin",
      "geo_id": "6",
      "province_id": "76"
  },
  {
      "amphur_id": "994",
      "amphur_code": "9610",
      "amphur_name": "\u0e2a\u0e38\u0e44\u0e2b\u0e07\u0e42\u0e01-\u0e25\u0e01",
      "amphur_name_eng": "Su-ngai Kolok",
      "geo_id": "6",
      "province_id": "76"
  },
  {
      "amphur_id": "995",
      "amphur_code": "9611",
      "amphur_name": "\u0e2a\u0e38\u0e44\u0e2b\u0e07\u0e1b\u0e32\u0e14\u0e35",
      "amphur_name_eng": "Su-ngai Padi",
      "geo_id": "6",
      "province_id": "76"
  },
  {
      "amphur_id": "996",
      "amphur_code": "9612",
      "amphur_name": "\u0e08\u0e30\u0e41\u0e19\u0e30",
      "amphur_name_eng": "Chanae",
      "geo_id": "6",
      "province_id": "76"
  },
  {
      "amphur_id": "997",
      "amphur_code": "9613",
      "amphur_name": "\u0e40\u0e08\u0e32\u0e30\u0e44\u0e2d\u0e23\u0e49\u0e2d\u0e07",
      "amphur_name_eng": "Cho-airong",
      "geo_id": "6",
      "province_id": "76"
  },
  {
      "amphur_id": "998",
      "amphur_code": "9681",
      "amphur_name": "*\u0e2d.\u0e1a\u0e32\u0e07\u0e19\u0e23\u0e32  \u0e08.\u0e19\u0e23\u0e32\u0e18\u0e34\u0e27\u0e32\u0e2a",
      "amphur_name_eng": "*Bang Nra",
      "geo_id": "6",
      "province_id": "76"
  },
  {
      "amphur_id": "1005",
      "amphur_code": "3807",
      "amphur_name": "\u0e1b\u0e32\u0e01\u0e04\u0e32\u0e14",
      "amphur_name_eng": "Pak Khat",
      "geo_id": "3",
      "province_id": "77"
  },
  {
      "amphur_id": "1004",
      "amphur_code": "3806",
      "amphur_name": "\u0e1a\u0e36\u0e07\u0e42\u0e02\u0e07\u0e2b\u0e25\u0e07",
      "amphur_name_eng": "Bueng Khong Long",
      "geo_id": "3",
      "province_id": "77"
  },
  {
      "amphur_id": "1003",
      "amphur_code": "3805",
      "amphur_name": "\u0e28\u0e23\u0e35\u0e27\u0e34\u0e44\u0e25",
      "amphur_name_eng": "Si Wilai",
      "geo_id": "3",
      "province_id": "77"
  },
  {
      "amphur_id": "1002",
      "amphur_code": "3804",
      "amphur_name": "\u0e1e\u0e23\u0e40\u0e08\u0e23\u0e34\u0e0d",
      "amphur_name_eng": "Phon Charoen",
      "geo_id": "3",
      "province_id": "77"
  },
  {
      "amphur_id": "1001",
      "amphur_code": "3803",
      "amphur_name": "\u0e42\u0e0b\u0e48\u0e1e\u0e34\u0e2a\u0e31\u0e22",
      "amphur_name_eng": "So Phisai",
      "geo_id": "3",
      "province_id": "77"
  },
  {
      "amphur_id": "1000",
      "amphur_code": "3802",
      "amphur_name": "\u0e40\u0e0b\u0e01\u0e32",
      "amphur_name_eng": "Seka",
      "geo_id": "3",
      "province_id": "77"
  },
  {
      "amphur_id": "999",
      "amphur_code": "3801",
      "amphur_name": "\u0e40\u0e21\u0e37\u0e2d\u0e07\u0e1a\u0e36\u0e07\u0e01\u0e32\u0e2c",
      "amphur_name_eng": "Mueang Bueng Kan",
      "geo_id": "3",
      "province_id": "77"
  },
  {
      "amphur_id": "1006",
      "amphur_code": "3808",
      "amphur_name": "\u0e1a\u0e38\u0e48\u0e07\u0e04\u0e25\u0e49\u0e32",
      "amphur_name_eng": "Bung Khla",
      "geo_id": "3",
      "province_id": "77"
  },
  {
    "amphur_id": "1007",
    "amphur_code": "3809",
    "amphur_name": "กัลยาณิวัฒนา",
    "amphur_name_eng": "Galyani Vadhana",
    "geo_id": "3",
    "province_id": "38"
  }
]
  