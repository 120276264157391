import React from "react";

import { 
    Form,
    FormGroup,
    Input,
    Label,
    Button,
    Card,
    CardBody,
    CardFooter,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    UncontrolledTooltip
} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMagnifyingGlass,
    faLocationDot,
    faXmark,
    faCommentDots,
    faFileImport,
    faEllipsisV
} from '@fortawesome/free-solid-svg-icons';
import {
    faSquarePlus
} from '@fortawesome/free-regular-svg-icons'
import Navbars from "routers/navbars";
import Pagination from "./pagination/Pagination";
import { PROVINCE_LIST } from "./components/province";
import "react-multi-carousel/lib/styles.css";
import banner from "assets/img/Group 239.png"
import Footer from "components/footer";
import { ErrorAlert } from "components/alert";
import axios from "axios";
import blank_img from 'assets/img/blank-profile.png';
import { useHistory } from 'react-router-dom';
import { LoginContext } from "components/loginContext/LoginContext";
import LoginModal from "components/loginModal";
import Swal from "sweetalert2";

const path_url = process.env.REACT_APP_API_CONNECT;
const line_url = process.env.REACT_APP_LINE_PATH;

function AnnounceResult(){
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize] = React.useState(9);
    const [fetch, setFetch] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [filters, setFilters] = React.useState({
        province: ""
    })

    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    const dataFetch = fetch.slice(firstPageIndex, lastPageIndex);
    const history = useHistory();
    const { userData } = React.useContext(LoginContext);
    const [loginOpen, setLoginOpen] = React.useState(false);

    React.useEffect(() =>{
        onFetch();
    },[])

    const onFetch = async () =>{
        try{
            const res = await axios.post(path_url+'/announce/getAnnounceByFilter',{
                province: filters.province
            })

            if(res.status === 200){
                setFetch(res.data.data)
                setCount(res.data.count)
            }
        }catch(e){
            ErrorAlert({text: 'Network Error!'})
        }
    }

    const intl = new Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 0,
    })

    const onChange = (val, child) =>{
        setFilters(item =>{
            const data = {...item};
            data[child] = val;
            return {...data};
        })
    }

    const onSubmit = (e) =>{
        e.preventDefault();
        // console.log(filters.province)
        onFetch();
    }

    const [salesOffer, setSalesOffer] = React.useState({
        name: "",
        phoneNumber: "",
        email: "",
        _id: "",
        buyer: null,
    })
    const [salesOfferModal, setSalesOfferModal] = React.useState(false)
    const [salesOfferLoading, setSalesOfferLoading] = React.useState(false)

    function salesOfferToggle(items){
        if(userData !== null){
            if(userData.role === "PREMIUM"){
                setSalesOfferModal(true)
                setSalesOffer({
                    _id: userData._id,
                    name: userData.name,
                    phoneNumber: userData.phoneNumber,
                    email: userData.email,
                    buyer: items
                })
            }else{
                window.open(line_url)
            }
            
        }else{
            setLoginOpen(true)
        }
    }
    
    async function salesOffering(){
        setSalesOfferLoading(true)
        try{
            const result = await axios.post(path_url+'/announce/salesOffer',{
                offinger:{
                    _id: salesOffer._id,
                    name: salesOffer.name,
                    phoneNumber: salesOffer.phoneNumber,
                    email: salesOffer.email,
                },
                buyer: salesOffer.buyer,
            })

            if(result.status === 200){
                setSalesOfferLoading(false)
                setSalesOfferModal(false)
                setSalesOffer({
                    name: "",
                    phoneNumber: "",
                    email: "",
                    _id: "",
                    buyer: null,
                })
                Swal.fire({
                    icon: 'info',
                    position: 'center',
                    html: `<p>ชื่อ: <strong>${salesOffer?.buyer?.user?.name}</strong></p>
                    <p>เบอร์โทรติดต่อ: <strong> ${salesOffer?.buyer?.user?.phoneNumber ? salesOffer.buyer.user.phoneNumber : ""}</strong> </p>
                    <p>${salesOffer?.buyer?.user?.phoneBackup ? "เบอร์โทรติดต่อสำรอง: "+ salesOffer.buyer.user.phoneBackup : ""}</p>`,
                    confirmButtonText:"ตกลง",
                    confirmButtonColor: "#5DADE2",
                })
            }
        }catch(e){
            console.log(e)
            setSalesOfferLoading(false)
        }
    }

    return(
        <>
        <Navbars/>
        <div className="announceresult-banner">
            <img alt="banner" src={banner} className="banner-img" />
            <svg xmlns="http://www.w3.org/2000/svg" width="122" height="121.492" viewBox="0 0 122 121.492" style={{position: 'absolute', left: -80, top: 200, zIndex: 1}}>
                <defs>
                    <linearGradient id="linear-gradient1" x1="0.026" y1="0.042" x2="1.56" y2="1.304" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#51c4ef"/>
                    <stop offset="1" stop-color="#296278"/>
                    </linearGradient>
                </defs>
                <ellipse id="Ellipse_44" data-name="Ellipse 44" cx="61" cy="60.746" rx="61" ry="60.746" fill="url(#linear-gradient1)"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="292" height="275.311" viewBox="0 0 292 275.311" style={{position: 'absolute', right: -100, top: -40, zIndex: 2}}>
                <defs>
                    <linearGradient id="linear-gradient2" x1="0.026" y1="0.042" x2="1.56" y2="1.304" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#51ef80"/>
                    <stop offset="0.741" stop-color="#179884"/>
                    <stop offset="1" stop-color="#296278"/>
                    </linearGradient>
                </defs>
                <ellipse id="Ellipse_45" data-name="Ellipse 45" cx="146" cy="137.656" rx="146" ry="137.656" fill="url(#linear-gradient2)"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="122" height="121.492" viewBox="0 0 122 121.492" style={{position: 'absolute', right: '40%', bottom: -100, zIndex: 3}}>
                <defs>
                    <linearGradient id="linear-gradient3" x1="0.026" y1="0.042" x2="1.56" y2="1.304" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#51c4ef"/>
                    <stop offset="1" stop-color="#296278"/>
                    </linearGradient>
                </defs>
                <ellipse id="Ellipse_44" data-name="Ellipse 44" cx="61" cy="60.746" rx="61" ry="60.746" fill="url(#linear-gradient3)"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="121" height="119.441" viewBox="0 0 121 119.441" style={{position: 'absolute', left: '15%', top: 10, zIndex: 4}}>
                <defs>
                    <linearGradient id="linear-gradient4" x1="0.026" y1="0.042" x2="1.078" y2="-0.489" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#ffb83e"/>
                    <stop offset="1" stop-color="#ffc654"/>
                    </linearGradient>
                </defs>
                <ellipse id="Ellipse_46" data-name="Ellipse 46" cx="60.5" cy="59.72" rx="60.5" ry="59.72" fill="url(#linear-gradient4)"/>
            </svg>
        </div>
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="122" height="121.492" viewBox="0 0 122 121.492" style={{position: 'relative', left: -80, top: 200}}>
            <defs>
                <linearGradient id="linear-gradient" x1="0.026" y1="0.042" x2="1.56" y2="1.304" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#51c4ef"/>
                <stop offset="1" stop-color="#296278"/>
                </linearGradient>
            </defs>
            <ellipse id="Ellipse_44" data-name="Ellipse 44" cx="61" cy="60.746" rx="61" ry="60.746" fill="url(#linear-gradient)"/>
        </svg> */}
        <div className="announceresult-container">
            <div className="announce-section">
                <div className="title-container">
                    <Label className="title color-darkblue">
                        ประกาศรับซื้อที่ดินทั้งหมด
                    </Label>
                    <div>
                        <Button 
                        color="skypastal"
                        onClick={() => history.push("/announce")}
                        >
                            <FontAwesomeIcon icon={faSquarePlus} style={{marginRight: 10}} />
                            ลงประกาศรับซื้อที่ดิน
                        </Button>
                    </div>
                </div>
                <div className="announce-section-row">
                    <div className="announce-section-col-1">
                        <div className="containner shadow">
                            <p 
                            className="color-default mb-10"
                            style={{fontSize: 18, fontWeight:400}}
                            >
                                หาไม่เจอใช่ไหม?
                            </p>
                            <Form onSubmit={onSubmit}>
                                <Row className="mb-30">
                                    <Col>
                                        <FormGroup className="form-group">
                                            <Label className="color-darkblue">จังหวัด</Label>
                                            <Input 
                                            type="select"
                                            className="border form-control color-darkblue" 
                                            value={filters.province}
                                            onChange={(val) => onChange(val.target.value, 'province')}
                                            >
                                                <option key={0} value={''}>ทั้งหมด</option>
                                                {PROVINCE_LIST.map((item,i) =>(
                                                    <option key={i} value={item.province_name}>
                                                        {item.province_name}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button 
                                        type="submit"
                                        color="default-sm"
                                        style={{width: '100%'}}
                                        >
                                            <FontAwesomeIcon icon={faMagnifyingGlass} className="mr-10" />
                                            ค้นหากันเลย
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                    <div className="announce-section-col-2">
                        <Row className="row-cols-1 row-cols-md-3 row-cols-lg-3" style={{marginBottom: 50}}>
                            {dataFetch.length !==0 ? dataFetch.map((items,i) =>{
                                const date = new Date(items.create_At).toLocaleDateString('th',{day:'2-digit', month:'short', year:'2-digit'});
                                var SizeUnitLow;
                                switch(items.size.lowest.square){
                                    case 'squarewa' : SizeUnitLow = 'ตร.ว.'; 
                                    break;
                                    case 'ngan' : SizeUnitLow = 'งาน';
                                    break;
                                    case 'rai' : SizeUnitLow = 'ไร่';
                                    break;
                                    default : SizeUnitLow = '';
                                }
            
                                var SizeUnitMax;
                                switch(items.size.maximum.square){
                                    case 'squarewa' : SizeUnitMax = 'ตร.ว.'; 
                                    break;
                                    case 'ngan' : SizeUnitMax = 'งาน';
                                    break;
                                    case 'rai' : SizeUnitMax = 'ไร่';
                                    break;
                                    default : SizeUnitMax = '';
                                }
                                function choose(val){
                                    switch(val){
                                        case 'build' : return 'สร้างบ้าน';
                                        case 'SmallHouse' : return 'พัฒนาโครงการที่อยู่อาศัยขนาดเล็ก';
                                        case 'LargeResidential' : return 'พัฒนาโครงการที่อยู่อาศัยขนาดใหญ่';
                                        case 'BigBusiness' : return 'ธุรกิจขนาดใหญ่';
                                        case 'SME' : return 'ธุรกิจขนาดเล็ก SME';
                                        case 'Speculate' : return 'เก็งกำไร';
                                        case 'Farming' : return 'การเกษตร';
                                        default : return 'อื่นๆ';
                                    }
                                }
                                return(
                                    <Col key={i} style={{marginBottom: 20}}>
                                        <Card>
                                            <CardBody>
                                                <div className="header mb-10">
                                                    <img alt="userImg" src={items?.user?.profileImg ? items.user.profileImg : blank_img} className="user-img" />
                                                    <p className="name">{items?.user?.name}</p>
                                                    <p className="oversize">ต้องการ</p>
                                                    <div className="text-wrapper">
                                                        <FontAwesomeIcon icon={faLocationDot} className="mr-10 icon" />
                                                        <div className="label">
                                                            <p className="label-1">
                                                                จ.{items?.province}
                                                            </p>
                                                            <p className="label-2">
                                                                อ.{items?.district}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <small className="date">
                                                        {date}
                                                    </small>
                                                </div>
                                                
                                                <div className="text-description">
                                                    <div className="font-weight-500 color-default mb-10">
                                                        วัตถุประสงค์: 
                                                        <p className="font-weight-300 ml-10 color-darkblue">{`${choose(items?.choice)}`}</p>
                                                    </div>
                                                    <div className="flex font-weight-500 color-default mb-10">
                                                        ขนาดเนื้อที่: <div className="font-weight-300 ml-10 color-darkblue">{`${items?.size?.lowest?.count} ${SizeUnitLow} - ${items?.size?.maximum?.count} ${SizeUnitMax}`}</div>
                                                    </div>
                                                    <div className="flex font-weight-500 color-default ">
                                                        ราคารวม: 
                                                    </div>
                                                    <div className="color-darkblue mb-10">
                                                        {fetch ? 
                                                        `${intl.format(items?.price?.lowest)} - ${intl.format(items?.price?.maximum)}`
                                                        : null}
                                                    </div>
                                                    <div className="flex font-weight-500 color-default">
                                                        รายละเอียด: 
                                                    </div>
                                                    <div className="text-detail color-darkblue">
                                                        {items?.description} 
                                                    </div>
                                                </div>
                                            </CardBody>
                                            <CardFooter 
                                            style={{
                                                display:'flex', 
                                                justifyContent:'space-between',
                                                padding: 10
                                            }}
                                            >
                                                <Button 
                                                color="transparent"
                                                style={{color: '#909090'}}
                                                onClick={() => window.open('/announce_view/'+ items._id)}
                                                >
                                                    <FontAwesomeIcon icon={faCommentDots} style={{fontSize: 20}} /> {items?.comments?.length}
                                                </Button>
                                                <div style={{display: 'flex'}}>
                                                    <Button 
                                                    id={`present${i}`}
                                                    color="round-default-sm"
                                                    onClick={() => salesOfferToggle(items)}
                                                    >
                                                        <FontAwesomeIcon icon={faFileImport} style={{fontSize: 20}} />
                                                    </Button>
                                                    <UncontrolledTooltip
                                                    target={`present${i}`}
                                                    >
                                                        เสนอขาย
                                                    </UncontrolledTooltip>

                                                    <Button 
                                                    id={`info${i}`}
                                                    color="round-sky-sm"
                                                    onClick={() => window.open('/announce_view/'+ items._id)}
                                                    >
                                                        <FontAwesomeIcon icon={faEllipsisV} style={{fontSize: 20}} />
                                                    </Button>
                                                    <UncontrolledTooltip
                                                    target={`info${i}`}
                                                    >
                                                        รายละเอียด
                                                    </UncontrolledTooltip>
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                )
                            }) 
                            : 
                            <Col className="col-12 col-md-12 col-lg-12" style={{textAlign:'center', marginTop: 30}}>
                                <div>
                                    <FontAwesomeIcon icon={faXmark} size="2x" className="color-gray" />
                                    <h3 className="color-gray">ไม่พบข้อมูล</h3>
                                </div>
                                
                            </Col>}
                        </Row>
                        <div style={{display:'flex', justifyContent:"flex-end"}}>
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={count}
                                pageSize={pageSize}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <LoginModal isOpen={loginOpen} onClose={() => setLoginOpen(false)} />
        <Modal isOpen={salesOfferModal} toggle={() => setSalesOfferModal(false)}>
            <ModalHeader toggle={() => setSalesOfferModal(false)}>
                เสนอขายให้ผู้รับซื้อ
            </ModalHeader>
            <ModalBody>
                <p>ข้อมูลผู้เสนอขาย</p>
                <Form>
                    <FormGroup className="form-group">
                        <Label>email</Label>
                        <Input 
                        type="text" 
                        className="border" value={salesOffer.email} 
                        onChange={(val) => setSalesOffer({...salesOffer, email:val.target.value})} 
                        />
                    </FormGroup>
                    <FormGroup className="form-group">
                        <Label>ชื่อผู้เสนอ</Label>
                        <Input 
                        type="text" 
                        className="border" value={salesOffer.name} 
                        onChange={(val) => setSalesOffer({...salesOffer, name:val.target.value})} 
                        />
                    </FormGroup>
                    <FormGroup className="form-group">
                        <Label>เบอร์โทรศัพท์</Label>
                        <Input 
                        type="text" 
                        className="border" value={salesOffer.phoneNumber} 
                        onChange={(val) => setSalesOffer({...salesOffer, phoneNumber:val.target.value})} 
                        />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={() => salesOffering()} disabled={salesOfferLoading}>
                    {salesOfferLoading ? 
                    <>
                        <Spinner size={'sm'} className="mr-10" style={{color:'white'}} />
                        Loading...
                    </>
                    : 
                    "ตกลง"
                    }
                    
                </Button>
            </ModalFooter>
        </Modal>
        <Footer/>
        </>
    )
}
export default AnnounceResult