import React  from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    Button, 
    Form,
    Row,
    Col,
    FormText,
    FormGroup,
    Input
} from "reactstrap";

import { 
    faUpload,
    faCheck,
    faCircleCheck
} from "@fortawesome/free-solid-svg-icons";

import {
    faImages,
}from '@fortawesome/free-regular-svg-icons';
import {
    faFacebookSquare,
    faYoutube
} from '@fortawesome/free-brands-svg-icons';
import ReactImageGallery from "react-image-gallery";
import { ErrorAlert } from "components/alert";

function Consignment_Tabs2(props){
    const inputFile = React.useRef(null);
    const { 
        value, 
        onchange, 
        onDeleteImg,
        onChangeImg
    } = props;

    const onButtonClick = () => {
        if(value.preview_images.length < 8){
            inputFile.current.click();
        }else{
            ErrorAlert({text: 'ขออภัย! รูปภาพครบ 8 รูปแล้ว'})
        }
       
    };

    return(
        <>
        <div className="consignment-tab-wrapper">
            <div className="consignment-tab2-section-1">
                <div className="header-tab">
                    <div className="title">รูปถ่าย</div>
                    <label className="subtitle">(ขนาดที่รูปภาพไม่เกิน 1920x1080 px จำนวน 8 รูปเท่านั้น)
                        <label className="color-red font-weight-500" style={{fontSize:20}}>*</label>
                    </label>
                </div>
                <input 
                type='file' 
                id='file' 
                ref={inputFile} 
                style={{display: 'none'}} 
                onChange={(val) => onChangeImg(val.target.files)} 
                accept="image/jpg, image/jpeg, image/png" 
                />
                <Button color="default-sm" onClick={onButtonClick}>
                    <FontAwesomeIcon icon={faUpload} style={{marginRight:10}} /> อัพโหลดรูปภาพ
                </Button>
            </div>
            <div className="consignment-tab2-section-2">
                {value.preview_images.length !== 0 ? 
                <ReactImageGallery 
                showPlayButton={false}
                // showThumbnails={false}
                thumbnailPosition='bottom'
                showFullscreenButton={false}
                items={
                    value.preview_images.map((items,i) => {
                        return{
                            original: items,
                            thumbnail: items,
                            originalClass:"item-img",
                            renderThumbInner:()=>{
                                return(
                                    <div className="thumnail-container">
                                        <img alt="" src={items} style={{width:'100%', height:'100%'}} />
                                        <Button type="button" onClick={() => onDeleteImg(i)} className="delete">x</Button>
                                    </div>
                                )
                            }
                        }
                    })
                }
                />
                :
                <>
                <div className="ground-image">
                    <FontAwesomeIcon icon={faImages} style={{fontSize:100, color:'lightgray'}} />
                </div>
                <div className="ground-images">
                    <div className="sub-ground" >
                        <FontAwesomeIcon icon={faImages} style={{fontSize:50, color:"lightgray"}} />
                    </div>
                    <div className="sub-ground" >
                        <FontAwesomeIcon icon={faImages} style={{fontSize:50, color:"lightgray"}} />
                    </div>
                    <div className="sub-ground">
                        <FontAwesomeIcon icon={faImages} style={{fontSize:50, color:"lightgray"}} />
                    </div>
                </div>
                </>
                }
            </div>
            <div className="consignment-tab2-section-3">
                <Form>
                    <Row>
                        <Col className="col-12 col-md-1">
                            <div className="title">วิดีโอ</div>
                        </Col>
                        <Col className="col-12 col-md-10">
                            <FormGroup className="form-group">
                                <Input 
                                className="color-darkblue border" 
                                type="text" 
                                placeholder="example https://www.youtube.com" 
                                onChange={(val) => onchange(val.target.value, 'video')}
                                />
                                <FormText className="color-red">
                                    * วาง URL ของวิดีโอ
                                </FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
        </>
    )
}
export default Consignment_Tabs2