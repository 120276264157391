export const PROVINCE_LIST = [
  {
      "province_id": "1",
      "province_code": "10",
      "province_name": "\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e\u0e21\u0e2b\u0e32\u0e19\u0e04\u0e23",
      "province_name_eng": "Bangkok",
      "geo_id": "2"
  },
  {
      "province_id": "2",
      "province_code": "11",
      "province_name":"\u0e2a\u0e21\u0e38\u0e17\u0e23\u0e1b\u0e23\u0e32\u0e01\u0e32\u0e23",
      "province_name_eng": "Samut Prakan",
      "geo_id": "2"
  },
  {
      "province_id": "3",
      "province_code": "12",
      "province_name": "\u0e19\u0e19\u0e17\u0e1a\u0e38\u0e23\u0e35",
      "province_name_eng": "Nonthaburi",
      "geo_id": "2"
  },
  {
      "province_id": "4",
      "province_code": "13",
      "province_name": "\u0e1b\u0e17\u0e38\u0e21\u0e18\u0e32\u0e19\u0e35",
      "province_name_eng": "Pathum Thani",
      "geo_id": "2"
  },
  {
      "province_id": "5",
      "province_code": "14",
      "province_name": "\u0e1e\u0e23\u0e30\u0e19\u0e04\u0e23\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32",
      "province_name_eng": "Phra Nakhon Si Ayutthaya",
      "geo_id": "2"
  },
  {
      "province_id": "6",
      "province_code": "15",
      "province_name": "\u0e2d\u0e48\u0e32\u0e07\u0e17\u0e2d\u0e07",
      "province_name_eng": "Ang Thong",
      "geo_id": "2"
  },
  {
      "province_id": "7",
      "province_code": "16",
      "province_name": "\u0e25\u0e1e\u0e1a\u0e38\u0e23\u0e35",
      "province_name_eng": "Loburi",
      "geo_id": "2"
  },
  {
      "province_id": "8",
      "province_code": "17",
      "province_name": "\u0e2a\u0e34\u0e07\u0e2b\u0e4c\u0e1a\u0e38\u0e23\u0e35",
      "province_name_eng": "Sing Buri",
      "geo_id": "2"
  },
  {
      "province_id": "9",
      "province_code": "18",
      "province_name": "\u0e0a\u0e31\u0e22\u0e19\u0e32\u0e17",
      "province_name_eng": "Chai Nat",
      "geo_id": "2"
  },
  {
      "province_id": "10",
      "province_code": "19",
      "province_name": "\u0e2a\u0e23\u0e30\u0e1a\u0e38\u0e23\u0e35",
      "province_name_eng": "Saraburi",
      "geo_id": "2"
  },
  {
      "province_id": "11",
      "province_code": "20",
      "province_name": "\u0e0a\u0e25\u0e1a\u0e38\u0e23\u0e35",
      "province_name_eng": "Chon Buri",
      "geo_id": "5"
  },
  {
      "province_id": "12",
      "province_code": "21",
      "province_name": "\u0e23\u0e30\u0e22\u0e2d\u0e07",
      "province_name_eng": "Rayong",
      "geo_id": "5"
  },
  {
      "province_id": "13",
      "province_code": "22",
      "province_name": "\u0e08\u0e31\u0e19\u0e17\u0e1a\u0e38\u0e23\u0e35",
      "province_name_eng": "Chanthaburi",
      "geo_id": "5"
  },
  {
      "province_id": "14",
      "province_code": "23",
      "province_name": "\u0e15\u0e23\u0e32\u0e14",
      "province_name_eng": "Trat",
      "geo_id": "5"
  },
  {
      "province_id": "15",
      "province_code": "24",
      "province_name": "\u0e09\u0e30\u0e40\u0e0a\u0e34\u0e07\u0e40\u0e17\u0e23\u0e32",
      "province_name_eng": "Chachoengsao",
      "geo_id": "5"
  },
  {
      "province_id": "16",
      "province_code": "25",
      "province_name": "\u0e1b\u0e23\u0e32\u0e08\u0e35\u0e19\u0e1a\u0e38\u0e23\u0e35",
      "province_name_eng": "Prachin Buri",
      "geo_id": "5"
  },
  {
      "province_id": "17",
      "province_code": "26",
      "province_name": "\u0e19\u0e04\u0e23\u0e19\u0e32\u0e22\u0e01",
      "province_name_eng": "Nakhon Nayok",
      "geo_id": "2"
  },
  {
      "province_id": "18",
      "province_code": "27",
      "province_name": "\u0e2a\u0e23\u0e30\u0e41\u0e01\u0e49\u0e27",
      "province_name_eng": "Sa Kaeo",
      "geo_id": "5"
  },
  {
      "province_id": "19",
      "province_code": "30",
      "province_name": "\u0e19\u0e04\u0e23\u0e23\u0e32\u0e0a\u0e2a\u0e35\u0e21\u0e32",
      "province_name_eng": "Nakhon Ratchasima",
      "geo_id": "3"
  },
  {
      "province_id": "20",
      "province_code": "31",
      "province_name": "\u0e1a\u0e38\u0e23\u0e35\u0e23\u0e31\u0e21\u0e22\u0e4c",
      "province_name_eng": "Buri Ram",
      "geo_id": "3"
  },
  {
      "province_id": "21",
      "province_code": "32",
      "province_name": "\u0e2a\u0e38\u0e23\u0e34\u0e19\u0e17\u0e23\u0e4c",
      "province_name_eng": "Surin",
      "geo_id": "3"
  },
  {
      "province_id": "22",
      "province_code": "33",
      "province_name": "\u0e28\u0e23\u0e35\u0e2a\u0e30\u0e40\u0e01\u0e29",
      "province_name_eng": "Si Sa Ket",
      "geo_id": "3"
  },
  {
      "province_id": "23",
      "province_code": "34",
      "province_name": "\u0e2d\u0e38\u0e1a\u0e25\u0e23\u0e32\u0e0a\u0e18\u0e32\u0e19\u0e35",
      "province_name_eng": "Ubon Ratchathani",
      "geo_id": "3"
  },
  {
      "province_id": "24",
      "province_code": "35",
      "province_name": "\u0e22\u0e42\u0e2a\u0e18\u0e23",
      "province_name_eng": "Yasothon",
      "geo_id": "3"
  },
  {
      "province_id": "25",
      "province_code": "36",
      "province_name": "\u0e0a\u0e31\u0e22\u0e20\u0e39\u0e21\u0e34",
      "province_name_eng": "Chaiyaphum",
      "geo_id": "3"
  },
  {
      "province_id": "26",
      "province_code": "37",
      "province_name": "\u0e2d\u0e33\u0e19\u0e32\u0e08\u0e40\u0e08\u0e23\u0e34\u0e0d",
      "province_name_eng": "Amnat Charoen",
      "geo_id": "3"
  },
  {
      "province_id": "27",
      "province_code": "39",
      "province_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e1a\u0e31\u0e27\u0e25\u0e33\u0e20\u0e39",
      "province_name_eng": "Nong Bua Lam Phu",
      "geo_id": "3"
  },
  {
      "province_id": "28",
      "province_code": "40",
      "province_name": "\u0e02\u0e2d\u0e19\u0e41\u0e01\u0e48\u0e19",
      "province_name_eng": "Khon Kaen",
      "geo_id": "3"
  },
  {
      "province_id": "29",
      "province_code": "41",
      "province_name": "\u0e2d\u0e38\u0e14\u0e23\u0e18\u0e32\u0e19\u0e35",
      "province_name_eng": "Udon Thani",
      "geo_id": "3"
  },
  {
      "province_id": "30",
      "province_code": "42",
      "province_name": "\u0e40\u0e25\u0e22",
      "province_name_eng": "Loei",
      "geo_id": "3"
  },
  {
      "province_id": "31",
      "province_code": "43",
      "province_name": "\u0e2b\u0e19\u0e2d\u0e07\u0e04\u0e32\u0e22",
      "province_name_eng": "Nong Khai",
      "geo_id": "3"
  },
  {
      "province_id": "32",
      "province_code": "44",
      "province_name": "\u0e21\u0e2b\u0e32\u0e2a\u0e32\u0e23\u0e04\u0e32\u0e21",
      "province_name_eng": "Maha Sarakham",
      "geo_id": "3"
  },
  {
      "province_id": "33",
      "province_code": "45",
      "province_name": "\u0e23\u0e49\u0e2d\u0e22\u0e40\u0e2d\u0e47\u0e14",
      "province_name_eng": "Roi Et",
      "geo_id": "3"
  },
  {
      "province_id": "34",
      "province_code": "46",
      "province_name": "\u0e01\u0e32\u0e2c\u0e2a\u0e34\u0e19\u0e18\u0e38\u0e4c",
      "province_name_eng": "Kalasin",
      "geo_id": "3"
  },
  {
      "province_id": "35",
      "province_code": "47",
      "province_name": "\u0e2a\u0e01\u0e25\u0e19\u0e04\u0e23",
      "province_name_eng": "Sakon Nakhon",
      "geo_id": "3"
  },
  {
      "province_id": "36",
      "province_code": "48",
      "province_name": "\u0e19\u0e04\u0e23\u0e1e\u0e19\u0e21",
      "province_name_eng": "Nakhon Phanom",
      "geo_id": "3"
  },
  {
      "province_id": "37",
      "province_code": "49",
      "province_name": "\u0e21\u0e38\u0e01\u0e14\u0e32\u0e2b\u0e32\u0e23",
      "province_name_eng": "Mukdahan",
      "geo_id": "3"
  },
  {
      "province_id": "38",
      "province_code": "50",
      "province_name": "\u0e40\u0e0a\u0e35\u0e22\u0e07\u0e43\u0e2b\u0e21\u0e48",
      "province_name_eng": "Chiang Mai",
      "geo_id": "1"
  },
  {
      "province_id": "39",
      "province_code": "51",
      "province_name": "\u0e25\u0e33\u0e1e\u0e39\u0e19",
      "province_name_eng": "Lamphun",
      "geo_id": "1"
  },
  {
      "province_id": "40",
      "province_code": "52",
      "province_name": "\u0e25\u0e33\u0e1b\u0e32\u0e07",
      "province_name_eng": "Lampang",
      "geo_id": "1"
  },
  {
      "province_id": "41",
      "province_code": "53",
      "province_name": "\u0e2d\u0e38\u0e15\u0e23\u0e14\u0e34\u0e15\u0e16\u0e4c",
      "province_name_eng": "Uttaradit",
      "geo_id": "1"
  },
  {
      "province_id": "42",
      "province_code": "54",
      "province_name": "\u0e41\u0e1e\u0e23\u0e48",
      "province_name_eng": "Phrae",
      "geo_id": "1"
  },
  {
      "province_id": "43",
      "province_code": "55",
      "province_name": "\u0e19\u0e48\u0e32\u0e19",
      "province_name_eng": "Nan",
      "geo_id": "1"
  },
  {
      "province_id": "44",
      "province_code": "56",
      "province_name": "\u0e1e\u0e30\u0e40\u0e22\u0e32",
      "province_name_eng": "Phayao",
      "geo_id": "1"
  },
  {
      "province_id": "45",
      "province_code": "57",
      "province_name": "\u0e40\u0e0a\u0e35\u0e22\u0e07\u0e23\u0e32\u0e22",
      "province_name_eng": "Chiang Rai",
      "geo_id": "1"
  },
  {
      "province_id": "46",
      "province_code": "58",
      "province_name": "\u0e41\u0e21\u0e48\u0e2e\u0e48\u0e2d\u0e07\u0e2a\u0e2d\u0e19",
      "province_name_eng": "Mae Hong Son",
      "geo_id": "1"
  },
  {
      "province_id": "47",
      "province_code": "60",
      "province_name": "\u0e19\u0e04\u0e23\u0e2a\u0e27\u0e23\u0e23\u0e04\u0e4c",
      "province_name_eng": "Nakhon Sawan",
      "geo_id": "2"
  },
  {
      "province_id": "48",
      "province_code": "61",
      "province_name": "\u0e2d\u0e38\u0e17\u0e31\u0e22\u0e18\u0e32\u0e19\u0e35",
      "province_name_eng": "Uthai Thani",
      "geo_id": "2"
  },
  {
      "province_id": "49",
      "province_code": "62",
      "province_name": "\u0e01\u0e33\u0e41\u0e1e\u0e07\u0e40\u0e1e\u0e0a\u0e23",
      "province_name_eng": "Kamphaeng Phet",
      "geo_id": "2"
  },
  {
      "province_id": "50",
      "province_code": "63",
      "province_name": "\u0e15\u0e32\u0e01",
      "province_name_eng": "Tak",
      "geo_id": "4"
  },
  {
      "province_id": "51",
      "province_code": "64",
      "province_name": "\u0e2a\u0e38\u0e42\u0e02\u0e17\u0e31\u0e22",
      "province_name_eng": "Sukhothai",
      "geo_id": "2"
  },
  {
      "province_id": "52",
      "province_code": "65",
      "province_name": "\u0e1e\u0e34\u0e29\u0e13\u0e38\u0e42\u0e25\u0e01",
      "province_name_eng": "Phitsanulok",
      "geo_id": "2"
  },
  {
      "province_id": "53",
      "province_code": "66",
      "province_name": "\u0e1e\u0e34\u0e08\u0e34\u0e15\u0e23",
      "province_name_eng": "Phichit",
      "geo_id": "2"
  },
  {
      "province_id": "54",
      "province_code": "67",
      "province_name": "\u0e40\u0e1e\u0e0a\u0e23\u0e1a\u0e39\u0e23\u0e13\u0e4c",
      "province_name_eng": "Phetchabun",
      "geo_id": "2"
  },
  {
      "province_id": "55",
      "province_code": "70",
      "province_name": "\u0e23\u0e32\u0e0a\u0e1a\u0e38\u0e23\u0e35",
      "province_name_eng": "Ratchaburi",
      "geo_id": "4"
  },
  {
      "province_id": "56",
      "province_code": "71",
      "province_name": "\u0e01\u0e32\u0e0d\u0e08\u0e19\u0e1a\u0e38\u0e23\u0e35",
      "province_name_eng": "Kanchanaburi",
      "geo_id": "4"
  },
  {
      "province_id": "57",
      "province_code": "72",
      "province_name": "\u0e2a\u0e38\u0e1e\u0e23\u0e23\u0e13\u0e1a\u0e38\u0e23\u0e35",
      "province_name_eng": "Suphan Buri",
      "geo_id": "2"
  },
  {
      "province_id": "58",
      "province_code": "73",
      "province_name": "\u0e19\u0e04\u0e23\u0e1b\u0e10\u0e21",
      "province_name_eng": "Nakhon Pathom",
      "geo_id": "2"
  },
  {
      "province_id": "59",
      "province_code": "74",
      "province_name": "\u0e2a\u0e21\u0e38\u0e17\u0e23\u0e2a\u0e32\u0e04\u0e23",
      "province_name_eng": "Samut Sakhon",
      "geo_id": "2"
  },
  {
      "province_id": "60",
      "province_code": "75",
      "province_name": "\u0e2a\u0e21\u0e38\u0e17\u0e23\u0e2a\u0e07\u0e04\u0e23\u0e32\u0e21",
      "province_name_eng": "Samut Songkhram",
      "geo_id": "2"
  },
  {
      "province_id": "61",
      "province_code": "76",
      "province_name": "\u0e40\u0e1e\u0e0a\u0e23\u0e1a\u0e38\u0e23\u0e35",
      "province_name_eng": "Phetchaburi",
      "geo_id": "4"
  },
  {
      "province_id": "62",
      "province_code": "77",
      "province_name": "\u0e1b\u0e23\u0e30\u0e08\u0e27\u0e1a\u0e04\u0e35\u0e23\u0e35\u0e02\u0e31\u0e19\u0e18\u0e4c",
      "province_name_eng": "Prachuap Khiri Khan",
      "geo_id": "4"
  },
  {
      "province_id": "63",
      "province_code": "80",
      "province_name": "\u0e19\u0e04\u0e23\u0e28\u0e23\u0e35\u0e18\u0e23\u0e23\u0e21\u0e23\u0e32\u0e0a",
      "province_name_eng": "Nakhon Si Thammarat",
      "geo_id": "6"
  },
  {
      "province_id": "64",
      "province_code": "81",
      "province_name": "\u0e01\u0e23\u0e30\u0e1a\u0e35\u0e48",
      "province_name_eng": "Krabi",
      "geo_id": "6"
  },
  {
      "province_id": "65",
      "province_code": "82",
      "province_name": "\u0e1e\u0e31\u0e07\u0e07\u0e32",
      "province_name_eng": "Phangnga",
      "geo_id": "6"
  },
  {
      "province_id": "66",
      "province_code": "83",
      "province_name": "\u0e20\u0e39\u0e40\u0e01\u0e47\u0e15",
      "province_name_eng": "Phuket",
      "geo_id": "6"
  },
  {
      "province_id": "67",
      "province_code": "84",
      "province_name": "\u0e2a\u0e38\u0e23\u0e32\u0e29\u0e0e\u0e23\u0e4c\u0e18\u0e32\u0e19\u0e35",
      "province_name_eng": "Surat Thani",
      "geo_id": "6"
  },
  {
      "province_id": "68",
      "province_code": "85",
      "province_name": "\u0e23\u0e30\u0e19\u0e2d\u0e07",
      "province_name_eng": "Ranong",
      "geo_id": "6"
  },
  {
      "province_id": "69",
      "province_code": "86",
      "province_name": "\u0e0a\u0e38\u0e21\u0e1e\u0e23",
      "province_name_eng": "Chumphon",
      "geo_id": "6"
  },
  {
      "province_id": "70",
      "province_code": "90",
      "province_name": "\u0e2a\u0e07\u0e02\u0e25\u0e32",
      "province_name_eng": "Songkhla",
      "geo_id": "6"
  },
  {
      "province_id": "71",
      "province_code": "91",
      "province_name": "\u0e2a\u0e15\u0e39\u0e25",
      "province_name_eng": "Satun",
      "geo_id": "6"
  },
  {
      "province_id": "72",
      "province_code": "92",
      "province_name": "\u0e15\u0e23\u0e31\u0e07",
      "province_name_eng": "Trang",
      "geo_id": "6"
  },
  {
      "province_id": "73",
      "province_code": "93",
      "province_name": "\u0e1e\u0e31\u0e17\u0e25\u0e38\u0e07",
      "province_name_eng": "Phatthalung",
      "geo_id": "6"
  },
  {
      "province_id": "74",
      "province_code": "94",
      "province_name": "\u0e1b\u0e31\u0e15\u0e15\u0e32\u0e19\u0e35",
      "province_name_eng": "Pattani",
      "geo_id": "6"
  },
  {
      "province_id": "75",
      "province_code": "95",
      "province_name": "\u0e22\u0e30\u0e25\u0e32",
      "province_name_eng": "Yala",
      "geo_id": "6"
  },
  {
      "province_id": "76",
      "province_code": "96",
      "province_name": "\u0e19\u0e23\u0e32\u0e18\u0e34\u0e27\u0e32\u0e2a",
      "province_name_eng": "Narathiwat",
      "geo_id": "6"
  },
  {
      "province_id": "77",
      "province_code": "97",
      "province_name": "\u0e1a\u0e36\u0e07\u0e01\u0e32\u0e2c",
      "province_name_eng": "buogkan",
      "geo_id": "3"
  }
]