import axios from "axios";
import { ErrorAlert, SuccessAlert } from "components/alert";
import React, {createContext, useState} from "react";
import { useParams } from "react-router-dom";

export const PageContext = createContext();

const path_url = process.env.REACT_APP_API_CONNECT;

export function PageProvider(props){
    const [pageData, setPageData] = React.useState(null);
    const [color, setColor] = React.useState('#48C9B0');
    const [pageName, setPageName] = React.useState('')
    // let { name } = useParams();

    function _onFetch(name){
        setPageName(name)
        axios.post(path_url+'/landingpage/pageOfOwner',{
            userName: name
        }).then(res => {
            if(res.status === 200){
                setPageData(res.data)
                setColor(res.data.page.color ? res.data.page.color : '#48C9B0')
                // console.log(res.data)
            }
        }).catch(err =>{
            console.error(err)
        })
    }

    return(
        <PageContext.Provider
        value={{
            pageData,
            pageName,
            color,
            _onFetch
        }}
        >
            {props.children}
        </PageContext.Provider>
    )
}