import Footer from "components/footer";
import PackageComponents from "components/packagecomponents";
import React from "react";
import Navbars from "routers/navbars";

function Packages(){
    return(
        <>
        <Navbars />
        <div className="packages-container">
            <div className="packages-banner-wrapper mb-30">
                <div className="packages-banner-img" />
                <div className="packages-banner-text">
                    <label className="color-default font-weight-500" style={{fontSize: 25}}>
                        "ตลาดที่ดิน" <label className="font-weight-500 color-black" style={{fontSize: 20}}>ยกระดับการซื้อขายที่ดิน ด้วยพื้นที่สื่อกลางที่นำเสนอข้อมูลอย่างโปร่งใส ถูกต้อง ครบถ้วน ท่านสามารถเลือกบริการทั้งแบบ <i className="font-weight-500 color-black" style={{fontSize: 20}}>"ฟรี", "รายแปลง" หรือ "สมาชิกพรีเมี่ยม"</i> ที่จะมีโอกาสทำงานร่วมกับตลาดที่ดิน</label>
                    </label>
                </div>
            </div>
            <div className="packages-body mb-30">
                <PackageComponents />
            </div>
        </div>
        <Footer/>
        </>
    )
}
export default Packages;