import React from "react";

import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Label,
    FormText,
    Spinner,
    UncontrolledTooltip
} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faLocationDot,
    faTrashCan,
    faPlus,
    faCircle,
    faEllipsisV,
    faCommentDots
} from '@fortawesome/free-solid-svg-icons';
import {
    faPenToSquare,
} from '@fortawesome/free-regular-svg-icons';
import axios from "axios";
import { ErrorAlert, SuccessAlert } from "components/alert";
import Pagination from "views/pagination/Pagination";
import { PROVINCE_LIST } from "views/components/province";
import { DISTRICT_LIST } from "views/components/districts";
import Swal from "sweetalert2";
import Navbars from "routers/navbars";
import { LoginContext } from "components/loginContext/LoginContext";

const path_url = process.env.REACT_APP_API_CONNECT;

function AnnonceMn(){
    const { userData } = React.useContext(LoginContext)
    const [fetch, setFetch] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize] = React.useState(9);
    const [editToggle, setEditToggle] = React.useState(false);
    const [editData, setEditData] = React.useState(null);
    const [districtList, setDistrictList] = React.useState([]);
    const [loading, setLoading] = React.useState(false)

    const EditToggleOpen = (val) =>{
        setEditToggle(true);
        setEditData(val);
        onStartProvince(val.province)
    }

    const EditToggleClose = () =>{
        setEditToggle(false);
        setEditData(null);
    }

    React.useEffect(() => {
        if(userData !== null){
            fetchData(userData._id)
        }
    },[userData])

    const fetchData = (userid) =>{
        axios.post(path_url+'/announce/getAnnonceByUser',{
            userId: userid
        })
        .then(res =>{
            if(res.status === 200){
                setFetch(res.data.data)
                setCount(res.data.count)
            }
        })
        .catch(err =>{
            ErrorAlert({text: 'Error Server'})
        })
    }

    const intl = new Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 0,
    })

    const onChangePage = (page) =>{
        setCurrentPage(page);
    }

    const onStartProvince = (val) =>{
        const province = PROVINCE_LIST.find(element =>{
            return element.province_name === val
        });

        const district = DISTRICT_LIST.filter((item) =>{
            return item.province_id === province?.province_id
        }).map((element) =>{
            return element
        });
        setDistrictList(district);
    }

    const _onSelectProvince = (val) =>{
        const province = PROVINCE_LIST.find(element =>{
            return element.province_name === val
        });

        const district = DISTRICT_LIST.filter((item) =>{
            return item.province_id === province?.province_id
        }).map((element) =>{
            return element
        });
        setDistrictList(district);
        onChange(val, 'province');
    }

    const _onSelectDistrict = (val) =>{
        const district = DISTRICT_LIST.find(element =>{
            return element.amphur_name === val
        })
        onChange(district.amphur_name, 'district');
    }

    const onChange = (val, prop) =>{
        setEditData((item) => {
            const data = {...item};
            data[prop] = val
            return {...data}
        })
    }

    const onChangeSize = (val, prop, child) =>{
        setEditData((item) => {
            const data = {...item};
            data['size'][prop][child] = val
            return {...data}
        })
    }

    const onChangePrice = (val, prop) =>{
        setEditData((item) => {
            const data = {...item};
            data['price'][prop] = parseFloat(val)
            return {...data}
        })
    }

    const onSubmit = async (e) =>{
        e.preventDefault();
        setLoading(true)
        try{
            const res = await axios.post(path_url+'/announce/updateAnnonce',{
                ...editData
            })
            if(res.status === 200){
                setFetch((prevItems) =>{
                    return prevItems.map(item =>{
                        if(item._id === editData._id){
                            item = {...editData}
                        }
                        return item;
                    })
                },EditToggleClose())
                SuccessAlert()
                setLoading(false)
            }
        }catch(e){
            // ErrorAlert({text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'})
            console.log(e)
            setLoading(false)
        }
    }

    const DeleteAnnonce = async (val) =>{
        try{
            const res = await axios.post(path_url+'/annonce/removeAnnonce',{_id: val._id})
            if(res.status === 200){
                setFetch((prevItems) =>{
                    return prevItems.map(item =>{
                        if(item._id === val._id){
                            item = {...val, status: 'INACTIVE'}
                        }
                        return item;
                    })
                })
            }
        }catch(e){
            console.log(e)
            // ErrorAlert({text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'})
        }
    }

    const onDelete = (val) =>{
        Swal.fire({
        title: 'แน่ใจหรือไม่ต้องการลบข้อมูล',
        text: `ประกาศหา อ.${val.district} จ.${val.province}`,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        confirmButtonColor: '#EF9751',
        cancelButtonText: 'Cancle',
        cancelButtonColor: '#dc3545',
        }).then((result) =>{
            if(result.isConfirmed){
                DeleteAnnonce(val)
            }
        })
    }

    return(
        <>
        <div className="annonce-user-container">
            <div className="header mb-30 color-darkblue">รายการประกาศรับซื้อของท่าน</div>
            <div className="annonce-user-wrapper">
                <Row className="row-cols-1 row-cols-md-3 row-cols-lg-3 mb-20">
                    {fetch.length !== 0 
                    ? 
                    <>
                        {fetch?.map((item,i) =>{
                            const date = new Date(item.create_At).toLocaleDateString('th',{day:'2-digit', month:'short', year:'2-digit', hour:'2-digit', minute:'2-digit'});
                            var SizeUnitLow;
                            switch(item.size.lowest.square){
                                case 'squarewa' : SizeUnitLow = 'ตร.ว.'; 
                                break;
                                case 'ngan' : SizeUnitLow = 'งาน';
                                break;
                                case 'rai' : SizeUnitLow = 'ไร่';
                                break;
                                default : SizeUnitLow = '';
                            }
        
                            var SizeUnitMax;
                            switch(item.size.maximum.square){
                                case 'squarewa' : SizeUnitMax = 'ตร.ว.'; 
                                break;
                                case 'ngan' : SizeUnitMax = 'งาน';
                                break;
                                case 'rai' : SizeUnitMax = 'ไร่';
                                break;
                                default : SizeUnitMax = '';
                            }
                            return(
                                <Col key={i} className="mb-20">
                                    <Card>
                                        <CardBody>
                                            <div 
                                            style={{
                                                position:'absolute',
                                                top: 5,
                                                right: 5,
                                                zIndex: 2
                                            }}
                                            >
                                                <FontAwesomeIcon icon={faCircle} className={item.status === "ACTIVE" ? "color-green" : "color-red"} />
                                            </div>
                                            <div className="title-annonce">
                                                <div className="flex">
                                                    <p className="label-1">
                                                        <FontAwesomeIcon icon={faLocationDot} style={{marginRight:5}} />
                                                        จ.{item.province}
                                                    </p>
                                                    <p className="label-2">
                                                        อ.{item.district}
                                                    </p>
                                                </div>
                                                <small >
                                                    ประกาศเมื่อ {date}
                                                </small>
                                            </div>
                                            <div className="text-description">
                                                <div className="text-detail mb-20 color-darkblue">
                                                    {item.description}
                                                </div>
                                                <div className="flex font-weight-500 color-default">
                                                    ขนาดเนื้อที่: <div className="font-weight-300 ml-10 color-darkblue">{`${item.size.lowest.count} ${SizeUnitLow} - ${item.size.maximum.count} ${SizeUnitMax}`}</div>
                                                </div>
                                                <div className="flex font-weight-500 color-default">
                                                    ราคารวม: 
                                                </div>
                                                <div className="color-darkblue">
                                                    {fetch ? 
                                                    `${intl.format(item.price.lowest)} - ${intl.format(item.price.maximum)}`
                                                    : null}
                                                </div>
                                            </div>
                                        </CardBody>
                                        <CardFooter
                                        style={{
                                            display:'flex',
                                            flexDirection:'row',
                                            justifyContent:'flex-end',
                                            padding:10
                                        }}
                                        >
                                            <Button 
                                            color="transparent"
                                            style={{color: '#909090', marginRight: 20}}
                                            onClick={() => window.open('/announce_view/'+ item._id)}
                                            >
                                                <FontAwesomeIcon icon={faCommentDots} style={{fontSize: 20}} /> {item?.comments?.length}
                                            </Button>
                                            <Button 
                                            id={`edit${i}`}
                                            color="round-sky-sm" 
                                            onClick={() => EditToggleOpen(item)}
                                            >
                                                <FontAwesomeIcon icon={faPenToSquare} />
                                            </Button>
                                            <UncontrolledTooltip
                                            target={`edit${i}`}
                                            >
                                                แก้ไข
                                            </UncontrolledTooltip>
                                            <Button 
                                            id={`info${i}`}
                                            color="round-default-sm"
                                            onClick={() => window.open('/announce_view/'+ item._id)}
                                            >
                                                <FontAwesomeIcon icon={faEllipsisV} style={{fontSize: 20}} />
                                            </Button>
                                            <UncontrolledTooltip
                                            target={`info${i}`}
                                            >
                                                รายละเอียด
                                            </UncontrolledTooltip>
                                            <Button 
                                            id={`delete${i}`}
                                            color="round-danger-sm" 
                                            onClick={() => onDelete(item)} 
                                            disabled={item.status === 'INACTIVE' ? true : false}
                                            >
                                                <FontAwesomeIcon icon={faTrashCan} />
                                            </Button>
                                            <UncontrolledTooltip
                                            target={`delete${i}`}
                                            >
                                                ลบ
                                            </UncontrolledTooltip>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            )
                        })}
                    </>
                    :
                    <div> ไม่พบข้อมูล </div>
                    }
                </Row>
                <div 
                className="mb-20 mr-20"
                style={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'flex-end',
                }}>
                    <Pagination 
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={count}
                    pageSize={pageSize}
                    onPageChange={page => onChangePage(page)}
                    />
                </div>  
            </div>
        </div>
        <Modal isOpen={editToggle} toggle={EditToggleClose} className="modal-lg">
            <ModalHeader toggle={EditToggleClose}>แก้ไข</ModalHeader>
            {editData && 
            <Form onSubmit={onSubmit}>
                <ModalBody>
                    <div 
                    style={{fontWeight:400}} 
                    className="color-darkblue"
                    >
                        ที่อยู่ของแปลงที่ดิน
                    </div>
                    <Row className="mb-20">
                        <Col className="col-12 col-md-6 col-lg-6">
                            <FormGroup className="form-group">
                                <Label className="color-darkblue">จังหวัด</Label>
                                <Input 
                                type="select" 
                                className="border color-darkblue form-control"
                                value={editData.province}
                                onChange={(val) => _onSelectProvince(val.target.value)}
                                >
                                    <option key={0} value={""}>จังหวัด</option>
                                    {PROVINCE_LIST.map((item, i) =>{
                                        return(
                                            <option key={i} value={item.province_name}>
                                                {item.province_name}
                                            </option>
                                        )
                                    })}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col className="col-12 col-md-6 col-lg-6">
                            <FormGroup className="form-group">
                                <Label className="color-darkblue">อำเภอ</Label>
                                <Input 
                                type="select" 
                                className="border color-darkblue form-control"
                                value={editData.district}
                                onChange={(val) => _onSelectDistrict(val.target.value)}
                                >
                                    <option key={0} value={""}>อำเภอ</option>
                                    {districtList.map((item, i) =>{
                                        return(
                                            <option key={i} value={item.amphur_name}>
                                                {item.amphur_name}
                                            </option>
                                        )
                                    })}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div 
                    style={{fontWeight:400}} 
                    className="color-darkblue"
                    >
                        ขนาดที่ดินที่ต้องการ
                    </div>
                    <Row className="mb-20">
                        <Col>
                            <FormGroup className="form-group" style={{flexDirection:'row', alignItems:'center'}}>
                                <InputGroup style={{margin: 10}}>
                                    <Input 
                                    type="number"
                                    placeholder="ขนาดต่ำสุด" 
                                    className="color-darkblue" 
                                    value={editData.size.lowest.count}
                                    onChange={(val) => onChangeSize(val.target.value, 'lowest', 'count')}
                                    />
                                    <Input 
                                    type="select" 
                                    className="form-select color-darkblue"
                                    value={editData.size.lowest.square}
                                    onChange={(val) => onChangeSize(val.target.value, 'lowest', 'square')}
                                    >
                                        <option className="color-darkblue" value='squarewa'>ตร.ว</option>
                                        <option className="color-darkblue" value='ngan'>งาน</option>
                                        <option className="color-darkblue" value='rai'>ไร่</option>
                                    </Input>
                                </InputGroup>
                                <label>ถึง</label>
                                <InputGroup style={{margin: 10}}>
                                    <Input 
                                    type="number"
                                    placeholder="ขนาดสูงสุด" 
                                    className="color-darkblue" 
                                    value={editData.size.maximum.count}
                                    onChange={(val) => onChangeSize(val.target.value, 'maximum', 'count')}
                                    />
                                    <Input 
                                    type="select" 
                                    className="form-select color-darkblue"
                                    value={editData.size.maximum.square}
                                    onChange={(val) => onChangeSize(val.target.value, 'maximum', 'square')}
                                    >
                                        <option className="color-darkblue" value='squarewa'>ตร.ว</option>
                                        <option className="color-darkblue" value='ngan'>งาน</option>
                                        <option className="color-darkblue" value='rai'>ไร่</option>
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div 
                    style={{fontWeight:400}} 
                    className="color-darkblue"
                    >
                        ราคา(รวม) ที่ตั้งรับ
                    </div>
                    <Row className="mb-20">
                        <Col className="col-12 col-md-6 col-lg-6">
                            <FormGroup className="form-group">
                                <Input 
                                type='range'
                                min={0}
                                max={100000000} 
                                placeholder="ระบุราคา" 
                                step={25000}
                                className="mb-10" 
                                value={editData.price.lowest}
                                onChange={(val) => onChangePrice(val.target.value, 'lowest')}
                                />
                                <FormText 
                                className="color-darkblue"
                                style={{fontSize:16, fontWeight: 400, display:'flex', alignItems:'center'}}
                                >
                                    ต่ำสุด 
                                    <Input 
                                    type="number" 
                                    placeholder="ระบุราคา" 
                                    style={{width: 250}}
                                    className="color-darkblue border" 
                                    value={editData.price.lowest}
                                    onChange={(val) => onChangePrice(val.target.value, 'lowest')}
                                    /> 
                                    {editData.price.lowest >= 100000000 ? <FontAwesomeIcon icon={faPlus} className="mr-10" /> : null } บาท
                                </FormText>
                            </FormGroup>
                        </Col>
                        <Col className="col-12 col-md-6 col-lg-6">
                            <FormGroup className="form-group">
                                <Input 
                                type='range'
                                min={0}
                                max={100000000} 
                                step={25000}
                                placeholder="ระบุราคา" 
                                className="mb-10"
                                value={editData.price.maximum}
                                onChange={(val) => onChangePrice(val.target.value, 'maximum')}
                                />
                                <FormText 
                                style={{fontSize:16, fontWeight: 400, display:'flex', alignItems:'center'}}
                                className="color-darkblue"
                                >
                                    สูงสุด 
                                    <Input 
                                    type="number" 
                                    placeholder="ระบุราคา" 
                                    className="color-darkblue border" 
                                    style={{width: 250}}
                                    value={editData.price.maximum}
                                    onChange={(val) => onChangePrice(val.target.value, 'maximum')}
                                    /> {editData.price.maximum >= 100000000 ? <FontAwesomeIcon icon={faPlus} className="mr-10" /> : null } บาท
                                </FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-20">
                        <Col>
                            <div 
                            style={{fontWeight:400}} 
                            className="color-darkblue"
                            >
                                วัตถุประสงค์ <label className="color-red">(เลือกได้ 1 ตัวเลือกเท่านั้น)</label>
                            </div>
                            <FormGroup style={{margin: 10}} check>
                                <Input 
                                type="radio" 
                                name="radio1" 
                                value="build" 
                                className="mr-10" 
                                checked={editData.choice === "build"}
                                onChange={val => onChange(val.target.value, 'choice')} 
                                />
                                <Label check>สร้างบ้าน</Label>
                            </FormGroup>
                            <FormGroup style={{margin: 10}} check>
                                <Input 
                                type="radio" 
                                name="radio1" 
                                value="SmallHouse" 
                                className="mr-10" 
                                checked={editData.choice === "SmallHouse"}
                                onChange={val => onChange(val.target.value, 'choice')} 
                                />
                                <Label check>พัฒนาโครงการที่อยู่อาศัยขนาดเล็ก</Label>
                            </FormGroup>
                            <FormGroup style={{margin: 10}} check>
                                <Input 
                                type="radio" 
                                name="radio1" 
                                value="LargeResidential" 
                                className="mr-10" 
                                checked={editData.choice === "LargeResidential"}
                                onChange={val => onChange(val.target.value, 'choice')} 
                                />
                                <Label check>พัฒนาโครงการที่อยู่อาศัยขนาดใหญ่</Label>
                            </FormGroup>
                            <FormGroup style={{margin: 10}} check>
                                <Input 
                                type="radio" 
                                name="radio1" 
                                value="BigBusiness" 
                                className="mr-10" 
                                checked={editData.choice === "BigBusiness"}
                                onChange={val => onChange(val.target.value, 'choice')} 
                                />
                                <Label check>ธุรกิจขนาดใหญ่</Label>
                            </FormGroup>
                            <FormGroup style={{margin: 10}} check>
                                <Input 
                                type="radio" 
                                name="radio1" 
                                value="SME" 
                                className="mr-10" 
                                checked={editData.choice === "SME"}
                                onChange={val => onChange(val.target.value, 'choice')} 
                                />
                                <Label check>ธุรกิจขนาดเล็ก SME</Label>
                            </FormGroup>
                            <FormGroup style={{margin: 10}} check>
                                <Input 
                                type="radio" 
                                name="radio1" 
                                value="Speculate" 
                                className="mr-10" 
                                checked={editData.choice === "Speculate"}
                                onChange={val => onChange(val.target.value, 'choice')} 
                                />
                                <Label check>เก็งกำไร</Label>
                            </FormGroup>
                            <FormGroup style={{margin: 10}} check>
                                <Input 
                                type="radio" 
                                name="radio1" 
                                value="Farming" 
                                className="mr-10" 
                                checked={editData.choice === "Farming"}
                                onChange={val => onChange(val.target.value, 'choice')} 
                                />
                                <Label check>การเกษตร</Label>
                            </FormGroup>
                            <FormGroup className="form-group" style={{margin: 10}} check>
                                <Input 
                                type="radio" 
                                name="radio1" 
                                value="More" 
                                className="mr-10" 
                                checked={editData.choice === "More"}
                                onChange={val => onChange(val.target.value, 'choice')} 
                                />
                                <Label check>อื่นๆ</Label>
                                <Input 
                                type="text" 
                                className="border" 
                                placeholder="ระบุความต้องการอื่นๆ" 
                                disabled={editData.choice === "More" ? false : true} 
                                value={editData.more ? editData.more : ""}
                                onChange={val => onChange(val.target.value, 'more')} 
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-20">
                        <Col>
                            <div 
                            style={{fontWeight:400}} 
                            className="color-darkblue"
                            >
                                คีย์เวิร์ด/สถานที่ใกล้เคียง/รายละเอียด
                            </div>
                            <FormGroup className="form-group mt-10">
                                <Input
                                type="textarea" 
                                className="form-textarea"
                                rows={7} 
                                placeholder="ติดถนนหลัก/ที่นา/ย่านการค้า/ติดธรรมชาติ"
                                value={editData.description}
                                onChange={val => onChange(val.target.value, 'description')} 
                                />
                            </FormGroup>
                        </Col>
                    </Row> 
                </ModalBody>
                <ModalFooter>
                    {loading ? 
                    <Button color="default-sm">
                        <Spinner size={'sm'} /> Loading...
                    </Button>
                    :
                    <Button color="default-sm" type="submit">
                        update
                    </Button>
                    }
                </ModalFooter>
            </Form>}
        </Modal>
        </>
    )
}

export default AnnonceMn;