import React from "react";

import { 
    FormGroup,
    Input,
    InputGroup,
    Label,
    Row,
    Col,
    Form,
    Button,
    FormText,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Navbars from "routers/navbars";
import Footer from "components/footer";

import { PROVINCE_LIST } from "./components/province";
import { DISTRICT_LIST } from "./components/districts";
import { SuccessAlert, ErrorAlert } from "components/alert";
import axios from "axios";
import LoginModal from "components/loginModal";
import { LoginContext } from "components/loginContext/LoginContext";

const path_url = process.env.REACT_APP_API_CONNECT;

function Announce(){
    const [DistrictList, setDistrictList] = React.useState([]);
    const {userData, setUserData} = React.useContext(LoginContext);
    const [phoneIsOpen, setPhoneIsOpen] = React.useState(false);
    const [Data, setData] = React.useState({
        province:{
            id: '0',
            name: ''
        },
        district: {
            id: '0',
            name: ''
        },
        size: {
            lowest: {
                count: '',
                square: 'squarewa'
            },
            maximum: {
                count: '',
                square: 'squarewa'
            }
        },
        price: {
            lowest: 0,
            maximum: 0
        },
        choice: null,
        description: ''
    })
    const [MoreText, setMorText] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [loginOpen, setLoginOpen] = React.useState(false);
    const [checkBefore, setCheckBefore] = React.useState(false)

    React.useEffect(() => {
        const user = localStorage.getItem("profile")
        if(user !== null){
            if(!userData?.phoneNumber){
                setPhoneIsOpen(true)
                // onChangePhoneAndName(parseUser.name, 'name')
            }
        }else{
            setLoginOpen(true)
        }
    },[])

    const _onSelectProvince = (val) =>{
        const province = PROVINCE_LIST.find(element =>{
            return element.province_id === val
        });

        const district = DISTRICT_LIST.filter((item) =>{
            return item.province_id === province?.province_id
        }).map((element) =>{
            return element
        });
        setDistrictList(district);
        inputAddress({id: province.province_id, name: province.province_name}, 'province')
        inputAddress({id: '0', name: ''}, "district")
    }

    const _onChangDistrict = (val) =>{
        const district = DISTRICT_LIST.find(element =>{
            return element.amphur_id === val
        })
        inputAddress({id: district.amphur_id, name: district.amphur_name}, 'district')
    }

    const inputData = (val, props) =>{
        setData((items) => {
            const data = {...items};
            data[props] = val;
            return {...data}
        })
    }

    const inputAddress = (val, props) =>{
        setData((items) => {
            const data = {...items};
            data[props] = val;
            return {...data}
        })
    }

    const inputSize = (val, props, child) =>{
        setData((items) => {
            const data = {...items};
            data['size'][props][child] = val;
            return {...data}
        })
    }

    const inputPrice = (val, child) =>{
        setData((items) => {
            const data = {...items};
            data['price'][child] = parseInt(val);
            return {...data}
        })
    }

    const onSubmit = (e) =>{
        e.preventDefault();
        // console.log(Data)
        setLoading(true);
        try{
            if(!userData){
                setLoginOpen(true)
                setLoading(false);
            }else{
                if(
                    Data.province.name && 
                    Data.district.name && 
                    Data.size.lowest.count && 
                    Data.size.maximum.count &&
                    Data.price.lowest &&
                    Data.price.maximum &&
                    Data.choice
                ){
                    axios.post(path_url+"/announce/addAnnonce", {
                        ...Data,
                        user: userData._id,
                        name: userData.name,
                        province: Data.province.name,
                        district: Data.district.name,
                        more: MoreText
                    })
                    .then(res =>{
                        if(res.status === 200){
                            SuccessAlert();
                            setLoading(false);
                            setTimeout(() => {
                                window.location.reload()
                            },1500)
                        }
                    }).catch((err) =>{
                        setLoading(false);
                        // ErrorAlert({text: 'การบันทึกไม่สำเร็จ กรุณาลองใหม่ภายหลัง'})
                    })
                }else{
                    setLoading(false);
                    setCheckBefore(true);
                }
            }
        }catch(e){
            setLoading(false);
            ErrorAlert({text: 'ข้อมูลผิดพลาด กรุณาตรวจสอบอีกครั้ง'})
        }
    }

    function onChangePhoneAndName(val, props){
        setUserData(item =>{
            const data = {...item};
            data[props] = val;
            return{...data}
        })
    }

    const onSubmitUpdate = async (e) =>{
        e.preventDefault();
        try{
            const res = await axios.post(path_url+'/user/updatePhoneAndName',{
                _id: userData._id,
                name: userData.name,
                phoneNumber : userData.phoneNumber,
                email: userData.email
            })

            if(res.status === 200){
                SuccessAlert();
                setPhoneIsOpen(false)
                setUserData((item) =>{
                    const data = {...item}
                    return {...data}
                })
            }
        }catch(e){
            console.log(e)
            ErrorAlert({text: 'เกิดข้อผิดพลาด! กรุณาลองใหม่อีกครั้ง'})
        }
    }

    return(
        <>
        <Navbars/>
        <div className="annonce-containner">
            <div className="annonce-title mb-50">
                ประกาศ<p className="underline">รับซื้อที่ดิน</p>
            </div>
            <div className="annonce-detail">
                <Form onSubmit={onSubmit}>
                    <Row className="mb-50">
                        <Col className="col-12 col-md-8 col-lg-8">
                            <h5 className="underline mb-10">1. ที่อยู่ของแปลงที่ดิน</h5>
                            <Row>
                                <Col className="col-12 col-md-6 col-lg-6">
                                    <FormGroup className="form-group">
                                        <Input 
                                        name="province" 
                                        type="select"
                                        className="form-control color-darkblue border" 
                                        placeholder="จังหวัด" 
                                        value={Data.province.id}
                                        onChange={(val) => _onSelectProvince(val.target.value)}
                                        >
                                            <option value={0} disabled>จังหวัด</option>
                                            {PROVINCE_LIST.map((item, i) =>(
                                                <option key={i} value={item.province_id}>
                                                    {item.province_name}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col className="col-12 col-md-6 col-lg-6">
                                    <FormGroup className="form-group">
                                        <Input 
                                        type="select"
                                        className="form-control color-darkblue border" 
                                        placeholder="อำเภอ" 
                                        value={Data.district.id}
                                        disabled={DistrictList.length !== 0 ? false: true}
                                        onChange={(val) => _onChangDistrict(val.target.value)}
                                        >
                                            <option value={0} disabled>อำเภอ</option>
                                            {DistrictList.map((item, i) =>{
                                                return(
                                                    <option key={i} value={item.amphur_id}>
                                                        {item.amphur_name}
                                                    </option>
                                                )
                                            })}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mb-50">
                        <Col className="col-12 col-md-8 col-lg-8">
                            <h5 className="underline">2. ขนาดที่ดินที่ต้องการ</h5>
                            <FormGroup className="form-group" style={{flexDirection:'row', alignItems:'center'}}>
                                <InputGroup style={{margin: 10}}>
                                    <Input 
                                    type="number"
                                    placeholder="ขนาดต่ำสุด" 
                                    className="color-darkblue" 
                                    value={Data.size.lowest.count}
                                    onChange={(val) => inputSize(val.target.value, 'lowest', 'count')}
                                    />
                                    <Input 
                                    type="select" 
                                    className="form-select color-darkblue"
                                    value={Data.size.lowest.square}
                                    onChange={(val) => inputSize(val.target.value, 'lowest', 'square')}
                                    >
                                        <option className="color-darkblue" value='squarewa'>ตร.ว</option>
                                        <option className="color-darkblue" value='ngan'>งาน</option>
                                        <option className="color-darkblue" value='rai'>ไร่</option>
                                    </Input>
                                </InputGroup>
                                <label>ถึง</label>
                                <InputGroup style={{margin: 10}}>
                                    <Input 
                                    type="number"
                                    placeholder="ขนาดสูงสุด" 
                                    className="color-darkblue" 
                                    value={Data.size.maximum.count}
                                    onChange={(val) => inputSize(val.target.value, 'maximum', 'count')}
                                    />
                                    <Input 
                                    type="select" 
                                    className="form-select color-darkblue"
                                    value={Data.size.maximum.square}
                                    onChange={(val) => inputSize(val.target.value, 'maximum', 'square')}
                                    >
                                        <option className="color-darkblue" value='squarewa'>ตร.ว</option>
                                        <option className="color-darkblue" value='ngan'>งาน</option>
                                        <option className="color-darkblue" value='rai'>ไร่</option>
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-50">
                        <Col className="col-12 col-md-8 col-lg-8">
                            <h5 className="underline">3. ราคา(รวม) ที่ตั้งรับ</h5>
                            <Row>
                                <Col className="col-6 col-md-6 col-lg-6">
                                    <FormGroup className="form-group">
                                        <div style={{margin: 10}}>
                                            <Input 
                                            type='range'
                                            min={0}
                                            max={100000000} 
                                            placeholder="ระบุราคา" 
                                            step={25000}
                                            // className="color-darkblue" 
                                            value={Data.price.lowest}
                                            onChange={(val) => inputPrice(val.target.value, 'lowest')}
                                            />
                                            <FormText style={{fontWeight: 400, display:'flex', alignItems:'center'}}>
                                                ต่ำสุด 
                                                <Input 
                                                type="number" 
                                                placeholder="ระบุราคา" 
                                                style={{width: 250}}
                                                className="color-darkblue border mr-5 ml-5" 
                                                value={Data.price.lowest}
                                                onChange={(val) => inputPrice(val.target.value, 'lowest')}
                                                /> 
                                                {Data.price.lowest >= 100000000 ? 
                                                <FontAwesomeIcon icon={faPlus} className="mr-10" />
                                                : null } บาท
                                            </FormText>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col className="col-1 col-md-1 col-lg-1">
                                    <label>ถึง</label>
                                </Col>
                                <Col className="col-5 col-md-5 col-lg-5">
                                    <FormGroup className="form-group">
                                        <div style={{margin: 10}}>
                                            <Input 
                                            type='range'
                                            min={0}
                                            max={100000000} 
                                            step={25000}
                                            placeholder="ระบุราคา" 
                                            className="color-darkblue" 
                                            value={Data.price.maximum}
                                            onChange={(val) => inputPrice(val.target.value, 'maximum')}
                                            />
                                            <FormText style={{fontWeight: 400, display:'flex', alignItems:'center'}}>
                                                {/* สูงสุด {parePrice(Data.price.maximum)}{Data.price.maximum === 100000000 && "+"} บาท */}
                                                สูงสุด 
                                                <Input 
                                                type="number" 
                                                placeholder="ระบุราคา" 
                                                className="color-darkblue border mr-5 ml-5" 
                                                style={{width: 250}}
                                                value={Data.price.maximum}
                                                onChange={(val) => inputPrice(val.target.value, 'maximum')}
                                                /> {Data.price.maximum >= 100000000 ? 
                                                <FontAwesomeIcon icon={faPlus} className="mr-10" />
                                                : null } บาท
                                            </FormText>
                                            
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {/* <FormGroup className="form-group" style={{flexDirection:'row', alignItems:'center'}}>
                                <div style={{margin: 10}}>
                                    <Input 
                                    type='range'
                                    min={0}
                                    max={5000000} 
                                    placeholder="ระบุราคา" 
                                    className="color-darkblue" 
                                    value={Data.price.lowest}
                                    onChange={(val) => inputPrice(val.target.value, 'lowest')}
                                    />
                                </div>
                                <label>ถึง</label>
                                <div style={{margin: 10}}>
                                    <Input 
                                    type="text" 
                                    placeholder="ระบุราคา" 
                                    className="color-darkblue" 
                                    value={Data.price.maximum}
                                    onChange={(val) => inputPrice(val.target.value, 'maximum')}
                                    />
                                </div>
                            </FormGroup> */}
                        </Col>
                    </Row>
                    {/* <Row className="mb-50">
                        <Col className="col-12 col-md-8 col-lg-8">
                            <h5 className="underline">4. ช่องทางการติดต่อ</h5>
                            <FormGroup className="form-group" style={{flexDirection:'row', alignItems:'center'}}>
                                <InputGroup style={{margin: 10}}>
                                    <Input 
                                    type="text" 
                                    placeholder="ชื่อผู้ติดต่อ" 
                                    className="color-darkblue" 
                                    value={Data.name}
                                    onChange={(val) => inputData(val.target.value, 'name')}
                                    />
                                </InputGroup>
                                <InputGroup style={{margin: 10}}>
                                    <FontAwesomeIcon icon={faPhone} style={{marginLeft:5, marginRight:5, color:'#23265F'}} />
                                    <Input 
                                    type="text" 
                                    placeholder="08x-xxxxxxx" 
                                    className="color-darkblue" 
                                    value={Data.phoneNumber}
                                    onChange={(val) => inputData(val.target.value, 'phoneNumber')}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row> */}
                    <Row className="mb-50">
                        <Col className="col-12 col-md-8 col-lg-8">
                            <h5 className="underline">4. วัตถุประสงค์ <label className="color-red">(เลือกได้ 1 ตัวเลือกเท่านั้น)</label> </h5>
                            <div className=" mt-10"></div>
                            <FormGroup style={{margin: 10}} check>
                                <Input 
                                type="radio" 
                                name="radio1" 
                                value="build" 
                                className="mr-10" 
                                checked={Data.choice === "build"}
                                onChange={val => inputData(val.target.value, 'choice')} 
                                />
                                <Label className="h6" check>สร้างบ้าน</Label>
                            </FormGroup>
                            <FormGroup style={{margin: 10}} check>
                                <Input 
                                type="radio" 
                                name="radio1" 
                                value="SmallHouse" 
                                className="mr-10" 
                                checked={Data.choice === "SmallHouse"}
                                onChange={val => inputData(val.target.value, 'choice')} 
                                />
                                <Label className="h6" check>พัฒนาโครงการที่อยู่อาศัยขนาดเล็ก</Label>
                            </FormGroup>
                            <FormGroup style={{margin: 10}} check>
                                <Input 
                                type="radio" 
                                name="radio1" 
                                value="LargeResidential" 
                                className="mr-10" 
                                checked={Data.choice === "LargeResidential"}
                                onChange={val => inputData(val.target.value, 'choice')} 
                                />
                                <Label className="h6" check>พัฒนาโครงการที่อยู่อาศัยขนาดใหญ่</Label>
                            </FormGroup>
                            <FormGroup style={{margin: 10}} check>
                                <Input 
                                type="radio" 
                                name="radio1" 
                                value="BigBusiness" 
                                className="mr-10" 
                                checked={Data.choice === "BigBusiness"}
                                onChange={val => inputData(val.target.value, 'choice')} 
                                />
                                <Label className="h6" check>ธุรกิจขนาดใหญ่</Label>
                            </FormGroup>
                            <FormGroup style={{margin: 10}} check>
                                <Input 
                                type="radio" 
                                name="radio1" 
                                value="SME" 
                                className="mr-10" 
                                checked={Data.choice === "SME"}
                                onChange={val => inputData(val.target.value, 'choice')} 
                                />
                                <Label className="h6" check>ธุรกิจขนาดเล็ก SME</Label>
                            </FormGroup>
                            <FormGroup style={{margin: 10}} check>
                                <Input 
                                type="radio" 
                                name="radio1" 
                                value="Speculate" 
                                className="mr-10" 
                                checked={Data.choice === "Speculate"}
                                onChange={val => inputData(val.target.value, 'choice')} 
                                />
                                <Label className="h6" check>เก็งกำไร</Label>
                            </FormGroup>
                            <FormGroup style={{margin: 10}} check>
                                <Input 
                                type="radio" 
                                name="radio1" 
                                value="Farming" 
                                className="mr-10" 
                                checked={Data.choice === "Farming"}
                                onChange={val => inputData(val.target.value, 'choice')} 
                                />
                                <Label className="h6" check>การเกษตร</Label>
                            </FormGroup>
                            <FormGroup className="form-group" style={{margin: 10}} check>
                                <Input 
                                type="radio" 
                                name="radio1" 
                                value="More" 
                                className="mr-10" 
                                checked={Data.choice === "More"}
                                onChange={val => inputData(val.target.value, 'choice')} 
                                />
                                <Label className="h6" check>อื่นๆ</Label>
                                <Input 
                                type="text" 
                                className="border" 
                                placeholder="ระบุความต้องการอื่นๆ" 
                                disabled={Data.choice === "More" ? false : true} 
                                value={MoreText}
                                onChange={val => setMorText(val.target.value)} 
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-50">
                        <Col className="col-12 col-md-8 col-lg-8">
                            <h5 className="underline">5. คีย์เวิร์ด/สถานที่ใกล้เคียง/รายละเอียด</h5>
                            <FormGroup className="form-group mt-10">
                                <Input
                                type="textarea" 
                                className="form-textarea"
                                rows={7} 
                                placeholder="ติดถนนหลัก/ที่นา/ย่านการค้า/ติดธรรมชาติ"
                                value={Data.description}
                                onChange={val => inputData(val.target.value, 'description')} 
                                />
                            </FormGroup>
                        </Col>
                    </Row> 
                    <Row className="mb-50">
                        <Col >
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <Button type="submit" color="default">
                                {loading ?
                                    "Loading..."
                                :
                                    "ส่งข้อมูลการประกาศ"
                                }
                                </Button>
                                
                            </div>
                        </Col>
                    </Row>
                    {/* {!Data.province.name && 
                    !Data.district.name && 
                    !Data.size.lowest.count && 
                    !Data.size.maximum.count &&
                    !Data.price.lowest &&
                    !Data.price.maximum &&
                    !Data.choice ? 
                    <Row className="mb-50">
                        <Col className="col-12 col-md-5 col-lg-5">
                            <div className="text-error-box">
                                <ul className="error-list">
                                    {!Data.province.name ? <li>กรุณาเปลี่ยนจังหวัดที่คุณต้องการหาที่ดิน</li> : null}
                                    {!Data.district.name ? <li>กรุณาเปลี่ยนอำเภอที่คุณต้องการหาที่ดิน</li> : null}
                                    {!Data.size.lowest.count ? <li>กรุณาเพิ่มขนาดที่ดินต่ำสุดที่ต้องการ ใส่เป็นตัวเลข 1 - 999</li> : null}
                                    {!Data.size.maximum.count ? <li>กรุณาเพิ่มขนาดที่ดินสูงสุดที่ต้องการ ใส่เป็นตัวเลข 1 - 999</li> : null}
                                    {!Data.price.lowest ? <li>กรุณาเพิ่มราคาต่ำที่สุดที่ต้องการ ใส่เป็นตัวเลข 1 - 999</li> : null}
                                    {!Data.price.maximum ? <li>กรุณาเพิ่มราคาสูงที่สุดที่ต้องการ ใส่เป็นตัวเลข 1 - 999</li> : null}
                                    {!Data.choice ? <li>กรุณาเลือกวัตถุประสงค์ 1 ตัวเลือก</li> : null}
                                </ul>
                            </div>
                        </Col>
                    </Row> : null} */}
                </Form>
            </div>
        </div>
        <LoginModal isOpen={loginOpen} onClose={() => setLoginOpen(false)} />
        <Modal isOpen={checkBefore} toggle={() => setCheckBefore(false)}>
            <ModalHeader toggle={() => setCheckBefore(false)}></ModalHeader>
            <ModalBody>
                <Row>
                    <Col className="col-12 col-md-12 col-lg-12">
                        <div className="text-error-box">
                            <ul className="error-list">
                                {!Data.province.name ? <li>กรุณาเปลี่ยนจังหวัดที่คุณต้องการหาที่ดิน</li> : null}
                                {!Data.district.name ? <li>กรุณาเปลี่ยนอำเภอที่คุณต้องการหาที่ดิน</li> : null}
                                {!Data.size.lowest.count ? <li>กรุณาเพิ่มขนาดที่ดินต่ำสุดที่ต้องการ ใส่เป็นตัวเลข 1 - 999</li> : null}
                                {!Data.size.maximum.count ? <li>กรุณาเพิ่มขนาดที่ดินสูงสุดที่ต้องการ ใส่เป็นตัวเลข 1 - 999</li> : null}
                                {!Data.price.lowest ? <li>กรุณาเพิ่มราคาต่ำที่สุดที่ต้องการ ใส่เป็นตัวเลข 1 - 999</li> : null}
                                {!Data.price.maximum ? <li>กรุณาเพิ่มราคาสูงที่สุดที่ต้องการ ใส่เป็นตัวเลข 1 - 999</li> : null}
                                {!Data.choice ? <li>กรุณาเลือกวัตถุประสงค์ 1 ตัวเลือก</li> : null}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button 
                color="transparent" 
                onClick={() => setCheckBefore(false)}
                >
                    ปิด
                </Button>
            </ModalFooter>
        </Modal>

        <Modal isOpen={phoneIsOpen}>
            <ModalHeader>แก้ไขข้อมูลส่วนตัว</ModalHeader>
            <Form onSubmit={onSubmitUpdate}>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup className="form-group">
                                <Label>Email</Label>
                                <Input 
                                className="color-darkblue border" 
                                type="text"
                                value={userData?.email}
                                onChange={(val) => onChangePhoneAndName(val.target.value, 'email')}
                                />
                                <FormText className="color-red">โปรดระบุอีเมลที่ท่านใช้งาน เพื่อการได้รับข่าวสารหรือการแจ้งเตือน</FormText>
                            </FormGroup>           
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className="form-group">
                                <Label>ชื่อ-สกุล</Label>
                                <Input 
                                className="color-darkblue border" 
                                type="text"
                                value={userData?.name}
                                onChange={(val) => onChangePhoneAndName(val.target.value, 'name')}
                                />
                            </FormGroup>           
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className="form-group">
                                <Label>หมายเลขโทรศัพท์</Label>
                                <Input 
                                className="color-darkblue border" 
                                type="tel" 
                                value={userData?.phoneNumber}
                                onChange={(val) => onChangePhoneAndName(val.target.value, 'phoneNumber')}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" color="default">บันทึกการเปลี่ยนแปลง</Button>
                </ModalFooter>
            </Form>
        </Modal>
        <Footer/>
        </>
    )
}
export default Announce