import React from "react";

import {
    Button
} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLine } from '@fortawesome/free-brands-svg-icons'

import ads1_more from 'assets/img/Package/more/raw.png';
import ads2_more from 'assets/img/Package/more/consoind.png';
import ads3_more from 'assets/img/Package/more/drone.png';
import ads4_more from 'assets/img/Package/more/anylize.png';
import ads5_more from 'assets/img/Package/more/happy.png';

const line_url = process.env.REACT_APP_LINE_PATH;

function MorePackage(){
    return(
        <>
        <div className="morepackage-container">
            <h3 className="color-default text-center mb-50 font-weight-500">
                บริการอื่นๆ เพิ่มเติม
            </h3>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                <p className="color-default text-center max-width-600">
                    นอกเหนือจากบริการเสริมต่างๆ แล้วตลาดที่ดินยังมีทีมงานผู้เชี่ยวชาญในด้านต่างๆ เรื่องที่ดิน
                </p>
            </div>
            <div className="container-left">
                <img alt="img-1" src={ads5_more} className="ads-img" />
                <div className="txt-container">
                    <p className="color-default font-weight-500">1.ปรึกษากฎหมาย/ทนายความ ผู้เชี่ยวชาญด้านที่ดิน</p>
                    <div>
                        หากท่านต้องการหาข้อมูล หรือปรึกษาเกี่ยวกับกฎหมายที่ดินต่างๆ 
                        <label className="color-default"> เรามีทีมงานทนายความที่มีความเชี่ยวชาญด้านที่ดิน </label>ให้คำปรึกษาท่าน
                    </div>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', marginTop: 20}}>
                        <Button color="default" onClick={() => window.open(line_url)}>
                            ติดต่อสอบถาม <FontAwesomeIcon icon={faLine} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="container-right">
                <img alt="img-1" src={ads2_more} className="ads-img" />
                <div className="txt-container">
                    <p className="color-default font-weight-500">2.ปรึกษาเรื่องการแบ่งแปลง / ออกแบบผังโครงการ</p>
                    <div>
                        การแบ่งแปลงที่ดิน อาจทำให้เพิ่มโอกาสในการขาย และสร้างผลกำไรจากการแบ่งแปลง 
                        <label className="color-default"> เรามีทีมงานที่มีความเชี่ยวชาญด้านการแบ่งแปลง หรือการออกแบบผังโครงการ</label>
                    </div>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', marginTop: 20}}>
                        <Button color="default" onClick={() => window.open(line_url)}>
                            ติดต่อสอบถาม <FontAwesomeIcon icon={faLine} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="container-left">
                <img alt="img-1" src={ads3_more} className="ads-img" />
                <div className="txt-container">
                    <p className="color-default font-weight-500">3.บริการถ่ายภาพนิ่ง, วีดีโอ, โดรน</p>
                    <div>
                        ภาพถ่าย รวมถึงวีดีโอ อีกทั้งภาพและวีดีโอจากโดรน เป็นสื่อกลางที่จะช่วยนำเสนอที่ดินของท่านให้มีความน่าสนใจ  และเพิ่มโอกาสในการขาย
                        <label className="color-default"> เรามีทีมงานบริการถ่ายภาพนิ่ง วีดีโอ และโดรน รวมถึงงานตัดต่อ </label>เพื่อสื่อถึงศักยภาพของที่ดินของท่านให้ดีที่สุด
                    </div>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', marginTop: 20}}>
                        <Button color="default" onClick={() => window.open(line_url)}>
                            ติดต่อสอบถาม <FontAwesomeIcon icon={faLine} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="container-right">
                <img alt="img-1" src={ads4_more} className="ads-img" />
                <div className="txt-container">
                    <p className="color-default font-weight-500">4.บริการวิเคราะห์ความเป็นไปได้ของโครงการ</p>
                    <div>
                        <label className="color-default"> เรามีทีมงานจัดทำรายงานวิเคราะห์ Feasibility Study Report </label>
                        เพื่อให้ท่านได้ใช้ในการตัดสินใจลงทุนโครงการ หรือการขอสินเชื่อจากสถาบันการเงิน
                    </div>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', marginTop: 20}}>
                        <Button color="default" onClick={() => window.open(line_url)}>
                            ติดต่อสอบถาม <FontAwesomeIcon icon={faLine} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="container-left">
                <img alt="img-1" src={ads1_more} className="ads-img" />
                <div className="txt-container">
                    <p className="color-default font-weight-500">5.ปรึกษาเรื่องขายด่วน</p>
                    <p>
                        หากท่านมีความจำเป็นต้องขายด่วน เรายินดีให้คำปรึกษาอย่างตรงไปตรงมา เพื่อให้ท่านได้รับข้อมูลอย่างครบถ้วน และทำการตัดสินใจ ได้อย่างเหมาะสมที่สุด 
                    </p>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', marginTop: 20}}>
                        <Button color="default" onClick={() => window.open(line_url)}>
                            ติดต่อสอบถาม <FontAwesomeIcon icon={faLine} />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
        
        </>
    )
}
export default MorePackage