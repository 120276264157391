import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ESProvider } from "views/forsaleTab/context/ESContext";
import { LoginProvider } from './components/loginContext/LoginContext';
import { HelmetProvider } from 'react-helmet-async';

const helmetContext = {};

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider context={helmetContext}>
      <ESProvider>
        <LoginProvider>
          <App />
        </LoginProvider>
      </ESProvider>
    </HelmetProvider> 
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
