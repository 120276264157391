import React from "react";

// import RouteDash from "./routes";
import { 
    Nav, 
    NavItem, 
    Navbar, 
    NavbarBrand, 
    Collapse, 
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    Badge
} from 'reactstrap';
import { NavLink, useRouteMatch, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faBars, 
    faXmark, 
    faUser, 
    faArrowRightFromBracket, 
    faFileInvoice,
    faListCheck
} from "@fortawesome/free-solid-svg-icons";
import { 
    faRectangleList, 
    faBell
} from "@fortawesome/free-regular-svg-icons"
import logo from 'assets/img/taladteedin-logo.png';
import blank_profile from 'assets/img/blank-profile.png';
import LoginModal from "components/loginModal";
import liff from "@line/liff";
import { LoginContext } from "components/loginContext/LoginContext";

const line_liff_id = process.env.REACT_APP_LINE_LIFF_ID;

function Navbars(){
    const [toggle, setToggle] = React.useState(false);
    const [loginOpen, setLoginOpen] = React.useState(false);
    const { userData } = React.useContext(LoginContext);
    const [dropdownToggle, setDropdownToggle] = React.useState(false);
    const [shadows, setShadows] = React.useState(false);
    const history = useHistory();

    let { url } = useRouteMatch();

    const loginToggle = () => setLoginOpen(!loginOpen);

    React.useEffect(() => {
        window.onclick = function(event){
            if (!event.target.matches('.user_img')) {
                var dropdowns = document.getElementsByClassName("dropdown-menu");
                var i;
                for (i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show')) {
                    openDropdown.classList.remove('show');
                    setDropdownToggle(false)
                }
                }
            }
        }

        
    },[])

    React.useEffect(() => {
        // Login();

        const updateNavbarColor = () => {
            if (
              document.documentElement.scrollTop > 250 ||
              document.body.scrollTop > 250
            ) {
                setShadows(true)
            } else if (
              document.documentElement.scrollTop < 251 ||
              document.body.scrollTop < 251
            ) {
                setShadows(false)
            }
        };
        window.addEventListener("scroll", updateNavbarColor);
        return function cleanup() {
        window.removeEventListener("scroll", updateNavbarColor);
        };
    },[])

    // const Login = () =>{
    //     const user = localStorage.getItem("profile")
    //     setUserData(JSON.parse(user))
    // }

    function gotoPolicy(){
        window.open("/privacy", '_blank')
    }

    function LogOut(){
        // console.log(userData)
        if(userData.provider === 'line'){
            liff.init({ liffId: line_liff_id }, () => {
                liff.logout()
            }, err => console.error(err));
            localStorage.removeItem('profile')
            window.location.reload()
        }else{
            localStorage.removeItem('profile')
            window.location.reload()
        }
    }

    return(
        <>
        {toggle && 
        <div className="nav-open">
            <Button color="transparent" className="nav-close" onClick={() => setToggle(false)}>
                <FontAwesomeIcon icon={faXmark} style={{fontSize:30}} />
            </Button>
        </div>
        }
        <Navbar container className={`${toggle ? "active" : ""} ${shadows ? "shadow-3" : ""}`} >
            <NavbarBrand href="/">
                <img alt="logo" src={logo} style={{width:100}} />
            </NavbarBrand>
            
            <Collapse navbar>
                <Nav navbar>
                    <NavItem active={url === "/" ? true : false}>
                        <NavLink to="/" className="text-link">หน้าแรก</NavLink>
                    </NavItem>
                    <NavItem active={url === "/forsale" ? true : false}>
                        <NavLink to="/forsale" className="text-link">ลงประกาศขาย</NavLink>
                    </NavItem>
                    <NavItem active={url === "/announce" ? true : false}>
                        <NavLink to="/announce" className="text-link">ลงประกาศรับซื้อ</NavLink>
                    </NavItem>
                    <NavItem active={url === "/map" ? true : false}>
                        <NavLink to="/map" className="text-link">ค้นหาบนแผนที่</NavLink>
                    </NavItem>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            บริการของเรา
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => history.push(`/tools`)}>
                                เครื่องมือผู้ช่วย
                            </DropdownItem>
                            <DropdownItem onClick={() => history.push(`/packages`)}>
                                แพ็กเกจเสริม
                            </DropdownItem>
                            <DropdownItem onClick={() => history.push(`/services?service=0`)}>
                                บริการเสริม
                            </DropdownItem>
                            <DropdownItem onClick={() => history.push(`/services?service=0`)}>
                                บริการขายฝาก/จำนอง
                            </DropdownItem>
                            <DropdownItem onClick={() => history.push(`/services?service=1`)}>
                                บริการจัดหาที่ดิน
                            </DropdownItem>
                            <DropdownItem onClick={() => history.push(`/services?service=2`)}>
                                บริการอื่นๆ
                            </DropdownItem>
                            <DropdownItem onClick={() => window.location.href = `/content`}>
                                บทความและข่าวสาร
                            </DropdownItem>
                            <DropdownItem onClick={() => history.push(`/helpCenter`)}>
                                ศูนย์ช่วยเหลือ
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    {/* <NavItem active={url === "/HelpCenter" ? true : false}>
                        <NavLink to="/HelpCenter" className="text-link">ศูนย์ช่วยเหลือ</NavLink>
                    </NavItem> */}
                </Nav>
            </Collapse>
            <div className="nav-signup" >
                {userData ? 
                <div>
                    <div className="profile-img-container">
                        <img alt="profile" src={userData.profileImg ? userData.profileImg : blank_profile} className={`btn user_img`} onClick={() => setDropdownToggle(!dropdownToggle)}/>
                        {userData.notify ? <Badge pill className="bg_danger badge-img font-weight-300">{userData.notify}</Badge> : null}
                    </div>
                    <div className="nav-dropdown">
                        <DropdownMenu property="end" className={dropdownToggle ? "show" : ""}>
                            <div className="profile-edit">
                                <img alt="" src={userData.profileImg ? userData.profileImg : blank_profile} className="profile_img mb-10" />
                                <label className="mb-10">{userData.name}</label>
                                {userData.role === "PREMIUM" ? <label className="mb-10" style={{fontWeight: 500, color: '#F4D03F'}}>PREMIUM</label> : null}
                                <Button color="transparent" onClick={() => history.push('/dashboard/profile')}>
                                    บัญชีของฉัน
                                </Button>
                            </div>
                            <hr/>
                            <DropdownItem onClick={() => history.push('/dashboard')}>
                                <FontAwesomeIcon icon={faListCheck} className="mr-10" />
                                จัดการข้อมูล
                            </DropdownItem>
                            <DropdownItem style={{display:'flex', justifyContent:'space-between'}} onClick={() => history.push('/notification')}>
                                <div style={{fontWeight: 400}}>
                                    <FontAwesomeIcon icon={faBell} className="mr-10" />
                                    Notification
                                </div>
                                {userData.notify ? <Badge pill className="bg_danger">{userData.notify}</Badge> : null}
                            </DropdownItem>
                            {/* <DropdownItem onClick={() => history.push('/user_favorite')}>
                                <FontAwesomeIcon icon={faHeart} className="mr-10" />
                                รายการโปรด
                            </DropdownItem>
                            <DropdownItem onClick={() => history.push('/user_sale')}>
                                <FontAwesomeIcon icon={faSignHanging} className="mr-10" />
                                รายการประกาศขาย
                            </DropdownItem>
                            <DropdownItem onClick={() => history.push('/user_announce')}>
                                <FontAwesomeIcon icon={faBullhorn} className="mr-10" />
                                รายการประกาศหา
                            </DropdownItem> */}
                            <DropdownItem onClick={() => history.push('/consignment')}>
                                <FontAwesomeIcon icon={faFileInvoice} className="mr-10" />
                                ลงจำนอง/ขายฝาก
                            </DropdownItem>
                            {/* <DropdownItem onClick={() => history.push('/favoritearea')}>
                                <FontAwesomeIcon icon={faHeartCirclePlus} className="mr-10" />
                                พื้นที่โปรดของท่าน
                            </DropdownItem> */}
                            <hr/>
                            <DropdownItem onClick={() => gotoPolicy()}>
                                <FontAwesomeIcon icon={faRectangleList} className="mr-10" />
                                นโยบายความเป็นส่วนตัว
                            </DropdownItem>
                            <DropdownItem onClick={() => LogOut()}>
                                <FontAwesomeIcon icon={faArrowRightFromBracket} className="mr-10" />
                                ออกจากระบบ
                            </DropdownItem>
                        </DropdownMenu>
                    </div>
                </div>
                : 
                <Button color="default" onClick={loginToggle}>
                    <FontAwesomeIcon icon={faUser}/>
                </Button>
                }
            </div>
            <Button className="nav-toggle" color="transparent" onClick={() => setToggle(!toggle)}>
                <FontAwesomeIcon icon={faBars} style={{color:'gray', fontSize:25}} />
            </Button>
        </Navbar>
        <LoginModal isOpen={loginOpen} onClose={loginToggle}  />
        </>
    )
}

export default Navbars