import React from "react";

import { 
    Button,
    Input,
    InputGroup,
    Form,
    FormGroup,
    Row,
    Col,
    Label,
    Card,
    CardBody,
    CardImg,
    CardTitle,
    CardText,
    CardFooter,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    UncontrolledTooltip
} from 'reactstrap';

import Navbars from "routers/navbars";
import Footer from "components/footer";
import Carousel from "react-multi-carousel";
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faLocationDot, 
    faSearch, 
    faMapLocationDot,
    faMagnifyingGlass,
    faChevronDown,
    faChevronUp,
    faEllipsisV,
    faFileImport
} from "@fortawesome/free-solid-svg-icons";
import {
    faCommentDots
} from "@fortawesome/free-regular-svg-icons"
import ReactPlayer from "react-player";
import axios from "axios";
import { ErrorAlert } from "components/alert";
import { PROVINCE_LIST } from "./components/province";
import service1 from 'assets/img/Home/ขายได้.jpg';
import service2 from 'assets/img/Home/ซื้อง่าย2.jpg';
import service3 from 'assets/img/Home/partnership.jpg';
import B_info from 'assets/img/Home/เราคือตลาดที่ดิน2-01 Large.jpeg';
import blank_img from 'assets/img/blank-profile.png';
import mappic from 'assets/img/taladteedinpic-Large.webp';
import PackageComponents from "components/packagecomponents";
import { LoginContext } from "components/loginContext/LoginContext";
import LoginModal from "components/loginModal";
import Swal from "sweetalert2";
import { TikTok } from "react-tiktok";
import { SEO } from "./components/SEO";

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
};

const path_url = process.env.REACT_APP_API_CONNECT;
const line_url = process.env.REACT_APP_LINE_PATH;

function Home(){
    const { userData } = React.useContext(LoginContext);
    const [searchActive, setSearchActive] = React.useState(null);
    const [loginOpen, setLoginOpen] = React.useState(false);
    const [fetch, setFetch] = React.useState(null);
    const [searchData, setSearchData] = React.useState({
        province: "",
        size: {
            min: {
                count: 0,
                unit: 'wa'
            },
            max:{
                count: 0,
                unit: 'wa'
            }
        },
        price:{
            min: 0,
            max: 0
        },
    })
    const history = useHistory();

    React.useEffect(() =>{
        function reveal() {
            var reveals = document.querySelectorAll(".reveal");
            
            for (var i = 0; i < reveals.length; i++) {
                var windowHeight = window.innerHeight;
                var elementTop = reveals[i].getBoundingClientRect().top;
                var elementVisible = 10;
            
                if (elementTop < windowHeight - elementVisible) {
                    reveals[i].classList.add("active");
                }
            }
        }
        
        window.addEventListener("scroll", reveal);

        var textfirst = document.getElementById('text-telling')
        setTimeout(function(){
            textfirst.classList.add('block')
        },5000)
    },[])

    const gotoMapPage = () =>{
        history.push("/map")
        // window.location.href = "/map"
    }

    const onChange = (val, child) =>{
        setSearchData((item) =>{
            const data = {...item}
            data[child] = val
            return{...data}
        })
    }

    const onChangeSize = (val, prop, child) =>{
        setSearchData((item) =>{
            const data = {...item}
            data['size'][prop][child] = val
            return{...data}
        })
    }

    const onChangePrice = (val, prop) =>{
        setSearchData((item) =>{
            const data = {...item}
            data['price'][prop] = parseFloat(val)
            return{...data}
        })
    }

    const onFormSubmit = e => {
        e.preventDefault();
        if(searchData.province){
            history.push(`/searching?${searchData?.province ? 'province='+searchData.province+"&" : ""} size=${JSON.stringify({min:{count:searchData.size.min.count, unit:searchData.size.min.unit},max:{count:searchData.size.max.count ? searchData.size.max.count : 9999999999, unit: searchData.size.max.count ? searchData.size.max.unit : "rai" }})}&price=${JSON.stringify({min:searchData.price.min ? searchData.price.min : 1, max: searchData.price.max ? searchData.price.max : 9999999999})}`)
        }else{
            ErrorAlert({text: 'กรุณากรอกข้อมูลให้ครบถ้วน'})
        }
    }

    React.useEffect(() =>{
        // setLoading(true)
        axios.get(path_url+'/home/getHomeAll')
        .then(res =>{
            if(res.status === 200){
                setFetch(res.data)
                // setLoading(false)
            }else{
                ErrorAlert({text: 'เกิดข้อผิดพลาด! ไม่สามารถดหลดข้อมูลได้'})
                // setLoading(true)
            }
        })
        .catch((e) =>{
            console.log(e)
        })

        // metaTag()
    },[])

    const intl = new Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 0,
    })

    const toEstateDetail = (val) =>{
        window.open(`/estate_detail/?sale=${val}`, "_blank") 
        // window.location.href = `/EstateDetail/?sale=${val}`
    }

    const [salesOffer, setSalesOffer] = React.useState({
        name: "",
        phoneNumber: "",
        email: "",
        _id: "",
        buyer: null,
    })
    const [salesOfferModal, setSalesOfferModal] = React.useState(false)
    const [salesOfferLoading, setSalesOfferLoading] = React.useState(false)

    function salesOfferToggle(items){
        if(userData !== null){
            if(userData.role === "PREMIUM"){
                setSalesOfferModal(true)
                setSalesOffer({
                    _id: userData._id,
                    name: userData.name,
                    phoneNumber: userData.phoneNumber,
                    email: userData.email,
                    buyer: items
                })
            }else{
                window.open(line_url)
            }
            
        }else{
            setLoginOpen(true)
        }
    }

    async function salesOffering(){
        setSalesOfferLoading(true)
        try{
            const result = await axios.post(path_url+'/announce/salesOffer',{
                offinger:{
                    _id: salesOffer._id,
                    name: salesOffer.name,
                    phoneNumber: salesOffer.phoneNumber,
                    email: salesOffer.email,
                },
                buyer: salesOffer.buyer,
            })

            if(result.status === 200){
                setSalesOfferLoading(false)
                setSalesOfferModal(false)
                setSalesOffer({
                    name: "",
                    phoneNumber: "",
                    email: "",
                    _id: "",
                    buyer: null,
                })
                Swal.fire({
                    icon: 'info',
                    position: 'center',
                    html: `<p>ชื่อ: <strong>${salesOffer?.buyer?.user?.name}</strong></p>
                    <p>เบอร์โทรติดต่อ: <strong> ${salesOffer?.buyer?.user?.phoneNumber ? salesOffer.buyer.user.phoneNumber : ""}</strong> </p>
                    <p>${salesOffer?.buyer?.user?.phoneBackup ? "เบอร์โทรติดต่อสำรอง: "+ salesOffer.buyer.user.phoneBackup : ""}</p>`,
                    confirmButtonText:"ตกลง",
                    confirmButtonColor: "#5DADE2",
                })
            }
        }catch(e){
            console.log(e)
            setSalesOfferLoading(false)
        }
    }
    
    return(
    <>
        <SEO 
        title={"TaladTeedin"}
        description={"เว็บไซต์ซื้อขายที่ดิน ที่พัฒนาเพื่อการซื้อขายที่ดินโดยเฉพาะ หาที่ดินง่ายและเร็วที่สุด สถิติขายได้ใน 1 เดือน โดยใช้ระบบแผนที่ ซึ่งแสดงรูปแปลง ราคาขายที่ดิน และข้อมูลสำคัญของที่ดิน อย่างชัดเจน ท่านสามารถเช็คราคาที่ดิน ประกาศขายที่ดิน หาซื้อที่ดิน ค้นหาที่ดินที่ประกาศขาย ค้นหาแปลงที่ดิน ค้นหาที่ดินได้ที่นี่ เราเป็นเครื่องมือและผู้ช่วย ของท่านเรื่องการซื้อขายที่ดิน"}
        type={"website"}
        image={"https://taladteedin.sgp1.digitaloceanspaces.com/landmarket-prod/banner/Banner2-01-01.jpg"}
        url={"https://www.taladteedin.com"}
        />
        <Navbars />
        <div className="gotomap">
            <button 
            className="btn" 
            onClick={() => history.push('/map')}
            >
                <FontAwesomeIcon icon={faMapLocationDot} className="mr-10" />
                <p>แสดงหน้าแผนที่</p>
            </button>
        </div>
        <div className="home-container">
            <div className="container-slider">
                <img 
                alt="" 
                src="https://taladteedin.sgp1.digitaloceanspaces.com/landmarket-prod/banner/Banner2-01-01-Large.webp" 
                className="image"
                />
                <div className="home-scrolldown">
                    <Label>เลื่อนลง</Label>
                    <div className="scrolldown">
                        <FontAwesomeIcon icon={faChevronDown} style={{fontSize: 20}} />
                    </div>
                </div>
            </div>
            <div className="search-container">
                <div className="btn-wrapper">
                    <Button 
                    color="default" 
                    style={{marginRight:10}} 
                    className="shadow animated"
                    onClick={() => window.location.href = "/forsale"}
                    >
                        ลงประกาศขาย
                    </Button>
                    <Button 
                    color="default" 
                    style={{marginRight:10}} 
                    className="shadow animated"
                    onClick={() => window.location.href = "/announce"}
                    >
                        ลงประกาศรับซื้อ
                    </Button>
                    <Button 
                    color="default" 
                    style={{marginRight:10}} 
                    className="shadow animated"
                    onClick={() => window.location.href = "/announceview"}
                    >
                        ดูประกาศรับซื้อที่ดิน
                    </Button>
                </div>
                <button className="iconVisible" onClick={() => setSearchActive(searchActive ? null : 'active')}>
                    ค้นหาเพิ่มเติม
                    <FontAwesomeIcon icon={searchActive ? faChevronUp : faChevronDown} style={{marginLeft:10}} />
                </button>
                <div className={"search-wrapper " + searchActive}>
                    <Form onSubmit={onFormSubmit}>
                        <Row>
                            <div className="col-md-3 col-sm-12 col-xs-12">
                                <FormGroup className="form-group">
                                    <Label className="color-darkblue">ค้นหาตามจังหวัด</Label>
                                    <InputGroup>
                                        <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5, color:'#23265F'}} />
                                        <Input 
                                        list="browsers" 
                                        name="browser" 
                                        className="form-list color-darkblue" 
                                        placeholder="จังหวัด" 
                                        defaultValue={searchData.province}
                                        onChange={(val) => onChange(val.target.value, 'province')}
                                        />
                                        <datalist id="browsers" className="list" >
                                            {PROVINCE_LIST.map((item, k) =>{
                                                return <option key={k} className="color-darkblue" value={item.province_name} />
                                            })}
                                        </datalist>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <FormGroup className="form-group">
                                    <Label className="color-darkblue">ค้นหาหาตามขนาด</Label>
                                    <Row>
                                        <div className="col-md-5 col-sm-5">
                                            <InputGroup>
                                                <Input 
                                                type="number" 
                                                placeholder="0" 
                                                className="color-darkblue" 
                                                value={searchData.size.min.count ? searchData.size.min.count : ""}
                                                onChange={(val) => onChangeSize(val.target.value, 'min', 'count')}
                                                />
                                                <select 
                                                className="form-select color-darkblue"
                                                value={searchData.size.min.unit}
                                                onChange={(val) => onChangeSize(val.target.value, 'min', 'unit')}
                                                >
                                                    <option className="color-darkblue" disabled value={''}>เลือก</option>
                                                    <option className="color-darkblue" value={'wa'}>ตร.ว</option>
                                                    <option className="color-darkblue" value={'ngan'}>งาน</option>
                                                    <option className="color-darkblue" value={'rai'}>ไร่</option>
                                                </select>
                                            </InputGroup>
                                        </div>
                                        <div className="col-md-1 col-sm-1">
                                            <Label className="color-darkblue">ถึง</Label>
                                        </div>
                                        <div className="col-md-5 col-sm-5">
                                            <InputGroup>
                                                <Input 
                                                type="number" 
                                                placeholder="0" 
                                                className="color-darkblue" 
                                                value={searchData.size.max.count ? searchData.size.max.count : ""}
                                                onChange={(val) => onChangeSize(val.target.value, 'max', 'count')}
                                                />
                                                <select 
                                                className="form-select color-darkblue"
                                                value={searchData.size.max.unit}
                                                onChange={(val) => onChangeSize(val.target.value, 'max', 'unit')}
                                                >
                                                    <option className="color-darkblue" disabled value={''}>เลือก</option>
                                                    <option className="color-darkblue" value={'wa'}>ตร.ว</option>
                                                    <option className="color-darkblue" value={'ngan'}>งาน</option>
                                                    <option className="color-darkblue" value={'rai'}>ไร่</option>
                                                </select>
                                            </InputGroup>
                                        </div>
                                    </Row>
                                </FormGroup>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <FormGroup className="form-group">
                                    <Label className="color-darkblue">ราคาระหว่าง</Label>
                                    <Row>
                                        <div className="col-md-5 col-sm-5">
                                            <InputGroup>
                                                <Input 
                                                type="number"
                                                placeholder="ต่ำสุด" 
                                                className="color-darkblue" 
                                                value={searchData.price.min ? searchData.price.min : ""}
                                                onChange={(val) => onChangePrice(val.target.value, 'min')}
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className="col-md-1 col-sm-1">
                                            <Label className="color-darkblue">ถึง</Label>
                                        </div>
                                        <div className="col-md-5 col-sm-5">
                                            <InputGroup>
                                                <Input 
                                                type="number"
                                                placeholder="สูงสุด" 
                                                className="color-darkblue" 
                                                value={searchData.price.max ? searchData.price.max : ""}
                                                onChange={(val) => onChangePrice(val.target.value, 'max')}
                                                />
                                            </InputGroup>
                                        </div>
                                    </Row>
                                </FormGroup>
                            </div>
                            <div className="col-md-1 col-sm-12" style={{alignItems:'center', display:'flex'}}>
                                <Button type="submit" className="btn-circle-md">
                                    <FontAwesomeIcon icon={faSearch} style={{marginRight:5}} /> ค้นหา
                                </Button>
                            </div>
                        </Row>
                    </Form>
                </div>
            </div>
            <div className="home-section-1">
                <div className="flex-1">
                    <h3 style={{fontWeight: 500}}>
                        หาที่ดินง่ายๆด้วย <label style={{fontSize: '1em', fontWeight: 500}} className="color-default">แผนที่</label>
                    </h3>
                    <div className="mb-20">
                        การค้นหาที่ดินบนแผนที่เป็นกระบวนการที่สำคัญในการค้นหาสถานที่ที่เหมาะสมสำหรับการสร้างบ้านหรือธุรกิจของคุณ ขั้นตอนและเคล็ดลับในการค้นหาและเลือกที่ดินที่เหมาะกับความต้องการของคุณ ไม่ว่าคุณจะเป็นนักลงทุนอสังหาริมทรัพย์หรือเพียงคนที่กำลังมองหาที่อยู่ใหม่ เราจะช่วยคุณค้นพบที่ดินที่ทันสมัยและตอบสนองความต้องการของคุณ
                    </div>
                    {/* <div style={{display:'flex', justifyContent:'center'}}>
                        <Button color="default" onClick={() => history.push('/map')}>
                            ดูบนแผนที่ <FontAwesomeIcon icon={faChevronRight} />
                        </Button>
                    </div> */}
                </div>
                <div className="flex-2">
                    <img 
                    alt="หาที่ดินง่ายๆด้วย แผนที่" 
                    src={mappic} 
                    className="img-1 shadow" 
                    />
                </div>
            </div> 
            <div className="home-section-2">
                {fetch ? 
                <>
                <Label className="title color-darkblue">
                    แปลงที่ดินล่าสุด
                </Label>
                <Carousel 
                responsive={responsive}
                infinite={false}
                autoPlay={false}
                ssr={true}
                shouldResetAutoplay={false}
                // removeArrowOnDeviceType={["tablet", "mobile"]}
                containerClass="carousel-container mb-30"
                itemClass="carousel-item"
                >
                    {Array.from(fetch.LastL).map((item, i) =>{
                        return(
                            <Card 
                            style={{cursor:'pointer'}} 
                            key={i}
                            onClick={() => toEstateDetail(item?._id)}
                            >
                                <div className="card-tag">
                                    <div className="tag">
                                        {item?.saleType === "OWNER" ?
                                        <div className="tag-1 bg_default color-white mt-10">
                                            เจ้าของขายเอง
                                        </div>
                                        :
                                        null}
                                        {item.quick ? 
                                        <div className="tag-2 bg_redpink color-white mt-10">
                                            ขายด่วน
                                        </div>
                                        : null}
                                    </div>
                                    
                                    <CardImg 
                                    alt={item?.titleDeedNumber}
                                    src={item?.images[0]?.path} 
                                    />
                                </div>
                                <CardBody>
                                    <div className="body-1">
                                        <CardTitle>
                                            {item?.title}
                                        </CardTitle>
                                        <CardText>{item?.description}</CardText>
                                    </div>
                                    <div className="body-2">
                                        <CardText>{intl.format(item?.totalPrice)}</CardText>
                                        {item?.pricePerWa ?
                                            <CardText className="color-default">{intl.format(item?.pricePerWa)}/ตร.ว.</CardText>
                                        :null}
                                    </div>
                                    <CardText>
                                        <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                        {item?.saleDistrict + ", " + item?.saleProvince}
                                    </CardText>
                                </CardBody>
                            </Card>
                        )
                    })}
                </Carousel>
                <Label className="title color-darkblue">
                    ขายแล้ว
                </Label>
                <Carousel 
                responsive={responsive}
                infinite={true}
                autoPlay={false}
                ssr={true}
                shouldResetAutoplay={false}
                // removeArrowOnDeviceType={["tablet", "mobile"]}
                containerClass="carousel-container mb-30"
                itemClass="carousel-item"
                > 
                    {Array.from(fetch.SoldOut).map((item, i) =>{
                        return(
                            <Card 
                            // style={{cursor:'pointer'}} 
                            key={i}
                            // onClick={() => toEstateDetail(item?._id)}
                            >
                                <div className="card-tag">
                                    <div className="tag">
                                        {item?.saleType === "OWNER" ?
                                        <div className="tag-1 bg_default color-white mt-10">
                                            เจ้าของขายเอง
                                        </div>
                                        :
                                        null}
                                        <div className="tag-2 bg_danger color-white mt-10">
                                            ขายแล้ว
                                        </div>
                                    </div>
                                    
                                    <CardImg 
                                    alt={item?.titleDeedNumber}
                                    src={item?.images[0]?.path} 
                                    />
                                </div>
                                <CardBody>
                                    <div className="body-1">
                                        <CardTitle>
                                            {item?.title}
                                        </CardTitle>
                                        <CardText>{item?.description}</CardText>
                                    </div>
                                    <div className="body-2">
                                        <CardText>฿0</CardText>
                                        {item?.pricePerWa ?
                                            <CardText className="color-default">฿0/ตร.ว.</CardText>
                                        :null}
                                    </div>
                                    <CardText>
                                        <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                        {item?.saleDistrict + ", " + item?.saleProvince}
                                    </CardText>
                                </CardBody>
                            </Card>
                        )
                    })}
                </Carousel>
                <div className="section-button-last">
                    <Button color="default" onClick={() => gotoMapPage()}>
                        <FontAwesomeIcon icon={faMapLocationDot} style={{marginRight:10}} />
                        ค้นหาบนแผนที่
                    </Button>
                </div>
                </>
                : null}
            </div>
            <div className="home-section-3">
                <div className="title color-darkblue text-center full-w">
                    Package
                </div>
                <PackageComponents />
            </div>
            <div className="home-section-4">
                <div className="home-section-4-box">
                    <Label className="title color-darkblue">
                        ประกาศรับซื้อที่ดินล่าสุด
                    </Label>

                    {fetch ? 
                    Array.from(fetch.Annonce).length <= 4 ? 
                    <Row className="row-cols-1 row-cols-md-4 row-cols-lg-4 mb-30">
                        {Array.from(fetch.Annonce).map((items, i) =>{
                        const date = new Date(items.create_At).toLocaleDateString('th',{day:'2-digit', month:'short', year:'2-digit'});
                        var SizeUnitLow;
                        switch(items.size.lowest.square){
                            case 'squarewa' : SizeUnitLow = 'ตร.ว.'; 
                            break;
                            case 'ngan' : SizeUnitLow = 'งาน';
                            break;
                            default : SizeUnitLow = 'ไร่';
                        }

                        var SizeUnitMax;
                        switch(items.size.maximum.square){
                            case 'squarewa' : SizeUnitMax = 'ตร.ว.'; 
                            break;
                            case 'ngan' : SizeUnitMax = 'งาน';
                            break;
                            default : SizeUnitMax = 'ไร่';
                        }
                        function choose(val){
                            switch(val){
                                case 'build' : return 'สร้างบ้าน';
                                case 'SmallHouse' : return 'พัฒนาโครงการที่อยู่อาศัยขนาดเล็ก';
                                case 'LargeResidential' : return 'พัฒนาโครงการที่อยู่อาศัยขนาดใหญ่';
                                case 'BigBusiness' : return 'ธุรกิจขนาดใหญ่';
                                case 'SME' : return 'ธุรกิจขนาดเล็ก SME';
                                case 'Speculate' : return 'เก็งกำไร';
                                case 'Farming' : return 'การเกษตร';
                                default : return 'อื่นๆ';
                            }
                        }
                        return(
                            <Col key={i} className="mb-10">
                                <Card >
                                    <CardBody>
                                        <div className="header mb-10">
                                            <img alt="userImg" src={items.user.profileImg ? items.user.profileImg : blank_img} className="user-img" />
                                            <p className="name">{items?.user?.name ? items?.user?.name : 'visitor'}</p>
                                            <p className="oversize">ต้องการ</p>
                                            <div className="text-wrapper">
                                                <FontAwesomeIcon icon={faLocationDot} className="mr-10 icon" />
                                                <div className="label">
                                                    <p className="label-1">
                                                        จ.{items.province}
                                                    </p>
                                                    <p className="label-2">
                                                        อ.{items.district}
                                                    </p>
                                                </div>
                                            </div>
                                            <small className="date">
                                                {date}
                                            </small>
                                        </div>
                                        
                                        <div className="text-description">
                                            <div className="font-weight-500 color-default mb-10">
                                                วัตถุประสงค์: 
                                                <p className="font-weight-300 ml-10 color-darkblue">{`${choose(items.choice)}`}</p>
                                            </div>
                                            <div className="flex font-weight-500 color-default mb-10">
                                                ขนาดเนื้อที่: <div className="font-weight-300 ml-10 color-darkblue">{`${items.size.lowest.count} ${SizeUnitLow} - ${items.size.maximum.count} ${SizeUnitMax}`}</div>
                                            </div>
                                            <div className="flex font-weight-500 color-default ">
                                                ราคารวม: 
                                            </div>
                                            <div className="color-darkblue mb-10">
                                                {fetch ? 
                                                `${intl.format(items.price.lowest)} - ${intl.format(items.price.maximum)}`
                                                : null}
                                            </div>
                                            <div className="flex font-weight-500 color-default">
                                                รายละเอียด: 
                                            </div>
                                            <div className="text-detail color-darkblue">
                                                {items.description} 
                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardFooter 
                                    style={{
                                        display:'flex', 
                                        justifyContent:'space-between'
                                    }}
                                    >
                                        <Button 
                                        color="transparent"
                                        style={{color: '#909090'}}
                                        onClick={() => window.open('/announce_view/'+ items._id)}
                                        >
                                            <FontAwesomeIcon icon={faCommentDots} style={{fontSize: 20}} /> {items?.comments?.length}
                                        </Button>
                                        <div style={{display: 'flex'}}>
                                            <Button 
                                            id={`present${i}`}
                                            color="round-default-sm"
                                            onClick={() => salesOfferToggle(items)}
                                            >
                                                <FontAwesomeIcon icon={faFileImport} style={{fontSize: 20}} />
                                            </Button>
                                            <UncontrolledTooltip
                                            target={`present${i}`}
                                            >
                                                เสนอขาย
                                            </UncontrolledTooltip>

                                            <Button 
                                            id={`info${i}`}
                                            color="round-sky-sm"
                                            onClick={() => window.open('/announce_view/'+ items._id)}
                                            >
                                                <FontAwesomeIcon icon={faEllipsisV} style={{fontSize: 20}} />
                                            </Button>
                                            <UncontrolledTooltip
                                            target={`info${i}`}
                                            >
                                                รายละเอียด
                                            </UncontrolledTooltip>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                        )
                        })}
                    </Row>
                    
                    : 
                    <Carousel
                    responsive={responsive}
                    infinite={false}
                    autoPlay={false}
                    ssr={true}
                    shouldResetAutoplay={false}
                    containerClass="carousel-container"
                    itemClass="carousel-item"
                    >
                        {Array.from(fetch.Annonce).map((items, i) =>{
                            const date = new Date(items.create_At).toLocaleDateString('th',{day:'2-digit', month:'short', year:'2-digit'});
                            var SizeUnitLow;
                            switch(items.size.lowest.square){
                                case 'squarewa' : SizeUnitLow = 'ตร.ว.'; 
                                break;
                                case 'ngan' : SizeUnitLow = 'งาน';
                                break;
                                default : SizeUnitLow = 'ไร่';
                            }

                            var SizeUnitMax;
                            switch(items.size.maximum.square){
                                case 'squarewa' : SizeUnitMax = 'ตร.ว.'; 
                                break;
                                case 'ngan' : SizeUnitMax = 'งาน';
                                break;
                                default : SizeUnitMax = 'ไร่';
                            }
                            function choose(val){
                                switch(val){
                                    case 'build' : return 'สร้างบ้าน';
                                    case 'SmallHouse' : return 'พัฒนาโครงการที่อยู่อาศัยขนาดเล็ก';
                                    case 'LargeResidential' : return 'พัฒนาโครงการที่อยู่อาศัยขนาดใหญ่';
                                    case 'BigBusiness' : return 'ธุรกิจขนาดใหญ่';
                                    case 'SME' : return 'ธุรกิจขนาดเล็ก SME';
                                    case 'Speculate' : return 'เก็งกำไร';
                                    case 'Farming' : return 'การเกษตร';
                                    default : return 'อื่นๆ';
                                }
                            }
                            return(
                                <Card key={i}>
                                    <CardBody>
                                        <div className="header mb-10">
                                            <img alt="userImg" src={items?.user?.profileImg ? items.user.profileImg : blank_img} className="user-img" />
                                            <p className="name">{items?.user?.name}</p>
                                            <p className="oversize">ต้องการ</p>
                                            <div className="text-wrapper">
                                                <FontAwesomeIcon icon={faLocationDot} className="mr-10 icon" />
                                                <div className="label">
                                                    <p className="label-1">
                                                        จ.{items?.province}
                                                    </p>
                                                    <p className="label-2">
                                                        อ.{items?.district}
                                                    </p>
                                                </div>
                                            </div>
                                            <small className="date">
                                                {date}
                                            </small>
                                        </div>
                                        
                                        <div className="text-description">
                                            <div className="font-weight-500 color-default mb-10">
                                                วัตถุประสงค์: 
                                                <p className="font-weight-300 ml-10 color-darkblue">{`${choose(items?.choice)}`}</p>
                                            </div>
                                            <div className="flex font-weight-500 color-default mb-10">
                                                ขนาดเนื้อที่: <div className="font-weight-300 ml-10 color-darkblue">{`${items?.size?.lowest?.count} ${SizeUnitLow} - ${items?.size?.maximum?.count} ${SizeUnitMax}`}</div>
                                            </div>
                                            <div className="flex font-weight-500 color-default ">
                                                ราคารวม: 
                                            </div>
                                            <div className="color-darkblue mb-10">
                                                {fetch ? 
                                                `${intl.format(items?.price?.lowest)} - ${intl.format(items?.price?.maximum)}`
                                                : null}
                                            </div>
                                            <div className="flex font-weight-500 color-default">
                                                รายละเอียด: 
                                            </div>
                                            <div className="text-detail color-darkblue">
                                                {items?.description} 
                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardFooter 
                                    style={{
                                        display:'flex', 
                                        justifyContent:'space-between',
                                        padding: 10
                                    }}
                                    >
                                        <Button 
                                        color="transparent"
                                        style={{color: '#909090'}}
                                        onClick={() => window.open('/announce_view/'+ items._id)}
                                        >
                                            <FontAwesomeIcon icon={faCommentDots} style={{fontSize: 20}} /> {items?.comments?.length}
                                        </Button>
                                        <div style={{display: 'flex'}}>
                                            <Button 
                                            id={`present${i}`}
                                            color="round-default-sm"
                                            onClick={() => salesOfferToggle(items)}
                                            >
                                                <FontAwesomeIcon icon={faFileImport} style={{fontSize: 20}} />
                                            </Button>
                                            <UncontrolledTooltip
                                            target={`present${i}`}
                                            >
                                                เสนอขาย
                                            </UncontrolledTooltip>

                                            <Button 
                                            id={`info${i}`}
                                            color="round-sky-sm"
                                            onClick={() => window.open('/announce_view/'+ items._id)}
                                            >
                                                <FontAwesomeIcon icon={faEllipsisV} style={{fontSize: 20}} />
                                            </Button>
                                            <UncontrolledTooltip
                                            target={`info${i}`}
                                            >
                                                รายละเอียด
                                            </UncontrolledTooltip>
                                        </div>
                                    </CardFooter>
                                </Card>
                            )
                        })}
                        
                    </Carousel>
                    : null}
                    
                    <div className="section-button-last">
                        <Button color="default" onClick={() => window.location.href = "/announce_all"}>
                            <FontAwesomeIcon icon={faMagnifyingGlass} style={{marginRight:10}} />
                            ดูประกาศรับซื้อทั้งหมด
                        </Button>
                    </div>
                </div>
            </div>
            <div className="home-section-5">
                <Label className="title color-darkblue">
                    ทำไมต้องเลือกบริการจากเรา?
                </Label>
                <Row className="row-cols-1 row-cols-md-3 row-cols-sm-1 mb-30">
                    <Col style={{marginBottom:10}}>
                        <Card className="btn" onClick={() => window.location.href = "/forsale"}>
                            <CardBody>
                                <div className="header">
                                    <img alt="" src={service1} className="icon" />
                                    <div style={{fontSize:'2em', fontWeight:400}} className="color-default">ขายได้</div>
                                </div>
                                <ul style={{marginLeft:10, marginBottom:10}} className="color-darkblue">
                                    <li>
                                        เพิ่มโอกาสขาย เพิ่มการมองเห็นบนโลกออนไลน์
                                    </li>
                                    <li>
                                        ช่วยจับคู่ที่ดิน ให้ Matching กับผู้ซื้อ
                                    </li>
                                    <li>
                                        มีที่ปรึกษา มีผู้ช่วย ทุกขั้นตอน
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col style={{marginBottom:10}}>
                        <Card className="btn" onClick={() => window.location.href = "/map"}>
                            <CardBody>
                                <div className="header">
                                    <img alt="" src={service2} className="icon" />
                                    <div style={{fontSize:'2em', fontWeight:400}} className="color-default">ซื้อง่าย</div>
                                </div>
                                <ul style={{marginLeft:10, marginBottom:10}} className="color-darkblue">
                                    <li>
                                        หาที่ดินง่าย เพียงเลื่อนแผนที่ ได้ข้อมูลครบ
                                    </li>
                                    <li>
                                        ที่ดินไม่ถูกใจ ระบุโจทย์ไว้ ให้เราช่วยหา
                                    </li>
                                    <li>
                                        มีที่ปรึกษาที่ไว้ใจได้ ให้คำปรึกษาก่อนตัดสินใจ
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col style={{marginBottom:10}}>
                        <Card className="btn" onClick={() => window.open(line_url)}>
                            <CardBody>
                                <div className="header">
                                    <img alt="" src={service3} className="icon" />
                                    <div style={{fontSize:'2em', fontWeight:400}} className="color-default">มีผู้ช่วย</div>
                                </div>
                                <ul style={{marginLeft:10, marginBottom:10}} className="color-darkblue ul-color-default">
                                    <li>
                                        ช่วยลงประกาศที่ดินให้คุณ
                                    </li>
                                    <li>
                                        ช่วยให้คำปรึกษาให้กับคุณ
                                    </li>
                                    <li>
                                        ช่วยตอบคำถาม คลายข้อสงสัยให้กบคุณ
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            <div className="home-section-6">
                <div className="home-section-6-box">
                    <Label className="title color-darkblue">
                        ข่าวสารจากตลาดที่ดิน
                    </Label>
                    {/* <Row className="row-cols-1 row-cols-md-3">
                        {fetch ? 
                        Array.from(fetch.Content.image).map((item, i) =>{
                            return(
                                <Col className="mb-20" key={i}>
                                    <Card style={{height: '100%'}}>
                                        <CardImg 
                                        alt={item._id}
                                        src={item.coverPath}
                                        onClick={() => window.open(`/contentview?id=${item._id}`,"_blank")}
                                        style={{cursor:'pointer'}}
                                        />
                                        <CardBody>
                                            <CardTitle className="color-default mb-10">{item.title}</CardTitle>
                                            <CardText className="color-darkblue mb-10">{`ผู้เขียน: ${item.name}`}</CardText>
                                        </CardBody>
                                        <CardFooter style={{display:'flex', flexDirection:'column'}}>
                                            <div style={{display: 'flex', flexWrap:'wrap'}} className="mb-10">
                                                {Array.from(item.tags).map((result, i) =>{
                                                    return(
                                                        <div key={i} className="tags" style={{margin:5}}>
                                                            <p style={{fontSize: 10, color:'white'}}>{result}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <Button 
                                            onClick={() => window.open(`/contentview?id=${item._id}`,"_blank")}
                                            color="transparent"
                                            >
                                                อ่านเพิ่มเติม
                                            </Button>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            )
                        })
                        : 
                        null}
                    </Row>
                    <Row className="row-cols-1 row-cols-md-3">
                    {fetch ? 
                        Array.from(fetch.Content.video).map((item, i) =>{
                            return(
                                <Col className="mb-20" key={i}>
                                    <Card style={{height: '100%'}}>
                                        <div className="card-video">
                                            <ReactPlayer 
                                            url={item.coverPath}
                                            controls={true}
                                            width={'100%'}
                                            height={'100%'}
                                            />
                                        </div>
                                        
                                        <CardBody>
                                            <CardTitle className="color-default mb-10">{item.title}</CardTitle>
                                            <CardText className="color-darkblue mb-10">{`ผู้เขียน: ${item.name}`}</CardText>
                                        </CardBody>
                                        <CardFooter style={{display:'flex', flexDirection:'column'}}>
                                            <div style={{display: 'flex', flexWrap:'wrap'}} className="mb-10">
                                                {Array.from(item.tags).map((result, i) =>{
                                                    return(
                                                        <div key={i} className="tags" style={{margin:5}}>
                                                            <p style={{fontSize: 10, color:'white'}}>{result}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <Button 
                                            onClick={() => window.open(`/contentview?id=${item._id}`,"_blank")}
                                            color="transparent"
                                            >
                                                อ่านเพิ่มเติม
                                            </Button>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            )
                        })
                        : 
                        null}
                    </Row> */}
                    <div className="home-section-6-body">
                        <div className="image-container">
                            {fetch ? 
                            Array.from(fetch.Content.image).map((item, i) =>{
                                return(
                                    <div key={i} className={`gallery__item gallery_item-${i+1}`} onClick={() => window.open(`/contentview/${item._id}`,"_blank")}>
                                        <img src={item.coverPath} className="gallery__img" alt="Image 1" />
                                        <div className="backdrop">
                                            <p className="text">
                                                {item.title}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                            : null}
                        </div>
                        <div className="video-container">
                            {fetch ? 
                            Array.from(fetch.Content.video).map((item, i) =>{
                                return(
                                    <div key={i} className={`video_item video_item-${i+1}`}>
                                        <ReactPlayer 
                                        url={item.coverPath}
                                        controls={true}
                                        width={'100%'}
                                        height={250}
                                        />
                                        <p className="text mt-10">
                                            {item.title}
                                        </p>
                                        <Button color="transparent" size="sm" onClick={() => window.open(`/contentview/${item._id}`,"_blank")}>
                                            {"อ่านต่อ >"}
                                        </Button>
                                    </div>
                                )
                            }) : null}
                        </div>
                        <div className="tiktok-container">
                            {fetch ? 
                            Array.from(fetch.Content.tiktok).map((item, i) =>{
                                return(
                                    <div key={i} className={`tiktok_item tiktok_item-${i+1}`}>
                                        <TikTok 
                                        url={item.coverPath}
                                        />
                                    </div>
                                )
                            }) : null}
                        </div>
                    </div>
                    
                    <div className="section-button-last">
                        <Button color="default" onClick={() => window.location.href = "/content"} >
                            ดูบทความเพิ่มเติม
                        </Button>
                    </div>
                </div>
            </div>
            <div className="home-section-7">
                <img alt="" src={B_info} />
                {/* <div className="title">แอพพลิเคชั่น <p className="title-sub">ตลาดที่ดิน</p> </div>
                <div className="wrapper-1">
                    ปฎิวัติการซื้อขาย ที่ดินรูปแบบใหม่ <br/>
                    ให้เกิดการซื้อขาย โดยเสร็จสมบูรณ์ <br/>
                    อันเป็นวัตถุประสงค์สูงสุดของผู้ซื้อ ผู้ขาย และตลาดที่ดิน
                </div>
                <div className="wrapper-2">
                    Download Application <br/>
                    <div style={{fontSize:30, fontWeight:400}}> &#34; ตลาดที่ดิน &#34; </div>
                </div>
                <div className="wrapper-3">
                    <Button className="btn-application-download">
                        <FontAwesomeIcon icon={faAppStoreIos} style={{fontSize:25}} className="mr-10" /> Comming Soon...
                    </Button>
                    <Button className="btn-application-download ml-10">
                        <FontAwesomeIcon icon={faGooglePlay} style={{fontSize:25}} className="mr-10" /> Comming Soon...
                    </Button>
                </div> */}
            </div>
        </div>
        <LoginModal isOpen={loginOpen} onClose={() => setLoginOpen(false)} />

        <Modal isOpen={salesOfferModal} toggle={() => setSalesOfferModal(false)}>
            <ModalHeader toggle={() => setSalesOfferModal(false)}>
                เสนอขายให้ผู้รับซื้อ
            </ModalHeader>
            <ModalBody>
                <p>ข้อมูลผู้เสนอขาย</p>
                <Form>
                    <FormGroup className="form-group">
                        <Label>email</Label>
                        <Input 
                        type="text" 
                        className="border" value={salesOffer.email} 
                        onChange={(val) => setSalesOffer({...salesOffer, email:val.target.value})} 
                        />
                    </FormGroup>
                    <FormGroup className="form-group">
                        <Label>ชื่อผู้เสนอ</Label>
                        <Input 
                        type="text" 
                        className="border" value={salesOffer.name} 
                        onChange={(val) => setSalesOffer({...salesOffer, name:val.target.value})} 
                        />
                    </FormGroup>
                    <FormGroup className="form-group">
                        <Label>เบอร์โทรศัพท์</Label>
                        <Input 
                        type="text" 
                        className="border" value={salesOffer.phoneNumber} 
                        onChange={(val) => setSalesOffer({...salesOffer, phoneNumber:val.target.value})} 
                        />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <small className="color-red">* สมาชิก Premium จะสามารถเสนอขาย ไปยังผู้ประกาศรับซื้อได้โดยตรง</small>
                <Button color="default" onClick={() => salesOffering()} disabled={salesOfferLoading}>
                    {salesOfferLoading ? 
                    <>
                        <Spinner size={'sm'} className="mr-10" style={{color:'white'}} />
                        Loading...
                    </>
                    : 
                    "ตกลง"
                    }
                    
                </Button>
            </ModalFooter>
        </Modal>
        <Footer />
        <a href="https://line.me/R/ti/p/@027regej?from=page" id="line-customer-chat" class="line-chat" target="_blank">
            <div 
            id="text-telling"
            class="text-telling" 
            >
                ติดต่อสอบถาม 👋
            </div>
            <img alt="line-img" src="/LINE_APP_Android.png" style={{width: 50, height: 50, marginLeft: 10}} />
        </a>
    </>
    )
}

export default Home;