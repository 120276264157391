import React from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faHeart} from '@fortawesome/free-regular-svg-icons';
import {faHeart as fullHeart} from '@fortawesome/free-solid-svg-icons';

function Favorite(props){
    const {className, onFavorite, favorite} = props;

    return(
        <>
        <Button color="favorite" onClick={onFavorite} className={className}>
            {favorite ? 
            <FontAwesomeIcon icon={fullHeart} className="icon" />
            : 
            <FontAwesomeIcon icon={faHeart} className="icon" />
            }
            
        </Button>
        {/* <button onClick={() => console.log(test)}>sdfsd</button> */}
        {/* <LoginModal isOpen={loginOpen} onClose={() => setLoginOpen(false)} /> */}
        </>
    )
}
export default Favorite