import React from "react";
import Navbars from "routers/navbars";
import Footer from "components/footer";

import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faShareNodes,
    faChevronUp,
    faInfoCircle,
    faLocationDot,
    faLink,
    faCheck,
    faChevronRight,
    faChevronLeft,
    faPenRuler,
    faEraser
} from '@fortawesome/free-solid-svg-icons';
import { 
    Button, 
    Row,
    Col,
    Card,
    CardBody,
    CardImg,
    CardText,
    CardTitle,
    Popover,
    PopoverBody,
    Tooltip,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Input,
    Label
} from "reactstrap";
import { 
    GoogleMap, 
    Polygon, 
    Marker,
    useJsApiLoader,
    InfoWindow,
} from '@react-google-maps/api';
import {
    faFacebookF,
    faLine,
    faTwitter
} from '@fortawesome/free-brands-svg-icons';
import Carousel from "react-multi-carousel";
import PhotoGrid from "components/photogrid/photogrid";
import blank_profile from "assets/img/blank-profile.png";
import { ErrorAlert } from "components/alert";
import HouseMarker from 'assets/img/house.svg';
import LandMarker from 'assets/img/land.svg';
import CommercialMarker from 'assets/img/commercial.svg';
import HotelMarker from 'assets/img/hotel.svg';
import FactoryMarker from 'assets/img/factory.svg';
import MoreMarker from 'assets/img/more.svg';
import axios from "axios";
import ReportModal from "views/components/report";
import {
    FacebookShareButton,
    LineShareButton,
    TwitterShareButton
} from 'react-share';
import LoginModal from "components/loginModal";
import Favorite from "components/favorites";
import ReactPlayer from "react-player";
import ImageGallery from 'react-image-gallery';
import circle_point from 'assets/img/Geo-1.svg';
import { LoginContext } from "components/loginContext/LoginContext";
import Swal from "sweetalert2";
import { TikTok } from "react-tiktok";
import { Helmet } from 'react-helmet-async';
import MeasureTool from 'measuretool-googlemaps-v3';

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
};

const path_url = process.env.REACT_APP_API_CONNECT;
const domain = `${window.location.origin}${window.location.pathname}${window.location.search}`
const share_url = domain;
const line_path = process.env.REACT_APP_LINE_PATH;
const google_api = process.env.REACT_APP_GOOGLE_API;

function EstateDetail(){
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: google_api,
        region:'th',
        language: 'th',
        version: "weekly",
    });
    const { userData, setUserData } = React.useContext(LoginContext);
    const [show, setShow] = React.useState(false);
    const [Estate, setEstate] = React.useState(null);
    const [reportOpen, setReportOpen] = React.useState(false);
    const [popoverShare, setPopoverShare] = React.useState(false);
    const [loginOpen, setLoginOpen] = React.useState(false);
    const [tooltip, setTooltip] = React.useState(false);
    const [copycheck, setCopycheck] = React.useState(false);
    // const [user, setUser] = React.useState(null);
    const [titledeeds, setTitledeeds] = React.useState("");
    const [showmore, setShowmore] = React.useState(false);
    const [EststeActive, setEstateActive] = React.useState(null);
    const [coordinateUser, setCoordinateUser] = React.useState({
        lat: 18.737666197841595,
        lng: 98.98034537929969,
    })
    const [map, setMap] = React.useState(null);

    const toggle = () => setTooltip(!tooltip);

    function useQuery() {
        const { search } = useLocation();
        return new URLSearchParams(search);
    }

    let query = useQuery();
    const google = window.google;

    const intl = new Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 0,
    })

    React.useEffect(() => {
        onFetch();
        scrollToTop();

        navigator.geolocation.getCurrentPosition(function(position){
            const {latitude, longitude} = position.coords;
            setCoordinateUser({lat:latitude, lng:longitude})
        },(err)=>{
            if(err){
                Swal.fire({
                    title: "ตำแหน่งที่ตั้ง ถูกปิดใช้งาน",
                    text: "เพื่อการใช้งานที่สมบูรณ์ กรุณาเปิดการใช้งานการแชร์ตำแหน่งที่ตั้งของท่าน",
                    confirmButtonText: "ปิด",
                    confirmButtonColor: "#E74C3C"
                })
            }
        })
    },[])

    function scrollToTop(){
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
    }

    const onFetch = () =>{
        const titledeed = query.get("sale");
        setTitledeeds(titledeed)
        axios.post(path_url+'/sale/getSaleByTitleDeed',{
            titleDeed: titledeed
        }).then(result =>{
            if(result.status === 200){
                const data = {...result.data}
                const userDetail = {...data.userDetail}
                if(userDetail.role === "PREMIUM"){
                    const datenow = new Date().getTime()
                    const datepremium = new Date(userDetail.expiredAt).getTime()
                    // console.log(datenow, datepremium)
                    if(datenow >= datepremium){
                        userDetail["role"] = "USER"
                    }
                }
                // data['titleDeedShow'] = data.titleDeedShow ? data.titleDeedShow : false
                setEstate({
                    ...data, 
                    userDetail: {...userDetail}, 
                    // titleDeedShow : data.titleDeedShow ? data.titleDeedShow : true
                })
                // console.log(data)
            }
        }).catch((e) =>{
            console.log(e)
            ErrorAlert({text: 'เกิดข้อผิดพลาดบางประการ กรุณาลองใหม่อีกครั้ง'})
        })
    }

    const toEstateDetail = (val) =>{
        // history.push(`/EstateDetail/?sale=${val}`)
        window.open(`/estate_detail/?sale=${val}`)
    }

    const freepaytype = (val) =>{
        switch(val){
            case '1' : return "ผู้ซื้อผู้ขายแบ่งจ่ายคนละครึ่ง";
            case '2' : return "ผู้ขายจ่ายทั้งหมด";
            case '3' : return "ผู้ซื้อจ่ายทั้งหมด";
            case '4' : return "ผู้ขายจ่ายภาษีเงินได้ ส่วนที่เหลือแบ่งจ่ายคนละครึ่ง";
            case '5' : return "ตามที่ตกลง";
            default : return "ตามที่ตกลง";
        }
    }

    const userSaleShow = async () =>{
        if(userData){
            try{
                const res = await axios.post(path_url+'/sale/interest',{
                    saleID: Estate._id,
                    userID: userData._id,
                    name: userData.name,
                    email: userData.email,
                })
                if(res.status === 200){
                    setShow(true)
                }
            }catch(e){
                console.log(e)
            }
        }else{
            setLoginOpen(true)
        }
    }

    const copylink = async () =>{
        try{
            await navigator.clipboard.writeText(share_url)
            setCopycheck(true)
            setTimeout(() => {
                setCopycheck(false)
            },2000)
        }catch(e){
            console.log(e)
        }
    }

    const checkFavorite = async (id) =>{
        if(userData !== null){
            return userData.favorites.some((item) => item === id)
        }else{
            return null
        }
    }

    async function onFavorite(id){
        const fa = await checkFavorite(id)
        if(fa === false){
            try{
                const data = {...userData}
                const favo = [...data.favorites, id]
                const res = await axios.post(path_url+'/user/updateFavorites',{
                    _id: userData._id,
                    favorites: favo
                })

                if(res.status === 200){
                    data['favorites'] = favo
                    // localStorage.setItem('profile', JSON.stringify({...data}));
                    setUserData({...data})
                }
            }catch(e){
                console.log(e)
            }
        }else if(fa === true){
            try{
                const data = {...userData};
                const favo = [...data.favorites];
                const dataFilter = favo.filter((item) => item !== id)
                const res = await axios.post(path_url+'/user/updateFavorites',{
                    _id: userData._id,
                    favorites: dataFilter
                })

                if(res.status === 200){
                    data['favorites'] = dataFilter
                    setUserData({...data})
                }
            }catch(e){
                console.log(e)
            }
        }else{
            setLoginOpen(true)
        }
    }

    const toNavigate = () =>{
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${Estate?.titledeed?.centerPoint?.coordinates[1]}, ${Estate?.titledeed?.centerPoint?.coordinates[0]}`)
    }

    const parePrice = (val) =>{
        let thaibath = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        return thaibath;
    }

    const handleActiveMarker = (val) =>{
        setEstateActive(val)
    }

    const [pricesOffer, setPricesOffer] = React.useState("");
    const [pricesOfferModal, setPricesOfferModal] = React.useState(false)

    async function OfferPrices(){
        try{
            const result = await axios.post(path_url+'/sale/pricesOffer',{
                bidder:{
                    _id: userData._id,
                    name: userData.name,
                    phoneNumber: userData.phoneNumber,
                    email: userData.email,
                    role: userData.role
                },
                seller:{
                    _id: Estate.userDetail._id,
                    name: Estate.userDetail.name,
                    phoneNumber: Estate.userDetail.phoneNumber,
                    email: Estate.userDetail.email,
                    role: Estate.userDetail.role
                },
                totalPrice: Estate.totalPrice,
                offer: pricesOffer,
                estateID: Estate._id,
                saleID: Estate.saleID,
                saleDistrict: Estate.saleDistrict,
                saleProvince: Estate.saleProvince,
                titleDeedNumber: Estate.titleDeedNumber
            })

            if(result.status === 200){
                Swal.fire({
                    icon: 'success',
                    text: "เราได้ส่งข้อมูลให้เจ้าของที่แล้ว กรุณารอการตอบกลับ ขอบคุณสำหรับการใช้บริการ",
                    confirmButtonText:"ตกลง",
                    confirmButtonColor: "#5DADE2",
                })
                setPricesOfferModal(false)
                setPricesOffer("")
            }
        }catch(e){
            console.log(e)
        }
    }

    var measure;
    function measureToolClick(){

        measure = new MeasureTool(map,{
            contextMenu: false,
            unit: MeasureTool.UnitTypeId.METRIC,
        })

        measure.addListener('measure_start', () => {
            console.log('started');
        });

        measure.start()
    }

    function measureToolEnd(){
        measure.end()
    }

    const onLoad = React.useCallback(function callback(map) {
        setMap(map)
    }, [])

    return(
        <>
        <Navbars />
        {Estate ? 
        <>
        <Helmet>
            <title>{Estate?.title}</title>
            <meta name="title" content={Estate?.title} />
            <meta name="description" content={Estate?.description}/>
            <meta property="og:title" content={Estate?.title} />
            <meta property="og:description" content={Estate?.description} />
            <meta property="og:site_name" content={Estate?.title} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={`https://www.taladteedin.com/estate_detail/?sale=${Estate?._id}`} />
            <meta property="og:image" content={Estate?.images[0]?.path} />
            {/* <meta property="og:image:type" content="image/jpg" /> */}
            <meta property="fb:app_id" content="1202029513835683" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={Estate?.title} />
            <meta name="twitter:description" content={Estate?.description} />
            <meta name="twitter:image" content={Estate?.images[0]?.path} />
            <meta name="twitter:site" content={`https://www.taladteedin.com/estate_detail/?sale=${Estate?._id}`} />
        </Helmet>
        <div className="estate-container">
            <div className="estate-header mb-30">
                <h1 className="tltd-header">{Estate?.title}</h1>
                <div className="container-like">
                    <Favorite 
                    className="mr-10" 
                    onFavorite={() => onFavorite(titledeeds)} 
                    favorite={userData !== null ? userData.favorites.some((items) => items === titledeeds) : false} 
                    />
                    <Button 
                    id="Popover1" 
                    className="btn-share-estate"
                    onClick={() => setPopoverShare(true)}
                    >
                        <FontAwesomeIcon icon={faShareNodes} style={{fontSize: 20}} />
                    </Button>
                    <Popover 
                    target="Popover1"
                    placement="bottom" 
                    toggle={() => setPopoverShare(!popoverShare)}
                    isOpen={popoverShare}
                    >
                        <PopoverBody>
                            <FacebookShareButton 
                            url={share_url+''} 
                            className="btn-share-facebook mr-10"
                            >
                                <FontAwesomeIcon icon={faFacebookF} style={{color:'white'}} />
                            </FacebookShareButton>
                            <LineShareButton
                            url={share_url}
                            className="btn-share-line mr-10"
                            >
                                <FontAwesomeIcon icon={faLine} style={{color:'white'}} />
                            </LineShareButton>
                            <TwitterShareButton
                            url={share_url}
                            className="btn-share-twitter mr-10"
                            >
                                <FontAwesomeIcon icon={faTwitter} style={{color:'white'}} />
                            </TwitterShareButton>
                            <Button
                            color="share-copylink"
                            onClick={() => copylink()}
                            id="tooltip1"
                            >
                                <FontAwesomeIcon icon={faLink} />
                            </Button>
                            <Tooltip
                            isOpen={tooltip}
                            target="tooltip1"
                            toggle={toggle}
                            >
                                Copy Link {copycheck ? <FontAwesomeIcon icon={faCheck} color="#41CD28" /> : null}
                            </Tooltip>
                        </PopoverBody>
                    </Popover>
                </div>
            </div>
            <div className="estate-picture mb-50">
                <PhotoGrid altTitle={Estate?.title} items={Estate? Estate?.images?.map(items => {return {src: items.path}}):[]} />
            </div>
            {Estate?.propertyDetailUrl ? 
            <div className="mb-50">
                {String(Estate?.propertyDetailUrl).indexOf("tiktok.com") > 1 ? 
                <TikTok
                url={Estate?.propertyDetailUrl}
                />
                : 
                <ReactPlayer 
                url={Estate?.propertyDetailUrl }
                controls={true}
                width={'100%'}
                height={500}
                />}
            </div> : null}
            <div className="estate-map mb-50">
                <p className="header">แผนที่</p>
                {isLoaded && google ?
                    <GoogleMap
                    mapContainerClassName="mapWrapper"
                    center={{
                        lat: Estate?.titledeed?.centerPoint?.coordinates[1], 
                        lng: Estate?.titledeed?.centerPoint?.coordinates[0]
                    }}
                    zoom={14}
                    options={{ 
                        gestureHandling: 'greedy',
                        fullscreenControl: false,
                        mapTypeControl: true,
                        mapTypeControlOptions:{
                            position: google.maps.ControlPosition.LEFT_TOP,
                            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                            mapTypeIds: [google.maps.MapTypeId.TERRAIN, google.maps.MapTypeId.SATELLITE],
                            
                        },
                        mapTypeId: `${google.maps.MapTypeId.SATELLITE}`,
                    }}
                    onLoad={onLoad}
                    >
                        <div
                        style={{
                            display:'flex', 
                            flexDirection:'column',
                            position:'absolute', 
                            right: 15,
                            bottom: 200,
                            justifyContent:'center',
                            alignItems:'center'
                        }}
                        >
                        <button
                        style={{
                            marginTop: 7,
                            marginBottom: 7,
                            width: 35, 
                            height: 35, 
                            borderRadius: 25, 
                            border: 'none', 
                            backgroundColor: '#fff',
                            color: '#000',
                            cursor: 'pointer'
                        }}
                        onClick={() => measureToolClick()}
                        >
                            <FontAwesomeIcon icon={faPenRuler} />
                        </button>
                        <button
                        style={{
                            marginTop: 7,
                            marginBottom: 7,
                            width: 35, 
                            height: 35, 
                            borderRadius: 25, 
                            border: 'none', 
                            backgroundColor: '#fff',
                            color: '#000',
                            cursor: 'pointer'
                        }}
                        onClick={() => measureToolEnd()}
                        >
                            <FontAwesomeIcon icon={faEraser} />
                        </button>
                    </div>
                        <button 
                        style={{
                            display:'block',
                            position:'absolute',
                            top: 15,
                            right: 15,
                            background: '#fff',
                            color: '#000',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                            border: 'none',
                            cursor: 'pointer',
                            borderRadius: 8
                        }}
                        className="shadow"
                        onClick={() => toNavigate()}
                        >
                            <p>นำทาง</p>
                        </button>
                        <button 
                        style={{
                            display:'block',
                            position:'absolute',
                            top: 60,
                            right: 15,
                            background: '#fff',
                            color: '#000',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                            border: 'none',
                            cursor: 'pointer',
                            borderRadius: 8
                        }}
                        className="shadow"
                        onClick={() => setShowmore(!showmore)}
                        >
                            {!showmore ? 
                            <p>แสดงเพิ่มเติม</p>
                            :
                            <p>ซ่อนการแสดง</p>
                            }
                            
                        </button>
                        <Marker 
                        position={coordinateUser}
                        icon={circle_point}
                        />
                        {Estate?.titledeed ? 
                        <>
                        <Polygon 
                        options={{
                            strokeColor: showmore ? 'red' 
                            :Estate?.saleCategory === 'LAND' ? '#EF9751'   
                            :Estate?.saleCategory === 'HOME' ? '#1789FE'
                            :Estate?.saleCategory === 'COMMERCIAL' ? '#2EB82E'
                            :Estate?.saleCategory === 'HOTEL' ? '#FF4D4D'
                            :Estate?.saleCategory === 'FACTORY' ? '#884ea0'
                            :'#595959',
                            strokeWeight: 3,
                            fillColor: showmore ? 'red' 
                            :Estate?.saleCategory === 'LAND' ? '#EF9751'   
                            :Estate?.saleCategory === 'HOME' ? '#1789FE'
                            :Estate?.saleCategory === 'COMMERCIAL' ? '#2EB82E'
                            :Estate?.saleCategory === 'HOTEL' ? '#FF4D4D'
                            :Estate?.saleCategory === 'FACTORY' ? '#884ea0'
                            :'#595959',
                        }}
                        path={Estate?.titledeed?.paths?.coordinates[0].map(element =>{
                            return {lat: element[1], lng: element[0]}
                        })}
                        />
                        <Marker 
                        icon={{
                            url: Estate?.saleCategory === "LAND" ? LandMarker 
                            :Estate?.saleCategory === "HOME" ? HouseMarker
                            :Estate?.saleCategory === "COMMERCIAL" ? CommercialMarker
                            :Estate?.saleCategory === "HOTEL" ? HotelMarker
                            :Estate?.saleCategory === "FACTORY" ? FactoryMarker
                            :MoreMarker,
                            scaledSize: Estate?.saleCategory === 'LAND' ? new google.maps.Size(150,49,'px','px') 
                            :Estate?.saleCategory === 'HOME' ? new google.maps.Size(160,49,'px','px')
                            :Estate?.saleCategory === 'COMMERCIAL' ? new google.maps.Size(155,49,'px','px')
                            :Estate?.saleCategory === 'HOTEL' ? new google.maps.Size(160,49,'px','px')
                            :Estate?.saleCategory === 'FACTORY' ? new google.maps.Size(160,49,'px','px')
                            :new google.maps.Size(150,49,'px','px')
                        }}
                        label={{
                            color: showmore ? 'red' 
                            :Estate?.saleCategory === 'LAND' ? '#EF9751'   
                            :Estate?.saleCategory === 'HOME' ? '#1789FE'
                            :Estate?.saleCategory === 'COMMERCIAL' ? '#2EB82E'
                            :Estate?.saleCategory === 'HOTEL' ? '#FF4D4D'
                            :Estate?.saleCategory === 'FACTORY' ? '#884ea0'
                            :'#595959',
                            text: Estate?.saleCategory === 'LAND'
                            ? `${intl.format(Estate?.pricePerWa)}` 
                            : `${intl.format(Estate?.totalPrice)}`,
                            fontFamily: "'Prompt', sans-serif"
                        }}
                        position={{
                            lat: Estate?.titledeed?.centerPoint?.coordinates[1], 
                            lng: Estate?.titledeed?.centerPoint?.coordinates[0]
                        }}
                        />
                        {showmore & isLoaded ?
                        <>
                        {Estate?.nearby ? 
                            Estate?.nearby?.filter(item => item?.owner?._id !== query.get('sale'))
                            .map((items,i) =>{
                                return(
                                    <>
                                    <Polygon 
                                    key={i}
                                    options={{
                                        strokeColor: items?.owner?.saleCategory === 'LAND' ? '#EF9751'   
                                        :items?.owner?.saleCategory === 'HOME' ? '#1789FE'
                                        :items?.owner?.saleCategory === 'COMMERCIAL' ? '#2EB82E'
                                        :items?.owner?.saleCategory === 'HOTEL' ? '#FF4D4D'
                                        :items?.owner?.saleCategory === 'FACTORY' ? '#884ea0'
                                        :'#595959',
                                        strokeWeight: 3,
                                        fillColor: items?.owner?.saleCategory === 'LAND' ? '#EF9751'   
                                        :items?.owner?.saleCategory === 'HOME' ? '#1789FE'
                                        :items?.owner?.saleCategory === 'COMMERCIAL' ? '#2EB82E'
                                        :items?.owner?.saleCategory === 'HOTEL' ? '#FF4D4D'
                                        :items?.owner?.saleCategory === 'FACTORY' ? '#884ea0'
                                        :'#595959'
                                    }}
                                    path={items?.paths?.coordinates[0].map(element =>{
                                        return {lat: element[1], lng: element[0]}
                                    })}
                                    />
                                    <Marker
                                    icon={{
                                        url: items?.owner?.saleCategory === 'LAND' ? LandMarker 
                                        :items?.owner?.saleCategory === 'HOME' ? HouseMarker
                                        :items?.owner?.saleCategory === 'COMMERCIAL' ? CommercialMarker
                                        :items?.owner?.saleCategory === 'HOTEL' ? HotelMarker
                                        :items?.owner?.saleCategory === 'FACTORY' ? FactoryMarker
                                        :MoreMarker,
                                        scaledSize: items?.owner?.saleCategory === 'LAND' ? new google.maps.Size(150,49,'px','px') 
                                        :items?.owner?.saleCategory === 'HOME' ? new google.maps.Size(160,49,'px','px')
                                        :items?.owner?.saleCategory === 'COMMERCIAL' ? new google.maps.Size(155,49,'px','px')
                                        :items?.owner?.saleCategory === 'HOTEL' ? new google.maps.Size(160,49,'px','px')
                                        :items?.owner?.saleCategory === 'FACTORY' ? new google.maps.Size(160,49,'px','px')
                                        :new google.maps.Size(150,49,'px','px')
                                    }}
                                    label={{
                                        color: items?.owner?.saleCategory === 'LAND' ? '#EF9751'   
                                        :items?.owner?.saleCategory === 'HOME' ? '#1789FE'
                                        :items?.owner?.saleCategory === 'COMMERCIAL' ? '#2EB82E'
                                        :items?.owner?.saleCategory === 'HOTEL' ? '#FF4D4D'
                                        :items?.owner?.saleCategory === 'FACTORY' ? '#884ea0'
                                        :'#595959',
                                        text: items?.owner?.saleCategory === 'HOME' 
                                        ? `${intl.format(items?.owner?.totalPrice)}` 
                                        : `${intl.format(items?.owner?.pricePerWa)}`,
                                        fontFamily: "'Prompt', sans-serif"
                                    }}
                                    position={{
                                        lat: items?.centerPoint?.coordinates[1], 
                                        lng: items?.centerPoint?.coordinates[0]
                                    }}
                                    onClick={() => handleActiveMarker(items)}
                                    >
                                       {EststeActive?._id === items?._id ? 
                                        <InfoWindow
                                        onCloseClick={() => setEstateActive(null)}
                                        >
                                            <>
                                            
                                            <ImageGallery 
                                            showPlayButton={false}
                                            showThumbnails={false}
                                            showFullscreenButton={false}
                                            renderLeftNav={(onClick, disable) =>(
                                                <Button 
                                                type="button"
                                                className="image-gallery-icon image-gallery-left-nav" 
                                                disabled={disable}
                                                onClick={onClick}
                                                aria-label="Previous Slide"
                                                >
                                                    <FontAwesomeIcon icon={faChevronLeft} onClick={onclick} className="gallery-icon-size" />
                                                </Button>
                                            )}
                                            renderRightNav={(onClick, disable) =>(
                                                <Button 
                                                type="button"
                                                className="image-gallery-icon image-gallery-right-nav" 
                                                disabled={disable}
                                                onClick={onClick}
                                                aria-label="Next Slide"
                                                >
                                                    <FontAwesomeIcon icon={faChevronRight} className="gallery-icon-size" />
                                                </Button>
                                            )}
                                            items={EststeActive?.owner?.images.map((images) =>{
                                                return{
                                                    original : images.path,
                                                    originalClass:"item-img",
                                                }
                                            })}
                                            />
                                            {EststeActive?.owner?.status === 'SOLD' ? 
                                            <div style={{
                                                display:'block',
                                                position:'absolute',
                                                top:20,
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                color: 'white'
                                            }}
                                            className="bg_danger"
                                            >
                                                ขายแล้ว
                                            </div> : null}
                                            <div style={{display:'flex', justifyContent:'end', alignItems:'center', paddingTop:10}}>
                                                <Favorite onFavorite={() => onFavorite(EststeActive?.owner?._id)} favorite={userData !== null ? userData.favorites.some((items) => items === EststeActive?.owner?._id) : false} />
                                            </div>
                                            <div className="mt-20 mb-10" style={{borderBottom:'0.5px solid gray'}}>
                                                <p className="font-size-sm font-weight-500">{EststeActive?.owner?.title}</p>
                                                {/* <p className="font-size-sm mt-10 mb-10">{EststeActive?.owner?.description}</p> */}
                                            </div>
                                            <Row>
                                                <Col className="mb-10">
                                                    <p className="font-size-sm color-gray">ขนาดแปลงที่ดิน</p>
                                                    <p className="font-size-sm">{EststeActive?.owner?.size.rai + " ไร่ " + EststeActive?.owner?.size.ngan + " งาน " + EststeActive?.owner?.size.wa + " วา"}</p>
                                                </Col>
                                            </Row>
                                            {EststeActive?.owner?.status === 'SOLD' ? 
                                            <Row className="row-cols-2 pb-10 mt-20" style={{borderBottom:'0.5px solid gray'}}>
                                                <Col>
                                                    <p className="font-size-sm color-gray">ราคารวม</p>
                                                    <p className="font-weight-500 color-default">{parePrice(0)} บาท</p>
                                                </Col>
                                                <Col>
                                                    <p className="font-size-sm color-gray">ราคาต่อตารางวา</p>
                                                    <p className="font-weight-500 color-default">{parePrice(0)} บาท</p>
                                                </Col>
                                            </Row>
                                            : 
                                            <Row className="row-cols-2 pb-10 mt-20" style={{borderBottom:'0.5px solid gray'}}>
                                                <Col>
                                                    <p className="font-size-sm color-gray">ราคารวม</p>
                                                    <p className="font-weight-500 color-default">{parePrice(EststeActive?.owner?.totalPrice)} บาท</p>
                                                </Col>
                                                {EststeActive?.owner?.pricePerWa ? 
                                                <Col>
                                                    <p className="font-size-sm color-gray">ราคาต่อตารางวา</p>
                                                    <p className="font-weight-500 color-default">{parePrice(EststeActive?.owner?.pricePerWa)} บาท</p>
                                                </Col>: null}
                                            </Row>
                                            }
                                            {EststeActive?.owner?.status !== 'SOLD' ? 
                                            <Row>
                                                <Col className="col-12" style={{display:'flex', justifyContent:'center'}}>
                                                    <Button 
                                                    type="button" 
                                                    color="transparent" 
                                                    className="pb-10 pt-10"
                                                    onClick={() => toEstateDetail(items?.owner?._id)}
                                                    >
                                                        เพิ่มเติม
                                                    </Button>
                                                </Col>
                                            </Row>
                                            : null}
                                            </>
                                        </InfoWindow> : null}
                                    </Marker>
                                    </>
                                )
                            })
                        : null}
                        </>
                        :null}
                        </>
                        : null}
                    </GoogleMap>
                : null}
            </div>
            <div className="estate-info mb-50">
                <div className="estate-info-container-1">
                    <div className="header-container">
                        <span className="header">ข้อมูลและรายละเอียดแปลงที่ดิน</span>
                        {Estate?.quick ? 
                        <div className="saler-quick mb-20">
                            <div className="description">ขายด่วน</div> 
                        </div>
                        : null}
                    </div>
                    
                    {show ? 
                    <div className="saler-info mb-50">
                        <div className="saler-img mr-10">
                            <img src={Estate?.userDetail?.profileImg ? Estate?.userDetail?.profileImg : blank_profile} alt="" />
                        </div>
                        
                        <div className="saler-detail">
                            <div className="saler-body">
                                <p>ชื่อผู้ประกาศ : <label>{userData ? userData.role === "PREMIUM" || Estate?.userDetail?.role === "PREMIUM" || Estate?.package?.type === "PERPLOT" ? Estate?.userDetail?.name : "ตลาดที่ดิน" : "ตลาดที่ดิน"}</label></p>
                                <p>เบอร์ติดต่อ : <label>{userData ? userData.role === "PREMIUM" || Estate?.userDetail?.role === "PREMIUM" || Estate?.package?.type === "PERPLOT" ? Estate?.userDetail?.phoneNumber : "020-955-198" : "020-955-198"}</label></p>
                                <p>เบอร์ติดต่อสำรอง : <label>{userData ? userData.role === "PREMIUM" || Estate?.userDetail?.role === "PREMIUM" || Estate?.package?.type === "PERPLOT" ? Estate?.userDetail?.phoneBackup : "" : ""}</label></p>
                            </div>
                            <div className="saler-status mb-20">
                                <div className="description">{Estate?.saleType === "BROKER" ? 'นายหน้า' : 'เจ้าของที่ดิน'}</div> 
                            </div>
                            {userData ? 
                            userData.role === "PREMIUM" || Estate?.userDetail?.role === "PREMIUM" || Estate?.package?.type === "PERPLOT" ?
                            null
                            : 
                            <div className="mb-20">
                                <small className="color-red">* ข้อมูลติดต่อเจ้าของที่ดินสำหรับสมาชิก Premium เท่านั้น <a href="/packages" ><u>สนใจคลิก</u></a></small>
                            </div>
                            :
                            <div className="mb-20">
                                <small className="color-red">* ข้อมูลติดต่อเจ้าของที่ดินสำหรับสมาชิก Premium เท่านั้น <a href="/packages" ><u>สนใจคลิก</u></a></small>
                            </div>
                            }
                            <div className="hideinfo">
                                <Button color="transparent" onClick={() => setShow(!show)}>
                                    แสดงน้อยลง 
                                    <FontAwesomeIcon icon={faChevronUp} className="ml-10" />
                                </Button>
                            </div>
                        </div>
                        
                    </div>
                    :
                    <div className="showinfo mb-50">
                        <Button color="default" onClick={() => userSaleShow()}>แสดงข้อมูลผู้ขาย</Button>
                    </div>
                    }
                    {Estate?.coAgent ? 
                        <div className="co-agent mb-30">
                            {Estate?.saleType === "OWNER" ? "ยินดีรับนายหน้า" : "ยินดีรับ Co-Agent"}
                        </div>
                    : null}
                    <div className="estate-description mb-10">
                        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-2">
                            <Col>
                                <label className="title">รหัสประกาศ</label>
                                <label className="text">{Estate?.saleID}</label>
                            </Col>
                            <Col>
                                <label className="title">โฉนดเลขที่</label>
                                {userData?.role === "PREMIUM"  ? 
                                <p 
                                className="text" 
                                style={{display:'flex', wordBreak:'break-word', wordWrap:'break-word'}}>
                                    {Estate?.titleDeedShow ? Estate?.titleDeedNumber : <label>*****</label>}
                                </p>
                                : 
                                <div 
                                className="text" 
                                style={{display:'flex', wordBreak:'break-word', wordWrap:'break-word'}}
                                >
                                    <small className="color-red">* สำหรับสมาชิก Premium เท่านั้น <a href="/packages" ><u>สนใจคลิก</u></a></small>
                                </div>
                                }
                            </Col> 
                            <Col>
                                <label className="title">ประเภท</label>
                                <label className="text">
                                    {
                                        Estate?.saleCategory === "LAND" 
                                        ? 'ที่ดินเปล่า' 
                                        : Estate?.saleCategory === "HOME"
                                        ? 'บ้าน ที่พักอาศัย'
                                        : Estate?.saleCategory === "COMMERCIAL"
                                        ? 'อาคารพาณิชย์'
                                        : Estate?.saleCategory === "HOTEL"
                                        ? 'โรงแรม/รีสอร์ท'
                                        : Estate?.saleCategory === "FACTORY"
                                        ? 'โรงงาน/โกดัง'
                                        : Estate?.saleCategory === "MORE"
                                        ? 'อื่นๆ'
                                        :null
                                    }
                                    {Estate?.saleCategory === "MORE" ?
                                    <div>({Estate?.more})</div>
                                    : null}
                                </label>
                            </Col>
                            <Col>
                                <label className="title">ที่อยู่</label>
                                <label className="text">{Estate?.saleDistrict + ", " + Estate?.saleProvince}</label>
                            </Col>
                        </Row>
                        {Estate?.cityplan ? 
                        <>
                        <div className="estate-cityplan">
                            <label className="title">ผังเมือง</label>
                            <Row className="row-cols-1 row-cols-md-2 row-cols-lg-2">
                                <Col>
                                    <label>พิกัดสถานที่</label>
                                    <label className="text">{Estate?.cityplan?.locationCoordinates}</label>
                                </Col>
                                <Col>
                                    <label>ที่ดินหมายเลข</label>
                                    <label className="text">{Estate?.cityplan?.landNumber}</label>
                                </Col>
                                <Col>
                                    <label>ประเภท</label>
                                    <label className="text">{Estate?.cityplan?.type}</label>
                                </Col>
                            </Row>
                        </div>
                        </>
                        : null}
                        
                        <div className="estate-highlight">
                            <label className="title">จุดเด่นของแปลงที่ดิน</label>
                            <textarea 
                            className="text" 
                            rows={8} 
                            disabled 
                            defaultValue={Estate?.description}
                            />
                        </div>
                        
                    </div>
                </div>
                <div className="estate-info-container-2">
                    <div className="detail-wrapper mb-20">
                        <div 
                        style={{
                            paddingLeft: 20, 
                            paddingTop:10, 
                            paddingBottom:10, 
                            borderBottom:'0.5px solid gray', 
                            marginBottom: 10
                        }}>
                            <p style={{fontWeight: 400}}>รายละเอียด</p>
                        </div>
                        <div style={{padding: 10}}>
                            <p className="mb-10">
                                {'พื้นที่ขนาด '}
                                {Estate?.size?.rai ? Estate?.size?.rai+' ไร่ ': null}
                                {Estate?.size?.ngan ? Estate?.size?.ngan+' งาน ': null} 
                                {Estate?.size?.wa ? Estate?.size?.wa+' ตารางวา': null} 
                            </p>
                            {Estate?.saleCategory === "HOME" || Estate?.saleCategory === "COMMERCIAL" || Estate?.saleCategory === "MORE" ? 
                            <div className="mb-10"> 
                                <label className="mr-10">{Estate.bedRoom ? `${Estate?.bedRoom} ห้องนอน`: null} </label> 
                                <label className="mr-10">{Estate.bathRoom ? `${Estate?.bathRoom} ห้องน้ำ`: null} </label>
                                <label>{Estate.usableArea ? `พื้นที่ใช้สอย ${Estate.usableArea} ตารางเมตร`: null} </label> 
                            </div>
                            : Estate?.saleCategory === "HOTEL" || Estate?.saleCategory === "FACTORY" ?
                            <div className="mb-10"> 
                                <label>{Estate.usableArea ? `พื้นที่ใช้สอย ${Estate.usableArea} ตารางเมตร`: null} </label> 
                            </div>
                            :null}
                            {Estate?.saleCategory === 'LAND' ? 
                            <p style={{marginBottom: 10}}>
                                ราคา {intl.format(Estate?.pricePerWa)} / ตร.ว.
                            </p>:null}
                            <p style={{marginBottom: 10, fontWeight:500}} className="color-default font">
                                ราคารวม {intl.format(Estate?.totalPrice)}
                            </p>
                            {Estate?.landAppraisalPrice ? 
                            <p style={{marginBottom: 10, fontWeight:500}} className="color-black font">
                                ราคาประเมินที่ดิน (กรมธนารักษ์) : {Estate?.landAppraisalPrice}
                            </p>
                            :null}
                            
                        </div>
                        <div style={{padding: 10}}>
                            <Button 
                            color="default-outline" 
                            size="sm" 
                            className="full-w" 
                            disabled={userData?.role === "PREMIUM" ? false : true}
                            onClick={() => setPricesOfferModal(true)}
                            >
                                เสนอราคา
                            </Button>
                        </div>
                    </div>
                    <div className="mb-30">
                        <p style={{fontWeight:500}}>ผู้ชำระค่าธรรมเนียม, ภาษี ณ วันโอนกรรมสิทธิ์</p>
                        <p className="color-gray" style={{fontSize:14}}>(ค่าธรรมเนียมการโอน, ภาษีธุรกิจเฉพาะ และภาษีเงินได้ไม่รวมค่าจดจำนอง)</p>
                        <div className="color-gray" style={{border : '0.5px solid gray', textAlign:'center', borderRadius:10, padding:10}}>
                            {Estate?.freepay ? freepaytype(Estate.freepay) : "ตามที่ตกลง"}
                        </div>
                    </div>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <a 
                        rel="noreferrer"
                        href={line_path}
                        className="mb-20 btn btn-line"
                        target="_blank"
                        >
                            <FontAwesomeIcon icon={faLine} style={{fontSize: 20}} className="mr-10" />
                            ปรึกษาทีมงานได้เลยนะครับ
                        </a>
                        {/* <a 
                        href="tel:0964146351"
                        className="mb-20 btn btn-default"
                        >
                            <FontAwesomeIcon icon={faPhone} style={{fontSize: 20}} className="mr-10" />
                            ติดต่อสอบถาม : 096-414-6351
                        </a> */}
                        <Button 
                        className="border-red color-red" 
                        style={{
                            padding:10, 
                            display:'flex', 
                            alignItems:'center',
                            backgroundColor:'white',
                            borderRadius: 10
                            // width:'fit-content'
                        }}
                        onClick={() => setReportOpen(true)}
                        >
                            <FontAwesomeIcon icon={faInfoCircle} style={{fontSize: 20}} className="mr-10" />
                            หากพบปัญหา สามารถแจ้งได้ที่นี่เลย
                        </Button>
                        {/* Modal Report */}
                        <ReportModal 
                        isOpen={reportOpen}
                        toggle={() => setReportOpen(!reportOpen)}
                        province={Estate?.saleProvince}
                        titledeed={Estate?.titleDeedNumber}
                        district={Estate?.saleDistrict}
                        />
                    </div>
                </div>
            </div>
            <div className="estate-near mb-50">
                <p className="header">แปลงที่ดินใกล้เคียง</p>
                {Estate ? 
                <Carousel 
                responsive={responsive}
                infinite={true}
                autoPlay={false}
                ssr={true}
                shouldResetAutoplay={false}
                // removeArrowOnDeviceType={["tablet", "mobile"]}
                containerClass="carousel-container"
                itemClass="carousel-item"
                > 
                {Estate?.nearby?.filter(item => item?.owner?._id !== query.get('sale'))
                .map((items,i) =>{
                    return(
                        <Card 
                        key={i}
                        style={{cursor:'pointer'}} 
                        onClick={() => toEstateDetail(items?.owner?._id)}
                        >
                            {/* <div className="tag bg_default color-white">
                                เจ้าของขายเอง
                            </div>
                            <div className="tag-2 bg_redpink color-white">
                                ขายด่วน
                            </div> */}
                            <CardImg 
                            alt=""
                            src={items?.owner?.images[0].path}
                            />
                            <CardBody>
                                <div className="body-1">
                                    <CardTitle>{items?.owner?.title}</CardTitle>
                                    <CardText>{items?.owner?.description}</CardText>
                                </div>
                                <div className="body-2">
                                    <CardText>{intl.format(items?.owner?.totalPrice)}</CardText>
                                    {items?.owner?.pricePerWa ?
                                        <CardText className="color-default">{intl.format(items?.owner?.pricePerWa)}/ตร.ว.</CardText>
                                    :null}
                                </div>
                                <CardText>
                                    <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                    {items?.owner?.saleDistrict + ", " + items?.owner?.saleProvince}
                                </CardText>
                            </CardBody>
                        </Card>
                    )
                })}
                </Carousel> :null}
            </div>
        </div> 
        </>
        : null}
        <LoginModal isOpen={loginOpen} onClose={() => setLoginOpen(false)} className="modal-sm" />
        <Modal isOpen={pricesOfferModal} toggle={() => setPricesOfferModal(false)}>
            <ModalHeader toggle={() => setPricesOfferModal(false)}>
                เสนอราคา
            </ModalHeader>
            <ModalBody>
                <p className="font-weight-500 mb-20">ราคารวม : <label className="font-weight-500 color-default">{Estate ? parePrice(Estate?.totalPrice) : ""} บาท</label></p>
                <Form>
                    <FormGroup className="form-group">
                        <Label className="font-weight-500">ราคาที่เสนอ (บาท)</Label>
                        <Input type="text" className="border" value={pricesOffer} onChange={(val) => setPricesOffer(val.target.value)} />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={() => OfferPrices()}>
                    เสร็จสิ้น
                </Button>
            </ModalFooter>
        </Modal>
        <Footer />
        </>
    )
}
export default EstateDetail;