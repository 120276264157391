import React from "react";
import Navbars from "routers/navbars";
import {
    Row,
    Col
} from 'reactstrap';
import axios from "axios";
import { LoginContext } from "components/loginContext/LoginContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrashAlt
} from '@fortawesome/free-regular-svg-icons';
import { useHistory } from 'react-router-dom';

const path_url = process.env.REACT_APP_API_CONNECT;

function Notification(){
    const [noti_List, setNoti_List] = React.useState([]);
    const { userData } = React.useContext(LoginContext);
    const history = useHistory();

    React.useEffect(() =>{
        if(userData){
            onFetch()
        }
    },[userData])

    async function onFetch(){
        try{
            const res = await axios.post(path_url+'/notification/notification_list',{
                uid: userData._id
            })

            if(res.status === 200){
                setNoti_List(res.data)
            }
        }catch(e){
            console.log(e)
        }
    }

    async function onReading(id, path){
        try{
            await axios.post(path_url+'/notification/notification_reading',{
                _id: id
            })
            history.push(path)
        }catch(e){
            console.log(e)
        }
    }

    async function onDelete(id){
        try{
            await axios.post(path_url+'/notification/notification_delete',{
                _id: id
            })
            onFetch()
        }catch(e){
            console.log(e)
        }
    }

    return(
        <>
        <Navbars />
        <div className="notify-container">
            <div className="notify-wrapper">
                <div className="shadow notify-list">
                    <h3>การแจ้งเตือน</h3>
                    <br/>
                    <Row className="row-cols-1 noti-row">
                        {noti_List.map((item, i) =>{
                            const date = new Date(item.createAt).toLocaleString('th', {day:'2-digit', month:'short', year:'2-digit', hour:'2-digit', minute:'2-digit'})
                            return(
                                <Col className="mb-10" key={i}>
                                    <button className="btn body-containner" onClick={() => onReading(item._id, item.path)}>
                                        <div style={{width: '10%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            {item.deleteAt === null ? 
                                                <div className="dot" />
                                            : null}
                                        </div>
                                        <div style={{width: '80%', textAlign:'start', wordBreak:'break-all', color: 'black'}}>
                                            <p style={{fontWeight: 400}}>{item.title}</p>
                                            <small className="mb-10" >{item.text}</small>
                                            <br/>
                                            <small>{date}</small>
                                        </div>
                                        <div style={{width: '10%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <FontAwesomeIcon icon={faTrashAlt} className="color-default" onClick={() => onDelete(item._id)} />
                                        </div>
                                    </button>
                                </Col>
                            )
                        })}
                        {noti_List.length === 0 ? 
                        <div className="color-gray" style={{textAlign:'center'}}>
                            ยังไม่พบการแจ้งเตือน
                        </div>
                        : null}
                    </Row>
                </div>
            </div>
        </div>
        </>
    )
}
export default Notification;