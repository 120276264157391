import React from "react";
import { PhotoSwipe } from "react-photoswipe";

function PhotoGrid({ items, altTitle}){
    const [isOpen, setIsOpen] = React.useState(false);
    const item_data = Array.from(items);
    const length = Array.from(items).length;

    const handleClose = () => {
        setIsOpen(false);
    };

    return(
        <>
        <PhotoSwipe 
        isOpen={isOpen} 
        items={item_data.map(item =>{
            return{
                src: item.src,
                w: 1400,
                h: window.innerHeight,
            }
        })} 
        shareLinkClick={false}
        onClose={() => handleClose()}
        options={{bgOpacity: 0.5}}
        />
        <div className="grid-wrapper">
            {length <= 1 ? 
            <div className="photo-box-1" id="gallery">
                {item_data.map((element,i) =>{
                    return (
                        <img key={i} src={element.src} alt={altTitle} className="btn photo-item" onClick={() => setIsOpen(true)} />
                    )
                })}
            </div>
            : length <= 2 ?
            <div className={"photo-box-2"} id="gallery">
                <div className="photo-row-1">
                    {item_data.slice(0,1).map((element,i) =>{
                        return <img key={i} src={element.src} alt={altTitle} className="btn photo-item" onClick={() => setIsOpen(true)} />
                    })}
                </div>
                <div className="photo-row-2">
                    {item_data.slice(1,2).map((element,i) =>{
                        return <img key={i} src={element.src} alt={altTitle} className="btn photo-item" onClick={() => setIsOpen(true)} />
                    })}
                </div>
            </div>
            : length <= 3 ?
            <div className={"photo-box-3"} id="gallery">
                <div className="photo-row-1">
                    {item_data.slice(0,1).map((element,i) =>{
                        return <img key={i} src={element.src} alt={altTitle} className="btn photo-item" onClick={() => setIsOpen(true)} />
                    })}
                </div>
                <div className="photo-row-2">
                    {item_data.slice(1,3).map((element,i) =>{
                        return <img key={i} src={element.src} alt={altTitle} className="btn photo-item" onClick={() => setIsOpen(true)} />
                    })}
                </div>
            </div>
            : length <= 4 ? 
            <div className={"photo-box-4"} id="gallery">
                <div className="photo-row-1">
                    {item_data.slice(0,1).map((element,i) =>{
                        return <img key={i} src={element.src} alt={altTitle} className="btn photo-item" onClick={() => setIsOpen(true)} />
                    })}
                </div>
                <div className="photo-row-2">
                    {item_data.slice(1,4).map((element,i) =>{
                        return <img key={i} src={element.src} alt={altTitle} className="btn photo-item" onClick={() => setIsOpen(true)} />
                    })}
                </div>
            </div>
            :
            <div className={"photo-box-5"} id="gallery">
                <div className="photo-row-1">
                    {item_data.slice(0,1).map((element,i) =>{
                        return <img key={i} src={element.src} alt={altTitle} className="btn photo-item" onClick={() => setIsOpen(true)} />
                    })}
                </div>
                <div className="photo-row-2">
                    {item_data.slice(1,4).map((element,i) =>{
                        return <img key={i} src={element.src} alt={altTitle} className="btn photo-item" onClick={() => setIsOpen(true)} />
                    })}
                </div>
                <div className="show-more" onClick={() => setIsOpen(true)}>
                    <span className="text">Show More +{length-4}</span>
                </div>
            </div>
            }
        </div>
        
        </>
    )
}



export default PhotoGrid