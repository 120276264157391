import React from "react";
import { 
    Button,
    Row,
    Form,
    FormGroup,
    Label,
    Input,
    InputGroup,
    Col,
    FormText,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter, 
} from "reactstrap";
import { ESContext } from "./context/ESContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faImages,
}from '@fortawesome/free-regular-svg-icons';
import { 
    faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { PROVINCE_LIST } from "views/components/province";
import { DISTRICT_LIST } from "views/components/districts";
import axios from "axios";
import { ErrorAlert, SuccessAlert } from "components/alert";
import { LoginContext } from "components/loginContext/LoginContext";
import ReactImageGallery from "react-image-gallery";

const path_url = process.env.REACT_APP_API_CONNECT;

function Tab1(){
    const { 
        ESDetail, 
        inputData, 
        inputDataSize, 
        _handlerLandCal, 
        inputImage, 
        deleteImage, 
        inputDataNumber
    } = React.useContext(ESContext)
    const [DistrictList, setDistrictList] = React.useState([]);
    // const [userData, setUserData] = React.useState(null);
    const [phoneIsOpen, setPhoneIsOpen] = React.useState(false);
    const { userData, setUserData } = React.useContext(LoginContext);
    // const [phoneAndName, setPhoneAndName] = React.useState({
    //     name: "",
    //     phoneNumber: ""
    // });

    React.useEffect(() => {
        // const user = localStorage.getItem("profile")
        if(userData !== null){
            if(!userData.phoneNumber || !userData.email){
                setPhoneIsOpen(true)
                // onChangePhoneAndName(parseUser.name, 'name')
            }
        }
    },[userData])

    // const phoneToggle = () =>{
    //     setPhoneAndName({
    //         name: "",
    //         phoneNumber: ""
    //     })
    //     setPhoneIsOpen(false)
    // }

    function onChangePhoneAndName(val, props){
        setUserData(item =>{
            const data = {...item};
            data[props] = val;
            return{...data}
        })
    }

    const _onSelectProvince = (val) =>{
        const province = PROVINCE_LIST.find(element =>{
            return element.province_id === val
        });

        const district = DISTRICT_LIST.filter((item) =>{
            return item.province_id === province?.province_id
        }).map((element) =>{
            return element
        });
        setDistrictList(district);
        inputData({id: val, name: province.province_name}, "province")
        inputData({id: "0", name: ''}, "district")
    }

    const _onChangDistrict = (val) =>{
        const district = DISTRICT_LIST.find(element =>{
            return element.amphur_id === val
        })
        // setEnterData(item => {
        //     return{...item, district: val}
        // })
        inputData({id: val, name: district.amphur_name}, "district")
    }

    const onSubmit = async (e) =>{
        e.preventDefault();
        try{
            const res = await axios.post(path_url+'/user/updatePhoneAndName',{
                _id: userData?._id,
                name: userData?.name,
                phoneNumber : userData?.phoneNumber,
                email: userData?.email
            })

            if(res.status === 200){
                SuccessAlert();
                setPhoneIsOpen(false)
                setUserData((item) =>{
                    const data = {...item}
                    return {...data}
                })
            }
        }catch(e){
            console.log(e)
            ErrorAlert({text: 'เกิดข้อผิดพลาด! กรุณาลองใหม่อีกครั้ง'})
        }
    }

    const inputFile = React.useRef(null);
    // const [Preview, setPreview] = React.useState([]);

    const onButtonClick = () => {
        if(ESDetail.preview_images.length < 8){
            inputFile.current.click();
        }else{
            ErrorAlert({text: 'ขออภัย! รูปภาพครบ 8 รูปแล้ว'})
        }
    };

    const onChangeImage = (val) =>{
        inputImage(val[0])
    }

    const deleteImg = (key) => deleteImage(key)

    return(
        <>
            <div className="tab-wrapper">
                <div className="tab-section-1">
                    <div className="title">ข้อมูลผู้ลงประกาศ</div>
                    <div className="selected">
                        <Button 
                        color={ESDetail.saleType === "OWNER" ? "selected" : "default-outline" }
                        size="sm" 
                        className={ESDetail.saleType === "OWNER" ? "" : "animated" }
                        style={{margin:10}}
                        onClick={() => inputData("OWNER", "saleType")}
                        >
                            เจ้าของที่ดิน
                        </Button>
                        <Button 
                        color={ESDetail.saleType === "BROKER" ? "selected" : "default-outline" }
                        size="sm" 
                        className={ESDetail.saleType === "BROKER" ? "" : "animated" }
                        style={{margin:10}}
                        onClick={() => inputData("BROKER", "saleType")}
                        >
                            นายหน้า
                        </Button>
                    </div>
                    {ESDetail.saleType ? 
                    <Row className="mb-20">
                        <Col 
                        className="col-12 col-md-4 col-lg-4"
                        onClick={() => inputData(ESDetail.coAgent ? false : true, "coAgent")}
                        style={{cursor:'pointer'}}
                        >
                            <FormGroup 
                            check
                            className="check-box-container"
                            >
                                <Input 
                                type="checkbox" 
                                checked={ESDetail.coAgent} 
                                onChange={(val) => inputData(val.target.checked, "coAgent")} 
                                />
                                {ESDetail.saleType === "OWNER" ? <p>ยินดีรับนายหน้า</p> : <p>ยินดีรับ Co-Agent</p>}
                            </FormGroup>
                            <FormText className="color-red">* ไม่จำเป็น</FormText>
                        </Col>
                    </Row>
                    : null}
                    <Form className="mb-30">
                        <Row style={{alignItems:'flex-end'}}>
                            <div className="col-md-2 col-sm-12">
                                <FormGroup className="form-group">
                                    <div className="font-weight-500">ผู้ลงขาย <Label className="color-red font-weight-500" style={{fontSize:20}}>*</Label></div>
                                    <Input 
                                    placeholder="Name" 
                                    className="color-darkblue border" 
                                    type="text" 
                                    disabled
                                    defaultValue={userData?.name}
                                    onChange={val => inputData(val.target.value, "name")}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-md-2 col-sm-12">
                                <FormGroup className="form-group">
                                    <div className="font-weight-500">เบอร์ติดต่อ <Label className="color-red font-weight-500" style={{fontSize:20}}>*</Label></div>
                                    <Input 
                                    placeholder="Phone Number" 
                                    className="color-darkblue border" 
                                    type="tel" 
                                    disabled
                                    defaultValue={userData?.phoneNumber}
                                    onChange={(val) => inputData(val.target.value, "tel")}
                                    />
                                    {userData 
                                    ? !userData.phoneNumber 
                                    ? <FormText className="color-red">* กรุณาแก้ไขข้อมูลส่วนตัว</FormText>
                                    : null
                                    : null}
                                </FormGroup>
                            </div>
                            <div className="col-md-2 col-sm-12">
                                <FormGroup className="form-group">
                                    <div className="font-weight-500">เบอร์ติดต่อสำรอง</div>
                                    <Input 
                                    placeholder="Reserve Phone Number" 
                                    className="color-darkblue border" 
                                    type="tel" 
                                    disabled
                                    defaultValue={userData?.phoneBackup}
                                    onChange={(val) => inputData(val.target.value, "tel_backup")}
                                    />
                                </FormGroup>
                            </div>
                        </Row>
                        <Row>
                            <Col>
                                <Label className="color-red">* กรุณาเข้าสู่ระบบก่อนกรอกข้อมูล</Label>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className="tab-section-2">
                    <div className="title">ข้อมูลของที่ดิน / บ้าน</div>
                    <Label className="font-weight-500">ประเภทที่ต้องการประกาศขาย <Label className="color-red font-weight-500" style={{fontSize:20}}>*</Label></Label>
                    <Row className="mb-10">
                        <Col className="col-12 col-md-6 col-xl-6">
                            <Button 
                            color={ESDetail.saleCatagory === "LAND" ? "selected" : "default-outline" }
                            size="sm" 
                            className={ESDetail.saleCatagory === "LAND" ? "" : "animated" }
                            style={{margin:10}}
                            onClick={() => inputData("LAND", "saleCatagory")}
                            >
                                ที่ดิน
                            </Button>
                            <Button 
                            color={ESDetail.saleCatagory === "HOME" ? "selected" : "default-outline" }
                            size="sm" 
                            className={ESDetail.saleCatagory === "HOME" ? "" : "animated" }
                            style={{margin:10}}
                            onClick={() => inputData("HOME", "saleCatagory")}
                            >
                                บ้าน
                            </Button>
                            <Button 
                            color={ESDetail.saleCatagory === "COMMERCIAL" ? "selected" : "default-outline" }
                            size="sm" 
                            className={ESDetail.saleCatagory === "COMMERCIAL" ? "" : "animated" }
                            style={{margin:10}}
                            onClick={() => inputData("COMMERCIAL", "saleCatagory")}
                            >
                                อาคารพาณิชย์
                            </Button>
                            <Button 
                            color={ESDetail.saleCatagory === "HOTEL" ? "selected" : "default-outline" }
                            size="sm" 
                            className={ESDetail.saleCatagory === "HOTEL" ? "" : "animated" }
                            style={{margin:10}}
                            onClick={() => inputData("HOTEL", "saleCatagory")}
                            >
                                โรงแรม/รีสอร์ท
                            </Button>
                            <Button 
                            color={ESDetail.saleCatagory === "FACTORY" ? "selected" : "default-outline" }
                            size="sm" 
                            className={ESDetail.saleCatagory === "FACTORY" ? "" : "animated" }
                            style={{margin:10}}
                            onClick={() => inputData("FACTORY", "saleCatagory")}
                            >
                                โรงงาน/โกดัง
                            </Button>
                            <Button 
                            color={ESDetail.saleCatagory === "MORE" ? "selected" : "default-outline" }
                            size="sm" 
                            className={ESDetail.saleCatagory === "MORE" ? "" : "animated" }
                            style={{margin:10}}
                            onClick={() => inputData("MORE", "saleCatagory")}
                            >
                                อื่นๆ
                            </Button>
                        </Col>
                        
                    </Row>
                    {ESDetail.saleCatagory === "MORE" ? 
                    <Row className="mb-30">
                        <Col className="col-12 col-md-6 col-xl-6">
                            <FormGroup className="form-group">
                                <Label>โปรดระบุ</Label>
                                <Input 
                                className="border" 
                                placeholder="ระบุประเภท" 
                                value={ESDetail.more}
                                onChange={(val) => inputData(val.target.value, "more")}
                                />
                            </FormGroup>
                        </Col>
                    </Row>: null}
                    <Form>
                        <Row className="mb-20">
                            <div className="col-md-6 col-sm-12">
                                <FormGroup className="form-group">
                                    <Label className="font-weight-500">
                                        เลขที่โฉนด<Label className="color-red font-weight-500" style={{fontSize:20}}>* </Label> 
                                    </Label>
                                    <span style={{fontSize:12}}>(กรณีแปลงนี้มีมากกว่า 1 โฉนดให้ใช้เครื่องหมาย "," คั่นระหว่างเลขโฉนด *432136,123456,35795*)</span>
                                    <span style={{fontSize:12, marginBottom: 10}}>(ท่านสามารถแอดไลน์ผู้ช่วย  เพื่อให้ช่วยลงประกาศ เช่น ในกรณีที่เป็นแปลงใหญ่ มีหลายโฉนด เป็นต้น)</span>
                                    
                                    <Input 
                                    placeholder="1234, 45677" 
                                    className="color-darkblue border" 
                                    type="text" 
                                    value={ESDetail.titledeed_number}
                                    onChange={(val) => inputData(val.target.value, "titledeed_number")}
                                    />
                                </FormGroup>
                                <FormGroup switch>
                                    <Input 
                                    type="switch" 
                                    role="switch" 
                                    disabled={userData?.role === "PREMIUM" ? false : true}
                                    checked={ESDetail.titleDeedShow} 
                                    onChange={(val) => inputData(val.target.checked, "titleDeedShow")} 
                                    />
                                    <Label>{ESDetail.titleDeedShow ? "แสดงเลขที่โฉนด" : "ไม่แสดงเลขที่โฉนด"}</Label>
                                </FormGroup>
                            </div>
                            
                        </Row>
                        <Row className="mb-20">
                            <div className="col-md-6 col-sm-12">
                                <FormGroup className="form-group">
                                    <Label className="font-weight-500">
                                        หัวข้อ <Label className="color-red font-weight-500" style={{fontSize:20}}>* </Label> 
                                    </Label>
                                    <Input 
                                    placeholder="Header" 
                                    className="color-darkblue border" 
                                    type="text" 
                                    defaultValue={ESDetail.title}
                                    onChange={(val) => inputData(val.target.value, "title")}
                                    />
                                </FormGroup>
                            </div>
                        </Row>
                        <Row className="mb-20">
                            <div className="col-md-5 col-sm-12">
                                <Row style={{display:'flex', alignItems:'flex-end'}}>
                                    <div className="col-md-6 col-sm-12">
                                        <FormGroup className="form-group">
                                            <Label className="font-weight-500">
                                                ที่อยู่ของแปลงที่ดิน <Label className="color-red font-weight-500" style={{fontSize:20}}>*</Label> 
                                            </Label>
                                            <InputGroup>
                                                <Input 
                                                className="color-darkblue form-control" 
                                                type="select" 
                                                value={ESDetail.province.id}
                                                onChange={(val) =>{ 
                                                    _onSelectProvince(val.target.value);
                                                }}
                                                >
                                                    <option value={0} disabled>จังหวัด</option>
                                                    {PROVINCE_LIST.map((item, i) =>{
                                                        return(
                                                            <option key={i} value={item.province_id}>
                                                                {item.province_name}
                                                            </option>
                                                        )
                                                    })}
                                                </Input>
                                            </InputGroup>
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <FormGroup className="form-group">
                                            <InputGroup>
                                                <Input 
                                                className="color-darkblue form-control" 
                                                type="select" 
                                                disabled={DistrictList.length !== 0 ? false : true}
                                                value={ESDetail.district.id}
                                                onChange={val => _onChangDistrict(val.target.value)}
                                                >
                                                    <option value={0} disabled>อำเภอ</option>
                                                    {DistrictList.map((item, i) =>{
                                                        return(
                                                            <option key={i} value={item.amphur_id}>
                                                                {item.amphur_name}
                                                            </option>
                                                        )
                                                    })}
                                                </Input>
                                            </InputGroup>
                                        </FormGroup>
                                    </div>
                                </Row>
                            </div>
                        </Row>
                        <Row className="mb-20">
                            <div className="col-md-6 col-sm-12">
                                <Row style={{display:'flex', alignItems:'flex-end'}}>
                                    <div className="col-md-4 col-sm-12">
                                        <FormGroup className="form-group">
                                            <Label className="font-weight-500">
                                                เนื้อที่รวมของทรัพย์ <Label className="color-red font-weight-500" style={{fontSize:20}}>*</Label> 
                                            </Label>
                                            <Input 
                                            placeholder="ไร่" 
                                            className="color-darkblue border" 
                                            type="number" 
                                            value={ESDetail.size.rai ? ESDetail.size.rai : ""}
                                            onChange={(val) => inputDataSize(val.target.value, "size", "rai")}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <FormGroup className="form-group">
                                            <Input 
                                            placeholder="งาน" 
                                            className="color-darkblue border" 
                                            type="number" 
                                            value={ESDetail.size.ngan ? ESDetail.size.ngan : ""}
                                            onChange={(val) => inputDataSize(val.target.value, "size", "ngan")}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <FormGroup className="form-group">
                                            <Input 
                                            placeholder="ตารางวา" 
                                            className="color-darkblue border" 
                                            type="number" 
                                            value={ESDetail.size.wa ? ESDetail.size.wa : ""}
                                            onChange={(val) => inputDataSize(val.target.value, "size", "wa")}
                                            />
                                        </FormGroup>
                                    </div>
                                </Row>
                            </div>
                        </Row>
                        
                        <Row className="mb-20">
                            {ESDetail.saleCatagory === "HOME" || ESDetail.saleCatagory === "COMMERCIAL" || ESDetail.saleCatagory === "MORE" ? 
                            <div className="col-md-7 col-sm-12">
                                <Row>
                                    <div className="col-md-4 col-sm-12">
                                        <FormGroup className="form-group">
                                            <Label className="font-weight-500">
                                                ห้องน้ำ <Label className="color-red font-weight-500" style={{fontSize:20}}>*</Label> 
                                            </Label>
                                            <Input 
                                            placeholder="0" 
                                            className="color-darkblue border" 
                                            type="text" 
                                            value={ESDetail.bathRoom ? ESDetail.bathRoom : ""}
                                            onChange={(val) => inputData(val.target.value, "bathRoom")}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <FormGroup className="form-group">
                                            <Label className="font-weight-500">
                                                ห้องนอน <Label className="color-red font-weight-500" style={{fontSize:20}}>*</Label> 
                                            </Label>
                                            <Input 
                                            placeholder="0" 
                                            className="color-darkblue border" 
                                            type="text" 
                                            value={ESDetail.bedRoom ? ESDetail.bedRoom : ""}
                                            onChange={(val) => inputData(val.target.value, "bedRoom")}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <FormGroup className="form-group">
                                            <Label className="font-weight-500">
                                                พื้นที่ใช้สอย (ตารางเมตร) <Label className="color-red font-weight-500" style={{fontSize:20}}> * </Label> 
                                            </Label>
                                            <Input 
                                            placeholder="0" 
                                            className="color-darkblue border" 
                                            type="text" 
                                            value={ESDetail.useableArea ? ESDetail.useableArea : ""}
                                            onChange={(val) => inputData(val.target.value, "useableArea")}
                                            />
                                        </FormGroup>
                                    </div>
                                </Row>
                            </div>
                            : ESDetail.saleCatagory === "HOTEL" || ESDetail.saleCatagory === "FACTORY" ?
                            <div className="col-md-7 col-sm-12">
                                <Row>
                                    <div className="col-md-4 col-sm-12">
                                        <FormGroup className="form-group">
                                            <Label className="font-weight-500">
                                                พื้นที่ใช้สอย (ตารางเมตร)
                                            </Label>
                                            <Input 
                                            placeholder="0" 
                                            className="color-darkblue border" 
                                            type="text" 
                                            value={ESDetail.useableArea ? ESDetail.useableArea : ""}
                                            onChange={(val) => inputData(val.target.value, "useableArea")}
                                            />
                                        </FormGroup>
                                    </div>
                                </Row>
                            </div>
                            : null}
                        </Row>
                        <Row className="mb-20">
                            <div className="col-md-6 col-sm-12">
                                <Row>
                                    {ESDetail.saleCatagory === "LAND" || ESDetail.saleCatagory === "MORE" ? 
                                    <div className="col-md-6 col-sm-12">
                                        <FormGroup className="form-group">
                                            <Label className="font-weight-500">
                                                ราคาต่อตารางวา (บาท) <Label className="color-red font-weight-500" style={{fontSize:20}}>*</Label> 
                                            </Label>
                                            <Input 
                                            placeholder="0" 
                                            className="color-darkblue border" 
                                            type="text" 
                                            pattern="[0-9]{10}"
                                            required
                                            value={ESDetail.pricePerWa ? ESDetail.pricePerWa : ""}
                                            onChange={(val) => inputDataNumber(val.target.value, "pricePerWa")}
                                            />
                                            <small className="color-red">* ใส่จำนวนเต็มเท่านั้น เช่น 1000000</small>
                                            <small className="color-red">* ห้ามใส่ตัวอักษร (- , บาท)</small>
                                        </FormGroup>
                                    </div>
                                    :null}
                                    <div className="col-md-6 col-sm-12">
                                        <FormGroup className="form-group">
                                            <Label className="font-weight-500">
                                                ราคารวมทั้งหมด (บาท) <Label className="color-red font-weight-500" style={{fontSize:20}}>*</Label> 
                                            </Label>
                                            <Input 
                                            placeholder="0" 
                                            className="color-darkblue border" 
                                            type="text" 
                                            value={ESDetail.totalPrice}
                                            onChange={(val) => inputDataNumber(val.target.value, "totalPrice")}
                                            onFocus={() => _handlerLandCal()}
                                            />
                                            <small className="color-red">* ใส่จำนวนเต็มเท่านั้น เช่น 1000000</small>
                                            <small className="color-red">* ห้ามใส่ตัวอักษร (- , บาท)</small>
                                        </FormGroup>
                                    </div>
                                    
                                </Row>
                            </div>
                        </Row>
                        <Row className="mb-20">
                            <Col 
                            className="col-12 col-md-4 col-lg-4"
                            onClick={() => inputData(ESDetail.quick ? false : true, "quick")}
                            style={{cursor:'pointer'}}
                            >
                                <FormGroup 
                                check
                                className="check-box-container"
                                >
                                    <Input 
                                    type="checkbox" 
                                    checked={ESDetail.quick} 
                                    onChange={(val) => inputData(val.target.checked, "quick")} 
                                    />
                                    <p>ต้องการขายด่วน</p>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className="tab-section-3">
                    <Form>
                        <Row>
                            <div className="col-md-6 col-sm-12">
                                <FormGroup className="form-group">
                                    <Label className="title">
                                        จุดเด่นแปลงที่ดินของคุณ 
                                    </Label>
                                    <Input
                                    type="textarea" 
                                    className="form-textarea" 
                                    placeholder="ที่ดินติดแม่น้ำ / ติดถนน / อยู่ในเขตชุมชน / ทำเลดี / อยู่ใกล้สถานที่ต่างๆ เช่น ศูนย์ราชการหน้าโรงเรียน หน้าสถานที่ท่องเที่ยว"
                                    rows={7} 
                                    defaultValue={ESDetail.description}
                                    onChange={(val) => inputData(val.target.value, "description")}
                                    />
                                </FormGroup>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-md-6">
                                <FormGroup className="form-group">
                                    <div className="title">
                                        ผู้ชำระค่าธรรมเนียม, ภาษี ณ วันโอนกรรมสิทธิ์ <Label className="color-red font-weight-500" style={{fontSize:20}}>*</Label> 
                                    </div>
                                    <div style={{color:'gray'}} className="mb-10">
                                        <p>(ค่าธรรมเนียมการโอน, ภาษีธุรกิจเฉพาะ และภาษีเงินได้ ไม่รวมค่าจดจำนอง)</p>
                                        <p>* หากท่านไม่ทราบ หรือไม่แน่ใจ เรามีบริการเสริม ช่วยคำนวนให้</p>
                                    </div>
                                    <InputGroup>
                                        <Input 
                                        className="color-darkblue form-control" 
                                        type="select"
                                        defaultValue={ESDetail.freePay}
                                        onChange={(val) => inputData(val.target.value, "freePay")}
                                        >
                                            <option value={0} disabled>เลือก</option>
                                            <option value={1}>ผู้ซื้อผู้ขายแบ่งจ่ายคนละครึ่ง</option>
                                            <option value={2}>ผู้ขายจ่ายทั้งหมด</option>
                                            <option value={3}>ผู้ซื้อจ่ายทั้งหมด</option>
                                            <option value={4}>ผู้ขายจ่ายภาษีเงินได้ ส่วนที่เหลือแบ่งจ่ายคนละครึ่ง</option>
                                            <option value={5}>ตามที่ตกลง</option>
                                        </Input>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                        </Row>
                    </Form>
                </div>
                <div className="tab2-section-1">
                    <div className="header-tab">
                        <div className="title">รูปถ่าย</div>
                        <label className="subtitle">(ขนาดที่รูปภาพไม่เกิน 1920x1080 px จำนวน 8 รูปเท่านั้น)
                            <label className="color-red font-weight-500" style={{fontSize:20}}>*</label>
                        </label>
                    </div>
                    <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={(val) => onChangeImage(val.target.files)} accept="image/jpg, image/jpeg, image/png" />
                    <Button color="default-sm" onClick={onButtonClick}>
                        <FontAwesomeIcon icon={faUpload} style={{marginRight:10}} /> อัพโหลดรูปภาพ
                    </Button>
                </div>
                <div className="tab2-section-2">
                    {ESDetail.preview_images.length !== 0 ? 
                    <ReactImageGallery 
                    showPlayButton={false}
                    // showThumbnails={false}
                    thumbnailPosition='bottom'
                    showFullscreenButton={false}
                    items={
                        ESDetail.preview_images.map((items,i) => {
                            return{
                                original: items,
                                thumbnail: items,
                                originalClass:"item-img",
                                renderThumbInner:()=>{
                                    return(
                                        <div className="thumnail-container">
                                            <img alt="" src={items} style={{width:'100%', height:'100%'}} />
                                            <button onClick={() => deleteImg(i)} className="delete">x</button>
                                        </div>
                                    )
                                }
                            }
                        })
                    }
                    />
                    :
                    <>
                    <div className="ground-image">
                        <FontAwesomeIcon icon={faImages} style={{fontSize:100, color:'lightgray'}} />
                    </div>
                    <div className="ground-images">
                        <div className="sub-ground" >
                            <FontAwesomeIcon icon={faImages} style={{fontSize:50, color:"lightgray"}} />
                        </div>
                        <div className="sub-ground" >
                            <FontAwesomeIcon icon={faImages} style={{fontSize:50, color:"lightgray"}} />
                        </div>
                        <div className="sub-ground">
                            <FontAwesomeIcon icon={faImages} style={{fontSize:50, color:"lightgray"}} />
                        </div>
                    </div>
                    </>
                    }
                </div>
                <div className="tab2-section-3">
                    <Form>
                        <Row>
                            <div className="col-12 col-md-1">
                                <div className="title">วิดีโอ</div>
                            </div>
                            <div className="col-12 col-md-10">
                                <FormGroup className="form-group">
                                    <Input 
                                    className="color-darkblue border" 
                                    type="text" 
                                    placeholder="example https://www.youtube.com" 
                                    onChange={(val) => inputData(val.target.value, 'video')}
                                    />
                                    <FormText className="color-red">
                                        * วาง URL ของวิดีโอ
                                    </FormText>
                                </FormGroup>
                            </div>
                        </Row>
                    </Form>
                </div>
            </div>
            <Modal isOpen={phoneIsOpen}>
                <ModalHeader>แก้ไขข้อมูลส่วนตัว</ModalHeader>
                <Form onSubmit={onSubmit}>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup className="form-group">
                                    <Label>Email</Label>
                                    <Input 
                                    className="color-darkblue border" 
                                    type="text"
                                    value={userData?.email}
                                    onChange={(val) => onChangePhoneAndName(val.target.value, 'email')}
                                    />
                                    <FormText>โปรดระบุอีเมลที่ท่านใช้งาน เพื่อการได้รับข่าวสารหรือการแจ้งเตือน</FormText>
                                </FormGroup>           
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="form-group">
                                    <Label>ชื่อ</Label>
                                    <Input 
                                    className="color-darkblue border" 
                                    type="text"
                                    value={userData?.name}
                                    onChange={(val) => onChangePhoneAndName(val.target.value, 'name')}
                                    />
                                </FormGroup>           
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="form-group">
                                    <Label>หมายเลขโทรศัพท์</Label>
                                    <Input 
                                    className="color-darkblue border" 
                                    type="tel" 
                                    value={userData?.phoneNumber}
                                    onChange={(val) => onChangePhoneAndName(val.target.value, 'phoneNumber')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" color="default">บันทึกการเปลี่ยนแปลง</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
        
    )
}
export default Tab1;